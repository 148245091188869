import { useTheme } from "@emotion/react";
import { Box, InputAdornment, Stack, TextField } from "@mui/material";
import React from "react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import UsaFlagIcon from "../../../icons/UsaFlagIcon";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

export default function MobileInput({
  name,
  label,
  type = "text",
  first = false,
  sx = {},
  icon,
  register = () => {},
  errors = {},
  serverError,
  validateInputMethod = () => {},
  inputLabel,
  editIcon,
  ...rest
}) {
  let { typography, palette } = useTheme();
  let { darkMode } = useThemeStore();
  // if (!label) {
  //   label = name.replace(/([a-z])([A-Z])/g, "$1 $2");
  // }
  return (
    <Box sx={{ marginTop: "20px" }}>
      {label && (
        <label
          style={{
            color: darkMode ? palette.white["1000"] : palette.neutral["350"],
            fontFamily: typography.manrope,
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "146%",
            cursor: "pointer",
          }}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <TextField
        id={`outlined-number ${name}`}
        type={type ? type : "text"}
        fullWidth
        label={inputLabel && inputLabel}
        name={name}
        onBlur={() => {
          console.log("blur");
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ zIndex: 2, display: !icon ? "none" : "" }}
            >
              {icon}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="start"
              sx={{ gap: "15px", zIndex: 2, mr: 0 }}
            >
              {
                <>
                  <UsaFlagIcon />
                  {editIcon && editIcon}
                </>
              }
            </InputAdornment>
          ),
        }}
        sx={{
          mt: { md: "8px" },
          textTransform: "unset",
          "input:placeholder-shown": { border: "none" },
          "input:focus": { border: "none" },
          "input:hover": { border: "none" },
          "& label, & label.Mui-disabled": {
            color: darkMode ? "white.1000" : "primary.main",
            fontSize: "16px",
            fontWeight: 500,
            fontStyle: "normal",
            lineHeight: "normal",
            textTransform: "capitalize",
          },
          "& input,input:is(:hover,:focus)": {
            border: "none",
            zIndex: 2,
            color: darkMode ? "white.1000" : "black.1000",
            opacity: 1,
          },
          "& fieldset": {
            borderRadius: "10px",
            border: "1px solid",
            borderColor: darkMode ? "neutral.650" : "neutral.150",
            paddingTop: "15px",
            paddingBottom: "13px",
          },

          "& :-webkit-autofill": {
            BackgroundClip: "text",
            textFillColor: darkMode ? "#ffffff" : "black.1000",
            transition: "background-color 5000s ease-in-out 0s",
            boxShadow: ` inset 0 0 20px 20px ${
              darkMode ? "neutral.650" : "white.1000"
            }`,
          },
          "& label.Mui-focused": {
            color: darkMode ? "white.1000" : "primary.main",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: darkMode ? "white.1000" : "black.1000",
            },
          },
          "& .Mui-disabled": {
            cursor: "no-drop",
            color: darkMode ? "white.1000" : "primary.main",
            opacity: 0.6,
          },
          "& input.Mui-disabled": {
            textFillColor: darkMode
              ? palette.white["1000"]
              : palette.black["1000"],
          },
          " & .Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: darkMode
              ? `${palette.neutral["650"]}!important`
              : `${palette.neutral["150"]}!important`,
          },
          ".MuiFormHelperText-root": {
            marginInline: 0,
            mt: "5px",
          },
          ...sx,
        }}
        error={Boolean(errors[name]) || Boolean(serverError)}
        helperText={
          (errors[name]?.message || serverError) && (
            <>
              <Stack
                component={"span"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ErrorOutlineRoundedIcon
                  sx={{ fontSize: "16px", mr: "10px" }}
                />
                <Box component={"span"}>
                  {errors[name]?.message || serverError}
                </Box>
              </Stack>
            </>
          )
        }
        {...register(name)}
        {...rest}
      />
    </Box>
  );
}
