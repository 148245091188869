import React from "react";
import DialogBackground from "./DialogBackground";
import { Box, Button, alpha } from "@mui/material";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

export default function TransactionActiveDialog({ cancelCall }) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <DialogBackground
      heading={"Delete Account"}
      description="You can not delete this account before complete current transaction"
      hideAction
    >
      <Box sx={{ mx: "auto", maxWidth: { sm: "400px" }, mb: "50px" }}>
        <Button
          color="primary"
          variant="contained"
          sx={{
            p: "15px",
            fontSize: { xs: "14px", md: "16px" },
            lineHeight: 1.3,
            width: "100%",
            "&.Mui-disabled": {
              color: darkMode
                ? alpha(palette.white["1000"], 0.7)
                : "white.1000",
              backgroundColor: darkMode
                ? "primary.disabledDark"
                : "primary.disabledLight",
            },
          }}
          onClick={cancelCall}
        >
          Cancel
        </Button>
      </Box>
    </DialogBackground>
  );
}
