import { createSvgIcon } from "@mui/material";
import React from "react";

export default function InfoIcon(props) {
  const CreateInfoIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/Danger Circle">
        <g id="Danger Circle">
          <path
            id="Stroke 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.75012C17.108 2.75012 21.25 6.89112 21.25 12.0001C21.25 17.1081 17.108 21.2501 12 21.2501C6.891 21.2501 2.75 17.1081 2.75 12.0001C2.75 6.89112 6.891 2.75012 12 2.75012Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            fill="none"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 3"
            d="M11.9961 8.20325V12.6222"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            fill="none"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 5"
            d="M11.995 15.797H12.005"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            fill="none"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateInfoIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
