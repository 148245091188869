import { createSvgIcon } from "@mui/material";
export default function CommentSendIcon(props) {
  const CreateCommentSendIcon = createSvgIcon(
    <>
      <path
        d="M2.5 16.6666V11.6666L9.16667 9.99992L2.5 8.33325V3.33325L18.3333 9.99992L2.5 16.6666Z"
        fill="#B3B3B3"
      />
    </>
  );
  return (
    <CreateCommentSendIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
