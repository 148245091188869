import { Box, Typography } from "@mui/material";
import React from "react";
import Input from "../ui/form/Input";
import useThemeStore from "../../hooks/store/useThemeStore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import { useTheme } from "@emotion/react";

const DraftRaiseForm = ({ begDetails }) => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <Box>
      <Input
        name={"title"}
        inputLabel={"Title"}
        placeholder="Example title"
        defaultValue={begDetails?.title}
        disabled={true}
        countDown={true}
        maxLength={40}
        value={begDetails?.title}
        targetValue={begDetails?.title}
        maxlength={40}
      />
      <Input
        name={"postDescription"}
        inputLabel={"Description"}
        multiline
        fullWidth
        rows={5}
        defaultValue={begDetails?.postDescription}
        disabled={true}
        sx={{
          mt: "10px",
          "& fieldset": {
            border: "1px solid",
            borderColor: darkMode ? "neutral.650" : "white.400",
            borderRadius: "10px",
          },
          "& textarea::placeholder": {
            color: darkMode ? "white.1000" : "black.1000",
          },
          "& textarea,textarea:is(:hover,:focus)": {
            border: "none",
            zIndex: 2,
            color: darkMode ? "white.1000" : "black.1000",
            opacity: 1,
            mb: "20px",
          },
          "& .MuiInputBase-input.Mui-disabled": {
            textFillColor: darkMode
              ? `${palette.white[1000]}!important`
              : palette.black[1000],
          },
        }}
        countDown={true}
        maxLength={500}
        targetValue={begDetails?.postDescription}
        countDownSx={{
          position: "absolute",
          bottom: "20px",
          right: "9px",
        }}
      />
      {/* <Typography
        sx={{
          marginTop: "10px",
          color: darkMode ? "grey.900" : "black.350",
          opacity: darkMode ? 0.7 : 1,
          fontSize: "13px",
          fontWeight: 400,
          fontStyle: "normal",
          textAlign: "right",
        }}
      >
        {begDetails?.postDescription?.length || 0}/500
      </Typography> */}
      {begDetails.amount && (
        <Input
          inputLabel={"Goal"}
          type="number"
          placeholder="Min goal amount is $50"
          fullWidth
          name={"amount"}
          disabled={true}
          defaultValue={begDetails.amount}
        />
      )}

      {begDetails.goalDate && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Fundraiser end date"
              disabled
              defaultValue={moment(begDetails.goalDate)}
              sx={{
                mt: "20px",
                width: "100%",
                "& .MuiSvgIcon-root": {
                  color: darkMode ? "white.1000" : "black.350",
                },
                "& label.Mui-focused": {
                  color: darkMode ? "white.1000" : "primary.main",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: darkMode ? "white.1000" : "black.1000",
                  },
                },
                "input:placeholder-shown": { border: "none" },
                "input:focus": { border: "none" },
                "input:hover": { border: "none" },
                "& input.Mui-disabled": {
                  cursor: "no-drop",
                  textFillColor: darkMode
                    ? palette.white[1000]
                    : palette.black[1000],
                },
                "& label, & label.Mui-disabled": {
                  color: darkMode ? "white.1000" : "primary.main",
                  fontSize: "16px",
                  fontWeight: 500,
                  fontStyle: "normal",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                },
                "& input,input:is(:hover,:focus)": {
                  border: "none",
                  zIndex: 2,
                  color: darkMode ? "white.1000" : "black.1000",
                  opacity: 1,
                },
                "& fieldset": {
                  borderRadius: "10px",
                  border: "1px solid",
                  borderColor: darkMode ? "neutral.650" : "neutral.150",
                  paddingTop: "15px",
                  paddingBottom: "13px",
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      )}

      <Typography
        sx={{
          marginTop: "24px",
          color: darkMode ? "grey.900" : "black.350",
          opacity: darkMode ? 0.7 : 1,
          fontSize: "13px",
          fontWeight: 400,
          fontStyle: "normal",
          lineHeight: "24px",
        }}
      >
        A 8% processing fee is collected by PloomSocial, Inc. plus 2.9% credit
        card processing fee and 0.30 per transaction.
      </Typography>
    </Box>
  );
};

export default DraftRaiseForm;
