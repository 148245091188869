import { createSvgIcon } from "@mui/material";

export default function DustbinIcon2(props) {
  let CreateMenuIcon = createSvgIcon(
    <>
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.59875 3.93628L6.20775 3.02203C6.48178 2.61104 6.85306 2.27407 7.28861 2.04105C7.72417 1.80803 8.21053 1.68616 8.7045 1.68628H9.29625C9.7901 1.68629 10.2763 1.80821 10.7117 2.04123C11.1471 2.27424 11.5183 2.61114 11.7922 3.02203L12.4012 3.93628H15C15.1989 3.93628 15.3897 4.0153 15.5303 4.15595C15.671 4.2966 15.75 4.48737 15.75 4.68628C15.75 4.88519 15.671 5.07596 15.5303 5.21661C15.3897 5.35726 15.1989 5.43628 15 5.43628H14.907L13.6672 14.7333C13.5953 15.2738 13.3294 15.7698 12.9191 16.1291C12.5089 16.4883 11.9821 16.6863 11.4367 16.6863H6.56325C6.01804 16.6863 5.49135 16.4885 5.08108 16.1294C4.67082 15.7703 4.4049 15.2744 4.33275 14.734L3.093 5.43628H3C2.80109 5.43628 2.61032 5.35726 2.46967 5.21661C2.32902 5.07596 2.25 4.88519 2.25 4.68628C2.25 4.48737 2.32902 4.2966 2.46967 4.15595C2.61032 4.0153 2.80109 3.93628 3 3.93628H5.59875ZM10.5435 3.85378L10.5982 3.93628H7.4025L7.4565 3.85378C7.59352 3.64841 7.77912 3.48003 7.99683 3.36359C8.21453 3.24715 8.45761 3.18624 8.7045 3.18628H9.2955C9.54239 3.18624 9.78547 3.24715 10.0032 3.36359C10.2209 3.48003 10.4065 3.64841 10.5435 3.85378ZM7.5 6.93628C7.69891 6.93628 7.88968 7.0153 8.03033 7.15595C8.17098 7.2966 8.25 7.48737 8.25 7.68628V12.9363C8.25 13.1352 8.17098 13.326 8.03033 13.4666C7.88968 13.6073 7.69891 13.6863 7.5 13.6863C7.30109 13.6863 7.11032 13.6073 6.96967 13.4666C6.82902 13.326 6.75 13.1352 6.75 12.9363V7.68628C6.75 7.48737 6.82902 7.2966 6.96967 7.15595C7.11032 7.0153 7.30109 6.93628 7.5 6.93628ZM11.25 7.68628C11.25 7.48737 11.171 7.2966 11.0303 7.15595C10.8897 7.0153 10.6989 6.93628 10.5 6.93628C10.3011 6.93628 10.1103 7.0153 9.96967 7.15595C9.82902 7.2966 9.75 7.48737 9.75 7.68628V12.9363C9.75 13.1352 9.82902 13.326 9.96967 13.4666C10.1103 13.6073 10.3011 13.6863 10.5 13.6863C10.6989 13.6863 10.8897 13.6073 11.0303 13.4666C11.171 13.326 11.25 13.1352 11.25 12.9363V7.68628Z"
            fill={props.color}
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateMenuIcon
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          //   width: "unset",
          //   height: "unset",
          background: "transparent",
        },
      }}
      {...props}
    />
  );
}
