import { createSvgIcon, useTheme } from "@mui/material";

export default function VolumeUpIcon(props) {
  let { palette } = useTheme();
  let color = palette.darkIcon["main"];
  let CreateHeart = createSvgIcon(
    <>
      <g id="Iconly/Bold/Volume Up">
        <g id="Volume Up">
          <path
            id="Volume Up_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5831 7.52565C15.5244 6.95826 15.4632 6.36957 15.3128 5.78213C14.9029 4.37678 13.7676 3.50001 12.5504 3.50001C11.8714 3.49751 11.0126 3.91585 10.5257 4.33921L6.48985 7.7198H4.37711C2.82221 7.7198 1.57193 8.91847 1.33582 10.6482C1.13519 12.3091 1.08626 15.4442 1.33582 17.2716C1.55236 19.0991 2.74636 20.2802 4.37711 20.2802H6.48985L10.604 23.7109C11.0261 24.0779 11.7711 24.4987 12.4562 24.4987C12.5002 24.5 12.5394 24.5 12.5785 24.5C13.819 24.5 14.9115 23.5907 15.3213 22.1891C15.4757 21.5965 15.5298 21.0411 15.5821 20.5044L15.5831 20.4944L15.6382 19.9596C15.8486 18.2248 15.8486 9.76393 15.6382 8.0417L15.5831 7.52565ZM20.3075 7.57701C19.9711 7.08101 19.3043 6.95701 18.8162 7.30145C18.333 7.6484 18.2131 8.33229 18.5495 8.82704C19.4854 10.2061 20.0004 12.0423 20.0004 14C20.0004 15.9565 19.4854 17.7939 18.5495 19.173C18.2131 19.6677 18.333 20.3516 18.8174 20.6985C18.9985 20.8263 19.2101 20.8939 19.4279 20.8939C19.779 20.8939 20.1069 20.7173 20.3075 20.423C21.4893 18.682 22.1413 16.4011 22.1413 14C22.1413 11.5989 21.4893 9.31803 20.3075 7.57701ZM22.5049 3.75791C22.9918 3.41221 23.661 3.53872 23.995 4.03347C25.8251 6.72767 26.8332 10.2686 26.8332 13.9999C26.8332 17.7337 25.8251 21.2733 23.995 23.9675C23.7956 24.2619 23.4665 24.4385 23.1154 24.4385C22.8976 24.4385 22.6872 24.3709 22.5061 24.2431C22.0217 23.8961 21.9018 23.2135 22.237 22.7175C23.82 20.3853 24.6923 17.289 24.6923 13.9999C24.6923 10.712 23.82 7.61571 22.237 5.2835C21.9018 4.78874 22.0217 4.10486 22.5049 3.75791Z"
            fill="white"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateHeart
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
