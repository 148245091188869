import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";

const GeneralNotificationItem = () => {
  const { typography, palette } = useTheme();
  return (
    <>
      <Box
        position={"relative"}
        padding={"12px"}
        borderRadius={"8px"}
        sx={{ backgroundColor: alpha(palette.white["400"], 0.15) }}
      >
        {/* avatar with text */}
        <Stack
          direction={"row"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "9px",
            }}
          >
            <Avatar
              src="https://mui.com/static/images/avatar/2.jpg"
              sx={{ bgcolor: "primary.main" }}
              aria-label="userprofile"
            >
              R
            </Avatar>
            <Stack direction={"column"}>
              <Typography
                sx={{
                  fontFamily: typography.inter,
                  fontWeight: "700",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  color: "black.350",
                  textShadow: `0px 0px 11px   ${alpha(
                    palette.black["1000"],
                    0.15
                  )}`,
                }}
              >
                @invitation
              </Typography>
              <Typography
                sx={{
                  fontFamily: typography.inter,
                  fontWeight: "500",
                  fontSize: "12px",
                  opacity: "0.80",
                  color: "black.350",
                  textShadow: `0px 0px 11px   ${alpha(
                    palette.black["1000"],
                    0.15
                  )}`,
                }}
              >
                Video creator name
              </Typography>
            </Stack>
          </Stack>
          <Typography
            sx={{
              fontFamily: typography.inter,
              fontWeight: "500",
              fontSize: { xs: "12px", md: "14px" },
              opacity: "0.41",
              color: "black.350",
            }}
          >
            9 hr ago
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontFamily: typography.inter,
            fontWeight: "500",
            fontSize: { xs: "12px", md: "14px" },
            opacity: "0.50",
            color: "black.350",
            marginTop: "14px",
          }}
        >
          FarizHas invited You To become a member of house fariz
        </Typography>
        <Stack spacing={2} direction="row" mt={"15px"}>
          <Button
            variant="contained"
            fullWidth
            sx={{ borderRadius: "10px", textTransform: "capitalize" }}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            fullWidth
            sx={{ borderRadius: "10px", textTransform: "capitalize" }}
          >
            Decline
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default GeneralNotificationItem;
