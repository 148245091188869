import React, { useEffect, useState } from "react";
import SendIcon from "../../../icons/SendIcon";
import { Box, Button, Stack, Typography, alpha } from "@mui/material";
import MonetizationOnOutlinedIcon from "../../../icons/MonetizationOnOutlinedIcon";
import MonetizationOnOutlinedIconGrey from "../../../icons/MonetizationOnOutlinedIconGrey";
import { useTheme } from "@emotion/react";
import { useAuth } from "../../../hooks/store/useAuth";
import { useAllModal } from "../../../hooks/store/useAllModal";
import { useSnack } from "../../../hooks/store/useSnack";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useNavigate } from "react-router-dom";

const ShareAndDonationBtn = ({
  daysRemaining,
  author,
  id,
  status,
  begType,
  title,
  imageUrl,
  endedDate,
  description,
  goalAmount,
  amountRaised,
  shareLink,
}) => {
  const { palette, typography } = useTheme();
  const { userId } = useAuth();
  const { setShowPaymentDrawer, setShowShareDrawer } = useAllModal();
  const { setSnack } = useSnack();
  const { darkMode } = useThemeStore();

  function getShareLink() {
    setShowShareDrawer(
      true,
      id,
      title,
      imageUrl,
      false,
      description,
      author,
      {
        goalAmount,
        amountRaised,
      },
      shareLink
    );
  }

  let [show, setShow] = useState(false);
  let [position, setPosition] = useState(
    sessionStorage.getItem("btnContainerPosition")
  );
  let navigate = useNavigate();

  //   code for hide and show given component inside feed screen
  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      // Your code to run when scrolling occurs
      let position = sessionStorage.getItem("btnContainerPosition");
      if (position <= -55 && window.innerWidth > 640) {
        setShow(true);
        setPosition(position);
      } else {
        setShow(false);
        setPosition(position);
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (position > 50 && window.innerWidth > 640) {
    return null; // Don't render the component if 'position' is greater than 50
  } else {
    return (
      <Box
        display={
          position
            ? position < 50
              ? "grid"
              : { xs: "grid", md: "none" }
            : { xs: "grid", md: "none" }
        }
        gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
        padding={{ xs: "12px", md: "15px 20px" }}
        sx={{
          borderRadius: { xs: 0, md: "10px" },
          backgroundColor: darkMode ? "grey.550" : "white.1000",
          transition: "all .5s ease-in",
          boxShadow: `0px 2px 10px 0px ${alpha(
            darkMode ? palette.black["1000"] : palette.black["1000"],
            0.1
          )} `,
          // transform: "translateX(-20px)",
        }}
        // margin={{ xs: "12px", md: "30px" }}
        position={{ xs: "fixed", md: "sticky" }}
        bottom={show ? { xs: 0, md: "20px" } : { xs: 0, md: "-100px" }}
        left={{ xs: 0, md: "unset" }}
        width={"100%"}
        zIndex={200}
      >
        <Typography
          sx={{
            display: { xs: "none", sm: "none", md: "inline" },
            color: darkMode ? "white.1000" : "grey.550",
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        <Stack display={"grid"} gridTemplateColumns={"1fr 1fr"} gap={"20px"}>
          {daysRemaining >= 0 && status !== "ended" ? (
            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                background: palette.gradients["main"],
                borderRadius: "10px",
                textTransform: "capitalize",
                fontSize: { xs: "12px", md: "14px" },
                fontWeight: "700",
                fontFamily: typography.inter,
                cursor:
                  begType === 2
                    ? "pointer"
                    : daysRemaining < 0 || status === "ended"
                    ? "no-drop"
                    : "pointer",
                "& *": {
                  marginRight: 0,
                  marginLeft: 0,
                },
              }}
              onClick={() => {
                // if (!isLoggedIn) {
                //   return setSnack("login required", "warning");
                // } else {
                if (begType !== 2) {
                  if (daysRemaining >= 0 && status !== "ended") {
                    if (userId === author._id) {
                      setSnack(
                        "Self contribution are not allowed at this time.",
                        "warning"
                      );
                    } else {
                      navigate(`/donate?feed=${id}`);
                      // setShowPaymentDrawer(true, id, author._id);
                    }
                  } else {
                    setSnack("Contribution Period ended", "warning");
                  }
                } else {
                  if (status !== "ended") {
                    navigate(`/donate?feed=${id}`);
                    // setShowPaymentDrawer(true, id, author._id);
                  } else {
                    setSnack("Contribution Period ended", "warning");
                  }
                }
                // }
              }}
              startIcon={
                daysRemaining !== null &&
                daysRemaining < 0 &&
                endedDate === undefined ? (
                  <MonetizationOnOutlinedIconGrey />
                ) : (
                  <MonetizationOnOutlinedIcon />
                )
              }
            >
              Donate
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                textTransform: "capitalize",
                fontSize: { xs: "12px", md: "14px" },
                fontWeight: "700",
                fontFamily: typography.inter,
                "& *": {
                  marginRight: 0,
                  marginLeft: 0,
                },
              }}
              startIcon={<MonetizationOnOutlinedIcon />}
              style={{
                backgroundColor: "#a1a1a1",
                color: "white",
                pointerEvents: "all",
                cursor: "no-drop",
              }}
            >
              Donate
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={<SendIcon />}
            sx={{
              display: "flex",
              flexDirection: "column",
              background: palette.gradients["mainInvert"],
              borderRadius: "10px",
              textTransform: "capitalize",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: "700",
              fontFamily: typography.inter,
              "& *": {
                marginRight: 0,
                marginLeft: 0,
              },
              // cursor: "no-drop",
            }}
            onClick={() => getShareLink()}
          >
            Share
          </Button>
        </Stack>
      </Box>
    );
  }
};

export default ShareAndDonationBtn;
