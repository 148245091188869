import { Box, Button, Typography } from "@mui/material";
import React from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import useThemeStore from "../../hooks/store/useThemeStore";

const ContentFlagDialog = ({ handleClose }) => {
  const { darkMode } = useThemeStore();
  return (
    <>
      <Box
        sx={{
          maxWidth: "498px",
          padding: { xs: "20px", md: "40px" },
          display: "grid",
          placeItems: "center",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        <InfoRoundedIcon
          sx={{
            width: "54px!important",
            height: "54px!important",
            color: "error.main",
          }}
        />
        <Typography
          sx={{
            mt: "25px",
            color: darkMode ? "grey.900" : "black.1000",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: { xs: "12px", md: "14px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          The content posted does not meet the app guidelines. Please review the
          guidelines and edit before re-posting. If you have questions,
        </Typography>
        <Typography
          sx={{
            color: darkMode ? "grey.900" : "black.1000",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: { xs: "12px", md: "14px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          Please email{" "}
          <Button
            href="mailto:support@ploomsocial.com"
            sx={{
              p: 0,
              textTransform: "lowercase!important",
              textDecoration: "underline",
              color: darkMode ? "white.1000" : "primary.main",
            }}
          >
            support@ploomsocial.com
          </Button>
        </Typography>

        <Button
          variant="contained"
          fullWidth
          sx={{ paddingBlock: "15px", mt: "15px" }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default ContentFlagDialog;
