import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, alpha } from "@mui/material";
import AddMobileNumber from "./AddMobileNumber";
import VerifyOtp from "./VerifyOtp";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

export default function GetInfoDialog({
  open = false,
  setOpen,
  verifyUserIdentity,
}) {
  const [openForm, setOpenForm] = React.useState("addMobile"); //1. addMobile 2. otpVerify
  const [mobileNumber, setMobileNumber] = React.useState("");
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <React.Fragment>
      <Dialog
        maxWidth={"lg"}
        open={true}
        sx={{
          backgroundColor: alpha(palette.black[1000], 0.8),
          "& .MuiPaper-root": {
            mx: "15px",
            borderRadius: "15px!important",
            boxShadow: "none",
            border: "none",
            backgroundColor: "unset",
          },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 0,
            backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
          }}
        >
          {openForm === "addMobile" ? (
            <AddMobileNumber
              mobile={mobileNumber}
              setOpenForm={setOpenForm}
              setMobileNumber={setMobileNumber}
            />
          ) : (
            <VerifyOtp
              mobile={mobileNumber}
              setOpen={setOpen}
              setOpenForm={setOpenForm}
              verifyUserIdentity={verifyUserIdentity}
            ></VerifyOtp>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
