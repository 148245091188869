import { createSvgIcon } from "@mui/material";
import React from "react";

export default function YoutubeIcon(props) {
  const CreateNextDoorIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_615_2213)">
        <path
          d="M17.9961 32.0046C9.72734 32.0046 2.99609 25.2734 2.99609 17.0046C2.99609 8.7312 9.72734 2.00464 17.9961 2.00464C26.2648 2.00464 32.9961 8.73589 32.9961 17.0046C32.9961 25.2734 26.2648 32.0046 17.9961 32.0046Z"
          fill="#DB2B42"
          fillOpacity="0.25098"
        />
        <path
          d="M17.9961 32.0046C9.72734 32.0046 2.99609 25.2734 2.99609 17.0046C2.99609 8.7312 9.72734 2.00464 17.9961 2.00464C26.2648 2.00464 32.9961 8.73589 32.9961 17.0046C32.9961 25.2734 26.2648 32.0046 17.9961 32.0046Z"
          fill="#F90000"
        />
        <path
          d="M26.2 13.6531C26.2 13.6531 26.0359 12.5 25.5344 11.9937C24.8969 11.3281 24.1844 11.3234 23.8563 11.2859C21.5172 11.1172 18.0016 11.1172 18.0016 11.1172H17.9922C17.9922 11.1172 14.4813 11.1172 12.1375 11.2859C11.8094 11.3234 11.0969 11.3281 10.4594 11.9937C9.95781 12.5 9.79375 13.6531 9.79375 13.6531C9.79375 13.6531 9.625 15.0078 9.625 16.3625V17.6328C9.625 18.9875 9.79375 20.3422 9.79375 20.3422C9.79375 20.3422 9.95781 21.4953 10.4594 22.0016C11.0969 22.6672 11.9313 22.6484 12.3063 22.7188C13.6516 22.8453 18.0016 22.8828 18.0016 22.8828C18.0016 22.8828 21.5172 22.8781 23.8563 22.7094C24.1844 22.6719 24.8969 22.6672 25.5344 22.0016C26.0359 21.4953 26.2 20.3422 26.2 20.3422C26.2 20.3422 26.3687 18.9875 26.3687 17.6328V16.3625C26.3687 15.0078 26.2 13.6531 26.2 13.6531ZM16.2766 19.1703V14.4687L20.7953 16.8266L16.2766 19.1703Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_615_2213">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(3 2)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateNextDoorIcon
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      sx={{
        "&.MuiSvgIcon-root": { width: "unset", height: "unset" },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
