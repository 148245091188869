import { Stack, Typography, useTheme, alpha, IconButton } from "@mui/material";
import React from "react";

import { Box } from "@mui/system";

// import { formatNumberCount } from "../../utils/NumberFormater";
import { UsMoneyFormat } from "../../utils/UsMoneyFormat";
import useThemeStore from "../../hooks/store/useThemeStore";
import MonetizationOnOutlinedIconGrey from "../../icons/MonetizationOnOutlinedIconGrey";

export default function AmountProgress({
  daysRemaining,
  amountRaised,
  donorCount,
  goalAmount,
  begType,
  endedDate,
}) {
  const { typography, palette } = useTheme();
  const { darkMode } = useThemeStore();
  return (
    <>
      <Box
        // width={{ xs: "calc(100% - 15px)", md: "calc(100% - 30px)" }}
        width="100%"
        position={"absolute"}
        left={0}
        right={0}
        marginInline={"auto"}
        bottom={"0px"}
        color="white.1000"
        zIndex={2}
      >
        <Typography
          ml={{ xs: "8px", md: "15px" }}
          fontSize={{ xs: "8px", md: "12px" }}
          color={"white.1000"}
        >
          {daysRemaining !== null &&
            daysRemaining > 0 &&
            endedDate === undefined &&
            `${daysRemaining} days left to contribute`}

          {daysRemaining !== null &&
            daysRemaining === 0 &&
            endedDate === undefined &&
            "contribution ends today"}

          {daysRemaining !== null &&
            daysRemaining < 0 &&
            endedDate === undefined &&
            `Contribution period ended`}

          {endedDate && `Contribution period ended`}
        </Typography>
        <Box
          // border="1px solid"
          // borderRadius={"50px"}
          display={"block"}
          mt={0.5}
          sx={{
            placeContent: "center",
            backgroundColor: darkMode ? "black.250" : "black.350",
            // backgroundColor: alpha(palette.white["600"], 0.2),
            // backdropFilter: "blur(20px)",
          }}
        >
          <Box
            sx={{
              padding: begType === 1 ? "10px 15px" : "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            <Stack sx={{ width: "100%" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"5px"}
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      fontFamily: typography.inter,
                      fontWeight: 700,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      color:
                        begType === 1 ? "white.1000" : "success.progressBar",
                      textShadow: `0px 0px 11px   ${alpha(
                        palette.black["1000"],
                        0.15
                      )}`,
                    }}
                  >
                    ${UsMoneyFormat(amountRaised)} Raised
                  </Typography>
                </Stack>
                {goalAmount !== undefined && begType === 1 && (
                  <Typography
                    sx={{
                      color: "white.300",
                      fontSize: { xs: "12px", md: "14px" },
                      fontFamily: typography.inter,
                      fontWeight: 700,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      textShadow: `0px 0px 11px   ${alpha(
                        palette.black["1000"],
                        0.15
                      )}`,
                    }}
                  >
                    {/* Need of ${formatNumberCount(goalAmount)} */}
                    Goal ${UsMoneyFormat(goalAmount)}
                  </Typography>
                )}
              </Box>

              {begType === 1 && (
                <Box
                  sx={{
                    mt: 1,
                    backgroundColor: alpha(palette.white["100"], 0.1),
                    backdropFilter: "blur(20px)",
                  }}
                  width="100%"
                  height={"5px"}
                  borderRadius={1}
                  overflow={"hidden"}
                >
                  <Box
                    backgroundColor={"success.progressBar"}
                    width={
                      ((amountRaised / goalAmount) * 100 > 100
                        ? 100
                        : (amountRaised / goalAmount) * 100) + "%"
                    }
                    height={"100%"}
                    borderRadius={1}
                  />
                </Box>
              )}
            </Stack>
            {begType === 1 && (
              <IconButton
                aria-label="donate"
                sx={{
                  pointerEvents: "none",
                  color: "white.1000",
                  background: palette.gradients.main,
                  "& ": { borderRadius: "50%" },
                  width: { xs: "32px", md: "43px" },
                  height: { xs: "32px", md: "43px" },
                }}
              >
                <MonetizationOnOutlinedIconGrey />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
