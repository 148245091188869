import { create } from "zustand";

export const useKyc = create((set) => ({
  firstName: undefined,
  surName: undefined,
  mobileNumber: undefined,
  nationalId: undefined,
  physicalAddress: undefined,
  city: undefined,
  country: undefined,
  zip: undefined,
  state: undefined,
  dob: undefined,
  email: undefined,
  kycTry: 0,
  setStep1(firstName, surName, mobileNumber, email) {
    set(() => ({
      firstName,
      surName,
      mobileNumber,
      email,
    }));
  },

  setStep2(physicalAddress, city, state, zip, country) {
    set(() => ({
      physicalAddress,
      city,
      state,
      zip,
      country,
    }));
  },

  setStep3(dob, nationalId) {
    set(() => ({
      dob,
      nationalId,
    }));
  },

  setKycTry() {
    set((state) => ({
      kycTry: state.kycTry + 1,
    }));
  },

  setKycTryCount(count) {
    set(() => ({
      kycTry: count,
    }));
  },

  setNationalId(value) {
    set(() => ({ nationalId: value }));
  },

  setDob(value) {
    set(() => ({ dob: value }));
  },

  setPhysicalAddress(value) {
    set(() => ({ physicalAddress: value }));
  },
  setZip(value) {
    set(() => ({ zip: value }));
  },
  setState(value) {
    set(() => ({ state: value }));
  },
  setCity(value) {
    set(() => ({ city: value }));
  },

  reset() {
    set(() => ({
      firstName: undefined,
      surName: undefined,
      mobileNumber: undefined,
      nationalId: undefined,
      physicalAddress: undefined,
      city: undefined,
      country: undefined,
      zip: undefined,
      state: undefined,
      dob: undefined,
      email: undefined,
      kycTry: 0,
    }));
  },
}));
