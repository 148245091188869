import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { useSnack } from "../../../hooks/store/useSnack";
import { useAuth } from "../../../hooks/store/useAuth";

import { useTheme } from "@emotion/react";
import useThemeStore from "../../../hooks/store/useThemeStore";
// import { LoadingButton } from "@mui/lab";

import CommentSendIcon from "../../../icons/CommentSendIcon";
import ProfileAvatar from "../../user/ProfileAvatar";
import CloseIcon from "../../../icons/CloseIcon";
import { useImmersivePage } from "../../../hooks/store/useImmersivePage";
import { useSettings } from "../../../hooks/store/useSettings";

export default function SubCommentForm({
  parentId,
  fetchComments,
  feedId,
  setRepliesCount = () => {},
  setShowSubCommentForm = () => {},
  setCurrentPage,
  disableMainCommentCount = false,
}) {
  const { setSnack } = useSnack();
  const { userId, user } = useAuth();
  const { darkMode } = useThemeStore();
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(false);
  const commentDivRef = useRef();
  const [buttonHeight, setButtonHeight] = useState(42);

  const { apiCall } = useApi();
  const { palette } = useTheme();
  const { settings } = useSettings();

  const { incrementCommentCount } = useImmersivePage();

  useEffect(() => {
    if (commentDivRef.current) {
      setButtonHeight(commentDivRef.current.clientHeight);
    }
  }, []);
  useEffect(() => {
    function resizeHandler() {
      if (commentDivRef.current) {
        setButtonHeight(commentDivRef.current.clientHeight);
      }
    }
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  });

  const postComment = async (e) => {
    try {
      e.preventDefault();
      if (loading) {
        return;
      }
      setLoading(true);
      const res = await apiCall({
        url: "v3/comments",
        method: "post",
        data: {
          userId,
          textDescription: commentText,
          parentId: parentId,
          htmlDescription: commentText,
          begId: feedId,
        },
      });
      if (res.status === 200) {
        setCurrentPage(1);
        let subComments = await fetchComments(parentId);
        setRepliesCount(subComments?.subComment || 0);
        if (!disableMainCommentCount) {
          incrementCommentCount(subComments?.comments || 0);
        }
        // incrementCommentCount(comments);
        setCommentText("");
        setLoading(false);
      } else throw Error("fail to post comment");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSnack("something gone wrong!", "error");
      //   console.log(error);
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: darkMode ? "grey.550" : "primary.light10",
        borderRadius: "10px",
        padding: { xs: "10px", sm: "15px" },
        marginTop: "15px",
      }}
    >
      <Box sx={{ mb: "15px" }}>
        <Box display={"flex"} alignItems={"center"} overflow={"hidden"}>
          <ProfileAvatar
            sx={{
              backgroundColor: "primary.main",
              color: "white.1000",
              width: { xs: "32px", sm: "40px" },
              height: { xs: "32px", sm: "40px" },
            }}
            src={user?.profileImage}
            alt={user?.username}
          />
          <Typography
            ml={"10px"}
            sx={{
              color: darkMode ? "white.1000" : "grey.550",
              fontSize: { xs: "12px", sm: "14px" },
              flexGrow: 1,
              fontWeight: 600,
              lineHeight: "16px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
          >
            {user.orgName
              ? user.orgName
              : user?.firstName + " " + user?.lastName}
          </Typography>
          <IconButton
            sx={{ p: "0" }}
            onClick={() => setShowSubCommentForm(false)}
          >
            <CloseIcon
              color={darkMode ? palette.white[1000] : palette.black[1000]}
              width="20px"
              height="20px"
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        component={"form"}
        onSubmit={postComment}
        sx={{
          display: "flex",
          placeItems: "center",
          backgroundColor: darkMode ? "black.250" : "primary.light10",
          borderRadius: "10px",
        }}
      >
        <Box
          width={"100%"}
          flexGrow={1}
          ref={commentDivRef}
          sx={{
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            "& ::placeholder": {
              fontSize: { xs: "12px", md: "14px" },
              color: darkMode
                ? alpha(palette.white["800"], 0.5)
                : alpha(palette.grey["550"], 0.5),
            },
            "& input": {
              padding: { xs: "9px", md: "11px" },
              "&:WebkitAutofill,&:WebkitAutofill:hover, &:WebkitAutofill:focus, &:WebkitAutofill:active":
                {
                  backgroundColor: "white.1000",
                  WebkitBoxShadow: `0 0 0 30px ${
                    darkMode ? "#3d3d3d" : "#e5e5e5"
                  } inset`,
                  WebkitTextFillColor: darkMode
                    ? palette.white[1000]
                    : palette.black[1000],
                  caretColor: darkMode
                    ? palette.white[1000]
                    : palette.black[1000],
                },
            },
          }}
        >
          <input
            type="text"
            name="comment"
            required
            minLength={settings?.subCommentMinLength}
            maxLength={settings?.subCommentMaxLength}
            placeholder="Reply..."
            value={commentText}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            style={{
              width: "100%",
              height: "100%",
              fontSize: { xs: "12px", md: "14px" },
              backgroundColor: darkMode
                ? palette.bgDark.third
                : palette.white["1000"],
              fontFamily: "poppins",
              fontWeight: 500,
              lineHeight: "16px",
              border: "none",
              outline: "none",
              color: darkMode ? palette.white["1000"] : palette.black["1000"],
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
          />
        </Box>

        {/* comment submit button */}
        {!loading ? (
          <IconButton
            disableRipple={true}
            aria-label="fingerprint"
            type="submit"
            sx={{
              backgroundColor: !darkMode ? "white.1000" : "bgDark.third",
              width: buttonHeight + "px",
              height: buttonHeight + "px",
              padding: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& :hover": {
                backgroundColor: "inherit",
              },
              borderRadius: "0px !important",
              borderTopRightRadius: "5px !important",
              borderBottomRightRadius: "5px !important",
            }}
          >
            <CommentSendIcon
              sx={{ color: darkMode ? "black.1000" : "white.1000" }}
            />
          </IconButton>
        ) : (
          <Box
            sx={{
              width: buttonHeight + "px",
              height: buttonHeight + "px",
              borderRadius: "0px !important",
              borderTopRightRadius: "5px !important",
              borderBottomRightRadius: "5px !important",
              backgroundColor: !darkMode ? "white.1000" : "bgDark.third",
              display: "grid",
              placeItems: "center",
            }}
          >
            <CircularProgress
              size={16}
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
