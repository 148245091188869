import { Box, Skeleton } from "@mui/material";
import React from "react";
import useThemeStore from "../../../hooks/store/useThemeStore";

const DashboardSkeleton = () => {
  const { darkMode } = useThemeStore();
  return (
    <>
      <Skeleton
        sx={{
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
          borderRadius: "6px",
        }}
        variant="rectangular"
        height={48}
      />
      <Skeleton
        sx={{
          mt: 2,
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
          borderRadius: "6px",
        }}
        variant="rectangular"
        height={12}
        width={180}
      />

      <Skeleton
        sx={{
          mt: 1.5,
          borderRadius: "12px",
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
          height: { xs: 300, md: 412 },
        }}
        variant="rectangular"
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <Skeleton
          sx={{
            width: "100%",
            mt: 1.5,
            borderRadius: "6px",
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            height: { xs: 60, md: 120 },
          }}
          variant="rectangular"
        />
        <Skeleton
          sx={{
            width: "100%",
            mt: 1.5,
            borderRadius: "6px",
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            height: { xs: 60, md: 120 },
          }}
          variant="rectangular"
        />
        <Skeleton
          sx={{
            width: "100%",
            mt: 1.5,
            borderRadius: "6px",
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            height: { xs: 60, md: 120 },
          }}
          variant="rectangular"
        />
      </Box>
      <Skeleton
        sx={{
          width: "100%",
          mt: 1.5,
          borderRadius: "6px",
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
          height: { xs: 45, md: 45 },
        }}
        variant="rectangular"
      />
      <Skeleton
        sx={{
          mt: 1.5,
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
        }}
      />
      <Skeleton
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      />
      <Skeleton
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      />
    </>
  );
};

export default DashboardSkeleton;
