import { useCallback, useEffect, useState } from "react";
// import { useFeed } from "./store/useFeed";
import useApi from "./useApi";
import { useAuth } from "./store/useAuth";
import { useFeed } from "./store/useFeed";

export default function useInfiniteFeeds(
  url = "v3/begs",
  limit = 10,
  resumePage = false
) {
  // variables
  // const [feeds, setFeeds] = useState([]),
  let {
    feeds,
    setFeeds,
    current,
    setCurrent,
    totalPages,
    setTotalPages,
    setType,
    type,
    setUserId,
    userId: selectedUserId,
    setFeedOnTop,
  } = useFeed();
  const [page, setPage] = useState(resumePage ? current : 1),
    [isRefreshing, setIsRefreshing] = useState(false),
    hasMore = page < totalPages;
  // const [hasMore, setHasMore] = useState(true);
  // useEffect(() => {
  //   setHasMore(currentPage < totalPages);
  // }, [currentPage, totalPages]);
  // const callNext = currentPage < totalPages;
  const { apiCall, isLoading } = useApi();
  const { isLoggedIn, userId } = useAuth();
  const fetchFeeds = useCallback(
    async (method = "get", search) => {
      try {
        const params = {};
        let data = {};
        switch (type) {
          case "all":
            params.begType = 0;
            break;
          case "raise":
            params.begType = 1;
            break;
          case "story":
            params.begType = 2;
            break;
          case "search":
            data.terms = search;
            break;
          case "user":
            setUserId(search);
            break;
          default:
            break;
        }

        params.page = page;
        params.limit = limit;
        if (isLoggedIn) {
          params.cuserId = userId;
        }
        if (page === 1) {
          setIsRefreshing(true);
        }
        const res = await apiCall({ url, params, method, data });
        if (page === 1) {
          setIsRefreshing(false);
        }

        const responseData = res.data.data;
        const totalPages = responseData.pagination.pages;
        const fetchFeeds = responseData.results;
        setFeeds(fetchFeeds, page);

        setTotalPages(totalPages);
      } catch (error) {
        console.log(error);
      }
    },
    [
      apiCall,
      type,
      url,
      page,
      setTotalPages,
      setFeeds,
      isLoggedIn,
      setUserId,
      userId,
      limit,
    ]
  );

  const setCurrentPage = useCallback((page) => {
    setPage((prevPage) => (page ? page : prevPage + 1));
  }, []);

  useEffect(() => {
    setCurrent(page);
  }, [setCurrent, page]);

  return {
    fetchFeeds,
    allFeeds: feeds || {},
    hasMore,
    isRefreshing,
    setCurrentPage,
    // callNext,
    setType,
    type,
    currentPage: page,
    userId: selectedUserId,
    isLoading,
    setFeedOnTop,
  };
}
