import React, { useEffect, useState } from "react";
import BackButtonLayout from "../ui/BackButtonLayout";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { Box, Button, Stack, Typography } from "@mui/material";
import ClockIcon from "../../icons/ClockIcon";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSnack } from "../../hooks/store/useSnack";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";

const OtpVerifyForm = ({ setOpenForm, useCase, mobile, updateMobile }) => {
  const { setSnack } = useSnack();
  const { typography, palette } = useTheme();
  const { darkMode } = useThemeStore();
  const { apiCall } = useApi(true);
  const { user, userId, setMobile } = useAuth();
  const [timer, setTimer] = useState(59);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  // code for send otp on user registered mobile number
  async function fireOtp() {
    try {
      await apiCall({
        url: "v2/accounts/get-otp",
        method: "post",
        data: {
          code: user.code,
          mobile: mobile ? mobile : user.mobile,
          OTP_description: "edit_profile",
        },
      });
    } catch (error) {
      setSnack(error.response.data.message, "error");
      setTimer(0);
      setIsResendDisabled(true);
    }
  }

  useEffect(() => {
    if (user) {
      fireOtp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Start the countdown timer when the component mounts
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsResendDisabled(false); // Enable the "Resend OTP" button when the timer reaches 0
        clearInterval(interval); // Stop the timer
      }
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [timer]);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // function for update mobile number
  async function updateMobileNo() {
    try {
      let data = { mobile: mobile };
      let response = await apiCall({
        url: `v2/accounts/${userId}`,
        method: "patch",
        data,
      });
      setMobile(mobile);
      setSnack(response.data.message);
      return navigate("/edit-profile");
    } catch (error) {
      setSnack(error.response.message, "error");
      return navigate("/");
    }
  }

  // code for varify otp
  const handleComplete = async (finalValue) => {
    try {
      setLoading(true);
      setIsWrongOtp(false);
      let response = await apiCall({
        url: "v2/accounts/verify-otp",
        method: "post",
        data: {
          code: user.code,
          mobile: mobile ? mobile : user.mobile,
          OTP: finalValue,
        },
      });

      console.log(response);
      console.log(useCase);
      if (updateMobile) {
        await updateMobileNo();
        setLoading(false);
      } else {
        setLoading(false);
        setSnack(response.data.message);
        setOpenForm(useCase ? useCase : "savePassword");
      }
      return;
    } catch (error) {
      console.log(error);
      setIsWrongOtp(true);
      setLoading(false);
      setSnack(error.response.data.message, "error");
    }
  };

  // code for resend otp
  const ResendOtp = async () => {
    setIsResendDisabled(true);
    setTimer(59);
    let result = await apiCall({
      url: "v2/accounts/get-otp",
      method: "post",
      data: {
        code: user.code,
        mobile: mobile ? mobile : user.mobile,
        OTP_description: "edit-profile",
      },
    });
    console.log(result);
    setSnack(
      "We've successfully resent the OTP to your mobile number. Please check your messages for the new code.",
      "success"
    );
  };

  return (
    <BackButtonLayout
      titlePrefix={"Verify"}
      pageTitle={"OTP"}
      isTitleBold
      sx={{ maxWidth: "491px" }}
    >
      <Box
        sx={{
          maxWidth: "431px",
          margin: "0 auto",
          height: { xs: "100%", md: "unset" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* form headings */}
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: typography.manrope,
            color: darkMode ? "white.1000" : "black.1000",
            fontSize: { xs: "26px", lg: "36px" },
            fontWeight: { xs: 800, lg: 700 },
            lineHeight: { xs: "40px", lg: "122%" },
            letterSpacing: { xs: "-0.52px", md: "-0.72px" },
          }}
        >
          Verify Your OTP
        </Typography>
        <Typography
          sx={{
            mt: "10px",
            fontFamily: typography.manrope,
            color: darkMode ? "neutral.450" : "neutral.550",
            fontSize: { xs: "18px", lg: "16px" },
            fontWeight: 400,
            lineHeight: "152%",
            textAlign: "center",
          }}
        >
          A verification code has been sent on {mobile ? mobile : user.mobile}.
          Enter the code below. Enter the code below.
        </Typography>

        <Box sx={{ marginTop: { xs: "20px", md: "20px" } }}>
          <MuiOtpInput
            TextFieldsProps={{ placeholder: "0" }}
            value={otp}
            length={6}
            onChange={handleChange}
            autoFocus
            onComplete={handleComplete}
            sx={{
              "& .MuiInputBase-root": { borderRadius: "8px" },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              " & .MuiOutlinedInput-input": {
                color: darkMode ? "white.1000" : "neutral.650",
                width: {
                  xs: "30px",
                  ss: "45px",
                  sm: "50px",
                  md: "59px",
                },
                height: {
                  xs: "30px",
                  ss: "45px",
                  sm: "50px",
                  md: "59px",
                },
                padding: 0,
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-input": {
                fontFamily: "manrope",
                fontSize: "16px",
                border: "1px solid",
                borderColor: darkMode ? "neutral.650" : "bgWhite.secondary",
                borderRadius: "8px",
              },
              "&.MuiOtpInput-Box": {
                flexWrap: "nowrap",
                justifyContent: "space-between",
                gap: { xs: "5px", sm: "10px", md: "15px" },
              },
            }}
          />
        </Box>

        {/* alert message */}
        {isWrongOtp && (
          <Stack
            sx={{
              mt: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              color: "error.light",
            }}
          >
            <ErrorOutlineIcon fontSize="small" />
            <Typography>
              Invalid OTP: The OTP you entered is incorrect.
            </Typography>
          </Stack>
        )}

        <Stack
          sx={{
            mt: { xs: "15px", md: "17px" },
            flex: "1",
            display: "flex",
            flexDirection: "row",
            alignItems: { xs: "baseline", md: "center" },
            justifyContent: "space-between",
          }}
        >
          <Stack
            display={"flex"}
            flexDirection="row"
            alignItems="center"
            gap={"10px"}
          >
            <ClockIcon
              color={darkMode ? palette.white["1000"] : palette.primary["main"]}
              width="16px"
              height="16px"
            />
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "primary.main",
                fontFamily: typography.manrope,
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "146%",
              }}
            >
              00:{timer}
            </Typography>
          </Stack>

          {isResendDisabled ? (
            <Button
              variant="text"
              sx={{
                "&.Mui-disabled": {
                  color: darkMode ? "white.1000" : "primary.main",
                  opacity: 0.3,
                  textTransform: "capitalize",
                  pointerEvents: "all",
                  cursor: "no-drop",
                },
              }}
              disabled
            >
              Resend OTP
            </Button>
          ) : (
            <Button
              variant="text"
              onClick={ResendOtp}
              sx={{
                color: darkMode ? "white.1000" : "primary.main",
                textTransform: "unset",
                fontSize: "14px",
                fontWeight: 500,
                opacity: 0.6,
              }}
            >
              Resend OTP
            </Button>
          )}
        </Stack>

        <ButtonWithLoading
          disabled
          //   disabled={formState.isSubmitting}
          isLoading={loading}
          fullWidth
          variant="contained"
          size="large"
          sx={{
            "&.Mui-disabled": {
              backgroundColor: "primary.main",
              opacity: 0.5,
              color: "white.1000",
              textTransform: "capitalize",
              pointerEvents: "all",
              cursor: "no-drop",
            },
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "152%",
            padding: "14px 12px",
            marginTop: { xs: "25px", lg: "30px" },
            borderRadius: "10px",
            textTransform: "capitalize",
          }}
        >
          Verify
        </ButtonWithLoading>
      </Box>
    </BackButtonLayout>
  );
};

export default OtpVerifyForm;
