import React from "react";
import { Box, Button, Divider, Stack, Typography, alpha } from "@mui/material";

import { useTheme } from "@emotion/react";
import useApi from "../../../hooks/useApi";
import useThemeStore from "../../../hooks/store/useThemeStore";
import ButtonWithLoading from "../../ui/button/ButtonWithLoading";
import Input from "../../ui/form/Input";
import OrgIcon from "../../../icons/OrgIcon";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { APIS } from "../../../api/lists";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useAuth } from "../../../hooks/store/useAuth";

const OrgInfoForm = ({
  setOpenForm,
  accountType,
  verifyUserIdentity,
  getLatestUserData,
}) => {
  const { apiCall, isLoading } = useApi();
  const { darkMode } = useThemeStore();
  const { typography, palette } = useTheme();
  const { user } = useAuth();
  //   yup validation schema
  let einRegExp = /^(\d{2}\d{7})$/;
  let nonProfileSchema = {
    orgName: Yup.string()
      .required("Organization name is required")
      .min(4, " Organization name must be at least 4 characters"),
    EIN: Yup.string()
      .required("EIN is required")
      .matches(einRegExp, "Invalid EIN number"),
  };
  let organizationSchema = {
    orgName: Yup.string().required("Organization name is required"),
  };
  const schema = Yup.object({
    ...(accountType.value === "non-profit" ? nonProfileSchema : {}),
    ...(accountType.value === "organization" ? organizationSchema : {}),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { orgName: user.orgName },
    mode: "onChange",
  });

  async function updateUserDetails(values) {
    try {
      const data = {
        accountType: accountType._id,
        orgName: values.orgName,
        ein: values.EIN,
      };

      await apiCall({
        url: APIS.USER.EDIT,
        method: "patch",
        data,
      });
      setOpenForm("completed");
      await getLatestUserData();
      return verifyUserIdentity();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Box
        component={"form"}
        onSubmit={handleSubmit(updateUserDetails)}
        sx={{
          padding: { xs: "16px", sm: "30px", md: "40px" },
          minWidth: { sm: "450px", md: "491px", lg: "551px" },
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: { xs: "10px", lg: "18px" },
          boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Button
            disableRipple={true}
            sx={{
              minWidth: "unset",
              backgroundColor: darkMode ? "grey.550" : "white.1000",
              flexShrink: "0",
              padding: "10px",
              borderRadius: "10px",
              border: darkMode ? "none" : "1px solid",
              borderColor: "grey.1100",
            }}
            onClick={() => {
              setOpenForm("selectAccountType");
            }}
          >
            <ArrowBackIosNewRoundedIcon
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                marginLeft: "0",
              }}
              fontSize="18px"
            />
          </Button>
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: { xs: "18px", md: "22px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            Please, provide your details
          </Typography>
        </Stack>
        <Divider
          sx={{
            mt: "20px",
            "&.MuiDivider-root": {
              borderColor: darkMode ? "neutral.650" : "neutral.150",
            },
          }}
        />
        <Box
          sx={{
            height: { xs: "100%", md: "unset" },
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              marginTop: { xs: "20px", md: "15px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {(accountType.value === "organization" ||
              accountType.value === "non-profit") && (
              <Input
                fullWidth
                name={"orgName"}
                inputLabel={"Organization"}
                type="text"
                register={register}
                errors={formState.errors}
                placeholder="Enter a Organization"
                boxSX={{ width: "100%", marginTop: "12px" }}
                icon={
                  <OrgIcon
                    color={
                      darkMode ? palette.white["1000"] : palette.black["450"]
                    }
                  />
                }
              />
            )}
            {accountType.value === "non-profit" && (
              <Input
                fullWidth
                name={"EIN"}
                inputLabel={"EIN"}
                type="text"
                register={register}
                errors={formState.errors}
                placeholder="Enter EIN Number"
                boxSX={{ width: "100%", marginTop: "24px" }}
                icon={
                  <OrgIcon
                    color={
                      darkMode ? palette.white["1000"] : palette.black["450"]
                    }
                  />
                }
              />
            )}

            <ButtonWithLoading
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              isLoading={isLoading}
              disabled={!formState.isValid || formState.isSubmitting}
              sx={{
                fontFamily: typography.manrope,
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "152%",
                padding: "12px 20px",
                marginTop: { xs: "25px", lg: "30px" },
                borderRadius: "10px",
                textTransform: "capitalize",
                background: palette.gradients.main,
                "&.Mui-disabled": {
                  color: darkMode
                    ? alpha(palette.white["1000"], 0.7)
                    : "white.1000",
                  opacity: 0.5,
                },
              }}
            >
              Submit
            </ButtonWithLoading>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OrgInfoForm;
