import {
  Box,
  Stack,
  Typography,
  useTheme,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  alpha,
  IconButton,
  Modal,
} from "@mui/material";
import React, { useState } from "react";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useSnack } from "../../hooks/store/useSnack";
import { useAuth } from "../../hooks/store/useAuth";
import useApi from "../../hooks/useApi";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "../../icons/CloseIcon";
import useThemeStore from "../../hooks/store/useThemeStore";
// import { useForm, Controller } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";

export const ReportDrawer = () => {
  const { palette } = useTheme();
  const { apiCall, isLoading } = useApi();
  const { setSnack } = useSnack();
  const { userId } = useAuth();
  const { showReportDrawer, setShowReportDrawer, reportData } = useAllModal();
  const { darkMode } = useThemeStore();

  function HandleClick(e) {
    e.stopPropagation();
  }

  function closeComponent() {
    setShowReportDrawer(false);
  }

  // const validReasons = [
  //   "OFFENSIVE",
  //   "PROHIBITED",
  //   "MISLEADING",
  //   "VIOLENT",
  //   "INAPPROPRIATE",
  //   "POLITICAL",
  //   "OTHER",
  // ];
  const [reason, setReason] = useState("");

  async function submitHandler(event) {
    event.preventDefault();
    try {
      const res = await apiCall({
        url: `v3/content-flags`,
        method: "POST",
        data: {
          itemId: reportData.id,
          begId: reportData.itemType === "beg" ? reportData.id : undefined,
          flaggerId: userId,
          itemType: reportData.itemType,
          reason: reason.toLowerCase(),
        },
      });
      setSnack(res.data.message, "success");
      setReason("");
      // reset();
      closeComponent();
    } catch (error) {
      setSnack(error.response.data.message, "error");
    }
  }

  return (
    <Modal
      open={showReportDrawer}
      onClose={() => setShowReportDrawer(false)}
      sx={{
        zIndex: 2000,
        backgroundColor: alpha(palette.black["1000"], 0.5),
        "& .css-4w5voh-MuiPaper-root-MuiDrawer-paper": {
          maxWidth: "550px",
          marginInline: "auto",
          backgroundColor: "transparent",
        },
        "& .MuiDrawer-root": {
          position: "relative",
          background: "transparent",
        },
        "& .MuiPaper-root": {
          position: "relative",
        },
        "& .MuiDrawer-paper": {
          background: alpha(palette.black[1000], 0.1),
          backdropFilter: "blur(3px)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          maxWidth: { xs: "388px", md: "430px" },
          width: "calc(100% - 15px)",
          marginInline: "auto",
          borderRadius: "15px",
          overflow: "hidden",
          padding: "30px",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
        }}
        onClick={HandleClick}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "20px",
            borderBottom: "1px solid",
            borderColor: darkMode
              ? alpha(palette.white[1000], 0.1)
              : alpha(palette.black[1000], 0.1),
          }}
          width={"100%"}
        >
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            Report
          </Typography>
          <IconButton aria-label="delete" onClick={closeComponent}>
            <CloseIcon
              color={
                darkMode ? alpha(palette.white[1000], 0.7) : palette.black[1000]
              }
            />
          </IconButton>
        </Stack>

        <Stack width={"100%"}>
          <Typography
            sx={{
              mt: "20px",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
              color: darkMode ? "white.1000" : "black.1000",
            }}
          >
            {reportData?.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
              color: darkMode ? alpha(palette.white[1000], 0.7) : "grey.300",
            }}
          >
            {reportData?.description}
          </Typography>
          <form onSubmit={submitHandler} sx={{ width: "100%" }}>
            <FormControl>
              <Typography
                id="demo-radio-buttons-group-label"
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  letterSpacing: "-0.408px",
                }}
              >
                Reason
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: { xs: "20px", sm: "40px", md: "70px" },
                  "& .MuiTypography-root": {
                    pl: "15px",
                    textTransform: "unset",
                    color: darkMode ? "white.1000" : "black.1000",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: { xs: "14px", sm: "16px", md: "16px" },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "-0.408px",
                  },
                  "& .MuiRadio-root": {
                    p: 0,
                  },

                  ".MuiRadio-root.Mui-checked": {
                    color: darkMode ? "white.1000" : "primary.main",
                  },
                }}
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              >
                <Box
                  sx={{
                    pl: "8px",
                    mt: "15px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <FormControlLabel
                    value="OFFENSIVE"
                    control={<Radio />}
                    label="Offensive"
                  />
                  <FormControlLabel
                    value="PROHIBITED"
                    control={<Radio />}
                    label="Prohibited"
                  />
                  <FormControlLabel
                    value="MISLEADING"
                    control={<Radio />}
                    label="Misleading"
                  />
                  <FormControlLabel
                    value="VIOLENT"
                    control={<Radio />}
                    label="Violent"
                  />
                </Box>

                <Box
                  sx={{
                    mt: "15px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <FormControlLabel
                    value="INAPPROPRIATE"
                    control={<Radio />}
                    label="Inappropriate"
                  />
                  <FormControlLabel
                    value="POLITICAL"
                    control={<Radio />}
                    label="Political"
                  />
                  <FormControlLabel
                    value="OTHER"
                    control={<Radio />}
                    label="Other"
                  />
                </Box>
              </RadioGroup>
            </FormControl>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={reason === "" || isLoading}
              loadingPosition="end"
              endIcon={<></>}
              sx={{
                marginTop: "30px",
                maxWidth: "unset",
                width: "100%",
                borderRadius: "50px",
                paddingBlock: "12px",
                "&.Mui-disabled": {
                  color: darkMode ? "black.100" : "white.1000",
                  backgroundColor: darkMode
                    ? "primary.disabledDark"
                    : "primary.disabledLight",
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& .MuiButton-startIcon": { mr: 0, ml: 0 },
                "& .MuiLoadingButton-loadingIndicator": {
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-45%)",
                  color: "white.1000",
                },
              }}
            >
              Report
            </LoadingButton>
          </form>
        </Stack>
      </Box>
    </Modal>
  );
};
