import React from "react";
import { Grow, IconButton } from "@mui/material";
import { Box } from "@mui/system";

import { default as PlayIconSvg } from "../../icons/PlayIcon";

export default function PlayIcon({ videoType, onClick = () => {} }) {
  return (
    <>
      <Box
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        zIndex={2}
        sx={{ transform: "translate(-50%,-50%)", cursor: "pointer" }}
        onClick={onClick}
      >
        <Grow in={videoType === 0}>
          <IconButton sx={{ color: "neutral.1000" }}>
            <PlayIconSvg sx={{ width: "40px !important", height: "40px" }} />
          </IconButton>
        </Grow>
      </Box>
    </>
  );
}
