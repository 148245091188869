const ROUTE_LIST = {
  HOME: "/",
  RAISE: "/raise",
  STORY: "/story",
  WITHDRAWAL_HISTORY: "/banking",
  WITHDRAWAL_CONFIRM: "/withdrawal-confirm",
  RAISE_DASHBOARD: {
    route: "/campaign-dashboard/:id",
    redirect: (id) => "/campaign-dashboard/" + id,
  },
  FEED_WITHDRAWAL: { route: "/withdrawal/:feedId", redirect: "/withdrawal/" },
  WITHDRAWAL_DETAIL: {
    route: "/withdrawal-detail/:feedId",
    redirect: "/withdrawal-detail/",
  },
  FEED_CREATE: {
    BASE: "/create",
    RAISE: "/create/raise",
    STORY: "/create/story",
    DRAFT_TO_CAMPAIGNS: "/create/draft-to-campaign",
    WILD_ROUTE: "/create/:type",
  },
  FEED_DETAIL: "/feed/",
  DRAFT: {
    CREATE: "/draft/create",
  },
  ADD_ACH: "/banking/add",
  NOT_FOUND: "/404",
  COMMENT: "/feed/:id/comments",
  DONATION_HISTORY: "/feed/:id/donors",
  SETTINGS: "/settings",
  NOTIFICATION: "/notification",
};

export default ROUTE_LIST;
