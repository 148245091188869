import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@emotion/react";
import { alpha } from "@mui/material";

export default function DialogBox({
  openDialog,
  Content,
  setOpenDialog,
  close = true,
}) {
  const handleClose = () => {
    if (close) setOpenDialog(false);
  };
  const { palette } = useTheme();

  return (
    <Dialog
      open={Boolean(openDialog)}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        ".MuiDialog-paper": {
          background: "unset",
          boxShadow: "none",
          m: "15px",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: alpha(palette.black["1000"], 0.85),
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <Content handleClose={handleClose} />
    </Dialog>
  );
}
