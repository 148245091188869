import React from "react";
import Heading from "../ui/Heading";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, alpha } from "@mui/material";
import PasswordInput from "../ui/form/PasswordInput";
import BackButtonLayout from "../ui/BackButtonLayout";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";
import { useTheme } from "@emotion/react";

export default function ConfirmPasswordForm() {
  const navigate = useNavigate();
  const { apiCall } = useApi(true);
  const { setSnack } = useSnack();
  const { palette } = useTheme();
  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
  const schema = Yup.object({
    newPassword: Yup.string()
      .required("password is required.")
      .matches(
        /^\S*$/, // This regular expression ensures no white spaces
        "White spaces are not allowed in the password."
      )
      .matches(
        passwordRegExp,
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@$!%*?&)"
      ),
    confirmPassword: Yup.string()
      .required("confirm password is required.")
      .oneOf(
        [Yup.ref("newPassword")],
        "New Passwords and Confirm Password must match"
      )
      .min(8)
      .max(32),
  });
  const { darkMode } = useThemeStore();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  // update password
  const formHandler = async (values) => {
    try {
      try {
        let response = await apiCall({
          method: "post",
          url: "v2/accounts/change-password",
          data: {
            newPassword: values.newPassword,
            csPassword: values.confirmPassword,
          },
        });
        setSnack(response.data.message, "success");
        navigate("/edit-profile");
      } catch (error) {
        if (error.response.data.message) {
          setSnack(error.response.data.message, "error");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <BackButtonLayout
      titlePrefix={"Change"}
      pageTitle={"Password"}
      isTitleBold
      sx={{ maxWidth: { xs: "calc(100% - 30px)", md: "491px" } }}
    >
      <form onSubmit={handleSubmit(formHandler)}>
        <Heading>Enter your current password for verification</Heading>
        <Box position={"relative"}>
          <PasswordInput
            name="newPassword"
            register={register}
            errors={formState.errors}
            placeholder="Confirm Your Password"
            inputLabel="New Password"
          />
        </Box>

        <Box position={"relative"}>
          <PasswordInput
            name="confirmPassword"
            register={register}
            errors={formState.errors}
            placeholder="Confirm Your Password"
            inputLabel="Confirm Password"
          />
        </Box>
        <ButtonWithLoading
          color="primary"
          disabled={formState.isSubmitting}
          isLoading={formState.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            marginTop: "25px",
            paddingBlock: "15px",
            textTransform: "capitalize",
            "&.Mui-disabled": {
              color: darkMode
                ? alpha(palette.white["1000"], 0.7)
                : "white.1000",
              backgroundColor: darkMode
                ? "primary.disabledDark"
                : "primary.disabledLight",
            },
          }}
        >
          Save
        </ButtonWithLoading>
      </form>
    </BackButtonLayout>
  );
}
