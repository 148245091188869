import { createSvgIcon } from "@mui/material";

const CreateVideoIcon = createSvgIcon(
  <svg>
    <g id="Iconly/Bold/Video">
      <g id="Video">
        <path
          id="Video_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.0916 3.75H9.91831C11.9367 3.75 13.3458 5.14086 13.3458 7.13409V12.8659C13.3458 14.8591 11.9367 16.25 9.91831 16.25H5.0916C3.07324 16.25 1.66406 14.8591 1.66406 12.8659V7.13409C1.66406 5.14086 3.07324 3.75 5.0916 3.75ZM16.6313 5.73232C16.9971 5.5462 17.4263 5.56566 17.7754 5.78562C18.1246 6.00474 18.333 6.38546 18.333 6.8017V13.1985C18.333 13.6156 18.1246 13.9954 17.7754 14.2146C17.5846 14.3338 17.3713 14.3948 17.1563 14.3948C16.9771 14.3948 16.7979 14.3525 16.6304 14.267L15.3963 13.6443C14.9396 13.4125 14.6562 12.9472 14.6562 12.4303V7.56904C14.6562 7.05128 14.9396 6.58596 15.3963 6.35584L16.6313 5.73232Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

export default function VideoIcon(props) {
  return (
    <CreateVideoIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
      }}
      {...props}
    />
  );
}
