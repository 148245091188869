import React, { useEffect, useState } from "react";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import {
  Box,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";

import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";
import DonateGirlSVG from "../../icons/DonateGirlSVG";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import DonateGirlDarkSVG from "../../icons/DonateGirlDarkSVG";
import { useSignup } from "../../hooks/store/useSignup";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";

const AccountTypeForm = ({ openNextForm }) => {
  const { apiCall } = useApi(true);
  const { darkMode } = useThemeStore();
  const { typography, palette } = useTheme();
  const {
    setAccountType: chooseAccountType,
    accountType: accountTypeFromStore,
  } = useSignup();
  const [accountType, setAccountType] = useState(
    accountTypeFromStore ? accountTypeFromStore : false
  );
  const [openMenu, setOpenMenu] = useState(false);
  const [options, setOptions] = useState([
    {
      _id: "65cddfba7c4bd61528b85466",
      label: "Non-profit",
      value: "non-profit",
      description:
        "A 501(c)(3) nonprofit is an organization recognized by the IRS for its charitable, educational, or religious mission. It's exempt from federal income tax and often eligible to receive tax-deductible donations, operating primarily for the public benefit within IRS-defined guidelines.",
      createdAt: "2024-02-15T09:56:10.972Z",
      updatedAt: "2024-02-15T09:56:10.972Z",
      __v: 0,
      order: 1,
    },
    {
      _id: "65cddfba7c4bd61528b85464",
      label: "Organization",
      value: "organization",
      description:
        "An organization refers to a structured group or entity, which can encompass businesses, associations, clubs, or any group with defined goals, activities, and members. It can operate for various purposes, including commercial, social, or charitable objectives.",
      createdAt: "2024-02-15T09:56:10.971Z",
      updatedAt: "2024-02-15T09:56:10.971Z",
      __v: 0,
      order: 2,
    },
    {
      _id: "65cddfba7c4bd61528b85465",
      label: "Individual",
      value: "individual",
      description:
        "An individual fundraiser is someone seeking financial support for personal endeavors, charitable causes, or specific projects. This could include politicians raising campaign funds, individuals crowdfunding for personal trips, or supporters seeking contributions for charitable causes, advocating for support from a network of donors or supporters.",
      createdAt: "2024-02-15T09:56:10.972Z",
      updatedAt: "2024-02-15T09:56:10.972Z",
      __v: 0,
      order: 3,
    },
  ]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    // disableScrollLock: true,
    PaperProps: {
      style: {
        marginTop: "15px",
        borderRadius: "8px",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: darkMode ? palette.grey[550] : palette.white[1000],
        color: darkMode ? palette.white[1000] : palette.black[1000],
      },
    },
  };

  async function getAccountType() {
    try {
      let response = await apiCall({
        url: APIS.ACCOUNTTYPE.LIST,
        method: "get",
      });
      setOptions(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAccountType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAccountType(value);
  };

  // submit handler
  function submitFormHandler(e) {
    e.preventDefault();
    if (accountType) {
      chooseAccountType({ accountType });
      openNextForm("Registration");
    }
  }

  return (
    <>
      <Box
        component={"form"}
        onSubmit={(e) => submitFormHandler(e)}
        sx={{
          minWidth: { xs: "unset", md: "491px", lg: "551px" },
          paddingInline: { xs: "20px", md: "30px", lg: "60px" },
          paddingBlock: { xs: "20px", md: "30px", lg: "50px" },
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: { xs: "10px", lg: "18px" },
          marginTop: { xs: 0, lg: "50px" },
          boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
          height: { xs: "85vh", md: "unset" },
        }}
      >
        <Box
          sx={{
            maxWidth: "441px",
            margin: "0 auto",
            height: { xs: "100%", md: "unset" },
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              marginTop: { xs: "20px", md: "15px" },
              paddingInline: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: { xs: "100%", sm: "unset" },
            }}
          >
            {darkMode ? (
              <DonateGirlDarkSVG
                sx={{
                  width: { xs: "280px", sm: "347px", md: "341px" },
                  height: { xs: "150px", sm: "214.01px", md: "210.31px" },
                }}
              />
            ) : (
              <DonateGirlSVG
                sx={{
                  width: { xs: "280px", sm: "347px", md: "341px" },
                  height: { xs: "150px", sm: "214.01px", md: "210.31px" },
                }}
              />
            )}

            {/* dropDown */}

            <FormControl
              sx={{
                width: "100%",
                mt: "30px",
                flexGrow: { xs: 1, md: "unset" },
              }}
            >
              <InputLabel
                sx={{
                  "&.MuiFormLabel-root": {
                    backgroundColor: darkMode ? "bgDark.third" : "white.1000",
                    color: darkMode ? "white.1000" : "primary.main",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    textTransform: "capitalize",
                  },
                }}
                id="demo-multiple-chip-label"
              >
                account Type
              </InputLabel>
              <Select
                open={openMenu}
                onClose={() => setOpenMenu(false)}
                onOpen={() => setOpenMenu(true)}
                IconComponent={(props) => (
                  <KeyboardArrowDownIcon
                    sx={{
                      "&.MuiSelect-icon": {
                        color: darkMode ? "white.1000" : "black,1000",
                      },
                    }}
                    {...props}
                  />
                )}
                sx={{
                  "&.MuiOutlinedInput-root": {
                    padding: "0",
                    boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.06)",
                    backgroundColor: darkMode ? "transparent" : "white.1000",
                    color: darkMode ? "white.1000" : "black.1000",
                    border: "1px solid",
                    borderColor: darkMode ? "neutral.650" : "neutral.150",
                    borderRadius: "10px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "11px 20px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiPaper-root": {
                    marginTop: "20px !important",
                  },
                }}
                displayEmpty
                value={accountType}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (!accountType) {
                    return (
                      <Typography
                        component={"span"}
                        sx={{
                          color: darkMode
                            ? alpha(palette.white[1000], 0.6)
                            : alpha(palette.black[1000], 0.4),
                        }}
                      >
                        Select Account Type
                      </Typography>
                    );
                  }

                  return selected.value;
                }}
                MenuProps={MenuProps}
                // inputProps={{ "aria-label": "Without label" }}
              >
                {options.map(({ description, label, _id, value }) => (
                  <MenuItem
                    key={_id}
                    value={{ value, _id }}
                    selected
                    sx={{
                      "&:focus": {
                        color: "black !important",
                        backgroundColor: "transparent",
                        "& .item-option": {
                          color: darkMode
                            ? palette.white[1000]
                            : palette.black[1000],
                        },
                        "& .option-tooltip": {
                          color: darkMode ? "white.1000" : "black.1000",
                        },
                      },
                      "& .item-option": {
                        color: darkMode
                          ? palette.primary.disabledLight
                          : palette.primary.disabledLight,
                      },
                      "& .option-tooltip": {
                        color: darkMode
                          ? palette.primary.disabledLight
                          : palette.primary.disabledLight,
                      },
                      paddingInline: 0,
                      marginInline: "20px",
                      borderBottom: "1px solid",
                      borderColor: darkMode ? "neutral.650" : "grey.100",
                      borderRadius: "0px !important",
                      "&:last-child": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <Stack
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBlock: "7px",
                      }}
                    >
                      <Typography className="item-option">{label}</Typography>
                      <Tooltip
                        title={description}
                        placement="bottom-end"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: { xs: "12px", md: "14px" },
                              color: darkMode ? "white.1000" : "black.1000",
                              backgroundColor: darkMode
                                ? "black.250"
                                : "grey.1100",
                            },
                          },
                        }}
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-arrow": {
                              "&::before": {
                                backgroundColor: darkMode
                                  ? "black.250"
                                  : "grey.1100",
                              },
                            },
                          },
                        }}
                      >
                        <IconButton
                          className="option-tooltip"
                          sx={{
                            padding: 0,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <ErrorRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ButtonWithLoading
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={accountType ? false : true}
              sx={{
                fontFamily: typography.manrope,
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "152%",
                padding: "12px 20px",
                marginTop: { xs: "25px", lg: "30px" },
                borderRadius: "10px",
                textTransform: "capitalize",
                background: palette.gradients.main,
                "&.MuiButton-root": {
                  mt: { xs: "120px", md: "213px" },
                },
                "&.Mui-disabled": {
                  color: darkMode
                    ? alpha(palette.white["1000"], 0.7)
                    : "white.1000",
                  opacity: 0.5,
                },
              }}
            >
              Next
            </ButtonWithLoading>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AccountTypeForm;
