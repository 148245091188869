import React, { useCallback, useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import { APIS } from "../../../api/lists";
import { Button, Collapse } from "@mui/material";
import moment from "moment";
import SubCommentForm from "./SubCommentForm";
import SubCommentCard from "./SubCommentCard";
import { useAuth } from "../../../hooks/store/useAuth";
import CommentSkeleton from "./CommentSkeleton";
import { useTheme } from "@emotion/react";
import ReplyIcon from "../../../icons/ReplyIcon";

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "few seconds ago",
    ss: "%ss",
    m: "a minute ago",
    mm: "%d minute ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "a Day",
    dd: "%dd",
    M: "a month ago",
    MM: "%d month ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});

export default function SubComment({
  parentId,
  feedId,
  showSubComments,
  showSubCommentForm,
  setShowSubCommentForm,
  setRepliesCount,
  repliesCount,
  disableMainCommentCount = false,
}) {
  const { isLoggedIn } = useAuth();
  const { apiCall, isLoading } = useApi();
  const [subComments, setSubComments] = useState([]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [moreReplies, setMoreReplies] = useState(0);
  const [fetchMoreCommentsLoader, setFetchMoreCommentsLoader] = useState(false);
  const { palette } = useTheme();
  const getSubComments = useCallback(
    async (parentId) => {
      if (!parentId) {
        return;
      }
      try {
        const res = await apiCall({
          url: APIS.COMMENT.SINGLE(parentId),
          params: { page: currentPage },
        });
        let data = res.data?.data?.results;
        let pagination = res.data?.data?.pagination;
        setMoreReplies(pagination.records - currentPage * pagination.limit);
        setMaxPage(pagination?.pages || 1);
        if (currentPage === 1) {
          setSubComments(data[0]?.replies);
        } else {
          setSubComments((prevComments) => [
            ...prevComments,
            ...(data[0]?.replies || []),
          ]);
        }
        setFetchMoreCommentsLoader(false);
        return {
          subComment: pagination?.records || 0,
          comments: pagination?.totalCount || 0,
        };
      } catch (error) {
        console.log("error in SubComment");
      }
    },
    [apiCall, currentPage]
  );
  useEffect(() => {
    if (!parentId || !showSubComments) {
      return;
    }
    if (repliesCount > 0) getSubComments(parentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId, getSubComments, showSubComments]);

  return (
    <>
      <Collapse in={showSubCommentForm && isLoggedIn}>
        <SubCommentForm
          parentId={parentId}
          feedId={feedId}
          fetchComments={getSubComments}
          setShowSubCommentForm={setShowSubCommentForm}
          setRepliesCount={setRepliesCount}
          setCurrentPage={setCurrentPage}
          disableMainCommentCount={disableMainCommentCount}
        />
      </Collapse>

      {isLoading && <CommentSkeleton />}

      {subComments.map((comment, index) => (
        <SubCommentCard comment={comment} key={comment._id} feedId={feedId} />
      ))}

      {/* comment skeleton */}
      {fetchMoreCommentsLoader && <CommentSkeleton />}

      {!isLoading && maxPage > 1 && moreReplies > 1 && (
        <Button
          sx={{
            fontSize: "12px",
            backgroundImage: palette.gradients.main,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            MozBackgroundClip: "text",
            MozTextFillColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          startIcon={<ReplyIcon />}
          onClick={() => {
            setFetchMoreCommentsLoader(true);
            setCurrentPage((prevPage) => prevPage + 1);
          }}
        >
          view {moreReplies} more {moreReplies > 1 ? "replies" : "reply"}
        </Button>
      )}
    </>
  );
}
