import { Box } from "@mui/system";
import Layout from "../component/Layout";
import { useAuth } from "../hooks/store/useAuth";
import ProfileHeader from "../component/user/ProfileHeader";
import ProfileTabs from "../component/user/ProfileTabs";
import { useParams, useSearchParams } from "react-router-dom";
import useThemeStore from "../hooks/store/useThemeStore";
import { useState } from "react";

export const Profile = () => {
  const { userId: loggedInUserId } = useAuth();
  const { userId } = useParams();
  const { darkMode } = useThemeStore();
  const [query] = useSearchParams();
  const defaultTab = query.get("tab");
  const [userStats, setUserStats] = useState({
    feed: 0,
    followers: 0,
    followings: 0,
    donated: 0,
    raised: 0,
    isKyc: 0,
  });
  return (
    <Layout>
      <Box
        display={"grid"}
        gridTemplateColumns={"1fr"}
        sx={{
          maxWidth: "673px",
          width: { xs: "100%", sm: "calc(100% - 30px)", md: "100%" },
          margin: "0 auto",
          mt: { sm: "15px", md: "30px" },
          backgroundColor: darkMode ? "black.350" : "white.1000",
          position: "relative",
          borderRadius: { sm: "10px" },
          padding: { xs: "15px", md: "30px 30px 26px 30px" },
          zIndex: 10,
        }}
      >
        <ProfileHeader
          userId={userId ? userId : loggedInUserId}
          userStats={userStats}
          setUserStats={setUserStats}
        />
        <ProfileTabs
          defaultTab={userId ? "campaigns" : defaultTab}
          userId={userId ? userId : loggedInUserId}
        />
      </Box>
    </Layout>
  );
};
