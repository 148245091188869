import { createSvgIcon } from "@mui/material";
import React from "react";

export default function NoteIcon(props) {
  const CreateNoteIcon = createSvgIcon(
    <>
      <path
        d="M11.0948 12.5153H5.07812"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M11.0948 9.02604H5.07812"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.37396 5.54557H5.07812"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2567 1.28711C11.2567 1.28711 4.85917 1.29044 4.84917 1.29044C2.54917 1.30461 1.125 2.81794 1.125 5.12628V12.7896C1.125 15.1096 2.56 16.6288 4.88 16.6288C4.88 16.6288 11.2767 16.6263 11.2875 16.6263C13.5875 16.6121 15.0125 15.0979 15.0125 12.7896V5.12628C15.0125 2.80628 13.5767 1.28711 11.2567 1.28711Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  );
  return (
    <CreateNoteIcon
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
