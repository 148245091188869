import { createSvgIcon } from "@mui/material";

const CreateDollarIcon = createSvgIcon(
  <path
    d="M8.52081 17.0479C13.2217 17.0479 17.0479 13.2219 17.0479 8.52081C17.0479 3.81992 13.2219 0 8.52081 0C3.81977 0 0 3.81985 0 8.52081C0 13.2217 3.82016 17.0479 8.52081 17.0479ZM9.22603 9.12572H7.81802C6.68124 9.12572 5.7559 8.2003 5.7559 7.06359C5.7559 5.92681 6.68131 5.00209 7.81802 5.00209H7.91807L7.91838 4.32333C7.91838 3.98972 8.18843 3.71998 8.52172 3.71998C8.85533 3.71998 9.12507 3.99003 9.12507 4.32333V5.00209H10.6845C11.0181 5.00209 11.2879 5.27214 11.2879 5.60544C11.2879 5.93905 11.0178 6.20879 10.6845 6.20879H7.81803C7.34666 6.20879 6.96291 6.59196 6.96291 7.06336C6.96291 7.53473 7.34639 7.91848 7.81803 7.91848H9.22604C10.3628 7.91848 11.2882 8.84389 11.2882 9.9806C11.2882 11.1174 10.3627 12.0421 9.22604 12.0421H9.12538V12.7209C9.12538 13.0545 8.85533 13.3242 8.52204 13.3242C8.18843 13.3242 7.91869 13.0542 7.91869 12.7209V12.0421L6.35956 12.0424C6.02596 12.0424 5.75622 11.7724 5.75622 11.4391C5.75622 11.1054 6.02627 10.8357 6.35956 10.8357H9.22604C9.69741 10.8357 10.0812 10.4525 10.0812 9.98114C10.0812 9.50947 9.69737 9.12572 9.22603 9.12572Z"
    fill="white"
  />
);

export default function DollarIcon(props) {
  return (
    <CreateDollarIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
      }}
      {...props}
    />
  );
}
