import React from "react";
import DialoagBackground from "./DialoagBackground";
import CustomDialogContent from "./CustomDialogContent";
import { useExistDraft } from "../../hooks/store/useExistDraft";

export default function DraftToCampaignIdentityValidationDialog({
  open,
  setOpen,
  nextStep,
}) {
  const { resetExistDraftStore } = useExistDraft();
  return (
    <DialoagBackground
      open={open}
      setOpen={setOpen}
      closeModal={() => {
        resetExistDraftStore();
        setOpen(false);
      }}
    >
      <CustomDialogContent
        heading="Verify these details first to Post Your Raise"
        description="
Before you start creating a video to fundraise for your nonprofit or cause, let's quickly validate your identity. This ensures a safe and successful raise for everyone. Ready to proceed?"
        firstButton={{
          text: "Cancel",
          onClick: () => {
            resetExistDraftStore();
            setOpen(false);
          },
        }}
        secondButton={{
          text: "Continue",
          onClick: () => {
            nextStep();
            setOpen(false);
          },
        }}
      ></CustomDialogContent>
    </DialoagBackground>
  );
}
