import { createSvgIcon } from "@mui/material";
import React from "react";

export default function InfoIcon(props) {
  const CreateInfoIcon = createSvgIcon(
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6091 3.28809H6.3849C3.8674 3.28809 2.28906 5.07059 2.28906 7.59309V14.3998C2.28906 16.9223 3.8599 18.7048 6.3849 18.7048H13.6082C16.1341 18.7048 17.7057 16.9223 17.7057 14.3998V7.59309C17.7057 5.07059 16.1341 3.28809 13.6091 3.28809Z"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99479 14.3294V10.9961"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M9.99062 7.83366H9.99896"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
    </>
  );
  return (
    <CreateInfoIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
