import React from "react";
import { Box, alpha } from "@mui/material";

import ScrollToTop from "../component/ScrollToTop";
import DynamicMeta from "../utils/DynamicMeta";
import useThemeStore from "../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import BG from "../assets/forgotPasswordBg.png";
// import PloomLogo from "../icons/PloomLogo";
// import GetMobileNumber from "../component/forgoPasswordForms/GetMobileNumber";
// import OtpVerifyForm from "../component/forgoPasswordForms/OtpVerifyForm";
// import NewPasswordForm from "../component/forgoPasswordForms/NewPaaswordForm";
import GetEmailAddress from "../component/forgoPasswordForms/GetEmailAddress";
import Navbar from "../component/navbar/Navbar";

export default function ForgotPassword() {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  // const [openForm, setOpenForm] = useState("EnterMobile"); //1.EnterMobile 2.OTP  3.NewPassword
  // const [mobile, setMobile] = useState("");

  return (
    <>
      <DynamicMeta title="Forgot Password | Ploom Social" />
      <ScrollToTop></ScrollToTop>

      <Navbar />

      <Box
        sx={{
          minHeight: "100vh",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          background: darkMode
            ? `${alpha(palette.black["1000"], 0.8)} url(${BG})`
            : `${palette.white["1000"]} url(${BG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* <Stack sx={{ display: { xs: "none", md: "block" } }}>
          <PloomLogo width="196" height="76" />
        </Stack> */}
        <GetEmailAddress />

        {/* {openForm === "EnterMobile" && (
          <GetMobileNumber setOpenForm={setOpenForm} setMobile={setMobile} />
        )}
        {openForm === "OTP" && (
          <OtpVerifyForm setOpenForm={setOpenForm} mobile={mobile} />
        )}
        {openForm === "NewPassword" && <NewPasswordForm mobile={mobile} />} */}
      </Box>
    </>
  );
}
