import { create } from "zustand";

export const useNotification = create((set) => ({
  unReadNotificationCount: 0,
  setUnReadNotificationCount(unReadNotificationCount) {
    set(() => ({
      unReadNotificationCount,
    }));
  },
}));
