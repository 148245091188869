import { createSvgIcon } from "@mui/material";
export default function AppleIcon(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_3036_12957)">
        <path
          className="appleIcon"
          d="M17.0285 12.3318C16.4234 9.28618 19.3142 7.49994 19.3142 7.49994C19.3142 7.49994 18.1879 5.87044 16.295 5.45123C14.403 5.03111 13.4561 5.55601 12.511 5.9497C11.565 6.34435 11.093 6.34435 11.093 6.34435C9.72786 6.34435 8.72992 4.89893 6.12895 5.55697C4.33707 6.00918 2.45074 8.07772 2.10992 10.5465C1.7691 13.0163 2.50457 16.1677 3.92259 18.6101C5.34061 21.0544 6.78511 21.973 7.81037 21.9994C8.83566 22.0249 9.85907 21.2649 11.093 21.0798C12.3279 20.8967 13.0879 21.5274 14.2991 21.8682C15.5057 22.2081 15.9287 21.8927 17.3184 20.7107C18.7118 19.5287 19.9732 16.1384 19.9732 16.1384C19.9732 16.1384 17.6337 15.3793 17.0285 12.3318ZM14.3492 3.48087C15.6898 2.1148 15.2357 0 15.2357 0C15.2357 0 13.3532 0.330444 12.0918 1.64272C10.8315 2.95596 11.0401 5.03015 11.0401 5.03015C11.0401 5.03015 13.0085 4.84606 14.3492 3.48087Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3036_12957">
          <rect width="22" height="22" fill={color} />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateChatIcon
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
