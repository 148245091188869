import { Box, ListItem, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function WithdrawalListItem({ date, status, amount }) {
  let statusColor = "";
  let statusTextColor = "";
  let statusText = "";
  const { darkMode } = useThemeStore();
  switch (status) {
    case "Pending":
    case "Created":
      statusColor = darkMode ? "#4F3F22" : "#FCEED4";
      statusTextColor = darkMode ? "#FBA500" : "#FBA500";
      statusText = "Pending";
      break;

    case "Failed":
      statusColor = darkMode ? "#5F2E2E" : "#FCD4D4";
      statusTextColor = darkMode ? "#FE3535" : "#F90000";
      statusText = "Failed";
      break;
    case "Completed":
      statusColor = darkMode ? "#39472E" : "#E5FCD4";
      statusTextColor = darkMode ? "#58CD01" : "#368000";
      statusText = "Success";
      break;
    default:
      break;
  }
  const textColor = darkMode ? "white.1000" : "black.1000";
  return (
    <ListItem
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBlock: "12px",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: 1.2,
          fontWeight: 500,
          color: textColor,
          width: { xs: "50%", ssm: "68%", md: "75%" },
          flexShrink: "0",
        }}
      >
        {moment(date).format("MMMM DD, YYYY")}
      </Typography>
      <Box
        sx={{
          display: "inline-flex",
          flexGrow: "1",
          gap: { xs: "15px", sm: "20px" },
          fontWeight: 500,
          color: textColor,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: statusTextColor,
            backgroundColor: statusColor,
            width: "76px",
            borderRadius: "5px",
            display: "grid",
            placeItems: "center",
            fontSize: "10px",
            lineHeight: 1.2,
            textTransform: "capitalize",
            fontWeight: 500,
            padding: "5px",
          }}
        >
          {statusText}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            lineHeight: 1.5,
            fontWeight: 500,
            color: textColor,
            flexGrowl: "1",
          }}
        >
          ${amount}
        </Typography>
      </Box>
    </ListItem>
  );
}
