import { createSvgIcon } from "@mui/material";
export default function DrawerBg(props) {
  let color = props.color;

  const DrawerBg = createSvgIcon(
    <>
      <circle cx="-16" cy="91" r="91" fill={color} fillOpacity="0.5" />
      <circle cx="-31.5" cy="159.5" r="93.5" fill={color} fillOpacity="0.25" />
    </>
  );
  return (
    <DrawerBg
      width="75"
      height="253"
      viewBox="0 0 75 253"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
