import { initializeApp } from "firebase/app";
import {
  OAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

// Initialize Firebase with your configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);

// Get the Auth instance and create a GoogleAuthProvider
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Function to sign in with Google
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    // Handle successful sign-in
    const user = result.user;
    const oauthToken = await user.getIdToken(); // Get the OAuth token
    return oauthToken;
  } catch (error) {
    // Handle errors
    console.error("Error signing in with Google:", error);
    throw error; // Rethrow the error to be handled by the caller if needed
  }
};

// code for apple
const AppleAuthProvider = new OAuthProvider("apple.com");
const signInWithApple = async () => {
  try {
    const result = await signInWithPopup(auth, AppleAuthProvider);
    // Handle successful sign-in
    const user = result.user;
    let idToken = await user.getIdToken();
    return idToken;
  } catch (error) {
    // Handle errors
    console.error("Error signing in with Google:", error);
    throw error; // Rethrow the error to be handled by the caller if needed
  }
};

export { signInWithGoogle, signInWithApple };
