import React from "react";

import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { List, Typography, alpha } from "@mui/material";
import WithdrawalListItem from "./WithdrawalListItem";

export default function FeedWithdrawalList({ withdrawals, isLoading }) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();

  return (
    <List
      sx={{
        "& li:not(:last-child)": {
          borderBottom:
            "1px solid " +
            (darkMode
              ? alpha(palette.white[1000], 0.08)
              : alpha(palette.black[1000], 0.25)),
        },
      }}
    >
      {withdrawals.map((withdrawal) => (
        <WithdrawalListItem
          key={withdrawal._id}
          date={withdrawal.createdAt}
          amount={withdrawal.withdrawalAmount || 0}
          status={withdrawal.status}
        />
      ))}
      {Boolean(withdrawals.length === 0 && !isLoading) && (
        <Typography
          textAlign={"center"}
          mt={"20px"}
          color={darkMode ? "white.1000" : "black.1000"}
        >
          No withdrawal history available !
        </Typography>
      )}
    </List>
  );
}
