import React, { useEffect, useState } from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import { Box, IconButton, Stack, Typography, alpha } from "@mui/material";
import CrossIcon from "../icons/CrossIcon";
import useThemeStore from "../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import "swiper/css";
import "swiper/css/pagination";
import SelectedFileContainer from "../component/draft/SelectedFileContainer.jsx";
import DraftRaiseForm from "../component/draft/DraftRaiseForm.jsx";
import Slider from "../component/draft/Slider.jsx";
import { useDraft } from "../hooks/store/useDraft.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useApi from "../hooks/useApi";
import moment from "moment";
import { useAuth } from "../hooks/store/useAuth";
import { useSnack } from "../hooks/store/useSnack";
import { APIS } from "../api/lists";
import ButtonWithLoading from "../component/ui/button/ButtonWithLoading";
import FileUploadingDialog from "../component/ui/FileUploadingDialog.jsx";
import DialogBox from "../component/Dialogbox/DialogBox.jsx";
import DeleteConfirmDilog from "../component/withdrawal/dialog/DeleteConfirmDilog.jsx";
import handleApiError from "../utils/handleApiError.js";
import { useExistDraft } from "../hooks/store/useExistDraft.js";

export default function CreateDraft() {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { draftObject, reset } = useDraft();
  const { resetExistDraftStore } = useExistDraft();
  const navigate = useNavigate();
  const { setSnack } = useSnack();
  const { apiCall } = useApi();
  const { apiCall: createDraftCall, isLoading: createDraftLoading } = useApi();
  const { apiCall: deleteDraftApi, isLoading: isDeleteDraaftLoading } =
    useApi();
  const { userId, isLoggedIn } = useAuth();
  const [uploadinProgress, setUploadinProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalFiles, setTotalFiles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [uploadError, setUploadError] = useState([]); //store index of current uploading file when error in comes
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [awsUploading, setAwsUploading] = useState(true);
  const [finalVideo, setFinalVideo] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [confirmDraftDeleteDialog, setConfirmDeleteDialog] = useState("");
  const [deleteDraftId, setDeleteDraftId] = useState("");

  // effect for authorization checks
  useEffect(() => {
    if (draftObject === undefined || !isLoggedIn) {
      return navigate("/");
    }

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftObject, navigate, isLoggedIn]);

  // effect for uploading in progress checks
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (uploadinProgress) {
        event.preventDefault();
        event.returnValue =
          "Are you sure you want to leave? Your upload is in progress. Leaving now may result in an incomplete upload. Do you want to continue and leave the page?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploadinProgress]);

  // upload file in aws
  const uploadFileInAWS = async (files) => {
    setUploadinProgress(true);
    setTotalFiles(files);
    setOpenDialog(true);
    setUploadError([]);
    const videoArray = [...uploadedVideos];
    try {
      for (let i = videoArray.length; i < files.length; i++) {
        try {
          setCurrentFileIndex(i);
          setUploadProgress(0);
          let res;
          const unique = Date.now() + Math.ceil(Math.random() * 1e9).toString(),
            isVideo = files[i].file.type.includes("video");
          res = await apiCall({
            url: isVideo
              ? "v3/get-signed-url"
              : "v3/get-signed-url/begs/" + unique + ".jpeg",
          });
          if (res.status === 200) {
            res = res.data.data;
            await axios({
              method: "put",
              url: res.url,
              headers: {
                "Content-Type": files[i].file.type,
              },
              onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
              data: files[i].file,
            });
            videoArray.push({
              videoLink: isVideo ? res.uuid : unique,
              thumbLink: isVideo ? res.uuid : unique,
              type: isVideo ? "video" : "image",
            });
            setUploadedVideos(videoArray);
          } else {
            throw Error("link can not get");
          }
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (index === i) {
                video.uploaded = true;
              }
              return video;
            })
          );
        } catch (error) {
          setUploadError((prevError) => [...prevError, i]);
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (!video.uploadError) {
                video.uploadError = index === i;
              }
              return video;
            })
          );
          console.log("error");
          continue;
        }
      }
      setAwsUploading(false);
    } catch (error) {
      setAwsUploading(false);
      throw error;
    }
    setFinalVideo(videoArray);
    return videoArray;
  };

  const createDraft = async () => {
    try {
      const data = {
        userId,
        title: formValues.title,
        textDescription: formValues?.postDescription,
        htmlDescription: formValues?.postDescription,
        goalDate: formValues.goalDate
          ? moment(formValues.goalDate).format("l")
          : undefined,
        goalAmount: formValues?.amount ? formValues?.amount : undefined,
        links: finalVideo,
        begType: formValues.amount ? 1 : 2,
      };
      const res = await createDraftCall({
        url: APIS.DRAFT.CREATE,
        data,
        method: "post",
      });
      setLoading(false);
      setUploadinProgress(false);
      setSnack(res.data.message, "success");
      navigate("/");
    } catch (error) {}
  };
  // draft raise handler
  async function draftRaiseHandler(values) {
    try {
      setLoading(true);
      setFormValues(values);
      try {
        const isDraftExist = await apiCall({
          url: APIS.DRAFT.CHECK,
          params: { limit: 1 },
        });
        if (isDraftExist.data.data.results.length > 0) {
          setConfirmDeleteDialog(true);
          setDeleteDraftId(isDraftExist.data.data.results[0]._id);
          setLoading(false);
          return;
        }
      } catch (error) {}
      await uploadFileInAWS(values.file);
    } catch (error) {
      console.log(error);
      setSnack(error.response.data.message, "error");
      setUploadinProgress(false);
      setLoading(false);
    }
  }

  const deleteDraft = async (id) => {
    try {
      const res = await deleteDraftApi({
        url: APIS.DRAFT.DELETE(id),
        method: "delete",
      });
      if (res.data.success === true) {
        setSnack(res.data.message);
        resetExistDraftStore();
      }
    } catch (error) {
      handleApiError(error, setSnack);
    }
  };

  // on reload alert
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? Your upload is in progress.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <BackButtonLayout pageTitle={"Save as a Draft"} isTitleBold>
      {draftObject && (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "15px",
            }}
          >
            <IconButton sx={{ p: 0 }} disabled={true}>
              <CrossIcon
                color={darkMode ? palette.white[1000] : palette.black[1000]}
              ></CrossIcon>
            </IconButton>
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                fontFamily: "Poppins",
                fontSize: { xs: "18px", md: "20px" },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              New Post
            </Typography>
          </Stack>
          <ButtonWithLoading
            onClick={() => draftRaiseHandler(draftObject)}
            isLoading={loading}
            variant="text"
            sx={{
              color: darkMode ? "white.1000" : "primary.main",
              fontFamily: "Poppins",
              fontSize: { xs: "16px", md: "18px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              "&.Mui-disabled": {
                color: darkMode ? alpha(palette.white["1000"], 0.5) : "",
              },
            }}
          >
            Save
          </ButtonWithLoading>
        </Stack>
      )}
      {draftObject && (
        <Box
          sx={{
            mt: "15px",
            display: "grid",
            gridTemplateColumns: { xs: "1fr" },
          }}
        >
          <Slider videos={draftObject?.file} begDetails={""} />
          <SelectedFileContainer
            AllFiles={draftObject?.file}
          ></SelectedFileContainer>
          {/* draft form */}
          <Box>
            <DraftRaiseForm begDetails={draftObject}></DraftRaiseForm>
          </Box>
        </Box>
      )}
      <FileUploadingDialog
        openDialog={openDialog}
        totalFiles={totalFiles}
        currentFileIndex={currentFileIndex}
        message={`Hold on a moment, we are creating your story`}
        uploadProgress={uploadProgress}
        createPost={createDraft}
        isLoading={createDraftLoading}
        setCurrentFileIndex={setCurrentFileIndex}
        setFinalVideo={setFinalVideo}
        setTotalFiles={setTotalFiles}
        setUploadProgress={setUploadProgress}
        uploadError={uploadError}
        title={
          "Please don’t close the tabs while uploading files, Otherwise System won’t be able to create a story."
        }
        awsUploading={awsUploading}
      ></FileUploadingDialog>
      <DialogBox
        Content={({ handleClose }) => (
          <DeleteConfirmDilog
            cancelCall={handleClose}
            heading="Delete Draft"
            description="Are you sure you want to continue ? You can loose your old draft."
            isLoading={isDeleteDraaftLoading}
            confirmCall={async () => {
              await deleteDraft(deleteDraftId);
              setConfirmDeleteDialog(false);
              await uploadFileInAWS(formValues.file);
            }}
          />
        )}
        setOpenDialog={setConfirmDeleteDialog}
        openDialog={confirmDraftDeleteDialog}
      />
    </BackButtonLayout>
  );
}
