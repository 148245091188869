import { Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MediaType from "../editRaise/MediaType";
import useThemeStore from "../../hooks/store/useThemeStore";

const Slider = ({ videos, begDetails }) => {
  const { darkMode } = useThemeStore();
  const [activeVideo, setActiveVideo] = useState(videos[0]);

  //  function for swiper onchange event
  const handleSlideChange = useCallback(
    (swiper) => {
      const activeIndex = swiper.activeIndex;
      setActiveVideo(videos[activeIndex]);
      sessionStorage.setItem("activeVideoId", videos[activeIndex]._id);
    },
    [videos]
  );
  return (
    <Box
      component={Swiper}
      spaceBetween={10}
      slidesPerView={1}
      onSlideChange={handleSlideChange}
      navigation
      pagination={{ clickable: true }}
      modules={[Pagination]}
      sx={{
        width: "100%",
        height: { xs: "260px", ss: "300px", md: "412px" },
        position: "relative",
        borderRadius: "15px",
      }}
    >
      {/* media type indicator image/video icon */}
      <MediaType
        videoType={
          String(activeVideo.file.type).slice(0, 5) === "image" ? 1 : 2
        }
      ></MediaType>
      {videos.map((video, index) => (
        <SwiperSlide key={index}>
          <Box
            sx={{
              position: "relative",
              display: "block",
              width: "100%",
              height: "100%",
              backgroundColor: darkMode ? "grey.1000" : "grey.100",
              "& span": {
                width: "100%",
                height: "100%",
              },
            }}
          >
            {String(video.file.type).slice(0, 5) === "image" ? (
              <Box
                component={LazyLoadImage}
                src={video.url}
                // placeholderSrc={wrapper}
                effect="blur"
                alt="Paella dish"
                threshold={200}
                width={"100%"}
                sx={{
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
                loading="lazy"
              />
            ) : (
              <Box
                component={"video"}
                src={video.url}
                alt={video.url}
                width={"100%"}
                sx={{
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center center",
                  backgroundColor: darkMode ? "grey.1000" : "grey.100",
                }}
                loading="lazy"
              />
            )}
          </Box>
        </SwiperSlide>
      ))}
    </Box>
  );
};

export default Slider;
