import { Box, Card, Skeleton } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";

const CardSkeleton = () => {
  const { darkMode } = useThemeStore();
  return (
    <Card
      sx={{
        mx: "auto",
        maxWidth: 450,
        backgroundColor: "transparent",
        width: "100%",
        padding: "8px",
      }}
    >
      <Skeleton
        sx={{
          height: { md: "197px", xs: "150px" },
          mb: 1,
          borderRadius: 1,
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
        }}
        animation="wave"
        variant="rectangular"
      />
      <Skeleton
        animation="wave"
        height={14}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        animation="wave"
        height={14}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        width="80%"
      />
      <Box sx={{ display: "flex", mt: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexGrow: "1",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Skeleton
            animation="wave"
            variant="circular"
            width={24}
            height={24}
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          />
          <Skeleton
            animation="wave"
            width={"25%"}
            height={14}
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          />
        </Box>
        <Skeleton
          animation={"wave"}
          height={"100%"}
          width={20}
          sx={{
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
          }}
        />
      </Box>
    </Card>
  );
};

export default CardSkeleton;
