import React from "react";
import DialogBackground from "./DialogBackground";

export default function DeleteConfirmDilog({
  isLoading = false,
  confirmCall,
  cancelCall,
  heading = "Delete Account",
  description = "Are you sure you want to delete this account?",
}) {
  return (
    <DialogBackground
      heading={heading}
      description={description}
      cancelCall={cancelCall}
      confirmCall={confirmCall}
      isLoading={isLoading}
      confirmLabel="Delete"
    />
  );
}
