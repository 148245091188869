import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

const steps = [
  { title: "Step 1", form: "step1" },
  { title: "Step 2", form: "step2" },
  { title: "Step 3", form: "step3" },
];

export default function KycStepper({ step, setOpenForm }) {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  function formAccessChecker(currentIndex) {
    return step >= currentIndex;
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        sx={{
          ".MuiStepConnector-line": {
            border: "1px dashed " + palette.grey[400],
          },
          ".Mui-completed .MuiStepConnector-line, & .MuiStepConnector-root.Mui-active .MuiStepConnector-line":
            {
              border: "1px solid " + palette.primary.main,
            },
        }}
        activeStep={step ? step : 0}
        alternativeLabel
      >
        {steps.map((label, index) => (
          <Step
            key={label.title}
            onClick={() => {
              let check = formAccessChecker(index);
              if (check) {
                setOpenForm(label.form);
              }
            }}
            sx={{
              cursor: formAccessChecker(index) ? "pointer" : "no-drop!imortant",
            }}
          >
            <StepLabel
              sx={{
                "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                  mt: "5px",
                },
                "& .MuiStepLabel-label.Mui-completed": {
                  color: darkMode ? "white.1000" : "black.1000 ",
                },
                "& .MuiStepLabel-label.Mui-disabled": {
                  color: "grey.400",
                },
                "& .MuiStepLabel-label": {
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "142% /* 18.46px */",
                  letterSpacing: "-0.408px",
                },
                "& .MuiStepLabel-label.Mui-active": {
                  color: darkMode ? "white.1000" : "black.1000 ",
                },
              }}
            >
              {label.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
