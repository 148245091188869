import { create } from "zustand";

export const useRedirect = create((set) => ({
  redirectUrl: undefined,
  setRedirectRoute(route) {
    set(() => ({
      redirectUrl: route,
    }));
  },
}));
