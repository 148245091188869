import { createSvgIcon } from "@mui/material";

export default function FileUploadIcon(props) {
  let CreateMenuIcon = createSvgIcon(
    <>
      <path
        d="M14.0468 7.09834C13.842 4.4934 11.6567 2.43634 9 2.43634C7.90655 2.43111 6.84121 2.78256 5.9655 3.43738C5.08979 4.09221 4.45147 5.01471 4.14731 6.06502C3.26533 6.37191 2.50062 6.94549 1.95912 7.7063C1.41761 8.46711 1.12611 9.37749 1.125 10.3113C1.125 12.7925 3.14381 14.8113 5.625 14.8113H12.9375C15.1088 14.8113 16.875 13.0451 16.875 10.8738C16.8772 10.0228 16.6024 9.19423 16.0922 8.51313C15.582 7.83204 14.8641 7.33544 14.0468 7.09834ZM11.7697 8.83927C11.6826 9.04909 11.4773 9.18634 11.25 9.18634H10.125V11.4363C10.125 11.7347 10.0065 12.0209 9.7955 12.2318C9.58452 12.4428 9.29837 12.5613 9 12.5613C8.70163 12.5613 8.41548 12.4428 8.2045 12.2318C7.99353 12.0209 7.875 11.7347 7.875 11.4363V9.18634H6.75C6.639 9.18578 6.53064 9.15246 6.43851 9.09057C6.34637 9.02867 6.27456 8.94094 6.23209 8.83839C6.18962 8.73584 6.17837 8.62303 6.19976 8.51412C6.22114 8.4052 6.27421 8.30502 6.35231 8.22615L8.60231 5.97615C8.65443 5.92373 8.7164 5.88214 8.78465 5.85375C8.8529 5.82537 8.92608 5.81075 9 5.81075C9.07392 5.81075 9.1471 5.82537 9.21535 5.85375C9.2836 5.88214 9.34557 5.92373 9.39769 5.97615L11.6477 8.22615C11.7264 8.30481 11.78 8.40505 11.8017 8.51419C11.8235 8.62333 11.8123 8.73646 11.7697 8.83927Z"
        fill={props.color}
      />
    </>
  );
  return (
    <CreateMenuIcon
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          //   width: "unset",
          //   height: "unset",
          background: "transparent",
        },
      }}
      {...props}
    />
  );
}
