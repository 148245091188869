async function generateDynamicLink(Id, title, imageUrl, profile = false) {
  const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  const dynamicLinksDomain = process.env.REACT_APP_DYNAMICLINK_PREFIX;
  const deepLinkUrl = process.env.REACT_APP_FIREBASE_DEEP_LINK_URL;
  const apiUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`;

  // Data for the dynamic link
  const dynamicLinkData = {
    dynamicLinkInfo: {
      domainUriPrefix: dynamicLinksDomain,
      link: profile
        ? `${deepLinkUrl}/profile/${Id}`
        : `${deepLinkUrl}/feed/${Id}`,
      // androidInfo: {
      //   androidPackageName: "com.ploom",
      // },
      // iosInfo: {
      //   iosBundleId: process.env.REACT_APP_BUNDLE_ID,
      // },
      socialMetaTagInfo: {
        socialTitle: title,
        socialImageLink: imageUrl,
      },
    },
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dynamicLinkData),
    });

    if (!response.ok) {
      throw new Error(`Error creating dynamic link: ${response.status}`);
    }

    const data = await response.json();

    return data.shortLink;
  } catch (error) {
    console.error("Error creating dynamic link:", error);
  }
}

export default generateDynamicLink;
