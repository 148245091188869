import React from "react";
import { Box, Typography, Button, Stack } from "@mui/material";

import ButtonWithLoading from "../component/ui/button/ButtonWithLoading";
// import useApi from "../hooks/useApi";
// import { useSnack } from "../hooks/store/useSnack";
import ScrollToTop from "../component/ScrollToTop";
import DynamicMeta from "../utils/DynamicMeta";
import { useTheme } from "@emotion/react";
import bgWhite from "../assets/bgWhite.svg";
import smallBg from "../assets/smallBg.svg";
import ploomLogo from "../assets/ploom.svg";
import Navbar from "../component/navbar/Navbar";
import { MuiOtpInput } from "mui-one-time-password-input";
import ClockIcon from "../icons/ClockIcon";

export default function OtpVarify() {
  // const { setSnack } = useSnack();
  const { typography, palette } = useTheme();
  // const { apiCall } = useApi(true);

  const [otp, setOtp] = React.useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  return (
    <>
      <DynamicMeta title="Register | Ploom Social" />
      <ScrollToTop></ScrollToTop>
      <Box sx={{ display: { xs: "block", lg: "none" } }}>
        <Navbar />
      </Box>
      <Box
        minHeight={"100vh"}
        sx={{
          display: { xs: "flex", lg: "grid" },
          alignItems: { xs: "flex-start", md: "center", lg: "center" },
          gridTemplateColumns: { xs: "1fr", lg: "1.2fr 1fr" },
          background: {
            xs: `url(${smallBg}) no-repeat fixed`,
            md: `#fff url(${bgWhite}) no-repeat fixed`,
          },
          backgroundPosition: { xs: "center", md: "right bottom" },
          backgroundSize: { xs: "cover", md: "auto 100% " },
          padding: { xs: "15px", md: "unset" },
          paddingLeft: { lg: "49px" },
        }}
      >
        <Box
          sx={{
            maxWidth: "780px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            borderRadius: "10px",
            gap: "30px",
            backgroundColor: "transparent",
          }}
        >
          {/* form container */}
          <Box
            sx={{
              marginTop: { xs: 0, lg: "45px" },
              display: "flex",
              flexDirection: "column",
              maxWidth: "571px",
              width: "100%",
              borderRadius: { xs: "10px", md: "16px" },
              marginBottom: { xs: 0, lg: "40px" },
            }}
          >
            {/* logo */}
            <Box
              sx={{
                display: { xs: "none", lg: "block" },
                width: "201px",
                height: "87px",
                margin: "0 auto",
                background: "transparent",
              }}
            >
              <img src={ploomLogo} alt="" width={"100%"} height={"100%"} />
            </Box>
            {/* form */}
            <Box
              component={"form"}
              sx={{
                paddingInline: { xs: "20px", md: "30px", lg: "60px" },
                paddingBlock: { xs: "20px", lg: "50px" },
                backgroundColor: "white.1000",
                borderRadius: { xs: "10px", lg: "18px" },
                marginTop: { xs: 0, lg: "50px" },
                boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
                height: { xs: "85vh", md: "unset" },
              }}
            >
              <Box
                sx={{
                  maxWidth: "431px",
                  margin: "0 auto",
                  height: { xs: "100%", md: "unset" },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* form headings */}
                <Typography
                  sx={{
                    fontFamily: typography.manrope,
                    fontSize: { xs: "26px", lg: "36px" },
                    fontWeight: { xs: 800, lg: 700 },
                    lineHeight: { xs: "40px", lg: "122%" },
                    letterSpacing: { xs: "-0.52px", md: "-0.72px" },
                  }}
                >
                  Verify Phone number
                </Typography>
                <Typography
                  sx={{
                    mt: "10px",
                    fontFamily: typography.manrope,
                    color: "#9D9D9D",
                    fontSize: { xs: "18px", lg: "16px" },
                    fontWeight: 400,
                    lineHeight: "152%",
                  }}
                >
                  A verification code has been sent on XXXX98. Enter the code
                  below.
                </Typography>

                <Box sx={{ marginTop: { xs: "20px", md: "15px" } }}>
                  <MuiOtpInput
                    value={otp}
                    length={6}
                    onChange={handleChange}
                    sx={{
                      //   "& .MuiOutlinedInput-root": {
                      //     border: "1px solid",
                      //     borderColor: "bgWhite.secondary",
                      //   },
                      "& .MuiOutlinedInput-input, & .MuiTelInput-TextField": {
                        width: {
                          xs: "35px",
                          ss: "45px",
                          sm: "50px",
                          md: "59px",
                        },
                        height: {
                          xs: "35px",
                          ss: "45px",
                          sm: "50px",
                          md: "59px",
                        },
                        padding: 0,
                        borderRadius: "8px",
                      },
                      "&.MuiOtpInput-Box": {
                        flexWrap: { xs: "wrap", md: "nowrap" },
                        justifyContent: {
                          xs: "flex-start",
                          ss: "space-between",
                          sm: "space-between",
                          md: "space-between",
                        },
                        gap: { xs: "10px", md: "15px" },
                      },
                    }}
                  />
                </Box>

                <Stack
                  sx={{
                    mt: { xs: "15px", md: "17px" },
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: { xs: "baseline", md: "center" },
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    display={"flex"}
                    flexDirection="row"
                    alignItems="center"
                    gap={"10px"}
                  >
                    <ClockIcon
                      color={palette.primary["main"]}
                      width="16px"
                      height="16px"
                    />
                    <Typography
                      sx={{
                        color: "primary.main",
                        fontFamily: typography.manrope,
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "146%",
                      }}
                    >
                      00:59
                    </Typography>
                  </Stack>

                  <Button
                    variant="text"
                    sx={{
                      textTransform: "unset",
                      fontFamily: typography.manrope,
                      fontSize: "14px",
                      fontWeight: 500,
                      opacity: 0.6,
                    }}
                  >
                    Resend OTP
                  </Button>
                </Stack>

                <ButtonWithLoading
                  color="primary"
                  //   disabled={formState.isSubmitting}
                  //   isLoading={formState.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    fontFamily: typography.manrope,
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "152%",
                    padding: "14px 12px",
                    marginTop: { xs: "25px", lg: "30px" },
                    borderRadius: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  Verify
                </ButtonWithLoading>
              </Box>
            </Box>
          </Box>

          {/* verticle text */}
          <Box
            sx={{
              maxHeight: "100vh",
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              gap: "105px",
              transform: "rotate(180deg)",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: " 64px",
                fontWeight: 800,
                lineHeight: "normal",
                textTransform: " capitalize",
                background: palette.gradients["main"],
                color: "transparent",
                backgroundClip: "text",
                opacity: 0.1,
                writingMode: "vertical-rl",
                textOrientation: "mixed",
              }}
            >
              Verify
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: " 64px",
                fontWeight: 800,
                lineHeight: "normal",
                textTransform: " capitalize",
                background: palette.gradients["main"],
                color: "transparent",
                backgroundClip: "text",
                opacity: 0.1,
                writingMode: "vertical-rl",
                textOrientation: "mixed",
              }}
            >
              Verify
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: " 64px",
                fontWeight: 800,
                lineHeight: "normal",
                textTransform: " capitalize",
                background: palette.gradients["main"],
                color: "transparent",
                backgroundClip: "text",
                opacity: 0.1,
                writingMode: "vertical-rl",
                textOrientation: "mixed",
              }}
            >
              Verify
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
