import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TabPanel } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import ProfileFeedCard from "../profilefeedcard/ProfileFeedCard";
import CardSkeleton from "../skeleton/CardSkeleton";
import useInfinite from "../../hooks/useInfinite";

const RaiseTab = ({ searchInput }) => {
  const { darkMode } = useThemeStore();

  const {
    results: feeds,
    page,
    hasMore,
    setHasMore,
    setPage,
    totalPages,
    isLoading,
  } = useInfinite({
    url: "v3/begs/search",
    method: "post",
    addUserId: false,
    limit: 12,
    data: { terms: searchInput },
  });
  useEffect(() => {
    setPage(1);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [setPage, searchInput]);
  return (
    <>
      <TabPanel
        value="1"
        color="primary.main"
        sx={{
          // height: "94%",
          overflow: "auto",
          padding: 0,
        }}
      >
        <InfiniteScroll
          dataLength={feeds.length}
          next={() => {
            if (page < totalPages) {
              setPage((prev) => prev + 1);
            } else {
              setHasMore(false);
            }
          }}
          hasMore={hasMore}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr 1fr",
                xsm: "1fr 1fr 1fr",
              },
              gap: { xs: "10px", md: "20px" },
              marginTop: "12px",
              padding: "0px",
            }}
          >
            {feeds.map((feed) => (
              <ProfileFeedCard ownProfile={false} feed={feed} key={feed._id} />
            ))}
            {isLoading && (
              <>
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
              </>
            )}
          </Box>
          {feeds.length === 0 && !isLoading && (
            <Typography
              textAlign={"center"}
              color={darkMode ? "white.1000" : "primary.main"}
              fontSize={{
                xs: "14px",
                md: "18px",
              }}
              mt={2}
            >
              Post not found
            </Typography>
          )}
        </InfiniteScroll>
      </TabPanel>
    </>
  );
};

export default RaiseTab;
