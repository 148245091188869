import React, {
  useCallback,
  useEffect,
  // useMemo,
  // useReducer,
  useState,
} from "react";
// import { useParams } from "react-router-dom";
// import useApi from "../hooks/useApi";
import "swiper/css";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../component/Layout";
// import MobileNavigation from "../component/navbar/MobileNavigation";
import DynamicMeta from "../utils/DynamicMeta";
import useApi from "../hooks/useApi";
// import CommentContainer from "../component/feed/feedDetailComponent/CommentContainer";
import DetailCardWithComment from "../component/feed/DetailCardWithComment";
// import { Button } from "@mui/material";
// import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import useThemeStore from "../hooks/store/useThemeStore";
import FeedTabs from "../component/feed/FeedTabs";
import { useAuth } from "../hooks/store/useAuth";
import FeedDetailSkeleton from "../component/skeleton/FeedDetailSkeleton";
import ShareAndDonationBtn from "../component/feed/feedDetailComponent/ShareAndDonateBtn";
import { useImmersivePage } from "../hooks/store/useImmersivePage";
import ROUTE_LIST from "../Routes/list";
// import { useAuth } from "../hooks/store/useAuth";
// import { useRedirect } from "../hooks/store/useRedirect";

export default function NewFeedDetails() {
  const { userId, isLoggedIn } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApi();
  const [pageLoading, setPageLoading] = useState(true);
  const { darkMode } = useThemeStore();
  const [openTab, setOpenTab] = useState();
  // const { setRedirectRoute } = useRedirect();

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     setRedirectRoute(`/feed/${params.id}`);
  //     navigate("/signin");
  //   }
  // }, []);

  // const { isLoggedIn, userId } = useAuth();

  // const [feed, setFeed] = useState();
  const {
    begDetails: feed,
    setBegDetails: setFeed,
    resetStore,
  } = useImmersivePage();

  // code for searchfeed in databse if don't exist in session storage
  const searchBeg = useCallback(async () => {
    try {
      const param = {};
      if (isLoggedIn) {
        param.cuserId = userId;
      }
      const searchFeedResult = await apiCall({
        url: `v3/begs/${params.id}`,
        params: param,
        method: "GET",
      });
      setFeed(searchFeedResult.data.data);
      setPageLoading(false);
    } catch (error) {
      navigate("/404");
    }
  }, [apiCall, setFeed, navigate, isLoggedIn, userId, params.id]);

  useEffect(() => {
    setPageLoading(true);
    searchBeg();
    return () => {
      resetStore();
    };
  }, [searchBeg, resetStore]);

  return (
    <Layout>
      {feed?._id && (
        <DynamicMeta
          title={feed?.title}
          description={feed?.htmlDescription}
          image={feed?.videos["0"]?.thumbLink}
          url={ROUTE_LIST.FEED_DETAIL + feed?._id}
        ></DynamicMeta>
      )}

      <Box
        maxWidth={1050}
        margin={"0 auto"}
        mb={{ xs: "16px", md: "30px" }}
        sx={{ paddingInline: { sm: "15px" }, position: "relative", zIndex: 2 }}
      >
        {/* <Button
          disableRipple={true}
          sx={{
            minWidth: "unset",
            backgroundColor: darkMode ? "grey.550" : "white.1000",
            mt: "20px",
            padding: "10px",
            borderRadius: "10px",
          }}
          onClick={() => {
            if (
              window.history.length <= 1 ||
              document.referrer === "https://ploom.page.link/"
            ) {
              navigate("/");
            } else {
              navigate(-1);
            }
          }}
        >
          <ArrowBackIosNewRoundedIcon
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              marginLeft: "0",
            }}
            fontSize="18px"
          />
        </Button> */}
        <Box
          mt={{ xs: 0, sm: "14px", md: "20px" }}
          display={"grid"}
          gridTemplateRows={{ xs: "1fr auto", sm: "1fr auto", md: "1fr" }}
          sx={{
            backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
            borderRadius: { sm: "10px" },
          }}
        >
          {pageLoading && <FeedDetailSkeleton />}
          {!pageLoading && feed?._id && (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                // maxWidth: { xs: "400px", sm: "440px", md: "1020px" },
                maxWidth: { xs: "100%", sm: "100%", md: "1020px" },
                backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
                margin: "0 auto",
                borderRadius: "10px",
              }}
            >
              <DetailCardWithComment
                key={feed?._id}
                id={feed?._id}
                last={feed?.length - 1}
                author={feed?.author}
                title={feed?.title}
                videos={feed?.videos}
                likeCount={feed?.likeNo}
                commentCount={feed?.comments}
                shareCount={feed?.shares}
                donorCount={feed?.donors}
                htmlDescription={
                  feed.htmlDescription
                    ? feed.htmlDescription
                    : feed.textDescription
                }
                daysRemaining={feed?.daysRemaining}
                goalAmount={feed?.goalAmount}
                amountRaised={feed?.amountRaised}
                activeFeed={1}
                isLiked={feed?.currentUserLike}
                begType={feed?.begType}
                status={feed?.status}
                endedDate={feed?.endedDate}
                saved={feed?.saved}
                setFeed={setFeed}
                setOpenTab={setOpenTab}
                shareLink={feed?.shareLink}
              />
            </Box>
          )}
          {feed?._id !== undefined && (
            <FeedTabs
              setFeed={setFeed}
              begId={params.id}
              title={feed?.title}
              openTab={openTab}
            />
          )}

          {/* sticky donation and share button */}
          {feed?._id && (
            <ShareAndDonationBtn
              daysRemaining={feed?.daysRemaining}
              author={feed?.author}
              id={feed?._id}
              status={feed?.status}
              begType={feed?.begType}
              title={feed?.title}
              imageUrl={feed?.videos[0]?.thumbLink}
              endedDate={feed?.endedDate}
              description={feed?.htmlDescription}
              goalAmount={feed?.goalAmount}
              amountRaised={feed?.amountRaised}
              shareLink={feed?.shareLink}
            ></ShareAndDonationBtn>
          )}
          {/* <MobileNavigation /> */}
        </Box>
      </Box>
    </Layout>
  );
}
