import { create } from "zustand";

export const useUserVerification = create((set) => ({
  emailVerification: false,
  mobileVerification: false,
  kyc: 0,

  setKycStatus: (value) => {
    set({
      kyc: value,
    });
  },

  setUserVerification: (email, mobile, kyc) => {
    set({
      emailVerification: email,
      mobileVerification: mobile,
      kyc: kyc,
    });
  },
}));
