import { Avatar, Box, Stack, Typography, alpha } from "@mui/material";
import React, { useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import VerifiedIcon from "../../icons/VerifiedIcon";
import { useAllModal } from "../../hooks/store/useAllModal";

const NotificationItem = ({ data, markAllAsRead = false, close }) => {
  moment.locale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "few seconds ago",
      ss: "%ss",
      m: "a minute ago",
      mm: "%d minute ago",
      h: "an hour ago",
      hh: "%d hours ago",
      d: "a Day",
      dd: "%dd",
      M: "a month ago",
      MM: "%d month ago",
      y: "a year ago",
      yy: "%d years ago",
    },
  });
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { apiCall } = useApi();
  const { setOpenKycDialog } = useAllModal();
  const [isRead, setIsRead] = useState(data.isRead ? data.isRead : false);
  const navigate = useNavigate();
  let ploomNotification = ["WITHDRAW"];
  let navigateUrl = "/";
  switch (data.notificationType) {
    case "FOLLOW":
      navigateUrl = `/profile/${data.sender?._id}`;
      break;
    case "COMMENT":
      navigateUrl = `/feed/${data?.begId}/comments`;
      break;
    case "CHIPPIN":
      navigateUrl = `/feed/${data?.begId}/donors`;
      break;
    case "BEG":
      navigateUrl = `/feed/${data?.begId}`;
      break;
    case "LIKE":
      navigateUrl = `/feed/${data?.begId}`;
      break;
    case "SHARED":
      navigateUrl = `/feed/${data?.begId}`;
      break;
    case "RAISE END":
      navigateUrl = `/feed/${data?.begId}`;
      break;
    case "KYC":
      navigateUrl = `/profile`;
      break;
    case "WITHDRAW":
      navigateUrl = `/withdrawal/${data?.begId}?back=withdrawalDetail`;
      break;
    default:
  }

  function markAsRead() {
    setIsRead(true);
    close(true);
    apiCall({ url: APIS.NOTIFICATION.READ(data?._id), method: "PATCH" });
  }
  return (
    <Box
      sx={{
        mt: "10px",
        padding: "10px",
        border: isRead || markAllAsRead ? "1px solid" : "none",
        borderColor: darkMode ? "black.250" : "bgWhite.secondary",
        borderRadius: "10px",
        cursor: "pointer",
        backgroundColor:
          isRead || markAllAsRead
            ? "transparent"
            : darkMode
            ? alpha(palette.grey[650], 0.1)
            : alpha(palette.primary.main, 0.1),
      }}
      onClick={() => {
        markAsRead();
        navigate(navigateUrl);
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <Avatar
            src={
              ploomNotification.includes(data.notificationType)
                ? "/ploom.png"
                : data?.sender?.profileImage
            }
            sx={{
              background: ploomNotification.includes(data.notificationType)
                ? "transparent"
                : darkMode
                ? palette.grey[550]
                : palette.gradients.main,
            }}
          ></Avatar>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "hidden",
            }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
              <Typography
                sx={{
                  minWidth: "30px",
                  color: darkMode ? "white.1000" : "black.1000",
                  fontFamily: "Poppins",
                  fontSize: { xs: "12px", sm: "14px" },
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {data.sender
                  ? data.sender.orgName
                    ? data.sender.orgName
                    : data?.sender?.firstName + " " + data?.sender?.lastName
                  : ploomNotification.includes(data.notificationType)
                  ? "PloomSocial"
                  : "anonymous User"}
              </Typography>
              {data?.sender?.isKyc === 1 && (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "7px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenKycDialog(true, data?.sender?.verifiedAt);
                  }}
                >
                  <VerifiedIcon id={"verifiedIcon"}></VerifiedIcon>
                </Stack>
              )}
            </Stack>
            <Typography
              sx={{
                color: darkMode
                  ? "primary.disabledLight"
                  : "primary.disabledLight",
                fontFamily: "Poppins",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {data?.sender?.username}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            alignSelf: "baseline",
          }}
        >
          <Typography
            sx={{
              color: darkMode
                ? "primary.disabledLight"
                : "primary.disabledLight",
              fontFamily: "Poppins",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              alignSelf: "baseline",
              textWrap: "nowrap",
            }}
          >
            {moment(data.createdAt).fromNow()}
          </Typography>
          {!(isRead || markAllAsRead) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "11px",
                height: "11px",
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "50%",
              }}
            >
              <Box
                sx={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "primary.main",
                  borderRadius: "50%",
                }}
              ></Box>
            </Box>
          )}
        </Stack>
      </Box>
      <Typography
        sx={{
          mt: "6px",
          maxWidth: "500px",
          color: darkMode ? alpha(palette.white[1000], 0.6) : "black.350",
          fontFamily: "Poppins",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "15px",
        }}
      >
        {data?.message}
      </Typography>
    </Box>
  );
};

export default NotificationItem;
