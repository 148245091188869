import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";
import useThemeStore from "../../../hooks/store/useThemeStore";

const EditFormSkeleton = () => {
  const { darkMode } = useThemeStore();
  return (
    <Box
      display={"grid"}
      gridTemplateColumns={{ xs: "1fr", lg: "1fr 1fr" }}
      width={"100%"}
      gap="24px"
    >
      <Stack>
        <Skeleton
          sx={{
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            borderRadius: "12px",
            height: { xs: 250, md: 328 },
          }}
          variant="rectangular"
        />
        <Stack
          sx={{
            mt: 2,
            width: "100%",
            gap: "15px",
            display: "flex",
            alignItems: "Center",
            justifyContent: "Center",
            flexDirection: "row",
          }}
        >
          <Skeleton
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              borderRadius: "6px",
              height: { xs: 80, md: 120 },
              width: "100%",
            }}
            variant="rectangular"
          />
          <Skeleton
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              borderRadius: "6px",
              height: { xs: 80, md: 120 },
              width: "100%",
            }}
            variant="rectangular"
          />
          <Skeleton
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              borderRadius: "6px",
              height: { xs: 80, md: 120 },
              width: "100%",
            }}
            variant="rectangular"
          />
          <Skeleton
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              borderRadius: "6px",
              height: { xs: 80, md: 120 },
              width: "100%",
            }}
            variant="rectangular"
          />
        </Stack>
      </Stack>
      <Stack
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        gap="12px"
        width={"100%"}
        maxHeight={"328px"}
      >
        <Skeleton
          sx={{
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            borderRadius: "8px",
            width: "100%",
          }}
          height={56}
          variant="rectangular"
        />
        <Skeleton
          sx={{
            mt: "15px",
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            borderRadius: "8px",
            width: "100%",
          }}
          height={174}
          variant="rectangular"
        />
        <Skeleton
          sx={{
            mt: "15px",
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            borderRadius: "8px",
            width: "100%",
          }}
          height={40}
          variant="rectangular"
        />
      </Stack>
    </Box>
  );
};

export default EditFormSkeleton;
