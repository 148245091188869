import React from "react";
import DialogBackground from "../withdrawal/dialog/DialogBackground";
import { Box, Button, Stack, alpha } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import CheckIcon from "../../icons/CheckIcon";

export default function SuccessDialog({
  cancelCall,
  message,
  autoClose = false,
}) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <DialogBackground
      Icon={() => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: "25px",
          }}
        >
          <Stack
            sx={{
              boxShadow: `0 0 0 12px ${alpha(palette.primary.main, 0.3)}`,
              borderRadius: "50%",
            }}
          >
            <CheckIcon
              color="primary.main"
              fontSize={{ xs: "34px", md: "54px" }}
            />
          </Stack>
        </Box>
      )}
      description={message}
      hideAction
    >
      <Box sx={{ mx: "auto", maxWidth: { sm: "400px" }, mb: "20px" }}>
        {!autoClose && (
          <Button
            color="primary"
            variant="contained"
            sx={{
              p: "15px",
              fontSize: { xs: "14px", md: "16px" },
              lineHeight: 1.3,
              width: "100%",
              textTransform: "unset !important",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
            onClick={cancelCall}
          >
            Go back to Sign In
          </Button>
        )}
      </Box>
    </DialogBackground>
  );
}
