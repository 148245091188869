import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import CongratsSvgLight from "../CongratsSvgLight";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useNavigate } from "react-router-dom";

const HappyPath = ({ message }) => {
  const { darkMode } = useThemeStore();
  const { kycReason, setShowIdentityDrawer, redirectUrl, nextMovement } =
    useAllModal(); // possible reasons :  1.raise 2.banking 3.deposit
  const navigate = useNavigate();
  let kycSuccessMessage = "";
  switch (kycReason) {
    case "raise":
      kycSuccessMessage = "Click continue to create campaign.";
      break;
    // case "story":
    //   kycSuccessMessage = "Click continue to create story.";
    //   break;
    case "draft-to-campaign":
      kycSuccessMessage = "Click continue to convert your draft into campaign.";
      break;
    case "banking":
      kycSuccessMessage = "Click continue to view your deposits.";
      break;
    case "deposit":
      kycSuccessMessage = "Click continue to deposit your funds";
      break;
    default:
      break;
  }
  return (
    <>
      <Box
        sx={{
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          minWidth: { xs: "100%", xsm: "440px", md: "620px" },
          p: 4,
          borderRadius: "12px",
        }}
      >
        <Stack sx={{ width: "70%", mx: "auto" }}>
          <CongratsSvgLight></CongratsSvgLight>
        </Stack>
        <Typography
          sx={{
            mt: "30px",
            color: "#148C00",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "26px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
          }}
        >
          Congratulations!
        </Typography>

        <Typography
          sx={{
            mt: "15px",
            color: darkMode ? "grey.900" : "black.350",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "-0.408px",
          }}
        >
          {"Your identity has been verified. " + kycSuccessMessage}
        </Typography>

        <Stack
          sx={{
            mt: "22px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "12px",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            sx={{ paddingBlock: "15px" }}
            onClick={() => {
              if (kycReason === "raise") {
                setShowIdentityDrawer(false);
              } else if (kycReason === "banking") {
                setShowIdentityDrawer(false);
                navigate(redirectUrl);
              } else if (kycReason === "draft-to-campaign") {
                setShowIdentityDrawer(false);
                nextMovement();
              } else {
                setShowIdentityDrawer(false);
                navigate(redirectUrl);
              }
            }}
          >
            {kycReason === "raise"
              ? "Create raise"
              : kycReason === "banking"
              ? "View your deposits"
              : kycReason === "draft-to-campaign"
              ? "Post your campaign"
              : kycReason === "deposit"
              ? "Deposit funds"
              : "Close"}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default HappyPath;
