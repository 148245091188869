import React from "react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { Button, Menu, MenuItem, alpha } from "@mui/material";
import CommentFlagIcon from "../../../icons/CommentFlagIcon";
import MoreIcon from "../../../icons/MoreIcon";
import { useAllModal } from "../../../hooks/store/useAllModal";
import { useAuth } from "../../../hooks/store/useAuth";
import useApi from "../../../hooks/useApi";
import { APIS } from "../../../api/lists";
import LoadingButton from "@mui/lab/LoadingButton";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useSnack } from "../../../hooks/store/useSnack";

export default function CommentToggleMenu({
  commentId,
  feedId,
  begOwnerId,
  isPinned,
  setIsPinned,
}) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { setShowReportDrawer } = useAllModal();
  const { userId: loggedInUserId } = useAuth();
  const { apiCall, isLoading } = useApi();
  const { setSnack } = useSnack();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const reportComment = async () => {
    setShowReportDrawer(true, {
      itemType: "comment",
      id: commentId,
    });
    handleClose();
  };

  const pinComment = async () => {
    try {
      let res = await apiCall({
        url: APIS.COMMENT.PIN(commentId),
        method: isPinned ? "DELETE" : "POST",
      });
      handleClose();
      setSnack(res.data.message);
      return setIsPinned(!isPinned);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {" "}
      <Button
        sx={{
          p: "5px",
          backgroundColor: darkMode ? "grey.550" : "white.1000",
          borderRadius: "5px !important",
          border: darkMode ? "none" : "1px solid " + palette.grey[1100],
          minWidth: "unset",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={handleClick}
      >
        <MoreIcon
          height="18px"
          width="18px"
          color={darkMode ? palette.white[1000] : palette.black[1000]}
        ></MoreIcon>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          overflow: "visible",
          sx: {
            overflow: "visible",
            marginTop: "10px",
          },
        }}
        MenuListProps={{
          sx: {
            backgroundColor: darkMode ? "grey.550" : "white.1000",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: "30px",
              borderBottom: "10px solid",
              borderBottomColor: darkMode ? "grey.550" : "white.1000",
              borderRight: "10px solid transparent",
              borderLeft: "10px solid transparent",
              transform: "translateY(-100%)",
            },
          },
        }}
      >
        <MenuItem
          onClick={reportComment}
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <CommentFlagIcon height={"18px"} mr="12px" width="15px" /> Report
        </MenuItem>
        {begOwnerId === loggedInUserId && (
          <LoadingButton
            fullWidth
            loading={isLoading}
            onClick={pinComment}
            sx={{
              padding: "6px 16px",
              display: "flex",
              justifyContent: "start",
              color: darkMode ? "white.1000" : "black.1000",
              "&:hover": {
                backgroundColor: "transparent",
              },
              "& .MuiLoadingButton-loadingIndicator": {
                color: darkMode
                  ? alpha(palette.white[1000], 0.4)
                  : alpha(palette.black[1000], 0.4),
              },
            }}
            startIcon={
              !isLoading && (
                <PushPinIcon
                  sx={{
                    transform: "rotate(40deg)",
                    color: darkMode ? "white.1000" : "black.1000",
                  }}
                />
              )
            }
          >
            {isPinned ? "Unpin" : "Pin"}
          </LoadingButton>
        )}
      </Menu>
    </>
  );
}
