import { Box, Button, Divider, Stack, Typography, alpha } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";
import { useKyc } from "../../hooks/store/useKyc";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useSettings } from "../../hooks/store/useSettings";

const ContactUsDialog = ({ handleClose, msg = "" }) => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { reset } = useKyc();
  const { setShowIdentityDrawer } = useAllModal();
  const { settings } = useSettings();
  return (
    <>
      <Box
        sx={{
          minWidth: { md: "534px" },
          width: "100%",
          display: "grid",
          placeItems: "center",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "20px", md: "40px 40px 0 40px" },
          }}
        >
          <Stack
            sx={{
              pb: "15px",
              width: "100%",
              borderBottom: "1px solid",
              borderColor: darkMode
                ? "black.200"
                : alpha(palette.grey[100], 0.4),
            }}
          >
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                letterSpacing: "-0.408px",
              }}
            >
              Unverified Identity
            </Typography>
          </Stack>
          {msg === "" ? (
            <Typography
              sx={{
                mt: "20px",
                pb: { xs: "40px", md: "60px" },
                color: darkMode ? "grey.900" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: { xs: "12px", md: "14px" },
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                maxWidth: { xs: "271px", md: "310px" },
                mx: "auto",
              }}
            >
              You have failed your ID verification more than{" "}
              {settings.kyc_counts} times, please contact Ploom Support (
              <Stack
                target="_blank"
                to={`mailto:${settings.supportEmail}`}
                component={Link}
                sx={{
                  color: darkMode ? "white.1000" : "primary.main",
                  display: "inline",
                }}
              >
                {settings.supportEmail}
              </Stack>
              )
            </Typography>
          ) : (
            <Typography
              sx={{
                mt: "20px",
                pb: { xs: "40px", md: "60px" },
                color: darkMode ? "grey.900" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: { xs: "12px", md: "14px" },
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                maxWidth: { xs: "271px", md: "310px" },
                mx: "auto",
              }}
            >
              {msg}
            </Typography>
          )}
        </Box>

        <Divider
          light
          sx={{
            width: { xs: "100%", md: "calc(100% - 80px)" },
            background: darkMode ? "black.200" : alpha(palette.grey[100], 0.4),
          }}
        />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: { md: "10px 0 0px 0" },
          }}
        >
          <Button
            variant="text"
            fullWidth
            sx={{
              paddingBlock: "15px",
              maxWidth: "unset",
              color: darkMode ? "white.1000" : "black.1000",
              "&:hover": { background: "none" },
            }}
            onClick={() => {
              handleClose();
              reset();
              setShowIdentityDrawer(false);
              navigate("/");
            }}
          >
            Cancel
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: darkMode
                ? "black.200"
                : alpha(palette.grey[100], 0.4),
              height: "35px",
              transform: "translateY(20%)",
            }}
          />
          <Button
            component={Link}
            to={`mailto:${settings.supportEmail}`}
            variant="text"
            fullWidth
            sx={{
              paddingBlock: "15px",
              maxWidth: "unset",
              color: darkMode ? "white.1000" : "black.1000",
              "&:hover": { background: "none" },
            }}
          >
            Contact Us
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default ContactUsDialog;
