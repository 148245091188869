import { Avatar, Box, Stack, alpha } from "@mui/material";
import React, { useState } from "react";
import useApi from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/store/useAuth";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSettings } from "../../../hooks/store/useSettings";

export default function CommentModalForm({ feedId, fetchComments, setFeed }) {
  const { userId, user } = useAuth();
  const { darkMode } = useThemeStore();
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(false);
  const { settings } = useSettings();

  const { apiCall } = useApi();
  const { palette } = useTheme();

  const postComment = async (e) => {
    try {
      e.preventDefault();
      if (loading) {
        return;
      }
      setLoading(true);
      await apiCall({
        url: "v3/comments",
        method: "post",
        data: {
          userId,
          textDescription: commentText,
          begId: feedId,
          htmlDescription: commentText,
        },
      });

      setCommentText("");
      const comments = await fetchComments(true);
      setFeed((prev) => ({ ...prev, comments }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Box
      component={"form"}
      onSubmit={postComment}
      sx={{
        position: "sticky",
        bottom: 0,
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        placeItems: "center",
        left: "0px",
        right: "0px",
        padding: "15px",
        backgroundColor: darkMode ? "grey.550" : "white.1000",
        borderRadius: "10px",
        boxShadow: "0px -4px 30px 0px rgba(0, 0, 0, 0.06)",
        // margin: { sm: "15px" },
        gap: "10px",
      }}
    >
      <Box
        position={"relative"}
        display={"grid"}
        sx={{
          placeItems: "center",
        }}
      >
        <Avatar
          sx={{
            width: { xs: "30px", sm: "40px" },
            height: { xs: "30px", sm: "40px" },
            backgroundColor: "primary.main",
          }}
          src={user.profileImage}
        ></Avatar>
      </Box>
      <Box
        width={"100%"}
        flexGrow={1}
        position={"sticky"}
        bottom={0}
        sx={{
          "& ::placeholder": {
            fontSize: { xs: "12px", md: "14px" },
            color: darkMode
              ? alpha(palette.white["800"], 0.5)
              : alpha(palette.grey["550"], 0.5),
          },
        }}
      >
        <Stack
          component={"input"}
          type="text"
          name="comment"
          required
          minLength={settings?.mainCommentMinLength}
          maxLength={settings?.mainCommentMaxLength}
          placeholder="Add a comment..."
          value={commentText}
          onChange={(e) => {
            setCommentText(e.target.value);
          }}
          sx={{
            width: "100%",
            height: "100%",
            fontSize: { xs: "12px", md: "14px" },
            backgroundColor: darkMode
              ? palette.grey["550"]
              : palette.white["1000"],
            fontFamily: "poppins",
            fontWeight: 500,
            lineHeight: "16px",
            color: darkMode ? palette.white["1000"] : palette.black["1000"],
            padding: "10px 16px",
            outline: "none",
            borderRadius: "30px",
            border: "1px solid",
            borderColor: darkMode ? "neutral.650" : "neutral.150",
          }}
        />
      </Box>

      <LoadingButton
        loading={loading}
        variant="text"
        type="submit"
        sx={{
          padding: 0,
          color: darkMode ? "white.1000" : "primary.main",
          minWidth: "unset",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "16px /* 114.286% */",
          "& .MuiLoadingButton-loadingIndicator ": {
            color: darkMode
              ? alpha(palette.white["1000"], 0.7)
              : alpha(palette.black["1000"], 0.2),
          },
        }}
      >
        Post
      </LoadingButton>
    </Box>
  );
}
