import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Heart from "../../icons/Heart";
import Typography from "@mui/material/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageIcon from "../../icons/ImageIcon";
import PlayIcon from "../../icons/PlayIcon";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  alpha,
  useMediaQuery,
} from "@mui/material";
import DollarIcon from "../../icons/DollarIcon";
import { useAuth } from "../../hooks/store/useAuth";
import { useSnack } from "../../hooks/store/useSnack";
import LikeFillIcon from "../../icons/LikeFillIcon";
import useApi from "../../hooks/useApi";
import { Link } from "react-router-dom";
import { formatNumberCount } from "../../utils/NumberFormater";
import { useTheme } from "@emotion/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { theme } from "../../theme";
import useThemeStore from "../../hooks/store/useThemeStore";
import ContentFlagDialog from "../editRaise/ContentFlagDialog";
import DialogBox from "../Dialogbox/DialogBox";
import ROUTE_LIST from "../../Routes/list";
import { socket } from "../../socket";
import MediaInfoLayer from "../mediaInfoDialog/MediaInfoLayer";

function ProfileFeedCardComponent({
  feed,
  ownProfile = false,
  bookmark = false,
  removeBookmark,
  isLoading: removeBookmarkLoading = false,
}) {
  // const { userId } = useAuth();
  const { setSnack } = useSnack();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    _id,
    videos: feedVideo,
    likeNo,
    likeCount: totalLike,
    author,
    // htmlDescription,
    begType,
    currentUserLike,
    title,
  } = feed;
  let { palette } = useTheme();
  let { apiCall, isLoading } = useApi();
  let { isLoggedIn } = useAuth();
  // states
  const [videos, setVideos] = React.useState(feedVideo || []);
  const [videoType, setVideoType] = React.useState(videos[0].type);
  const [likeCount, setLikeCount] = React.useState(likeNo ? likeNo : totalLike);
  const [isCurrentUserLike, setIsCurrentUserLike] =
    React.useState(currentUserLike);
  const [openDialog, setOpenDialog] = React.useState(false);
  // end states

  React.useEffect(() => {
    setVideos(feedVideo);
  }, [feedVideo]);
  // const location = useLocation();
  const feedInteraction = "/feed/" + feed._id;
  const dashboard = ROUTE_LIST.RAISE_DASHBOARD.redirect(feed._id);
  const { darkMode } = useThemeStore();

  // like post
  async function likePost() {
    const res = await apiCall({
      url: `v3/videos/${_id}/like`,
      method: "post",
      data: {
        begId: _id,
      },
    });
    if (res.data.message === "Liked deleted.") {
      setLikeCount((prev) => prev - 1);
      setIsCurrentUserLike(false);
      setSnack("Like deleted", "success");
    } else {
      setLikeCount((prev) => prev + 1);
      setIsCurrentUserLike(true);
      setSnack("Like created", "success");
    }
  }

  const handleSlideChange = React.useCallback(
    (swiper) => {
      const activeIndex = swiper.activeIndex;
      setVideoType(videos[activeIndex].type);
    },
    [videos]
  );

  // raise socket
  // React.useEffect(() => {
  //   if (!ownProfile) {
  //     return;
  //   }
  //   socket.connect();

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [ownProfile]);

  // join user in room
  React.useEffect(() => {
    if (!ownProfile) {
      return;
    }
    // socket.emit("evntJoinUserToRoom", { userId });
  });

  //listen videos event
  React.useEffect(() => {
    if (!ownProfile) {
      return;
    }
    socket.on("evntUpdateVideo", (videoDetail) => {
      if (_id !== videoDetail.begId) {
        return;
      }

      setVideos((prevVideo) =>
        prevVideo.map((video) => {
          if (video._id === videoDetail.videoId) {
            video = videoDetail.video;
          }
          return video;
        })
      );
    });
  }, [ownProfile, _id]);
  return (
    <Card
      sx={{
        padding: "8px",
        border: "0px",
        backgroundColor: darkMode ? "grey.550" : "white.1000",
        boxShadow: {
          xsm: `0px 0px 5px 0px ${alpha(palette.black["1000"], 0.1)}`,
          xs: `0px 0px 30px 0px ${alpha(palette.black["1000"], 0.1)}`,
        },
        display: "flex",
        flexDirection: "column",
        "& .swiper-pagination-bullet": {
          width: "6px!important",
          height: "2px!important",
        },
        "& .swiper-pagination-bullet-active": {
          width: "13px!important",
          height: "2px !important",
        },
        ".swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet":
          {
            marginInline: "1.5px !important",
          },
      }}
    >
      <Box>
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="mySwiper"
          onSlideChange={handleSlideChange}
        >
          <Stack
            direction={"row"}
            gap={"7.5px"}
            sx={{
              position: "absolute",
              top: "7px",
              right: "7px",
              zIndex: "4",
            }}
          >
            {begType === 1 && (
              <Box fontSize={"16px"}>
                <DollarIcon></DollarIcon>
              </Box>
            )}
            {videoType === 1 ? (
              <Link
                style={{ fontSize: "16px" }}
                // to={ownProfile ? dashboard : feedInteraction}
                to={feedInteraction}
              >
                <ImageIcon></ImageIcon>
              </Link>
            ) : (
              <Link
                style={{ fontSize: "16px" }}
                // to={ownProfile ? dashboard : feedInteraction}
                to={feedInteraction}
              >
                <PlayIcon></PlayIcon>
              </Link>
            )}
          </Stack>

          {bookmark && (
            <Stack
              sx={{
                position: "absolute",
                bottom: "14px",
                right: "5px",
                width: "28px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                overflow: "hidden",
                border: "1px solid",
                borderColor: "error.main",
                background: alpha(palette.white["250"], 0.2),
                backdropFilter: "blur(20px)",
                zIndex: "100",
              }}
            >
              <IconButton
                onClick={() => {
                  removeBookmark(_id);
                }}
                aria-label="delete"
                sx={{
                  padding: "8px",
                  margin: 0,
                  color: "error.main",
                }}
              >
                {removeBookmarkLoading ? (
                  <CircularProgress
                    size={12}
                    sx={{
                      color: palette.white[1000],
                    }}
                  />
                ) : (
                  <DeleteOutlineOutlinedIcon sx={{ fontSize: "18px" }} />
                )}
              </IconButton>
            </Stack>
          )}

          {videos.map((media) => (
            <SwiperSlide key={media._id}>
              <Link
                to={ownProfile ? dashboard : feedInteraction}
                style={{
                  display: "block",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  <LazyLoadImage
                    src={media.thumbLink}
                    // placeholderSrc={loadingImage}
                    effect="blur"
                    alt="media.thumbLink"
                    threshold={200}
                    width="100%"
                    height={onlyMediumScreen ? "197px" : "150px"}
                    loading="lazy"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: "5px",
                    }}
                  />

                  <MediaInfoLayer
                    twoHate={media.twoHatflagged}
                    processingStatus={media.processingStatus}
                    adminFlag={media.isDelete}
                    mediaPoint={"xs"}
                  ></MediaInfoLayer>
                </Box>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <CardContent
        component={Link}
        // to={ownProfile ? dashboard : feedInteraction}
        to={feedInteraction}
        sx={{ padding: "0px", textDecoration: "none", flexGrow: "1" }}
      >
        <Typography
          variant="body2"
          color="black.150"
          sx={{
            padding: "0px",
            marginTop: "12px",
            fontSize: { xs: "12px", sm: "10px" },
            textOverflow: "ellipsis",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "2",
            whiteSpace: "normal",
            color: darkMode ? alpha(palette.white["1000"], 0.7) : "black.150",
            "&::first-letter": {
              textTransform: "capitalize",
            },
          }}
        >
          {title?.charAt(0).toUpperCase() + title?.slice(1)}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          marginTop: { xs: "10px", sm: "12px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0px",
          flexShrink: "1",
        }}
      >
        <Box
          display={"flex"}
          sx={{ flexGrow: 1, overflow: "hidden", gap: "5px" }}
          alignItems={"center"}
        >
          <Avatar
            alt={author.username}
            src={author.profileImage}
            sx={{
              width: { xs: 20, sm: 24 },
              height: { xs: 20, sm: 24 },
              backgroundColor: "primary.main",
              color: "white.1000",
            }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              color: darkMode ? "white.1000" : "black.1000",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {author.username}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            flexShrink: 0,
          }}
        >
          {isLoggedIn ? (
            <Button
              disabled
              onClick={likePost}
              variant="text"
              sx={{
                fontSize: "9px",
                color: darkMode
                  ? alpha(palette.white["1000"], 0.5)
                  : "black.300",
                cursor: "pointer",
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? "none" : "",
                minWidth: "auto",
                gap: "3px",
                padding: "0px",
                "& .MuiButton-startIcon": {
                  mx: "0",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&.Mui-disabled": {
                  color: darkMode
                    ? alpha(palette.white["1000"], 0.5)
                    : "black.300",
                },
              }}
              startIcon={
                isCurrentUserLike ? (
                  <LikeFillIcon />
                ) : (
                  <Heart
                    color={
                      darkMode
                        ? alpha(palette.white["1000"], 0.5)
                        : palette.black["1000"]
                    }
                  />
                )
              }
            >
              {formatNumberCount(likeCount ? likeCount : 0)}
            </Button>
          ) : (
            <Button
              disabled
              onClick={() => {
                setSnack("logged in needed!", "warning");
              }}
              variant="text"
              sx={{
                fontSize: "9px",
                color: darkMode
                  ? alpha(palette.white["1000"], 0.5)
                  : "black.300",
                cursor: "no-drop",
                minWidth: "auto",
                gap: "3px",
                padding: "0px",
                "& .MuiButton-startIcon": {
                  mx: "0",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&.Mui-disabled": {
                  color: darkMode
                    ? alpha(palette.white["1000"], 0.5)
                    : "black.300",
                },
              }}
              startIcon={
                <Heart
                  color={
                    darkMode
                      ? alpha(palette.white["1000"], 0.5)
                      : palette.black["1000"]
                  }
                />
              }
            >
              {formatNumberCount(likeCount ? likeCount : 0)}
            </Button>
          )}
        </Box>
      </CardActions>

      <DialogBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        Content={({ handleClose }) => (
          <ContentFlagDialog handleClose={() => setOpenDialog(false)} />
        )}
      ></DialogBox>
    </Card>
  );
}
const ProfileFeedCard = React.memo(ProfileFeedCardComponent);
export default ProfileFeedCard;
