import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React from "react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import CloseIcon from "../../../icons/CloseIcon";

const TotalAmountDialog = ({
  handleClose,
  totalRaise,
  platformFeesPer,
  platformFees,
  stripeFeesPer,
  perDonationFees,
  paymentFees,
  withdrawalAmount,
  bankFees,
  chipinCount,
}) => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();

  const fixAmount = (amount) => amount.toFixed(2);
  const paragraphStyles = {
    color: darkMode ? "white.1000" : "black.1000",
    fontSize: "14px",
    lineHeight: 1.5,
    fontWeight: 600,
  };
  return (
    <Box
      padding={{ xs: "40px 15px", sm: "60px" }}
      sx={{
        minWidth: { xs: "calc(100% - 30px)", sm: "574px" },
        backgroundColor: darkMode ? "bgDark.third" : "white.1000",
        borderRadius: "15px",
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: { xs: "10px", sm: "15px" },
          right: { xs: "10px", sm: "15px" },
          p: 0,
        }}
        onClick={handleClose}
      >
        <CloseIcon
          color={darkMode ? palette.white["1000"] : palette.black["1000"]}
          width={{ xs: "15px", sm: "24px" }}
        ></CloseIcon>
      </IconButton>
      <Box display={"flex"} sx={{ mt: "20px" }}>
        <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: 1.5,
            textTransform: "capitalize",
            flexGrow: "1",
          }}
        >
          Total Raised
        </Typography>
        <Typography
          sx={{
            ...paragraphStyles,
            flexShrink: "0",
            textTransform: "capitalize",
          }}
        >
          ${fixAmount(totalRaise)}
        </Typography>
      </Box>
      <Box display={"flex"} sx={{ mt: "20px" }}>
        {platformFees && (
          <>
            <Typography
              sx={{
                ...paragraphStyles,
                flexGrow: "1",
                textTransform: "capitalize",
              }}
            >
              PloomSocial Platform Fee ({platformFeesPer}%)
            </Typography>

            <Typography
              sx={{
                ...paragraphStyles,
                flexShrink: "0",
                textTransform: "capitalize",
              }}
            >
              ${fixAmount(platformFees)}
            </Typography>
          </>
        )}
      </Box>

      <Box display={"flex"} sx={{ mt: "20px" }}>
        <Typography
          sx={{
            ...paragraphStyles,
            flexGrow: "1",
            textTransform: "capitalize",
          }}
        >
          Processing fees ({stripeFeesPer}% + ${perDonationFees}/Donation (
          {chipinCount}))
        </Typography>
        <Typography
          sx={{
            ...paragraphStyles,
            flexShrink: "0",
            textTransform: "capitalize",
          }}
        >
          ${fixAmount(paymentFees)}
        </Typography>
      </Box>
      <Box display={"flex"} sx={{ mt: "20px" }}>
        <Typography
          sx={{
            flexGrow: "1",
            ...paragraphStyles,
          }}
        >
          Withdrawal Fees
        </Typography>
        <Typography
          sx={{
            flexShrink: "0",
            ...paragraphStyles,
          }}
        >
          ${fixAmount(bankFees)}
        </Typography>
      </Box>
      {/* <Divider
        sx={{
          my: { xs: "20px", sm: "15px" },
          borderColor: darkMode
            ? "black.250"
            : alpha(palette.black["1000"], 0.06),
        }}
      ></Divider> */}
      {/* <Stack
        sx={{
          mt: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          gap: "20px",
        }}
      >
        <Typography
          sx={{
            color: darkMode ? "grey.900" : "black.1000",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
          }}
        >
          Withdrawable amount
        </Typography>
        <Typography
          sx={{
            color: darkMode ? "grey.900" : "black.1000",
            textAlign: "right",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
          }}
        >
          ${fixAmount(withdrawalAmount)}
        </Typography>
      </Stack> */}

      <Divider
        sx={{
          my: { xs: "20px", sm: "15px" },
          borderColor: darkMode
            ? "black.250"
            : alpha(palette.black["1000"], 0.06),
        }}
      ></Divider>

      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          gap: "20px",
        }}
      >
        <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
          }}
        >
          Total
        </Typography>
        <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
          }}
        >
          ${fixAmount(withdrawalAmount)}
        </Typography>
      </Stack>

      <Button
        variant="contained"
        fullWidth
        sx={{
          mt: "25px",
          paddingBlock: "15px",
          color: "white.1000",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "22px /* 137.5% */",
          letterSpacing: "-0.408px",
          borderRadius: "10px",
        }}
        onClick={handleClose}
      >
        Close
      </Button>
    </Box>
  );
};

export default TotalAmountDialog;
