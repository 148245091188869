import { Box, IconButton, CircularProgress, alpha } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PlayIcon from "./PlayIcon";
import VolumeUpIcon from "../../icons/VolumeUpIcon";
import MuteIcon from "../../icons/MuteIcon";
import { useGlobalMute } from "../../hooks/store/useGlobalMute";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function VideoPlayer({
  videoUrl,
  activeVideoId,
  videoId,
  activeFeed,
  id,
}) {
  const videoRefs = useRef(null);
  const [pause, setPause] = useState(true);
  const [loading, setLoading] = useState(false); // New state to track buffering
  const { mute, setMute } = useGlobalMute();
  const videoType = pause ? 0 : 1;
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();

  function videoPlayToggle() {
    if (pause) {
      videoRefs.current.play();
    } else {
      videoRefs.current.pause();
    }
    setPause((prevState) => !prevState);
  }

  const videoMuteToggle = () => {
    videoRefs.current.muted = !mute;
    setMute(!mute);
  };

  useEffect(() => {
    try {
      if (videoId === activeVideoId) {
        videoRefs?.current?.play();
        videoRefs.current.muted = mute;
        setPause(false);
      } else {
        videoRefs?.current?.pause();
        setPause(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [videoId, activeVideoId, activeFeed, id, mute]);

  useEffect(() => {
    if (videoId === activeVideoId) {
      videoRefs.current.currentTime = 0;
    }
  }, [videoId, activeVideoId]);

  // code for track video loading state
  useEffect(() => {
    const handleWaiting = () => {
      setLoading(true);
    };

    const handleCanPlay = () => {
      setLoading(false);
    };

    videoRefs.current.addEventListener("waiting", handleWaiting);
    videoRefs.current.addEventListener("canplay", handleCanPlay);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      {/* video loading animation */}
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "0%",
            left: "0%",
            width: "100%",
            height: "100%",
            background: darkMode
              ? alpha(palette.black[1000], 0.3)
              : alpha(palette.black[1000], 0.2),
            display: "grid",
            placeItems: "center",
            zIndex: 100,
          }}
        >
          <CircularProgress
            sx={{
              color: "white.1000",
            }}
          />
        </Box>
      )}

      <Box
        position={"absolute"}
        top={{ xs: "50%", sm: "50%", md: "50%" }}
        left={"50%"}
      >
        <PlayIcon videoType={videoType} onClick={videoPlayToggle} />
      </Box>

      <video
        ref={videoRefs}
        autoPlay={videoId === activeVideoId}
        playsInline={videoId === activeVideoId}
        loop
        onClick={videoPlayToggle}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>

      {/* mute icon */}

      <IconButton
        sx={{
          position: "absolute",
          top: { xs: "85px", md: "25px" },
          right: { xs: "20px", md: "27px" },
          zIndex: 200,
        }}
        aria-label="volume"
        onClick={videoMuteToggle}
      >
        {mute ? <MuteIcon /> : <VolumeUpIcon />}
      </IconButton>
    </Box>
  );
}
