import React from "react";
import Layout from "../Layout";
import { Box, Typography, alpha } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
// import { useNavigate } from "react-router-dom";
// icons
// import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useTheme } from "@emotion/react";

export default function BackButtonLayout({
  children,
  pageTitle,
  titlePrefix,
  isTitleBold = false,
  sx,
  isLoggedInRequired = false,
  backButtonHandler,
  containerStyle,
}) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  // const navigate = useNavigate();
  // const defaultBackButtonHandler = () => {
  //   if (window.history.length <= 1) {
  //     navigate("/");
  //   } else {
  //     navigate(-1);
  //   }
  // };
  return (
    <Layout isLoggedInRequired={isLoggedInRequired}>
      <Box
        sx={{
          position: "relative",
          maxWidth: {
            // xs: "calc(100% - 30px)",
            xs: "100%",
            sm: "460px",
            md: "675px",
          },
          minHeight: { xs: "110vh", sm: "unset" },
          padding: { xs: "15px", md: "unset" },
          marginTop: { sm: "30px" },
          backgroundColor: {
            xs: darkMode ? "bgDark.third" : "white.1000",
            md: "unset",
          },
          width: "100%",
          marginInline: "auto",
          zIndex: 50,
          borderRadius: { xs: "10px", lg: "unset" },
          ...sx,
        }}
      >
        {/* page title with back button */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={"20px"}
        >
          {/* <Button
            disableRipple={true}
            sx={{
              minWidth: "unset",
              backgroundColor: {
                xs: darkMode ? "grey.550" : "white.1150",
                md: darkMode ? "grey.550" : "white.1000",
              },
              flexShrink: "0",
              padding: "10px",
              borderRadius: "10px",
            }}
            onClick={backButtonHandler || defaultBackButtonHandler}
          >
            <ArrowBackIosNewRoundedIcon
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                marginLeft: "0",
              }}
              fontSize="18px"
            />
          </Button> */}
          <Typography
            sx={{
              color: darkMode ? alpha(palette.white["1000"], 0.7) : "black.200",
              fontFamily: "Poppins",
              fontSize: { xs: "20px", md: "24px" },
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "142%" /* 34.08px */,
              letterSpacing: "-0.408px",
              flexGrow: 1,
            }}
          >
            {titlePrefix}{" "}
            <Typography
              component={"span"}
              sx={{
                color: darkMode
                  ? "white.1000"
                  : isTitleBold
                  ? "black.1000"
                  : "black.200",
                fontFamily: "Poppins",
                fontSize: { xs: "20px", md: "24px" },
                fontStyle: "normal",
                fontWeight: isTitleBold ? 600 : "400",
                lineHeight: "142%" /* 34.08px */,
                letterSpacing: "-0.408px",
              }}
            >
              {pageTitle}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            mt: { xs: "20px", md: "30px" },
            display: "grid",
            gridTemplateColumns: "1fr",
            padding: { md: "30px" },
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            borderRadius: "15px",
            ...containerStyle,
          }}
        >
          {/* contetn */}
          {children}
        </Box>
      </Box>
    </Layout>
  );
}
