import { Alert, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useApi from "../hooks/useApi";
import { useSnack } from "../hooks/store/useSnack";
import { LoadingButton } from "@mui/lab";
import EmailIcon from "@mui/icons-material/Email";
const EmailVarification = () => {
  let { apiCall } = useApi();
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  const email = decodeURIComponent(queryParams.get("mail"));
  const key = queryParams.get("key");
  let [mail, setMail] = useState(email);
  let [status, setStatus] = useState("loading");
  let [message, setMessage] = useState("");
  let { setSnack } = useSnack();

  // code for verify email
  async function verifyEmail() {
    try {
      let res = await apiCall({
        url: "v3/users/verify-email/email",
        method: "post",
        data: {
          email,
          key,
        },
      });
      setStatus("success");
      setSnack("Your email has been verified");
      setMessage(
        "Your email address is verified. Visit ploomsocial.com or our mobile application to get started!"
      );
    } catch (error) {
      setStatus("fail");
      setSnack(error.response.data.message, "error");
      setMessage(error.response.data.message);
    }
  }

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        minHeightheight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "750px",
          padding: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", fontWeight: 600, textAlign: "center" }}
        >
          Verify your email address
        </Typography>
        <Typography
          sx={{
            mt: "15px",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          You've entered {mail} as the email address for your account.
        </Typography>
        {status !== "loading" && (
          <Alert
            sx={{ mt: 2 }}
            severity={status === "success" ? "success" : "error"}
          >
            {message}
          </Alert>
        )}
        {status !== "loading" ? (
          <Button
            LinkComponent={Link}
            to="/"
            color="primary"
            variant="contained"
            sx={{ mt: 2, textTransform: "unset" }}
          >
            Go Home
          </Button>
        ) : (
          <LoadingButton
            color="secondary"
            loading={true}
            loadingPosition="start"
            startIcon={<EmailIcon />}
            variant="contained"
            sx={{ textTransform: "capitalize", mt: 2 }}
          >
            <span>Verifiying</span>
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};

export default EmailVarification;
