import { createSvgIcon, useTheme } from "@mui/material";
export default function ChatIcon(props) {
  let { palette } = useTheme();
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <g id="Iconly/Bold/Chat">
      <g id="Chat">
        <path
          id="Chat_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.99976 12.0151C1.99976 6.74718 6.20976 2.00006 12.0198 2.00006C17.6998 2.00006 21.9998 6.65705 21.9998 11.985C21.9998 18.1643 16.9598 22.0001 11.9998 22.0001C10.3598 22.0001 8.53976 21.5594 7.07976 20.6981C6.56976 20.3876 6.13976 20.1573 5.58976 20.3376L3.56976 20.9385C3.05976 21.0987 2.59976 20.6981 2.74976 20.1573L3.41976 17.9139C3.52976 17.6035 3.50976 17.273 3.34976 17.0126C2.48976 15.4302 1.99976 13.6976 1.99976 12.0151ZM7.36976 13.307C6.66976 13.307 6.08976 12.7262 6.08976 12.0251C6.08976 11.314 6.65976 10.7432 7.36976 10.7432C8.07976 10.7432 8.64976 11.314 8.64976 12.0251C8.64976 12.7262 8.07976 13.297 7.36976 13.307ZM10.699 12.0153C10.699 12.7263 11.269 13.2972 11.979 13.3072C12.689 13.3072 13.259 12.7263 13.259 12.0253C13.259 11.3142 12.689 10.7434 11.979 10.7434C11.279 10.7333 10.699 11.3142 10.699 12.0153ZM15.3083 12.0251C15.3083 12.7262 15.8783 13.3071 16.5883 13.3071C17.2983 13.3071 17.8683 12.7262 17.8683 12.0251C17.8683 11.3141 17.2983 10.7432 16.5883 10.7432C15.8783 10.7432 15.3083 11.3141 15.3083 12.0251Z"
          fill={color}
        />
      </g>
    </g>
  );
  return (
    <CreateChatIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
