import { createTheme } from "@mui/material";

export const theme = createTheme({
  // breakpoints
  breakpoints: {
    values: {
      xs: 0,
      ss: 320,
      ssm: 429,
      xsm: 540,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  border: {
    radius: "10px",
  },

  //   colors
  palette: {
    icon_white: {
      1000: "#FFFFFF",
    },

    bgDark: {
      main: "#2C2C2C",
      secondary: "#282828",
      third: "#313131",
    },

    bgWhite: {
      main: "#FFFFFF",
      secondary: "#F2F2F2",
    },

    iconGradient: {
      1: "#3837F6",
      2: "#CE2B7E",
      3: "#F46926",
    },

    darkIcon: {
      main: "#F4F4F4",
      active: "#CE2B7E",
    },

    icon_Primary: {
      1000: "#3A37EC",
    },

    white: {
      50: "#EEEEEE",
      100: "#F2F4F7",
      150: "#EAECF0",
      200: "#C9C9C9",
      250: "#FEFEFE",
      300: "#FAFAFA",
      350: "#F6F6F6",
      400: "#D9D9D9",
      450: "#F9F9F9",
      500: "#FBFBFB",
      550: "#E7E7E7",
      555: "#E8E8E8",
      600: "#FEFEFE",
      650: "#F4F4F4",
      700: "#F2F2F2",
      750: "#dcdcdc",
      800: "#EFEFEF",
      850: "#BBBBBB",
      900: "#EDEDED",
      950: "#3E3E3E",
      1000: "#ffffff",
      1150: "#F0F0F0",
    },
    black: {
      100: "#A9A9A9",
      150: "#626262",
      200: "#4A4A4A",
      250: "#3C3C3C",
      300: "#333333",
      350: "#2E2E2E",
      400: "#475467",
      450: "#232323",
      800: "#363636",
      850: "#515151",
      900: "#403F4C;",
      950: "#2B2B2B",
      1000: "#000000",
    },
    neutral: {
      100: "#FAFAFA",
      150: "#E5E5E5",
      200: "#E5E7EB",
      250: "#C7C7C7",
      300: "#D1D5DB",
      350: "#777777",
      400: "#9CA3AF",
      450: "#CBCBCB",
      500: "#6B7280",
      550: "#9D9D9D",
      600: "#4B5563",
      650: "#505050",
      700: "#374151",
      800: "#D0347E",
      900: "#111827",
      1000: "#fff",
    },
    grey: {
      100: "#C9C9C9",
      200: "#BDDBDD",
      300: "#98A2B3",
      450: "#DDDDDD",
      400: "#9B9B9B",
      500: "#888888",
      600: "#7F7F7F",
      250: "#c2c2c2",
      350: "#B8B8B8",
      550: "#404040",
      650: "#D3D3D3",
      700: "#848484",
      750: "#5757575",
      800: "#E0E0E0",
      850: "#A5A5A5",
      900: "#949494",
      950: "#9F9F9F",
      1000: "#656565",
      1050: "#E9E9E9",
      1100: "#F5F5F5",
    },
    gradients: {
      main: "linear-gradient(180deg, #5235E1 0%, #B02D96 52.6%, #EF6231 100%)",
      mainInvert:
        "linear-gradient(0deg, #5235E1 0%, #B02D96 52.6%, #EF6231 100%)",
      notification_bg: "linear-gradient(to top,#faf9fe,#eeeefd)",
      verticleTextBg:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.50) 65.66%, rgba(255, 255, 255, 0.50) 83.73%, rgba(255, 255, 255, 0.50) 100%)",
    },
    action: {
      active: "#6B7280",
      focus: "rgba(55, 65, 81, 0.12)",
      hover: "rgba(55, 65, 81, 0.04)",
      selected: "rgba(55, 65, 81, 0.08)",
      disabledBackground: "rgba(55, 65, 81, 0.12)",
      disabled: "rgba(55, 65, 81, 0.26)",
    },
    background: {
      default: "#FBFBFB",
      paper: "#FBFBFB",
      main: "#D0347E",
    },
    divider: "#E6E8F0",
    primary: {
      main: "#3A37EC",
      light: "#7A78DF",
      light10: "#F0EFFA",
      dark: "#2D2ABC",
      light50: "#DEDDFC",
      contrastText: "#FFFFFF",
      disabledLight: "#B3B3B3",
      disabledDark: "#424242",
    },
    secondary: {
      main: "#4942E4",
      light: "#3FC79A",
      dark: "#0B815A",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#14B8A6",
      secondary: "#1B9207;",
      light: "#34a56F",
      dark: "#0E8074",
      contrastText: "#FFFFFF",
      progressBar: "#368000",
    },
    info: {
      main: "#2196F3",
      light: "#64B6F7",
      dark: "#0B79D0",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFB020",
      light: "#FFBF4C",
      dark: "#B27B16",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FC4135",
      light: "#FF4439",
      dark: "#922E2E",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#121828",
      secondary: "#65748B",
      disabled: "rgba(55, 65, 81, 0.48)",
    },
  },

  //   typography
  typography: {
    fontFamily: " 'Poppins', sans-serif",
    inter: " 'Inter', sans-serif",
    manrope: "'Manrope', sans-serif",
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.57,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66,
    },
    h1: {
      fontWeight: 700,
      fontSize: "3.5rem",
      lineHeight: 1.375,
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.375,
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.375,
    },
    h4: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.375,
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.375,
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: 1.375,
    },
    error: {
      main: "#D14343",
      light: "#DA6868",
      dark: "#922E2E",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#14B8A6",
      light: "#43C6B7",
      dark: "#0E8074",
      contrastText: "#FFFFFF",
      progressBar: "#368000",
    },
  },

  //   shadows
  shadows: [
    "none",
    "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
    "0px 1px 2px rgba(100, 116, 139, 0.12)",
    "0px 1px 4px rgba(100, 116, 139, 0.12)",
    "0px 1px 5px rgba(100, 116, 139, 0.12)",
    "0px 1px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 6px rgba(100, 116, 139, 0.12)",
    "0px 3px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
    "0px 5px 12px rgba(100, 116, 139, 0.12)",
    "0px 5px 14px rgba(100, 116, 139, 0.12)",
    "0px 5px 15px rgba(100, 116, 139, 0.12)",
    "0px 6px 15px rgba(100, 116, 139, 0.12)",
    "0px 7px 15px rgba(100, 116, 139, 0.12)",
    "0px 8px 15px rgba(100, 116, 139, 0.12)",
    "0px 9px 15px rgba(100, 116, 139, 0.12)",
    "0px 10px 15px rgba(100, 116, 139, 0.12)",
    "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 14px 24px -8px rgba(100, 116, 139, 0.25)",
    "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
  ],
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar-thumb": {
          borderRadius: "3px",
        },
        "*": { margin: 0, boxSizing: "border-box" },
        "html,body": {
          scrollBehavior: "smooth",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&": {
            textTransform: "capitalize",
            borderRadius: "10px",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },
    },
  },
});
