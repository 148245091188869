import React from "react";
import DialogBackground from "./DialogBackground";
export default function ConfirmPrimaryDialog({
  isLoading = false,
  confirmCall,
  cancelCall,
}) {
  return (
    <DialogBackground
      heading={"Set Primary Account"}
      description="Are you sure you want to set this account as a primary or default
          account?"
      cancelCall={cancelCall}
      confirmCall={confirmCall}
      isLoading={isLoading}
    />
  );
}
