import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../../component/ui/form/Input";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";
import useThemeStore from "../../hooks/store/useThemeStore";
import moment from "moment";
import { useUserVerification } from "../../hooks/store/useUserVerification";
import ButtonWithLoading from "../../component/ui/button/ButtonWithLoading";
import { useKyc } from "../../hooks/store/useKyc";
import DialogBox from "../Dialogbox/DialogBox";
import ContactUsDialog from "./ContactUsDialog";
import ErrorDialog from "./ErrorDialog";
import { useSettings } from "../../hooks/store/useSettings";
import { useRedirect } from "../../hooks/useRedirect";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/store/useAuth";

export const SsnForm = ({
  setOpenForm,
  setStep,
  setKycStatus: kycStatus,
  setMessage,
}) => {
  const { darkMode } = useThemeStore();
  const { setKycStatus } = useUserVerification();
  const { apiCall } = useApi();
  const { setSnack } = useSnack();
  const [loading, setLoading] = useState(false);
  const [openContactUsDialog, setOpenContactUsDialog] = useState(false);
  const [selectDialog, setSelectDialog] = useState("ErrorDialog");
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();
  const {
    setStep3,
    firstName,
    surName,
    nationalId,
    physicalAddress,
    city,
    country,
    zip,
    state,
    dob,
    email,
    setNationalId,
    setDob,
    reset,
    kycTry,
    setKycTry,
    setKycTryCount,
  } = useKyc();
  const { settings } = useSettings();

  const { redirectUrl, setRedirectUrl } = useRedirect();
  const navigate = useNavigate();
  const schema = Yup.object({
    nationalId: Yup.string("SSN must be required")
      .required("SSN must be required")
      .matches(/^\d{9}$/, "Invalid SSN number"),
    dob: Yup.object().typeError("required"),
  });

  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      nationalId: nationalId ? nationalId : undefined,
      dob: dob ? dob : moment().subtract("18", "years"),
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  async function processKyc(values) {
    try {
      setLoading(true);
      let { nationalId, dob } = values;
      setStep3(dob, nationalId);
      let result = await apiCall({
        url: "v3/users/kyc-fin2",
        method: "post",
        data: {
          firstName,
          surName,
          email,
          mobileNumber: user?.mobile || "",
          physicalAddress,
          nationalId,
          state,
          zip,
          city,
          country,
          dob: dob.format("YYYY-MM-DD"),
        },
      });
      setKycTry();
      if (result.data.code === 200 && result.data.success === true) {
        setSnack(result.data.message);
        setKycStatus(1);
        setLoading(false);
        reset();
        if (
          Boolean(redirectUrl) &&
          typeof redirectUrl === "string" &&
          redirectUrl.charAt(0) === "/"
        ) {
          navigate(redirectUrl);
          setRedirectUrl(null);
        }
        kycStatus(true);
      } else {
        setStep("step1");
        if (kycTry >= settings.kyc_counts) {
          kycStatus(false);
          setMessage("");
          setSelectDialog("ContactUSDialog");
          return kycStatus(true);
          // return setOpenContactUsDialog(true);
        }

        if (result.data.code === 200 && result.data.success === false) {
          setSelectDialog("ErrorDialog");
          setErrorMessage(result.data.message);
          setKycTryCount(result.data.data.count);
          return setOpenContactUsDialog(true);
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.code === "ERR_NETWORK") {
        return setSnack(
          "Something went wrong! Please try again later.",
          "error"
        );
      }

      if (
        error.response.data.code === 500 &&
        error.response.data.data.allowKyc
      ) {
        setSelectDialog("ErrorDialog");
        setErrorMessage(error.response.data.message);
        return setOpenContactUsDialog(true);
      }

      if (error.response.data.code === 500 || kycTry >= settings.kyc_counts) {
        setSelectDialog("ContactUSDialog");
        setMessage(
          kycTry >= settings.kyc_counts
            ? `You have failed your ID verification more than 5 times. Please contact us at ${settings.supportEmail} for assistance.`
            : error.response.data.message
        );
        return kycStatus(false);
      }

      setKycTry();
      setLoading(false);
      setSelectDialog("ErrorDialog");
      setErrorMessage(error.response.data.message);
      return setOpenContactUsDialog(true);
      // return kycStatus(false);
    }
  }

  return (
    <Box
      sx={{
        mt: { md: "30px" },
        backgroundColor: {
          xs: darkMode ? "bgDark.third" : "white.1000",
          md: "unset",
        },
        width: "100%",
        marginInline: "auto",
        zIndex: 50,
        borderRadius: { xs: "10px", lg: "unset" },
      }}
    >
      <Stack
        sx={{
          mt: { xs: "20px", md: "30px" },
          display: "grid",
          gridTemplateColumns: "1fr",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        {/* form heading */}
        {/* <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontSize: { xs: "16px", md: "18px" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          Verify Your Identity
        </Typography> */}
        {/* form */}
        <Stack onSubmit={handleSubmit(processKyc)} component={"form"}>
          <Input
            sx={{ mt: { xs: "-10px!important", md: "0px!important" } }}
            name={"nationalId"}
            type="number"
            inputLabel={"SSN / TIN"}
            placeholder="Enter Your SSN / EIN Number"
            register={register}
            errors={formState.errors}
            onChange={(e) => {
              setNationalId(e.target.value);
            }}
          />

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Date of Birth"
                fullWidth
                name="dob" // Set the name attribute to "dob"
                value={dob ? dob : moment().subtract("18", "years")}
                maxDate={moment().subtract("18", "years")}
                onChange={(date) => {
                  setDob(date);
                  setValue("dob", date); // Use setValue to set the "dob" field value
                }}
                slotProps={{
                  textField: {
                    error: Boolean(formState.errors.dob),
                    helperText: formState.errors.dob?.message || "",
                  },
                }}
                sx={{
                  mt: "20px",
                  width: "100%",
                  "& .MuiIconButton-root": {
                    zIndex: 1,
                    color: darkMode ? "white.1000" : "black.1000",
                    opacity: darkMode ? 0.7 : 1,
                  },
                  "input:placeholder-shown": { border: "none" },
                  "input:focus": { border: "none" },
                  "input:hover": { border: "none" },
                  "& label": {
                    color: darkMode ? "white.1000" : "primary.main",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    textTransform: "capitalize",
                  },
                  "& input,input:is(:hover,:focus)": {
                    border: "none",
                    zIndex: 2,
                    color: darkMode ? "white.1000" : "black.1000",
                    opacity: 1,
                  },
                  "& fieldset": {
                    borderRadius: "8px",
                    border: "1px solid",
                    borderColor: darkMode ? "neutral.650" : "bgWhite.secondary",
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          {/* next and previous button container */}
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "15px" }}>
            <ButtonWithLoading
              disabled={loading}
              onClick={() => {
                setOpenForm("step2");
              }}
              variant="contained"
              fullWidth
              sx={{
                flex: 1,
                paddingBlock: "15px",
                borderRadius: "10px",
                textTransform: "capitalize",
                mt: "30px",
                "&.Mui-disabled": {
                  color: darkMode ? "black.100" : "white.1000",
                  backgroundColor: darkMode
                    ? "primary.disabledDark"
                    : "primary.disabledLight",
                },
              }}
            >
              Previous
            </ButtonWithLoading>

            <ButtonWithLoading
              type="submit"
              isLoading={loading}
              disabled={!formState.isValid || formState.isSubmitting}
              variant="contained"
              fullWidth
              sx={{
                flex: 1,
                paddingBlock: "15px",
                borderRadius: "10px",
                textTransform: "capitalize",
                mt: "30px",
                "&.Mui-disabled": {
                  color: darkMode ? "black.100" : "white.1000",
                  backgroundColor: darkMode
                    ? "primary.disabledDark"
                    : "primary.disabledLight",
                },
              }}
            >
              Verify
            </ButtonWithLoading>
          </Stack>
        </Stack>
      </Stack>
      <DialogBox
        openDialog={openContactUsDialog}
        setOpenDialog={setOpenContactUsDialog}
        Content={({ handleClose }) =>
          selectDialog === "ErrorDialog" ? (
            <ErrorDialog
              handleClose={() => setOpenContactUsDialog(false)}
              error={errorMessage}
              setOpenForm={setOpenForm}
            ></ErrorDialog>
          ) : (
            <ContactUsDialog
              handleClose={() => setOpenContactUsDialog(false)}
            />
          )
        }
      ></DialogBox>
    </Box>
  );
};
