import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../ui/Heading";
import { useTheme } from "@emotion/react";
import InfoIcon from "../../icons/InfoIcon";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import { useSnack } from "../../hooks/store/useSnack";
import handleApiError from "../../utils/handleApiError";
import useThemeStore from "../../hooks/store/useThemeStore";
import ROUTE_LIST from "../../Routes/list";
import DialogBox from "../Dialogbox/DialogBox";
import ConfirmWithdrawalDialog from "./dialog/ConfirmWithdrawalDialog";
import WithdrawalFailDialog from "./dialog/WithdrawalFailDialog";
import WithdrawalAmount from "./WithdrawalAmount";
import TotalAmountDialog from "../withdrawal/dialog/TotalAmountDialog";
import WithdrawalFundsSkeleton from "../skeleton/WithdrawalFundsSkeleton";

export default function WithdrawalFunds({ amount = 0, feedId }) {
  if (window.isNaN(amount)) {
    amount = 0;
  }
  const navigate = useNavigate();
  const { setSnack } = useSnack();
  const [failMessage, setFailMessage] = useState("");
  const fixAmount = (amount) => amount.toFixed(2);

  //   states
  const [openDialog, setOpenDialog] = useState(false);

  const [fees, setFees] = useState({
    availableBalance: 0,
    bankFees: 0,
    chipinCount: 0,
    perChipinFees: 0,
    chipinFees: 0,
    finalWithdrawal: 0,
    stripeFees: 0,
    stripeFeesPer: 0,
    platformFees: 0,
    platformFeesPer: 0,
  });
  const [openModal, setOpenModal] = useState({
    type: "",
  });

  const { border } = useTheme();
  const { darkMode } = useThemeStore();
  const { apiCall, isLoading } = useApi();
  const { apiCall: withdrawCall, isLoading: withdrawalLoading } = useApi();

  const closeModal = () => setOpenModal({ type: "" });

  useEffect(() => {
    (async function () {
      try {
        const response = await apiCall({
          url: APIS.FEED.WITHDRAW.CHARGES(feedId),
        });
        if (response.data.success === true) {
          const charges = response.data.data;

          const newFees = {
            availableBalance: charges.availableBalance,
            bankFees: charges.bankFees,
            chipinCount: charges.chipinCount,
            perChipinFees: charges.perDonationFeesAmt,
            chipinFees: charges.chipinFees,
            finalWithdrawal: charges.finalWithdrawal,
            stripeFees: charges.stripeFees,
            stripeFeesPer: charges.stripeFeesPer,
            platformFees: charges.platformFees,
            platformFeesPer: charges.platformFeesPer,
          };
          setFees(newFees);
        }
      } catch (error) {
        console.log(error); //        handleApiError(error, setSnack);
      }
    })();
  }, [apiCall, amount, setSnack, feedId]);

  const withdrawFeed = async () => {
    try {
      const res = await withdrawCall({
        url: APIS.FEED.WITHDRAW.CREATE,
        method: "post",
        data: {
          feedId: feedId,
        },
      });
      if (res.data.success === true) {
        setSnack(res.data.message);
        closeModal();
        navigate(ROUTE_LIST.WITHDRAWAL_CONFIRM);
      }
    } catch (error) {
      setOpenModal({ type: "fail" });
      if (error?.response.data.message) {
        setFailMessage(error.response.data.message);
      }
      handleApiError(error, setSnack);
    }
  };

  const paragraphStyles = {
    color: darkMode ? "white.1000" : "black.1000",
    fontSize: "14px",
    lineHeight: 1.5,
    fontWeight: 600,
  };
  return (
    <Box>
      <DialogBox
        Content={() => (
          <>
            {openModal.type === "confirm" && (
              <ConfirmWithdrawalDialog
                cancelCall={closeModal}
                confirmCall={withdrawFeed}
                isLoading={withdrawalLoading}
              />
            )}
            {openModal.type === "fail" && (
              <WithdrawalFailDialog
                message={failMessage}
                cancelCall={closeModal}
              />
            )}
          </>
        )}
        setOpenDialog={setOpenModal.bind(null, { type: "", id: "" })}
        openDialog={openModal.type !== ""}
      />
      <Heading>Withdrawal Funds</Heading>
      <WithdrawalAmount amount={amount} text="Available Balance" />

      {isLoading ? (
        <WithdrawalFundsSkeleton />
      ) : (
        <>
          {/* balance */}
          <Box display={"flex"} sx={{ mt: "15px" }}>
            <Typography
              sx={{
                flexGrow: "1",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: 1.5,
                color: darkMode ? "white.1000" : "black.1000",
              }}
            >
              Balance
            </Typography>
            <Typography
              sx={{
                flexShrink: "0",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: 1.5,
                color: darkMode ? "white.1000" : "black.1000",
              }}
            >
              ${fixAmount(fees.availableBalance)}
            </Typography>
          </Box>

          <Box
            display={"flex"}
            sx={{
              mt: "15px",
              backgroundColor: darkMode ? "grey.550" : "primary.light10",
              p: "10px 15px",
              borderRadius: border.radius,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                flexGrow: "1",
                ...paragraphStyles,
                color: !darkMode ? "primary.main" : "white.1000",
              }}
            >
              {fees.platformFees
                ? "Includes Processing and Platform Fees"
                : "Includes Processing Fees"}
            </Typography>
            <Button
              sx={{
                flexShrink: "0",
                ...paragraphStyles,
                color: !darkMode ? "primary.main" : "white.1000",
                minWidth: "unset",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => setOpenDialog(true)}
            >
              <InfoIcon />
            </Button>
          </Box>

          {/* total withdrawal */}
          <Box display={"flex"} sx={{ mt: "15px" }}>
            <Typography
              sx={{
                flexGrow: "1",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: 1.5,
                color: darkMode ? "white.1000" : "black.1000",
              }}
            >
              Total Withdrawal
            </Typography>
            <Typography
              sx={{
                flexShrink: "0",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: 1.5,
                color: darkMode ? "white.1000" : "black.1000",
              }}
            >
              ${fixAmount(fees.finalWithdrawal)}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              borderRadius: "10px",
              textTransform: "capitalize",
              fontSize: "14px",
              lineHeight: 1.5,
              fontWeight: 500,
              p: { xs: "10px", md: "15px" },
              textDecoration: "none",
              mt: "25px",
              "&.Mui-disabled": {
                color: darkMode ? "black.100" : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
            onClick={() => {
              setOpenModal({ type: "confirm" });
            }}
          >
            Deposit Funds
          </Button>
        </>
      )}

      {/* total a mont dialog */}
      <DialogBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        Content={({ handleClose }) => (
          <TotalAmountDialog
            totalRaise={fees.availableBalance}
            paymentFees={fees.stripeFees + fees.chipinFees}
            platformFees={fees.platformFees ? fees.platformFees : undefined}
            platformFeesPer={
              fees.platformFeesPer ? fees.platformFeesPer : undefined
            }
            stripeFeesPer={fees.stripeFeesPer}
            perDonationFees={fees.perChipinFees}
            withdrawalAmount={fees.finalWithdrawal}
            bankFees={fees.bankFees}
            handleClose={handleClose}
            chipinCount={fees.chipinCount}
          />
        )}
      ></DialogBox>
    </Box>
  );
}
