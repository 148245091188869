import {
  Box,
  Divider,
  Typography,
  Button,
  alpha,
  IconButton,
} from "@mui/material";
import React from "react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "../../../icons/CloseIcon";
import { useNavigate } from "react-router-dom";

export default function DialogBackground({
  isLoading = false,
  confirmCall,
  cancelCall,
  heading,
  description = "",
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  children,
  hideAction = false,
  Icon = () => {},
  showCloseBtn = false,
}) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          minWidth: { xs: "200px", sm: "575px" },
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        {/* close button */}
        {showCloseBtn && (
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
            onClick={() => {
              if (window.history.length <= 1) {
                return navigate("/");
              } else {
                return navigate(-1);
              }
            }}
          >
            <CloseIcon
              color={
                darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : palette.black["1000"]
              }
            />
          </IconButton>
        )}
        <Box px={"20px"} pt={{ xs: "20px", sm: "30px" }}>
          {heading ? (
            <>
              <Typography
                align="center"
                sx={{
                  margin: "0px 0px 15px 0",
                  color: darkMode ? "white.1000" : "black.1000",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  letterSpacing: "-0.408px",
                }}
              >
                {heading}
              </Typography>
              <Divider
                sx={{
                  mb: { xs: "15px", sm: "20px" },
                  borderColor: darkMode
                    ? "black.250"
                    : alpha(palette.grey[100], 0.4),
                }}
              />
            </>
          ) : (
            <Icon />
          )}

          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "16px" },
              lineHeight: 1.5,
              color: darkMode ? "grey.900" : "black.1000",
              my: "30px",
              textAlign: "center",
              maxWidth: { sm: "400px" },
              mx: "auto",
            }}
          >
            {description}
          </Typography>
          {children}
        </Box>
        {!hideAction && (
          <Box pb={"10px"}>
            <Divider
              sx={{
                borderColor: darkMode
                  ? "black.250"
                  : alpha(palette.grey[100], 0.4),
              }}
            />
            <Box sx={{ display: "flex", paddingTop: { sm: "10px" } }}>
              <Button
                variant="text"
                fullWidth
                sx={{
                  paddingBlock: "15px",
                  maxWidth: "unset",
                  color: darkMode ? "white.1000" : "black.1000",
                  "&:hover": { background: "none" },
                }}
                onClick={cancelCall}
              >
                {cancelLabel}
              </Button>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: darkMode
                    ? "black.250"
                    : alpha(palette.grey[100], 0.4),
                  height: "40px",
                  transform: "translateY(20%)",
                }}
              />
              <LoadingButton
                loading={isLoading}
                variant="text"
                fullWidth
                sx={{
                  paddingBlock: "15px",
                  color: darkMode ? "white.1000" : "black.1000",
                  "&:hover": { background: "none" },
                  "&.MuiLoadingButton-root.Mui-disabled": {
                    color: darkMode ? "black.100" : "black.100",
                  },
                }}
                loadingPosition="end"
                onClick={confirmCall}
                endIcon={<></>}
              >
                {confirmLabel}
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
