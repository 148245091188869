import { createSvgIcon, useTheme } from "@mui/material";
export default function ClockIcon(props) {
  let { palette } = useTheme();
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_2282_2369)">
        <path
          d="M7.99992 3.99992V7.99992L10.6666 9.33325M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
          stroke={color}
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2282_2369">
          <rect width="16px" height="16px" fill="white" />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateChatIcon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
