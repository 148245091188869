import { createSvgIcon } from "@mui/material";

export default function HowToIcon(props) {
  // const { palette } = useTheme();
  const { color } = props;
  let CreateSearchIcon = createSvgIcon(
    <>
      <path
        d="M10 0.5C4.48276 0.5 0 4.98276 0 10.5C0 16.0172 4.48276 20.5 10 20.5C15.5172 20.5 20 16.0172 20 10.5C20 4.98276 15.5172 0.5 10 0.5ZM9.62069 15.8103C9.03448 15.8103 8.55172 15.3276 8.55172 14.7414C8.55172 14.1552 9.03448 13.6724 9.62069 13.6724C10.2069 13.6724 10.6897 14.1552 10.6897 14.7414C10.6897 15.3276 10.2069 15.8103 9.62069 15.8103ZM11.1724 10.6379C10.6897 11.1207 10.5517 11.2931 10.5517 11.6724C10.5517 12.1897 10.1379 12.6379 9.58621 12.6379C9.06896 12.6379 8.62069 12.2241 8.62069 11.6724C8.62069 10.4655 9.27586 9.81034 9.82759 9.2931C10.3448 8.77586 10.7241 8.39655 10.7241 7.5C10.7241 7.12069 10.6207 6.84483 10.4483 6.67241C10.2759 6.53448 10.0345 6.43104 9.75862 6.43104C9.37931 6.43104 8.7931 6.63793 8.7931 7.12069C8.7931 7.63793 8.37931 8.08621 7.82759 8.08621C7.31035 8.08621 6.86207 7.67241 6.86207 7.12069C6.86207 5.46552 8.34483 4.5 9.75862 4.5C11.1379 4.5 12.6552 5.43103 12.6552 7.46552C12.6552 9.18966 11.7931 10.0172 11.1724 10.6379Z"
        fill={color}
      />
    </>
  );
  return (
    <CreateSearchIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      sx={{ color: "white.650" }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
