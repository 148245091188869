import { Box, IconButton, alpha } from "@mui/material";
import React from "react";
import FormSubmissionSVG from "../../../icons/FormSubmissionSVG";
import CloseIcon from "../../../icons/CloseIcon";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import FormSubmissionDarkSVG from "../../../icons/FormSubmissionDarkSVG";

const Submitted = ({ setOpen }) => {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  return (
    <Box sx={{ padding: { xs: "25px 12px", md: "60px 40px" } }}>
      {/* close button */}
      <IconButton
        aria-label="delete"
        size="small"
        sx={{
          position: "absolute",
          top: "15px",
          right: "15px",
        }}
        onClick={() => {
          setOpen(false);
        }}
      >
        <CloseIcon
          color={
            darkMode ? alpha(palette.white["1000"], 0.7) : palette.black["1000"]
          }
        />
      </IconButton>
      {darkMode ? (
        <FormSubmissionDarkSVG
          sx={{
            width: { xs: "240px", sm: "471px" },
            height: { xs: "180px", sm: "314px" },
          }}
        />
      ) : (
        <FormSubmissionSVG
          sx={{
            width: { xs: "240px", sm: "471px" },
            height: { xs: "180px", sm: "314px" },
          }}
        />
      )}
    </Box>
  );
};

export default Submitted;
