import { useTheme } from "@emotion/react";
import { Box, IconButton, Typography, alpha } from "@mui/material";
import React, { useState } from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import UnderProcessing from "../editRaise/UnderProcessing";
import DialogBox from "../Dialogbox/DialogBox";
import ContentFlagDialog from "../editRaise/ContentFlagDialog";
import MediaProcessingFail from "./MediaProcessingFail";
import AdminFlagDialog from "./AdminFlag";

const MediaInfoLayer = ({
  twoHate,
  processingStatus,
  adminFlag = false,
  mediaPoint = false,
  modifyHeight = true,
}) => {
  const { palette } = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  let openDialogName =
    processingStatus === "processing" || processingStatus === "failed"
      ? "processing"
      : twoHate
      ? "twoHate"
      : adminFlag
      ? "adminFlag"
      : "success";
  return (
    <>
      {((processingStatus === "success" && adminFlag === 1) ||
        (processingStatus === "success" && twoHate === true)) && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            padding: "10px",
            width: "100%",
            height: !modifyHeight ? "100%" : "calc(100% - 7px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: alpha(palette.black["1000"], 0.7),
            zIndex: 12,
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <IconButton>
            <InfoRoundedIcon
              sx={{
                color: "red",
                fontSize: {
                  xs: mediaPoint === "xs" ? "16px" : "20px",
                  md: mediaPoint === "xs" ? "16px" : "24px",
                },
              }}
            />
          </IconButton>
          <Typography
            sx={{
              maxWidth: { xs: "240px", md: "320px" },
              color: "white.1000",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: { xs: mediaPoint === "xs" ? "8px" : "12px" },
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            This video is not visible to community Tap here to know the reason
          </Typography>

          {/* under processing video */}
        </Box>
      )}

      {openDialogName === "processing" && (
        <UnderProcessing
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            padding: "10px",
            width: "100%",
            height: !modifyHeight ? "100%" : "calc(100% - 7px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: alpha(palette.black["1000"], 0.7),
            zIndex: 12,
            cursor: "pointer",
          }}
          status={processingStatus}
        ></UnderProcessing>
      )}

      <DialogBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        Content={({ handleClose }) => (
          <>
            {openDialogName === "twoHate" && (
              <ContentFlagDialog handleClose={() => setOpenDialog(false)} />
            )}

            {openDialogName === "adminFlag" && (
              <AdminFlagDialog handleClose={() => setOpenDialog(false)} />
            )}

            {processingStatus === "failed" && (
              <MediaProcessingFail
                handleClose={() => setOpenDialog(false)}
              ></MediaProcessingFail>
            )}
          </>
        )}
      ></DialogBox>
    </>
  );
};

export default MediaInfoLayer;
