import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../ui/form/Input";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  alpha,
} from "@mui/material";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/store/useAuth";
import { APIS } from "../../api/lists";
import { useSnack } from "../../hooks/store/useSnack";
import { useNavigate, useSearchParams } from "react-router-dom";
import ROUTE_LIST from "../../Routes/list";
Yup.addMethod(Yup.string, "integer", function (message) {
  return this.matches(/^\d+$/, message);
});
export default function AddAchForm() {
  const { userId } = useAuth();
  const schema = Yup.object({
    nickname: Yup.string().required("* field is required"),
    accountNumber: Yup.string()
      .integer("* account number should be number only")
      .required("* account number is required")
      .min(6, "* account number should be more than 6 digit")
      .max(17, "* account number should not more than 17 digit"),
    routingNumber: Yup.string()
      .required("* routing number is required")
      .length(9, "* routing number should have nine digit")
      .integer("* routing number is required"),
    // .matches(/^\d{9}$/, "* routing number should have nine digit"),
  });
  const [query] = useSearchParams();
  const formSuccessRoute = query.get("next") || ROUTE_LIST.WITHDRAWAL_HISTORY;
  const { formState, register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const navigate = useNavigate();
  const { setSnack } = useSnack();
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { apiCall } = useApi();
  const [loading, setLoading] = useState(false);
  const createAch = async (values) => {
    try {
      setLoading(true);
      const achDetail = {
        userId: userId,
        paytype: "ach",
        accountNumber: values.accountNumber,
        routeNumber: values.routingNumber,
        acc_type: values.accountType,
        description: values.nickname,
      };
      const res = await apiCall({
        url: APIS.BANK_ACCOUNT.ADD,
        method: "post",
        data: achDetail,
      });
      setLoading(false);
      if (res.data.success) {
        setSnack(res.data.message);
        navigate(formSuccessRoute);
      }
    } catch (error) {
      setLoading(false);
      if (error.code === "ERR_NETWORK") {
        return setSnack(
          "Something went wrong! Please try again later.",
          "error"
        );
      }
      let message = "Something went wrong! Please try again later.";
      console.log(error);
      if (
        error?.response.data.message &&
        typeof error?.response.data.message === "string"
      ) {
        const response = error.response;
        message = error.response.data.message;
        if (
          response.data.code === 400 &&
          typeof response.data.message === "object"
        ) {
          message = response.data.message.errors.join("");
        }
      }
      message = message.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
      setSnack(message.charAt(0).toUpperCase() + message.slice(1), "error");
    }
  };

  return (
    <form onSubmit={handleSubmit(createAch)}>
      <Input
        register={register}
        errors={formState.errors}
        name={"nickname"}
        inputLabel="Description/Nickname"
        placeholder={"Enter Description/Nickname"}
        disabled={formState.isSubmitting}
      />
      <Input
        register={register}
        errors={formState.errors}
        name={"accountNumber"}
        inputLabel="Account Number"
        placeholder="Enter Account Number"
        disabled={formState.isSubmitting}
      />
      <Input
        register={register}
        errors={formState.errors}
        name={"routingNumber"}
        inputLabel="Routing Number"
        placeholder="Enter Routing Number"
        disabled={formState.isSubmitting}
      />
      <FormControl sx={{ mt: "25px", display: "block" }}>
        <FormLabel
          id="accoutnType"
          sx={{ color: darkMode ? "white.1000" : "black.1000" }}
        >
          Account Type
        </FormLabel>
        <RadioGroup
          defaultValue={"checking"}
          row
          aria-labelledby="accoutnType"
          name="accountType"
          {...register("accountType")}
        >
          <FormControlLabel
            value="checking"
            control={<Radio />}
            sx={{
              // color: darkMode ? "white.1000" : "black.1000",
              ".MuiRadio-root": {
                color: darkMode ? "neutral.650" : "primary.disabledLight",
              },
              ".MuiFormControlLabel-label": {
                color: darkMode ? "grey.900" : "primary.disabledLight",
              },
              ".MuiRadio-root.Mui-checked": {
                color: darkMode ? "white.1000" : "primary.main",
              },
              ".Mui-checked+.MuiFormControlLabel-label": {
                color: darkMode ? "white.1000" : "black.1000",
              },
            }}
            label="Checking"
            disabled={formState.isSubmitting}
          />
          <FormControlLabel
            sx={{
              // color: darkMode ? "white.1000" : "black.1000",
              ".MuiRadio-root": {
                color: darkMode ? "neutral.650" : "primary.disabledLight",
              },
              ".MuiFormControlLabel-label": {
                color: darkMode ? "grey.900" : "primary.disabledLight",
              },
              ".MuiRadio-root.Mui-checked": {
                color: darkMode ? "white.1000" : "primary.main",
              },
              ".Mui-checked+.MuiFormControlLabel-label": {
                color: darkMode ? "white.1000" : "black.1000",
              },
            }}
            value="saving"
            control={<Radio />}
            label="Saving"
            disabled={formState.isSubmitting}
          />
        </RadioGroup>
      </FormControl>

      <Typography
        sx={{
          mt: "20px",
          color: darkMode ? alpha(palette.white[1000], 0.5) : "black.1000",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          letterSpacing: "-0.408px",
        }}
      >
        <Typography
          component={"span"}
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            letterSpacing: "-0.408px",
          }}
        >
          Note:
        </Typography>{" "}
        You can modify these settings at any time when you withdraw funds.
      </Typography>

      <ButtonWithLoading
        color="primary"
        disabled={formState.isSubmitting || !formState.isValid}
        isLoading={loading}
        variant="contained"
        sx={{
          p: "15px",
          mt: "25px",
          fontSize: { xs: "14px", md: "16px" },
          width: "100%",
          "&.Mui-disabled": {
            color: darkMode ? alpha(palette.white["1000"], 0.7) : "white.1000",
            backgroundColor: darkMode
              ? "primary.disabledDark"
              : "primary.disabledLight",
          },
        }}
        type="submit"
      >
        Add ACH
      </ButtonWithLoading>
    </form>
  );
}
