import { createSvgIcon } from "@mui/material";
import React from "react";

export default function MoreIcon(props) {
  const CreateNextDoorIcon = createSvgIcon(
    <>
      <path
        d="M22.7754 42.5504C33.697 42.5504 42.5508 33.6966 42.5508 22.775C42.5508 11.8533 33.697 2.99957 22.7754 2.99957C11.8537 2.99957 3 11.8533 3 22.775C3 33.6966 11.8537 42.5504 22.7754 42.5504Z"
        fill="black"
      />
      <g clipPath="url(#clip0_720_35566)">
        <path
          d="M15 24.9996C16.1046 24.9996 17 24.1042 17 22.9996C17 21.895 16.1046 20.9996 15 20.9996C13.8954 20.9996 13 21.895 13 22.9996C13 24.1042 13.8954 24.9996 15 24.9996Z"
          fill="white"
        />
        <path
          d="M23 24.9996C24.1046 24.9996 25 24.1042 25 22.9996C25 21.895 24.1046 20.9996 23 20.9996C21.8954 20.9996 21 21.895 21 22.9996C21 24.1042 21.8954 24.9996 23 24.9996Z"
          fill="white"
        />
        <path
          d="M31.002 24.9996C32.1065 24.9996 33.002 24.1042 33.002 22.9996C33.002 21.895 32.1065 20.9996 31.002 20.9996C29.8974 20.9996 29.002 21.895 29.002 22.9996C29.002 24.1042 29.8974 24.9996 31.002 24.9996Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_720_35566">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(13 12.999)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateNextDoorIcon
      width="35"
      height="35"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": { width: "unset", height: "unset" },
      }}
      {...props}
    />
  );
}
