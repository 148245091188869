import { Box, Skeleton, Stack } from "@mui/material";

const GeneralNotificationItemSkeleton = () => {
  return (
    <>
      <Box position={"relative"} padding={"10px"} borderRadius={"8px"}>
        {/* avatar with text */}
        <Stack
          direction={"row"}
          gap={"9px"}
          display={"grid"}
          gridTemplateColumns={"auto 1fr"}
        >
          <Skeleton variant="circular" width={40} height={40} />
          <Stack direction={"column"} width={"100%"}>
            <Skeleton
              variant="text"
              sx={{
                width: "70%",
              }}
            ></Skeleton>
            <Skeleton
              variant="text"
              sx={{
                width: "50%",
              }}
            ></Skeleton>
          </Stack>
        </Stack>
        <Skeleton variant="text"></Skeleton>
        <Skeleton variant="text" width={"70%"}></Skeleton>
        <Skeleton variant="text" width={"20%"}></Skeleton>
      </Box>
    </>
  );
};

export default GeneralNotificationItemSkeleton;
