import React from "react";
import useThemeStore from "../hooks/store/useThemeStore";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import { Box, List, ListItem, Typography } from "@mui/material";

export default function PrivacyPolicy() {
  const { darkMode } = useThemeStore();
  const paragraphStyle = {
    color: darkMode ? "white.1000" : "black.1000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "23px",
    opacity: darkMode ? 0.7 : 1,
  };

  const headingStyle = {
    mt: { xs: "25px", md: "30px" },
    mb: "15px",
    color: darkMode ? "white.1000" : "black.1000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  };
  const content = [
    {
      heading: "Comments",
      paragraph: [
        "When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection",
        "An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy. After approval of your comment, your profile picture is visible to the public in the context of your comment.",
      ],
    },
    {
      heading: "Media",
      paragraph: [
        "If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.",
      ],
    },
    {
      heading: "Cookies",
      paragraph: [
        "If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year",
        "If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.",
        "When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.",
        "If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.",
      ],
    },
    {
      heading: "Embedded content from other websites",
      paragraph: [
        "Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.",
        "These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.",
      ],
    },
    {
      heading: "Who we share your data with",
      paragraph: [
        "If you request a password reset, your IP address will be included in the reset email.",
      ],
    },
    {
      heading: "How long we retain your data",
      paragraph: [
        "If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.",
        "For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.",
      ],
    },
    {
      heading: "What rights you have over your data",
      paragraph: [
        "If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.",
      ],
    },
  ];

  const privacyPolicyPoints = [
    "What information do we collect from you?",
    "Where do we collect information from you and how do we use it?",
    "With whom do we share your information?",
    "How can you update, manage or delete your Personally Identifiable Information?",
    "What are your choices regarding collection, use and distribution of your information?",
    "What security precautions are in place to protect against the loss, misuse or alteration of your information?",
    "Do we use “cookies”?",
    "What should you know about privacy policies and data collection at any third-party sites accessible from our site?",
    "What else should you know about your privacy online?",
  ];
  const privacyPointDetail = [
    `Where do we collect information from you and how do we use it?
Ploom Media is designed to create an engaging customized experience for the people we serve and the information we gather is used to help us accomplish this goal. We do not collect any Personally Identifiable Information about you unless you voluntarily provide it to us. You provide certain Personally Identifiable Information to us when you:
(a) Register for a user account;
(b) Leave feedback and/or comments;
(c) Make a purchase or contribution;
(d) sign up for special offers from us or selected third parties.
In addition, we may also collect, or our third-party ad server and/or content server may collect, certain Non-Personally Identifiable Information. We use your IP address to diagnose problems with our servers, software, to administer our site and to gather demographic information. We will primarily use your Personally Identifiable Information to provide our services to you, as required by our agreements with you. We will also use Personally Identifiable Information to enhance the operation of our site, fill orders, authorize payment, improve our marketing and promotional efforts, statistically analyze site use, improve our product and service offerings, and customize our site’s content, layout, and services.
We employ other companies to perform functions on our behalf. One example is the payments processor we use. These third-party service providers have access to personal information needed to perform their functions but may not use it for other purposes. As the business continues to develop, we may buy or sell other businesses or services and customer information is a generally transferred business asset but remains subject to the pre-existing Privacy Notice in effect unless the customer consents otherwise.
We may use Personally Identifiable Information to deliver information to you and to contact you regarding administrative notices. We may also use Personally Identifiable Information to troubleshoot problems and enforce our agreements with you. We may use the information we have to improve our systems and provide relevant ads for other third-party services. We also may use the information we have to verify accounts and activity and promote safety and security.`,
    `With whom do we share your information?
Except as provided in this Privacy Policy, we do not sell or share your Personally Identifiable Information with third parties for marketing purposes and only share your information as described herein. We share your Personally Identifiable Information with:
(a) our server providers and processing agents;
(b) service providers under contract who help with parts of our business operations such as fraud prevention, bill collection, marketing and technology services;
(c) financial institutions with which we partner;
(d) people you choose to share and communicate with;
(e) people that see the content you choose to share and communicate with;
(f) other third parties with your consent or direction to do so. In addition, we may share some of your Personally Identifying Information with the person or company that you are paying or that is paying you through bergerz.com; and/or
(g) new ownership, if control or ownership of our Services or assets change.
Without limiting the above, occasionally we may be required by law enforcement or judicial authorities to provide Personally Identifiable Information to the appropriate governmental authorities. We will disclose Personally Identifiable Information upon receipt of a court order, subpoena, or to cooperate with a law enforcement investigation. We fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful. We may also provide Non-Personally Identifiable Information about our users’ traffic patterns, and related site information to third party advertisers.`,
  ];
  return (
    <BackButtonLayout pageTitle={"Privacy Policy"}>
      {/* common content */}
      {content.map((paraDetail, index) => (
        <Box key={index}>
          <Typography sx={headingStyle}>{paraDetail.heading}</Typography>
          {paraDetail.paragraph.map((paragraph, index) => (
            <Typography sx={paragraphStyle} key={index} variant="h3">
              {paragraph}
            </Typography>
          ))}
        </Box>
      ))}

      <Box>
        <Typography sx={headingStyle}>Where your data is sent</Typography>
        <Typography sx={paragraphStyle}>
          Visitor comments may be checked through an automated spam detection
          service.
        </Typography>
        <Box pl={2}>
          <List>
            <ListItem sx={{ display: "list-item", listStyleType: "decimal" }}>
              Privacy Policy
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box>
        <Typography sx={headingStyle}>
          Ploom Media.com Privacy Policy
        </Typography>
        <Typography sx={paragraphStyle}>
          This Privacy Policy discloses the privacy practices for the Ploom
          Media.com website, the Ploom Media mobile app and the various related
          services accessible through them (collectively referred to as the
          “Service”). By using the Service, you are consenting to the practices
          described in this Privacy Policy Notice. Please note, we are committed
          to protecting your privacy online. Please read the information below
          to learn the following regarding your use of this site:
        </Typography>
        <Box pl={2}>
          <List>
            {privacyPolicyPoints.map((point, index) => (
              <ListItem
                key={index}
                sx={{ display: "list-item", listStyleType: "decimal" }}
              >
                {point}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box pl={2}>
          <List>
            {privacyPointDetail.map((detail, index) => (
              <ListItem
                key={index}
                sx={{ display: "list-item", listStyleType: "decimal" }}
              >
                {detail}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </BackButtonLayout>
  );
}
