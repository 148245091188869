import React, { useEffect, useState } from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import KycStepper from "../component/indetityVerification/KycStepper";
import BasicInfoForm from "../component/indetityVerification/BasicInfoForm";
import { AddressForm } from "../component/indetityVerification/AddressForm";
import { SsnForm } from "../component/indetityVerification/SsnForm";
import { useAuth } from "../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";

const VerifyIndentity = () => {
  let [openForm, setOpenForm] = useState("step1"); //step1,step2,step3
  let [step, setStep] = useState(0);
  let { isLoggedIn } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BackButtonLayout pageTitle={"Verify Your Details"} isTitleBold>
      <KycStepper step={step} setOpenForm={setOpenForm}></KycStepper>
      {openForm === "step1" && (
        <BasicInfoForm
          setOpenForm={setOpenForm}
          setStep={setStep}
        ></BasicInfoForm>
      )}
      {openForm === "step2" && (
        <AddressForm setOpenForm={setOpenForm} setStep={setStep}></AddressForm>
      )}
      {openForm === "step3" && (
        <SsnForm setOpenForm={setOpenForm} setStep={setStep} />
      )}
    </BackButtonLayout>
  );
};

export default VerifyIndentity;
