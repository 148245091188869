import { Stack, Typography, useTheme, alpha } from "@mui/material";
import React from "react";

import { Box } from "@mui/system";

// import { formatNumberCount } from "../../../utils/NumberFormater";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { UsMoneyFormat } from "../../../utils/UsMoneyFormat";

export default function DonationDetails({
  daysRemaining,
  amountRaised,
  donorCount,
  goalAmount,
  begType,
  endedDate,
}) {
  const { typography, palette } = useTheme();
  const { darkMode } = useThemeStore();

  return (
    <>
      <Box
        mt="30px"
        width="100%"
        left={"15px"}
        right={"15px"}
        bottom={"7%"}
        color="white.1000"
        zIndex={2}
      >
        {/* <Typography
          ml={"8px"}
          fontSize={{ xs: "12px", md: "14px" }}
          color={{
            xs: "white.1000",
            md: darkMode ? "white.1000" : "grey.700",
          }}
          sx={{ opacity: ".8" }}
        >
          {daysRemaining !== null &&
            daysRemaining > 0 &&
            endedDate === undefined &&
            `${daysRemaining} days left to contribution`}

          {daysRemaining !== null &&
            daysRemaining === 0 &&
            endedDate === undefined &&
            "Contribution ends today"}

          {daysRemaining !== null &&
            daysRemaining < 0 &&
            endedDate === undefined &&
            `Contribution period ended`}

          {endedDate && `Contribution period ended`}
        </Typography> */}
        <Box
          // borderRadius={{ xs: "54px", md: "10px" }}
          overflow={"hidden"}
          display={"block"}
          mt={0.5}
          sx={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            borderTopRightRadius: { xs: 0, md: "10px" },
            borderTopLeftRadius: { xs: 0, md: "10px" },
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
            placeContent: "center",
            backgroundColor: {
              xs: darkMode ? "black.250" : "black.350",
              md: darkMode ? "grey.550" : "white.1000",
            },
            backdropFilter: "blur(20px)",
          }}
        >
          <Box
            padding={{
              xs: "13px 14px 13px 14px",
              sm: "13px 20px 15px 20px",
              md: "17px 15px 17px 15px",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"5px"}
                  fontSize={{ xs: "14px", md: "14px" }}
                  fontWeight={700}
                  color={{
                    xs: "white.1000",
                    md: darkMode ? "white.1000" : "bgDark.third",
                  }}
                  fontFamily={typography.inter}
                >
                  ${UsMoneyFormat(amountRaised)}
                </Typography>
                <Typography
                  fontSize={{ xs: "14px", md: "14px" }}
                  fontWeight={{ xs: 700 }}
                  fontFamily={typography.inter}
                  lineHeight={"normal"}
                  fontStyle={"normal"}
                  color={{
                    xs: "white.1000",
                    md: darkMode ? "white.1000" : "bgDark.third",
                  }}
                >
                  Raised
                  {/* (Donates
                      {donorCount > 0 && " " + UsMoneyFormat(donorCount)}) */}
                </Typography>
              </Stack>
              {goalAmount !== undefined && begType === 1 && (
                <Typography
                  fontSize={{ xs: "12px", md: "14px" }}
                  fontWeight={700}
                  fontFamily={typography.inter}
                  color={{
                    xs: "white.1000",
                    md: darkMode ? "white.1000" : "bgDark.third",
                  }}
                >
                  {/* Need of ${formatNumberCount(goalAmount)} */}
                  Goal ${UsMoneyFormat(goalAmount)}
                </Typography>
              )}
            </Box>
            {begType === 1 && (
              <Box
                sx={{
                  mt: 1,
                  backgroundColor: {
                    xs: alpha(palette.white["100"], 0.1),
                    md: darkMode ? "grey.750" : "grey.800",
                  },
                }}
                width="100%"
                height={{ xs: "6px", md: "8px" }}
                borderRadius={1}
                overflow={"hidden"}
              >
                <Box
                  backgroundColor={
                    "success.progressBar"
                    // xs: "white.1000",
                    // md: darkMode ? "white.1000" : "success.secondary",
                  }
                  width={
                    ((amountRaised / goalAmount) * 100 > 100
                      ? 100
                      : (amountRaised / goalAmount) * 100) + "%"
                  }
                  height={"100%"}
                  borderRadius={1}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
