import React from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import CustomInfinite from "../component/ui/CustomInfinite";
import ListItemSkeleton from "../component/skeleton/ListItemSkeleton";
import DonationListItem from "../component/donarHistory/DonationListItem";
import { APIS } from "../api/lists";
import { useParams } from "react-router-dom";

export default function DonarHistory({ begId, title }) {
  const parmas = useParams();

  return (
    <BackButtonLayout pageTitle={"Donor History"}>
      <CustomInfinite
        url={APIS.FEED.DONATION_LIST(parmas.id)}
        Skeleton={ListItemSkeleton}
        ListItem={({ item }) => (
          <DonationListItem
            item={{
              amount: item.amount,
              createdAt: item.createdAt,
              user: item.anonymous
                ? {}
                : item.donor
                ? item.donor
                : { username: item.donorName },
            }}
            key={item._id}
          />
        )}
      >
        Donor History is not available !
      </CustomInfinite>
    </BackButtonLayout>
  );
}
