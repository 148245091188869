import { Box, Button, Divider, Stack, Typography, alpha } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import VerifiedIcon from "../../icons/VerifiedIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import { useAllModal } from "../../hooks/store/useAllModal";
import moment from "moment";
import { useAuth } from "../../hooks/store/useAuth";
import { useUserVerification } from "../../hooks/store/useUserVerification";

const KycDialog = ({ handleClose, deleteFile, isLoading }) => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { kycCompleteDate, setOpenKycDialog, setShowIdentityDrawer } =
    useAllModal();
  const { isLoggedIn } = useAuth();
  const { kyc } = useUserVerification();
  return (
    <>
      <Box
        sx={{
          minWidth: { md: "534px" },
          width: "100%",
          display: "grid",
          placeItems: "center",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "20px 20px 0 20px", md: "30px 30px 0 30px" },
          }}
        >
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Poppins",
              fontSize: { xs: "24px", md: "32px" },
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            Verified Account
          </Typography>

          <Stack sx={{ mt: "10px", gap: "10px" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <VerifiedIcon id={"verified_badge_12"} />
              <Typography
                sx={{
                  color: darkMode ? "grey.900" : "black.350",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: { xs: "14px", md: "18px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "-0.408px",
                }}
              >
                This Account Is Verified
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <CalendarIcon
                color={darkMode ? palette.white[1000] : palette.black[1000]}
              />
              <Typography
                sx={{
                  color: darkMode ? "grey.900" : "black.350",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: { xs: "14px", md: "18px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "-0.408px",
                }}
              >
                Verified Since {moment(kycCompleteDate).format("MMMM  yyyy")}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Divider
          light
          sx={{
            mt: "30px",
            width: { xs: "100%", md: "calc(100% - 80px)" },
            background: darkMode ? "black.200" : alpha(palette.grey[100], 0.4),
          }}
        />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: { md: "10px 0 0px 0" },
          }}
        >
          <Button
            variant="text"
            fullWidth
            sx={{
              paddingBlock: "15px",
              maxWidth: "unset",
              color: darkMode ? "white.1000" : "black.1000",
              "&:hover": { background: "none" },
            }}
            onClick={() => {
              setOpenKycDialog(false, kycCompleteDate);
            }}
          >
            Cancel
          </Button>
          {isLoggedIn && kyc !== 1 && (
            <>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: darkMode
                    ? "black.200"
                    : alpha(palette.grey[100], 0.4),
                  height: "40px",
                  transform: "translateY(20%)",
                }}
              />
              <Button
                variant="text"
                fullWidth
                sx={{
                  paddingBlock: "15px",
                  color: darkMode ? "white.1000" : "black.1000",
                  "&:hover": { background: "none" },
                  "&.MuiLoadingButton-root.Mui-disabled": {
                    color: darkMode ? "black.100" : "black.100",
                  },
                }}
                onClick={() => {
                  setShowIdentityDrawer(true);
                  return setOpenKycDialog();
                }}
              >
                Get Verified
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default KycDialog;
