import * as React from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { Stack, TextField } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { useTheme } from "@emotion/react";

const GOOGLE_MAPS_API_KEY = "AIzaSyDs6qwftq-BaeLNY6Q27qdiqPlVeAxUXnk";

async function fetchGeocodeData(placeId) {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_API_KEY}&place_id=${placeId}`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching geocode data:", error);
    throw error;
  }
}

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function AddressInput({
  name,
  inputLabel,
  placeholder,
  sx,
  register,
  errors,
  updateState,
  updateCity,
  updateZipCode,
  value: address,
}) {
  const [value, setValue] = React.useState(address ? address : "");
  const [inputValue, setInputValue] = React.useState(address ? address : "");
  const [options, setOptions] = React.useState([]);
  const [place_Id, setPlaceId] = React.useState("");
  const loaded = React.useRef(false);
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();

  // calling function for get additiondal place details using google map api
  async function fetchData(placeId) {
    try {
      const geocodeData = await fetchGeocodeData(placeId);
      const address = geocodeData.results[0].address_components;
      const postalCode = address.find((component) =>
        component.types.includes("postal_code")
      );

      if (postalCode) {
        const zipCode = postalCode.long_name;
        // Now you can use `zipCode` for further processing or set it in the state.
        updateZipCode(zipCode);
      } else {
        updateZipCode("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  React.useEffect(() => {
    if (place_Id !== "") {
      fetchData(place_Id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place_Id]);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&region=us`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: "us" } },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            let state =
              value.terms !== undefined &&
              value?.terms[value?.terms?.length - 1];
            let city =
              value.terms !== undefined &&
              value?.terms[value?.terms?.length - 2];
            value.terms !== undefined &&
              updateState(state?.value ? state?.value : "");
            value.terms !== undefined &&
              updateCity(value?.terms.length >= 3 ? city?.value : "");
            value.terms !== undefined && setPlaceId(value?.place_id);
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{
        width: "100%",
        "& label.Mui-focused": {
          color: darkMode ? palette.white[1000] : palette.primary.main,
        },
        "&.Mui-focused .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
          borderColor: darkMode ? "white.1000" : "black.1000",
          // "& label": {
          // },
        },
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      // autoComplete
      includeInputInList
      filterSelectedOptions
      defaultValue={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          id="outlined-number/"
          {...params}
          name={name}
          label={inputLabel}
          value={address}
          placeholder={placeholder}
          sx={{
            mt: { xs: "25px", md: "25px" },
            textTransform: "capitalize",
            "input:placeholder-shown": { border: "none" },
            "input:focus": { border: "none" },
            "input:hover": { border: "none" },
            "& label": {
              color: darkMode ? "white.1000" : "primary.main",
              fontSize: "16px",
              fontWeight: 500,
              fontStyle: "normal",
              lineHeight: "normal",
              textTransform: "capitalize",
            },
            "& fieldset": {
              borderRadius: "8px",
              border: "1px solid",
              borderColor: darkMode ? "neutral.650" : "bgWhite.secondary",
            },
            "& input,input:is(:hover,:focus)": {
              border: "none",
              zIndex: 2,
              color: darkMode ? "white.1000" : "black.1000",
              opacity: 1,
            },
            ".MuiFormHelperText-root": {
              marginInline: 0,
              mt: "5px",
            },
            // ...sx,
          }}
          {...register(name)}
          error={Boolean(errors[name])}
          helperText={
            errors[name] && (
              <>
                <Stack
                  component={"span"}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ErrorOutlineRoundedIcon
                    sx={{ fontSize: "16px", mr: "10px" }}
                  />
                  <Box component={"span"}>{errors[name]?.message}</Box>
                </Stack>
              </>
            )
          }
          InputLabelProps={{
            shrink: true,
            sx: {
              color: darkMode ? palette.white[1000] : palette.primary.main,
            },
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = parse(
          option?.structured_formatting?.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Box component={"li"} {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word",
                }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    />
  );
}
