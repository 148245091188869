import { createSvgIcon } from "@mui/material";
export default function CrossIcon(props) {
  let color = props.color;
  const CreateCrossIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_44_28759)">
        <path
          d="M9.46582 8.01267L15.696 1.78239C16.1014 1.37712 16.1014 0.721854 15.696 0.316641C15.2907 -0.08862 14.6355 -0.08862 14.2303 0.316641L7.99993 6.54688L1.76984 0.316641C1.36438 -0.08862 0.709353 -0.08862 0.304092 0.316641C-0.101364 0.721903 -0.101364 1.37712 0.304092 1.78239L6.53413 8.01267L0.30414 14.2429C-0.101315 14.6482 -0.101315 15.3034 0.30414 15.7087C0.40027 15.8051 0.514502 15.8815 0.640272 15.9336C0.766042 15.9858 0.900871 16.0125 1.03701 16.0124C1.30233 16.0124 1.56774 15.9108 1.76988 15.7087L7.99993 9.47842L14.2303 15.7087C14.3264 15.805 14.4406 15.8815 14.5664 15.9336C14.6922 15.9857 14.827 16.0125 14.9631 16.0124C15.2284 16.0124 15.4939 15.9108 15.696 15.7087C16.1014 15.3034 16.1014 14.6482 15.696 14.2429L9.46582 8.01267Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_44_28759">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateCrossIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
      }}
      {...props}
    />
  );
}
