import React, { useEffect } from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";

import ProfileFeedCard from "../component/profilefeedcard/ProfileFeedCard";

import CustomInfinite from "../component/ui/CustomInfinite";
import { APIS } from "../api/lists";
import CardSkeleton from "../component/skeleton/CardSkeleton";
import useApi from "../hooks/useApi";
import { useSnack } from "../hooks/store/useSnack";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/store/useAuth";
const ListItem = ({ item, setResults }) => {
  const { apiCall, isLoading } = useApi();
  const { setSnack } = useSnack();
  // function for remove bookmark
  async function removeBookmark(setResults, begId) {
    try {
      const response = await apiCall({
        url: APIS.BOOKMARK.REMOVE_BOOKMARK,
        data: { begId },
        method: "delete",
      });
      setSnack(response.data.message);
      setResults((prev) => prev.filter((beg) => beg.beg._id !== begId));
    } catch (error) {
      console.log(error);
      setSnack(error.response.data.message, "error");
    }
  }
  return (
    <ProfileFeedCard
      feed={item.beg}
      bookmark={true}
      removeBookmark={removeBookmark.bind(null, setResults)}
      isLoading={isLoading}
    />
  );
};

const SavedFeed = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <BackButtonLayout titlePrefix={"Saved"}>
        <CustomInfinite
          dataKey="data"
          url={APIS.FEED.SAVE_LIST}
          listSx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr 1fr",
              xsm: "1fr 1fr 1fr",
            },
            gap: { xs: "10px", md: "20px" },
            marginTop: "12px",
            padding: "0px",
          }}
          renderLoaderInList
          ListItem={ListItem}
          Skeleton={() => (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          )}
        >
          No saved feed found !
        </CustomInfinite>
        {/* {savedFeed.map((feed) => (
            <ProfileFeedCard
              ownProfile={true}
              feed={feed.beg}
              bookmark={true}
              key={feed._id}
            />
          ))} */}
      </BackButtonLayout>
    </>
  );
};

export default SavedFeed;
