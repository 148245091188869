import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import SadPathSvg from "../../icons/SadPathSvg";
import useThemeStore from "../../hooks/store/useThemeStore";
import { Link, useNavigate } from "react-router-dom";
import { useSettings } from "../../hooks/store/useSettings";
import { useAllModal } from "../../hooks/store/useAllModal";

const SadPath = ({ message }) => {
  const { darkMode } = useThemeStore();
  const { settings } = useSettings();
  const { setShowIdentityDrawer } = useAllModal();
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          minWidth: { xs: "100%", xsm: "440px", md: "620px" },
          p: { xs: "15px", md: 4 },
          borderRadius: "12px",
        }}
      >
        <Stack sx={{ width: "70%", mx: "auto" }}>
          <SadPathSvg></SadPathSvg>
        </Stack>
        <Typography
          sx={{
            mt: "30px",
            color: "error.main",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "26px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
          }}
        >
          Sorry!
        </Typography>

        {message === "" ? (
          <Typography
            sx={{
              mt: "30px",
              color: darkMode ? "grey.900" : "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
              maxWidth: "475px",
              mx: "auto",
            }}
          >
            You have failed your ID verification more than {settings.kyc_counts}{" "}
            times, please contact PloomSocial Support (
            <Stack
              target="_blank"
              to={`mailto:${settings.supportEmail}`}
              component={Link}
              sx={{
                color: darkMode ? "white.1000" : "primary.main",
                display: "inline",
              }}
            >
              {settings.supportEmail}
            </Stack>
            )
          </Typography>
        ) : (
          <Typography
            sx={{
              mt: "15px",
              color: darkMode ? "grey.900" : "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            {message}
          </Typography>
        )}

        <Stack
          sx={{
            mt: "22px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "12px",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            sx={{ paddingBlock: { xs: "12px", md: "15px" } }}
            onClick={() => {
              setShowIdentityDrawer(false);
              navigate("/");
            }}
          >
            go to Home
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{ paddingBlock: { xs: "12px", md: "15px" } }}
            component={Link}
            to={`mailto:${settings.supportEmail}`}
          >
            Contact Us
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default SadPath;
