import { useCallback, useState } from "react";
import { instance } from "../api/instance";
import { useAuth } from "./store/useAuth";
import useRefresh from "./useRefresh";
import { useSnack } from "./store/useSnack";

export default function useApi(auth = false) {
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useAuth();
  const refresh = useRefresh();
  const { setSnack } = useSnack();
  const { logout } = useAuth();
  const apiCall = useCallback(
    async function (config = { url: "", method: "", params: {}, data: {} }) {
      const apiInstance = instance(auth);

      try {
        setIsLoading(true);

        if (accessToken) {
          apiInstance.interceptors.request.use(
            (reqConfig) => {
              if (!reqConfig.headers.Authorization)
                reqConfig.headers.Authorization = "Bearer " + accessToken;
              return reqConfig;
            },
            (error) => Promise.reject(error)
          );

          apiInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
              const prevRequest = error?.config;

              // condition for refresh token
              if (
                (error?.response?.status === 403 ||
                  error?.response?.status === 401) &&
                !prevRequest?.sent
              ) {
                prevRequest.sent = true;
                const newAccessToken = await refresh();

                prevRequest.headers[
                  "Authorization"
                ] = `Bearer ${newAccessToken}`;
                return apiInstance(prevRequest);
              } else if (
                error?.response?.status === 400 &&
                error.response?.data.data.logout === true
              ) {
                logout();
                setSnack(error.response.data.message, "error");
                window.scrollTo(0, 0);
                return setTimeout(() => {
                  window.location = "/";
                }, 4000);
              } else throw error;
            }
          );
        }
        const res = await apiInstance(config);
        setIsLoading(false);
        return res;
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, accessToken, refresh]
  );
  return {
    isLoading,
    setIsLoading,
    apiCall,
  };
}
