import { createSvgIcon } from "@mui/material";
export default function EyeIcon(props) {
  let color = props.color;
  const EyeIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/Show">
        <g id="Show">
          <path
            id="Stroke 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1609 12.0526C15.1609 13.7986 13.7449 15.2136 11.9989 15.2136C10.2529 15.2136 8.83789 13.7986 8.83789 12.0526C8.83789 10.3056 10.2529 8.89062 11.9989 8.89062C13.7449 8.89062 15.1609 10.3056 15.1609 12.0526Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            id="Stroke 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.998 19.354C15.806 19.354 19.289 16.616 21.25 12.052C19.289 7.488 15.806 4.75 11.998 4.75H12.002C8.194 4.75 4.711 7.488 2.75 12.052C4.711 16.616 8.194 19.354 12.002 19.354H11.998Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </g>
      </g>
    </>
  );
  return (
    <EyeIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
