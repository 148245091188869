import { create } from "zustand";
import { persist } from "zustand/middleware";

const useImmersivePage = create(
  persist(
    (set) => ({
      begDetails: undefined, // Initialize commentCount
      setBegDetails(value) {
        set({ begDetails: value });
      },
      resetStore() {
        set({ begDetails: undefined }); // Reset commentCount
      },
      incrementCommentCount(value) {
        set((state) => ({
          begDetails: {
            ...state.begDetails,
            comments: value,
          },
        }));
      },
      updateAuthorFollowing(value) {
        set((state) => ({
          begDetails: {
            ...state.begDetails,
            author: {
              ...state.begDetails.author,
              following: value,
            },
          },
        }));
      },
    }),
    {
      name: "immersive-page-storage",
      getStorage: () => localStorage,
    }
  )
);

export { useImmersivePage };
