import { createSvgIcon } from "@mui/material";

export default function SearchIcon(props) {
  // const { palette } = useTheme();
  const { color } = props;
  let CreateSearchIcon = createSvgIcon(
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3.25C6.66751 3.25 2.75 7.16751 2.75 12C2.75 16.8325 6.66751 20.75 11.5 20.75C16.3325 20.75 20.25 16.8325 20.25 12C20.25 7.16751 16.3325 3.25 11.5 3.25ZM1.25 12C1.25 6.33908 5.83908 1.75 11.5 1.75C17.1609 1.75 21.75 6.33908 21.75 12C21.75 14.5605 20.8111 16.9017 19.2589 18.6982L22.5303 21.9697C22.8232 22.2626 22.8232 22.7374 22.5303 23.0303C22.2374 23.3232 21.7626 23.3232 21.4697 23.0303L18.1982 19.7589C16.4017 21.3111 14.0605 22.25 11.5 22.25C5.83908 22.25 1.25 17.6609 1.25 12Z"
        fill={color}
      />
    </>
  );
  return (
    <CreateSearchIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      sx={{ color: "white.650" }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
