import { Button, CircularProgress } from "@mui/material";
import React from "react";

export default function ButtonWithLoading({
  disabled = false,
  isLoading,
  children,
  onClick = () => {},
  variant = "contain",
  ...rest
}) {
  return (
    <Button
      color="primary"
      disabled={disabled || isLoading}
      //   fullWidth
      //   size="large"
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      {children}
      {isLoading && (
        <CircularProgress color="neutral" size={18} sx={{ ml: 0.8 }} />
      )}
    </Button>
  );
}
