import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import DustbinIcon from "../../icons/DustbinIcon";
import { Link } from "react-router-dom";
import DialogBox from "../Dialogbox/DialogBox";
import DeleteDialog from "./DeleteDialog";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import { useSnack } from "../../hooks/store/useSnack";
import MediaInfoLayer from "../mediaInfoDialog/MediaInfoLayer";

const CurrentMedia = ({
  allFilesUrl: currentVideos,
  begId,
  setUploadLimit,
  hideRearrang = false,
  draft = false,
}) => {
  let [allFilesUrl, setAllFilesUrl] = useState(currentVideos);
  const { darkMode } = useThemeStore();
  const { apiCall, isLoading } = useApi();
  const { setSnack } = useSnack();
  const [selectedId, setSelectedId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  let fileUnderProcessing = allFilesUrl.filter(
    (file) => file.processingStatus === "processing"
  );

  // function for hide deleted record
  function removeAsset(id) {
    setAllFilesUrl((prev) => prev.filter((video) => video._id !== id));
    setTimeout(() => {
      setUploadLimit(10 - allFilesUrl.length + 1);
    }, 100);
  }

  // function for delete video
  async function deleteFile(id) {
    try {
      let response = await apiCall({
        url: APIS.FEED.DELETE_FILE(id),
        method: "DELETE",
        data: { id: begId, isDraft: draft },
      });
      setSnack(response.data.message);
      removeAsset(id);
    } catch (error) {
      setSnack(error.response.data.message, "error");
    }
  }

  // function for check delete options
  function checkDelete(id) {
    let CurrentTwoHateFlag = allFilesUrl.filter((video) => video._id === id);
    CurrentTwoHateFlag = JSON.stringify(CurrentTwoHateFlag[0].twoHatflagged);
    let totalAssets = allFilesUrl.length;
    let totalVideo = allFilesUrl.filter((file) => file.type === 0).length;
    let totalVideoTwoHate = allFilesUrl.filter(
      (file) => file.type === 0 && file.twoHatflagged === true
    ).length;
    let currentMediatype = allFilesUrl.filter((file) => file._id === id);
    currentMediatype = JSON.stringify(currentMediatype[0].type);
    // eslint-disable-next-line
    if (currentMediatype == 1) {
      //image
      return totalAssets > 1 ? true : false;
    } else {
      // video
      let finalValue = false;

      // eslint-disable-next-line eqeqeq
      if (CurrentTwoHateFlag == "true") {
        return true;
      }

      if (
        totalVideoTwoHate === totalVideo - 1 &&
        // eslint-disable-next-line eqeqeq
        CurrentTwoHateFlag == "false"
      ) {
        return false;
      }

      // eslint-disable-next-line eqeqeq
      if (totalVideo > 1 && totalVideoTwoHate == 0) {
        return true;
      }

      if (totalVideo - totalVideoTwoHate > 1) {
        return true;
      }
      return finalValue;
    }
  }

  return (
    <>
      <Box sx={{ mt: "12px" }}>
        <Stack
          sx={{
            maxWidth: { lg: "450px" },
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            Media
          </Typography>

          {allFilesUrl.length > 1 &&
            fileUnderProcessing.length === 0 &&
            !hideRearrang && (
              <Button
                component={Link}
                to={`/re-arrange/${begId}`}
                variant="text"
                sx={{
                  textTransform: "capitalize",
                  color: darkMode ? "white.1000" : "primary.main",
                }}
              >
                Rearrange
              </Button>
            )}
        </Stack>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fill, minmax(90px, 1fr))",
              md: "repeat(auto-fill, minmax(113px, 1fr))",
            },
            gap: "15px",
            mt: "10px",
          }}
        >
          {allFilesUrl.map((file) => (
            <Box
              key={file._id}
              sx={{
                height: { xs: "90px", md: "113px" },
                // width: { xs: "90px", md: "113px" },
                borderRadius: "5px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              {checkDelete(file._id) &&
                fileUnderProcessing.length === 0 &&
                file.processingStatus !== "processing" && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      zIndex: 50,
                      top: { xs: "3.44px", md: "4.56px" },
                      right: { xs: "3.44px", md: "4.56px" },
                      padding: "1px",
                    }}
                    onClick={() => {
                      setSelectedId(file._id);
                      setOpenDialog(true);
                    }}
                  >
                    <DustbinIcon
                      sx={{
                        height: { xs: "16.375px", md: "16.425px" },
                        width: { xs: "16.375px", md: "16.425px" },
                      }}
                    />
                  </IconButton>
                )}
              {file.thumbLink && file.processingStatus !== "processing" && (
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt="video.thumbLink"
                  src={file?.thumbLink}
                  height={80}
                  width={80}
                ></img>
              )}
              <MediaInfoLayer
                twoHate={file.twoHatflagged}
                processingStatus={file.processingStatus}
                adminFlag={file.isDelete}
                mediaPoint={"xs"}
                modifyHeight={false}
              ></MediaInfoLayer>
            </Box>
          ))}
        </Box>
      </Box>

      {/* delete media dialog box */}
      <DialogBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        Content={({ handleClose }) => (
          <DeleteDialog
            isLoading={isLoading}
            handleClose={handleClose}
            deleteFile={async () => {
              await deleteFile(selectedId);
              setOpenDialog(false);
            }}
          />
        )}
      ></DialogBox>
    </>
  );
};

export default CurrentMedia;
