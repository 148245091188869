import { create } from "zustand";

export const useSettings = create((set) => ({
  settings: {},
  setSettings(settings) {
    set(() => ({
      settings,
    }));
  },
}));
