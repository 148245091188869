import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Heading from "../ui/Heading";
import WithdrawalAmount from "../payout/WithdrawalAmount";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import FeedWithdrawalList from "./FeedWithdrawalList";
import { isPast } from "../../utils/time";

export default function WithdrawalDetail({
  title,
  showChargesDetail,
  feedDetail,
  withdrawals,
  isLoading,
  feed,
}) {
  const { darkMode } = useThemeStore();
  const { border } = useTheme();
  const paragraphStyles = {
    color: darkMode ? "white.1000" : "black.1000",
    fontSize: "14px",
    lineHeight: 1.5,
    fontWeight: 600,
  };
  let endDate = feed.endedDate || feed.goalDate;

  let disableNextStepButton = !(feedDetail.available > 10);
  if (withdrawals.length > 0) {
    disableNextStepButton = !(
      feedDetail.available >= 10 &&
      (withdrawals[0].status === "Completed" ||
        withdrawals[0].status === "Failed")
    );
  } else if (isPast(endDate)) {
    disableNextStepButton = feedDetail.available < 0;
  }
  const nextStep = () => {
    if (disableNextStepButton) {
      return;
    }
    showChargesDetail();
  };
  return (
    <Box>
      <Box>
        <Heading>{title}</Heading>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}
        >
          <WithdrawalAmount
            amount={feedDetail.total}
            text="Total Contributions"
          />
          <WithdrawalAmount
            amount={feedDetail.available}
            text="Available Balance"
          />
        </Box>
        {withdrawals[0] && (
          <Box
            display={"flex"}
            sx={{
              mt: "15px",
              backgroundColor: darkMode ? "grey.550" : "primary.light10",
              p: "10px 15px",
              borderRadius: border.radius,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                flexGrow: "1",
                ...paragraphStyles,
                color: !darkMode ? "primary.main" : "white.1000",
              }}
            >
              Transaction History
            </Typography>
            <Typography
              sx={{
                flexShrink: "0",
                ...paragraphStyles,
                color: !darkMode ? "primary.main" : "white.1000",
              }}
            >
              Total Withdraw $
              {withdrawals[0].status === "Pending" ||
              withdrawals[0].status === "Created"
                ? feedDetail.total - (withdrawals[0]?.withdrawalAmount || 0)
                : feedDetail.withdraw}
            </Typography>
          </Box>
        )}
        <FeedWithdrawalList withdrawals={withdrawals} isLoading={isLoading} />
        {withdrawals.length > 0 &&
          (withdrawals[0].status === "Pending" ||
            withdrawals[0].status === "Created") && (
            <Typography
              sx={{
                color: darkMode ? "primary.disabledLight" : "black.350",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: 1.5,
                textAlign: "center",
                mt: "14px",
              }}
            >
              Until your previous transactions are successfully deposited into
              your bank account, you won't be able to withdraw additional funds.
              This process usually takes up to 5 business days.
            </Typography>
          )}
        <Button
          variant="contained"
          sx={{
            width: "100%",
            borderRadius: "10px",
            textTransform: "capitalize",
            fontSize: "14px",
            lineHeight: 1.5,
            fontWeight: 500,
            p: { xs: "10px", md: "15px" },
            textDecoration: "none",
            mt: "25px",
            "&.Mui-disabled": {
              color: darkMode ? "black.100" : "white.1000",
              backgroundColor: darkMode
                ? "primary.disabledDark"
                : "primary.disabledLight",
            },
          }}
          onClick={nextStep}
          disabled={disableNextStepButton}
        >
          Deposit Funds
        </Button>
      </Box>
    </Box>
  );
}
