import { Box } from "@mui/material";
import React from "react";

const BlurBgCircle = ({ style }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "-63px",
        left: "-142px",
        width: "580px",
        height: "580px",
        display: { xs: "none", md: "block" },
        opacity: 0.3,
        background:
          "linear-gradient(180deg, #4836E9 0%, #AD2E99 51.56%, #EE5F34 100%)",
        borderRadius: "50%",
        filter: "blur(136.5px)",
        zIndex: 0,
        ...style,
      }}
    >
      <Box
        width={"100%"}
        height={"100%"}
        sx={{
          borderRadius: "50%",
          backdropFilter: "blur(136.5px)",
        }}
      ></Box>
    </Box>
  );
};

export default BlurBgCircle;
