import React from "react";
import { Box, Typography, alpha } from "@mui/material";

import PasswordInput from "../component/ui/form/PasswordInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonWithLoading from "../component/ui/button/ButtonWithLoading";
import { useSnack } from "../hooks/store/useSnack";
import useApi from "../hooks/useApi";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../component/ScrollToTop";
import DynamicMeta from "../utils/DynamicMeta";
import useThemeStore from "../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import Shield from "../icons/Shield";
import Navbar from "../component/navbar/Navbar";
import BG from "../assets/forgotPasswordBg.png";

export default function ResetPassword() {
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const { apiCall } = useApi(true);
  const { typography, palette } = useTheme();

  // form handling
  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;

  const schema = Yup.object({
    newPassword: Yup.string()
      .required("password is required.")
      .matches(
        passwordRegExp,
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@$!%*?&)"
      ),
    confirmPassword: Yup.string()
      .required("confirm password is required.")
      .oneOf(
        [Yup.ref("newPassword")],
        "New Passwords and Confirm Password must match"
      )
      .min(8)
      .max(32),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const resetPassword = async (values) => {
    try {
      const res = await apiCall({
        method: "post",
        url: "v2/reset-password",
        data: {
          password: values.newPassword,
          resetToken: resetToken,
        },
      });
      setSnack(res.data.message);
      return navigate("/signin");
    } catch (error) {
      setSnack("please re-generate forgot password link", "error");
      console.log(error);
    }
  };
  return (
    <>
      <DynamicMeta title="Reset Password | Ploom Social" />
      <ScrollToTop></ScrollToTop>
      <Navbar />
      <Box
        sx={{
          minHeight: "100vh",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          background: darkMode
            ? `${alpha(palette.black["1000"], 0.8)} url(${BG})`
            : `${palette.white["1000"]} url(${BG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          component={"form"}
          onSubmit={handleSubmit(resetPassword)}
          sx={{
            margin: "0 auto",
            width: { xs: "100%", sm: "auto" },
            mt: { md: "40px" },
            display: { xs: "flex", md: "block" },
            flexDirection: "column",
            height: { xs: "90vh", md: "unset" },
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            maxWidth: " 540px",
            p: { xs: "20px", md: "50px" },
            borderRadius: { xs: "10px", md: "24px" },
          }}
        >
          {/* form headings */}
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.450",
              fontFamily: "Manrope",
              fontSize: { xs: "26px", md: "36px" },
              fontStyle: "normal",
              fontWeight: { xs: 800, md: 700 },
              lineHeight: { xs: "40px", md: "122%" },
              letterSpacing: { xs: "-0.52px", md: "-0.72px" },
            }}
          >
            Set Password,
          </Typography>

          <Typography
            sx={{
              mt: "10px",
              color: darkMode ? "grey.900" : "neutral.550",
              fontFamily: "Manrope",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "152%",
              minWidth: { sm: "400px" },
            }}
          >
            Connecting Mentors with Purpose
          </Typography>

          <Box sx={{ paddingTop: { lg: "10px" } }}>
            <PasswordInput
              name="newPassword"
              inputLabel={"Password"}
              register={register}
              errors={formState.errors}
              placeholder="Enter Your Password"
              icon={
                <Shield
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
            />
            <PasswordInput
              name="confirmPassword"
              inputLabel={"Confirm Password"}
              register={register}
              errors={formState.errors}
              placeholder="Enter Your Password"
              icon={
                <Shield
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
            />
          </Box>

          <ButtonWithLoading
            color="primary"
            disabled={!formState.isValid || formState.isSubmitting}
            isLoading={formState.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              fontFamily: typography.manrope,
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "152%",
              padding: "14px 12px",
              marginTop: { xs: "25px", lg: "30px" },
              borderRadius: "10px",
              textTransform: "capitalize",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Confirm
          </ButtonWithLoading>
        </Box>
      </Box>
    </>
  );
}
