import { CardActions, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import HeartWhiteIcon from "../../../icons/HeartWhiteIcon";
import SendIcon from "../../../icons/SendIcon";
import ChatIcon from "../../../icons/ChatIcon";
import { Box } from "@mui/system";

import { useAuth } from "../../../hooks/store/useAuth";
import { useSnack } from "../../../hooks/store/useSnack";
import LikeFillIcon from "../../../icons/LikeFillIcon";
import useApi from "../../../hooks/useApi";
import { useFeed } from "../../../hooks/store/useFeed";
import { formatNumberCount } from "../../../utils/NumberFormater";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useAllModal } from "../../../hooks/store/useAllModal";
import { APIS } from "../../../api/lists";

export default function FeedCardAction({
  imageUrl,
  title,
  likeCount,
  commentCount,
  shareCount,
  id,
  isLiked,
  setOpenTab,
  htmlDescription,
  author,
  goalAmount,
  amountRaised,
  shareLink,
}) {
  const [feedStat, setFeedStat] = useState({
    like: likeCount,
    shares: shareCount,
  });
  const [liked, setLiked] = useState(Boolean(isLiked));
  const { likeFeed } = useFeed();
  const { apiCall, isLoading } = useApi();
  const { isLoggedIn } = useAuth();
  const { setSnack } = useSnack();
  const { darkMode } = useThemeStore();
  const { setShowShareDrawer } = useAllModal();
  const checkLogin = (message) => {
    if (!isLoggedIn) {
      setSnack(message, "warning");
      return false;
    }
    return true;
  };

  function getShareLink() {
    setShowShareDrawer(
      true,
      id,
      title,
      imageUrl,
      false,
      htmlDescription,
      author,
      { amountRaised, goalAmount },
      shareLink
    );
  }

  const feedAction = [
    {
      Icon: liked ? LikeFillIcon : HeartWhiteIcon,
      value: formatNumberCount(feedStat.like),
      name: "like",
      props: {
        color: darkMode ? "white" : "black",
        width: { xs: "20px", md: "32px" },
        height: { xs: "20px", md: "32px" },
        viewBox: { xs: "0 0 20 20", md: "0 0 32 32" },
      },
      disabled: isLoading,
      onClick: async () => {
        if (!checkLogin("please login to like post")) return;
        const res = await apiCall({
          url: APIS.LIKE.CREATE,
          method: "post",
          data: {
            itemType: "beg",
            itemId: id,
            begId: id,
          },
        });
        if (res.status === 200) {
          setLiked(!liked);
          // setSnack(
          //   !liked
          //     ? "post liked successfully"
          //     : "post like removed successfully"
          // );
          likeFeed(id);
          setFeedStat((prevState) => ({
            ...prevState,
            like: prevState.like + (!liked ? 1 : -1),
          }));
        }
      },
    },
    {
      Icon: ChatIcon,
      value: formatNumberCount(commentCount),
      name: "comment",
      props: {
        width: { xs: "20px", md: "32px" },
        color: darkMode ? "white" : "black",
        height: { xs: "20px", md: "32px" },
        viewBox: { xs: "0 0 20 20", md: "0 0 32 32" },
      },
      onClick: () => {
        setOpenTab("comments");
      },
      //   () => {if (!checkLogin("login to view comment")) return;
      //   setShowModal(true);
      // },
    },
    {
      Icon: SendIcon,
      value: formatNumberCount(feedStat.shares),
      props: {
        color: darkMode ? "white" : "black",
        width: { xs: "20px", md: "32px" },
        height: { xs: "20px", md: "32px" },
        viewBox: { xs: "0 0 20 20", md: "0 0 32 32" },
      },
      name: "share",
      onClick: () => {
        getShareLink();
        // setFeedStat((prevState) => ({
        //   ...prevState,
        //   shares: prevState.shares + 1,
        // }));
      },
    },
    // {
    //   Icon: MonetizationOnOutlinedIcon,
    //   value: donorCount,
    //   name: "donor",
    // },
    // {
    //   Icon: FileDownloadOutlinedIcon,
    //   //   value: 0,
    //   name: "downloads",
    // },
  ];

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <CardActions
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* like and comment button and others */}
        <Stack
          display={"flex"}
          flexDirection={"row"}
          gap={{ xs: "30px", md: "40px" }}
        >
          {feedAction.map((action) => (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"10px"}
              key={action.name}
              textAlign={"center"}
              sx={{ opacity: action.disabled === true ? 0.5 : 1 }}
            >
              <IconButton
                sx={{ p: 0, scale: 1.2 }}
                onClick={action.onClick}
                children={
                  <action.Icon
                    {...action.props}
                    color={darkMode ? "white" : "black"}
                    sx={{
                      color: "white.1000",
                      fill: "none",
                    }}
                  />
                }
                disabled={action.disabled}
              />

              <Typography
                color={darkMode ? "neutral.1000" : "black.1000"}
                fontSize={{ xs: "10px", md: "14px" }}
                lineHeight={"12px"}
                fontWeight={500}
              >
                {action.value || 0}
              </Typography>
            </Box>
          ))}
        </Stack>

        {/* sharer and donation button */}
        {/* <Stack display={"flex"} flexDirection={"row"} gap={"10px"} mt={"10px"}>
          <Button
            variant="contained"
            sx={{
              background: palette.gradients["main"],
              borderRadius: "10px",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: "700",
              fontFamily: typography.inter,
              cursor:
                daysRemaining < 0 && daysRemaining !== null
                  ? "no-drop"
                  : "pointer",
            }}
            onClick={() => {
              // if (!isLoggedIn) {
              //   return setSnack("login required", "warning");
              // } else {
              if (userId === authorId) {
                setSnack(
                  "Self contribution are not allowed at this time.",
                  "warning"
                );
              } else {
                setShowPaymentDrawer(true, id, authorId);
              }
              // }
            }}
            startIcon={
              daysRemaining !== null && daysRemaining < 0 ? (
                <MonetizationOnOutlinedIconGrey />
              ) : (
                <MonetizationOnOutlinedIcon />
              )
            }
          >
            Donate
          </Button>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            sx={{
              background: palette.gradients["main"],
              borderRadius: "10px",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: "700",
              fontFamily: typography.inter,
            }}
          >
            Share
          </Button>
        </Stack> */}
      </CardActions>
    </Stack>
  );
}
