import { createSvgIcon } from "@mui/material";

export default function NotificationIcon(props) {
  const CreateImageIcon = createSvgIcon(
    <>
      <g id="Iconly/Bold/Notification">
        <g id="Notification">
          <path
            id="Notification_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7071 9.54633C18.7071 10.8023 19.039 11.5425 19.7695 12.3956C20.3231 13.0241 20.5 13.8308 20.5 14.706C20.5 15.5802 20.2128 16.4101 19.6373 17.0839C18.884 17.8917 17.8215 18.4073 16.7372 18.497C15.1659 18.6309 13.5937 18.7437 12.0005 18.7437C10.4063 18.7437 8.83505 18.6763 7.26375 18.497C6.17846 18.4073 5.11602 17.8917 4.36367 17.0839C3.78822 16.4101 3.5 15.5802 3.5 14.706C3.5 13.8308 3.6779 13.0241 4.23049 12.3956C4.98384 11.5425 5.29392 10.8023 5.29392 9.54633V9.1203C5.29392 7.43834 5.71333 6.33852 6.577 5.26186C7.86106 3.6917 9.91935 2.75 11.9558 2.75H12.0452C14.1254 2.75 16.2502 3.73702 17.5125 5.37466C18.3314 6.42916 18.7071 7.48265 18.7071 9.1203V9.54633ZM9.07324 20.8107C9.07324 20.3071 9.53539 20.0765 9.96275 19.9778C10.4627 19.872 13.5088 19.872 14.0088 19.9778C14.4361 20.0765 14.8983 20.3071 14.8983 20.8107C14.8734 21.2901 14.5922 21.7151 14.2036 21.985C13.6997 22.3778 13.1083 22.6266 12.4901 22.7162C12.1482 22.7605 11.8123 22.7615 11.4824 22.7162C10.8632 22.6266 10.2718 22.3778 9.76895 21.984C9.37935 21.7151 9.09809 21.2901 9.07324 20.8107Z"
            fill="url(#paint0_linear_178_605)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_178_605"
          x1="12"
          y1="2.75"
          x2="12"
          y2="22.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
      </defs>
    </>
  );
  return (
    <CreateImageIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
      }}
      {...props}
    />
  );
}
