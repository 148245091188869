import { Box } from "@mui/material";
import Navbar from "./navbar/Navbar";
// import MobileNavigation from "./navbar/MobileNavigation";
// import Searchbar from "./searchbar/Searchbar";
import NotificationLayout from "./notification/NotificationLayout";
// import Sidebar from "./sidebar/Sidebar";
import { CreatePost } from "./createPost/CreatePost";
import SettingDrawer from "./settingDrawer/SettingDrawer";
import ScrollToTop from "../component/ScrollToTop";
import { useNavigate } from "react-router";
import { useAuth } from "../hooks/store/useAuth";
import { useSnack } from "../hooks/store/useSnack";
import { useEffect } from "react";
// import { SignupDrawer } from "./SignupDrawer/SignupDrawer";
import { ReportDrawer } from "./reportDrawer/ReportDrawer";
import { FollowerListDrawer } from "./followerList/FollowerListDrawer";
import { FollowingListDrawer } from "./followingList/FollowingListDrawer";
import { PaymentDrawer } from "./paymentDrawer/PaymentDrawer";
import LoadingBar from "react-top-loading-bar";
import { useTopProgressBar } from "../hooks/store/useTopProgressBar";
import { BlockListDrawer } from "./blockList/BlockListDrawer";
import { useAllModal } from "../hooks/store/useAllModal";
import { ShareDrawer } from "./shareDrawer/ShareDrawer";
import useThemeStore from "../hooks/store/useThemeStore";
import BlurBgCircle from "./blurBox/BlurBgCircle";
import CommonDrawer from "./commonDrawer/CommonDrawer";
import CreateRaiseBtn from "./createRaiseBtn/CreateRaiseBtn";
import IdentityVerificationDialog from "./indetityVerification/IdentityVerificationDialog";
import { useLocation } from "react-router-dom";
import Loader from "./Loader";
import KycDialog from "./Dialogbox/KycDialog";
import DialogBox from "./Dialogbox/DialogBox";
import ROUTE_LIST from "../Routes/list";
export default function Layout({
  children,
  isLoggedInRequired = false,
  hideSidebar = false,
  isLoading = false,
}) {
  const showCreateRaiseButton = [
    ROUTE_LIST.HOME,
    ROUTE_LIST.RAISE,
    ROUTE_LIST.STORY,
  ];
  let { isLoggedIn } = useAuth();
  let { setSnack } = useSnack();
  let { progressBarValue, setProgressBarValue } = useTopProgressBar();
  let {
    showNotificationbar,
    showBlockListDrawer,
    showIdentityDrawer,
    setOpenKycDialog,
    kycDialog,
  } = useAllModal();
  const { darkMode } = useThemeStore();
  const navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname;
  useEffect(() => {
    if (isLoggedInRequired) {
      if (!isLoggedIn) {
        setSnack("You must be logged in", "warning");
        return navigate("/");
      }
    }
  }, [isLoggedInRequired, isLoggedIn, setSnack, navigate]);

  return (
    <Box
      minHeight={"100vh"}
      maxWidth={"100vw"}
      bgcolor={darkMode ? "bgDark.main" : "bgWhite.secondary"}
      sx={{
        position: "relative",
        display: { xs: "flex", sm: "flex", md: "grid" },
        gridTemplateColumns: {
          xs: "1fr",
          sm: "1fr",
          md: "1fr",
        },
      }}
    >
      <LoadingBar
        color={"linear-gradient(139deg, #fb8817, #ff4b01, #c12127, #e02aff)"}
        height={4}
        progress={progressBarValue}
        onLoaderFinished={() => setProgressBarValue(0)}
      />
      <ScrollToTop></ScrollToTop>
      {/* <Searchbar /> */}
      {showNotificationbar && isLoggedIn && (
        <NotificationLayout></NotificationLayout>
      )}
      <CreatePost></CreatePost>
      <SettingDrawer></SettingDrawer>
      {/* <Sig</Box>nupDrawer></SignupDrawer> */}
      <ReportDrawer></ReportDrawer>
      <FollowerListDrawer></FollowerListDrawer>
      <FollowingListDrawer></FollowingListDrawer>
      <PaymentDrawer></PaymentDrawer>
      <ShareDrawer />
      <CommonDrawer />
      <DialogBox
        Content={({ handleClose }) => (
          <KycDialog
            cancelCall={() => {
              setOpenKycDialog();
            }}
            heading="Delete Draft"
            description="Are you sure you want to continue ? You can loose your old draft."
            confirmCall={async () => {}}
          />
        )}
        setOpenDialog={setOpenKycDialog}
        openDialog={kycDialog}
      />
      {showCreateRaiseButton.includes(path) && (
        // || String(path).slice(0, 5) === "/feed")
        <CreateRaiseBtn />
      )}
      {showIdentityDrawer && <IdentityVerificationDialog />}

      {showBlockListDrawer && <BlockListDrawer></BlockListDrawer>}
      {/* {!hideSidebar && <Sidebar />} */}
      <Box
        width={"100%"}
        sx={{
          backgroundColor: darkMode ? "bgDark.secondary" : "bgWhite.secondary",
        }}
      >
        <BlurBgCircle />
        <BlurBgCircle
          style={{
            width: "443px",
            height: "433px",
            top: "370px",
            left: "unset",
            right: "-27px",
            opacity: 0.2,
          }}
        />
        {!hideSidebar && <Navbar />}
        {isLoggedInRequired ? (
          <>
            {isLoggedIn && (
              <Box
                sx={{
                  backgroundColor: darkMode
                    ? "bgDark.secondary"
                    : "bgWhite.secondary",
                  minHeight: "100vh",
                  position: "relative",
                }}
              >
                {isLoading ? <Loader /> : <>{children}</>}
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              backgroundColor: darkMode
                ? "bgDark.secondary"
                : "bgWhite.secondary",
              // minHeight: "100vh",
            }}
          >
            {isLoading ? <Loader /> : <>{children}</>}
          </Box>
        )}
        {/* {!hideSidebar && <MobileNavigation />} */}
      </Box>
    </Box>
  );
}
