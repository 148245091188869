import { createSvgIcon } from "@mui/material";

export default function CommentFlagIcon(props) {
  const CreateCommentFlagIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_90_28902)">
        <path
          d="M9.2616 1.30855C6.93703 -0.0197207 4.49395 -0.143963 2.14453 0.91649V0.184326H1.08984V18.1843H2.14453V11.6195C4.30907 10.4842 6.58216 10.5195 8.7384 11.7515C10.0315 12.4905 11.3612 12.8597 12.6914 12.8597C14.0211 12.8597 15.3513 12.4905 16.6444 11.7515L16.9102 11.5996V0.857428L16.1212 1.30855C13.8779 2.59084 11.5059 2.59084 9.2616 1.30855Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_90_28902">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.184326)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateCommentFlagIcon
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
