import { Box, Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import useThemeStore from "../../hooks/store/useThemeStore";

export const ProfileSkeleton = () => {
  const { darkMode } = useThemeStore();
  const stats = new Array(6);
  stats.fill(1);
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection="column"
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      gap={"5px"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={{ xs: "18px", md: "30px" }}
      >
        {/* Profile Picture */}
        <Skeleton
          variant="rounded"
          sx={{
            borderRadius: { xs: "10px", md: "16px" },
            width: { xs: "70px", ssm: "90px", md: "105px" },
            height: { xs: "70px", ssm: "90px", md: "102px" },
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
          }}
        />
        {/* stats skeleton */}
        <Box
          display={"grid"}
          gridTemplateColumns={"1fr 1fr 1fr"}
          sx={{
            rowGap: "5px",
            columnGap: { xs: "10px", sm: "32px", md: "50px" },
          }}
        >
          {stats.map((value, index) => (
            <Stack direction={"column"} key={index}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: { xs: "12px", md: "16px" },
                  width: { xs: "50px", md: "90px" },
                  backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
                }}
              ></Skeleton>
              <Skeleton
                sx={{
                  fontSize: { xs: "11px", md: "14px" },
                  textAlign: { sm: "left", md: "center" },
                  width: { xs: "50px", md: "90px" },
                  backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
                }}
              ></Skeleton>
            </Stack>
          ))}
        </Box>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={{ xs: "row", xsm: "row" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={{ xs: "18px", md: "30px" }}
      >
        <Box>
          {/* username and first name  Skeleton */}
          <Skeleton
            variant="rectangular"
            sx={{
              mt: 2,
              width: { xs: "70px", ssm: "90px", md: "105px" },
              height: "14px",
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          ></Skeleton>
          <Skeleton
            variant="rectangular"
            sx={{
              mt: 1,
              width: { xs: "70px", ssm: "90px", md: "105px" },
              height: "14px",
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          ></Skeleton>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "grid" },
            gridTemplateColumns: "1fr 1fr ",
            gap: "10px",
            mt: "4px",
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              width: { xs: "100px", md: "150px" },
              height: "40px",
              borderRadius: "10px",
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          ></Skeleton>
          <Skeleton
            variant="rectangular"
            sx={{
              width: { xs: "100px", md: "150px" },
              height: "40px",
              borderRadius: "10px",
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          ></Skeleton>
        </Box>
      </Box>
    </Box>
  );
};
