import { Box, Typography } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

export default function WithdrawalAmount({ amount = 0, text = "" }) {
  const fixAmount = (amount) => amount.toFixed(2);
  const { darkMode } = useThemeStore();
  const { border } = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: darkMode ? "grey.550" : "grey.1100",
        padding: { xs: "15px 0px", sm: "44px 0px" },
        width: "100%",
        borderRadius: border.radius,
        display: "grid",
        placeItems: "center",
      }}
    >
      <Box sx={{ display: "inline-block", textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: { xs: "30px", sm: "34px" },
            fontWeight: 600,
            lineHeight: 1.5,
            color: darkMode ? "white.1000" : "black.1000",
          }}
        >
          ${fixAmount(amount)}
        </Typography>
        <Typography
          sx={{
            color: "primary.disabledLight",
            textTransform: "capitalize",
            fontSize: { xs: "13px", sm: "14px" },
            lineHeight: 1.5,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
