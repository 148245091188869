import React from "react";

import { createSvgIcon } from "@mui/material";
export default function CheckIcon(props) {
  const CreateCheckIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_204_46550)">
        <path
          d="M27.5 0C42.4116 0 54.5 12.0883 54.5 26.9999C54.5 41.9115 42.4116 53.9999 27.5 53.9999C12.5884 53.9999 0.500112 41.9115 0.500112 26.9999C0.457682 12.1306 12.4771 0.0424301 27.3463 0H27.5Z"
          fill={props.color ? "props.color" : "#3BB54A"}
        />
        <path
          d="M42.4577 19.5591L23.2816 38.7351L12.543 28.0733L16.9152 23.7779L23.2816 30.0677L38.0856 15.2637L42.4577 19.5591Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_204_46550">
          <rect
            width="54"
            height="54"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateCheckIcon
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
