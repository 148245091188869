import { Box, Skeleton } from "@mui/material";
import React from "react";
import BankAccountSkeleton from "./BankAccountSkeleton";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function WithdrawalHistorySkeleton() {
  const { darkMode } = useThemeStore();
  return (
    <Box>
      <Skeleton
        width={"80%"}
        height={35}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      ></Skeleton>
      <Skeleton
        width={"100%"}
        height={70}
        sx={{
          borderRadius: "30px 30px 0 0",
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
        }}
      ></Skeleton>
      <BankAccountSkeleton />
      <BankAccountSkeleton />
      <BankAccountSkeleton />
      <Skeleton
        width={"100%"}
        height={70}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      ></Skeleton>
      <Skeleton
        width={"100%"}
        height={30}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      ></Skeleton>
      <Skeleton
        width={"100%"}
        height={70}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      ></Skeleton>
    </Box>
  );
}
