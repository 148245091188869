import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function Loader() {
  return (
    <Box
      sx={{
        position: "absolute",
        inset: 0,
        backgroundColor: "rgba(0,0,0, 0.5)",
        zIndex: 1400,
        display: "grid",
        placeItems: "center",
      }}
    >
      <CircularProgress size={36} sx={{ color: "#ffffff" }}></CircularProgress>
    </Box>
  );
}
