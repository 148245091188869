import React, { useEffect, useState } from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import { Box, Stack, Typography } from "@mui/material";
import useThemeStore from "../hooks/store/useThemeStore";
import UserIcon from "../icons/UserIcon";
import { useTheme } from "@emotion/react";
import DialogBox from "../component/Dialogbox/DialogBox";
import useApi from "../hooks/useApi";
import DeleteDialog from "../component/DeleteAccount/DeleteDialog";
import { useAuth } from "../hooks/store/useAuth";
import { APIS } from "../api/lists";
import { useSnack } from "../hooks/store/useSnack";
import { Link, useNavigate } from "react-router-dom";
import EyeIcon from "../icons/EyeIcon";
export default function Settings() {
  const { darkMode } = useThemeStore();
  const [openDialog, setOpenDialog] = useState(false);
  const { palette } = useTheme();
  const { apiCall, isLoading } = useApi(true);
  const { userId, logout, isLoggedIn } = useAuth();
  const { setSnack } = useSnack();
  const navigate = useNavigate();

  async function deleteAccount() {
    try {
      let res = await apiCall({
        url: APIS.USER.DELETE(userId),
        method: "delete",
      });
      setSnack(res.data.message);
      logout();
      return navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <BackButtonLayout pageTitle={"Settings"} isTitleBold>
        <Stack sx={{ gap: { xs: "20px", md: "30px" } }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              width: "100%",
              textDecoration: "none",
              cursor: "pointer",
            }}
            component={Link}
            to={"/change-password"}
          >
            <Box
              sx={{
                borderRadius: "10px",
                border: "1px solid",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px",
                borderColor: darkMode ? "transparent" : "white.555",
                color: darkMode ? "white.1000" : "black.1000",
                backgroundColor: darkMode ? "grey.550" : "white.1000",
                flexShrink: "0",
              }}
            >
              <EyeIcon
                color={darkMode ? palette.white["1000"] : palette.grey["1000"]}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.408px",
                textTransform: "capitalize",
                color: darkMode ? "white.1000" : "black.1000",
              }}
            >
              Change Password
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              width: "100%",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <Box
              sx={{
                borderRadius: "10px",
                border: "1px solid",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px",
                borderColor: darkMode ? "transparent" : "white.555",
                color: darkMode ? "white.1000" : "black.1000",
                backgroundColor: darkMode ? "grey.550" : "white.1000",
                flexShrink: "0",
              }}
            >
              <UserIcon
                color={darkMode ? palette.white["1000"] : palette.grey["1000"]}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.408px",
                textTransform: "capitalize",
                color: darkMode ? "white.1000" : "black.1000",
              }}
            >
              Delete Account
            </Typography>
          </Box>
        </Stack>
      </BackButtonLayout>

      <DialogBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        Content={({ handleClose }) => (
          <DeleteDialog
            isLoading={isLoading}
            handleClose={handleClose}
            deleteAccount={async () => {
              await deleteAccount();
              setOpenDialog(false);
            }}
          />
        )}
      ></DialogBox>
    </>
  );
}
