import React from "react";
import { memo } from "react";
import { Helmet } from "react-helmet-async";

function DynamicMeta({
  title = "",
  description = "",
  name = "",
  type = "",
  image = "/logo.svg",
  url = "",
}) {
  const domain = process.env.REACT_APP_DOMAIN;
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}

      {/* og tags */}
      {image && <meta name="image" property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* end og tags */}
      {/* Facebook tags */}
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:card" content="card" />
      <meta property="twitter:domain" content={domain} />
      {url && <meta property="twitter:url" content={url} />}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image}></meta>}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
}

export default memo(DynamicMeta);
