import React, { useState } from "react";
import DialogBackground from "./DialogBackground";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  alpha,
} from "@mui/material";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { useSnack } from "../../../hooks/store/useSnack";

export default function DeletePrimary({
  isLoading = false,
  confirmCall,
  cancelCall,
  bankAccounts = [],
}) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  let oldPrimaryId = bankAccounts.find((account) => account.default)?._id;
  const [primaryId, setPrimaryId] = useState(oldPrimaryId);
  const paragraphStyle = {
    fontSize: { xs: "12px", sm: "14px" },
    lineHeight: 1.5,
    // color: darkMode ? "white.1000" : "black.1000",
  };

  const { setSnack } = useSnack();
  return (
    <DialogBackground
      heading={"Delete Account"}
      description="First set another account as a primary"
      cancelCall={cancelCall}
      confirmCall={() => {
        if (primaryId === oldPrimaryId) {
          return setSnack("Select different primary account");
        }
        confirmCall(primaryId);
      }}
      isLoading={isLoading}
      confirmLabel="Confirm"
    >
      <Divider
        sx={{
          mb: { xs: "15px", sm: "20px" },
          borderColor: darkMode ? "black.250" : alpha(palette.grey[100], 0.4),
        }}
      />
      <FormControl
        sx={{
          width: "100%",
          "&  div > label:nth-of-type(odd):not(:last-child)": {
            borderRight: {
              sm:
                "1px solid " +
                (darkMode ? palette.black[250] : alpha(palette.grey[100], 0.4)),
            },
          },
          "&  div > label:not(:last-child)": {
            paddingBottom: { xs: "5px", sm: 0 },
            borderBottom: {
              xs:
                "1px solid " +
                (darkMode ? palette.black[250] : alpha(palette.grey[100], 0.4)),
              sm: 0,
            },
          },
        }}
      >
        <RadioGroup
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "auto", sm: "auto auto" },
            gap: "10px",
            rowGap: "20px",
          }}
          aria-labelledby="accoutnType"
          name="accountType"
          onChange={(e) => {
            setPrimaryId(e.target.value);
          }}
          value={primaryId}
          defaultChecked={bankAccounts.find((account) => account.default)?._id}
          defaultValue={bankAccounts.find((account) => account.default)?._id}
        >
          {bankAccounts.map((account) => (
            <FormControlLabel
              key={account._id}
              sx={{
                mr: 0,
                ml: 0,
                alignItems: "flex-start",
                ".MuiRadio-root": {
                  color: darkMode ? "neutral.650" : "primary.disabledLight",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                },
                ".MuiFormControlLabel-label": {
                  color: darkMode ? "grey.900" : "primary.disabledLight",
                },
                ".MuiRadio-root.Mui-checked": {
                  color: darkMode ? "white.1000" : "primary.main",
                },
                ".Mui-checked+.MuiFormControlLabel-label": {
                  color: darkMode ? "white.1000" : "black.1000",
                },
                ".MuiRadio-root+.MuiFormControlLabel-label": {
                  flexGrow: 1,
                },
                "& .light-number": {
                  color: "primary.disabledLight",
                },
              }}
              value={account._id}
              control={<Radio />}
              label={
                <Box>
                  <Box mb={"20px"}>
                    <Typography sx={paragraphStyle}>Account Number</Typography>
                    <Typography sx={paragraphStyle} className="light-number">
                      {account.label}
                    </Typography>
                  </Box>
                  <Typography sx={paragraphStyle}>Routing Number</Typography>
                  <Typography sx={paragraphStyle} className="light-number">
                    {account.routeNumber}
                  </Typography>
                </Box>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </DialogBackground>
  );
}
