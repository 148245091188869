import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TabPanel } from "@mui/lab";
import UserProfileItem from "./UserProfileItem";
import { Box, Typography } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import useInfinite from "../../hooks/useInfinite";
import ListItemSkeleton from "../skeleton/ListItemSkeleton";

const UserTab = ({ searchInput }) => {
  const { darkMode } = useThemeStore();

  const {
    results: feeds,
    page,
    hasMore,
    setHasMore,
    setPage,
    totalPages,
    isLoading,
  } = useInfinite({
    url: "v3/users/search",
    method: "post",
    addUserId: false,
    limit: 12,
    data: { terms: searchInput },
  });

  useEffect(() => {
    setPage(1);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [setPage, searchInput]);
  return (
    <>
      <TabPanel value="2" color="primary.main" sx={{ padding: 0 }}>
        <InfiniteScroll
          dataLength={feeds.length}
          next={() => {
            if (page < totalPages) {
              setPage((prev) => prev + 1);
            } else {
              setHasMore(false);
            }
          }}
          style={{ overflow: "visible" }}
          hasMore={hasMore}
        >
          <Box
            sx={{
              display: "grid",
              gap: { xs: "10px", md: "15px" },
              marginTop: "12px",
              padding: "0px",
            }}
          >
            {feeds.map((item) => (
              <UserProfileItem item={item} key={item._id}></UserProfileItem>
            ))}
            {isLoading && (
              <>
                <ListItemSkeleton></ListItemSkeleton>
                <ListItemSkeleton></ListItemSkeleton>
                <ListItemSkeleton></ListItemSkeleton>
              </>
            )}
          </Box>
          {feeds.length === 0 && !isLoading && (
            <Typography
              textAlign={"center"}
              color={darkMode ? "white.1000" : "primary.main"}
              fontSize={{
                xs: "14px",
                md: "18px",
              }}
              mt={2}
            >
              User not found
            </Typography>
          )}
        </InfiniteScroll>
      </TabPanel>
    </>
  );
};

export default UserTab;
