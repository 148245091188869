import { LoadingButton } from "@mui/lab";
import React from "react";
import AddIcon from "../../icons/AddIcon";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Link, useLocation } from "react-router-dom";
// import useApi from "../../hooks/useApi";
// import { useSnack } from "../../hooks/store/useSnack";
// import { useUserVerification } from "../../hooks/store/useUserVerification";
// import { useAuth } from "../../hooks/store/useAuth";
// import { useAllModal } from "../../hooks/store/useAllModal";
// import { useNavigate } from "react-router-dom";
// import { useRedirect } from "../../hooks/useRedirect";

const CreateRaiseBtn = () => {
  const { palette } = useTheme();
  const location = useLocation();
  let path = location.pathname;
  // const { apiCall } = useApi(true);
  // const { setSnack } = useSnack();
  // const { logout, isLoggedIn } = useAuth();
  // const { setUserVerification } = useUserVerification();
  // const { setShowCommonDrawer } = useAllModal();
  // const [loading, setLoading] = useState(false);
  // const { userId } = useAuth();
  // const navigate = useNavigate();
  // const { setRedirectUrl } = useRedirect();

  //   check user verification status on button click
  // async function verifyUserIdentity() {
  //   try {
  //     if (!isLoggedIn) {
  //       setSnack("Please login to Create raise");
  //       return navigate("/signin");
  //     }

  //     setLoading(true);
  //     let response = await apiCall({
  //       url: "v2/accounts/check-verified-2",
  //       method: "post",
  //       data: { userId },
  //     });
  //     let mobileVerification = response.data.data.mobile_verified.mobile;
  //     let emailVerification = response.data.data.email_verified.email;
  //     let kycVerification = response.data.data.isKyc.isKyc;
  //     let requiredReLogin =
  //       response.data.data.required_relogin.required_relogin;

  //     setUserVerification(
  //       emailVerification,
  //       mobileVerification,
  //       kycVerification
  //     );

  //     // no activity detected for last 15 day
  //     if (requiredReLogin) {
  //       setLoading(false);
  //       logout();
  //       return setSnack(
  //         "You need to log in again because there has been no activity for the past 15 days."
  //       );
  //     }

  //     // if anything is pending then open drawer
  //     if (
  //       mobileVerification === false ||
  //       emailVerification === false ||
  //       kycVerification === 0
  //     ) {
  //       setLoading(false);
  //       if (emailVerification === true) setRedirectUrl("/create");
  //       return setShowCommonDrawer(true);
  //     }

  //     // if everything is done
  //     if (mobileVerification && emailVerification && kycVerification) {
  //       setLoading(false);
  //       navigate("/create");
  //     }
  //   } catch (error) {
  //     navigate("/404");
  //     setSnack("something gone wrong", "error");
  //     setLoading(false);
  //   }
  // }
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: {
          xs: String(path).slice(0, 5) === "/feed" ? "120px" : "15px",
          md: String(path).slice(0, 5) === "/feed" ? "120px" : "25px",
        },
        right: { xs: "15px", md: "25px" },
        zIndex: 100,
      }}
    >
      <LoadingButton
        component={Link}
        to="/create/raise"
        color="secondary"
        sx={{
          minWidth: "unset",
          p: 0,
          width: { xs: "42px", md: "61px" },
          height: { xs: "42px", md: "61px" },
          background: palette.gradients["main"],
          "&": {
            borderRadius: "50%",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiButton-startIcon": { mr: 0, ml: 0 },
          "& .MuiLoadingButton-loadingIndicator": {
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-45%)",
            color: "white.1000",
          },
        }}
        // onClick={verifyUserIdentity}
        // loading={loading}
        loadingPosition="start"
        startIcon={<AddIcon />}
        variant="contained"
      ></LoadingButton>
    </Box>
  );
};

export default CreateRaiseBtn;
