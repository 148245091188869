import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import Card from "../component/feed/Card";
import Skeleton from "../component/feed/SkeletonCard";
import Layout from "../component/Layout";
// import { useFeed } from "../hooks/store/useFeed";
import { useLocation, useSearchParams } from "react-router-dom";
// import { useAuth } from "../hooks/store/useAuth";
// import PullToRefresh from "react-pull-to-refresh";
import useInfiniteFeeds from "../hooks/useInfiniteFeeds";
import { useAllModal } from "../hooks/store/useAllModal";
// import DynamicMeta from "../utils/DynamicMeta";
import StoryFilter from "../component/storyFilter/StoryFilter";
import useThemeStore from "../hooks/store/useThemeStore";
import { socket } from "../socket";
import { useSnack } from "../hooks/store/useSnack";
import { useExistDraft } from "../hooks/store/useExistDraft";

export default function Home() {
  const {
    allFeeds: feeds,
    fetchFeeds,
    hasMore,
    setCurrentPage,
    setType,
    isLoading,
    setFeedOnTop,
  } = useInfiniteFeeds("v3/begs", 25);
  const { setSnack } = useSnack();

  const location = useLocation();
  const [query] = useSearchParams();
  const { setShowSearchbar } = useAllModal();
  const { darkMode } = useThemeStore();
  const [videoUrl, setVideoUrl] = useState("");
  const [isMute, setIsMute] = useState(true);
  const { draftExist, resetExistDraftStore } = useExistDraft();
  useEffect(() => {
    if (query.get("search")) {
      // console.log(query.get("search"));
      setShowSearchbar(true);
    }
  }, [query, setShowSearchbar]);

  useEffect(() => {
    if (draftExist) {
      resetExistDraftStore();
    }
  }, [draftExist, resetExistDraftStore]);
  useEffect(() => {
    let type = location.pathname;
    if (type === "/") {
      type = "all";
    } else {
      type = location.pathname.split("/")[1];
    }
    setType(type);
    setCurrentPage(1);
  }, [location.pathname, setCurrentPage, setType]);
  // const feeds = allFeeds[type] || [];
  useEffect(() => {
    fetchFeeds();
  }, [fetchFeeds]);
  useEffect(() => {
    // setType(type);
    setCurrentPage(1);
  }, [setCurrentPage]);

  useEffect(() => {
    let getNewfeeds = (feedData) => {
      setSnack("New feeds uploaded scroll top to view");
      setFeedOnTop(feedData);
    };

    socket.on("evntCreateRaise", getNewfeeds);

    return () => {
      socket.off("evntCreateRaise", getNewfeeds);
    };
  }, [setFeedOnTop, setSnack]);

  return (
    <Layout>
      <Box
        pt={{ xs: "1px", sm: "25px" }}
        sx={{
          maxWidth: { sm: "465px" },
          margin: "0 auto",
          position: "relative",
          zIndex: "2",
        }}
      >
        <StoryFilter />
        {isLoading && (
          <Box
            textAlign={"center"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              zIndex: 200,
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              left: "50%",
              transform: "translateX(-50%)",
              mt: "60px",
              backgroundColor: darkMode ? "grey.550" : "white.1000",
            }}
          >
            <CircularProgress
              size={25}
              sx={{
                color: darkMode ? "white.1000" : "primary.main",
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            paddingInline: { xs: "15px" },
            backgroundColor: darkMode ? "bgDark.main" : "bgWhite.main",
          }}
        >
          {/* <PullToRefresh onRefresh={refreshFunction}> */}

          <InfiniteScroll
            dataLength={feeds?.length || 0} //This is important field to render the next data
            next={async () => {
              if (hasMore) {
                setCurrentPage();
              }
              return;
            }}
            hasMore={hasMore}
            loader={
              <Stack sx={{ mt: "15px" }}>
                <Skeleton />
                <Skeleton />
              </Stack>
            }
            endMessage={
              <Typography sx={{ textAlign: "center", color: "white.1000" }}>
                Yay! You have seen it all.
              </Typography>
            }
          >
            {feeds?.map((feed, index) => (
              <Card
                key={feed._id}
                id={feed._id}
                last={index === feeds.length - 1}
                author={feed.author}
                title={feed.title}
                videos={feed.videos}
                likeCount={feed.likeNo}
                commentCount={feed.comments}
                donorCount={feed.donors}
                htmlDescription={
                  feed.htmlDescription
                    ? feed.htmlDescription
                    : feed?.textDescription
                }
                daysRemaining={feed.daysRemaining}
                goalAmount={feed.goalAmount}
                amountRaised={feed.amountRaised}
                isLiked={feed.currentUserLike}
                begType={feed.begType}
                status={feed.status}
                endedDate={feed.endedDate}
                saved={feed.saved}
                setVideoUrl={setVideoUrl}
                videoUrl={videoUrl}
                isMute={isMute}
                setIsMute={setIsMute}
                shareLink={feed.shareLink}
              />
            ))}
          </InfiniteScroll>
          {isLoading && (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}
          {/* </PullToRefresh> */}
        </Box>
      </Box>
    </Layout>
  );
}
