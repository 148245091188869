import { useTheme, alpha } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import VideoIcon from "../../../icons/VideoIcon";
import ImageIcon from "../../../icons/ImageIcon";
import FeedMoreMenu from "../FeedMoreMenu";

export default function FeedMediaType({ videoType, video, data }) {
  const { palette } = useTheme();

  return (
    <Box
      position={"absolute"}
      display={"flex"}
      top={"15px"}
      left={{ xs: "unset", sm: "unset", md: "15px" }}
      right={{ xs: "15px", sm: "15px", md: "unset" }}
      zIndex={2}
      sx={{
        placeItems: "center",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        color={"neutral.1000"}
        border="1px solid"
        borderRadius={"54px"}
        sx={{
          padding: "5px",
          backgroundColor: alpha(palette.white["550"], 0.2),
          backdropFilter: "blur(20px)",
        }}
      >
        <Box
          sx={{
            padding: { xs: "0px 8px 0px 8px", sm: "0px 20px 0px 15px" },
            display: "grid",
            placeItems: "center",
          }}
        >
          {videoType === 1 ? (
            <ImageIcon
              sx={{
                width: {
                  xs: "16px",
                  sm: "20px",
                },
                height: { xs: "16px", sm: "20px" },
              }}
            />
          ) : (
            <VideoIcon
              sx={{
                width: {
                  xs: "16px",
                  sm: "20px",
                },
                height: { xs: "16px", sm: "20px" },
              }}
            />
          )}
        </Box>
        <FeedMoreMenu activeVideo={video} data={data} />
      </Box>
    </Box>
  );
}
