import { Box, Stack, Typography, alpha } from "@mui/material";
import React from "react";
import CommentForm from "./CommentForm";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
const CommentContainer = () => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        paddingInline: {
          xs: "20px",
          sm: "30px",
        },
      }}
    >
      <Stack
        sx={{
          paddingBlock: "25px",
          borderBottom: "1px solid",
          borderColor: darkMode ? "black.250" : "white.700",
        }}
      >
        <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "27px",
            letterSpacing: "-0.40799999237060547px",
            textAlign: "left",
          }}
        >
          Comment
        </Typography>
        <Typography
          sx={{
            mt: "5px",
            color: darkMode ? alpha(palette.white[1000], 0.6) : "black.1000",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "21px",
            letterSpacing: "-0.40799999237060547px",
            textAlign: "left",
          }}
        >
          Your thoughts matter! Share your valuable comment to help donors to
          get more donations.
        </Typography>
        <CommentForm />
      </Stack>
    </Box>
  );
};

export default CommentContainer;
