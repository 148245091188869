import { Box, IconButton, alpha } from "@mui/material";
import React, { useState } from "react";
import useApi from "../../../hooks/useApi";
import { useSnack } from "../../../hooks/store/useSnack";
import { useAuth } from "../../../hooks/store/useAuth";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ChatIcon from "../../../icons/ChatIcon";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { LoadingButton } from "@mui/lab";
import { useImmersivePage } from "../../../hooks/store/useImmersivePage";
import { useSettings } from "../../../hooks/store/useSettings";

export default function Form({ feedId, fetchComments }) {
  const { setSnack } = useSnack();
  const { userId } = useAuth();
  const { darkMode } = useThemeStore();
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(false);
  const { incrementCommentCount } = useImmersivePage();

  const { apiCall } = useApi();
  const { palette } = useTheme();
  const { settings } = useSettings();

  const postComment = async (e) => {
    try {
      e.preventDefault();
      if (loading) {
        return;
      }
      setLoading(true);
      const res = await apiCall({
        url: "v3/comments",
        method: "post",
        data: {
          userId,
          textDescription: commentText,
          begId: feedId,
          htmlDescription: commentText,
        },
      });
      if (res.status === 200) {
        const comments = await fetchComments();
        incrementCommentCount(comments);
        setCommentText("");
        setLoading(false);
      } else throw Error("fail to post comment");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSnack("something gone wrong!", "error");
      //   console.log(error);
    }
  };
  return (
    <Box
      component={"form"}
      onSubmit={postComment}
      sx={{
        mt: "18px",
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        placeItems: "center",
        left: "0px",
        right: "0px",
        padding: "10px",
        backgroundColor: darkMode ? "grey.550" : "white.1000",
        borderRadius: "10px",
        boxShadow: `0px 0px 10px 0px ${alpha(palette.black[1000], 0.15)}`,
      }}
    >
      <Box
        position={"relative"}
        display={"grid"}
        paddingRight={"15px"}
        sx={{
          placeItems: "center",
          "&::after": {
            content: "''",
            position: "absolute",
            top: "50%",
            right: "0%",
            transform: "translateY(-50%)",
            width: "1px",
            height: "27px",
            backgroundColor: darkMode ? "white.1000" : "neutral.250",
            opacity: darkMode ? 0.1 : 1,
          },
        }}
      >
        <ChatIcon
          color="#C7C7C7"
          width={{ xs: "28px", md: "32px" }}
          height={{ xs: "28px", md: "32px" }}
          viewBox={{ xs: "0 0 28 28", md: "0 0 32 32" }}
        />
      </Box>
      <Box
        width={"100%"}
        flexGrow={1}
        paddingLeft={"15px"}
        mr={1}
        sx={{
          "& ::placeholder": {
            fontSize: { xs: "12px", md: "14px" },
            color: darkMode
              ? alpha(palette.white["800"], 0.5)
              : alpha(palette.grey["550"], 0.5),
          },
        }}
      >
        <input
          type="text"
          name="comment"
          required
          minLength={settings?.mainCommentMinLength}
          maxLength={settings?.mainCommentMaxLength}
          placeholder="Leave your comments here....."
          value={commentText}
          onChange={(e) => {
            setCommentText(e.target.value);
          }}
          style={{
            width: "100%",
            height: "100%",
            fontSize: { xs: "12px", md: "14px" },
            backgroundColor: darkMode
              ? palette.grey["550"]
              : palette.white["1000"],
            fontFamily: "poppins",
            fontWeight: 500,
            lineHeight: "16px",
            border: "none",
            outline: "none",
            color: darkMode ? palette.white["1000"] : palette.black["1000"],
            padding: "10px 0px",
            "& input:WebkitAutofill,& input:WebkitAutofill:hover, & input:WebkitAutofill:focus, & input:WebkitAutofill:active":
              {
                WebkitBoxShadow: `0 0 0 30px ${
                  darkMode ? "#3d3d3d" : "#e5e5e5"
                } inset`,
                WebkitTextFillColor: darkMode
                  ? palette.white[1000]
                  : palette.black[1000],
                caretColor: darkMode
                  ? palette.white[1000]
                  : palette.black[1000],
              },
          }}
        />
        {/* <Input
          variant="standard"
          first
          name="comment"
          register={register}
          errors={formState.errors}
          multiline
          sx={{ mt: "0" }}
        /> */}
      </Box>

      {/* comment submit button */}
      {!loading ? (
        <IconButton
          disableRipple={true}
          aria-label="fingerprint"
          type="submit"
          sx={{
            backgroundColor: darkMode ? "white.1000" : "black.1000",
            width: { xs: "38px", md: "42px" },
            height: { xs: "38px", md: "42px" },
            padding: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            "& :hover": {
              backgroundColor: "inherit",
            },
          }}
        >
          <ArrowForwardRoundedIcon
            sx={{ color: darkMode ? "black.1000" : "white.1000" }}
          />
        </IconButton>
      ) : (
        <LoadingButton
          loading
          variant="contained"
          sx={{
            minWidth: "unset",
            width: { xs: "38px", md: "42px" },
            height: { xs: "38px", md: "42px" },
            padding: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            "&.Mui-disabled": {
              backgroundColor: darkMode ? "white.1000" : "dark.1000",
            },
            "& :hover": {
              backgroundColor: "inherit",
            },
          }}
        ></LoadingButton>
      )}
    </Box>
  );
}
