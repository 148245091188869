import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Checkbox,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import Input from "../ui/form/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useThemeStore from "../../hooks/store/useThemeStore";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useSearchParams } from "react-router-dom";
import ROUTE_LIST from "../../Routes/list";
import CloseIcon from "../../icons/CloseIcon";

export default function GuestUserFormDialog({
  open = false,
  setOpen,
  setGuestEmail,
  setGuestFirstName,
  setGuestLastName,
  allowMarketing,
  setAllowMarketing,
  setDonorDetails,
}) {
  const { darkMode } = useThemeStore();
  const { palette, typography } = useTheme();
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const redirectPath =
    query.get("redirect") === "home"
      ? ROUTE_LIST.HOME
      : ROUTE_LIST.FEED_DETAIL + query.get("feed");

  const schema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email address"),
    firstName: Yup.string()
      .required("First name is required")
      .min(3, "First name must contain at least 3 characters")
      .max(24, "First name must be at most 24 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .min(3, "Last name must contain at least 3 characters")
      .max(24, "Last name must be at most 24 characters"),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  // submit guest user form
  async function submitGuestUserDetails(values) {
    setGuestEmail(values.email);
    setGuestFirstName(values.firstName);
    setGuestLastName(values.lastName);
    setDonorDetails({
      guestFirstName: values.firstName,
      guestLastName: values.lastName,
      guestEmail: values.email,
    });
    return setOpen(false);
  }

  function closeModal() {
    setOpen(false);
    navigate(redirectPath);
  }

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "574px",
          width: "100%",
          mx: "15px",
          borderRadius: "15px!important",
          boxShadow: "none",
          border: "none",
          backgroundColor: "unset",
        },
        "& .MuiDialog-container": {
          background: alpha(palette.black[1000], 0.6),
          backdropFilter: "blur(2px)",
        },
      }}
      onClose={closeModal}
    >
      <DialogContent
        sx={{
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
        }}
      >
        <Box
          component={"form"}
          onSubmit={handleSubmit(submitGuestUserDetails)}
          sx={{
            padding: { xs: "16px", sm: "30px", md: "40px" },
            minWidth: { sm: "450px", md: "491px", lg: "551px" },
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            borderRadius: { xs: "10px", lg: "18px" },
            boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography
              flexGrow={1}
              sx={{
                mt: "28px",
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: { xs: "16px", sm: "18px", md: "22px" },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
            >
              Please, provide your details
            </Typography>
            <IconButton
              aria-label="delete"
              size="small"
              sx={{
                position: "absolute",
                top: { xs: "10px", sm: "15px" },
                right: { xs: "10px", sm: "15px" },
              }}
              onClick={closeModal}
            >
              <CloseIcon
                color={
                  darkMode
                    ? alpha(palette.white["1000"], 0.7)
                    : palette.black["1000"]
                }
              />
            </IconButton>
          </Box>
          <Divider
            sx={{
              mt: "20px",
              "&.MuiDivider-root": {
                borderColor: darkMode ? "neutral.650" : "neutral.150",
              },
            }}
          />
          <Box
            sx={{
              height: { xs: "100%", md: "unset" },
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                marginTop: { xs: "20px", md: "15px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Stack
                sx={{
                  display: { xs: "flex", sm: "grid" },
                  gridTemplateColumns: "1fr 1fr",
                  flexDirection: { xs: "column" },
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <Input
                  fullWidth
                  name={"firstName"}
                  inputLabel={"First Name"}
                  type="text"
                  register={register}
                  errors={formState.errors}
                  placeholder="Enter Name"
                  boxSX={{ width: "100%", marginTop: "12px" }}
                />
                <Input
                  fullWidth
                  name={"lastName"}
                  inputLabel={"Last Name"}
                  type="text"
                  register={register}
                  errors={formState.errors}
                  placeholder="Enter Name"
                  boxSX={{ width: "100%", marginTop: "12px" }}
                />
              </Stack>

              <Input
                fullWidth
                name={"email"}
                inputLabel={"Email"}
                type="text"
                register={register}
                errors={formState.errors}
                placeholder="Enter Email"
                boxSX={{ width: "100%", marginTop: "32px" }}
              />
              <Box sx={{ width: "100%", mt: "10px" }}>
                <FormControlLabel
                  sx={{
                    m: 0,
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: "primary.disabledLight",
                    },
                  }}
                  label="Video update showcasing the progress of this fundraising/story."
                  control={
                    <Checkbox
                      checked={allowMarketing}
                      sx={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "4px",
                        mr: "10px",
                        "&.Mui-checked": {
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          background: `linear-gradient(180deg, #5235E1 0%, #B02D96 52.6%, #EF6231 100%)`,
                          color: "white.1000",
                          borderRadius: "4px",
                        },
                      }}
                      disableRipple
                      checkedIcon={
                        <CheckIcon
                          sx={{ width: "14px", height: "14px" }}
                          color="white"
                        />
                      }
                      onChange={() => {
                        setAllowMarketing((prevState) => !prevState);
                      }}
                    />
                  }
                />
              </Box>

              <ButtonWithLoading
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!formState.isValid || formState.isSubmitting}
                sx={{
                  fontFamily: typography.manrope,
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "152%",
                  padding: "12px 20px",
                  marginTop: { xs: "25px", lg: "30px" },
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  background: palette.gradients.main,
                  "&.Mui-disabled": {
                    color: darkMode
                      ? alpha(palette.white["1000"], 0.7)
                      : "white.1000",
                    opacity: 0.5,
                  },
                }}
              >
                Next
              </ButtonWithLoading>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
