import React, { useEffect } from "react";
import SendIcon from "../../../icons/SendIcon";
import { Button, Stack } from "@mui/material";
import MonetizationOnOutlinedIcon from "../../../icons/MonetizationOnOutlinedIcon";
import MonetizationOnOutlinedIconGrey from "../../../icons/MonetizationOnOutlinedIconGrey";
import { useTheme } from "@emotion/react";
import { useAuth } from "../../../hooks/store/useAuth";
import { useAllModal } from "../../../hooks/store/useAllModal";
import { useSnack } from "../../../hooks/store/useSnack";
import { useNavigate } from "react-router-dom";

const DonationAndShareBtns = ({
  daysRemaining,
  author,
  id,
  status,
  begType,
  title,
  imageUrl,
  endedDate,
  description,
  goalAmount,
  amountRaised,
  shareLink,
}) => {
  const { palette, typography } = useTheme();
  const { userId } = useAuth();
  const { setShowPaymentDrawer, setShowShareDrawer } = useAllModal();
  const { setSnack } = useSnack();
  const navigate = useNavigate();

  function getShareLink() {
    setShowShareDrawer(
      true,
      id,
      title,
      imageUrl,
      false,
      description,
      author,
      {
        goalAmount,
        amountRaised,
      },
      shareLink
    );
  }

  //   code for hide and show sticky share and donation button on this componet got out of screen
  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      // Your code to run when scrolling occurs
      let position = document.getElementById("btnContainer");
      position = position.getBoundingClientRect();
      sessionStorage.setItem("btnContainerPosition", position.y);
    };

    // Add the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Stack
        display={{ xs: "none", md: "grid" }}
        gridTemplateColumns={"1fr 1fr"}
        gap={"20px"}
        id="btnContainer"
        marginTop={{ xs: "15px", md: "30px" }}
      >
        {daysRemaining >= 0 && status !== "ended" && endedDate === undefined ? (
          <Button
            variant="contained"
            sx={{
              display: "flex",
              flexDirection: "column",
              background: palette.gradients["main"],
              borderRadius: "10px",
              textTransform: "capitalize",
              fontSize: { xs: "12px", md: "14px" },
              padding: { xs: "12px", md: "16px" },
              lineHeight: "1.5",
              fontWeight: "700",
              fontFamily: typography.inter,
              cursor:
                begType === 2
                  ? "pointer"
                  : daysRemaining < 0 || status === "ended"
                  ? "no-drop"
                  : "pointer",
              "& *": {
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            onClick={() => {
              if (begType !== 2) {
                if (daysRemaining >= 0 && status !== "ended") {
                  if (userId === author._id) {
                    setSnack(
                      "Self contribution are not allowed at this time.",
                      "warning"
                    );
                  } else {
                    navigate(`/donate?feed=${id}`);
                    // setShowPaymentDrawer(true, id, author._id);
                  }
                } else {
                  setSnack("Contribution Period ended", "warning");
                }
              } else {
                if (status !== "ended") {
                  navigate(`/donate?feed=${id}`);
                  // setShowPaymentDrawer(true, id, author._id);
                } else {
                  setSnack("Contribution Period ended", "warning");
                }
              }
              // }
            }}
            startIcon={<MonetizationOnOutlinedIcon />}
          >
            Donate
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "10px",
              textTransform: "capitalize",
              fontSize: { xs: "12px", md: "14px" },
              padding: { xs: "12px", md: "16px" },
              lineHeight: "1.5",
              fontWeight: "700",
              fontFamily: typography.inter,
              "& *": {
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            startIcon={<MonetizationOnOutlinedIconGrey />}
            disabled
            style={{
              color: "#ffffff",
              backgroundColor: "#a1a1a1",
              pointerEvents: "all",
              cursor: "no-drop",
            }}
          >
            Donate
          </Button>
        )}

        <Button
          variant="contained"
          startIcon={<SendIcon />}
          sx={{
            display: "flex",
            flexDirection: "column",
            background: palette.gradients["mainInvert"],
            borderRadius: "10px",
            textTransform: "capitalize",
            fontSize: { xs: "12px", md: "14px" },
            fontWeight: "700",
            fontFamily: typography.inter,
            "& *": {
              marginRight: 0,
              marginLeft: 0,
            },
            // cursor: "no-drop",
          }}
          onClick={() => getShareLink()}
        >
          Share
        </Button>
      </Stack>
    </>
  );
};

export default DonationAndShareBtns;
