import { create } from "zustand";

export const useSignup = create((set) => ({
  accountType: undefined,
  firstName: undefined,
  lastName: undefined,
  username: undefined,
  orgName: undefined,
  EIN: undefined,
  email: undefined,
  mobileNumber: undefined,
  code: 1,
  password: undefined,
  profilePicture: undefined,
  userId: "",

  setAccountType({ accountType }) {
    set(() => ({ accountType }));
  },

  setStep1({
    username,
    mobileNumber,
    email,
    password,
    userId = "",
    orgName = undefined,
    EIN = undefined,
  }) {
    set(() => ({
      username,
      mobileNumber,
      email,
      password,
      userId,
      orgName,
      EIN,
    }));
  },

  setStep2(firstName, lastName, profilePicture = undefined) {
    set(() => ({
      firstName,
      lastName,
      profilePicture,
    }));
  },

  setUserId(userId) {
    set(() => ({
      userId,
    }));
  },

  partialReset() {
    set(() => ({
      username: undefined,
      mobileNumber: undefined,
      code: 1,
      password: undefined,
    }));
  },

  reset() {
    set(() => ({
      firstName: undefined,
      lastName: undefined,
      username: undefined,
      email: undefined,
      mobileNumber: undefined,
      code: 1,
      password: undefined,
      profilePicture: undefined,
      userId: "",
    }));
  },
}));
