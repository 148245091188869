import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAllModal } from "../../hooks/store/useAllModal";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import generateDynamicLink from "../../utils/DynamicLink";
import { useSnack } from "../../hooks/store/useSnack";
import LoadingCircle from "../../icons/LoagingCircle";
import copy from "copy-to-clipboard";
import NextdoorIcon from "../../icons/share/NextdoorIcon";
import TwitterXIcon from "../../icons/share/TwitterXIcon";
import useThemeStore from "../../hooks/store/useThemeStore";
import CloseIcon from "../../icons/CloseIcon";
import InstaShareDialog from "./InstaShareDialog";
import InstaIcon from "../../icons/share/InstaIcon";
import TikTokIcon from "../../icons/share/TikTokIcon";
import TiktokShareDialog from "./TiktokShareDialog";
import { useSettings } from "../../hooks/store/useSettings";
import FacebookIcon from "../../icons/share/FacebookIcon";
import EmailIcon from "../../icons/share/EmailIcon";
import MoreIcon from "../../icons/share/MoreIcon";
import OtherPlatformShare from "./OtherPlatformShare";
import ChipinSuccessMsg from "./ChipinSuccessMsg";
import CommentContainer from "./CommentContainer";
import ROUTE_LIST from "../../Routes/list";

export const ShareDrawer = () => {
  const { palette, border } = useTheme();
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const { settings } = useSettings();
  const [loading, setLoading] = useState(false);
  const [openInstaDrawer, setOpenInstaDrawer] = useState(false);
  const [openTiktokDrawer, setOpenTiktokDrawer] = useState(false);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const {
    showShareDrawer,
    setShowShareDrawer,
    begTitle,
    imageUrl,
    begId,
    profile,
    author,
    beg,
    shareLink,
    openAfterDonation,
  } = useAllModal();
  const [link, setLink] = useState(shareLink);
  useEffect(() => {
    setLink(shareLink);
  }, [shareLink]);

  let [isMobileDevice, setIsMobileDevice] = useState(false);
  let shareContext = settings.shareLinkContext;

  // link for general applications like facebook,twitter,whatsApp
  let generalShareLink = `${shareContext}

${link}
`;

  generalShareLink = String(generalShareLink).replaceAll(
    "%username%",
    author.orgName ? author.orgName : author.username
  );

  generalShareLink = String(generalShareLink).replaceAll(
    "%raise_title%",
    begTitle
  );

  // link for nextdoor
  let nextdoorLink = window.encodeURI(
    `https://nextdoor.com/sharekit/?hashtag=ploomsocialfundraising&source=ploomsocial.com&body=${generalShareLink}`
  );

  // link for email
  let mailShareLink = profile
    ? `mailto:?&body=${link}`
    : `mailto:?subject=Have you seen "${
        begTitle || ""
      }"?&body=${window.encodeURIComponent(`${generalShareLink}`)}`;

  // function for generate firebase dynamic link
  let getShareLink = useCallback(async () => {
    try {
      setLoading(true);
      let shareLink = await generateDynamicLink(
        begId,
        begTitle,
        imageUrl,
        profile
      );
      setLink(shareLink);
      // !profile && incrementShareCount();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShowShareDrawer(false);
      setSnack("Something gone wrong", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [begId, imageUrl, begTitle, setShowShareDrawer, setSnack, profile]);

  // check device type
  function DetectDevice() {
    let isMobile = window.matchMedia || window.msMatchMedia;
    if (isMobile) {
      let match_mobile = isMobile("(pointer:coarse)");
      return match_mobile.matches;
    }
    return false;
  }

  useEffect(() => {
    if (showShareDrawer) {
      !shareLink && getShareLink();
      setIsMobileDevice(DetectDevice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getShareLink, showShareDrawer]);

  function HandleClick(e) {
    e.stopPropagation();
  }

  function openInstaDrawerHandler() {
    setOpenInstaDrawer(true);
  }

  function openTiktokDrawerHandler() {
    setOpenTiktokDrawer(true);
  }

  function closeShareDrawer() {
    if (openAfterDonation) {
      navigate(ROUTE_LIST.FEED_DETAIL + query.get("feed"));
      return setShowShareDrawer(false);
    } else setShowShareDrawer(false);
  }

  return (
    <Dialog
      anchor="bottom"
      open={showShareDrawer}
      onClose={() => closeShareDrawer()}
      disablebackdroptransition={String(!iOS)}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
          boxShadow: "unset",
          margin: "8px",
          maxHeight: "calc(100% - 12px) !important",
          overflow: "hidden",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: border.radius,
        }}
      >
        {!loading && (
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
            onClick={() => closeShareDrawer()}
          >
            <CloseIcon
              color={
                darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : palette.black["1000"]
              }
            />
          </IconButton>
        )}
        <Box
          sx={{
            maxWidth: "550px",
            width: "100%",
            marginInline: "auto",
          }}
          onClick={HandleClick}
        >
          {openAfterDonation && <ChipinSuccessMsg />}
          {openAfterDonation && <CommentContainer />}
          <Box sx={{ padding: { xs: "20px", sm: "25px 30px 55px" } }}>
            {loading && (
              <Stack
                sx={{
                  display: "grid",
                  placeItems: "center",
                  gap: "12px",
                  color: darkMode ? "white.1000" : "primary.main",
                }}
              >
                <LoadingCircle />
                <Typography
                  sx={{ color: darkMode ? "white.1000" : "primary.main" }}
                >
                  Loading
                </Typography>
              </Stack>
            )}

            {!loading && (
              <>
                <Stack
                  paddingBottom={{ xs: "18px", sm: "24px" }}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"left"}
                  borderBottom={"1px solid"}
                  borderColor={darkMode ? "black.250" : "white.700"}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "20px", sm: "24px" },
                      fontWeight: 600,
                      lineHeight: "36px",
                      letterSpacing: "-0.40799999237060547px",
                      textAlign: "left",
                      color: darkMode ? "white.100" : "black.1000",
                    }}
                  >
                    Help by sharing
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "21px",
                      letterSpacing: "-0.40799999237060547px",
                      textAlign: "left",
                      color: darkMode
                        ? alpha(palette.white[1000], 0.6)
                        : "black.1000",
                    }}
                  >
                    Fundraisers shared on social networks raise up to 5x more.
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    paddingBlock: { xs: "18px", sm: "24px" },
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "1fr 1fr 1fr",
                      sm: "1fr 1fr 1fr 1fr",
                      md: "1fr 1fr 1fr 1fr",
                    },
                    gap: "6px",
                  }}
                >
                  <FacebookShareButton
                    url={link}
                    onClick={() => {
                      closeShareDrawer();
                    }}
                  >
                    <FacebookIcon />
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "21px", sm: "24px" },
                        letterSpacing: "-0.40799999237060547px",
                        textAlign: "center",
                      }}
                    >
                      Facebook
                    </Typography>
                  </FacebookShareButton>
                  {isMobileDevice && (
                    <IconButton
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "6px",
                        borderRadius: "0px",
                        "&:hover": { borderRadius: "0px" },
                        color: "white.850",
                      }}
                      aria-label="fingerprint"
                      onClick={() => {
                        openInstaDrawerHandler();
                      }}
                    >
                      <InstaIcon />
                      <Typography
                        sx={{
                          color: darkMode ? "white.1000" : "black.1000",
                          fontFamily: "Poppins",
                          fontSize: { xs: "14px", sm: "16px" },
                          fontWeight: 400,
                          lineHeight: { xs: "21px", sm: "24px" },
                          letterSpacing: "-0.40799999237060547px",
                          textAlign: "center",
                        }}
                      >
                        Instagram
                      </Typography>
                    </IconButton>
                  )}

                  <TwitterShareButton
                    url={generalShareLink}
                    onClick={() => {
                      closeShareDrawer();
                    }}
                    style={{
                      color: palette.white[850],
                    }}
                  >
                    <TwitterXIcon />
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "21px", sm: "24px" },
                        letterSpacing: "-0.40799999237060547px",
                        textAlign: "center",
                      }}
                    >
                      Twitter
                    </Typography>
                  </TwitterShareButton>

                  <IconButton
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "6px",
                      borderRadius: "0px",
                      "&:hover": { borderRadius: "0px" },
                    }}
                    aria-label="fingerprint"
                    color={darkMode ? "white.1000" : "black.1000"}
                    LinkComponent={Link}
                    to={nextdoorLink}
                    target="_blank"
                  >
                    <NextdoorIcon />
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "21px", sm: "24px" },
                        letterSpacing: "-0.40799999237060547px",
                        textAlign: "center",
                      }}
                    >
                      Nextdoor
                    </Typography>
                  </IconButton>

                  <IconButton
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "6px",
                      borderRadius: "0px",
                      "&:hover": { borderRadius: "0px" },
                      color: "white.850",
                    }}
                    aria-label="fingerprint"
                    LinkComponent={Link}
                    to={mailShareLink}
                  >
                    <EmailIcon />
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "21px", sm: "24px" },
                        letterSpacing: "-0.40799999237060547px",
                        textAlign: "center",
                      }}
                    >
                      Email
                    </Typography>
                  </IconButton>

                  <WhatsappShareButton
                    url={generalShareLink}
                    // url={link}
                    onClick={() => {
                      closeShareDrawer();
                    }}
                  >
                    <WhatsappIcon size={32} round={true} />
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "21px", sm: "24px" },
                        letterSpacing: "-0.40799999237060547px",
                        textAlign: "center",
                      }}
                    >
                      Whatsapp
                    </Typography>
                  </WhatsappShareButton>

                  {isMobileDevice && (
                    <IconButton
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "6px",
                        borderRadius: "0px",
                        "&:hover": { borderRadius: "0px" },
                        color: "white.850",
                      }}
                      aria-label="fingerprint"
                      onClick={() => {
                        openTiktokDrawerHandler();
                      }}
                    >
                      <TikTokIcon />
                      <Typography
                        sx={{
                          color: darkMode ? "white.1000" : "black.1000",
                          fontFamily: "Poppins",
                          fontSize: { xs: "14px", sm: "16px" },
                          fontWeight: 400,
                          lineHeight: { xs: "21px", sm: "24px" },
                          letterSpacing: "-0.40799999237060547px",
                          textAlign: "center",
                        }}
                      >
                        TikTok
                      </Typography>
                    </IconButton>
                  )}

                  {/* {isMobileDevice && ( */}
                  <IconButton
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "6px",
                      borderRadius: "0px",
                      "&:hover": { borderRadius: "0px" },
                    }}
                    aria-label="More"
                    color="white.100"
                    onClick={async () => {
                      try {
                        await navigator.share({
                          text: generalShareLink,
                        });
                        closeShareDrawer();
                      } catch (err) {
                        copy(generalShareLink);
                        setSnack("Link Copied Successfully", "success");
                        console.log(`Error: ${err}`);
                        closeShareDrawer();
                      }
                    }}
                  >
                    <MoreIcon />
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", sm: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "21px", sm: "24px" },
                        letterSpacing: "-0.40799999237060547px",
                        textAlign: "center",
                      }}
                    >
                      More
                    </Typography>
                  </IconButton>
                  {/* // )} */}
                </Stack>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    gap: "12px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: darkMode
                        ? alpha(palette.white["100"], 0.1)
                        : "white.700",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        letterSpacing: "-0.408px",
                      }}
                    >
                      Copy Link
                    </Typography>

                    <Typography
                      sx={{
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                        overflowX: "auto",
                        "::-webkit-scrollbar ": {
                          display: "none",
                        },
                        color: darkMode
                          ? alpha(palette.white[1000], 0.6)
                          : "black.1000",
                      }}
                    >
                      {link}
                    </Typography>
                  </Box>

                  <Button
                    sx={{
                      height: "100%",
                      "&": {
                        borderRadius: "5px",
                        minWidth: "unset",
                        flexShrink: "0",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      copy(link);
                      setSnack("Link Copied Successfully");
                    }}
                  >
                    Copy
                  </Button>
                </Box>
              </>
            )}
            <OtherPlatformShare link={link} />
          </Box>

          <InstaShareDialog
            openInstaDrawer={openInstaDrawer}
            setOpenInstaDrawer={setOpenInstaDrawer}
            begTitle={begTitle}
            begImage={imageUrl}
            link={link}
            beg={beg}
          ></InstaShareDialog>

          <TiktokShareDialog
            openTiktokDrawer={openTiktokDrawer}
            setOpenTiktokDrawer={setOpenTiktokDrawer}
            begTitle={begTitle}
            begImage={imageUrl}
            link={link}
            beg={beg}
          ></TiktokShareDialog>
        </Box>
      </Box>
    </Dialog>
  );
};
