import { create } from "zustand";

export const useExistDraft = create((set) => ({
  draft: undefined,
  draftExist: false,
  checked: false,
  setDraft(draft) {
    set(() => ({
      draft: draft,
      draftExist: true,
      checked: true,
    }));
  },
  setChecked() {
    set(() => ({
      draft: {},
      draftExist: false,
      checked: true,
    }));
  },
  resetExistDraftStore() {
    set(() => ({
      draft: undefined,
      draftExist: false,
      checked: false,
    }));
  },
}));
