import React from "react";
// import ProfileAvatar from "../../user/ProfileAvatar";
import { Box, Typography } from "@mui/material";

import moment from "moment/moment";
import InfiniteScroll from "react-infinite-scroll-component";
import useThemeStore from "../../../hooks/store/useThemeStore";
import Skeleton from "./Skeleton";
import CommentCard from "./CommentCard";

export default function CommentItem({
  comments,
  setPage,
  totalPages,
  page,
  feedId,
  begOwnerId,
}) {
  moment.locale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "few seconds ago",
      ss: "%ss",
      m: "a minute ago",
      mm: "%d minute ago",
      h: "an hour ago",
      hh: "%d hours ago",
      d: "a Day",
      dd: "%dd",
      M: "a month ago",
      MM: "%d month ago",
      y: "a year ago",
      yy: "%d years ago",
    },
  });
  const hashMore = page < totalPages;
  const { darkMode } = useThemeStore();
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <InfiniteScroll
        dataLength={comments.length}
        hasMore={hashMore}
        // scrollableTarget="scrollableDiv"
        next={async () => {
          if (page < totalPages) {
            setPage((prevPage) => prevPage + 1);
          }
        }}
        loader={
          <Box
            sx={{
              "&>:not([hidden])~:not([hidden])": {
                mt: 4,
              },
            }}
          >
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        }
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
        }}
      >
        {comments.map((comment, index) => (
          <CommentCard
            disableMainCommentCount
            comment={comment}
            feedId={feedId}
            key={index}
            begOwnerId={begOwnerId}
            pinned={comment.pinAt ? true : false}
          />
        ))}
      </InfiniteScroll>
      {comments.length === 0 && (
        <Typography
          sx={{
            mt: "15px",
            textAlign: "center",
            color: darkMode ? "white.1000" : "black.1000",
          }}
        >
          No comments yet.
        </Typography>
      )}
    </Box>
  );
}
