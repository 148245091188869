import { createSvgIcon } from "@mui/material";
import React from "react";

export default function SlackIcon(props) {
  const CreateNextDoorIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_615_2207)">
        <path
          d="M6.10524 18.3153C6.10524 20.0011 4.73833 21.368 3.05259 21.368C1.36691 21.368 0 20.0011 0 18.3153C0 16.6296 1.36691 15.2627 3.05265 15.2627H6.10529L6.10524 18.3153ZM7.63159 18.3153C7.63159 16.6296 8.9985 15.2627 10.6842 15.2627C12.37 15.2627 13.7369 16.6296 13.7369 18.3153V25.9469C13.7369 27.6327 12.37 28.9996 10.6842 28.9996C8.9985 28.9996 7.63159 27.6327 7.63159 25.9469V18.3153Z"
          fill="#FF185B"
        />
        <path
          d="M10.6842 6.10524C8.9985 6.10524 7.63159 4.73833 7.63159 3.05259C7.63159 1.36691 8.9985 0 10.6842 0C12.37 0 13.7369 1.36691 13.7369 3.05265V6.10529L10.6842 6.10524ZM10.6842 7.63159C12.37 7.63159 13.7369 8.9985 13.7369 10.6842C13.7369 12.37 12.37 13.7369 10.6842 13.7369H3.05265C1.36691 13.7368 0 12.3699 0 10.6842C0 8.99855 1.36691 7.63159 3.05265 7.63159H10.6842Z"
          fill="#00C5F1"
        />
        <path
          d="M22.8953 10.6842C22.8953 8.9985 24.2622 7.63159 25.948 7.63159C27.6337 7.63159 29.0006 8.9985 29.0006 10.6842C29.0006 12.37 27.6337 13.7369 25.948 13.7369H22.8953V10.6842ZM21.369 10.6842C21.369 12.37 20.0021 13.7369 18.3163 13.7369C16.6306 13.7369 15.2637 12.37 15.2637 10.6842V3.05265C15.2637 1.36691 16.6306 0 18.3163 0C20.002 0 21.369 1.36691 21.369 3.05265V10.6842Z"
          fill="#00B67B"
        />
        <path
          d="M18.3163 22.8943C20.0021 22.8943 21.369 24.2612 21.369 25.947C21.369 27.6327 20.0021 28.9996 18.3163 28.9996C16.6306 28.9996 15.2637 27.6327 15.2637 25.947V22.8943H18.3163ZM18.3163 21.368C16.6306 21.368 15.2637 20.0011 15.2637 18.3153C15.2637 16.6296 16.6306 15.2627 18.3163 15.2627H25.9479C27.6336 15.2627 29.0006 16.6296 29.0006 18.3153C29.0006 20.0011 27.6336 21.368 25.9479 21.368H18.3163Z"
          fill="#FBA500"
        />
      </g>
      <defs>
        <clipPath id="clip0_615_2207">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateNextDoorIcon
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      sx={{
        "&.MuiSvgIcon-root": { width: "unset", height: "unset" },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
