import React from "react";
import { Box, Skeleton as MaterialSkeleton } from "@mui/material";
import useThemeStore from "../../../hooks/store/useThemeStore";
import MuiSkeleton from "@mui/material/Skeleton";

export default function Skeleton() {
  const { darkMode } = useThemeStore();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <MuiSkeleton
        variant="circular"
        width={40}
        height={40}
        sx={{
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
        }}
      />
      <MaterialSkeleton
        variant="rounded"
        width={"100%"}
        height={40}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      />
    </Box>
  );
}
