import { redirect } from "react-router-dom";
import ROUTE_LIST from "../list";

export default function checkForCreateFeedForm({ params }) {
  const raise = ROUTE_LIST.FEED_CREATE.RAISE.replace(
    ROUTE_LIST.FEED_CREATE.BASE + "/",
    ""
  );
  const story = ROUTE_LIST.FEED_CREATE.STORY.replace(
    ROUTE_LIST.FEED_CREATE.BASE + "/",
    ""
  );
  const draftToCampaign = ROUTE_LIST.FEED_CREATE.DRAFT_TO_CAMPAIGNS.replace(
    ROUTE_LIST.FEED_CREATE.BASE + "/",
    ""
  );
  const allowedForms = [draftToCampaign, raise, story];
  if (!allowedForms.includes(params.type)) {
    return redirect(ROUTE_LIST.NOT_FOUND);
  }
  return { story, raise, draftToCampaign };
}
