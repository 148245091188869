import { Box, Skeleton } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function BankAccountSkeleton() {
  const { darkMode } = useThemeStore();

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ flexGrow: "1" }}>
          <Skeleton
            sx={{
              maxWidth: "40%",
              width: "150px",
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          />
          <Skeleton
            sx={{
              maxWidth: "30%",
              width: "100px",
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          />
        </Box>
        <Skeleton
          width={30}
          sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box sx={{ flexGrow: "1" }}>
          <Skeleton
            sx={{
              maxWidth: "40%",
              width: "150px",
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          />
          <Skeleton
            sx={{
              maxWidth: "30%",
              width: "100px",
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          />
        </Box>
        <Skeleton
          width={60}
          height={"30px"}
          sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        />
      </Box>
    </Box>
  );
}
