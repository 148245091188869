import React, { useCallback, useEffect, useState } from "react";
import { useExistDraft } from "../../hooks/store/useExistDraft";
import { useNavigate } from "react-router-dom";
import ROUTE_LIST from "../../Routes/list";
import DynamicMeta from "../../utils/DynamicMeta";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useApi from "../../hooks/useApi";
import axios from "axios";
import { APIS } from "../../api/lists";
import { useSnack } from "../../hooks/store/useSnack";
import DragAndDropInput from "../editRaise/DragAndDropInput";
import Input from "../ui/form/Input";
import { useAuth } from "../../hooks/store/useAuth";
import { useUserVerification } from "../../hooks/store/useUserVerification";
import { useAllModal } from "../../hooks/store/useAllModal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { useTheme } from "@emotion/react";
import FileUploadingDialog from "../ui/FileUploadingDialog";
import DraftToCampaignIdentityValidationDialog from "../commonDrawer/DraftToCampaignIdentityValidationDialog";
import handleApiError from "../../utils/handleApiError";
import CheckIcon from "../../icons/CheckIcon";
import StoryIcon from "../../icons/StoryIcon";
import RaiseIcon from "../../icons/RaiseIcon";
import { useSettings } from "../../hooks/store/useSettings";

export default function DraftToRaise() {
  const { draft, draftExist } = useExistDraft();
  const { darkMode } = useThemeStore();
  const { apiCall } = useApi();
  const { apiCall: authCall, isLoading: checkIdentityLoading } = useApi(true);
  const { apiCall: updateDraft, isLoading: updateDraftIsloading } = useApi();
  const { apiCall: draftToApiCall, isLoading } = useApi();
  const { userId, user } = useAuth();
  const { setSnack } = useSnack();
  const { settings } = useSettings();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { setNextMovement, setShowIdentityDrawer, setShowShareDrawer } =
    useAllModal();
  const { setUserVerification } = useUserVerification();
  const [showShare, setShowShare] = useState(false);
  const [postDescription, setPostDescription] = useState("");
  const [title, setTitle] = useState(undefined);
  const [data, setData] = useState({});
  useEffect(() => {
    if (!draftExist) {
      navigate(ROUTE_LIST.NOT_FOUND);
    }
  }, [draftExist, navigate]);

  // states
  const [UploadLimit, setUploadLimit] = useState(10 - draft.videos.length);
  const [totalFiles, setTotalFiles] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadError, setUploadError] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [awsUploading, setAwsUploading] = useState(true);
  const [identityPending, setIdentityPending] = useState(false);
  const [finalVideo, setFinalVideo] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [chooseType, setChooseType] = useState(
    draft.begType === 2 ? "story" : "campaign"
  ); // 1.campaign 2. story
  const [amount, setAmount] = useState(undefined);
  const [goalDate, setGoalDate] = useState(
    chooseType === "campaign"
      ? draft.goalDate
        ? moment(draft.goalDate)
        : moment().add(1, "months")
      : undefined
  );
  const [file, setFile] = useState(undefined);
  const [btnText, setBtnText] = useState(
    draft.begType === 2 ? "Post Story" : "Post Raise"
  );

  // code for upload files in aws
  const uploadFileInAWS = async (files) => {
    setTotalFiles(files);
    setOpenDialog(true);
    setUploadError([]);
    const videoArray = [...uploadedVideos];
    try {
      for (let i = videoArray.length; i < files.length; i++) {
        try {
          setCurrentFileIndex(i);
          setUploadProgress(0);
          let res;
          const unique = Date.now() + Math.ceil(Math.random() * 1e9).toString(),
            isVideo = files[i].file.type.includes("video");
          res = await apiCall({
            url: isVideo
              ? "v3/get-signed-url"
              : "v3/get-signed-url/begs/" + unique + ".jpeg",
          });
          if (res.status === 200) {
            res = res.data.data;
            await axios({
              method: "put",
              url: res.url,
              headers: {
                "Content-Type": files[i].file.type,
              },
              onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
              data: files[i].file,
            });
            videoArray.push({
              videoLink: isVideo ? res.uuid : unique,
              thumbLink: isVideo ? res.uuid : unique,
              type: isVideo ? "video" : "image",
            });
            setUploadedVideos(videoArray);
          } else {
            throw Error("link can not get");
          }
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (index === i) {
                video.uploaded = true;
              }
              return video;
            })
          );
        } catch (error) {
          setUploadError((prevError) => [...prevError, i]);
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (!video.uploadError) {
                video.uploadError = index === i;
              }
              return video;
            })
          );
          console.log("error");
          continue;
        }
      }
      setAwsUploading(false);
    } catch (error) {
      setAwsUploading(false);
      throw error;
    }
    setFinalVideo(videoArray);
    return videoArray;
    // files.map((file) => {
    //   console.log(file.file);
    //   return file;
    // });
  };

  // yup data validator schema
  const raiseAdditionalSchema = {
    amount: Yup.number()
      .required("Amount is required")
      .typeError("Amount is required")
      .min(50, "Minimum goal amount is $50"),
    goalDate: Yup.object()
      .typeError("required")
      .test(
        "is moment object",
        "provided date is not valid",
        (value) => value?._isAMomentObject || false
      )
      .test("min-date", "Please select a date after today.", (value) =>
        moment(value).isAfter(moment().add(0, "days"))
      )
      .test(
        "max-date",
        "Please choose a date that is within the 6 months.",
        (value) => moment(value).isBefore(moment().add(6, "months"))
      ),
  };

  const schema = Yup.object({
    title: Yup.string()
      .required("Title is required.")
      .trim()
      .min(3)
      .max(
        settings?.raiseTitleLimit,
        `Title must be at most ${settings?.raiseTitleLimit} characters.`
      ),
    postDescription: Yup.string()
      .trim()
      .max(
        settings?.raiseDescriptionLimit,
        `Description must be at most ${settings?.raiseDescriptionLimit} characters.`
      ),
    file: Yup.array()
      .optional()
      .min(0)
      .max(
        UploadLimit,
        `Sorry, you can only upload up to ${UploadLimit} files at a time`
      )
      .test(
        "videos-size-validation",
        "Video is too big, please upload a video less than 100mb.",
        function (value) {
          if (value) {
            let videoSizeError = false;
            Array.from(value).forEach((element) => {
              if (String(element.file.type).slice(0, 5) === "video") {
                if (element.file.size / 1048576 > 100) {
                  videoSizeError = true;
                }
              }
            });
            if (videoSizeError) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      ),
    ...(chooseType === "campaign" ? raiseAdditionalSchema : {}),
  });

  const { register, handleSubmit, formState, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      title: draft?.title,
      postDescription: draft?.textDescription,
      amount: chooseType === "campaign" ? draft.goalAmount : undefined,
      goalDate:
        chooseType === "campaign"
          ? draft.goalDate
            ? moment(draft.goalDate)
            : moment().add(30, "days")
          : moment().add(30, "days"),
    },
  });

  const draftToRaise = async () => {
    try {
      if (file && finalVideo === undefined) {
        await uploadFileInAWS(file);
        return;
      }
      setAwsUploading(false);
      let begType = chooseType === "campaign" ? 1 : 2;
      if (
        title !== draft?.title ||
        amount !== draft.goalAmount ||
        goalDate !== moment(draft.goalDate) ||
        finalVideo ||
        postDescription !== draft?.textDescription ||
        draft.begType !== begType
      ) {
        await updateDraft({
          url: APIS.DRAFT.UPDATE(draft._id),
          data: {
            title: title,
            textDescription: postDescription,
            htmlDecription: postDescription,
            goalAmount: chooseType === "campaign" ? amount : undefined,
            goalDate: chooseType === "campaign" ? goalDate : undefined,
            links: finalVideo ? finalVideo : undefined,
            begType: chooseType === "campaign" ? 1 : 2,
          },
          method: "patch",
        });
      }
      // 1. check id verification
      let response = await authCall({
        url: "v2/accounts/check-verified-2",
        method: "post",
        data: { userId },
      });
      if (
        response.data.data.isKyc.isKyc === 0 ||
        response.data.data.isKyc.isKyc === 3
      ) {
        let mobileVerification = response.data.data.mobile_verified.mobile;
        let emailVerification = response.data.data.email_verified.email;
        let kycVerification = response.data.data.isKyc.isKyc;

        setUserVerification(
          emailVerification,
          mobileVerification,
          kycVerification
        );
        return setIdentityPending(true);
      }

      setAwsUploading(false);
      const res = await draftToApiCall({
        url: APIS.DRAFT.DRAFT_TO_CAMPAIGN(draft._id),
        method: "post",
      });
      if (res.data.success === true) {
        setSnack(res.data.message);
        setData(res.data.data);
        setShowShare(true);
        // navigate("/");
        // resetExistDraftStore();
        // setOpenDialog(false);
      }
    } catch (error) {
      setSnack(error.response.data.message, "error");
    }
  };

  const makeDraftToCampaignAfterUpload = async () => {
    try {
      await updateDraft({
        url: APIS.DRAFT.UPDATE(draft._id),
        data: {
          title: title,
          textDescription: postDescription,
          htmlDecription: postDescription,
          goalAmount: chooseType === "campaign" ? amount : undefined,
          goalDate: chooseType === "campaign" ? goalDate : undefined,
          begType: chooseType === "campaign" ? 1 : 2,
          links: finalVideo ? finalVideo : undefined,
        },
        method: "patch",
      });
      // 1. check id verification
      let response = await authCall({
        url: "v2/accounts/check-verified-2",
        method: "post",
        data: { userId },
      });
      if (
        response.data.data.isKyc.isKyc === 0 ||
        response.data.data.isKyc.isKyc === 3
      ) {
        let mobileVerification = response.data.data.mobile_verified.mobile;
        let emailVerification = response.data.data.email_verified.email;
        let kycVerification = response.data.data.isKyc.isKyc;

        setUserVerification(
          emailVerification,
          mobileVerification,
          kycVerification
        );
        setOpenDialog(false);
        return setIdentityPending(true);
      }
      const res = await draftToApiCall({
        url: APIS.DRAFT.DRAFT_TO_CAMPAIGN(draft._id),
        method: "post",
      });
      setOpenDialog(false);
      if (res.data.success === true) {
        setSnack(res.data.message);
        setData(res.data.data);
        setShowShare(true);
      }
    } catch (error) {
      handleApiError(error, setSnack);
    }
  };

  const makeDraftToCampaign = async () => {
    try {
      const res = await draftToApiCall({
        url: APIS.DRAFT.DRAFT_TO_CAMPAIGN(draft._id),
        method: "post",
      });

      if (res.data.success === true) {
        setSnack(res.data.message);
        setData(res.data.data);
        setShowShare(true);
        // navigate("/");
      }
    } catch (error) {
      handleApiError(error, setSnack);
    }
  };

  const onContinueIdentity = () => {
    setNextMovement(makeDraftToCampaign);
    setIdentityPending(false);
    setShowIdentityDrawer(true, "draft-to-campaign", "/");
  };

  const postDescriptionFormValue = watch("postDescription");
  const postTitleFormValue = watch("title");
  const postAmount = watch("amount");
  const postGoalDate = watch("goalDate");
  const postFile = watch("file");
  useEffect(() => {
    if (postDescriptionFormValue) {
      setPostDescription(postDescriptionFormValue);
    }
  }, [postDescriptionFormValue]);

  useEffect(() => {
    if (postTitleFormValue) {
      setTitle(postTitleFormValue);
    }
  }, [postTitleFormValue]);

  useEffect(() => {
    if (postAmount) {
      setAmount(Number(postAmount));
    }
  }, [postAmount]);

  useEffect(() => {
    if (postGoalDate) {
      setGoalDate(postGoalDate);
    }
  }, [postGoalDate]);

  useEffect(() => {
    if (postFile) {
      setFile(postFile);
    }
  }, [postFile]);

  // function and useState for make button text dynamic
  let makeButtonTextDynamic = useCallback(() => {
    let begType = chooseType === "campaign" ? 1 : 2;
    if (
      begType === 1 &&
      (title !== draft?.title ||
        postDescription !== draft?.textDescription ||
        amount !== draft.goalAmount ||
        moment(goalDate).format("l") !== moment(draft?.goalDate).format("l") ||
        file)
    ) {
      return setBtnText("Update and Post Raise");
    } else if (begType === 1) {
      return setBtnText("Post Raise");
    } else if (
      begType === 2 &&
      (title !== draft?.title ||
        postDescription !== draft?.textDescription ||
        file)
    ) {
      return setBtnText("Update and Post Story");
    } else {
      return setBtnText("Post Story");
    }
  }, [amount, chooseType, title, draft, file, goalDate, postDescription]);

  useEffect(() => {
    makeButtonTextDynamic();
  }, [makeButtonTextDynamic]);

  return (
    <>
      <DynamicMeta
        title="Create Post | Ploom Social"
        description="Creative
Are you a creative powerhouse on social media platforms? Ploom Social offers you the opportunity to lead your followers on an enriching journey that begins and ends with you as the content creator. No more tedious partnerships or complicated strategies to make money."
      />
      <Box
        sx={{
          position: "relative",
          backgroundColor: {
            xs: darkMode ? "bgDark.third" : "white.1000",
            md: "unset",
          },
          width: "100%",
          marginInline: "auto",
          zIndex: 50,
          borderRadius: { xs: "10px", lg: "unset" },
        }}
      >
        {/* page title with back button */}
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: { xs: "0px", md: "12px" },
          }}
        >
          <Typography
            component={"span"}
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Poppins",
              fontSize: { xs: "14px", sm: "20px", md: "24px" },
              fontStyle: "normal",
              fontWeight: { xs: 400, sm: "600" },
              lineHeight: "142%" /* 34.08px */,
              letterSpacing: "-0.408px",
            }}
          >
            Create a {chooseType === "campaign" ? "Raise" : "Story"}
          </Typography>

          {/* toggle switch */}
          <Stack
            component={Button}
            onClick={() => {
              setChooseType(chooseType === "campaign" ? "story" : "campaign");
            }}
            sx={{
              "&.MuiButtonBase-root": {
                borderRadius: "125px",
                backgroundColor: darkMode ? "bgDark.third" : "white.1000",
                border: { xs: "1px solid", md: "none" },
                borderColor: darkMode
                  ? "#505050 !important"
                  : "#F5F5F5 !important",
              },
              "&.MuiButtonBase-root:hover": {
                backgroundColor: darkMode ? "bgDark.third" : "white.1000",
              },

              position: "relative",
              borderRadius: "125px",
              background: "white.1000",
              backgroundColor: "white.1000",
              padding: { xs: "3px", md: "8px 10px" },
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: "0%",
                  left: "0%",
                  transition: "all .5s ease-in-out",
                  transform:
                    chooseType === "campaign"
                      ? "translateX(100%)"
                      : "translateX(0%)",
                  width: "50%",
                  height: "100%",
                  background: "blue",
                  borderRadius: "155px",
                  zIndex: 1,
                },
              }}
            >
              <Stack
                sx={{
                  padding: { xs: "4px 10px", md: "8px 30px" },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: { xs: "2px", sm: "4px", md: "8px" },
                  borderRadius: "155px",
                  zIndex: 3,
                }}
              >
                <StoryIcon
                  color={
                    chooseType === "story"
                      ? palette.white[1000]
                      : palette.primary.disabledLight
                  }
                  sx={{
                    width: { xs: "14px", md: "20px" },
                    height: { xs: "14px", md: "20px" },
                    transition: "color .5s ease-in-out",
                  }}
                />
                <Typography
                  sx={{
                    transition: "color .5s ease-in-out",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "18px" },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "142%",
                    letterSpacing: "-0.229px",
                    color:
                      chooseType === "story"
                        ? "white.1000"
                        : "primary.disabledLight",
                  }}
                >
                  Story
                </Typography>
              </Stack>
              <Stack
                sx={{
                  padding: { xs: "4px 10px", md: "8px 30px" },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: { xs: "0px", sm: "4px", md: "8px" },
                  borderRadius: "155px",
                  zIndex: 3,
                }}
              >
                <RaiseIcon
                  color={
                    chooseType === "campaign"
                      ? palette.white[1000]
                      : palette.primary.disabledLight
                  }
                  sx={{
                    width: { xs: "11px", md: "20px" },
                    height: { xs: "11px", md: "20px" },
                    transition: "color .5s ease-in-out",
                  }}
                />
                <Typography
                  sx={{
                    transition: "color .5s ease-in-out",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "18px" },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "142%",
                    letterSpacing: "-0.408px",
                    color:
                      chooseType === "campaign"
                        ? "white.1000"
                        : "primary.disabledLight",
                  }}
                >
                  Raise
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>

        {/* form */}
        <Stack
          onSubmit={handleSubmit(draftToRaise)}
          component={"form"}
          sx={{
            mt: { xs: "0px", md: "30px" },
            display: "grid",
            gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" },
            padding: { md: "30px" },
            gridTemplateAreas: {
              xs: `"dragAndDrop"
              "oldVideo" 
              "form"`,
              lg: `"dragAndDrop form"
              "oldVideo oldVideo"`,
            },
            columnGap: { md: "20px" },
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            borderRadius: "15px",
            maxHeight: "unset !important",
            height: "100%",
          }}
        >
          {/* drag and drop input container with old video  */}
          <Stack sx={{ gridArea: "dragAndDrop" }}>
            <DragAndDropInput
              allowImage
              UploadLimit={UploadLimit}
              onChange={(value) => {
                setValue("file", value);
                setFile(value);
              }}
              register={register}
              errors={formState.errors}
              beg={draft}
              setUploadLimit={setUploadLimit}
              draft={true}
              hideRearrang={true}
            />
          </Stack>
          {/* all input boxes */}
          <Stack sx={{ gridArea: "form" }}>
            <Input
              name={"title"}
              inputLabel={"Title"}
              register={register}
              errors={formState.errors}
              placeholder="Example title"
              countDown={true}
              maxLength={settings.raiseTitleLimit}
              targetValue={title}
              onChange={(event) => {
                setTitle(
                  String(event.target.value).slice(
                    0,
                    settings.raiseTitleLimit
                  ) || ""
                );
              }}
              onBlur={(event) => {
                setTitle(event.target.value?.trim() || "");
              }}
              value={title}
            />
            <Input
              name={"postDescription"}
              inputLabel={"Description"}
              placeholder="Add details about your campaign, your goal, and why you started this campaign."
              multiline
              fullWidth
              rows={5}
              sx={{
                mt: "10px",
                "& fieldset": {
                  border: "1px solid",
                  borderColor: darkMode ? "neutral.650" : "white.400",
                  borderRadius: "10px",
                },
                "& textarea::placeholder": {
                  color: darkMode ? "white.1000" : "black.1000",
                },
                "& textarea,textarea:is(:hover,:focus)": {
                  border: "none",
                  zIndex: 2,
                  color: darkMode ? "white.1000" : "black.1000",
                  opacity: 1,
                  mb: "20px",
                },
              }}
              register={register}
              errors={formState.errors}
              onChange={(event) => {
                setPostDescription(
                  String(event.target.value).slice(
                    0,
                    settings.raiseDescriptionLimit
                  ) || ""
                );
              }}
              onBlur={(event) => {
                setPostDescription(event.target.value?.trim() || "");
              }}
              value={postDescription}
              countDown={true}
              maxLength={settings.raiseDescriptionLimit}
              targetValue={postDescription}
              countDownSx={{
                position: "absolute",
                bottom: "20px",
                right: "9px",
              }}
            />
            <Collapse in={Boolean(chooseType === "campaign" ? true : false)}>
              <Input
                inputLabel={"Goal"}
                type="number"
                placeholder="Min goal amount is $50"
                fullWidth
                name={"amount"}
                register={register}
                errors={formState.errors}
                onChange={(event) => {
                  setAmount(event.target.value);
                }}
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Fundraiser end date"
                    defaultValue={moment().add(30, "days")}
                    minDate={moment().add(1, "days")}
                    maxDate={moment().add(6, "months")}
                    sx={{
                      mt: "20px",
                      width: "100%",
                      "& .MuiSvgIcon-root": {
                        color: darkMode ? "white.1000" : "black.350",
                      },
                      "& label.Mui-focused": {
                        color: darkMode ? "white.1000" : "primary.main",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: darkMode ? "white.1000" : "black.1000",
                        },
                      },
                      "input:placeholder-shown": { border: "none" },
                      "input:focus": { border: "none" },
                      "input:hover": { border: "none" },
                      "& label, & label.Mui-disabled": {
                        color: darkMode ? "white.1000" : "primary.main",
                        fontSize: "16px",
                        fontWeight: 500,
                        fontStyle: "normal",
                        lineHeight: "normal",
                        textTransform: "capitalize",
                      },
                      "& input,input:is(:hover,:focus)": {
                        border: "none",
                        zIndex: 2,
                        color: darkMode ? "white.1000" : "black.1000",
                        opacity: 1,
                      },
                      "& fieldset": {
                        borderRadius: "10px",
                        border: "1px solid",
                        borderColor: darkMode ? "neutral.650" : "neutral.150",
                        paddingTop: "15px",
                        paddingBottom: "13px",
                      },
                    }}
                    onChange={(date) => {
                      setValue("goalDate", date);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(formState.errors.goalDate),
                        helperText: formState.errors.goalDate?.message || "",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Collapse>
            <ButtonWithLoading
              color="primary"
              disabled={
                formState.isSubmitting || isLoading || updateDraftIsloading
              }
              isLoading={
                formState.isSubmitting || isLoading || updateDraftIsloading
              }
              size="13px"
              type="submit"
              variant="contained"
              sx={{
                background: palette.gradients["main"],
                mr: "0",
                alignSelf: "end",
                maxWidth: { xs: "100%", lg: "fit-content" },
                display: "flex",
                paddingBlock: "15px",
                borderRadius: "10px",
                textTransform: "capitalize",
                mt: { xs: "20px", md: "30px" },
                "&.Mui-disabled": {
                  color: alpha(palette.white["1000"], 0.5),
                },
              }}
            >
              {btnText}
            </ButtonWithLoading>
          </Stack>
        </Stack>
      </Box>
      <FileUploadingDialog
        openDialog={openDialog}
        totalFiles={totalFiles}
        currentFileIndex={currentFileIndex}
        message={`Hold on a moment, we are updating your ${
          chooseType === "campaign" ? "raise" : "story."
        } draft`}
        uploadProgress={uploadProgress}
        isLoading={isLoading || updateDraftIsloading || checkIdentityLoading}
        setCurrentFileIndex={setCurrentFileIndex}
        setFinalVideo={setFinalVideo}
        setTotalFiles={setTotalFiles}
        setUploadProgress={setUploadProgress}
        createPost={makeDraftToCampaignAfterUpload}
        uploadError={uploadError}
        title={`Please don’t close the tabs while uploading files, Otherwise System won’t be able to update a ${
          chooseType === "campaign" ? "raise" : "story."
        } draft`}
        awsUploading={awsUploading}
      ></FileUploadingDialog>
      {identityPending && (
        <DraftToCampaignIdentityValidationDialog
          open={identityPending}
          setOpen={setIdentityPending}
          nextStep={onContinueIdentity}
        />
      )}
      <Dialog
        open={showShare}
        sx={{
          "& .MuiPaper-root": {
            mx: "15px",
            borderRadius: "15px!important",
            boxShadow: "none",
            border: "none",
            backgroundColor: "unset",
          },
        }}
      >
        <Box
          sx={{
            padding: { xs: "20px", md: "60px" },
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: "25px",
            }}
          >
            <Stack
              sx={{
                boxShadow: `0 0 0 12px ${alpha(palette.primary.main, 0.3)}`,
                borderRadius: "50%",
              }}
            >
              <CheckIcon
                color="primary.main"
                fontSize={{ xs: "34px", md: "54px" }}
              />
            </Stack>
          </Stack>
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: { xs: "20px", md: "24px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            You made it nice!
          </Typography>

          <Typography
            sx={{
              mt: { xs: "10px", md: "20px" },
              color: darkMode ? "grey.900" : "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "18px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                display: "block",
                marginBottom: "8px",
                color: darkMode ? "grey.900" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: { xs: "12px", md: "18px" },
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "-0.408px",
              }}
            >
              share your Raise
              <br />
            </Typography>
            Ask 3-5 friends to help you share. Sharing on a socialnetwork can
            raise up to 5x more!
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              mt: "40px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setShowShareDrawer(
                  true,
                  data._id,
                  data.title,
                  undefined,
                  false,
                  data.description,
                  user,
                  data,
                  data.shareLink
                );
                navigate("/");
                // share
              }}
              sx={{
                backgroundColor: "none !important",
                background: palette.gradients.main,
                textTransform: "capitalize",
                padding: "14px",
                fontSize: { xs: "12px", md: "16px" },
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              Share
            </Button>
            <Button
              variant="text"
              onClick={() => {
                navigate(ROUTE_LIST.HOME);
              }}
              sx={{
                color: darkMode ? "white.1000" : "primary.main",
                textTransform: "capitalize",
                padding: "14px",
                fontSize: { xs: "12px", md: "16px" },
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              Continue Without Sharing
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
