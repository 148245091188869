import { createSvgIcon } from "@mui/material";

const CreateImageIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.38588 17.3327H12.6081C15.4333 17.3327 17.3307 15.3509 17.3307 12.4021V5.59662C17.3307 2.64778 15.4333 0.666016 12.6089 0.666016H5.38588C2.56148 0.666016 0.664062 2.64778 0.664062 5.59662V12.4021C0.664062 15.3509 2.56148 17.3327 5.38588 17.3327ZM6.08238 8.16569C4.93351 8.16569 4 7.23092 4 6.08236C4 4.9338 4.93351 3.99902 6.08238 3.99902C7.23042 3.99902 8.16476 4.9338 8.16476 6.08236C8.16476 7.23092 7.23042 8.16569 6.08238 8.16569ZM15.5147 11.4443C15.7938 12.1598 15.6488 13.0198 15.3505 13.7284C14.9969 14.5712 14.3198 15.2094 13.4667 15.488C13.0879 15.6118 12.6907 15.666 12.2944 15.666H5.27127C4.57239 15.666 3.95396 15.4983 3.44698 15.1861C3.12939 14.9901 3.07324 14.5377 3.30872 14.2444C3.70257 13.7542 4.09139 13.2623 4.48356 12.7661C5.23104 11.8167 5.73467 11.5415 6.29444 11.7831C6.52153 11.8829 6.74946 12.0325 6.9841 12.1908C7.60923 12.6156 8.47822 13.1995 9.6229 12.5657C10.4062 12.127 10.8606 11.3745 11.2562 10.7191L11.2628 10.7081C11.2907 10.6624 11.3184 10.6166 11.346 10.571L11.3464 10.5704C11.4793 10.3503 11.6105 10.1333 11.7589 9.9333C11.9449 9.68304 12.6346 8.90044 13.5279 9.45772C14.0969 9.8086 14.5754 10.2833 15.0874 10.7916C15.2826 10.9859 15.4217 11.2069 15.5147 11.4443Z"
    fill="white"
  />
);

export default function ImageIcon(props) {
  return (
    <CreateImageIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
      }}
      {...props}
    />
  );
}
