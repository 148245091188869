import React from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import CommentModal from "../component/feed/Comment/CommentModal";
import { useParams } from "react-router-dom";

const Comments = () => {
  const param = useParams();
  return (
    <BackButtonLayout
      pageTitle={"Comments"}
      isTitleBold
      sx={{ maxWidth: "565px" }}
      containerStyle={{
        padding: { md: "15px" },
      }}
    >
      <CommentModal id={param.id} setCommentCount={""} setOpenDrawer={""} />
    </BackButtonLayout>
  );
};

export default Comments;
