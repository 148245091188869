import { createSvgIcon } from "@mui/material";

export default function AddIcon(props) {
  let CreateAddIcon = createSvgIcon(
    <path
      d="M13.7079 26.4913C13.3179 26.4913 12.9936 26.3602 12.7351 26.0979C12.4766 25.8356 12.3473 25.5105 12.3473 25.1228V15.0864H2.31097C1.92321 15.0864 1.59817 14.9545 1.33585 14.6907C1.07354 14.4268 0.942383 14.0999 0.942383 13.7099C0.942383 13.3198 1.07354 12.9955 1.33585 12.737C1.59817 12.4785 1.92321 12.3492 2.31097 12.3492H12.3473V2.31293C12.3473 1.92516 12.4792 1.60012 12.743 1.33781C13.0069 1.07549 13.3339 0.944336 13.7239 0.944336C14.1139 0.944336 14.4382 1.07549 14.6967 1.33781C14.9552 1.60012 15.0845 1.92516 15.0845 2.31293V12.3492H25.1208C25.5086 12.3492 25.8336 12.4812 26.0959 12.745C26.3582 13.0089 26.4894 13.3358 26.4894 13.7258C26.4894 14.1159 26.3582 14.4402 26.0959 14.6987C25.8336 14.9572 25.5086 15.0864 25.1208 15.0864H15.0845V25.1228C15.0845 25.5105 14.9526 25.8356 14.6887 26.0979C14.4249 26.3602 14.0979 26.4913 13.7079 26.4913Z"
      fill="white"
    />
  );
  return (
    <CreateAddIcon
      width="17"
      height="17"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
      }}
      {...props}
    />
  );
}
