import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment/moment";

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "few seconds ago",
    ss: "%ss",
    m: "a minute ago",
    mm: "%d minute ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "a Day",
    dd: "%dd",
    M: "a month ago",
    MM: "%d month ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});

const GeneralNotificationItem = ({ item }) => {
  const { typography, palette } = useTheme();
  // console.log(item);
  return (
    <>
      <Box
        position={"relative"}
        padding={"10px"}
        borderRadius={"8px"}
        mb={2}
        sx={{ backgroundColor: alpha(palette.white["400"], 0.15) }}
      >
        {/* close Icon */}
        <Button
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "black.300",
            minWidth: "auto",
            backgroundColor: "white.1000",
            borderRadius: "50%",
            padding: "1px",
            border: "1px solid",
            borderColor: "grey.100",
          }}
        >
          <CloseIcon sx={{ color: "grey.400", fontSize: "16px" }}></CloseIcon>
        </Button>

        {/* avatar with text */}
        <Stack direction={"row"} gap={"9px"}>
          <Avatar
            src={item?.sender?.profileImage}
            sx={{ bgcolor: "primary.main" }}
            aria-label="userprofile"
          >
            {/* {String(item?.sender?.firstName).slice(0, 1)} */}
          </Avatar>
          <Stack direction={"column"}>
            <Typography
              sx={{
                fontFamily: typography.inter,
                fontWeight: "700",
                fontSize: "14px",
                textTransform: "capitalize",
                color: "black.350",
                textShadow: `0px 0px 11px   ${alpha(
                  palette.black["1000"],
                  0.15
                )}`,
              }}
            >
              {item?.sender
                ? `${item.sender.firstName} ${item.sender.lastName}`
                : "Anonymous User"}
            </Typography>
            <Typography
              sx={{
                fontFamily: typography.inter,
                fontWeight: "500",
                fontSize: "12px",
                opacity: "0.80",
                color: "black.350",
                textShadow: `0px 0px 11px   ${alpha(
                  palette.black["1000"],
                  0.15
                )}`,
              }}
            >
              {String(item.notificationType).slice(0, 1).toUpperCase()}
              {String(item.notificationType).slice(1).toLowerCase()}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          sx={{
            fontFamily: typography.inter,
            fontWeight: "500",
            fontSize: { xs: "12px", md: "14px" },
            opacity: "0.50",
            marginTop: "14px",
            color: "black.350",
          }}
        >
          {item.message}
        </Typography>
        <Typography
          sx={{
            marginTop: "8px",
            fontFamily: typography.inter,
            fontWeight: "500",
            fontSize: { xs: "12px", md: "14px" },
            opacity: "0.41",
            color: "black.350",
          }}
        >
          {moment(item.createdAt).fromNow()}
        </Typography>
      </Box>
    </>
  );
};

export default GeneralNotificationItem;
