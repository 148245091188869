import {
  IconButton,
  Stack,
  Typography,
  useTheme,
  alpha,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
// import FeedMoreMenu from "./FeedMoreMenu";
import ProfileAvatar from "../user/ProfileAvatar";
// import VarifiedIcon from "../../icons/VarifiedIcon";
import AddIcon from "../../icons/AddIcon";
import { useAuth } from "../../hooks/store/useAuth";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";
import { useFeed } from "../../hooks/store/useFeed";
import useThemeStore from "../../hooks/store/useThemeStore";
import VerifiedIcon from "../../icons/VerifiedIcon";
import { useAllModal } from "../../hooks/store/useAllModal";
export default function CardHeader({
  author = {},
  username,
  profileImage,
  title,
  id,
  description,
  activeVideo,
}) {
  const { setSnack } = useSnack();
  const { apiCall, isLoading } = useApi();
  const { typography } = useTheme();
  const { isLoggedIn, userId } = useAuth();
  const { setFollowUser } = useFeed();
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const { setOpenKycDialog } = useAllModal();

  const profileLink =
    author?._id === userId ? "/profile" : "/profile/" + author._id;
  async function followUser(Id) {
    try {
      const res = await apiCall({
        url: "v3/followers",
        method: "POST",
        data: { userId: userId, followerId: author._id },
      });
      if (res.status === 200) {
        setFollowUser(author._id);
        setSnack(res.data.message, "success");
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Box
      flexGrow={1}
      display={"grid"}
      gridTemplateColumns={"auto 1fr auto"}
      gap={{ xs: "10px", sm: "15px" }}
      top={0}
      right={0}
      left={0}
      zIndex={12}
    >
      {/* profile button */}
      <Stack sx={{ position: "relative" }}>
        <Link to={profileLink}>
          <ProfileAvatar
            alt={username}
            src={profileImage}
            sx={{
              width: { xs: "36px", sm: "42px" },
              height: { xs: "36px", sm: "42px" },
              backgroundColor: darkMode
                ? alpha(palette.black["350"], 0.4)
                : alpha(palette.white["1000"], 0.4),
              backdropFilter: "blur(20px)",
            }}
          />
        </Link>

        {/* follow icon */}
        {!author.following && isLoggedIn && author._id !== userId && (
          <IconButton
            onClick={() => {
              followUser(author.userId);
            }}
            sx={{
              "&.MuiIconButton-root": { borderRadius: "50%" },
              pointerEvents: isLoading ? "none" : "",
              position: "absolute",
              top: "65%",
              left: "55%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "primary.main",
              scale: "0.5",
              "&:hover": {
                backgroundColor: "primary.main",
              },
            }}
          >
            {isLoading ? (
              <CircularProgress size={22} sx={{ color: "white.1000" }} />
            ) : (
              <AddIcon />
            )}
          </IconButton>
        )}
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {/* user name */}
        <Box display={"grid"}>
          <Typography
            children={!author.orgName ? author.username : author.orgName}
            component={Link}
            to={profileLink}
            fontFamily={typography.inter}
            fontWeight={700}
            fontSize={{ xs: "12px", sm: "14px" }}
            textTransform={"unset"}
            sx={{
              fontFamily: "inter",
              fontSize: "16px",
              textDecoration: "none",
              display: "inline",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "white.1000",
            }}
          />
        </Box>

        {author.isKyc === 1 && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "7px",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenKycDialog(true, author.verifiedAt);
            }}
          >
            <VerifiedIcon id={"verifiedIcon"}></VerifiedIcon>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
