import { Box } from "@mui/material";
import React from "react";
import DynamicMeta from "../utils/DynamicMeta";
import Layout from "../component/Layout";
import useThemeStore from "../hooks/store/useThemeStore";
import ComingSoonSvg from "../icons/ComingSoonSvg";

export default function ComingSoon() {
  const { darkMode } = useThemeStore();
  return (
    <Layout>
      <DynamicMeta title="Coming soon | Ploom Social" />

      <Box
        minWidth={"100%"}
        display={"flex"}
        justifyContent={"center"}
        paddingInline={"15px"}
      >
        <Box
          sx={{
            mt: { xs: "15px", md: "30px" },
            padding: "30px",
            maxWidth: "673px",
            width: "100%",
            height: "100%",
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            display: "flex",
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "center",
            borderRadius: "15px",
            zIndex: 50,
            minHeight: { xs: "70vh", sm: "unset" },
          }}
        >
          <ComingSoonSvg
            color={darkMode ? "#3e3e3e" : "#EBEBEB"}
            icon={darkMode ? "#4B4A4A" : "#FAFAFA"}
            text={darkMode ? "#FFFFFF" : "#000000"}
            sx={{
              width: { xs: "270px", sm: "320px", md: "520px" },
              height: { xs: "270px", sm: "320px", md: "520px" },
            }}
          />

          {/* <Stack sx={{ mt: { xs: "50px", md: "30px" } }}>
          </Stack> */}

          {/* <Button
            component={Link}
            to="/"
            variant="contained"
            fullWidth
            sx={{
              mt: { xs: "25px", md: "15px" },
              borderRadius: "10px",
              paddingBlock: "15px",
              backgroundColor: "primary.disabledDark",
              textAlign: "center",
              color: "white.650",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "22px",
              letterSpacing: "-0.408px",
              textTransform: "unset",
            }}
          >
            Go Home
          </Button> */}
        </Box>
      </Box>
    </Layout>
  );
}
