import { Box, Typography } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";

export default function CustomDialogContent({
  heading = "Draft",
  description = "Ready to continue your fundraising journey? choose to either resume editing your existing draft or start a fresh campaign. Remember, starting a new campaign means your unsaved draft will be lost. What would you like to do?",
  firstButton = { text: "", onClick: () => {}, isLoading: false },
  secondButton = { text: "", onClick: () => {}, isLoading: false },
}) {
  let { darkMode } = useThemeStore();
  return (
    <>
      <Typography
        sx={{
          maxWidth: { xs: "257px", md: "330px" },
          mx: "auto",
          color: darkMode ? "white.1000" : "black.350",
          textAlign: "center",
          fontSize: { xs: "18px", md: "24px" },
          fontStyle: " normal",
          fontWeight: 600,
          lineHeight: "normal",
          letterSpacing: "-0.408px",
        }}
      >
        {heading}
      </Typography>
      <Typography
        sx={{
          mt: "15px",
          fontWeight: 500,
          fontSize: { xs: "12px", sm: "14px" },
          lineHeight: 1.5,
          mx: "auto",
          color: darkMode ? "white.1000" : "black.350",
          textAlign: "center",
        }}
      >
        Welcome to PloomSocial! <br />
        {description}
      </Typography>
      <Box
        sx={{
          display: "flex",
          mt: { xs: "20px", sm: "25px", md: "35px" },
          justifyContent: "space-between",
          gap: { xs: "10px", sm: "25px", md: "35px" },
        }}
      >
        <ButtonWithLoading
          onClick={firstButton.onClick}
          variant={darkMode ? "contained" : "outlined"}
          sx={{
            backgroundColor: darkMode ? "black.900" : "unset",
            width: "100%",
            textTransform: "capitalize",
            padding: { xs: "10px 6px", md: "14px" },
            fontSize: { xs: "12px", md: "14px" },
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            borderRadius: "10px",
          }}
          isLoading={firstButton.isLoading}
        >
          {firstButton.text}
        </ButtonWithLoading>
        <ButtonWithLoading
          variant="contained"
          onClick={secondButton.onClick}
          isLoading={secondButton.isLoading}
          sx={{
            textTransform: "capitalize",
            padding: { xs: "8px", md: "14px" },
            fontSize: { xs: "12px", md: "14px" },
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          {secondButton.text}
        </ButtonWithLoading>
      </Box>
    </>
  );
}
