import { Box, Button, Card, Typography, alpha } from "@mui/material";
import React from "react";
import Heading from "../ui/Heading";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import Note from "../payout/Note";
import BankAccountList from "./BankAccountList";
import ROUTE_LIST from "../../Routes/list";

export default function WidthdrawalHeader({
  balance,
  feedId,
  heading = "Withdrawal Settings",
}) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const showBalance = balance !== undefined;
  return (
    <Box>
      <Heading>{heading}</Heading>
      <Card
        sx={{
          borderRadius: { xs: "20px", md: "30px" },
          overflow: "hidden",
          backgroundColor: "transparent",
          mb: { xs: "10px", md: "15px" },
          border:
            "1px solid" +
            (darkMode ? palette.neutral[650] : palette.grey[1050]),
        }}
      >
        {/* card header */}
        {showBalance && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              backgroundColor: "primary.main",
              color: "white.1000",
              p: { xs: "14px 25px", md: "18px 30px" },
            }}
          >
            <Typography
              flexGrow={1}
              sx={{ fontSize: "14px", fontWeight: 600, lineHeight: 1.5 }}
            >
              Available Balance
            </Typography>
            <Typography
              fontSize="18px"
              sx={{ fontSize: "18px", fontWeight: 600, lineHeight: 1.5 }}
              flexShrink={0}
            >
              ${balance}
            </Typography>
          </Box>
        )}
        <Box
          p={{ xs: "20px", md: "30px" }}
          sx={{
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            boxShadow: "0px 0px 4px 0px " + alpha(palette.black[1000], 0.1),
          }}
        >
          <BankAccountList />
          <Button
            variant="contained"
            sx={{
              width: "100%",
              mt: "40px",
              borderRadius: "10px",
              textTransform: "capitalize",
              fontSize: "14px",
              lineHeight: 1.5,
              fontWeight: 500,
              p: { xs: "10px", md: "15px" },
              textDecoration: "none",
            }}
            LinkComponent={Link}
            to={
              showBalance
                ? ROUTE_LIST.ADD_ACH +
                  "?next=" +
                  ROUTE_LIST.FEED_WITHDRAWAL.redirect +
                  feedId
                : ROUTE_LIST.ADD_ACH
            }
          >
            Add
          </Button>
        </Box>
      </Card>
      <Note />
    </Box>
  );
}
