import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, alpha } from "@mui/material";
import React, { useEffect, useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

export default function CustomTabs({ tabs = [], defaultTab }) {
  const [value, setValue] = useState(defaultTab);

  const firstTabValue = tabs[0].value;
  useEffect(() => {
    setValue(defaultTab || firstTabValue);
  }, [defaultTab, firstTabValue]);
  const handleTabChange = (_, newValue) => {
    setValue(newValue);
  };
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const tabStyles = {
    position: "relative",
    fontSize: { xs: "12px", md: "14px" },
    textTransform: "capitalize",
    padding: { xs: "14px 16px" },
    color: darkMode
      ? alpha(palette.white["1000"], 0.7)
      : alpha(palette?.black["1000"], 0.5),
    fontWeight: "600",
    "&.Mui-selected": {
      background: darkMode ? palette.gradients.main : "transparent",
      backgroundClip: darkMode ? "text" : "unset",
      color: darkMode ? "transparent" : "primary.main",
      fontWeight: "700",
    },
  };
  const divider = {
    "&::before": {
      content: "''",
      position: "absolute",
      top: "50%",
      left: "99%",
      transform: "translate(-50%,-50%)",
      width: "1px",
      height: "50%",
      backgroundColor: "grey.300",
    },
  };
  return (
    <TabContext value={value || tabs[0].value}>
      <Box
        sx={{
          zIndex: "2",
        }}
      >
        <TabList
          onChange={handleTabChange}
          aria-label="user all raises and donation history tabs"
          sx={{
            borderRadius: "10px",
            backgroundColor: darkMode
              ? "grey.550"
              : alpha(palette.primary.light, 0.1),
            backdropFilter: "blur(24px)",

            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab.name}
              value={tab.value}
              key={index}
              sx={{
                ...tabStyles,
                ...(index + 1 === tabs.length ? {} : divider),
              }}
            />
          ))}
        </TabList>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel
          value={tab.value}
          sx={{
            padding: "0px",
            paddingTop: "20px",
            mb: { xs: "77px", md: 0 },
          }}
          key={index}
        >
          <tab.Component />
        </TabPanel>
      ))}
    </TabContext>
  );
}
