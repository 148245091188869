import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import { useAuth } from "../../hooks/store/useAuth";
import { List, Typography } from "@mui/material";
import BankAccount from "./BankAccount";
import useThemeStore from "../../hooks/store/useThemeStore";
import BankAccountSkeleton from "../skeleton/BankAccountSkeleton";
import DialogBox from "../Dialogbox/DialogBox";
import ConfirmPrimaryDialog from "./dialog/ConfirmPrimaryDialog";
import { useSnack } from "../../hooks/store/useSnack";
import handleApiError from "../../utils/handleApiError";
import DeleteConfirmDilog from "./dialog/DeleteConfirmDilog";
import DeletePrimary from "./dialog/DeletePrimary";
import CanNotDeleteDialog from "./dialog/CanNotDeleteDialog";
import { useNavigate } from "react-router-dom";
import ROUTE_LIST from "../../Routes/list";
import TransactionActiveDialog from "./dialog/TransactionActiveDialog";

const RenderModal = ({
  openModal,
  closeModal,
  accounts,
  confirmPrimaryDeleteAccount,
  deleteAccountCall,
  deleteLoading,
  confirmPrimaryCall,
  primaryLoading,
}) => {
  const navigate = useNavigate();
  switch (openModal.type) {
    case "confirm":
      return (
        <ConfirmPrimaryDialog
          confirmCall={confirmPrimaryCall.bind(null, openModal.primaryId)}
          isLoading={primaryLoading}
          cancelCall={closeModal}
        />
      );
    case "delete":
      return (
        <DeleteConfirmDilog
          confirmCall={deleteAccountCall.bind(null, openModal.deleteId)}
          isLoading={deleteLoading}
          cancelCall={closeModal}
        />
      );
    case "delete-primary":
      return (
        <DeletePrimary
          bankAccounts={accounts}
          confirmCall={confirmPrimaryDeleteAccount}
          cancelCall={closeModal}
        />
      );
    case "not-delete":
      return (
        <CanNotDeleteDialog
          confirmCall={() => navigate(ROUTE_LIST.ADD_ACH)}
          cancelCall={closeModal}
        />
      );
    case "transaction-active":
      return <TransactionActiveDialog cancelCall={closeModal} />;
    default:
      return null;
  }
};

export default function BankAccountList() {
  const [accounts, setAccounts] = useState([]);
  const [openModal, setOpenModal] = useState({
    type: "",
    primaryId: "",
    deleteId: "",
  });
  const { userId } = useAuth();
  let { darkMode } = useThemeStore();
  const { apiCall, isLoading } = useApi();
  const { apiCall: primaryCall, isLoading: primaryLoading } = useApi();
  const { apiCall: deleteCall, isLoading: deleteLoading } = useApi();
  const { setSnack } = useSnack();

  useEffect(() => {
    (async function () {
      let response = await apiCall({ url: APIS.BANK_ACCOUNT.LIST(userId) });
      if (response.data.success === true) {
        let records = response.data.data.results;
        if (records.length === 0 && response.data.data.pagination.records > 0) {
          let response = await apiCall({
            url: APIS.BANK_ACCOUNT.LIST(userId),
          });

          if (response.data.success === true) {
            records = response.data.data.results;
          }
        }
        setAccounts(records);
      }
    })();
  }, [userId, apiCall]);
  const closeModal = () =>
    setOpenModal({ type: "", deleteId: "", primaryId: "" });
  /**
   set primary account 
  */
  const confirmPrimaryCall = async (id) => {
    try {
      const res = await primaryCall({
        url: APIS.BANK_ACCOUNT.EDIT(id),
        method: "patch",
        data: { default: true },
      });
      if (res.data.success === true) {
        // setSnack(res.data.message);
        setAccounts((prevAccounts) =>
          prevAccounts.map((account) => {
            account.default = account._id === id;
            return account;
          })
        );
        if (openModal.deleteId) {
          deleteAccountCall(openModal.deleteId);
          setOpenModal((prevState) => ({ ...prevState, type: "delete" }));
          return;
        }

        closeModal();
      }
    } catch (error) {
      handleApiError(error, setSnack);
      closeModal();
    }
  };
  const setPrimaryAccount = async (id) => {
    setOpenModal((prev) => ({ ...prev, type: "confirm", primaryId: id }));
  };
  /**
   end set primary account 
  */
  /**
   delete account 
  */
  const deleteAccountCall = async (id) => {
    try {
      const res = await deleteCall({
        url: APIS.BANK_ACCOUNT.DELETE(id),
        method: "delete",
      });
      if (res.data.success === true) {
        closeModal();
        setAccounts((prevAccounts) =>
          prevAccounts.filter((account) => account._id !== id)
        );

        // closeModal();
      }
    } catch (error) {
      if (error?.response.status === 403) {
        setOpenModal((prev) => ({ ...prev, type: "transaction-active" }));
        handleApiError(error, setSnack);
        return;
      }
      closeModal();
    }
  };
  const deleteBankAccount = async (isDefault, id) => {
    if (accounts.length === 1) {
      return setOpenModal((prev) => ({
        ...prev,
        type: "not-delete",
        deleteId: id,
      }));
    }

    setOpenModal((prev) => ({
      ...prev,
      type: isDefault ? "delete-primary" : "delete",
      deleteId: id,
    }));
  };
  /**
   end delete account 
  */
  /** delete primary account */
  const confirmPrimaryDeleteAccount = (primaryId) => {
    setOpenModal((prev) => ({ ...prev, type: "confirm", primaryId }));
  };
  /** end delete primary account */

  return (
    <>
      <DialogBox
        Content={() => (
          <RenderModal
            accounts={accounts}
            openModal={openModal}
            closeModal={closeModal}
            confirmPrimaryDeleteAccount={confirmPrimaryDeleteAccount}
            deleteAccountCall={deleteAccountCall}
            deleteLoading={deleteLoading}
            confirmPrimaryCall={confirmPrimaryCall}
            primaryLoading={primaryLoading}
          />
        )}
        setOpenDialog={setOpenModal.bind(null, { type: "", id: "" })}
        openDialog={openModal.type !== ""}
      ></DialogBox>
      {isLoading && (
        <>
          <BankAccountSkeleton />
          <BankAccountSkeleton />
        </>
      )}
      {!isLoading && accounts.length === 0 && (
        <Typography
          mt={"10px"}
          sx={{
            color: darkMode ? "white.1000" : "grey.750",
            fontSize: "14px",
            lineHeight: 1.5,
          }}
        >
          ACH Withdrawals / None
        </Typography>
      )}
      {!isLoading && (
        <List>
          {accounts.map((account) => (
            <BankAccount
              key={account._id}
              id={account._id}
              accountNumber={account.label}
              routeNumber={account.routeNumber}
              defaultAccount={account.default}
              setPrimaryAccount={setPrimaryAccount}
              deleteBankAccount={deleteBankAccount}
            />
          ))}
        </List>
      )}
    </>
  );
}
