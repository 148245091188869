import { Button, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";

const CardTextContent = ({ title, description, author }) => {
  const [showMore, setShowMore] = useState(false);
  const htmlDescription = description;
  const truncatedText = showMore
    ? htmlDescription
    : htmlDescription.slice(0, 80);
  const { darkMode } = useThemeStore();

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <CardContent
      sx={{ p: 0, "&.MuiCardContent-root:last-child": { paddingBottom: 0 } }}
    >
      <Typography
        sx={{
          color: darkMode ? "white.1000" : "black.1000",
          fontFamily: "Poppins",
          fontSize: { xs: "14px", md: "16px" },
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          mt: "5px",
          color: darkMode ? "grey.900" : "black.350",
          fontFamily: "Poppins",
          fontSize: { xs: "12px", md: "13px" },
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        }}
      >
        {truncatedText}{" "}
        {htmlDescription.length > 90 && (
          <Button
            onClick={() => {
              toggleShowMore();
            }}
            sx={{
              padding: 0,
              margin: 0,
              textTransform: "unset",
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "13px" },
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              outline: "none",
            }}
          >
            {showMore ? "Load less" : "Load more"}
          </Button>
        )}
      </Typography>
      <Typography
        sx={{
          mt: "10px",
          color: darkMode ? "white.1000" : "black.350",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        }}
      >
        your donation will be in support of{" "}
        <Typography
          component={"span"}
          sx={{ fontSize: "13px", fontWeight: 700 }}
        >
          {author.firstName + " " + author.lastName}
        </Typography>
      </Typography>
    </CardContent>
  );
};

export default CardTextContent;
