import React from "react";
import NextdoorIcon from "../../icons/share/NextdoorIcon";
import { Box, IconButton, Stack, Typography, alpha } from "@mui/material";
import MoreIcon from "../../icons/share/MoreIcon";
import YoutubeIcon from "../../icons/share/YoutubeIcon";
import SlackIcon from "../../icons/share/SlackIcon";
import InstaIcon from "../../icons/share/InstaIcon";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { useSnack } from "../../hooks/store/useSnack";
import copy from "copy-to-clipboard";
import TikTokIcon from "../../icons/share/TikTokIcon";

const OtherPlatformShare = ({ link }) => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { setSnack } = useSnack();
  return (
    <Box
      sx={{
        mt: "28px",
        padding: { xs: "12px", sm: "20px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        backgroundColor: darkMode
          ? alpha(palette.white["100"], 0.1)
          : "white.700",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "21px",
          letterSpacing: "-0.40799999237060547px",
          textAlign: "center",
          color: darkMode ? "white.100" : "black.1000",
        }}
      >
        <Typography component={"span"} sx={{ fontWeight: 700 }}>
          Tip :{" "}
        </Typography>
        Paste this fundraiser link anywhere.
      </Typography>

      <Stack
        sx={{
          mt: "20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
        }}
      >
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            copy(link);
            setSnack("Link Copied Successfully");
          }}
        >
          <InstaIcon />
        </IconButton>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            copy(link);
            setSnack("Link Copied Successfully");
          }}
        >
          <SlackIcon />
        </IconButton>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            copy(link);
            setSnack("Link Copied Successfully");
          }}
        >
          <YoutubeIcon />
        </IconButton>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            copy(link);
            setSnack("Link Copied Successfully");
          }}
        >
          <TikTokIcon />
        </IconButton>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            copy(link);
            setSnack("Link Copied Successfully");
          }}
        >
          <NextdoorIcon />
        </IconButton>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            copy(link);
            setSnack("Link Copied Successfully");
          }}
        >
          <MoreIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default OtherPlatformShare;
