import React from "react";
import { Box, Icon, InputAdornment, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../../component/ui/form/Input";
import { useAuth } from "../../hooks/store/useAuth";
import useThemeStore from "../../hooks/store/useThemeStore";
import ButtonWithLoading from "../../component/ui/button/ButtonWithLoading";
import MobileInput from "../ui/form/MobileInput";
import { useKyc } from "../../hooks/store/useKyc";
import {
  maxCharacterMessage,
  minCaharacterMessage,
} from "../../utils/formErrorMessage";
import EditIcon from "../../icons/EditIcon";
import { useTheme } from "@emotion/react";

export default function BasicInfoForm({ setOpenForm, setStep }) {
  const { darkMode } = useThemeStore();
  const { user } = useAuth();
  const { setStep1 } = useKyc();
  const { palette } = useTheme();
  const phoneRegExp = /^(\d{1,4}(\s|-)?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const schema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required("First name must be required.")
      .min(3, minCaharacterMessage("First Name", 3))
      .max(12, maxCharacterMessage("First Name", 40)),
    surName: Yup.string()
      .trim()
      .min(3, minCaharacterMessage("Last name", 3))
      .max(12, maxCharacterMessage("Last name", 40))
      .required("Last name must be required."),
    email: Yup.string().required("email must be required.").email(),
    mobileNumber: Yup.string()
      .required("Mobile number must be required.")
      .matches(phoneRegExp, "Invalid mobile number"),
  });

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      firstName: user.firstName || "",
      surName: user.lastName || "",
      mobileNumber: `${user.code}${user.mobile}`,
      email: user.email,
    },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  console.log(formState.isValid, formState.errors, formState.defaultValues);
  async function processKyc(values) {
    try {
      setStep1(
        values.firstName,
        values.surName,
        values.mobileNumber,
        values.email
      );
      setOpenForm("step2");
      setStep(1);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
        mx: "auto",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          mt: { xs: "20px", md: "30px" },
          display: "grid",
          gridTemplateColumns: "1fr",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        {/* form heading */}
        {/* <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontSize: { xs: "16px", md: "18px" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          Enter your details
        </Typography> */}
        {/* form */}
        <Stack onSubmit={handleSubmit(processKyc)} component={"form"}>
          <Input
            name={"firstName"}
            inputLabel={"First name"}
            register={register}
            errors={formState.errors}
            placeholder="Enter First name"
            sx={{ mt: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" sx={{ zIndex: 2 }}>
                  <Icon
                    sx={{
                      position: "absolute",
                      top: "30%",
                      transform: "translate(-50%)",
                      right: "0%",
                      zIndex: 1,
                    }}
                  >
                    <EditIcon
                      color={
                        darkMode ? palette.white["1000"] : palette.black["350"]
                      }
                    ></EditIcon>
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <Input
            name={"surName"}
            inputLabel={"Last Name"}
            placeholder="Enter First name"
            register={register}
            errors={formState.errors}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" sx={{ zIndex: 2 }}>
                  <Icon
                    sx={{
                      position: "absolute",
                      top: "30%",
                      transform: "translate(-50%)",
                      right: "0%",
                      zIndex: 1,
                    }}
                  >
                    <EditIcon
                      color={
                        darkMode ? palette.white["1000"] : palette.black["350"]
                      }
                    ></EditIcon>
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
          <Input
            name={"email"}
            inputLabel={"Email"}
            placeholder="Enter Your Email"
            disabled
            register={register}
            errors={formState.errors}
          />

          <MobileInput
            placeholder={`+${user.code + " " + user.mobile}`}
            defaultValue={`+${user.code} ${user.mobile}`}
            inputLabel="Mobile number"
            // register={register}
            // errors={formState.errors}
            disabled
          />

          {/* next and previous button container */}
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "15px" }}>
            <ButtonWithLoading
              type="submit"
              disabled={!formState.isValid || formState.isSubmitting}
              variant="contained"
              fullWidth
              sx={{
                flex: 1,
                paddingBlock: "15px",
                borderRadius: "10px",
                textTransform: "capitalize",
                mt: "30px",
                "&.Mui-disabled": {
                  color: darkMode ? "black.100" : "white.1000",
                  backgroundColor: darkMode
                    ? "primary.disabledDark"
                    : "primary.disabledLight",
                },
              }}
            >
              Next
            </ButtonWithLoading>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
