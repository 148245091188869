import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileSkeleton } from "../skeleton/ProfileSkeleton";
import { formatNumberCount } from "../../utils/NumberFormater";
import useApi from "../../hooks/useApi";
import { useTopProgressBar } from "../../hooks/store/useTopProgressBar";
import { useAuth } from "../../hooks/store/useAuth";
import { LoadingButton } from "@mui/lab";
import { useSnack } from "../../hooks/store/useSnack";
import { useFeed } from "../../hooks/store/useFeed";
import CameraIcon from "../../icons/CameraIcon";
import DynamicMeta from "../../utils/DynamicMeta";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";
import CloseIcon from "../../icons/CloseIcon";
import axios from "axios";
import { APIS } from "../../api/lists";
import MoreSquareIcon from "../../icons/MoreSquareIcon";
import ToggleMenu from "../ui/ToggleMenu";
import { useAllModal } from "../../hooks/store/useAllModal";
import ROUTE_LIST from "../../Routes/list";
import VerifiedIcon from "../../icons/VerifiedIcon";
import FileUploadIcon from "../../icons/FileUploadIcon";
import DustbinIcon2 from "../../icons/DustbinIcon2";

const ProfileHeader = ({
  userId,
  setUserStats,
  userStats: userStatsInitial,
}) => {
  const { userId: cuserId } = useAuth();
  const { setSnack } = useSnack();
  const { setShowReportDrawer, setShowShareDrawer, setOpenKycDialog } =
    useAllModal();
  // const navigate = useNavigate();
  const { palette } = useTheme();
  const { resetFeedStore } = useFeed();
  const [user, setUser] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isProfilePictureLoading, setIsProfilePictureLoading] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [following, setFollowing] = useState(false);
  const [followingId, setFollowingId] = useState("");
  const [showDetailPicture, setShowDetailPicture] = useState(false);
  const { setUserProfile } = useAuth();
  const [isBlocked, setIsBlocked] = useState(undefined);
  const { apiCall, isLoading } = useApi();
  const { apiCall: api } = useApi();
  const { setProgressBarValue } = useTopProgressBar();
  const { isLoggedIn } = useAuth();
  const { darkMode } = useThemeStore();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userStats = [
    { name: "Posts", key: "feed", useFormatNumber: false },
    { name: "Followers", key: "followers", useFormatNumber: false },
    { name: "Following", key: "followings", useFormatNumber: false },
    {
      name: "Donated",
      key: "donated",
      useFormatNumber: true,
      prefix: "$",
    },
    {
      name: "Raised",
      key: "raised",
      useFormatNumber: true,
      prefix: "$",
    },
  ];

  // option for three dots menu
  const options = [
    {
      name: isBlocked ? "unblock" : "block",
      onClick: isBlocked ? unBlockUser : blockUser,
      hide: userId === cuserId,
    },
    {
      name: "report",
      hide: userId === cuserId,
      onClick: () => {
        setShowReportDrawer(true, { id: user._id, itemType: "account" });
      },
    },
    {
      name: "Edit profile",
      onClick: () => {
        navigate("/edit-profile");
      },
      hide: userId !== cuserId,
    },
    {
      name: "Withdrawals",
      onClick: async () => {
        navigate(ROUTE_LIST.WITHDRAWAL_HISTORY);
      },
      hide: userId !== cuserId,
    },
    {
      name: "Donor History",
      onClick: async () => {
        navigate("/profile?tab=contribution");
      },
      hide: userId !== cuserId,
    },
    {
      name: "Saved",
      onClick: async () => {
        navigate("/saved-feed");
      },
      hide: userId !== cuserId,
    },
    {
      name: "Block list",
      onClick: async () => {
        navigate("/block-list");
      },
      hide: userId !== cuserId,
    },
  ];

  // Side Effects: get user id from localStorage and fetch particular user
  const fetchUserProfile = useCallback(async () => {
    try {
      setIsProfileLoading(true);
      setProgressBarValue(20);
      const res = await apiCall({
        url: `v3/users/${userId}`,
        params: isLoggedIn && { cuserId: cuserId },
      });
      setProgressBarValue(70);
      const userData = res.data.data;
      setUser(res.data.data);
      setIsBlocked(userData.blocked === 1 ? true : false);
      setUserStats({
        feed: userData.begCount,
        followers: userData.followers,
        followings: userData.following,
        donated: userData.totalChippedIn,
        raised: userData.totalRaised,
        isKyc: userData?.isKyc,
      });
      // setIsBlocked(res.data.data?.blocked);
      setFollowing(res.data.data?.isFollowing);
      setFollowingId(res.data.data?.followingId);
      setProfilePictureUrl(res.data.data?.profileImage);
      setProgressBarValue(100);
      setIsProfileLoading(false);
    } catch (error) {
      setIsProfileLoading(false);
      setProgressBarValue(100);
      console.log(error.message);
      if (error.response.status === 404) {
        return navigate("/404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCall, userId, setUserStats, setProgressBarValue, isLoggedIn, cuserId]);

  const uploadFileInAWS = async (file) => {
    let res;

    try {
      res = await api({
        url: APIS.USER.PROFILE_UPDATE(userId),
      });
      if (res.status === 200) {
        res = res.data.data;
        await axios({
          method: "put",
          url: res.url,
          headers: {
            "Content-Type": file.type,
          },
          data: file,
        });
      } else throw Error("link can not get");
    } catch (error) {
      console.log(error);
    }
    return res;
  };

  // set profile picture function
  const profileChangeHandler = async (event) => {
    setIsProfilePictureLoading(true);
    const imageFile = event.target.files[0];
    try {
      if (
        imageFile.type === "image/png" ||
        imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg" ||
        imageFile.type === "image/webp"
      ) {
        let res = await uploadFileInAWS(imageFile);
        let profileImage = `${process.env.REACT_APP_MEDIA_PREFIX_URL}${res.uuid}`;
        let response = await apiCall({
          url: APIS.USER.EDIT,
          method: "patch",
          data: { profileImage },
        });
        if (response.data.data.profileImage) {
          setProfilePictureUrl(response.data.data?.profileImage);
          setUserProfile(response.data.data.profileImage);
        }
        // setSnack("Profile picture updated", "success");
        setIsProfilePictureLoading(false);
      } else {
        setIsProfilePictureLoading(false);
        return setSnack(
          "profile picture file must be png,jpg,jpeg or webp required",
          "error"
        );
      }
    } catch (error) {
      setIsProfilePictureLoading(false);

      console.log(error);
      setSnack("fail to update profile picture", "error");
    }
  };

  // remove profile picture function
  async function removeProfilePicture() {
    try {
      setIsProfilePictureLoading(true);
      let response = await apiCall({
        url: APIS.USER.EDIT,
        method: "patch",
        data: { profileImage: "" },
      });
      console.log(response);
      setUserProfile(undefined);
      setProfilePictureUrl("");
      return setIsProfilePictureLoading(false);
    } catch (error) {
      setIsProfilePictureLoading(false);
      console.log(error.message);
    }
  }

  const openPhotoHandler = () => {
    setShowDetailPicture(true);
  };
  const closePhotoHandler = () => {
    setShowDetailPicture(false);
  };
  // const uploadPhotoHandler = () => {};
  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  //follow user
  async function followUser() {
    if (!isLoggedIn) {
      return setSnack("Please login to follow", "warning");
    }
    try {
      const res = await apiCall({
        url: "v3/followers",
        method: "POST",
        data: { userId: cuserId, followerId: userId },
      });
      if (res.status === 200) {
        setFollowing((prev) => !prev);
        setFollowingId(res.data.data._id);
        setUserStats((prev) => ({ ...prev, followers: prev.followers + 1 }));
        // setSnack("Followed", "success");
        resetFeedStore();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // unfollow user
  async function unfollowUser() {
    try {
      const res = await apiCall({
        url: "v3/followers",
        method: "delete",
        data: { id: followingId },
      });
      if (res.status === 200) {
        setFollowingId(res.data.data._id);
        setFollowing((prev) => !prev);
        // setSnack("Unfollowed", "success");
        setUserStats((prev) => ({ ...prev, followers: prev.followers - 1 }));
        resetFeedStore();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // unblock user
  async function unBlockUser() {
    try {
      const res = await apiCall({
        url: APIS.USER.UNBLOCK(userId),
        method: "post",
      });
      if (res.status === 200) {
        setSnack(res.data.message, "success");
        resetFeedStore();
        setIsBlocked(0);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // block user
  async function blockUser() {
    try {
      const res = await apiCall({
        url: APIS.USER.BLOCK(userId),
        method: "post",
      });
      if (res.status === 200) {
        setIsBlocked(1);
        setSnack(res.data.message, "success");
        resetFeedStore();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // share profile
  const shareProfile = () => {
    // copy(process.env.REACT_APP_FIREBASE_DEEP_LINK_URL + "/profile/" + userId);
    // setSnack("Profile link copied to your clipboard");
    setShowShareDrawer(
      true,
      userId,
      user.firstName + " " + user.lastName,
      user.profileImage
        ? user.profileImage
        : "https://www.ploomsocial.com/wp-content/uploads/2023/08/PloomSocial-Logo-w-Social.webp",
      true,
      undefined,
      undefined,
      undefined,
      user.shareLink
    );
  };

  return (
    <>
      {user !== "" && (
        <DynamicMeta
          title={`${
            String(user.firstName).slice(0, 1).toUpperCase() +
            String(user.firstName).slice(1)
          } ${
            String(user.lastName).slice(0, 1).toUpperCase() +
            String(user.lastName).slice(1)
          } (@${user.username}) | Ploom Social`}
          description={`${user.firstName} ${user.lastName}`}
          name={`${user.firstName} ${user.lastName}`}
          type="website"
          image={user?.profileImage}
        />
      )}

      <Modal open={showDetailPicture} onClose={closePhotoHandler}>
        <Box
          sx={{
            position: "fixed",
            inset: "0",
            backgroundColor: alpha(palette.black[1000], 0.85),
            backdropFilter: "blur(2px)",
            zIndex: 1301,
            height: "100vh",
            display: "grid",
            placeItems: "center",
          }}
          onClick={closePhotoHandler}
        >
          <Box
            padding={1}
            sx={{
              backgroundColor: "white.1000",
              borderRadius: { xs: "10px", md: "16px" },
              position: "relative",
            }}
          >
            <Button
              sx={{
                position: "absolute",
                zIndex: 2,
                top: "20px",
                right: "20px",
                padding: "6px",
                cursor: "pointer",
                minWidth: "unset",
                "&": {
                  p: "0",
                },
              }}
              onClick={closePhotoHandler}
            >
              <CloseIcon
                color={
                  darkMode
                    ? alpha(palette.white["1000"], 0.7)
                    : palette.black["1000"]
                }
              />
            </Button>
            <Avatar
              sx={{
                borderRadius: { xs: "10px", md: "16px" },
                width: { xs: "250px", md: "350px" },
                height: { xs: "250px", md: "350px" },
                color: "white.1000",
                backgroundColor: "primary.main",
              }}
              src={profilePictureUrl}
            />
          </Box>
        </Box>
      </Modal>

      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          placeItems: "center",
        }}
      >
        {isProfileLoading ? (
          <Box width={"100%"}>
            <ProfileSkeleton />
          </Box>
        ) : (
          <Box
            position={"relative"}
            width={"100%"}
            display={"grid"}
            gap={"5px"}
          >
            {/* profile pic and user data */}
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={{ xs: "10px", sm: "18px", md: "25px" }}
            >
              <Stack>
                <Box position="relative">
                  <Button
                    sx={{
                      "&": {
                        p: "0",
                        position: "relative",
                        borderRadius: { xs: "10px", md: "16px" },
                        overflow: "hidden",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={openPhotoHandler}
                  >
                    {isProfilePictureLoading && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          backgroundColor: alpha(palette.black[1000], 0.3),
                          backdropFilter: "blur(2px)",
                          display: "grid",
                          placeItems: "center",
                          zIndex: 1,
                          color: "white.1000",
                        }}
                      >
                        <CircularProgress
                          size={20}
                          sx={{ color: "white.1000" }}
                        />
                      </Box>
                    )}
                    <Avatar
                      sx={{
                        borderRadius: { xs: "10px", md: "16px" },
                        width: { xs: "80px", ssm: "90px", md: "105px" },
                        height: { xs: "80px", ssm: "90px", md: "102px" },
                        color: "white.1000",
                        backgroundColor: "primary.main",
                      }}
                      src={profilePictureUrl}
                    />
                  </Button>

                  {/* display change profile button on self profile page*/}
                  {isLoggedIn && cuserId === userId && (
                    <>
                      <Icon
                        aria-label="more"
                        aria-controls={open ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        sx={{
                          position: "absolute",
                          zIndex: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: { xs: "21px", ssm: "25px" },
                          height: { xs: "22px", ssm: "25px" },
                          borderRadius: "8px",
                          bottom: "5px",
                          right: "5px",
                          background: "#F6F6F6",
                          padding: "6px",
                          cursor: "pointer",
                          "& .MuiSvgIcon-root": {
                            height: "auto",
                            width: "auto",
                          },
                          "&": {
                            p: "0",
                          },
                        }}
                      >
                        <CameraIcon
                          height={"16px!important"}
                          width={"16px!important"}
                        />
                      </Icon>
                      <Menu
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        sx={{
                          width: "120px",
                          position: "absolute",
                          marginTop: "20px",
                          marginLeft: "-10px",
                          top: 0,
                          left: 0,
                          borderRadius: "10px",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            position: "absolute",
                            top: "0",
                            left: 0,
                            overflow: "visible",
                            backgroundColor: darkMode
                              ? "grey.550"
                              : "white.1000",
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              left: 12,
                              borderBottom: "8px solid",
                              borderBottomColor: darkMode
                                ? "grey.550"
                                : "white.1000",
                              borderRight: "8px solid transparent",
                              borderLeft: "8px solid transparent",
                              transform: "translateY(-100%)",
                            },

                            "& ul": {
                              padding: "10px 10px",
                              display: "flex",
                              flexDirection: "column",
                            },
                            "&.MuiPopover-paper": {
                              maxWidth: "unset",
                              borderRadius: "8px",
                              filter: `drop-shadow(0px 0px 20px  ${alpha(
                                palette.black[1000],
                                0.07
                              )})`,
                            },
                          },
                        }}
                      >
                        <MenuItem
                          component={"label"}
                          htmlFor="userImage"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                            gap: "10px",
                            paddingInline: "4px",
                            minHeight: { xs: "unset", sm: "45px" },
                          }}
                        >
                          <Icon>
                            <FileUploadIcon
                              color={
                                darkMode
                                  ? palette.white[1000]
                                  : palette.black[1000]
                              }
                            />
                          </Icon>
                          <Typography
                            sx={{
                              color: darkMode ? "white.1000" : "black.350",
                              fontFamily: "Poppins",
                              fontSize: { xs: "12px", sm: "14px" },
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            Upload From Local Device
                          </Typography>
                          <input
                            type="file"
                            name="profilePicture"
                            accept="image/png, image/jpg, image/jpeg, image/webp" // Fix the accepted file types
                            id="userImage"
                            hidden
                            onChange={(e) => {
                              profileChangeHandler(e);
                              handleClose();
                            }}
                          />
                        </MenuItem>

                        {profilePictureUrl && (
                          <>
                            <Divider
                              sx={{
                                "&.MuiDivider-root": {
                                  m: 0,
                                  borderColor: darkMode
                                    ? "grey.555"
                                    : "white.700",
                                  opacity: darkMode ? 0.2 : 1,
                                },
                              }}
                            />
                            <MenuItem
                              onClick={() => {
                                removeProfilePicture();
                                handleClose();
                              }}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left",
                                gap: "10px",
                                paddingInline: "4px",
                                minHeight: { xs: "unset", sm: "45px" },
                              }}
                            >
                              <Icon>
                                <DustbinIcon2
                                  color={
                                    darkMode
                                      ? palette.white[1000]
                                      : palette.black[1000]
                                  }
                                />
                              </Icon>
                              <Typography
                                sx={{
                                  color: darkMode ? "white.1000" : "black.350",
                                  fontFamily: "Poppins",
                                  fontSize: { xs: "12px", sm: "14px" },
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "normal",
                                }}
                              >
                                Remove Profile Photo
                              </Typography>
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </>
                  )}
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    mt: "10px",
                    width: { xs: "100%" },
                    borderRadius: "10px",
                    fontSize: "11px",
                    whiteSpace: "nowrap",
                    padding: { xs: "6px 7px", md: "11px 17px" },
                    minWidth: "unset",
                    background: palette.gradients.main,
                    textTransform: "unset",
                  }}
                  onClick={shareProfile}
                >
                  Share profile
                </Button>
              </Stack>

              <Stack
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                {/* username and First name and Last name */}
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    sx={{
                      width: "70px",
                      flexGrow: 1,
                      overflow: "hidden",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontSize: { xs: "12px", sm: "16px" },
                          fontWeight: 700,
                          color: darkMode ? "white.1000" : "black.1000",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          // textTransform: "capitalize",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {!user.orgName
                          ? user.firstName + " " + user.lastName
                          : user.orgName}
                      </Typography>
                      {userStatsInitial?.isKyc === 1 && (
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            setOpenKycDialog(true, user.verifiedAt);
                          }}
                        >
                          <VerifiedIcon
                            width={{
                              xs: "15px !important",
                              sm: "18px !important",
                            }}
                            height={{
                              xs: "15px !important",
                              sm: "18px !important",
                            }}
                            id={"profile_badge"}
                          />
                        </IconButton>
                      )}
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: { xs: "11px", md: "16px" },
                        textAlign: "left",
                        color: darkMode
                          ? alpha(palette.white["1000"], user.ein ? 0.9 : 0.6)
                          : alpha(palette.grey["1000"], user.ein ? 0.9 : 0.5),
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {user && user.ein
                        ? `EIN: ${user.ein}`
                        : `@${user.username}`}
                    </Typography>
                  </Stack>

                  <Stack
                    sx={{
                      flexDirection: "row",
                      gap: "10px",
                      paddingLeft: "15px",
                    }}
                  >
                    {userId !== cuserId && (
                      <LoadingButton
                        loading={isLoading}
                        onClick={following ? unfollowUser : followUser}
                        variant="contained"
                        sx={{
                          borderRadius: "10px",
                          fontSize: "11px",
                          whiteSpace: "nowrap",
                          padding: { xs: "6px 7px", sm: "9px 30px" },
                          minWidth: "unset",
                          background: palette.gradients.main,
                          textTransform: "unset",
                          "& .MuiLoadingButton-loadingIndicator": {
                            color: "white.1000",
                          },
                        }}
                      >
                        {following ? "Unfollow" : "Follow"}
                      </LoadingButton>
                    )}
                    {isLoggedIn && (
                      <Box sx={{ position: "relative" }}>
                        <ToggleMenu
                          sx={{
                            padding: "10px",
                            width: "37px",
                            height: "37px",
                            borderRadius: "10px !important",
                            border: "1px solid",
                            backgroundColor: darkMode
                              ? "grey.550"
                              : "transparent",
                            borderColor: darkMode ? "transparent" : "white.555",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                            minWidth: "auto",
                            "& svg": {
                              color: "black!important",
                            },
                          }}
                          options={options}
                        >
                          <MoreSquareIcon
                            color={
                              darkMode
                                ? palette.white[1000]
                                : palette.black[1000]
                            }
                          ></MoreSquareIcon>
                        </ToggleMenu>
                      </Box>
                    )}
                  </Stack>
                </Stack>

                {/* all user stats */}
                <Stack
                  mt={{ xs: "10px", sm: "15px" }}
                  display={"grid"}
                  gridTemplateColumns={"1fr 1fr 1fr"}
                  sx={{
                    rowGap: "5px",
                    columnGap: { xs: "10px", sm: "32px", md: "50px" },
                  }}
                >
                  {userStats.map(({ key, name, useFormatNumber, prefix }) => (
                    <Stack direction={"column"} key={key}>
                      <Typography
                        sx={{
                          color: darkMode ? "white.1000" : "black.1000",
                          fontSize: { xs: "12px", md: "16px" },
                          fontWeight: 700,
                          fontStyle: "normal",
                          lineHeight: "normal",
                        }}
                      >
                        {prefix && prefix}
                        {useFormatNumber
                          ? formatNumberCount(userStatsInitial?.[key]) || 0
                          : userStatsInitial?.[key]}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "11px", md: "13px" },
                          color: darkMode
                            ? alpha(palette.white["1000"], 0.6)
                            : alpha(palette.grey["1000"], 0.5),
                          fontWeight: 500,
                          fontStyle: "normal",
                          lineHeight: "normal",
                        }}
                      >
                        {name}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Box>
            {/* block/unblock and report user menu */}

            {/* <Box
              sx={{
                p: 0,
                position: "absolute",
                top: "0px",
                right: "0px",
                borderRadius: "10px",
                border: "1px solid",
                backgroundColor: darkMode ? "grey.550" : "transparent",
                borderColor: darkMode ? "transparent" : "white.555",
              }}
            >
              {isLoggedIn && (
                <Box sx={{ position: "relative" }}>
                  <ToggleMenu
                    sx={{
                      width: "37px",
                      height: "37px",
                      borderRadius: "10px !important",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      minWidth: "auto",
                      "& svg": {
                        color: "black!important",
                      },
                    }}
                    options={options}
                  >
                    <MoreSquareIcon
                      color={
                        darkMode ? palette.white[1000] : palette.black[1000]
                      }
                    ></MoreSquareIcon>
                  </ToggleMenu>
                </Box>
              )}
            </Box> */}
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProfileHeader;
