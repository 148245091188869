import { createSvgIcon } from "@mui/material";
export default function OrgIcon(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/3 User">
        <g id="3 User">
          <path
            id="Stroke 1"
            d="M17.8867 10.8967C19.2817 10.7007 20.3557 9.50473 20.3587 8.05573C20.3587 6.62773 19.3177 5.44373 17.9527 5.21973"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 3"
            d="M19.7305 14.2505C21.0815 14.4525 22.0245 14.9255 22.0245 15.9005C22.0245 16.5715 21.5805 17.0075 20.8625 17.2815"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8887 14.6641C8.67469 14.6641 5.92969 15.1511 5.92969 17.0961C5.92969 19.0401 8.65769 19.5411 11.8887 19.5411C15.1027 19.5411 17.8467 19.0591 17.8467 17.1131C17.8467 15.1671 15.1197 14.6641 11.8887 14.6641Z"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 7"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8854 11.888C13.9944 11.888 15.7044 10.179 15.7044 8.069C15.7044 5.96 13.9944 4.25 11.8854 4.25C9.77643 4.25 8.06643 5.96 8.06643 8.069C8.05843 10.171 9.75543 11.881 11.8574 11.888H11.8854Z"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 9"
            d="M5.88606 10.8967C4.49006 10.7007 3.41706 9.50473 3.41406 8.05573C3.41406 6.62773 4.45506 5.44373 5.82006 5.21973"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 11"
            d="M4.044 14.2505C2.693 14.4525 1.75 14.9255 1.75 15.9005C1.75 16.5715 2.194 17.0075 2.912 17.2815"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateChatIcon
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
