import {
  Box,
  Button,
  Icon,
  Stack,
  SwipeableDrawer,
  Typography,
  alpha,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useAllModal } from "../../hooks/store/useAllModal";
import { CloseRounded } from "@mui/icons-material";
import useApi from "../../hooks/useApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "@emotion/react";
import ListItemSkeleton from "../skeleton/ListItemSkeleton";
import FollowingListItem from "./FollowingListItem";

export const FollowingListDrawer = () => {
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [followingUsers, setFollowingUser] = useState([]);
  const { showFollowingDrawer, setShowFollowingDrawer, followingId } =
    useAllModal();
  const { apiCall } = useApi();
  const { palette } = useTheme();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  // console.log(followerId);
  function HandleClick(e) {
    e.stopPropagation();
  }

  function closeComponent() {
    setShowFollowingDrawer(false);
  }

  // get logged in user's all followers
  const loggedInUserFollower = useCallback(async () => {
    try {
      if (followingId !== undefined) {
        const res = await apiCall({
          url: `v3/users/${followingId}/following?page=${currentPage}`,
        });
        if (res.data.data.results.length === 0) {
          setHasMore(false);
        }
        if (res.data.data.results.length !== 0) {
          setFollowingUser((prev) => {
            if (currentPage === 1) {
              return res.data.data.results;
            } else {
              return [...prev, ...res.data.data.results];
            }
          });
        }
        setTotalPages(res.data.data.pagination.pages);
      }
    } catch (error) {
      console.log(error);
    }
  }, [apiCall, followingId, currentPage]);

  useEffect(() => {
    loggedInUserFollower();
  }, [loggedInUserFollower]);
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={showFollowingDrawer}
      onClose={() => setShowFollowingDrawer(false)}
      onOpen={() => setShowFollowingDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      sx={{
        zIndex: 1200,
        backgroundColor: alpha(palette.black["1000"], 0.5),
        "& .css-4w5voh-MuiPaper-root-MuiDrawer-paper": {
          backgroundColor: "transparent",
          maxWidth: "550px",
          marginInline: "auto",
        },
      }}
    >
      <Box
        sx={{
          // marginTop: { xs: "70px" },
          background: "primary.main",
          maxWidth: { md: "550px" },
          maxHeight: "550px",
          height: { xs: "100%", sm: "70%", md: "70%" },
          overflowY: "auto",
          width: "100%",
          marginInline: "auto",
          backgroundColor: "white.1000",
          display: "grid",
          gridTemplateRows: "auto 1fr",
          borderTopLeftRadius: { xs: 0, sm: 15, md: 15 },
          borderTopRightRadius: { xs: 0, sm: 15, md: 15 },
          overflow: "hidden",
        }}
        onClick={HandleClick}
      >
        <Stack
          display={"flex"}
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
          position={"sticky"}
          top={"0px"}
          backgroundColor={"white.1000"}
          padding="20px"
          zIndex={2}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
            All Followings
          </Typography>
          {/* minmize icon */}
          <Button
            sx={{
              padding: "0px",
              minWidth: "unset",
              color: "grey.300",
            }}
            onClick={closeComponent}
          >
            <Icon>
              <CloseRounded></CloseRounded>
            </Icon>
          </Button>
        </Stack>
        <Stack
          display={"flex"}
          flexDirection={"column"}
          justifyItems={"left"}
          alignItems={"flex-start"}
          width={"100%"}
          height={"100%"}
          id="scrollable_div_el"
          sx={{
            overflowY: "scroll",
            position: "relative",
            padding: {
              xs: "22px 20px 20px 20px",
              sm: "22px 20px 20px 20px",
              md: "22px 20px 120px 20px",
            },
            "& .infinite-scroll-component__outerdiv": { width: "100%" },
          }}
        >
          {/* follower list item */}
          <InfiniteScroll
            scrollableTarget="scrollable_div_el"
            style={{ width: "100%" }}
            dataLength={followingUsers.length} //This is important field to render the next data
            next={() => {
              if (currentPage <= totalPages - 1) {
                setCurrentPage((prev) => prev + 1);
              } else {
                setHasMore(false);
              }
            }}
            hasMore={hasMore}
            loader={
              <>
                <ListItemSkeleton></ListItemSkeleton>
                <ListItemSkeleton></ListItemSkeleton>
              </>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {followingUsers.length !== 0 &&
              followingUsers.map((item) => {
                return (
                  <FollowingListItem
                    key={item._id}
                    item={item}
                    closeComponent={closeComponent}
                  />
                );
              })}
          </InfiniteScroll>
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};
