import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { alpha, useTheme } from "@mui/material/styles";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "../../icons/CheckIcon";
import BinIcon from "../../icons/payouts/Bin";
import axios from "axios";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import ROUTE_LIST from "../../Routes/list";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useAuth } from "../../hooks/store/useAuth";

export default function FileUploadingDialog({
  openDialog,
  totalFiles,
  currentFileIndex,
  message,
  uploadProgress,
  createPost = async () => {},
  uploadError = [],
  title,
  awsUploading,
  setTotalFiles,
  setUploadProgress,
  setFinalVideo,
  setCurrentFileIndex,
  isLoading,
  showShare = false,
  setOpenDialog = () => {},
  type,
}) {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const [retry, setRetry] = React.useState(false);
  const [shareButton, setShareButton] = React.useState(false);
  const { apiCall } = useApi();
  const disableBtn = awsUploading || retry || isLoading;
  const noError = uploadError.length === 0;
  const [feedData, setFeedData] = React.useState({});
  const { setShowShareDrawer } = useAllModal();
  const { user } = useAuth();

  const navigate = useNavigate();
  const uploadFile = async (file, index) => {
    setRetry(true);
    setUploadProgress(0);
    setCurrentFileIndex(index);
    let fail = false;
    setTotalFiles((prevVideo) =>
      prevVideo.map((video, i) => {
        if (video.uploadError && i === index) {
          video.uploadError = fail;
        }
        return video;
      })
    );
    try {
      let res;
      const unique = Date.now() + Math.ceil(Math.random() * 1e9).toString(),
        isVideo = file.file.type.includes("video");
      res = await apiCall({
        url: isVideo
          ? "v3/get-signed-url"
          : "v3/get-signed-url/begs/" + unique + ".jpeg",
      });
      if (res.status === 200) {
        res = res.data.data;
        await axios({
          method: "put",
          url: res.url,
          headers: {
            "Content-Type": file.file.type,
          },
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
          data: file.file,
        });
        setFinalVideo((prev) => [
          ...prev,
          {
            videoLink: isVideo ? res.uuid : unique,
            thumbLink: isVideo ? res.uuid : unique,
            type: isVideo ? "video" : "image",
          },
        ]);
      }

      setCurrentFileIndex(null);
    } catch (error) {
      console.log(error);
      fail = true;
    }
    setRetry(false);

    setTotalFiles((prevVideo) =>
      prevVideo.map((video, i) => {
        if (video.uploadError && i === index) {
          video.uploadError = fail;
        }
        video.uploaded = !fail;
        return video;
      })
    );
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiPaper-root": {
            mx: "15px",
            borderRadius: "15px!important",
            boxShadow: "none",
            border: "none",
            backgroundColor: "unset",
          },
        }}
      >
        <Box
          sx={{
            padding: { xs: "20px", md: "60px" },
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          }}
        >
          {shareButton && (
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: "25px",
              }}
            >
              <Stack
                sx={{
                  boxShadow: `0 0 0 12px ${alpha(palette.primary.main, 0.3)}`,
                  borderRadius: "50%",
                }}
              >
                <CheckIcon
                  color="primary.main"
                  fontSize={{ xs: "34px", md: "54px" }}
                />
              </Stack>
            </Stack>
          )}
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: { xs: "20px", md: "24px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            {shareButton ? "You made it nice!" : "File uploading"}
          </Typography>
          <Typography
            sx={{
              mt: { xs: "10px", md: "20px" },
              color: darkMode ? "grey.900" : "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "18px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            {shareButton ? (
              <>
                <Typography
                  component={"span"}
                  sx={{
                    display: "block",
                    marginBottom: "8px",
                    color: darkMode ? "grey.900" : "black.1000",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", md: "18px" },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "-0.408px",
                  }}
                >
                  share your{" "}
                  {String(type).toLowerCase() === "story" ? "Story" : "Raise"}
                  <br />
                </Typography>
                Ask 3-5 friends to help you share. Sharing on a socialnetwork
                can raise up to 5x more!
              </>
            ) : (
              title
            )}
          </Typography>
          {!shareButton && (
            <>
              {!isLoading ? (
                <>
                  {totalFiles.length > 0 &&
                    totalFiles.map((file, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: "relative",
                          border:
                            noError && index <= currentFileIndex
                              ? "1px solid"
                              : !noError
                              ? "1px solid"
                              : "none",
                          borderColor:
                            noError && index <= currentFileIndex
                              ? darkMode
                                ? "white.1000"
                                : "primary.main"
                              : !Boolean(file.uploadError)
                              ? "primary.main"
                              : "error.main",
                          mt: { xs: "25px", nd: "50px" },
                          padding: { xs: "15px 12px", md: "16px 30px" },
                          backgroundColor: darkMode
                            ? "grey.550"
                            : alpha(palette.primary.main, 0.1),
                          borderRadius: "10px",
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ flexGrow: "1", overflow: "hidden" }}>
                          {!noError && Boolean(file.uploadError) && (
                            <Box
                              sx={{
                                position: "absolute",
                                zIndex: 2,
                                top: { xs: "0px", md: "4px" },
                                right: { xs: "4px", md: "6px" },
                                p: 0,
                              }}
                            >
                              <Tooltip
                                enterTouchDelay={0}
                                title="Something went wrong while uploading your videos/images. Please try again."
                              >
                                <IconButton sx={{ p: 0 }}>
                                  <ErrorIcon
                                    sx={{
                                      color: "error.main",
                                      fontSize: { xs: "14px", md: "18px" },
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                          <Typography
                            sx={{
                              color: darkMode ? "white.1000" : "black.1000",
                              fontFamily: "Poppins",
                              fontSize: { xs: "12px", md: "16px" },
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                              letterSpacing: "-0.408px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {file.file.name !== undefined
                              ? file.file.name
                              : "image.heic"}
                          </Typography>
                          {!Boolean(file.uploadError) && (
                            <Box
                              sx={{
                                width: "100%",
                                mt: "10px",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: darkMode
                                    ? "white.1000"
                                    : "primary.main",
                                },
                                "& .MuiLinearProgress-root": {
                                  borderRadius: "10px",
                                  backgroundColor: darkMode
                                    ? "neutral.550"
                                    : alpha(palette.primary.main, 0.25),
                                },
                              }}
                            >
                              <LinearProgress
                                variant="determinate"
                                value={
                                  index === currentFileIndex
                                    ? uploadProgress
                                    : Boolean(file.uploaded)
                                    ? 100
                                    : 0
                                }
                              />
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            flexGrow: "0",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          {Boolean(file.uploaded) &&
                            !Boolean(file.uploadError) && <CheckIcon />}
                          {!noError && Boolean(file.uploadError) && (
                            <Box sx={{ display: "flex", gap: "5px" }}>
                              <Button
                                sx={{
                                  flexShrink: 0,
                                  color: darkMode ? "white.1000" : "black.1000",
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                  minWidth: "unset",
                                }}
                                onClick={() => uploadFile(file, index)}
                                disabled={disableBtn}
                              >
                                <ReplayIcon />
                              </Button>
                              <Button
                                sx={{
                                  flexShrink: 0,
                                  color: darkMode ? "white.1000" : "black.1000",
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                  minWidth: "unset",
                                }}
                                onClick={() => {
                                  setTotalFiles((prevFiles) =>
                                    prevFiles.filter((_, i) => i !== index)
                                  );
                                }}
                                disabled={disableBtn}
                              >
                                <BinIcon
                                  sx={{ height: "16px", width: "16px" }}
                                />
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
                </>
              ) : (
                <Stack
                  sx={{
                    mt: { xs: "25px", nd: "50px" },
                    padding: { xs: "15px 12px", md: "16px 30px" },
                    backgroundColor: darkMode
                      ? "grey.550"
                      : alpha(palette.success.main, 0.1),
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                  }}
                >
                  <CircularProgress
                    size={"24px"}
                    sx={{ color: darkMode ? "white.1000" : "primary.main" }}
                  />
                  <Typography
                    sx={{
                      color: darkMode ? "white.1000" : "black.1000",
                      fontFamily: "Poppins",
                      fontSize: { xs: "12px", md: "16px" },
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "-0.408px",
                    }}
                  >
                    {message}
                  </Typography>
                </Stack>
              )}

              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "primary.main",
                  mt: 2,
                  paddingBlock: "15px",
                }}
                onClick={async () => {
                  const data = await createPost();
                  setFeedData(data);
                  if (showShare) {
                    setShareButton(true);
                  } else {
                    setOpenDialog(false);
                  }
                }}
                disabled={disableBtn}
              >
                Continue
              </Button>
            </>
          )}
          {shareButton && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                mt: "40px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setShowShareDrawer(
                    true,
                    feedData._id,
                    feedData.title,
                    undefined,
                    false,
                    feedData.description,
                    user,
                    feedData,
                    feedData.shareLink
                  );
                  navigate("/");
                  // share
                }}
                sx={{
                  backgroundColor: "none !important",
                  background: palette.gradients.main,
                  textTransform: "capitalize",
                  padding: "14px",
                  fontSize: { xs: "12px", md: "16px" },
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                Share
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  navigate(ROUTE_LIST.HOME);
                }}
                sx={{
                  color: darkMode ? "white.1000" : "primary.main",
                  textTransform: "capitalize",
                  padding: "14px",
                  fontSize: { xs: "12px", md: "16px" },
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                Continue Without Sharing
              </Button>
            </Box>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
