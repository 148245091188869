import { createSvgIcon } from "@mui/material";
import React from "react";

export default function MoreSquareIcon(props) {
  const color = props.color;
  const CreateMoreSquareIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/More Square">
        <g id="More Square">
          <path
            id="Stroke 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5 2.91699H7.83102C4.81002 2.91699 2.91602 5.05599 2.91602 8.08299V16.251C2.91602 19.278 4.80002 21.417 7.83102 21.417H16.499C19.53 21.417 21.416 19.278 21.416 16.251V8.08299C21.416 5.05599 19.53 2.91699 16.5 2.91699Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            id="Stroke 11"
            d="M16.1049 12.1797H16.1139"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            fill="none"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 13"
            d="M12.0971 12.1797H12.1061"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            fill="none"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 15"
            d="M8.0873 12.1797H8.0963"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            fill="none"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateMoreSquareIcon
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
