import React, { useEffect, useState } from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import useApi from "../hooks/useApi";
import { APIS } from "../api/lists";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import WidthdrawalHeader from "../component/withdrawal/WidthdrawalHeader";
import { Box, Button } from "@mui/material";
import WithdrawalFunds from "../component/payout/WithdrawalFunds";
import WithdrawalHistorySkeleton from "../component/skeleton/WithdrawalHistorySkeleton";
import { useSnack } from "../hooks/store/useSnack";
import WithdrawalDetail from "../component/withdrawal/WithdrawalDetail";
import { useAllModal } from "../hooks/store/useAllModal";
import { useAuth } from "../hooks/store/useAuth";
import DialogBox from "../component/Dialogbox/DialogBox";
import AddBankAccount from "../component/withdrawal/dialog/AddBankAccount";
import ROUTE_LIST from "../Routes/list";

export default function FeedWithdrawal() {
  const hasNotAccount = "has-not-account";
  const [dialog, setDialog] = useState({ type: "" });
  const accounts = "accounts";
  const charges = "charges";
  const withdrawalDetail = "withdrawal-detail";
  const { userId } = useAuth();

  const [feed, setFeed] = useState({});
  const [showStep, setShowStep] = useState(accounts);
  const { setSnack } = useSnack();
  const navigate = useNavigate();
  const { setShowIdentityDrawer, showIdentityDrawer } = useAllModal();

  // get  feed detail
  const { feedId } = useParams();
  const { apiCall, isLoading } = useApi();
  const { apiCall: checkAccountCall, isLoading: checkAccountIsLoading } =
    useApi();

  useEffect(() => {
    (async () => {
      try {
        const res = await checkAccountCall({
          url: APIS.BANK_ACCOUNT.LIST(userId),
          params: {
            limit: 1,
            sort: "-createdAt",
          },
        });
        if (res.data.success === true) {
          setDialog({
            type: !res.data.data.results.length > 0 ? hasNotAccount : "",
          });
        } else {
          throw Error("Something went wrong");
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [checkAccountCall, userId, showIdentityDrawer]);

  // redirect to 404 if user is not owner of raise
  useEffect(() => {
    (async function () {
      try {
        const res = await apiCall({ url: APIS.FEED.GET(feedId) });
        if (res.data.success === true && res.data.data.author._id === userId) {
          setFeed(res.data.data);
        } else {
          navigate("/404");
        }
      } catch (error) {
        // handleApiError(error, setSnack);
      }
    })();
  }, [apiCall, feedId, setSnack, navigate, userId]);

  // end feed detail

  // get feed withdrawal history
  const { apiCall: withdrawalCall, isLoading: withdrawalLoading } = useApi();
  const [withdrawals, setWithdrawals] = useState([]);
  const [feedDetail, setfeedDetail] = useState({
    available: 0,
    total: 0,
    withdraw: 0,
  });
  const [query, setQuery] = useSearchParams();

  // close show identity modal on page change
  useEffect(() => {
    return () => {
      setShowIdentityDrawer(false);
    };
  }, [setShowIdentityDrawer]);

  // get withdrawal list
  useEffect(() => {
    (async function () {
      try {
        let response = await withdrawalCall(APIS.FEED.WITHDRAW.LIST(feedId));
        if (response.data.success === true) {
          const data = response.data.data;
          const records = data.results;
          setWithdrawals(records);
          setfeedDetail({
            available: data.availableBalance,
            total: data.totalContribution,
            withdraw: data.withdrawal,
          });
        }
      } catch (error) {
        if (error?.response.data?.data) {
          console.log(error.response.data.data?.kyc);
          setShowIdentityDrawer(true, "deposit");
        }
      }
    })();
  }, [withdrawalCall, feedId, setShowIdentityDrawer]);

  // end feed withdrawal history
  const showChargesDetail = () => {
    query.set("back", "charges");
    setQuery(query);
  };
  const showWithdrawalDetail = () => {
    query.set("back", "withdrawalDetail");
    setQuery(query);
  };

  useEffect(() => {
    if (query.get("back") === null) {
      setShowStep(accounts);
    }
    if (query.get("back") === "withdrawalDetail") {
      setShowStep(withdrawalDetail);
    }
    if (query.get("back") === "charges") {
      setShowStep(charges);
    }
  }, [query]);

  // go back handler
  const goBackHandler = () => {
    switch (showStep) {
      case accounts:
        if (window.history.length <= 1) {
          navigate("/");
        } else {
          navigate(-1);
        }
        return;
      case charges:
        setShowStep(withdrawalDetail);
        return;
      default:
        setShowStep(accounts);
        return;
    }
  };
  return (
    <BackButtonLayout
      pageTitle={"Deposit Information"}
      isTitleBold
      backButtonHandler={goBackHandler}
    >
      {(isLoading || checkAccountIsLoading) && <WithdrawalHistorySkeleton />}
      {!isLoading && (
        <>
          <Box display={showStep === accounts ? "block" : "none"}>
            <WidthdrawalHeader
              heading="Bank Account"
              balance={feedDetail.available}
              feedId={feedId}
            />
            <Button
              variant="contained"
              sx={{
                width: "100%",
                borderRadius: "10px",
                textTransform: "capitalize",
                fontSize: "14px",
                lineHeight: 1.5,
                fontWeight: 500,
                p: { xs: "10px", md: "15px" },
                textDecoration: "none",
              }}
              onClick={showWithdrawalDetail}
            >
              continue
            </Button>
          </Box>
          <Box display={showStep === withdrawalDetail ? "block" : "none"}>
            <WithdrawalDetail
              title={feed.title}
              feedDetail={feedDetail}
              showChargesDetail={showChargesDetail}
              isLoading={withdrawalLoading}
              withdrawals={withdrawals}
              showIdentityDrawer={showIdentityDrawer}
              feedId={feedId}
              feed={feed}
            />
          </Box>

          <Box display={showStep === charges ? "block" : "none"}>
            <WithdrawalFunds amount={feedDetail.available} feedId={feedId} />
          </Box>
        </>
      )}
      <DialogBox
        setOpenDialog={() => {}}
        Content={() => (
          <AddBankAccount
            confirmCall={() => {
              setDialog({ type: "" });
              navigate(
                ROUTE_LIST.ADD_ACH +
                  "?next=" +
                  ROUTE_LIST.FEED_WITHDRAWAL.redirect +
                  feedId
              );
            }}
          />
        )}
        openDialog={dialog.type === hasNotAccount && !showIdentityDrawer}
      />
    </BackButtonLayout>
  );
}
