import { useCallback } from "react";
import { useAuth } from "./store/useAuth";
import { instance } from "../api/instance";
//actions

const useRefresh = () => {
  const { refreshToken, setAccessToken } = useAuth();
  const refresh = useCallback(async () => {
    const apiInstance = instance(true);
    const response = await apiInstance.post(
      "v2/refresh",
      { refreshToken },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    setAccessToken(response.data.data.accessToken);
    //set new access token in
    return response.data.data.accessToken;
  }, [refreshToken, setAccessToken]);

  return refresh;
};

export default useRefresh;
