import { createSvgIcon } from "@mui/material";

export default function HappyManIcon(props) {
  const CreateVideoIcon = createSvgIcon(
    <>
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 6.56659C16.4801 6.56659 15.246 5.33257 15.246 3.81259C15.246 2.29262 16.4801 1.05859 18 1.05859C19.52 1.05859 20.754 2.29262 20.754 3.81259C20.754 5.33257 19.52 6.56659 18 6.56659ZM18.001 9.35603C20.8844 9.35603 23.2235 11.6952 23.2235 14.5785C23.2235 17.4639 20.8844 19.8029 18.001 19.8029C15.1157 19.8029 12.7766 17.4639 12.7766 14.5785C12.7766 11.6952 15.1157 9.35603 18.001 9.35603ZM11.6541 34.9409V30.9642C11.6541 30.4059 11.476 29.9257 11.1158 29.5015L1.64332 18.3763C0.758921 17.3358 0.895015 15.757 1.93746 14.8767C2.98196 13.9942 4.55671 14.1263 5.43913 15.1688L11.7301 22.6143C12.1723 23.1385 12.7646 23.4126 13.4529 23.4126H22.5492C23.2355 23.4126 23.8278 23.1385 24.272 22.6143L30.5609 15.1688C31.4433 14.1263 33.0181 13.9942 34.0626 14.8767C35.105 15.757 35.2411 17.3358 34.3567 18.3763L24.8863 29.5015C24.5241 29.9257 24.348 30.4059 24.348 30.9642V34.9409H11.6541ZM3.81285 9.37467C2.29287 9.37467 1.05885 8.14064 1.05885 6.62066C1.05885 5.10069 2.29287 3.86666 3.81285 3.86666C5.33283 3.86666 6.56685 5.10069 6.56685 6.62066C6.56685 8.14064 5.33283 9.37467 3.81285 9.37467ZM32.1872 9.37467C30.6672 9.37467 29.4332 8.14064 29.4332 6.62066C29.4332 5.10069 30.6672 3.86666 32.1872 3.86666C33.7072 3.86666 34.9412 5.10069 34.9412 6.62066C34.9412 8.14064 33.7072 9.37467 32.1872 9.37467Z"
            fill="white"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateVideoIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
        ...props.sx,
      }}
      {...props}
    />
  );
}
