import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, alpha } from "@mui/material";
import ClockIcon from "../../icons/ClockIcon";
import { useSnack } from "../../hooks/store/useSnack";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";
import useApi from "../../hooks/useApi";
import { useSignup } from "../../hooks/store/useSignup";
import { socket } from "../../socket";
// import { useSocket } from "../../hooks/store/useSocket";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
const EmailVerifyForm = ({ openNextForm }) => {
  const { setSnack } = useSnack();
  const { palette, typography } = useTheme();
  const { darkMode } = useThemeStore();
  const { email, userId } = useSignup();
  const { apiCall: API, isLoading: resendLoader } = useApi();
  const { apiCall, isLoading } = useApi(true);
  const [timer, setTimer] = useState(59);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  // const { socketConnection } = useSocket();

  // this function fire on email verification done
  function emailVerified(value) {
    try {
      if (value.email_verified.email) openNextForm("Profile");
      setSnack(
        "Your email has been verified, please complete your on-boarding process."
      );
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  // effect for connect socket
  // React.useEffect(() => {
  //   socket.connect();
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  //  socket for email verification
  React.useEffect(() => {
    // const data = { userId: userId };
    socket.on("connect", () => {});
    socket.on("evntUpdateAccountCheck", emailVerified);
    // socket.emit("evntJoinUserToRoom", data);
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    return () => {
      socket.off("connect", () => {});
      socket.off("disconnect", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Start the countdown timer when the component mounts
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsResendDisabled(false); // Enable the "Resend OTP" button when the timer reaches 0
        clearInterval(interval); // Stop the timer
      }
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [timer]);

  // code for resend otp
  const ResendEmail = async () => {
    try {
      setIsResendDisabled(true);
      await API({
        url: `v3/users/${userId}/verify-email`,
        method: "post",
      });
      setTimer(59);
      // setSnack(
      //   "We've successfully resent the verification link to your email address. Please check your email.",
      //   "success"
      // );
    } catch (error) {
      if (
        error.response.data.code === 400 &&
        error.response.data.success === false
      ) {
        openNextForm("Profile");
        return setSnack(
          "Your email has been verified, please complete your on-boarding process."
        );
      }
      setIsResendDisabled(true);
      setTimer(0);
      setSnack(error.response.data.message);
    }
  };

  useEffect(() => {
    socket.emit("evntJoinUserToRoom", { userId });
    ResendEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // code for check email is verified or not
  const checkEmailVerification = async () => {
    let response = await apiCall({
      url: "v2/accounts/check-verified-2",
      method: "post",
      data: { userId: userId },
    });
    let emailVerification = response.data.data.email_verified.email;
    if (emailVerification) {
      openNextForm("Profile");
      return setSnack(
        "Your email has been verified, please complete your on-boarding process."
      );
    } else {
      return setSnack("Please verify your email", "warning");
    }
  };

  return (
    <Box
      sx={{
        minWidth: { md: "571px" },
        paddingInline: { xs: "20px", md: "30px", lg: "60px" },
        paddingTop: { xs: "20px", md: "40px", lg: "50px" },
        paddingBottom: { xs: "175px", md: "40px", lg: "50px" },
        backgroundColor: darkMode ? "bgDark.third" : "white.1000",
        borderRadius: { xs: "10px", lg: "18px" },
        marginTop: { xs: 0, lg: "50px" },
        boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
      }}
    >
      <Typography
        sx={{
          fontFamily: typography.manrope,
          color: darkMode ? "white.1000" : "black.1000",
          fontSize: { xs: "26px", lg: "36px" },
          fontWeight: { xs: 800, lg: 700 },
          lineHeight: { xs: "40px", lg: "122%" },
          letterSpacing: { xs: "-0.52px", md: "-0.72px" },
        }}
      >
        Verify Your Email Address
      </Typography>
      <Typography
        sx={{
          mt: "10px",
          fontFamily: typography.manrope,
          color: darkMode ? "neutral.450" : "black.1000",
          fontSize: { xs: "18px", lg: "16px" },
          fontWeight: 400,
          lineHeight: "152%",
        }}
      >
        Please check your email at{" "}
        <Typography
          component={"span"}
          sx={{ color: darkMode ? "white.1000" : "primary.main" }}
        >
          {email}
        </Typography>{" "}
        for a verification link. Click the link to complete the verification.
      </Typography>
      <Stack
        sx={{
          mt: "30px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <ButtonWithLoading
          isLoading={resendLoader}
          disabled={isResendDisabled}
          variant="contained"
          onClick={ResendEmail}
          sx={{
            paddingBlock: "14px",
            width: "100%",
            "&.Mui-disabled": {
              color: darkMode
                ? alpha(palette.white["1000"], 0.7)
                : "white.1000",
              backgroundColor: darkMode
                ? "primary.disabledDark"
                : "primary.disabledLight",
            },
          }}
        >
          Resend Email
        </ButtonWithLoading>
      </Stack>

      <Stack
        mt={"15px"}
        display={"flex"}
        flexDirection="row"
        alignItems="center"
        gap={"10px"}
      >
        <ClockIcon
          color={darkMode ? palette.white["1000"] : palette.primary["main"]}
          width="16px"
          height="16px"
        />
        <Typography
          sx={{
            color: darkMode ? "white.1000" : "primary.main",
            fontFamily: typography.manrope,
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "146%",
          }}
        >
          00:{timer < 10 ? `0${timer}` : timer}
        </Typography>
      </Stack>

      {/* {!socketConnection && ( */}
      <ButtonWithLoading
        isLoading={isLoading}
        variant="text"
        onClick={checkEmailVerification}
        sx={{
          paddingBlock: 0,
          width: "100%",
          color: darkMode ? "white.1000" : "primary.main",
          "&.Mui-disabled": {
            color: darkMode ? "white.1000" : "primary.main",
            backgroundColor: "none",
          },
        }}
      >
        Done verification? continue
      </ButtonWithLoading>
      {/* )} */}
    </Box>
  );
};

export default EmailVerifyForm;
