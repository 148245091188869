import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";

import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";
// import { LoadingButton } from "@mui/lab";

import CommentSendIcon from "../../icons/CommentSendIcon";
import ProfileAvatar from "../user/ProfileAvatar";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useSearchParams } from "react-router-dom";
import { useSettings } from "../../hooks/store/useSettings";

export default function CommentForm() {
  const { setSnack } = useSnack();
  const { darkMode } = useThemeStore();
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(false);
  const commentDivRef = useRef();
  const [buttonHeight, setButtonHeight] = useState(42);
  const { donorDetails } = useAllModal();
  const [query] = useSearchParams();
  const [commentAdded, setCommentAdded] = useState(false);
  const { apiCall } = useApi();
  const { palette } = useTheme();
  const { settings } = useSettings();

  useEffect(() => {
    if (commentDivRef.current) {
      setButtonHeight(commentDivRef.current.clientHeight);
    }
  }, []);
  useEffect(() => {
    function resizeHandler() {
      if (commentDivRef.current) {
        setButtonHeight(commentDivRef.current.clientHeight);
      }
    }
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  });

  const postComment = async (e) => {
    try {
      e.preventDefault();
      if (loading) {
        return;
      }
      setLoading(true);
      const res = await apiCall({
        url: "v3/comments",
        method: "post",
        data: {
          userId: donorDetails._id ? donorDetails._id : undefined,
          isGuest: donorDetails._id ? undefined : true,
          firstName: donorDetails.guestFirstName
            ? donorDetails.guestFirstName
            : undefined,
          lastName: donorDetails.guestLastName
            ? donorDetails.guestLastName
            : undefined,
          textDescription: commentText,
          htmlDescription: commentText,
          begId: query.get("begId") || query.get("feed"),
        },
      });
      if (res.status === 200) {
        setCommentAdded(true);
        return setSnack(res.data.message);
      } else throw Error("fail to post comment");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSnack("something gone wrong!", "error");
      //   console.log(error);
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: darkMode ? "grey.550" : "transparent",
        borderRadius: "15px",
        padding: { xs: "10px", sm: "15px" },
        marginTop: "15px",
        boxShadow: `0px 0px 8px 0px ${alpha(palette.black[1000], 0.1)}`,
      }}
    >
      <Box sx={{ mb: "15px" }}>
        <Box display={"flex"} alignItems={"center"} overflow={"hidden"}>
          <ProfileAvatar
            sx={{
              backgroundColor: "primary.main",
              color: "white.1000",
              width: "32px",
              height: "32px",
            }}
            src={donorDetails?.profileImage}
            alt={donorDetails?.username}
          />
          <Typography
            ml={"10px"}
            sx={{
              color: darkMode ? "white.1000" : "grey.550",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            {donorDetails.orgName
              ? donorDetails.orgName
              : donorDetails.firstName
              ? donorDetails.firstName + " " + donorDetails.lastName
              : donorDetails.guestFirstName + " " + donorDetails.guestLastName}
          </Typography>
        </Box>
      </Box>
      {commentAdded ? (
        <Typography
          sx={{
            color: darkMode ? alpha(palette.white[1000], 0.6) : "black.1000",
            fontFamily: "Poppins",
            fontSize: "13px",
            fontWeight: 400,
            lineHeight: "19px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          {commentText}
        </Typography>
      ) : (
        <Box
          component={"form"}
          onSubmit={postComment}
          sx={{
            display: "flex",
            placeItems: "center",
            backgroundColor: darkMode ? "black.250" : "primary.light10",
            border: darkMode ? "1px solid" : "none",
            borderColor: "neutral.650",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Box
            width={"100%"}
            flexGrow={1}
            ref={commentDivRef}
            sx={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              "& ::placeholder": {
                fontSize: { xs: "12px", md: "14px" },
                color: darkMode
                  ? alpha(palette.white["800"], 0.5)
                  : alpha(palette.grey["550"], 0.5),
              },
              "& input": {
                padding: { xs: "9px", md: "11px" },
                "&:WebkitAutofill,&:WebkitAutofill:hover, &:WebkitAutofill:focus, &:WebkitAutofill:active":
                  {
                    backgroundColor: darkMode ? "grey.550" : "white.1000",
                    WebkitBoxShadow: `0 0 0 30px ${
                      darkMode ? "#3d3d3d" : "#e5e5e5"
                    } inset`,
                    WebkitTextFillColor: darkMode
                      ? palette.white[1000]
                      : palette.black[1000],
                    caretColor: darkMode
                      ? palette.white[1000]
                      : palette.black[1000],
                  },
              },
            }}
          >
            <input
              type="text"
              name="comment"
              required
              minLength={settings?.mainCommentMinLength}
              maxLength={settings?.mainCommentMaxLength}
              placeholder="Reply..."
              value={commentText}
              onChange={(e) => {
                setCommentText(e.target.value);
              }}
              style={{
                width: "100%",
                height: "100%",
                fontSize: { xs: "12px", md: "14px" },
                backgroundColor: darkMode
                  ? palette.grey[550]
                  : palette.grey["1100"],
                fontFamily: "poppins",
                fontWeight: 500,
                lineHeight: "16px",
                border: "none",
                outline: "none",
                color: darkMode ? palette.white["1000"] : palette.black["1000"],
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
            />
          </Box>

          {/* comment submit button */}
          {!loading ? (
            <IconButton
              disableRipple={true}
              aria-label="fingerprint"
              type="submit"
              sx={{
                backgroundColor: darkMode
                  ? palette.grey[550]
                  : palette.grey["1100"],
                width: buttonHeight + "px",
                height: buttonHeight + "px",
                padding: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& :hover": {
                  backgroundColor: "inherit",
                },
                borderRadius: "0px !important",
                borderTopRightRadius: "5px !important",
                borderBottomRightRadius: "5px !important",
              }}
            >
              <CommentSendIcon
                sx={{ color: darkMode ? "black.1000" : "white.1000" }}
              />
            </IconButton>
          ) : (
            <Box
              sx={{
                width: buttonHeight + "px",
                height: buttonHeight + "px",
                borderRadius: "0px !important",
                borderTopRightRadius: "5px !important",
                borderBottomRightRadius: "5px !important",
                backgroundColor: !darkMode ? "white.1000" : "bgDark.third",
                display: "grid",
                placeItems: "center",
              }}
            >
              <CircularProgress
                size={16}
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
