import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { alpha, useTheme } from "@mui/material/styles";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import PloomLogo from "../../icons/PloomLogo";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// div to image generator
import * as htmlToImage from "html-to-image";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { APIS } from "../../api/lists";
import copy from "copy-to-clipboard";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useSnack } from "../../hooks/store/useSnack";
import CloseIcon from "../../icons/CloseIcon";

// swiper slider imports
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
// import "./style/swiper.custom.css";

export default function TiktokShareDialog({
  openTiktokDrawer,
  setOpenTiktokDrawer,
  begTitle,
  begImage,
  link,
  beg,
}) {
  const { palette } = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [sharedImage, setSharedImage] = React.useState(null);
  const imgPrefix = process.env.REACT_APP_API_BASE_URL + APIS.GET_IMAGE;
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const [shareId] = React.useState("sharableDiv" + Math.random() * 5000);
  const [currentOpenedSlide, setCurrentOpenedSlide] = React.useState(0);
  const [linkShareId] = React.useState(
    "LinkSharableDiv" + Math.random() * 5000
  );
  const handleClose = () => {
    setOpenTiktokDrawer(false);
  };
  const handleSharableDivLoaded = async () => {
    try {
      const sharableDiv = document.getElementById(shareId);
      const sharableLinkDiv = document.getElementById(linkShareId);

      // Generate image when the sharable div is loaded
      let imgBlob = await htmlToImage.toBlob(sharableDiv, {
        cacheBust: true,
        includeQueryParams: true,
      });
      imgBlob = await htmlToImage.toBlob(sharableDiv, {
        cacheBust: true,
        includeQueryParams: true,
      });
      imgBlob = await htmlToImage.toBlob(sharableDiv, {
        cacheBust: true,
        includeQueryParams: true,
      });

      // Generate image when the sharable div is loaded
      let imgBlob2 = await htmlToImage.toBlob(sharableLinkDiv, {
        cacheBust: true,
        includeQueryParams: true,
      });

      // Create a file from the Blob
      const imgFile = new File([imgBlob], "shared_image.png", {
        type: "image/png",
      });

      // Create a file from the Blob
      const imgFile2 = new File([imgBlob2], "shared_image.png", {
        type: "image/png",
      });

      // Store the image in the state
      setSharedImage([imgFile, imgFile2]);
      setLoading(false);
    } catch (err) {
      console.log(`Error generating image: ${err.message}`);
    }
  };

  const handleShareClick = async () => {
    try {
      setLoading(true);
      // Share the image stored in the state
      await navigator.share({
        files: [sharedImage[currentOpenedSlide]],
      });
      setLoading(false);
    } catch (err) {
      console.log(sharedImage);
      setLoading(false);
      console.log(`Error: ${err.message}`);
    }
  };

  React.useEffect(() => {
    setSharedImage(null);
  }, [begImage]);

  // current visible slide tracker
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setCurrentOpenedSlide(activeIndex);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openTiktokDrawer}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            margin: "10px",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 200,
              borderRadius: "20px",
              background: palette.black["1000"],
            }}
            onClick={() => setOpenTiktokDrawer(false)}
          >
            <CloseIcon color={alpha(palette.white["1000"], 0.7)} />
          </IconButton>
          <Box
            component={Swiper}
            onSlideChange={handleSlideChange}
            spaceBetween={10}
            slidesPerView={1}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            sx={{ backgroundColor: "black.1000" }}
          >
            <SwiperSlide>
              <Box
                id={shareId}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "black.1000",
                  padding: "20px",
                }}
                onLoad={handleSharableDivLoaded}
              >
                <Box width={"320px"}></Box>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBlock: "12px",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    backgroundColor: "black.1000",
                    width: "100%",
                  }}
                >
                  <PloomLogo
                    sx={{
                      width: { xs: "120px" },
                      height: { xs: "50px" },
                      viewBox: "0 0 104 45",
                    }}
                  />
                </Stack>
                <Box
                  component={"img"}
                  src={imgPrefix + "?img_url=" + begImage}
                  sx={{
                    mt: "20px",
                    objectFit: "cover",
                    width: "100%",
                    minHeight: "210px",
                    maxHeight: "210px",
                    borderRadius: "12px",
                  }}
                ></Box>

                <Box
                  sx={{
                    width: "100%",
                    padding: "14px",
                    backgroundColor: "black.1000",
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "white.1000",
                      textTransform: "capitalize",
                    }}
                  >
                    {begTitle}
                  </Typography>

                  {beg.goalAmount > 0 && (
                    <Box
                      sx={{
                        mt: "14px",
                        backgroundColor: alpha(palette.white["100"], 0.1),
                        backdropFilter: "blur(20px)",
                      }}
                      width="100%"
                      height={"8px"}
                      borderRadius={1}
                      overflow={"hidden"}
                    >
                      <Box
                        backgroundColor={"success.progressBar"}
                        width={
                          ((beg.amountRaised / beg.goalAmount) * 100 > 100
                            ? 100
                            : (beg.amountRaised / beg.goalAmount) * 100) + "%"
                        }
                        height={"100%"}
                        borderRadius={1}
                      />
                    </Box>
                  )}

                  <Typography
                    sx={{
                      mt: "10px",
                      fontFamily: "arial",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "white.1000",
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "white.1000",
                      }}
                    >
                      ${beg.amountRaised}
                    </Typography>{" "}
                    {beg.goalAmount > 0
                      ? `Raised of ${beg.goalAmount}`
                      : "Raised"}
                  </Typography>
                </Box>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                id={linkShareId}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "black.1000",
                  padding: "20px",
                  minHeight: "461px",
                  mAXHeight: "461px",
                }}
                onLoad={handleSharableDivLoaded}
              >
                <Box width={"320px"}></Box>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBlock: "12px",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    backgroundColor: "black.1000",
                    width: "100%",
                  }}
                >
                  <PloomLogo
                    sx={{
                      width: { xs: "120px" },
                      height: { xs: "50px" },
                      viewBox: "0 0 104 45",
                    }}
                  />
                </Stack>
                <Typography
                  sx={{
                    mt: "60px",
                    color: "white.1000",
                    fontSize: "16px",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  Show support at
                </Typography>

                <Typography
                  sx={{
                    mt: "4px",
                    display: "inline-block",
                    maxWidth: "270px",
                    color: alpha(palette.white[1000], 0.7),
                    fontSize: "14px",
                    fontWeight: 700,
                    textAlign: "center",
                    wordWrap: "break-word",
                    textDecoration: "underline",
                  }}
                >
                  {link}
                </Typography>
                <Typography
                  sx={{
                    mt: "60px",
                    color: "white.1000",
                    fontSize: "16px",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  Link in bio!
                </Typography>
              </Box>
            </SwiperSlide>
          </Box>

          <Box
            sx={{
              padding: "15px",
              backgroundColor: darkMode ? "bgDark.main" : "bgLight.main",
            }}
          >
            <Typography
              sx={{
                mt: "20px",
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Remember to add the fundraiser link to your Tiktok bio
            </Typography>

            <Box
              sx={{
                mt: "18px",
                display: "grid",
                gridTemplateColumns: "1fr auto",
                backgroundColor: darkMode ? "#4F4F4F" : "white.700",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  backgroundColor: darkMode ? "#4F4F4F" : "white.700",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Typography
                  sx={{
                    // textOverflow: "ellipsis",
                    // whiteSpace: "nowrap",
                    overflowX: "auto",
                    "::-webkit-scrollbar ": {
                      display: "none",
                    },
                    color: darkMode ? "white.1000" : "black.1000",
                  }}
                >
                  {link}
                </Typography>
              </Box>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  copy(link);
                  setSnack("Link Copied Successfully");
                }}
                sx={{
                  backgroundColor: darkMode ? "#4F4F4F" : "white.700",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>

            <ButtonWithLoading
              variant="contained"
              isLoading={loading}
              onClick={handleShareClick}
              disabled={loading}
              fullWidth
              sx={{
                mt: "20px",
                padding: "10px",
                background: palette.gradients.main,
              }}
            >
              Share to TokTok
            </ButtonWithLoading>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
