import { useTheme } from "@emotion/react";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { Box, Dialog, IconButton, alpha } from "@mui/material";
import DrawerBg from "../../icons/DrawerBg";
import CloseIcon from "../../icons/CloseIcon";
import { useNavigate } from "react-router-dom";

export default function DialoagBackground({
  open,
  setOpen,
  children,
  notAllowToClose = false,
  closeModal,
}) {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingInline: "15px",
        "& .MuiBackdrop-root": {
          backgroundColor: alpha(palette.black["1000"], 0.85),
          backdropFilter: "blur(2px)",
        },
        "& *.css-18tmwsn": {
          backgroundColor: "transparent",
          maxWidth: "550px",
          margin: "0 auto",
        },
        "& .css-4w5voh-MuiPaper-root-MuiDrawer-paper": {
          background: "transparent",
          maxWidth: "550px",
          margin: "0 auto",
        },
        "& .MuiPaper-root": {
          position: "relative",
        },
        "& .MuiDialog-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      onClose={() => {
        if (notAllowToClose) {
          return;
        }
        return setOpen(false);
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "574px",
          width: "100%",
          //   minHeight: "533px",
          marginInline: "auto",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
          overflowX: "hidden",
          padding: { xs: "30px", sm: "50px 30px", md: "110px 70px" },
        }}
      >
        <Box sx={{ position: "absolute", top: "35px", left: "-70px" }}>
          <DrawerBg
            color={darkMode ? palette.grey["550"] : palette.primary["light50"]}
            width={"220px"}
            height={"253px"}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "29px",
            right: "-70px",
            transform: "rotate(180deg)",
          }}
        >
          <DrawerBg
            color={darkMode ? palette.grey["550"] : palette.primary["light50"]}
            width={"220px"}
            height={"253px"}
          />
        </Box>
        {/* close button */}
        {/* {!notAllowToClose && ( */}
        <IconButton
          aria-label="delete"
          size="small"
          sx={{
            position: "absolute",
            top: { xs: "10px", md: "15px" },
            right: { xs: "10px", md: "15px" },
          }}
          onClick={() => {
            if (notAllowToClose) {
              return navigate("/");
            } else if (typeof closeModal === "function") {
              closeModal();
              return setOpen(false);
            } else {
              return setOpen(false);
            }
          }}
        >
          <CloseIcon
            color={
              darkMode
                ? alpha(palette.white["1000"], 0.7)
                : palette.black["1000"]
            }
          />
        </IconButton>
        {/* )} */}

        <Box
          sx={{
            maxWidth: { xs: "300px", sm: "370px", md: "500px" },
            zIndex: 1,
          }}
        >
          {children}
        </Box>
      </Box>
    </Dialog>
  );
}
