import { createSvgIcon } from "@mui/material";
import React from "react";

export default function FacebookIcon(props) {
  const CreateNextDoorIcon = createSvgIcon(
    <>
      <path
        d="M22.5 42.2754C33.4216 42.2754 42.2754 33.4216 42.2754 22.5C42.2754 11.5784 33.4216 2.72461 22.5 2.72461C11.5784 2.72461 2.72461 11.5784 2.72461 22.5C2.72461 33.4216 11.5784 42.2754 22.5 42.2754Z"
        fill="#3A37EC"
      />
      <path
        d="M27.1994 24.0706L27.8055 20.1192H24.0142V17.5549C24.0142 16.4739 24.5438 15.4201 26.2419 15.4201H27.9657V12.0561C27.9657 12.0561 26.4013 11.7891 24.9057 11.7891C21.7832 11.7891 19.7424 13.6815 19.7424 17.1075V20.1192H16.2715V24.0706H19.7424V33.6229C20.4383 33.7321 21.1516 33.7891 21.8783 33.7891C22.6049 33.7891 23.3182 33.7321 24.0142 33.6229V24.0706H27.1994Z"
        fill="white"
      />
    </>
  );
  return (
    <CreateNextDoorIcon
      width="36"
      height="36"
      viewBox="0 0 45 45"
      fill="none"
      sx={{
        "&.MuiSvgIcon-root": { width: "unset", height: "unset" },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
