import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import Skeleton from "../Comment/Skeleton";
import Form from "../Comment/Form";
import FeedCommentCard from "../Comment/FeedCommentCard";
import { useAuth } from "../../../hooks/store/useAuth";
import { APIS } from "../../../api/lists";

function CommentContainer({ id, setFeed }) {
  const [comments, setComments] = useState([]);
  const [begOwnerId, setBegOwnerId] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const { apiCall, isLoading } = useApi();
  const { isLoggedIn } = useAuth();

  const fetchComments = useCallback(async () => {
    try {
      let res = await apiCall({
        url: APIS.COMMENT.GET,
        params: { page, begId: id, sort: "-pinAt" },
      });
      res = res.data.data;
      setBegOwnerId(res?.begOwnerId);
      setTotalPage(res?.pagination?.pages);
      setComments((prevComments) => [
        ...(page === 1 ? [] : prevComments),
        ...res.results,
      ]);
      return res?.pagination?.totalCount || 0;
    } catch (error) {
      console.log("error in fetch comments");
    }
  }, [apiCall, id, page]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  return (
    <Box pb={"15px"}>
      {/* comment form */}
      {isLoggedIn && (
        <Box>
          <Form feedId={id} fetchComments={fetchComments} />
        </Box>
      )}

      {isLoading && page === 1 ? (
        <Box
          sx={{
            "&>:not([hidden])~:not([hidden])": {
              mt: 4,
            },
            marginTop: { xs: "7px", md: "19px" },
          }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <FeedCommentCard
          comments={comments}
          isLoading={isLoading}
          fetchComments={fetchComments}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          feedId={id}
          begOwnerId={begOwnerId}
        />
      )}
    </Box>
  );
}

// Prevent unnecessary re-renders of the component
const MemoizedCommentContainer = React.memo(CommentContainer);

export default MemoizedCommentContainer;
