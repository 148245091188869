import {
  Box,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

import WithdrawalButton from "../RaiseDashboard/WithdrawalButton";
import { useUserVerification } from "../../hooks/store/useUserVerification";

const BegDetails = ({ begInfo, begDetails, showAddBankText }) => {
  const { kyc } = useUserVerification();
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();

  // useEffect(() => {
  //   (async function () {})();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        {/* raise statistics */}
        <Box display={"flex"} mt={{ xs: "10px", md: "15px" }}>
          {/* {begInfo.map((data, index) => ( */}
          <Stack
            sx={{
              // width: "100%",
              position: "relative",
              textDecoration: "none",
              color: "black.1000",
            }}
          >
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                fontFamily: "Poppins",
                fontSize: { xs: "13px", md: "16px" },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                textTransform: "unset",
                whiteSpace: "nowrap",
              }}
            >
              {begInfo[0].count}
            </Typography>
            <Typography
              sx={{
                mt: "5px",
                color: darkMode ? "white.1000" : "primary.disabledLight",
                fontFamily: "Poppins",
                fontSize: { xs: "12px", md: "14px" },
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              {begInfo[0].title}
            </Typography>
          </Stack>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginInline: { xs: "30px", sm: "50px" },
              transform: { xs: "translateY(40%)", md: "translateY(50%)" },
              height: "26px",
              borderColor: darkMode
                ? "black.250"
                : alpha(palette.primary.disabledLight, 0.5),
            }}
          />
          <Stack
            sx={{
              // width: "100%",
              position: "relative",
              textDecoration: "none",
              color: "black.1000",
            }}
          >
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                fontFamily: "Poppins",
                fontSize: { xs: "13px", md: "16px" },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                textTransform: "unset",
                whiteSpace: "nowrap",
              }}
            >
              {begInfo[1].count}
            </Typography>
            <Typography
              sx={{
                mt: "5px",
                color: darkMode ? "white.1000" : "primary.disabledLight",
                fontFamily: "Poppins",
                fontSize: { xs: "12px", md: "14px" },
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              {begInfo[1].title}
            </Typography>
          </Stack>
          {/* // ))} */}
        </Box>

        {/* {!begDetails?.withdrawal.length ? ( */}
        <Box
          display={"flex"}
          gap={{ xs: "0px", sm: "10px" }}
          flexDirection={{ xs: "column-reverse", sm: "row-reverse" }}
        >
          {!Boolean(begDetails.endedDate) && (
            <Box width={{ xs: "100%", sm: "50%" }}>
              <WithdrawalButton
                endRaise
                disabled={begDetails.amountRaised < 10}
                feedId={begDetails._id}
                children={"Withdraw & End Fundraising"}
              />
            </Box>
          )}
          <Box width={{ xs: "100%", sm: "50%" }}>
            <WithdrawalButton
              feedId={begDetails._id}
              disabled={
                begDetails?.amountRaised <= 0 && !(kyc === 0 || kyc === 2)
              }
              checkKyc={true}
            >
              {kyc === 0 || kyc === 2 || showAddBankText
                ? "Setup Your Account"
                : Boolean(begDetails.endedDate)
                ? "Withdrawals"
                : "Withdraw & Continue Fundraising"}
            </WithdrawalButton>
          </Box>
        </Box>
        {/* // ) : (
        //   <Button
        //     disabled
        //     variant="contained"
        //     fullWidth
        //     sx={{
        //       mt: { xs: "15px", md: "20px" },
        //       minWidth: "unset",
        //       width: "100%",
        //       paddingBlock: "15px",
        //       background: "primary.main",
        //       textTransform: "unset",
        //       fontSize: { xs: "12px", md: "16px" },
        //       fontStyle: "normal",
        //       fontWeight: 500,
        //       lineHeight: "22px 137.5% ",
        //       letterSpacing: "-0.408px",
        //       borderRadius: "10px",
        //       "&.Mui-disabled": {
        //         color: darkMode ? "black.100" : "white.1000",
        //         backgroundColor: darkMode
        //           ? "primary.disabledDark"
        //           : "primary.disabledLight",
        //       },
        //     }}
        //   >
        //     Fund Withdrawn
        //   </Button>
        // )} */}

        <Typography
          sx={{
            mt: "20px",
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          Before initiating deposit of funds, please add your bank details.
        </Typography>

        <List
          sx={{
            m: 0,
            ml: "8px",
            listStyleType: "disc",
            listStylePosition: "inside",
            color: "primary.disabledLight",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            paddingBlock: "12px",
          }}
        >
          {showAddBankText && (
            <ListItem sx={{ display: "list-item", p: 0 }}>
              Add your bank details.
            </ListItem>
          )}
          <ListItem sx={{ display: "list-item", p: 0 }}>
            Ensure Minimum withdrawal amount is $10.
          </ListItem>
        </List>

        <Typography
          sx={{
            order: { xs: 4, lg: "unset" },
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          Note: For withdrawals or transfers, there's a minimum requirement of
          $10.00. Additionally, it may take up to five business days for your
          funds to be deposited
        </Typography>
      </Box>
    </>
  );
};

export default BegDetails;
