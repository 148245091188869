import { Box, Button, IconButton, Menu, MenuItem, alpha } from "@mui/material";
import React, { useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

export default function ToggleMenu({
  icon = false,
  children,
  options,
  sx = {},
}) {
  const ButtonComponent = icon ? IconButton : Button;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  options = options.filter((option) => option.hide !== true);
  return (
    <Box sx={{ position: "relative" }}>
      <ButtonComponent
        aria-label="more"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          p: 0.5,
          color: "white !important",
          ...sx,
        }}
      >
        {children}
      </ButtonComponent>
      <Menu
        // disableScrollLock={true}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: darkMode ? "grey.550" : "white.1000",
            "& ul": {
              paddingInline: "10px",
              display: "flex",
              flexDirection: "column",
            },
            "& ul li:not(:last-child)": {
              borderBottom: "1px solid",
              borderColor: darkMode
                ? alpha(palette.white[1000], 0.07)
                : "white.700",
              borderRadius: "0px",
            },
            "&.MuiPopover-paper": {
              maxWidth: "unset",
              borderRadius: "12px",
              boxShadow: `0px 0px 20px ${alpha(palette.black[1000], 0.07)}`,
            },
          },
        }}
        sx={{
          transform: {
            xs: "translateX(35px) translateY(14px)",
            xsm: "translateX(45px) translateY(14px)",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            sx={{
              p: "7px 10px",
              m: 0,
              minHeight: "unset",
              color: darkMode ? "white.1000" : "back.1000",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              minWidth: "92px",
            }}
            disabled={option.disabled}
            key={option.name || option}
            // selected={option === }
            onClick={async (event) => {
              handleClose();
              if (typeof option.onClick === "function")
                await option.onClick(event);
            }}
          >
            {option.name || option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
