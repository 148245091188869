import { Box } from "@mui/system";
import React from "react";
import VideoIcon from "../../icons/VideoIcon";
import ImageIcon from "../../icons/ImageIcon";

export default function MediaType({ videoType }) {
  return (
    <>
      <Box
        position={"absolute"}
        display={"flex"}
        top={{ xs: "10px", md: "15px" }}
        right={{ xs: "10px", md: "15px" }}
        zIndex={2}
        sx={{
          placeItems: "center",
        }}
      >
        {videoType === 1 ? <ImageIcon /> : <VideoIcon />}
      </Box>
    </>
  );
}
