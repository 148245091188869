import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import DynamicMeta from "../utils/DynamicMeta";
import ConfirmPasswordForm from "../component/editProfile/ConfirmPasswordForm";
import OtpVerifyForm from "../component/editProfile/OtpVerifyForm";
import { useAuth } from "../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";
import { useRedirect } from "../hooks/store/useRedirect";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { setRedirectRoute } = useRedirect();
  const [openForm, setOpenForm] = useState("OTP"); //1.OTP  2.savePassword
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    if (!isLoggedIn) {
      setRedirectRoute("/change-password");
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DynamicMeta title="Change Password | Ploom" />
      <Box>
        {openForm === "OTP" && (
          <OtpVerifyForm setOpenForm={setOpenForm}></OtpVerifyForm>
        )}

        {openForm === "savePassword" && (
          <ConfirmPasswordForm></ConfirmPasswordForm>
        )}
      </Box>
    </>
  );
};

export default ChangePassword;
