import { createSvgIcon } from "@mui/material";

export default function MoreIcon(props) {
  const CreateMoreIcon = createSvgIcon(
    <>
      <path
        d="M8.125 10C8.125 11.0337 8.96625 11.875 10 11.875C11.0338 11.875 11.875 11.0337 11.875 10C11.875 8.96625 11.0338 8.125 10 8.125C8.96625 8.125 8.125 8.96625 8.125 10ZM8.125 16.25C8.125 17.2837 8.96625 18.125 10 18.125C11.0338 18.125 11.875 17.2837 11.875 16.25C11.875 15.2163 11.0338 14.375 10 14.375C8.96625 14.375 8.125 15.2163 8.125 16.25ZM8.125 3.75C8.125 4.78375 8.96625 5.625 10 5.625C11.0338 5.625 11.875 4.78375 11.875 3.75C11.875 2.71625 11.0338 1.875 10 1.875C8.96625 1.875 8.125 2.71625 8.125 3.75Z"
        fill="currentColor"
      />
    </>
  );
  return (
    <CreateMoreIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
