import React, { useState } from "react";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { Box, Typography, alpha } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSnack } from "../../hooks/store/useSnack";
import useApi from "../../hooks/useApi";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import Input from "../ui/form/Input";
import EmailIcon from "../../icons/EmailIcon";
import DialogBox from "../Dialogbox/DialogBox";
import SuccessDialog from "./SuccessDialog";
import { useNavigate } from "react-router-dom";

const GetEmailAddress = ({ setOpenForm, setMobile }) => {
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const { apiCall } = useApi(true);
  const { palette, typography } = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  // form validation
  const schema = Yup.object({
    emailAddress: Yup.string()
      .required("Email is required")
      .email("Enter a valid email address"),
    //   .test(
    //     "is-unique-email",
    //     `This email was not found`,
    //     async function (value) {
    //       try {
    //         let result = await API({
    //           url: `v3/users/check/email/${value}`,
    //           method: "get",
    //         });
    //         if (result.data.success) return false;
    //         else return true;
    //       } catch (error) {
    //         return true;
    //       }
    //     }
    //   ),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const sendResetLink = async (values) => {
    try {
      setEmail(values.emailAddress);
      let response = await apiCall({
        url: "v2/create-reset-link",
        method: "post",
        data: {
          email: values.emailAddress,
          role: "customer",
        },
      });
      setSnack(response.data.message);
      return setOpenDialog(true);
    } catch (error) {
      setSnack(error.response.data.message, "error");
      return;
    }
  };
  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(sendResetLink)}
      sx={{
        margin: "0 auto",
        width: { xs: "100%", sm: "auto" },
        mt: { md: "40px" },
        display: { xs: "flex", md: "block" },
        flexDirection: "column",
        height: { xs: "90vh", md: "unset" },
        backgroundColor: darkMode ? "bgDark.third" : "white.1000",
        maxWidth: " 540px",
        p: { xs: "20px", md: "50px" },
        borderRadius: { xs: "10px", md: "24px" },
      }}
    >
      {/* form headings */}
      <Typography
        sx={{
          color: darkMode ? "white.1000" : "black.450",
          fontFamily: "Manrope",
          fontSize: { xs: "26px", md: "36px" },
          fontStyle: "normal",
          fontWeight: { xs: 800, md: 700 },
          lineHeight: { xs: "40px", md: "122%" },
          letterSpacing: { xs: "-0.52px", md: "-0.72px" },
        }}
      >
        Forgot Password
      </Typography>

      <Typography
        sx={{
          mt: "10px",
          color: darkMode ? "grey.900" : "neutral.550",
          fontFamily: "Manrope",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "152%",
          minWidth: { sm: "400px" },
        }}
      >
        Please enter details below to continue.
      </Typography>

      <Box sx={{ paddingTop: { lg: "10px" } }}>
        <Input
          name={"emailAddress"}
          inputLabel={"Email"}
          type="text"
          register={register}
          errors={formState.errors}
          placeholder="Enter Your Email"
          icon={
            <EmailIcon
              color={darkMode ? palette.white["1000"] : palette.black["450"]}
            />
          }
        />
      </Box>

      <ButtonWithLoading
        color="primary"
        disabled={!formState.isValid || formState.isSubmitting}
        isLoading={formState.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{
          fontFamily: typography.manrope,
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "152%",
          padding: "14px 12px",
          marginTop: { xs: "25px", lg: "30px" },
          borderRadius: "10px",
          textTransform: "capitalize",
          "&.Mui-disabled": {
            color: darkMode ? alpha(palette.white["1000"], 0.7) : "white.1000",
            backgroundColor: darkMode
              ? "primary.disabledDark"
              : "primary.disabledLight",
          },
        }}
      >
        Send Email
      </ButtonWithLoading>

      <DialogBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        Content={() => (
          <SuccessDialog
            cancelCall={() => {
              setOpenDialog(false);
              return navigate("/signin");
            }}
            message={`A password reset link is sent to ${email}.`}
          />
        )}
      ></DialogBox>
    </Box>
  );
};

export default GetEmailAddress;
