import { createSvgIcon } from "@mui/material";
import React from "react";

export default function TikTokIcon(props) {
  const CreateNextDoorIcon = createSvgIcon(
    <>
      <path
        d="M23 43C34.0457 43 43 34.0457 43 23C43 11.9543 34.0457 3 23 3C11.9543 3 3 11.9543 3 23C3 34.0457 11.9543 43 23 43Z"
        fill="#212121"
      />
      <path
        d="M22.6789 12H26.1161C26.4994 14.8666 27.9541 16.6832 31 16.9405V20.277C29.2197 20.4738 27.6942 19.7703 26.1686 18.8725C26.1686 19.0195 26.2132 23.5767 26.1371 25.7503C26.0609 27.9843 25.2627 29.9505 23.5113 31.4179C19.7985 34.5339 14.5285 32.8512 13.2708 28.0867C12.5881 25.4904 13.1815 23.1515 15.2191 21.3008C16.3666 20.2507 17.7819 19.7834 19.2996 19.5892C19.6252 19.5472 19.9534 19.5288 20.3052 19.4973V23.0254C19.8588 23.1357 19.4072 23.2328 18.9635 23.3641C18.6556 23.453 18.3553 23.5662 18.0654 23.7027C17.661 23.8846 17.3039 24.157 17.0218 24.499C16.7397 24.841 16.5401 25.2434 16.4386 25.6749C16.337 26.1064 16.3362 26.5555 16.4362 26.9874C16.5362 27.4193 16.7343 27.8224 17.0151 28.1654C17.3529 28.6323 17.8123 28.9977 18.3433 29.2217C18.8744 29.4457 19.4567 29.5198 20.0269 29.436C21.1744 29.2968 21.8938 28.5749 22.3192 27.538C22.6133 26.7174 22.741 25.8464 22.6947 24.9759C22.6789 20.6917 22.6789 16.4102 22.6711 12.126C22.6714 12.0839 22.6741 12.0418 22.6789 12Z"
        fill="white"
      />
    </>
  );
  return (
    <CreateNextDoorIcon
      width="36"
      height="36"
      viewBox="0 0 45 45"
      fill="none"
      sx={{
        "&.MuiSvgIcon-root": { width: "unset", height: "unset" },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
