import React from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import Heading from "../component/ui/Heading";
import AddAchForm from "../component/payout/AddAchForm";

export default function AddAch() {
  return (
    <BackButtonLayout pageTitle={"Add ACH"} isTitleBold>
      <Heading children={"Add ACH Withdrawal"} />
      <AddAchForm />
    </BackButtonLayout>
  );
}
