import { createSvgIcon } from "@mui/material";

export default function CloseGradientIcon(props) {
  let CreateAddIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/Close Square">
        <g id="Close Square" fill="none">
          <path
            id="Stroke 1"
            d="M8.39592 5.59637L5.60059 8.39171"
            stroke="url(#paint0_linear_433_39850)"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            id="Stroke 2"
            d="M8.39825 8.39462L5.60059 5.59637"
            stroke="url(#paint1_linear_433_39850)"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            id="Stroke 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.528 1.60419H4.47109C2.70884 1.60419 1.604 2.85194 1.604 4.61769V9.38235C1.604 11.1481 2.70359 12.3959 4.47109 12.3959H9.52742C11.2955 12.3959 12.3957 11.1481 12.3957 9.38235V4.61769C12.3957 2.85194 11.2955 1.60419 9.528 1.60419Z"
            stroke="url(#paint2_linear_433_39850)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_433_39850"
          x1="6.99825"
          y1="5.59637"
          x2="6.99825"
          y2="8.39171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_433_39850"
          x1="6.99942"
          y1="5.59637"
          x2="6.99942"
          y2="8.39462"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_433_39850"
          x1="6.99984"
          y1="1.60419"
          x2="6.99984"
          y2="12.3959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
      </defs>
    </>
  );
  return (
    <CreateAddIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "18px",
          height: "18px",
          ...props.sx,
        },
        ...props,
      }}
    />
  );
}
