import { createSvgIcon } from "@mui/material";
import React from "react";

export default function TwitterXIcon(props) {
  const CreateTwitterXIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_720_35714)">
        <path
          d="M19.5 -0.000244141C8.73082 -0.000244141 0 8.73057 0 19.4998C0 30.2689 8.73082 38.9998 19.5 38.9998C30.2692 38.9998 39 30.2689 39 19.4998C39 8.73057 30.2692 -0.000244141 19.5 -0.000244141Z"
          fill="black"
        />
        <path
          d="M21.6342 17.7355L29.9819 8.03198H28.0038L20.7554 16.4574L14.9662 8.03198H8.28906L17.0435 20.7727L8.28906 30.9484H10.2673L17.9217 22.0508L24.0356 30.9484H30.7127L21.6337 17.7355H21.6342ZM10.9801 9.5212H14.0186L28.0047 29.5269H24.9662L10.9801 9.5212Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_720_35714">
          <rect
            width="39"
            height="39"
            fill="white"
            transform="translate(0 -0.000244141)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateTwitterXIcon
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      {...props}
    />
  );
}
