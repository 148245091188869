import { createSvgIcon } from "@mui/material";
export default function ManIcon(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <g id="Iconly/Bold/Profile">
        <g id="Profile">
          <path
            id="Profile_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.941 10.9366C25.941 15.3421 22.4087 18.8747 18 18.8747C13.5928 18.8747 10.059 15.3421 10.059 10.9366C10.059 6.53104 13.5928 3 18 3C22.4087 3 25.941 6.53104 25.941 10.9366ZM18 33C11.4936 33 6 31.9425 6 27.8624C6 23.7809 11.5281 22.7609 18 22.7609C24.5079 22.7609 30 23.8184 30 27.8984C30 31.98 24.4719 33 18 33Z"
            fill={color}
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateChatIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
