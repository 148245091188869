import * as React from "react";
import Dialog from "@mui/material/Dialog";
import VerifyEmailIcon from "../../icons/VerifyEmailIcon";
import { DialogContent, Stack, Typography, alpha } from "@mui/material";
import { useAuth } from "../../hooks/store/useAuth";
import { socket } from "../../socket";
import useApi from "../../hooks/useApi";
import { useSignin } from "../../hooks/store/useSignin";
import { useSnack } from "../../hooks/store/useSnack";
import { useNavigate } from "react-router-dom";
// import { useSocket } from "../../hooks/store/useSocket";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { useTheme } from "@emotion/react";

export default function EmailVerifyDialog({
  open = false,
  setOpen,
  openNextForm,
}) {
  const { userId, email, username, password } = useSignin();
  const { login, userId: loggedInUserId } = useAuth();
  const { apiCall, isLoading } = useApi(true);
  const { apiCall: API, isLoading: apiLoading } = useApi();
  const { setSnack } = useSnack();
  const navigate = useNavigate();
  const [timer, setTimer] = React.useState(59);
  const [isResendDisabled, setIsResendDisabled] = React.useState(true);
  // const { socketConnection } = useSocket();
  const { palette } = useTheme();

  // this function fire on email verification done
  const accountUpdate = React.useCallback(
    async (value) => {
      try {
        if (value.email_verified.email) {
          const res = await apiCall({
            method: "post",
            url: "v2/login/signin",
            data: {
              username: username,
              password: password,
              role: "customer",
            },
          });
          if (res.status === 200) {
            let {
              _id: userId,
              firstName,
              lastName,
              username,
              email,
              accessToken,
              refreshToken,
              profileImage,
              mobile,
              code,
              email_verified,
              mobile_verified,
              isKyc,
              onboarding_complete,
            } = res.data.data;
            login({
              user: {
                firstName,
                lastName,
                username,
                email,
                profileImage,
                mobile,
                code,
                email_verified,
                mobile_verified,
                isKyc,
                onboarding_complete,
              },
              refreshToken,
              accessToken,
              userId,
            });
            setSnack("login successfully");
            navigate("/");
          }
          setOpen(false);
          socket.on("disconnect", () => {});
        }
      } catch (error) {
        if (
          error.response.status === 401 &&
          error.response?.data?.data?.email_verified === true &&
          error.response?.data?.data?.onboarding === false
        ) {
          setSnack(error.response.data.message, "warning");
          return openNextForm("Onboarding");
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [username, password]
  );

  // effect for connect socket
  // React.useEffect(() => {
  //   socket.connect();
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  //  socket for email verification
  React.useEffect(() => {
    socket.on("connect", () => {});
    socket.on("evntUpdateAccountCheck", accountUpdate);
    socket.on("error", (error) => {
      console.error("Socket connection error:", error);
    });
    return () => {
      socket.off("connect", () => {});
      socket.off("disconnect", () => {});
    };
  }, [accountUpdate, userId]);

  // code for resend otp
  const ResendEmail = async () => {
    try {
      setIsResendDisabled(true);
      await API({
        url: `v3/users/${
          loggedInUserId ? loggedInUserId : userId
        }/verify-email`,
        method: "post",
      });
      setTimer(59);
      setSnack(
        "We've successfully resent the verification link to your email address. Please check your email.",
        "success"
      );
    } catch (error) {
      if (
        error.response.data.code === 400 &&
        error.response.data.success === false
      ) {
        try {
          const res = await apiCall({
            method: "post",
            url: "v2/login/signin",
            data: {
              username: username,
              password: password,
              role: "customer",
            },
          });
          if (res.status === 200) {
            let {
              _id: userId,
              firstName,
              lastName,
              username,
              email,
              accessToken,
              refreshToken,
              profileImage,
              mobile,
              code,
              email_verified,
              mobile_verified,
              isKyc,
              onboarding_complete,
            } = res.data.data;
            login({
              user: {
                firstName,
                lastName,
                username,
                email,
                profileImage,
                mobile,
                code,
                email_verified,
                mobile_verified,
                isKyc,
                onboarding_complete,
              },
              refreshToken,
              accessToken,
              userId,
            });
            setOpen(false);
            setSnack("login successfully");
            socket.disconnect();
            return navigate("/");
          }
        } catch (error) {
          console.log(error);
        }
      }
      setIsResendDisabled(true);
      setTimer(0);
      setSnack(error.response.data.message);
    }
  };

  React.useEffect(() => {
    // Start the countdown timer when the component mounts
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsResendDisabled(false); // Enable the "Resend OTP" button when the timer reaches 0
        clearInterval(interval); // Stop the timer
      }
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [timer]);

  // code for check is email verified or not
  const checkEmailVerification = async () => {
    let response = await apiCall({
      url: "v2/accounts/check-verified-2",
      method: "post",
      data: { userId: loggedInUserId ? loggedInUserId : userId },
    });
    let emailVerification = response.data.data.email_verified.email;
    if (emailVerification) {
      let value = { email_verified: { email: true } };
      await accountUpdate(value);
      return;
    } else {
      return setSnack("Please verify your email", "warning");
    }
  };

  // join socket room
  React.useEffect(() => {
    socket.emit("evntJoinUserToRoom", { userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Dialog
        maxWidth={"md"}
        open={open}
        sx={{
          "& .MuiDialog-container": {
            background: alpha(palette.black[1000], 0.6),
            backdropFilter: "blur(2px)",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "15px", md: "40px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <VerifyEmailIcon
            sx={{
              width: { xs: "215px", sm: "328px", md: "444px" },
              height: { xs: "180px", sm: "230px", md: "296px" },
            }}
          />
          <Typography
            sx={{
              mt: { xs: "10px", md: "20px" },
              color: "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: { xs: "16px", md: "22px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            Verify Your Email Address
          </Typography>
          <Typography
            sx={{
              mx: "auto",
              width: { lg: "674px" },
              color: "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "18px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: { xs: "22px", md: "normal" },
              mt: { xs: "10px", md: "15px" },
            }}
          >
            We've sent a verification link to your email address:{email}. Please
            check your email and click on the verification link to confirm your
            email. Once verified, you'll be able to log in to your account.
          </Typography>
          <Stack
            sx={{
              width: "100%",
              mt: "25px",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: { xs: "12px", sm: "12px" },
            }}
          >
            <ButtonWithLoading
              isLoading={apiLoading}
              disabled={isResendDisabled}
              variant="contained"
              onClick={ResendEmail}
              sx={{
                padding: "12px 30px",
                minWidth: { xs: "100%", sm: "256.55px" },
                width: { xs: "100%", sm: "unset" },
                "&.Mui-disabled": {
                  color: "white.1000",
                  backgroundColor: "primary.disabledLight",
                },
              }}
            >
              Resend
            </ButtonWithLoading>
          </Stack>
          <Typography
            sx={{
              mt: 0.2,
              fontSize: { xs: "12px", md: "16px" },
              color: "primary.main",
            }}
          >
            00:{timer < 10 ? `0${timer}` : timer}
          </Typography>
          {/* {!socketConnection && ( */}
          <ButtonWithLoading
            isLoading={isLoading}
            variant="text"
            onClick={checkEmailVerification}
            sx={{
              mt: 1,
              padding: 0,
              width: { xs: "100%", sm: "unset" },
              color: "primary.main",
            }}
          >
            Already Done? continue
          </ButtonWithLoading>
          {/* )} */}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
