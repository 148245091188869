import React from "react";
import DialogBackground from "../../withdrawal/dialog/DialogBackground";
import { Box, Button, alpha } from "@mui/material";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import DangerInfoIcon from "../../../icons/DangerInfoIcon";

export default function ErrorDialog({
  cancelCall,
  message,
  autoClose = false,
}) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <DialogBackground
      Icon={() => (
        <Box sx={{ textAlign: "center", mb: "25px" }}>
          <DangerInfoIcon
            sx={{
              width: { xs: "74px", sm: "80px" },
              height: { xs: "74px", sm: "80px" },
            }}
          />
        </Box>
      )}
      description={
        message || "Please upload 1 video minimum for create your raise."
      }
      hideAction
    >
      <Box sx={{ mx: "auto", maxWidth: { sm: "400px" }, mb: "20px" }}>
        {!autoClose && (
          <Button
            color="primary"
            variant="contained"
            sx={{
              p: "15px",
              fontSize: { xs: "14px", md: "16px" },
              lineHeight: 1.3,
              width: "100%",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
            onClick={cancelCall}
          >
            Ok
          </Button>
        )}
      </Box>
    </DialogBackground>
  );
}
