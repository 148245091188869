import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { Typography, alpha } from "@mui/material";

export default function Note() {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <Typography
      color={darkMode ? alpha(palette.white[1000], 0.7) : "grey.950"}
      fontSize={"14px"}
      lineHeight={1.5}
      mb={{ xs: "20px", md: "30px" }}
      sx={{ textAlign: { xs: "left ", sm: "left" } }}
    >
      <strong
        style={{
          color: darkMode ? palette.white[1000] : palette.black[1000],
        }}
      >
        Note:
      </strong>{" "}
      You can modify these settings at any time when you withdraw funds.
    </Typography>
  );
}
