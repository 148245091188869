import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore.js";

const CardText = ({ title, htmlDescription }) => {
  const { typography, palette } = useTheme();
  const [showMore, setShowMore] = useState(false);
  const truncatedText = showMore
    ? htmlDescription
    : htmlDescription.slice(0, 90);
  const { darkMode } = useThemeStore();

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <Box mt={"14px"}>
      <Typography
        sx={{
          color: darkMode ? "white.1000" : "black.1000",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          "&::first-letter": {
            textTransform: "uppercase",
          },
        }}
      >
        {title}
      </Typography>

      <Typography
        sx={{
          color: darkMode ? "white.200" : "black.1000",
          textShadow: "0px 0px 11px rgba(0, 0, 0, 0.15)",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        }}
      >
        {truncatedText}{" "}
        {htmlDescription.length > 90 && (
          <button
            onClick={() => {
              toggleShowMore();
            }}
            style={{
              padding: 0,
              margin: 0,
              textTransform: "unset",
              fontSize: "12px",
              fontWeight: 700,
              fontFamily: typography.inter,
              color: darkMode ? palette.white["1000"] : palette.black["1000"],
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              outline: "none",
            }}
          >
            {showMore ? "Load less" : "Load more"}
          </button>
        )}
      </Typography>
    </Box>
  );
};

export default CardText;
