import { createSvgIcon } from "@mui/material";
import React from "react";

export default function DangerInfoIcon(props) {
  const CreateDangerInfoIcon = createSvgIcon(
    <>
      <circle cx="40" cy="40" r="40" fill="#DC1C4B" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.6004 40.0004C61.6004 45.7291 59.3247 51.2231 55.2739 55.2739C51.2231 59.3247 45.7291 61.6004 40.0004 61.6004C34.2717 61.6004 28.7777 59.3247 24.7269 55.2739C20.6761 51.2231 18.4004 45.7291 18.4004 40.0004C18.4004 34.2717 20.6761 28.7777 24.7269 24.7269C28.7777 20.6761 34.2717 18.4004 40.0004 18.4004C45.7291 18.4004 51.2231 20.6761 55.2739 24.7269C59.3247 28.7777 61.6004 34.2717 61.6004 40.0004ZM42.7004 50.8004C42.7004 51.5165 42.4159 52.2032 41.9096 52.7096C41.4032 53.2159 40.7165 53.5004 40.0004 53.5004C39.2843 53.5004 38.5975 53.2159 38.0912 52.7096C37.5849 52.2032 37.3004 51.5165 37.3004 50.8004C37.3004 50.0843 37.5849 49.3975 38.0912 48.8912C38.5975 48.3849 39.2843 48.1004 40.0004 48.1004C40.7165 48.1004 41.4032 48.3849 41.9096 48.8912C42.4159 49.3975 42.7004 50.0843 42.7004 50.8004ZM40.0004 26.5004C39.2843 26.5004 38.5975 26.7849 38.0912 27.2912C37.5849 27.7976 37.3004 28.4843 37.3004 29.2004V40.0004C37.3004 40.7165 37.5849 41.4032 38.0912 41.9096C38.5975 42.4159 39.2843 42.7004 40.0004 42.7004C40.7165 42.7004 41.4032 42.4159 41.9096 41.9096C42.4159 41.4032 42.7004 40.7165 42.7004 40.0004V29.2004C42.7004 28.4843 42.4159 27.7976 41.9096 27.2912C41.4032 26.7849 40.7165 26.5004 40.0004 26.5004Z"
        fill="#FF2929"
      />
    </>
  );
  return (
    <CreateDangerInfoIcon
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
