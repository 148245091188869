import {
  Box,
  Button,
  Collapse,
  InputAdornment,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import HappyManIcon from "../../icons/HappyManIcon";
import { useTheme } from "@emotion/react";
import Arrow from "../../icons/Arrow";
import { useSettings } from "../../hooks/store/useSettings";

const TipSelector = ({
  errors,
  register,
  name,
  tippingRange,
  setTippingRange,
  amount,
  setTipping,
  tipping,
  onChange,
}) => {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const [openCustomAmountForm, setOpenCustomAmountForm] = useState(false);
  const [maxTipError, setMaxTipError] = useState(false);
  const { settings } = useSettings();

  // Calculate the maximum tip based on the current amount
  useEffect(() => {
    const maxTip = Number(amount);
    // Update the tip error message
    tipping <= maxTip && Math.sign(tipping) !== -1
      ? setMaxTipError(false)
      : setMaxTipError(
          Math.sign(tipping) === -1
            ? `Invalid contribution`
            : `We accept a maximum of ${
                settings.maxTippingPercentage
                  ? settings.maxTippingPercentage
                  : 100
              }% of your donation as the contribution ($${maxTip.toFixed(2)})`
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, tipping, settings.maxTippingPercentage]);

  // amount slider tooltip filter
  function valueLabelFormat(value) {
    if (openCustomAmountForm === false) {
      setTippingRange(value);
      let tippingAmount = Number(
        (tippingRange * amount) / settings.maxTippingPercentage
      ).toFixed(2);
      setTipping(Math.round(tippingAmount));
      return `${
        tippingAmount > 0 ? "$" + Math.round(tippingAmount) : ""
      } (${value}%)`;
    } else {
      return;
    }
  }

  // amount decrement by 1
  function decrementAmount() {
    setTipping((prev) => Number(prev) - 1);
  }

  // amount increment by 1
  function incrementAmount() {
    setTipping((prev) => Number(prev) + 1);
  }
  return (
    <Box
      sx={{
        mt: {
          xs: openCustomAmountForm ? "25px" : "70px",
          sm: openCustomAmountForm ? "25px" : "100px",
        },
      }}
    >
      {/* tipping range slider */}
      <Collapse in={!Boolean(openCustomAmountForm)}>
        <Slider
          sx={{
            color: "success.progressBar",
            "& .MuiSlider-track": {
              height: "1px",
            },
            "& .MuiSlider-rail": {
              height: "1px",
              opacity: 1,
              backgroundColor: "primary.disabledLight",
            },
            "&.Mui-disabled .MuiSlider-markActive": {
              backgroundColor: "primary.disabledLight",
            },
            "& .MuiSlider-mark": {
              width: { xs: "4px", sm: "5px" },
              height: { xs: "4px", sm: "5px" },
              borderRadius: "50%",
              backgroundColor: "primary.disabledLight",
            },
            "& .MuiSlider-mark:nth-of-type(5n + 3)": {
              width: "1.5px",
              height: "9px",
              borderRadius: "50%",
            },

            "& .MuiSlider-markActive": {
              backgroundColor: "success.progressBar",
            },
            "& .MuiSlider-valueLabel": {
              paddingBlock: { xs: "12px", sm: "14px" },
              backgroundColor: darkMode ? "black.250" : "white.1000",
              fontSize: { xs: 12, md: 14 },
              color: darkMode ? "white.1000" : "black.1000",
              borderRadius: "12px",
              filter: "drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.08))",
              top: "-20px",
              transform: `translateX(${
                tippingRange > 25 ? "-35%" : tippingRange < 5 ? "35%" : "0%"
              }) translateY(-100%)`,
              "&:before": {
                display: { xs: "none", sm: "inline" },
                bottom: "3px",
                left:
                  tippingRange > 25
                    ? "85%"
                    : tippingRange < 5 && tippingRange > 0
                    ? "15%"
                    : tippingRange === 0
                    ? "25%"
                    : "50%",
                width: { xs: "12px", sm: "16px" },
                height: { xs: "12px", sm: "16px" },
              },
            },
          }}
          defaultValue={settings.defaultTippingRange || 16}
          step={1}
          marks
          min={0}
          max={settings.tippingRange ? settings.tippingRange : 30}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          disabled={Number(amount) === 0}
        ></Slider>
        <Button
          variant="text"
          disabled={Number(amount) === 0}
          sx={{
            p: 0,
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textDecorationLine: "underline",
            "&.Mui-disabled": {
              pointerEvents: "not-allowed",
            },
          }}
          onClick={() => {
            openCustomAmountForm === false && setTipping(0);
            setOpenCustomAmountForm((prev) => !prev);
          }}
        >
          Enter Custom Contribution
        </Button>
      </Collapse>

      {/* custom tipping form */}
      <Collapse in={Boolean(openCustomAmountForm)}>
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "inter",
              fontSize: { xs: "16px", sm: "18px" },
              fontStyle: "normal",
              fontWeight: { xs: 600, sm: 700 },
              lineHeight: "normal",
            }}
          >
            Contribution
          </Typography>

          <TextField
            {...register(name)}
            name={name}
            value={tipping > 0 ? tipping : ""}
            error={Boolean(errors[name])}
            onChange={onChange}
            type="number"
            pattern="/[^0-9]/g"
            onKeyDown={(e) => {
              const numberRegex = /^\d+$/;
              const value = e.target.value;

              switch (e.key) {
                case "Backspace":
                case "Delete":
                case "ArrowLeft":
                case "ArrowRight":
                  break;
                case ".":
                  e.preventDefault();
                  break;
                default:
                  if (!numberRegex.test(e.key)) {
                    return e.preventDefault();
                  }
                  if (value.includes(".") && value.split(".")[1]?.length >= 2) {
                    return e.preventDefault();
                  }
                  break;
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
            }}
            InputProps={{
              onWheel: (e) => e.target.blur(),
              startAdornment: (
                <InputAdornment position="start" sx={{ zIndex: 2 }}>
                  <Typography
                    sx={{
                      color: darkMode ? "white.1000" : "black.1000",
                      fontFamily: "Poppins",
                      fontSize: { xs: "15px", sm: "20px" },
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ zIndex: 2 }}>
                  <Stack sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      disabled={tipping >= amount}
                      variant="outlined"
                      onClick={incrementAmount}
                      sx={{
                        "&.MuiButton-root": {
                          minWidth: "unset",
                          border: "none",
                          lineHeight: "unset",
                          p: 0,
                        },
                        "&.Mui-disabled": {
                          pointerEvents: "initial",
                          cursor: "no-drop",
                        },
                      }}
                    >
                      <Arrow
                        color={
                          darkMode
                            ? tipping > amount || amount <= 0
                              ? palette.primary.disabledLight
                              : palette.white[1000]
                            : tipping >= amount
                            ? palette.primary.disabledLight
                            : palette.black[1000]
                        }
                        sx={{ rotate: "180deg" }}
                      />
                    </Button>
                    <Button
                      disabled={tipping === 0}
                      variant="outlined"
                      onClick={decrementAmount}
                      sx={{
                        "&.MuiButton-root": {
                          minWidth: "unset",
                          border: "none",
                          lineHeight: "unset",
                          p: 0,
                        },
                        "&.Mui-disabled": {
                          pointerEvents: "initial",
                          cursor: "no-drop",
                        },
                      }}
                    >
                      <Arrow
                        color={
                          darkMode
                            ? tipping > 0
                              ? palette.white[1000]
                              : palette.primary.disabledLight
                            : tipping > 0
                            ? palette.black[1000]
                            : palette.primary.disabledLight
                        }
                      />
                    </Button>
                  </Stack>
                </InputAdornment>
              ),
            }}
            sx={{
              mt: "15px",
              input: {
                padding: "12px 2px",
                color: darkMode ? "white.1000" : "black.1000",
                fontFamily: "Poppins",
                fontSize: { xs: "15px", sm: "20px" },
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              },
              "input:placeholder-shown": { border: "none" },
              "input:focus": { border: "none" },
              "input:hover": { border: "none" },
              "& input,input:is(:hover,:focus)": {
                border: "none",
                zIndex: 2,
                color: darkMode ? "white.1000" : "black.1000",
                opacity: 1,
              },
              "& fieldset": {
                borderRadius: "10px",
                border: "1px solid",
                borderColor: darkMode ? "neutral.650" : "neutral.150",
              },

              "& :-webkit-autofill": {
                BackgroundClip: "text",
                textFillColor: darkMode ? "#ffffff" : "black.1000",
                transition: "background-color 5000s ease-in-out 0s",
                boxShadow: ` inset 0 0 20px 20px ${
                  darkMode ? "neutral.650" : "white.1000"
                }`,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: darkMode ? "white.1000" : "black.1000",
                },
              },
              "& .MuiInputAdornment-root": {
                m: "2px",
              },
            }}
          />
          {maxTipError && (
            <Stack
              component={"span"}
              sx={{
                mt: "5px",
                color: "error.main",
                fontSize: { xs: "12px", sm: "14px" },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ErrorOutlineRoundedIcon sx={{ fontSize: "16px", mr: "10px" }} />
              <Box component={"span"}>{maxTipError}</Box>
            </Stack>
          )}
          <Button
            variant="text"
            sx={{
              mt: "10px",
              p: 0,
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              textDecorationLine: "underline",
            }}
            onClick={() => {
              openCustomAmountForm === false && setTipping(0);
              setOpenCustomAmountForm((prev) => !prev);
            }}
          >
            Back to default
          </Button>
        </Stack>
      </Collapse>

      {/* Collapse open when tipping amount is 0 and custom form is opened */}
      <Collapse
        in={tippingRange === 0 || (openCustomAmountForm && tipping <= 0)}
      >
        <Stack
          sx={{
            mt: "25px",
            padding: "15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "15px",
            border: "1px solid",
            borderColor: darkMode ? "neutral.650" : "neutral.150",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              minWidth: { xs: "40px", sm: "64px" },
              minHeight: { xs: "40px", sm: "64px" },
              backgroundColor: "primary.main",
              display: "grid",
              placeItems: "center",
              borderRadius: "5px",
            }}
          >
            <HappyManIcon
              sx={{
                width: { xs: "24px", sm: "40px" },
                height: { xs: "24px", sm: "40px" },
              }}
            />
          </Box>
          <Typography
            sx={{
              width: "515px",
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            Adding a PloomSocial Contribution means being a key part of
            improving the services for donors like you and the campaigns you
            support.{" "}
            <Typography
              component={"span"}
              onClick={() => {
                openCustomAmountForm === false && setTipping(0);
                setOpenCustomAmountForm((prev) => !prev);
              }}
              sx={{
                width: "515px",
                color: darkMode ? "white.1000" : "black.1000",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {openCustomAmountForm
                ? "Back to default"
                : "Enter Custom Contribution"}
            </Typography>
          </Typography>
        </Stack>
      </Collapse>
    </Box>
  );
};

export default TipSelector;
