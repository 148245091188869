import { ListItem, ListItemAvatar, Stack } from "@mui/material";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import useThemeStore from "../../hooks/store/useThemeStore";

const ListItemSkeleton = () => {
  const { darkMode } = useThemeStore();
  return (
    <ListItem
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        placeItems: "center",
        gap: "30px",
        // backgroundColor: "grey.100",
      }}
      alignItems="flex-start"
    >
      <ListItemAvatar
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <Skeleton
          variant="circular"
          width={40}
          height={40}
          sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        />
        <Stack direction={"column"}>
          <Skeleton
            fontSize={{ xs: "12px", md: "14px" }}
            fontWeight={"700"}
            width="100px"
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          ></Skeleton>
          <Skeleton
            fontSize={{ xs: "10px", md: "14px" }}
            fontWeight={"500"}
            color={"black.300"}
            variant="text"
            width="70%"
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          ></Skeleton>
        </Stack>
      </ListItemAvatar>
      <Stack direction={"row"} width={"80%"}>
        <Skeleton
          variant="rounded"
          height={"20px"}
          fontSize={{ xs: "10px", md: "14px" }}
          color={"black.200"}
          width={"100%"}
          sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        ></Skeleton>
      </Stack>
      <Skeleton
        variant="text"
        sx={{
          color: "#FC4135",
          fontSize: { xs: "16px", md: "22px" },
          fontWeight: "500",
          width: "50px",
          height: "45px",
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
        }}
      ></Skeleton>
    </ListItem>
  );
};

export default ListItemSkeleton;
