import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import { Box, Stack, Typography, alpha } from "@mui/material";
import RearangeIcon from "../icons/RearangeIcon";
import useThemeStore from "../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import useApi from "../hooks/useApi";
import { APIS } from "../api/lists";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/store/useAuth";
import { useSnack } from "../hooks/store/useSnack";
import DialogBox from "../component/Dialogbox/DialogBox";
import ContentFlagDialog from "../component/editRaise/ContentFlagDialog";
import Skeleton from "@mui/material/Skeleton";
import ButtonWithLoading from "../component/ui/button/ButtonWithLoading";
import MediaInfoLayer from "../component/mediaInfoDialog/MediaInfoLayer";

function RearrageMedia() {
  const [tasks, setTasks] = useState();
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const params = useParams();
  const { userId } = useAuth();
  const { apiCall, isLoading } = useApi();
  const { setSnack } = useSnack();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  let [preSequence, setPreSequence] = useState();
  let [disabled, setDisabled] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  // effect function for prevent unauthorised access
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get beg details
  const getBegData = useCallback(async () => {
    let result = await apiCall({
      url: APIS.FEED.BEG_BY_ID(params.id, userId),
      method: "GET",
    });
    setPreSequence(result.data.data.videos);
    setTasks(result.data.data.videos);

    // ownership checker
    if (userId !== result.data.data.userId) {
      navigate("/404");
    }
  }, [userId, params.id, apiCall, navigate]);
  useEffect(() => {
    getBegData();
  }, [getBegData]);

  // re-arrange function
  async function reArrange() {
    try {
      let data = tasks.map((task, index) => ({ index, videoId: task._id }));
      let result = await apiCall({
        url: APIS.FEED.CHANGE_ORDER(params.id),
        method: "POST",
        data: { ordered_assets: data },
      });
      setPreSequence(tasks);
      setDisabled(true);
      setSnack(result.data.message);
    } catch (error) {
      setSnack(error.response.data.message, "error");
    }
  }

  // on position change handler
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTasks(items);
    setDisabled(JSON.stringify(preSequence) === JSON.stringify(items));
  };

  return (
    <>
      <BackButtonLayout
        pageTitle={"Rearrange"}
        sx={{ minWidth: { lg: "1012px" } }}
      >
        {tasks ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="tasks">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {tasks.map((task, index) => (
                    <Draggable
                      key={task._id}
                      draggableId={task._id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Stack
                            sx={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-startS",
                              padding: {
                                xs: "5px 21px 5px",
                                md: "20px 50px 20px",
                              },
                              gap: { xs: "30px", md: "80px" },
                              borderRadius: "10px",
                              backgroundColor: darkMode
                                ? "grey.550"
                                : "white.1000",
                              marginBottom: "20px",
                              boxShadow: `0px 0px 10px 0px ${alpha(
                                palette.black[1000],
                                0.1
                              )}`,
                              overflow: "hidden",
                            }}
                          >
                            {/* re arrange icon */}
                            <RearangeIcon
                              color={
                                darkMode
                                  ? "white.1000"
                                  : "primary.disabledLight"
                              }
                            ></RearangeIcon>

                            {/* image with flag wrapper */}
                            <Box
                              sx={{
                                position: "relative",
                                overflow: "hidden",
                                borderRadius: "5px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: { xs: "123px", lg: "310px" },
                                  height: { xs: "65px", lg: "120px" },
                                  background: `url("${task.thumbLink}")`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  border: "none",
                                }}
                              ></Box>

                              {/* flag content wrapper
                              {task.twoHatflagged && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: alpha(
                                      palette.black["1000"],
                                      0.7
                                    ),
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      setOpenDialog(true);
                                    }}
                                  >
                                    <InfoRoundedIcon
                                      sx={{
                                        color: "red",
                                        fontSize: { xs: "16px", md: "20px" },
                                      }}
                                    />
                                  </IconButton>
                                  <Typography
                                    sx={{
                                      maxWidth: "220px",
                                      color: "white.1000",
                                      textAlign: "center",
                                      fontFamily: "Inter",
                                      fontSize: { xs: "6px", md: "10px" },
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      lineHeight: "normal",
                                    }}
                                  >
                                    This video is not visible to community Tap
                                    here to know the reason
                                  </Typography>
                                </Box>
                              )} */}

                              <MediaInfoLayer
                                twoHate={task.twoHatflagged}
                                processingStatus={task.processingStatus}
                                adminFlag={task.isDelete}
                                mediaPoint={"xs"}
                                modifyHeight={false}
                              ></MediaInfoLayer>
                            </Box>

                            {/* index */}
                            <Typography
                              sx={{
                                position: "absolute",
                                top: { xs: "14px", md: "0px", lg: "56px" },
                                right: { xs: "16px", md: "20px", lg: "40px" },
                                color: darkMode ? "white.1000" : "primary.main",
                                textAlign: "right",
                                leadingTrim: "both",
                                textEdge: "cap",
                                fontFamily: "Poppins",
                                fontSize: {
                                  xs: "64px",
                                  md: "110px",
                                  ld: "160px",
                                },
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "142% /* 227.2px */",
                                letterSpacing: "-0.408px",
                                opacity: 0.1,
                              }}
                            >
                              {index < 9 ? `0${index + 1}` : index + 1}
                            </Typography>
                          </Stack>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <ButtonWithLoading
              disabled={disabled ? true : false}
              sx={{
                order: { xs: 5, lg: "unset" },
                mt: { xs: "5px", md: "10px" },
                minWidth: "unset",
                width: "100%",
                paddingBlock: "18px",
                fontFamily: "Inter",
                fontSize: { xs: "12px", sm: "14px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                maxWidth: "543px",
                mx: "auto",
                py: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&.Mui-disabled": {
                  color: darkMode ? "black.100" : "white.1000",
                  backgroundColor: darkMode
                    ? "primary.disabledDark"
                    : "primary.disabledLight",
                },
              }}
              onClick={reArrange}
              isLoading={isLoading}
              variant="contained"
            >
              <span>Update</span>
            </ButtonWithLoading>
          </DragDropContext>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {[1, 2, 3].map((index) => (
              <Skeleton
                key={index}
                sx={{
                  height: { xs: 75, md: 105, lg: 160 },
                  "&.MuiSkeleton-root": {
                    transform: "scale(1)!important",
                  },
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
                }}
              />
            ))}
            <Skeleton
              sx={{
                mx: "auto",
                maxWidth: 543,
                height: 47,
                "&.MuiSkeleton-root": {
                  transform: "scale(1)!important",
                },
                width: "100%",
                borderRadius: "10px",
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
            />
          </Box>
        )}
      </BackButtonLayout>

      <DialogBox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        Content={({ handleClose }) => (
          <ContentFlagDialog handleClose={() => setOpenDialog(false)} />
        )}
      ></DialogBox>
    </>
  );
}

export default RearrageMedia;
