import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

export default function ToggleMenu({
  icon = false,
  children,
  options,
  sx = {},
}) {
  const ButtonComponent = icon ? IconButton : Button;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  options = options.filter((option) => option.hide !== true);
  return (
    <Box sx={{ position: "relative" }}>
      <ButtonComponent
        aria-label="more"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          p: 0.5,
          color: "white !important",
          ...sx,
        }}
      >
        {children}
      </ButtonComponent>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        sx={{
          width: "120px",
          position: "absolute",
          marginTop: "20px",
          marginLeft: "36px",
          top: 0,
          left: 0,
          borderRadius: "10px",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            position: "absolute",
            top: "0",
            left: 0,
            overflow: "visible",
            backgroundColor: darkMode ? "grey.550" : "white.1000",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: "12px",
              borderBottom: "8px solid",
              borderBottomColor: darkMode ? "grey.550" : "white.1000",
              borderRight: "8px solid transparent",
              borderLeft: "8px solid transparent",
              transform: "translateY(-100%)",
            },

            "& ul": {
              padding: "10px 10px",
              display: "flex",
              flexDirection: "column",
            },
            "&.MuiPopover-paper": {
              maxWidth: "unset",
              borderRadius: "12px",
              boxShadow: `0px 0px 20px ${alpha(palette.black[1000], 0.07)}`,
            },
          },
        }}
      >
        {options.map((option, index) => (
          <Stack key={option.name || option}>
            <MenuItem
              sx={{
                p: "7px 10px",
                m: 0,
                minHeight: "unset",
                color: darkMode ? "white.1000" : "back.1000",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                minWidth: "92px",
              }}
              // selected={option === }
              onClick={async (event) => {
                handleClose();
                if (typeof option.onClick === "function")
                  await option.onClick(event);
              }}
            >
              {option?.name || option}
            </MenuItem>
            {index !== options.length - 1 && (
              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    m: "0px !important",
                    borderColor: darkMode ? "grey.555" : "white.700",
                    opacity: darkMode ? 0.2 : 1,
                  },
                }}
              />
            )}
          </Stack>
        ))}
      </Menu>
    </Box>
  );
}
