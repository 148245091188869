import React, { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import Layout from "../component/Layout";
// import { useUserVerification } from "../hooks/store/useUserVerification";
import { useSnack } from "../hooks/store/useSnack";
import { useAuth } from "../hooks/store/useAuth";
import { CreateStory } from "./CreateStory";
import { CreateRaise } from "./CreateRaise";
import useApi from "../hooks/useApi";
import { APIS } from "../api/lists";
import { useExistDraft } from "../hooks/store/useExistDraft";
import DraftWarningDialog from "../component/commonDrawer/DraftWarningDialog";
import DraftToCampaign from "./DraftToCampaign";
import ROUTE_LIST from "../Routes/list";
import { Box } from "@mui/material";
import useThemeStore from "../hooks/store/useThemeStore";

export const CreateCampaign = () => {
  const navigate = useNavigate();
  const { setSnack } = useSnack();
  const { isLoggedIn } = useAuth();
  const { type: openForm } = useParams(); // 1.ChooseType => {select post type} 2.post 3.raise
  const [showDraftsModal, setShowDraftsModal] = useState(false);
  const { setDraft, draftExist, checked } = useExistDraft();
  const { apiCall, isLoading } = useApi();
  const data = useLoaderData();
  const { darkMode } = useThemeStore();

  if (!isLoggedIn) {
    setSnack("Please login to create raise.", "warning");
    navigate("/signin");
  }

  useEffect(() => {
    (async function () {
      try {
        if (checked && !draftExist && data.draftToCampaign !== openForm) {
          return;
        }
        if (draftExist) {
          if (data.draftToCampaign === openForm) {
            return;
          }

          setShowDraftsModal(true);
          return;
        }
        const isDraftExist = await apiCall({
          url: APIS.DRAFT.LIST,
          params: { limit: 1 },
        });

        if (isDraftExist.data.data.results.length > 0) {
          setDraft(isDraftExist.data.data.results[0]);
          if (data.draftToCampaign === openForm) {
            return;
          }
          setShowDraftsModal(true);
          return;
        }
      } catch (error) {
        if (openForm === data.draftToCampaign) {
          navigate(ROUTE_LIST.NOT_FOUND);
        }
      }
    })();
  }, [
    apiCall,
    setDraft,
    navigate,
    checked,
    data.draftToCampaign,
    openForm,
    draftExist,
  ]);

  return (
    <Layout isLoading={isLoading}>
      <Box
        sx={{
          position: "relative",
          maxWidth: {
            xs: "calc(100% - 30px)",
            sm: "460px",
            md: "520px",
            lg: "987px",
          },
          minHeight: { xs: "110vh", sm: "unset" },
          padding: { xs: "15px", md: "unset" },
          marginTop: { xs: "30px" },
          backgroundColor: {
            xs: darkMode ? "bgDark.third" : "white.1000",
            md: "unset",
          },
          width: "100%",
          marginInline: "auto",
          zIndex: 50,
          borderRadius: { xs: "10px", lg: "unset" },
        }}
      >
        {openForm === "story" && <CreateStory />}
        {openForm === "raise" && <CreateRaise />}
        {openForm === "draft-to-campaign" && !isLoading && draftExist && (
          <DraftToCampaign />
        )}

        <DraftWarningDialog
          open={showDraftsModal}
          setOpen={setShowDraftsModal}
        ></DraftWarningDialog>
      </Box>
    </Layout>
  );
};
