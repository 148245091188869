import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import Skeleton from "./Skeleton";
// icons
import CommentItem from "./CommentItem";
import CommentModalForm from "./CommentModalForm";
import { useAuth } from "../../../hooks/store/useAuth";
import { APIS } from "../../../api/lists";

export default function CommentModal({ id, setCommentCount, setOpenDrawer }) {
  const [comments, setComments] = useState([]);
  const [begOwnerId, setBegOwnerId] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const { apiCall, isLoading } = useApi();
  const { isLoggedIn } = useAuth();
  const fetchComments = useCallback(
    async (reset) => {
      try {
        let res = await apiCall({
          url: APIS.COMMENT.GET,
          params: { page: reset ? 1 : page, begId: id, sort: "-pinAt" },
        });
        reset && setPage(1);
        res = res.data.data;
        setBegOwnerId(res?.begOwnerId);
        setTotalPage(res?.pagination?.pages);
        setComments((prevComments) => [
          ...(page === 1 ? [] : prevComments),
          ...res.results,
        ]);
        return res?.pagination?.records;
      } catch (error) {}
    },
    [apiCall, id, page]
  );

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);
  return (
    <Box
      position={"relative"}
      sx={{
        marginInline: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: isLoggedIn ? "space-between" : "flex-start",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      {isLoading && page === 1 ? (
        <Box
          sx={{
            width: "100%",
            "&>:not([hidden])~:not([hidden])": {
              mt: "16px",
            },
          }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <CommentItem
          feedId={id}
          comments={comments}
          isLoading={isLoading}
          fetchComments={fetchComments}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          begOwnerId={begOwnerId}
        />
      )}
      {isLoggedIn && (
        <CommentModalForm
          feedId={id}
          fetchComments={fetchComments}
          setCommentCount={setCommentCount}
        />
      )}
    </Box>
  );
}
