import { Avatar, Box, ListItemAvatar, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useAuth } from "../../hooks/store/useAuth";
import { Link } from "react-router-dom";

export default function UserListAvatar({
  profileImage = "",
  username = "",
  timestamp,
  userId = "",
  sx = {},
}) {
  const { userId: loggedInUserId } = useAuth();
  const profileLink =
    loggedInUserId === userId ? "/profile" : "/profile/" + userId;
  const { darkMode } = useThemeStore();
  return (
    <ListItemAvatar
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: { xs: "8px", sm: "15px" },
        width: { xs: "140px", sm: "165px" },
        flexShrink: "0",
        p: "0",
        mt: 0,
        ...sx,
      }}
    >
      <Avatar
        alt="Remy Sharp"
        src={profileImage}
        sx={{
          backgroundColor: "primary.main",
          flexShrink: "0",
          width: { xs: "30px", sm: "40px" },
          height: { xs: "30px", sm: "40px" },
        }}
        component={userId ? Link : "div"}
        to={profileLink}
      />
      <Box
        sx={{
          flexGrow: "1",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Typography
          fontSize={{ xs: "12px", md: "14px" }}
          fontWeight={"700"}
          sx={{
            display: "block",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            color: darkMode ? "white.1000" : "black.1000",
            textDecoration: "none",
          }}
          component={userId ? Link : Typography}
          to={profileLink}
        >
          {username || "anonymous"}
        </Typography>
        <Typography
          fontSize={{ xs: "10px", md: "11px" }}
          fontWeight={"500"}
          color={"black.350"}
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            lineHeight: 1.6,
            color: darkMode ? "white.1000" : "black.1000",
          }}
        >
          {moment(timestamp).format("MMMM DD, yyyy")}
        </Typography>
      </Box>
    </ListItemAvatar>
  );
}
