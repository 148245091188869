import React, { useState, memo, useCallback, useRef, useEffect } from "react";
import {
  Card as MaterialCard,
  useTheme,
  Box,
  CircularProgress,
  alpha,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "./style/swiper.custom.css";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import wrapper from "../../assets/wrapper.webp";

import CardHeader from "./CardHeader";
import CardFooter from "./CardFooter";
import PlayIcon from "./PlayIcon";
import MediaType from "./MediaType";
import CardActionContainer from "./CardActionContainer";
import { deviceInfo } from "../../utils/DeviceInfo";
import { useAuth } from "../../hooks/store/useAuth";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useSettings } from "../../hooks/store/useSettings";

const Card = memo(
  ({
    id,
    author = {},
    title = "",
    videos = [],
    likeCount = 0,
    commentCount = 0,
    shareCount = 0,
    donorCount = 0,
    htmlDescription = "",
    daysRemaining,
    goalAmount,
    amountRaised,
    isLiked,
    onClick = () => {},
    begType,
    status,
    endedDate,
    saved,
    setVideoUrl = () => {},
    videoUrl = "",
    setIsMute = () => {},
    isMute = "",
    shareLink,
  }) => {
    const { palette } = useTheme();
    const { darkMode } = useThemeStore();
    const navigate = useNavigate();
    const videoRef = useRef([]);
    const [activeVideo, setActiveVideo] = useState(videos[0]);

    const [loading, setLoading] = useState(false);
    const { isLoggedIn } = useAuth();
    const { settings } = useSettings();

    document.documentElement.style.setProperty(
      "--swiper-theme-color",
      palette.neutral[1000]
    );

    const handleSlideChange = useCallback(
      (swiper) => {
        const activeIndex = swiper.activeIndex;
        if (settings.webAutoPlayFeed) {
          setActiveVideo(videos[activeIndex]);
          let videoLink =
            videos[activeIndex].type === 0 ? videos[activeIndex].videoLink : "";

          setVideoUrl(videoLink);
        }

        sessionStorage.setItem("activeVideoId", videos[activeIndex]._id);
      },
      [videos, setVideoUrl, settings.webAutoPlayFeed]
    );
    const playVideoHandler = (event) => {
      if (!settings.webAutoPlayFeed) {
        return;
      }
      setVideoUrl(
        activeVideo.type === 0 && event.type === "mouseenter"
          ? activeVideo.videoLink
          : ""
      );
      // activeVideo.type === 0 && event.type === "mouseenter"
      //   ? setLoading(true)
      //   : setLoading(false);
    };
    const toggleMute = () => {
      setIsMute((prevMute) => !prevMute);
    };

    const wiatingHandler = () => {
      setLoading(true);
    };
    const canPlayHandler = () => {
      setLoading(false);
    };
    // add autoplay for mobile
    const device = new deviceInfo();
    let isMobile = device.isMobile();

    useEffect(() => {
      if (!settings.webAutoPlayFeed) {
        return;
      }
      if (!isMobile) {
        return;
      }
      try {
        let intersectionCallback = (entries) => {
          entries.forEach((entry) => {
            let element = entry.target;
            let elVideoUrl = entry.target.dataset.video_url;
            if (entry.isIntersecting) {
              setVideoUrl(element.dataset.type === "video" ? elVideoUrl : "");
              // element.dataset.type === "video"
              //   ? setLoading(true)
              //   : setLoading(false);
            }
          });
        };
        const observer = new IntersectionObserver(intersectionCallback, {
          threshold: 1,
        });
        videoRef.current.forEach((element) => {
          observer.observe(element);
        });
      } catch (error) {
        console.log(error);
      }
      return () => {
        setVideoUrl("");
      };
    }, [videoUrl, setVideoUrl, isMobile, settings.webAutoPlayFeed]);

    return (
      <MaterialCard
        onClick={onClick}
        sx={{
          mx: "auto",
          maxWidth: 435,
          marginTop: "20px",
          borderRadius: 0,
          backgroundColor: "transparent",
          position: "relative",
          boxShadow: "none",
        }}
      >
        <Box
          component={Swiper}
          spaceBetween={10}
          slidesPerView={1}
          onSlideChange={handleSlideChange}
          navigation
          pagination={{ clickable: true }}
          modules={[Pagination]}
          sx={{
            height: { xs: "500px", sm: "620px" },
            position: "relative",
            borderRadius: "20px",
            "& .swiper-pagination": {
              marginBottom:
                begType !== 1
                  ? { xs: "40px", md: "50px" }
                  : { xs: "50px", md: "65px" },
            },
          }}
        >
          <MediaType
            author={author}
            username={author.username}
            profileImage={author.profileImage}
            id={id}
            title={title}
            description={htmlDescription}
            videoType={activeVideo.type}
            activeVideo={activeVideo}
            saved={saved}
            isMute={isMute}
            muteOnClickHandler={toggleMute}
            toggleVideoPlay={playVideoHandler}
          />
          {videos.map((video, index) => (
            <SwiperSlide
              style={{ display: "grid", placeItems: "center" }}
              key={video._id}
              onMouseEnter={playVideoHandler}
              onMouseLeave={playVideoHandler}
            >
              {loading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "0%",
                    left: "0%",
                    width: "100%",
                    height: "100%",
                    background: darkMode
                      ? alpha(palette.black[1000], 0.3)
                      : alpha(palette.black[1000], 0.2),
                    display: "grid",
                    placeItems: "center",
                    zIndex: 100,
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: "white.1000",
                    }}
                  />
                </Box>
              )}
              <PlayIcon
                onClick={() => {
                  navigate("/feed/" + id);
                }}
                videoType={videoUrl === video.videoLink ? 1 : video.type}
              />

              <Box
                component={Link}
                to={"/feed/" + id}
                sx={{ width: "100%", "& span": { width: "100%" } }}
                draggable={false}
              >
                <Box
                  component={
                    videoUrl === video.videoLink
                      ? "video"
                      : "img" || LazyLoadImage
                  }
                  poster={video.thumbLink}
                  src={
                    videoUrl === video.videoLink
                      ? video.videoLink
                      : video.thumbLink
                  }
                  data-type={video.type === 0 ? "video" : "image"}
                  data-video_url={video.videoLink}
                  loop
                  effect="blur"
                  alt="Paella dish"
                  threshold={200}
                  sx={{
                    display: "block",
                    width: "100%",
                    height: { xs: "500px", sm: "620px" },
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  muted={isMute}
                  autoPlay
                  playsInline
                  loading="lazy"
                  onWaiting={wiatingHandler}
                  onCanPlay={canPlayHandler}
                  ref={(element) => {
                    videoRef.current[index] = element;
                  }}
                />
              </Box>
            </SwiperSlide>
          ))}

          <CardFooter
            endedDate={endedDate}
            amountRaised={amountRaised}
            goalAmount={goalAmount}
            daysRemaining={daysRemaining}
            donorCount={donorCount}
            begType={begType}
          />
        </Box>

        <Box
          sx={{
            position: {
              xs: "absolute",
            },
            width: {
              xs: isLoggedIn ? "calc(100% - 96px)" : "calc(100% - 64px)",
              sm: isLoggedIn ? "calc(100% - 116px)" : "calc(100% - 94px)",
            },
            top: "15px",
            left: "15px",
            zIndex: 100,
          }}
        >
          <CardHeader
            author={author}
            username={author.username}
            profileImage={author.profileImage}
            id={id}
            title={title}
            description={htmlDescription}
            videoType={activeVideo.type}
            activeVideo={activeVideo}
          />
        </Box>

        <CardActionContainer
          title={title}
          videos={videos}
          commentCount={commentCount}
          daysRemaining={daysRemaining}
          htmlDescription={htmlDescription}
          likeCount={likeCount}
          shareCount={shareCount}
          id={id}
          isLiked={isLiked}
          activeVideo={activeVideo}
          authorId={author._id}
          author={author}
          begType={begType}
          status={status}
          endedDate={endedDate}
          goalAmount={goalAmount}
          amountRaised={amountRaised}
          shareLink={shareLink}
        />
      </MaterialCard>
    );
  }
);

export default Card;
