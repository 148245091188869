import * as React from "react";
import { alpha } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useThemeStore from "../../hooks/store/useThemeStore";

function StoryFilter() {
  // const [isVisible, setIsVisible] = React.useState(true);
  const { palette } = useTheme();
  const location = useLocation();
  const { darkMode } = useThemeStore();
  const type = location.pathname;
  const navigationLink = [
    {
      name: "All",
      href: "/",
      active: type === "/",
    },
    {
      name: "Raise",
      href: "/raise",
      active: type === "/raise",
    },
    {
      name: "Story",
      href: "/story",
      active: type === "/story",
    },
  ];

  // code for scroll down to to hide the navigation and scroll top to show
  //   React.useEffect(() => {
  //     let prevScrollPos = window.pageYOffset;

  //     const handleScroll = () => {
  //       const currentScrollPos = window.pageYOffset;
  //       const isScrollingDown = prevScrollPos < currentScrollPos;

  //       setIsVisible(!isScrollingDown);
  //       prevScrollPos = currentScrollPos;
  //     };

  //     window.addEventListener("scroll", handleScroll);
  //     return () => window.removeEventListener("scroll", handleScroll);
  //   }, []);

  return (
    <AppBar
      position="relative"
      sx={{
        maxWidth: "100%",
        // top: isVisible ? "0px" : "-10%",
        // top: "0px",
        backgroundColor: darkMode ? "grey.550" : "bgWhite.main",
        zIndex: "50",
        display: { sm: "block", md: "inline" },
        transition: "all .5s ease-in-out",
        // opacity: isVisible ? 1 : 0,
        borderTopLeftRadius: { sm: "10px" },
        borderTopRightRadius: { sm: "10px" },
      }}
    >
      <Box
        // maxWidth="xl"
        sx={{
          padding: "6px 0px 6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          backgroundColor: darkMode ? "grey.550" : "bgWhite.main",
          borderTopLeftRadius: { sm: "10px" },
          borderTopRightRadius: { sm: "10px" },
          borderBottom: darkMode ? "none" : "1px solid",
          borderColor: "white.900",
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          {navigationLink.map((link) => (
            <Link
              to={link.href}
              key={link.href}
              sx={{
                position: "relative",
                textDecoration: "none",
                fontSize: link.active ? "14px" : "12px",
                color: link.active
                  ? darkMode
                    ? "white.1000"
                    : "primary.main"
                  : darkMode
                  ? "grey.650"
                  : "black.300",
                opacity: link.active ? 1 : 0.5,
                fontWeight: link.active ? 700 : 500,
                paddingInline: "20px",
                paddingBlock: "10px",
                cursor: "pointer",
                ":hover": {
                  color: darkMode ? "white.1000" : "black.1000",
                },
                // borderColor: darkMode
                //   ? alpha(palette.grey["250"], 0.1)
                //   : alpha(palette.white["950"], 0.1),
                "&::after": {
                  content: "''",
                  width: "1px",
                  height: "14px",
                  position: "absolute",
                  top: "50%",
                  right: 0,
                  transform: "translateY(-50%)",
                  backgroundColor: darkMode
                    ? alpha(palette.grey["250"], 0.1)
                    : alpha(palette.white["950"], 0.1),
                },
                "&:last-child::after": {
                  content: '""',
                  display: "none",
                },
              }}
              component={RouterLink}
            >
              {link.name}
            </Link>
          ))}
        </Box>
        <Box display={{ sm: "inline", md: "none" }}></Box>
      </Box>
    </AppBar>
  );
}
export default StoryFilter;
