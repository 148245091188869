import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useThemeStore from "../../hooks/store/useThemeStore";

const UserProfileItem = ({ item, closeSearchbar }) => {
  const { darkMode } = useThemeStore();
  return (
    <Stack
      component={Link}
      to={`/profile/${item._id}`}
      key={item._id}
      spacing={"12px"}
      direction="row"
      alignItems="center"
      p={"6px"}
      borderRadius={"6px"}
      sx={{
        cursor: "pointer",
        transition: "all .3s ease-in",
        textDecoration: "none",
        color: "black.1000",
        ":hover": {
          background: "#2938f8",
          color: "#ffffff",
        },
      }}
    >
      <Avatar
        sx={{
          width: { xs: "35px", ms: "40px" },
          height: { xs: "35px", ms: "40px" },
          backgroundColor: "primary.main",
          color: "white.1000",
        }}
        src={item.profileImage}
      >
        {/* {item.firstName[0]} */}
      </Avatar>
      <Typography sx={{ color: darkMode ? "white.1000" : "black,1999" }} noWrap>
        {item.username}
      </Typography>
    </Stack>
  );
};

export default UserProfileItem;
