import * as React from "react";
import { Skeleton as MaterialSkeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function Skeleton() {
  const { darkMode } = useThemeStore();
  return (
    <Stack
      spacing={1}
      marginX={"auto"}
      marginBottom={3}
      maxWidth={"450px"}
      width={"100%"}
    >
      <Stack display={"grid"} gridTemplateColumns={"auto 1fr"} gap={"12px"}>
        <MaterialSkeleton
          variant="circular"
          width={40}
          height={40}
          sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        />
        <MaterialSkeleton
          variant="rounded"
          width={"100%"}
          height={40}
          sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        />
      </Stack>
      <MaterialSkeleton
        variant="rounded"
        width={"100%"}
        height={400}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      />
      <MaterialSkeleton
        variant="rounded"
        width={"100%"}
        height={30}
        sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
      />
    </Stack>
  );
}
