/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { Stack, Typography, alpha } from "@mui/material";
import DragAndDropInput from "../component/ui/form/DragAndDropInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../component/ui/form/Input";
import useApi from "../hooks/useApi";
import axios from "axios";
import ButtonWithLoading from "../component/ui/button/ButtonWithLoading";
import { useAuth } from "../hooks/store/useAuth";
import { useSnack } from "../hooks/store/useSnack";
import DynamicMeta from "../utils/DynamicMeta";
import useThemeStore from "../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { useAllModal } from "../hooks/store/useAllModal";
import { useUserVerification } from "../hooks/store/useUserVerification";
import FileUploadingDialog from "../component/ui/FileUploadingDialog";
import DialogBox from "../component/Dialogbox/DialogBox";
import ContactUsDialog from "../component/indetityVerification/ContactUsDialog";
import VideoRequired from "../component/feed/dialog/ErrorDialog";
import { useDraft } from "../hooks/store/useDraft";

export const CreateStory = () => {
  const { darkMode } = useThemeStore();
  const { userId } = useAuth();
  const { palette } = useTheme();
  const [videoPopup, setVideoPopup] = useState(false);
  const { setDraftObject } = useDraft();

  const schema = Yup.object({
    title: Yup.string()
      .required("Title is required.")
      .trim()
      .min(3)
      .max(40, "Title must be at most 40 characters"),
    postDescription: Yup.string()
      .max(500, "Description must be at most 500 characters")
      .trim()
      .optional(),
    file: Yup.array()
      .required("Images/Videos file is required")
      .max(10, "Sorry, you can only upload up to 10 files at a time")
      .test(
        "videos-size-validation",
        "Video is too big, please upload a video less than 100mb.",
        function (value) {
          let videoSizeError = false;
          Array.from(value).forEach((element, index) => {
            if (String(element.file.type).slice(0, 5) === "video") {
              if (element.file.size / 1048576 > 100) {
                videoSizeError = true;
                console.log(element);
                console.log(index);
              }
            }
          });
          if (videoSizeError) {
            return false;
          } else {
            return true;
          }
        }
      ),
    // .test(
    //   "videos-validation",
    //   "One Video is mandatory for create a story",
    //   function (value) {
    //     let video = false;
    //     Array.from(value).forEach((element) => {
    //       if (String(element.file.type).slice(0, 5) === "video") {
    //         video = true;
    //       }
    //     });
    //     if (video) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // )
  });
  const { apiCall } = useApi();
  const { apiCall: createPostApi, isLoading } = useApi();
  const { apiCall: API } = useApi(true);
  const { setSnack } = useSnack();
  const [uploadinProgress, setUploadinProgress] = useState(false);
  const [openContactUsDialog, setOpenContactUsDialog] = useState(false);
  const [title, setTitle] = useState("");
  const { setShowCommonDrawer } = useAllModal();
  const { setUserVerification } = useUserVerification();
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [totalFiles, setTotalFiles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState([]); //store index of current uploading file when error in comes
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [storyData, setStoryData] = useState();
  const [awsUploading, setAwsUploading] = useState(true);
  const [finalVideo, setFinalVideo] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [postDescription, setPostDescription] = useState("");

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (uploadinProgress) {
        event.preventDefault();
        event.returnValue =
          "Are you sure you want to leave? Your upload is in progress.";
      }
    };

    const handlePopState = () => {
      if (
        uploadinProgress &&
        !window.confirm(
          "Your upload is in progress. Are you sure you want to leave?"
        )
      ) {
        window.history.back();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [uploadinProgress]);

  // code for upload files in aws
  const uploadFileInAWS = async (files) => {
    setUploadinProgress(true);
    setTotalFiles(files);
    setOpenDialog(true);
    setUploadError([]);
    const videoArray = [...uploadedVideos];
    try {
      for (let i = videoArray.length; i < files.length; i++) {
        try {
          setCurrentFileIndex(i);
          setUploadProgress(0);
          let res;
          const unique = Date.now() + Math.ceil(Math.random() * 1e9).toString(),
            isVideo = files[i].file.type.includes("video");
          res = await apiCall({
            url: isVideo
              ? "v3/get-signed-url"
              : "v3/get-signed-url/begs/" + unique + ".jpeg",
          });
          if (res.status === 200) {
            res = res.data.data;
            await axios({
              method: "put",
              url: res.url,
              headers: {
                "Content-Type": files[i].file.type,
              },
              onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
              data: files[i].file,
            });
            videoArray.push({
              videoLink: isVideo ? res.uuid : unique,
              thumbLink: isVideo ? res.uuid : unique,
              type: isVideo ? "video" : "image",
            });
            setUploadedVideos(videoArray);
          } else {
            throw Error("link can not get");
          }
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (index === i) {
                video.uploaded = true;
              }
              return video;
            })
          );
        } catch (error) {
          setUploadError((prevError) => [...prevError, i]);
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (!video.uploadError) {
                video.uploadError = index === i;
              }
              return video;
            })
          );
          console.log("error");
          continue;
        }
      }
      setAwsUploading(false);
    } catch (error) {
      setAwsUploading(false);
      throw error;
    }
    setFinalVideo(videoArray);
    return videoArray;
  };

  // code for create post after file uploaded in aws
  const createPost = async (values) => {
    let video = false;
    if (Array.isArray(values.file)) {
      Array.from(values.file).forEach((element) => {
        if (String(element.file.type).slice(0, 5) === "video") {
          video = true;
        }
      });
    }
    if (!video) {
      setVideoPopup(true);
      return;
    }
    values.postDescription = postDescription;
    setDraftObject(values);
    try {
      // 1. check id verification
      let response = await API({
        url: "v2/accounts/check-verified-2",
        method: "post",
        data: { userId },
      });
      if (
        response.data.data.isKyc.isKyc === 0 ||
        response.data.data.isKyc.isKyc === 3
      ) {
        let mobileVerification = response.data.data.mobile_verified.mobile;
        let emailVerification = response.data.data.email_verified.email;
        let kycVerification = response.data.data.isKyc.isKyc;

        setUserVerification(
          emailVerification,
          mobileVerification,
          kycVerification
        );
        return setShowCommonDrawer(true, createPostApiCall);
      }

      if (response.data.data.isKyc.isKyc === 2) {
        return setOpenContactUsDialog(true);
      }

      await uploadFileInAWS(values.file);
      setFormValues(values);
    } catch (error) {
      setStoryData(values);
      console.log(error);
      setSnack(
        "Something went wrong while uploading your videos/images. Please try again.",
        "error"
      );
      setUploadinProgress(false);
      // if (error.status === 201) {
      // }
    }
  };

  // setNextMovement(createPost);

  const { register, handleSubmit, formState, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const createPostApiCall = async () => {
    try {
      setAwsUploading(false);
      if (!finalVideo.length) {
        return;
      }
      setAwsUploading(false);
      const data = {
        userId,
        title: formValues.title,
        textDescription: formValues.postDescription,
        htmlDescription: formValues.postDescription,
        links: finalVideo,
        begType: 2,
      };
      const res = await createPostApi({
        url: "v3/begs/create",
        data,
        method: "post",
      });
      if (res.status === 201) {
        setUploadinProgress(false);
        setSnack(res.data.message, "success");
        return res.data.data;
      }
    } catch (error) {
      setStoryData(formValues);
      console.log(error);
      setSnack(
        "Something went wrong while uploading your videos/images. Please try again.",
        "error"
      );
      setUploadinProgress(false);
    }
  };

  const postDescriptionFormValue = watch("postDescription");
  const postTitleFormValue = watch("title");
  useEffect(() => {
    if (postDescriptionFormValue) {
      setPostDescription(postDescriptionFormValue);
    }
  }, [postDescriptionFormValue]);

  useEffect(() => {
    if (postTitleFormValue) {
      setTitle(postTitleFormValue);
    }
  }, [postTitleFormValue]);

  return (
    <>
      <DynamicMeta
        title="Create Post | Ploom Social"
        description="Creative
Are you a creative powerhouse on social media platforms? Ploom Social offers you the opportunity to lead your followers on an enriching journey that begins and ends with you as the content creator. No more tedious partnerships or complicated strategies to make money."
      />

      {/* page title with back button */}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <Typography
          sx={{
            color: darkMode ? alpha(palette.white["1000"], 0.7) : "black.200",
            fontFamily: "Poppins",
            fontSize: { xs: "20px", md: "24px" },
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "142%" /* 34.08px */,
            letterSpacing: "-0.408px",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Poppins",
              fontSize: { xs: "20px", md: "24px" },
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "142%" /* 34.08px */,
              letterSpacing: "-0.408px",
            }}
          >
            Create a story
          </Typography>
        </Typography>
      </Stack>

      {/* form */}
      <Stack
        onSubmit={handleSubmit(createPost)}
        component={"form"}
        sx={{
          mt: { xs: "15px", md: "30px" },
          display: "grid",
          gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" },
          padding: { md: "30px" },
          gap: "19.81px",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        {/* drag and drop input container */}
        <Stack>
          <DragAndDropInput
            onChange={setValue.bind(null, "file")}
            register={register}
            errors={formState.errors}
            disabled={uploadinProgress}
          />
        </Stack>

        {/* all input boxes */}
        <Stack>
          <Input
            name={"title"}
            inputLabel={"Add Title"}
            register={register}
            errors={formState.errors}
            placeholder="Example title"
            disabled={uploadinProgress}
            countDown={true}
            maxLength={40}
            targetValue={title}
            onChange={(event) => {
              setTitle(String(event.target.value).slice(0, 40) || "");
            }}
            onBlur={(event) => {
              setTitle(event.target.value?.trim() || "");
            }}
            value={title}
            maxlength={40}
          />
          <Input
            name={"postDescription"}
            inputLabel={"Add description"}
            placeholder="Add details about your campaign, your goal, and why you started this campaign."
            multiline
            fullWidth
            rows={4}
            sx={{
              mt: "10px",
              "& fieldset": {
                border: "1px solid",
                borderColor: darkMode ? "neutral.650" : "white.400",
                borderRadius: "10px",
              },
              "& textarea::placeholder": {
                color: darkMode ? "white.1000" : "black.1000",
              },
              "& textarea,textarea:is(:hover,:focus)": {
                border: "none",
                zIndex: 2,
                color: darkMode ? "white.1000" : "black.1000",
                opacity: 1,
                mb: "20px",
              },
            }}
            register={register}
            errors={formState.errors}
            onChange={(event) => {
              setPostDescription(
                String(event.target.value).slice(0, 500) || ""
              );
            }}
            onBlur={(event) => {
              setPostDescription(event.target.value?.trim() || "");
            }}
            value={postDescription}
            disabled={uploadinProgress}
            countDown={true}
            maxLength={500}
            targetValue={postDescription}
            countDownSx={{ position: "absolute", bottom: "20px", right: "9px" }}
          />
          {/* <Typography
            sx={{
              marginTop: "10px",
              color: darkMode ? "grey.900" : "black.350",
              opacity: darkMode ? 0.7 : 1,
              fontSize: "13px",
              fontWeight: 400,
              fontStyle: "normal",
              textAlign: "right",
            }}
          >
            {postDescription?.length || 0}/500
          </Typography> */}
          {/* <Typography
              sx={{ marginTop: "20px", color: "grey.300" }}
              children={`${postDescription.length} - 200 characters left`}
            /> */}
          <Typography
            sx={{
              marginTop: "24px",
              color: darkMode ? "grey.900" : "black.350",
              opacity: darkMode ? 0.7 : 1,
              fontSize: "13px",
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "24px",
            }}
          >
            A 8% processing fee is collected by PloomSocial, plus 2.9% credit
            card processing fee and 0.30 per transaction.
          </Typography>
          <ButtonWithLoading
            color="primary"
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
            size="13px"
            type="submit"
            variant="contained"
            sx={{
              background: palette.gradients["main"],
              mr: "0",
              alignSelf: "end",
              width: { xs: "100%", lg: "184px" },
              display: "flex",
              paddingBlock: "15px",
              borderRadius: "10px",
              textTransform: "capitalize",
              mt: { xs: "20px", md: "30px" },
              "&.Mui-disabled": {
                color: alpha(palette.white["1000"], 0.5),
              },
            }}
          >
            Post Campaign
          </ButtonWithLoading>
        </Stack>
      </Stack>
      <FileUploadingDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        showShare
        type={"Story"}
        totalFiles={totalFiles}
        currentFileIndex={currentFileIndex}
        message={`Hold on a moment, we are creating your story`}
        uploadProgress={uploadProgress}
        createPost={createPostApiCall}
        isLoading={isLoading}
        setCurrentFileIndex={setCurrentFileIndex}
        setFinalVideo={setFinalVideo}
        setTotalFiles={setTotalFiles}
        setUploadProgress={setUploadProgress}
        storyData={storyData}
        uploadError={uploadError}
        title={
          "Please don’t close the tabs while uploading files, Otherwise System won’t be able to create a story."
        }
        awsUploading={awsUploading}
      ></FileUploadingDialog>

      <DialogBox
        openDialog={openContactUsDialog}
        setOpenDialog={setOpenContactUsDialog}
        Content={({ handleClose }) => (
          <ContactUsDialog
            msg="Your KYC details are not verified, Please contact our customer care."
            handleClose={() => setOpenContactUsDialog(false)}
          />
        )}
      ></DialogBox>

      <DialogBox
        openDialog={videoPopup}
        setOpenDialog={setVideoPopup}
        Content={({ handleClose }) => (
          <VideoRequired cancelCall={handleClose} />
        )}
      ></DialogBox>
    </>
  );
};
