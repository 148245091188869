// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Collapse,
//   Divider,
//   IconButton,
//   InputBase,
//   List,
//   Stack,
//   Typography,
//   alpha,
// } from "@mui/material";
// import SearchIcon from "../icons/SearchIcon";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import { useTheme } from "@mui/material";
// import RaiseTab from "../component/searchbar/RaiseTab";
// import UserTab from "../component/searchbar/UserTab";
// import Layout from "../component/Layout";
// import useThemeStore from "../hooks/store/useThemeStore";

// import CloseIcon from "../icons/CloseIcon";
// import useApi from "../hooks/useApi";
// import SearchUserSkeleton from "../component/skeleton/SearchUserSkeleton";
// import SearchBegSkeleton from "../component/skeleton/SearchBegSkeleton";
// import SearchAutoCorrectItem from "../component/searchbar/SearchAutoCorrectItem";
// // import { useAuth } from "../hooks/store/useAuth";

// const SearchResult = () => {
//   const { palette } = useTheme();
//   const { darkMode } = useThemeStore();
//   // const navigate = useNavigate();
//   // const { isLoggedIn } = useAuth();
//   const [currenTActiveTab, setCurrentActiveTab] = useState("1");
//   const [debouncedText, setDebouncedText] = useState("");
//   const [searchInput, setSearchInput] = useState("");
//   const { apiCall, isLoading } = useApi();
//   const [queryText, setQueryText] = useState(false);
//   const [currentSelectedItem, setCurrentSelectedItem] = useState(0);
//   const [accountSuggestion, setAccountSuggestion] = useState(false);
//   const [begSuggestion, setBegSuggestion] = useState(false);

//   async function getAutoCorrectData(text) {
//     let data = await apiCall({
//       url: `/v3/auto-correct?term=${text}&limit=5`,
//       method: "get",
//     });
//     setAccountSuggestion(data.data.data.accounts);
//     setBegSuggestion(data.data.data.begs);
//   }

//   useEffect(() => {
//     queryText && getAutoCorrectData(queryText);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [queryText]);

//   const setSearchText = (e) => {
//     setSearchInput(e.target.value);
//     if (debouncedText !== "") {
//       setDebouncedText("");
//     }
//     // sessionStorage.setItem("searchText", e.target.value);
//   };

//   useEffect(() => {
//     if (searchInput === "") {
//       return setQueryText("");
//     }
//     let timeout = setTimeout(() => {
//       setQueryText(searchInput);
//     }, 500);
//     return () => clearTimeout(timeout);
//   }, [searchInput]);

//   const handleClick = (event) => {
//     event.stopPropagation();
//   };

//   const handleChange = (event, newValue) => {
//     setCurrentActiveTab(newValue);
//     window.scroll({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   const reset = () => {
//     setSearchInput("");
//     setDebouncedText("");
//     // sessionStorage.setItem("searchText", "");
//   };
//   const maxSuggetion =
//     (begSuggestion?.length || 0) + (accountSuggestion.length || 0);

//   const isUserSuggetion = currentSelectedItem >= (begSuggestion?.length || 0);
//   let activeIndex = currentSelectedItem;
//   if (isUserSuggetion) {
//     activeIndex = currentSelectedItem - (begSuggestion.length || 0);
//   }
//   let searchData =
//     (isUserSuggetion
//       ? accountSuggestion[activeIndex]?.username
//       : begSuggestion[activeIndex].title) || 0;
//   useEffect(() => {
//     if (maxSuggetion <= 0) {
//       return;
//     }
//     console.log(searchData);
//     const keyDownHandler = (e) => {
//       const key = e.key;

//       switch (key) {
//         case "ArrowDown":
//           setCurrentSelectedItem((prevSelected) => {
//             const nextSelected = prevSelected + 1;

//             if (nextSelected > maxSuggetion - 1) {
//               return 0;
//             }
//             return nextSelected;
//           });
//           break;
//         case "ArrowUp":
//           setCurrentSelectedItem((prevSelected) => {
//             const nextSelected = prevSelected - 1;

//             if (nextSelected === -1) {
//               return maxSuggetion - 1;
//             }
//             return nextSelected;
//           });
//           break;
//         case "Enter":
//           setDebouncedText(searchData);
//           break;
//         default:
//           break;
//       }
//     };
//     window.addEventListener("keydown", keyDownHandler);
//     return () => {
//       window.removeEventListener("keydown", keyDownHandler);
//     };
//   }, [maxSuggetion, searchData]);

//   return (
//     <Layout>
//       <Box width={"100%"} sx={{ position: "relative", zIndex: "300" }}>
//         {/* searchbar component */}
//         <Box
//           width={{ xs: "calc(100% - 30px)", md: "673px" }}
//           display={"grid"}
//           gridTemplateRows={"auto auto 1fr"}
//           ml={"auto"}
//           mr={"auto"}
//           mt={{ xs: "15px", md: "30px" }}
//           borderRadius={"10px"}
//           sx={{
//             backgroundColor: darkMode ? "bgDark.third" : "white.1000",
//             paddingInline: { xs: "15px", md: "30px" },
//             paddingBottom: { xs: "15px", md: "30px" },
//           }}
//           onClick={handleClick}
//         >
//           <Box
//             sx={{
//               paddingTop: { xs: "15px", md: "30px" },
//               position: "sticky",
//               top: -0.5,
//               backgroundColor: darkMode ? "bgDark.third" : "white.1000",
//               zIndex: 12,
//             }}
//           >
//             {/* SearchBar input */}
//             <Box
//               maxWidth={"100%"}
//               width={"100%"}
//               display={"flex"}
//               backgroundColor={darkMode ? "black.250" : "neutral.150"}
//               sx={{
//                 alignItems: "center",
//                 justifyContent: "center",
//                 gap: { xs: "8px", md: "16px" },
//               }}
//               px={{ xs: "6px", md: "12px" }}
//               py={{ xs: "4px", md: "8px" }}
//               borderRadius={{ xs: "10px", md: "15px" }}
//             >
//               <IconButton component={"label"} htmlFor="searchTextField">
//                 <SearchIcon
//                   sx={{
//                     p: 0,
//                     fill: darkMode ? palette.grey[300] : palette.grey[300],
//                   }}
//                 />
//               </IconButton>
//               <InputBase
//                 type="text"
//                 name="search"
//                 id="searchTextField"
//                 value={debouncedText !== "" ? debouncedText : searchInput}
//                 onChange={setSearchText}
//                 placeholder="Search..."
//                 fullWidth
//                 flexgrow="1"
//                 autoComplete="off"
//                 sx={{
//                   outline: "none",
//                   border: "none",
//                   width: "100%",
//                   fontSize: "16px",
//                   color: darkMode ? "white.1000" : "black.1000",
//                   whiteSpace: "nowrap",
//                   textOverflow: "ellipsis",
//                   overflow: "hidden",
//                   "& input:-webkit-autofill,& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
//                     {
//                       WebkitBoxShadow: `0 0 0 30px ${
//                         darkMode ? "#3d3d3d" : "#e5e5e5"
//                       } inset`,
//                       WebkitTextFillColor: darkMode
//                         ? palette.white[1000]
//                         : palette.black[1000],
//                       caretColor: darkMode
//                         ? palette.white[1000]
//                         : palette.black[1000],
//                     },
//                 }}
//               />
//               {searchInput !== "" && (
//                 <IconButton
//                   onClick={reset}
//                   sx={{ p: 0 }}
//                   component={"label"}
//                   htmlFor="searchTextField"
//                 >
//                   <CloseIcon
//                     color={
//                       darkMode
//                         ? alpha(palette.white["1000"], 0.7)
//                         : palette.black["1000"]
//                     }
//                   ></CloseIcon>
//                 </IconButton>
//               )}
//             </Box>

//             <Collapse in={debouncedText !== ""}>
//               <Typography
//                 py="15px"
//                 ml={"16px"}
//                 fontSize={{ xs: "14px", md: "17px" }}
//                 sx={{
//                   color: darkMode ? "grey.900" : "black.1000",
//                   whiteSpace: "nowrap",
//                   textOverflow: "ellipsis",
//                   overflow: "hidden",
//                 }}
//               >
//                 Showing results for{" "}
//                 <Typography
//                   component={"span"}
//                   sx={{ color: darkMode ? "white.1000" : "primary.main" }}
//                 >
//                   "{debouncedText}"
//                 </Typography>
//               </Typography>
//             </Collapse>

//             {/* auto correct suggestion box */}
//             <Collapse in={searchInput !== "" && debouncedText === ""}>
//               <Stack
//                 sx={{
//                   mb: "5px",
//                   padding: "15px",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     mb: "5px",
//                     color: darkMode ? "white.1000" : "primary.main",
//                     fontWeight: 500,
//                   }}
//                 >
//                   Raise/Story
//                 </Typography>
//                 {isLoading && (
//                   <>
//                     <SearchBegSkeleton />
//                     <SearchBegSkeleton />
//                     <SearchBegSkeleton />
//                   </>
//                 )}
//                 <List>
//                   {begSuggestion &&
//                     !isLoading &&
//                     begSuggestion.map((data, index) => (
//                       <SearchAutoCorrectItem
//                         key={index + "_beg"}
//                         setSearchInput={setDebouncedText}
//                         selected={currentSelectedItem === index}
//                         data={data.title}
//                         setCurrentActiveTab={() => {
//                           setCurrentActiveTab("1");
//                         }}
//                       ></SearchAutoCorrectItem>
//                     ))}
//                 </List>

//                 {begSuggestion && !isLoading && begSuggestion.length === 0 && (
//                   <Typography
//                     sx={{
//                       fontSize: "14px",
//                       color: darkMode
//                         ? alpha(palette.white["1000"], 0.4)
//                         : alpha(palette.black["1000"], 0.6),
//                       textAlign: "center",
//                       fontWeight: "500",
//                     }}
//                   >
//                     No Campaign found
//                   </Typography>
//                 )}

//                 <Divider
//                   sx={{
//                     my: "10px",
//                     borderColor: darkMode
//                       ? alpha(palette.white["1000"], 0.2)
//                       : alpha(palette.black["1000"], 0.1),
//                   }}
//                 />

//                 <Typography
//                   sx={{
//                     my: "5px",
//                     color: darkMode ? "white.1000" : "primary.main",
//                     fontWeight: 500,
//                   }}
//                 >
//                   User
//                 </Typography>
//                 {isLoading && (
//                   <>
//                     <SearchUserSkeleton />
//                     <SearchUserSkeleton />
//                     <SearchUserSkeleton />
//                   </>
//                 )}
//                 <List>
//                   {accountSuggestion &&
//                     !isLoading &&
//                     accountSuggestion.map((data, index) => (
//                       <SearchAutoCorrectItem
//                         key={index + "_account"}
//                         setSearchInput={setDebouncedText}
//                         data={data.username}
//                         selected={
//                           currentSelectedItem ===
//                           index + (begSuggestion?.length || 0)
//                         }
//                         setCurrentActiveTab={() => {
//                           setCurrentActiveTab("2");
//                         }}
//                       ></SearchAutoCorrectItem>
//                     ))}
//                 </List>
//                 {accountSuggestion &&
//                   !isLoading &&
//                   accountSuggestion.length === 0 && (
//                     <Typography
//                       sx={{
//                         fontSize: "14px",
//                         color: darkMode
//                           ? alpha(palette.white["1000"], 0.4)
//                           : alpha(palette.black["1000"], 0.6),
//                         textAlign: "center",
//                         fontWeight: "500",
//                       }}
//                     >
//                       No user found
//                     </Typography>
//                   )}
//               </Stack>
//             </Collapse>
//           </Box>

//           {/* {tab container for showing raise and user results} */}
//           <Collapse
//             sx={{
//               width: "100%",
//               typography: "body1",
//             }}
//             in={debouncedText !== ""}
//           >
//             <TabContext value={currenTActiveTab}>
//               <Box
//                 sx={{
//                   borderBottom: 1,
//                   borderColor: darkMode
//                     ? "black.250"
//                     : alpha(palette.black[1000], 0.1),

//                   position: "sticky",
//                   backgroundColor: darkMode ? "bgDark.third" : "white.1000",
//                   top: { xs: "101.5px", md: "130px" },
//                   zIndex: "2",
//                 }}
//               >
//                 <TabList
//                   onChange={handleChange}
//                   aria-label="lab API tabs example"
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     width: "100%",
//                     minWidth: "100%",

//                     ".MuiTabs-indicator": {
//                       backgroundColor: darkMode ? "white.1000" : "primary.main",
//                     },
//                   }}
//                 >
//                   <Tab
//                     value="1"
//                     sx={{
//                       maxWidth: "50%",
//                       width: "50%",
//                       textTransform: "capitalize",
//                       padding: { sx: "0px" },
//                       "&.MuiTab-root": {
//                         color: darkMode ? "grey.900" : "black.350",
//                       },
//                       "&.Mui-selected": {
//                         color: darkMode ? "white.1000" : "black.1000",
//                       },
//                     }}
//                     label="Raises"
//                   ></Tab>

//                   <Tab
//                     value="2"
//                     sx={{
//                       maxWidth: "50%",
//                       width: "50%",
//                       textTransform: "capitalize",
//                       "&.MuiTab-root": {
//                         color: darkMode ? "grey.900" : "black.350",
//                       },
//                       "&.Mui-selected": {
//                         color: darkMode ? "white.1000" : "black.1000",
//                       },
//                     }}
//                     label="Users"
//                   />
//                 </TabList>
//               </Box>
//               {debouncedText && (
//                 <>
//                   <RaiseTab searchInput={debouncedText}></RaiseTab>
//                   <UserTab searchInput={debouncedText}></UserTab>
//                 </>
//               )}
//             </TabContext>
//           </Collapse>
//         </Box>
//       </Box>
//     </Layout>
//   );
// };

// export default SearchResult;

import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  InputBase,
  Typography,
  alpha,
} from "@mui/material";
import Button from "@mui/material/Button";
import SearchIcon from "../icons/SearchIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useTheme } from "@mui/material";
import RaiseTab from "../component/searchbar/RaiseTab";
import UserTab from "../component/searchbar/UserTab";
import Layout from "../component/Layout";
import useThemeStore from "../hooks/store/useThemeStore";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../icons/CloseIcon";
// import { useAuth } from "../hooks/store/useAuth";

const SearchResult = () => {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const navigate = useNavigate();
  // const { isLoggedIn } = useAuth();
  const [currenTActiveTab, setcurrentActiveTab] = useState("1");
  const [debounceedText, setDebouncedText] = useState("");
  const [searchInput, setSearchInput] = useState("");

  // /without LoggedIn not accessible
  // useEffect(() => {
  // !isLoggedIn && navigate("/");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const setSearchText = (e) => {
    setSearchInput(e.target.value);
    sessionStorage.setItem("searchText", e.target.value);
  };

  useEffect(() => {
    if (searchInput === "") {
      return setDebouncedText("");
    }
    let timeout = setTimeout(() => {
      setDebouncedText(searchInput);
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchInput]);

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleChange = (event, newValue) => {
    setcurrentActiveTab(newValue);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const reset = () => {
    setSearchInput("");
    sessionStorage.setItem("searchText", "");
  };

  return (
    <Layout>
      <Box width={"100%"} sx={{ position: "relative", zIndex: "300" }}>
        {/* searchbar component */}
        <Box
          width={{ xs: "calc(100% - 30px)", md: "673px" }}
          display={"grid"}
          gridTemplateRows={"auto auto 1fr"}
          ml={"auto"}
          mr={"auto"}
          mt={{ xs: "15px", md: "30px" }}
          borderRadius={"10px"}
          sx={{
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            paddingInline: { xs: "15px", md: "30px" },
            paddingBottom: { xs: "15px", md: "30px" },
          }}
          onClick={handleClick}
        >
          <Box
            sx={{
              paddingTop: { xs: "15px", md: "30px" },
              position: "sticky",
              top: -0.5,
              backgroundColor: darkMode ? "bgDark.third" : "white.1000",
              zIndex: 12,
            }}
          >
            {/* back button with search input */}
            <Box
              //   stop propogetion on click inside this block
              display={"grid"}
              gridTemplateColumns={"auto  1fr"}
              gap={"8px"}
              sx={{
                placeItems: "center",
              }}
            >
              <Button
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                  minWidth: { xs: "auto" },
                  p: 0,
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{
                    color: darkMode ? "white.1000" : "black.1000",
                    fontSize: { xs: "20px", sm: "24px" },
                  }}
                />
              </Button>

              <Box
                maxWidth={"100%"}
                width={"100%"}
                display={"flex"}
                backgroundColor={darkMode ? "black.250" : "neutral.150"}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  gap: { xs: "8px", md: "16px" },
                }}
                px={{ xs: "6px", md: "12px" }}
                py={{ xs: "4px", md: "8px" }}
                borderRadius={{ xs: "10px", md: "15px" }}
              >
                <IconButton component={"label"} htmlFor="searchTextField">
                  <SearchIcon
                    sx={{
                      p: 0,
                      fill: darkMode ? palette.grey[300] : palette.grey[300],
                    }}
                  />
                </IconButton>
                <InputBase
                  type="text"
                  name="search"
                  id="searchTextField"
                  value={searchInput}
                  onChange={setSearchText}
                  placeholder="Search..."
                  fullWidth
                  flexgrow="1"
                  sx={{
                    outline: "none",
                    border: "none",
                    width: "100%",
                    fontSize: "16px",
                    color: darkMode ? "white.1000" : "black.1000",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    "& input:-webkit-autofill,& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
                      {
                        "-webkit-box-shadow": `0 0 0 30px ${
                          darkMode ? "#3d3d3d" : "#e5e5e5"
                        } inset`,
                        "-webkit-text-fill-color": darkMode
                          ? palette.white[1000]
                          : palette.black[1000],
                        caretColor: darkMode
                          ? palette.white[1000]
                          : palette.black[1000],
                      },
                  }}
                />
                {debounceedText !== "" && (
                  <IconButton
                    onClick={reset}
                    sx={{ p: 0 }}
                    component={"label"}
                    htmlFor="searchTextField"
                  >
                    <CloseIcon
                      color={
                        darkMode
                          ? alpha(palette.white["1000"], 0.7)
                          : palette.black["1000"]
                      }
                    ></CloseIcon>
                  </IconButton>
                )}
              </Box>
            </Box>
            <Collapse in={debounceedText !== ""}>
              <Typography
                py="15px"
                ml={"16px"}
                fontSize={{ xs: "14px", md: "17px" }}
                sx={{
                  color: darkMode ? "grey.900" : "black.1000",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                Showing results for{" "}
                <Typography
                  component={"span"}
                  sx={{ color: darkMode ? "white.1000" : "primary.main" }}
                >
                  "{searchInput}"
                </Typography>
              </Typography>
            </Collapse>
          </Box>

          {/* {tabcontainer for showing raise and user results} */}
          <Collapse
            sx={{
              width: "100%",
              typography: "body1",
            }}
            in={debounceedText !== ""}
          >
            <TabContext value={currenTActiveTab}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: darkMode
                    ? "black.250"
                    : alpha(palette.black[1000], 0.1),

                  position: "sticky",
                  backgroundColor: darkMode ? "bgDark.third" : "white.1000",
                  top: { xs: "101.5px", md: "130px" },
                  zIndex: "2",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    minWidth: "100%",

                    ".MuiTabs-indicator": {
                      backgroundColor: darkMode ? "white.1000" : "primary.main",
                    },
                  }}
                >
                  <Tab
                    value="1"
                    sx={{
                      maxWidth: "50%",
                      width: "50%",
                      textTransform: "capitalize",
                      padding: { sx: "0px" },
                      "&.MuiTab-root": {
                        color: darkMode ? "grey.900" : "black.350",
                      },
                      "&.Mui-selected": {
                        color: darkMode ? "white.1000" : "black.1000",
                      },
                    }}
                    label="Raises"
                  ></Tab>

                  <Tab
                    value="2"
                    sx={{
                      maxWidth: "50%",
                      width: "50%",
                      textTransform: "capitalize",
                      "&.MuiTab-root": {
                        color: darkMode ? "grey.900" : "black.350",
                      },
                      "&.Mui-selected": {
                        color: darkMode ? "white.1000" : "black.1000",
                      },
                    }}
                    label="Users"
                  />
                </TabList>
              </Box>
              {debounceedText && (
                <>
                  <RaiseTab searchInput={debounceedText}></RaiseTab>
                  <UserTab searchInput={debounceedText}></UserTab>
                </>
              )}
            </TabContext>
          </Collapse>
        </Box>
      </Box>
    </Layout>
  );
};

export default SearchResult;
