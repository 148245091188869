import { createSvgIcon } from "@mui/material";
export default function UpArrowIcon(props) {
  const CreateThumbsUpIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_90_25479)">
        <path
          d="M0.868353 14.4316L17.1318 14.4316C17.9014 14.4316 18.2935 13.5023 17.7417 12.9505L9.60995 4.81879C9.27597 4.4848 8.72417 4.4848 8.39005 4.81879L0.258327 12.9505C-0.293324 13.5023 0.0987434 14.4316 0.868353 14.4316Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_90_25479">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(18 18.5) rotate(180)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateThumbsUpIcon
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
