import { createSvgIcon } from "@mui/material";

export default function Heart(props) {
  let color = props.color;
  let CreateHeart = createSvgIcon(
    <>
      <path
        id="Stroke 1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.67374 6.76569C1.04782 4.81152 1.77932 2.57794 3.83091 1.91702C4.91007 1.56877 6.10124 1.7741 6.99841 2.44902C7.84716 1.79277 9.08207 1.5711 10.1601 1.91702C12.2117 2.57794 12.9478 4.81152 12.3225 6.76569C11.3483 9.86319 6.99841 12.249 6.99841 12.249C6.99841 12.249 2.68057 9.89935 1.67374 6.76569Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        id="Stroke 3"
        d="M9.33182 3.9082C9.95598 4.11004 10.397 4.66712 10.4501 5.32104"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  );
  return (
    <CreateHeart
      width="22"
      height="22"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
