import { Box, Skeleton } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function WithdrawalFundsSkeleton() {
  const { darkMode } = useThemeStore();
  return (
    <Box>
      <Skeleton
        width={"100%"}
        height={35}
        sx={{
          transform: "unset",
          mt: "12px",
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
        }}
      ></Skeleton>
      <Skeleton
        width={"100%"}
        height={50}
        sx={{
          transform: "unset",
          mt: 1,
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
        }}
      ></Skeleton>
      <Skeleton
        width={"100%"}
        height={35}
        sx={{
          transform: "unset",
          mt: 1,
          backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
        }}
      ></Skeleton>
    </Box>
  );
}
