import React from "react";
import DialogBackground from "../../withdrawal/dialog/DialogBackground";

export default function ConfirmWithdrawalDialog({
  cancelCall,
  confirmCall,
  isLoading,
}) {
  return (
    <DialogBackground
      heading={"Confirmation"}
      description="Click confirm to deposit funds into your bank account on file."
      cancelCall={cancelCall}
      confirmCall={confirmCall}
      isLoading={isLoading}
    />
  );
}
