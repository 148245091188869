import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { alpha, useTheme } from "@mui/material/styles";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import PloomLogo from "../../icons/PloomLogo";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// div to image generator
import * as htmlToImage from "html-to-image";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { APIS } from "../../api/lists";
import copy from "copy-to-clipboard";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useSnack } from "../../hooks/store/useSnack";
import CloseIcon from "../../icons/CloseIcon";

export default function InstaShareDialog({
  openInstaDrawer,
  setOpenInstaDrawer,
  begTitle,
  begImage,
  link,
  beg,
}) {
  const { palette } = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [sharedImage, setSharedImage] = React.useState(null);
  const imgPrefix = process.env.REACT_APP_API_BASE_URL + APIS.GET_IMAGE;
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const [shareId] = React.useState("sharableDiv" + Math.random() * 5000);
  const handleClose = () => {
    setOpenInstaDrawer(false);
  };
  const handleSharableDivLoaded = async () => {
    try {
      const sharableDiv = document.getElementById(shareId);

      // Generate image when the sharable div is loaded
      let imgBlob = await htmlToImage.toBlob(sharableDiv, {
        cacheBust: true,
        includeQueryParams: true,
      });
      imgBlob = await htmlToImage.toBlob(sharableDiv, {
        cacheBust: true,
        includeQueryParams: true,
      });
      imgBlob = await htmlToImage.toBlob(sharableDiv, {
        cacheBust: true,
        includeQueryParams: true,
      });

      // Create a file from the Blob
      const imgFile = new File([imgBlob], "shared_image.png", {
        type: "image/png",
      });

      // Store the image in the state
      setSharedImage(imgFile);
      setLoading(false);
    } catch (err) {
      console.log(`Error generating image: ${err.message}`);
    }
  };

  const handleShareClick = async () => {
    try {
      setLoading(true);
      // Share the image stored in the state
      await navigator.share({
        files: [sharedImage],
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(`Error: ${err.message}`);
    }
  };

  React.useEffect(() => {
    setSharedImage(null);
  }, [begImage]);

  return (
    <React.Fragment>
      <Dialog
        open={openInstaDrawer}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            margin: "10px",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 200,
              borderRadius: "20px",
              background: palette.black["1000"],
            }}
            onClick={() => setOpenInstaDrawer(false)}
          >
            <CloseIcon color={alpha(palette.white["1000"], 0.7)} />
          </IconButton>
          <Box
            id={shareId}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: palette.gradients.main,
              padding: "20px",
            }}
            onLoad={handleSharableDivLoaded}
          >
            <Box width={"320px"}></Box>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBlock: "12px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                backgroundColor: "white.1000",
                width: "100%",
              }}
            >
              <PloomLogo
                sx={{
                  width: { xs: "60px" },
                  height: { xs: "25px" },
                  viewBox: "0 0 104 45",
                }}
              />
            </Stack>
            <Box
              component={"img"}
              src={imgPrefix + "?img_url=" + begImage}
              sx={{
                objectFit: "cover",
                width: "100%",
                minHeight: "250px",
                maxHeight: "320px",
              }}
            ></Box>
            <Box
              sx={{
                width: "100%",
                padding: "14px",
                backgroundColor: "white.1000",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "500",
                }}
              >
                {begTitle}
              </Typography>

              {beg.goalAmount > 0 && (
                <Box
                  sx={{
                    mt: "14px",
                    backgroundColor: alpha(palette.black["100"], 0.4),
                    backdropFilter: "blur(20px)",
                  }}
                  width="100%"
                  height={"8px"}
                  borderRadius={1}
                  overflow={"hidden"}
                >
                  <Box
                    backgroundColor={"success.progressBar"}
                    width={
                      ((beg.amountRaised / beg.goalAmount) * 100 > 100
                        ? 100
                        : (beg.amountRaised / beg.goalAmount) * 100) + "%"
                    }
                    height={"100%"}
                    borderRadius={1}
                  />
                </Box>
              )}

              <Typography
                sx={{
                  mt: "10px",
                  fontFamily: "arial",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "black.1000",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "black.1000",
                  }}
                >
                  ${beg.amountRaised}
                </Typography>{" "}
                {beg.goalAmount > 0 ? `Raised of ${beg.goalAmount}` : "Raised"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "15px",
              backgroundColor: darkMode ? "bgDark.main" : "bgLight.main",
            }}
          >
            <Typography
              sx={{
                mt: "20px",
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Remember to add the fundraiser link to your Instagram bio
            </Typography>

            <Box
              sx={{
                mt: "18px",
                display: "grid",
                gridTemplateColumns: "1fr auto",
                backgroundColor: darkMode ? "#4F4F4F" : "white.700",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  backgroundColor: darkMode ? "#4F4F4F" : "white.700",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Typography
                  sx={{
                    // textOverflow: "ellipsis",
                    // whiteSpace: "nowrap",
                    overflowX: "auto",
                    "::-webkit-scrollbar ": {
                      display: "none",
                    },
                    color: darkMode ? "white.1000" : "black.1000",
                  }}
                >
                  {link}
                </Typography>
              </Box>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  copy(link);
                  setSnack("Link Copied Successfully");
                }}
                sx={{
                  backgroundColor: darkMode ? "#4F4F4F" : "white.700",
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>

            <ButtonWithLoading
              variant="contained"
              isLoading={loading}
              onClick={handleShareClick}
              disabled={loading}
              fullWidth
              sx={{
                mt: "20px",
                padding: "10px",
                background: palette.gradients.main,
              }}
            >
              Share to Instagram
            </ButtonWithLoading>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
