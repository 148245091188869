import React, { useEffect } from "react";
import BackButtonLayout from "../../component/ui/BackButtonLayout";
import WithdrawalList from "../../component/withdrawal/WithdrawalList";
import WidthdrawalHeader from "../../component/withdrawal/WidthdrawalHeader";
import { useAllModal } from "../../hooks/store/useAllModal";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/store/useAuth";

export default function WithdrawalHistory() {
  const { showIdentityDrawer, setShowIdentityDrawer } = useAllModal();
  const { apiCall } = useApi(true);
  const { userId } = useAuth();

  //1. check kyc verification
  const verifyUserIdentity = React.useCallback(
    async function () {
      let response = await apiCall({
        url: "v2/accounts/check-verified-2",
        method: "post",
        data: { userId: userId },
      });
      let kycVerification = response.data.data.isKyc.isKyc;
      if (kycVerification !== 1) {
        return setShowIdentityDrawer(true, "banking", undefined, true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    verifyUserIdentity();
    return () => {
      if (showIdentityDrawer) {
        setShowIdentityDrawer(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BackButtonLayout pageTitle={"Withdrawals"}>
      <WidthdrawalHeader />
      <WithdrawalList />
    </BackButtonLayout>
  );
}
