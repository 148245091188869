const versionV3 = "v3";
const versionV2 = "v2";
export const APIS = {
  ACCOUNTTYPE: {
    LIST: versionV2 + "/account-types",
  },
  WITHDRAWAL: {
    LIST: versionV3 + "/withdrawals/user/list",
  },
  BANK_ACCOUNT: {
    LIST: (userId) => versionV3 + `/users/${userId}/paymethods`,
    ADD: versionV3 + "/paymethods/new",
    EDIT: (bankId) => versionV3 + `/paymethods/${bankId}`,
    DELETE: (bankId) => versionV3 + `/paymethods/${bankId}`,
  },
  FEED: {
    DONATION_LIST: (feedId) => versionV3 + "/begs/" + feedId + "/chipins",
    SHARE: versionV3 + "/shares",
    USER: {
      LIST: (userId) => versionV3 + "/begs/users/" + userId,
      SELF_LIST: (userId) => versionV3 + "/begs/usersAll/" + userId,
    },
    SAVE_LIST: versionV3 + "/begs/bookmark/get-all",
    GET: (feedId) => versionV3 + "/begs/" + feedId,
    WITHDRAW: {
      CREATE: versionV3 + "/withdrawals/payouts",
      LIST: (id) => versionV3 + "/withdrawals/feed/" + id,
      CHARGES: (id) => versionV3 + "/withdrawals/feed/" + id + "/charges",
    },
    BEG_BY_ID: (begId, cuserId) =>
      versionV3 + `/begs/${begId}?cuserId=${cuserId}`,
    CHANGE_ORDER: (begId) => versionV3 + `/videos/change-order/${begId}`,
    DELETE_FILE: (begId) => versionV3 + `/begs/assets/${begId}`,
    UPDATE_BEG: (begId) => versionV3 + `/begs/${begId}`,
  },
  USER: {
    EDIT: versionV3 + "/accounts",
    PROFILE_UPDATE: (userId) =>
      versionV3 + "/get-signed-url/profiles/" + userId,
    EDIT_PROFILE: (userId) => versionV2 + "/accounts/" + userId,
    ALL_CHIPINS_LIST: (userId) =>
      versionV3 + "/users/" + userId + "/chipins/all",
    BLOCK_LIST: versionV3 + "/users/blockedList",
    SEARCH: versionV3 + "/users/search",
    BLOCK: (userId) => versionV3 + `/users/${userId}/block`,
    UNBLOCK: (userId) => versionV3 + `/users/${userId}/unblock`,
    DELETE: (userId) => versionV2 + `/accounts/${userId}`,
    LOGOUT: versionV2 + "/logout",
  },
  BOOKMARK: {
    REMOVE_BOOKMARK: versionV3 + "/begs/bookmark/remove",
    ADD: versionV3 + "/begs/bookmark/save",
  },
  DRAFT: {
    CREATE: versionV3 + "/begs/drafts/create",
    DELETE: (draftId) => versionV3 + "/begs/drafts/" + draftId,
    UPDATE: (id) => versionV3 + "/begs/drafts/" + id,
    CHECK: versionV3 + "/begs/drafts/check-drafts",
    LIST: versionV3 + "/begs/drafts",
    DRAFT_TO_CAMPAIGN: (id) =>
      versionV3 + "/begs/drafts/draft-to-campaign/" + id,
  },
  GET_IMAGE: "get-image",
  COMMENT: {
    GET: versionV3 + "/comments",
    SINGLE: (id) => versionV3 + "/comments/" + id,
    PIN: (id) => versionV3 + "/comments/pin/" + id,
    //for pin user post method and for unpin use delete method
  },
  LIKE: { CREATE: versionV3 + "/likes" },
  // CUTOUT: versionV3 + "/withdrawals/cutouts",
  NOTIFICATION: {
    READ: (id) => versionV3 + "/notification/" + id,
    READAll: versionV3 + "/notification/mark-all-as-read",
    GET_COUNT: versionV3 + "/notification/count",
    FCM_TOKEN: versionV3 + "/notification/fcmToken",
  },
  REPORT: versionV3 + "/content-flags",
};
