import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useAuth = create(
  persist(
    (set) => ({
      user: {},
      accessToken: "",
      refreshToken: "",
      userId: "",
      isLoggedIn: false,
      isGuest: false,
      notificationPermission: undefined,
      fcmToken: undefined,
      guestLogin: ({ email, name }) => {
        set((state) => ({
          ...state,
          user: { email, name },
          isGuest: true,
        }));
      },
      login: ({ user, accessToken, refreshToken, userId }) => {
        set((state) => ({
          ...state,
          isLoggedIn: true,
          isGuest: false,
          user,
          accessToken,
          refreshToken,
          userId,
        }));
      },
      logout: () =>
        set(() => ({
          user: {},
          accessToken: "",
          refreshToken: "",
          userId: "",
          isLoggedIn: false,
          isGuest: false,
          notificationPermission: undefined,
          fcmToken: undefined,
        })),
      setUserDatail: (
        firstName,
        lastName,
        email,
        orgName = undefined,
        accountType = undefined,
        ein = undefined
      ) => {
        set((state) => ({
          ...state,
          user: {
            ...state.user,
            firstName,
            lastName,
            email,
            orgName,
            accountType,
            ein,
          },
        }));
      },
      setNewEmail: (email) => {
        set((state) => ({
          ...state,
          user: { ...state.user, email },
        }));
      },
      setUserProfile: (profileImage) => {
        set((state) => ({
          ...state,
          user: { ...state.user, profileImage },
        }));
      },
      setMobile: (mobile) => {
        set((state) => ({
          ...state,
          user: { ...state.user, mobile },
        }));
      },
      setEmailVerificationFlag: (email_verified) => {
        set((state) => ({
          ...state,
          user: { ...state.user, email_verified },
        }));
      },
      setAccessToken: (accessToken) =>
        set((state) => ({ ...state, accessToken })),
      setNotification: (fcmToken, notificationPermission) =>
        set((state) => ({ fcmToken, notificationPermission })),
    }),
    { name: "auth" }
  )
);
