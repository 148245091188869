import { create } from "zustand";

export const useDraft = create((set) => ({
  draftObject: undefined,
  setDraftObject(draftObject) {
    set(() => ({
      draftObject: draftObject,
    }));
  },
  reset() {
    set(() => ({
      draftObject: undefined,
    }));
  },
}));
