import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../../component/ui/form/Input";
import useThemeStore from "../../hooks/store/useThemeStore";
import AddressInput from "../../component/kyc/AddressInput";
import ButtonWithLoading from "../../component/ui/button/ButtonWithLoading";
import { useKyc } from "../../hooks/store/useKyc";

export const AddressForm = ({ setOpenForm, setStep }) => {
  const { darkMode } = useThemeStore();
  const {
    setStep2,
    city: preCity,
    zip: preZip,
    state: preState,
    physicalAddress: prePhysicalAddress,
  } = useKyc();
  const [state, setState] = useState(preState ? preState : "");
  const [city, setCity] = useState(preCity ? preCity : "");
  const [zipCode, setZipCode] = useState(preZip ? preZip : "");
  function updateState(value) {
    setState(value);
    setValue("state", value);
  }

  function updateCity(value) {
    setCity(value);
    setValue("city", value);
  }

  function updateZipCode(value) {
    setZipCode(value);
  }

  const uszipRegExp = /^\d{5}(-\d{4})?$/;
  const schema = Yup.object({
    physicalAddress: Yup.string().required("Address is required").min(4),
    city: Yup.string().required("City is required").min(2),
    state: Yup.string().required("State is required").min(2),
    country: Yup.string().required("Country is required").min(2),
    zip: Yup.string()
      .required("Zip code is required.")
      .matches(uszipRegExp, "Invalid zip code"),
  });

  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      physicalAddress: prePhysicalAddress ? prePhysicalAddress : undefined,
      city: city,
      state: state,
      zip: zipCode,
      country: "US",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  // use form default value updater
  useEffect(() => {
    setValue("city", city);
    setValue("state", state);
    setValue("zip", zipCode);
  }, [city, state, zipCode, setValue]);

  async function processKyc(values) {
    try {
      setStep2(
        values.physicalAddress,
        values.city,
        values.state,
        values.zip,
        values.country
      );
      setOpenForm("step3");
      setStep(2);
    } catch (error) {}
  }

  return (
    <Box
      sx={{
        backgroundColor: {
          xs: darkMode ? "bgDark.third" : "white.1000",
          md: "unset",
        },
        width: "100%",
        marginInline: "auto",
        zIndex: 50,
        borderRadius: { xs: "10px", lg: "unset" },
      }}
    >
      <Stack
        sx={{
          mt: { xs: "20px", md: "30px" },
          display: "grid",
          gridTemplateColumns: "1fr",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        {/* form heading */}
        {/* <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontSize: { xs: "16px", md: "18px" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          Please Help Us With Some Additional Details
        </Typography> */}
        {/* form */}
        <Stack onSubmit={handleSubmit(processKyc)} component={"form"}>
          <AddressInput
            sx={{ mt: { xs: "-10px!important", md: "0px!important" } }}
            name={"physicalAddress"}
            inputLabel={"Street Address"}
            placeholder="Enter Your Street Address"
            register={register}
            errors={formState.errors}
            updateState={updateState}
            updateCity={updateCity}
            updateZipCode={updateZipCode}
            value={prePhysicalAddress ? prePhysicalAddress : ""}
          />

          <Input
            name={"city"}
            inputLabel={"City"}
            value={city}
            onChange={(e) => {
              updateCity(e.target.value);
            }}
            placeholder="Enter Your City"
            register={register}
            errors={formState.errors}
            disabled
          />

          <Input
            name={"state"}
            inputLabel={"State"}
            value={state}
            onChange={(e) => {
              updateState(e.target.value);
            }}
            placeholder="Enter State"
            register={register}
            errors={formState.errors}
            disabled
          />

          <Input
            name={"zip"}
            type="number"
            inputLabel={"Zip Code"}
            value={zipCode}
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
            placeholder="Enter Zip Code"
            register={register}
            errors={formState.errors}
            disabled
          />

          <Input
            name={"country"}
            inputLabel={"Country"}
            placeholder="Enter Country"
            defaultValue={"US"}
            disabled
            register={register}
            errors={formState.errors}
          />

          {/* next and previous button container */}
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "15px" }}>
            <ButtonWithLoading
              onClick={() => {
                setOpenForm("step1");
              }}
              variant="contained"
              fullWidth
              sx={{
                flex: 1,
                paddingBlock: "15px",
                borderRadius: "10px",
                textTransform: "capitalize",
                mt: "30px",
                "&.Mui-disabled": {
                  opacity: 0.5,
                  color: "white.1000",
                  backgroundColor: "primary.main",
                },
              }}
            >
              Previous
            </ButtonWithLoading>
            <ButtonWithLoading
              type="submit"
              disabled={!formState.isValid || formState.isSubmitting}
              variant="contained"
              fullWidth
              sx={{
                flex: 1,
                paddingBlock: "15px",
                borderRadius: "10px",
                textTransform: "capitalize",
                mt: "30px",
                "&.Mui-disabled": {
                  color: darkMode ? "black.100" : "white.1000",
                  backgroundColor: darkMode
                    ? "primary.disabledDark"
                    : "primary.disabledLight",
                },
              }}
            >
              Next
            </ButtonWithLoading>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
