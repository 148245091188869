import { createSvgIcon } from "@mui/material";
export default function EditIcon2(props) {
  let color = props.color;
  const EditIcon2 = createSvgIcon(
    <>
      <g id="Iconly/Light/Edit Square">
        <g id="Edit Square">
          <path
            id="Stroke 1"
            d="M11.492 2.78906H7.753C4.678 2.78906 2.75 4.96606 2.75 8.04806V16.3621C2.75 19.4441 4.669 21.6211 7.753 21.6211H16.577C19.662 21.6211 21.581 19.4441 21.581 16.3621V12.3341"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            id="Stroke 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.82666 10.9205L16.2997 3.4475C17.2307 2.5175 18.7397 2.5175 19.6707 3.4475L20.8877 4.6645C21.8187 5.5955 21.8187 7.1055 20.8877 8.0355L13.3787 15.5445C12.9717 15.9515 12.4197 16.1805 11.8437 16.1805H8.09766L8.19166 12.4005C8.20566 11.8445 8.43266 11.3145 8.82666 10.9205Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            id="Stroke 5"
            d="M15.1641 4.60156L19.7301 9.16756"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </g>
      </g>
    </>
  );
  return (
    <EditIcon2
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
