import { createSvgIcon } from "@mui/material";

export default function MuteIcon(props) {
  // let { palette } = useTheme();
  // let color = palette.darkIcon["main"];
  let CreateHeart = createSvgIcon(
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8235 3.81599C24.2434 3.39611 24.9128 3.39364 25.3488 3.81475C25.7724 4.23833 25.7724 4.92248 25.35 5.34482L5.34318 25.3505C5.14434 25.5493 4.8578 25.6667 4.57868 25.6667C4.30573 25.6667 4.03648 25.5555 3.81911 25.3542C3.39425 24.9134 3.39425 24.2428 3.81541 23.8217L7.11303 20.5245H7.07969C5.43581 20.5245 4.23903 19.3636 4.01672 17.5606C3.76847 15.7577 3.81788 12.6704 4.01672 11.0279C4.25015 9.32373 5.51115 8.15179 7.07969 8.15179H9.21512L13.2908 4.81751C13.7861 4.39763 14.663 4.00122 15.341 3.99011C16.5761 3.99011 17.7124 4.85332 18.1199 6.23643C18.2805 6.81808 18.3435 7.39726 18.3929 7.9542L18.4905 8.74332C18.5065 8.86434 18.5213 8.98042 18.5337 9.10391L23.8235 3.81599ZM17.361 16.02C17.5277 15.857 17.9007 15.7396 18.0687 15.7829C18.522 15.8977 18.6109 16.5485 18.6035 17.0598C18.5825 18.5441 18.5331 19.5765 18.454 20.215L18.3985 20.7411L18.3975 20.7508C18.3447 21.28 18.29 21.8277 18.1329 22.4119C17.7216 23.7925 16.6187 24.6891 15.3651 24.6891C15.3244 24.6891 15.2836 24.6891 15.2416 24.6879C14.5488 24.6879 13.7978 24.2729 13.3717 23.9111L11.8538 22.7367C11.2771 22.3082 11.4475 21.624 11.7711 21.2276C12.0127 20.9325 14.917 18.2664 16.4424 16.8661C16.9597 16.3912 17.3185 16.0619 17.361 16.02Z"
        fill="white"
      />
    </>
  );
  return (
    <CreateHeart
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
