import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Stack, Typography, alpha } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import CloseIcon from "../../icons/CloseIcon";
import { useTheme } from "@emotion/react";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import useApi from "../../hooks/useApi";

export default function TermsDialog({
  openTncDialog,
  setOpenTncDialog,
  setAcceptTnc,
  updateRecord = false,
  verifyUserIdentity,
}) {
  const [
    scroll,
    // eslint-disable-next-line no-unused-vars
    setScroll,
  ] = React.useState("paper");
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { apiCall, isLoading } = useApi();
  const paragraphStyle = {
    mb: "25px",
    color: darkMode ? "white.1000" : "black.1000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    opacity: darkMode ? 0.7 : 1,
    fontFamily: "Poppins",
    lineHeight: "normal",
  };

  const headingStyle = {
    mb: { xs: "10px", md: "15px" },
    color: darkMode ? "white.1000" : "black.1000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  };
  const [scrolledToBottom, setScrolledToBottom] = React.useState(false); // State to track whether the user has scrolled to the bottom

  const handleClose = () => {
    setOpenTncDialog(false);
    setScrolledToBottom(false);
    return verifyUserIdentity();
  };

  const trackUserScroll = (e) => {
    let containerHeight = e.target.scrollTop + e.target.clientHeight;
    const isAtBottom = containerHeight >= e.target.scrollHeight - 10;
    if (isAtBottom) {
      setScrolledToBottom(true);
    } else {
      setScrolledToBottom(false);
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openTncDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openTncDialog]);

  // update terms and conditions in database
  async function acceptTermsAndConditions() {
    try {
      await apiCall({
        url: `v3/accounts`,
        method: "patch",
        data: { termsAndCondition: true },
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={openTncDialog}
        // onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "1047px",
            backgroundColor: darkMode ? "bgDark.third" : "wite.1000",
            borderRadius: { sm: "15px" },
            margin: { xs: "15px", sm: "32px" },
            maxHeight: { xs: "alc(100% - 15px)", sm: "calc(100% - 64px)" },
          },
          "& .MuiDialog-paperScrollBody": {
            maxWidth: "100%",
          },
        }}
      >
        <DialogTitle
          component={Stack}
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginInline: { xs: "15px", md: "30px" },
            padding: { xs: "20px 0 15px", sm: "20px 0 30px" },
          }}
          id="scroll-dialog-title"
        >
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "black.1000",
              fontFamily: "Poppins",
              fontSize: { xs: "20px", md: "24px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.408px",
            }}
          >
            Terms & Conditions
          </Typography>

          {!updateRecord && (
            <IconButton sx={{ p: 0 }} onClick={handleClose}>
              <CloseIcon color={darkMode ? "white" : "black"}></CloseIcon>
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          sx={{
            "&.MuiDialogContent-root": {
              marginInline: { xs: "15px", md: "30px" },
              p: 0,
              paddingRight: { xs: "0px", md: "20px" },
            },
          }}
          onScroll={trackUserScroll}
        >
          <DialogContentText
            component={"div"}
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography sx={paragraphStyle}>
              These Terms of Service (“Terms”) govern your use of the websites,
              mobile applications, and other online interfaces made available by
              Ploom Media Inc. (“Company”). These websites, mobile applications
              and other online interfaces are referred to in these Terms as the
              “Sites”). Your compliance with these Terms is a condition to your
              use of the Sites and by using the Sites, you agree to all of these
              Terms. If you do not agree to be bound by these Terms, please
              cease all use of the Sites and promptly exit them. You agree to
              fully cooperate with any request we make for evidence we deem
              necessary to verify your compliance with these Terms of Service.
            </Typography>

            <Typography sx={headingStyle}>Binding Arbitration</Typography>

            <Typography sx={paragraphStyle}>
              NOTE THAT THESE TERMS PROVIDE THAT ALL DISPUTES BETWEEN YOU AND
              COMPANY THAT IN ANY WAY RELATE TO THESE TERMS OR YOUR ACCESSING OR
              USE OF THE SITES WILL BE RESOLVED BY BINDING ARBITRATION.
              ACCORDINGLY, YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT OR TO
              A JURY TRIAL (INCLUDING IN CLASS ACTION PROCEEDINGS) TO ASSERT OR
              DEFEND YOUR RIGHTS UNDER THESE TERMS (EXCEPT FOR MATTERS THAT MAY
              BE TAKEN TO SMALL CLAIMS COURT). YOUR RIGHTS WILL BE DETERMINED BY
              A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY AND YOUR CLAIMS
              CANNOT BE BROUGHT AS A CLASS ACTION. PLEASE REVIEW THE SECTION
              BELOW ENTITLED DISPUTE RESOLUTION; ARBITRATION AGREEMENT FOR THE
              DETAILS REGARDING YOUR AGREEMENT TO ARBITRATE ANY DISPUTES WITH
              THE COMPANY.
            </Typography>

            <Typography sx={headingStyle}>1. Raises and Chipping</Typography>

            <Typography sx={paragraphStyle}>
              Through the Sites you will have the ability to create requests for
              donations from others for your use. These requests may be referred
              to as “a raise” on the Sites. You will also have the ability to
              respond to the “raises” of others by paying money towards their
              “raise”. These payments are known as “chipping.” You understand
              and agree that we reserve the right, at our sole discretion and
              without notice to you, to cancel any Rasies and to refuse or
              cancel any chipping for any reason. Your account may also be
              restricted or terminated for any reason, at our sole discretion.
              Some situations that may result in your raise or chipping being
              canceled include inaccuracies or errors in information provided by
              you or others, or problems identified through our credit and fraud
              avoidance procedures. We may also require additional verifications
              or information before accepting any raises or chipping from you or
              others. You agree that we may return any chipping amounts to those
              who paid them (the “chipper”), for any reason at our sole
              discretion. If a Raise or your chipping is canceled after your
              credit card (or other payment account) has been charged, we will
              issue a credit to your credit card (or other applicable payment
              account) that was charged in the amount of the charge.
            </Typography>

            <Typography sx={headingStyle}>2. Refunds</Typography>

            <Typography sx={paragraphStyle}>
              All transactions and payments made on the Sites, including
              Chipping, are final. Refunds may be issued in exceptional
              circumstances, at the sole discretion of the Company. The Company
              is not responsible for chipping donations and/or any other form of
              payment made on the Sites once such payments are withdrawn by the
              person making the raise. In this situation, all requests for
              refunds should be made directly to the person who made the raise.
            </Typography>

            <Typography sx={headingStyle}>
              3. Chippings You Make are NOT Tax Deductible
            </Typography>

            <Typography sx={paragraphStyle}>
              No chipping or other payments you make in connection with the
              Sites are tax deductible with any taxing authority.
            </Typography>

            <Typography sx={headingStyle}>
              4. Chippings You Receive May be Taxable.
            </Typography>

            <Typography sx={paragraphStyle}>
              Chippings or other payments you receive in connection with any
              raise you make may be taxable by applicable taxing authorities.
              You are responsible to determine whether any amounts you receive
              are taxable and any taxes due from receipt of such amounts is your
              sole responsibility. You agree to defend, indemnify and hold the
              Company harmless from any tax liability (including payment of all
              taxes, damages, losses, costs, attorney fees, expert fees and any
              other expenses incurred by the Company) that arises due to your
              receipt of chippings or other payment in connection with the
              Sites. You also agree that Company may withhold amounts from
              chippings payable to you for tax purposes if the Company believes
              in its sole discretion that it is advisable to make such
              withholdings.
            </Typography>

            <Typography sx={headingStyle}>5. Account Information</Typography>

            <Typography sx={paragraphStyle}>
              In order to create a Raise or participate in Chipping, you must
              create an account. You certify that all information you provide as
              part of your account is true. You understand that your account may
              be restricted or terminated for any reason, at our sole
              discretion. We will pass through to you the chippings made in
              connection with your rasie based on the information you provide in
              your account. We are not responsible for any misdirected payments
              due to inaccurate or incomplete information in your account.
            </Typography>

            <Typography sx={headingStyle}>6. Online Payments</Typography>

            <Typography sx={paragraphStyle}>
              For chippings or other products or services available on the
              Sites, we may accept credit and debit cards issued by U.S. banks.
              If a credit card account is being used for a transaction, Company
              may obtain pre approval for an amount up to the amount of the
              payment. If you want to designate a different credit card or if
              there is a change in your credit card, you must change your
              information in your account. This may temporarily delay your
              ability to make online payments while we verify your new payment
              information.
            </Typography>

            <Typography sx={{ mt: "20px", ...paragraphStyle }}>
              You represent and warrant that if you are making online payments
              that (i) any credit card, debit card and bank account information
              you supply is true, correct and complete, (ii) charges incurred by
              you will be honored by your credit/debit card company or bank,
              (iii) you will pay the charges incurred by you in the amounts
              posted, including any applicable taxes, and (iv) you are the
              person in whose name the card was issued and you are authorized to
              make a purchase or other transaction with the relevant credit card
              and credit card information.
            </Typography>

            <Typography sx={{ mt: "20px", ...paragraphStyle }}>
              When you pay for chippings using a credit card, you will have the
              option to cover the credit card processing fees with your payment.
              If you select this option, you authorize us to charge you a
              surcharge to cover the applicable credit card processing fee
              amounts in connection with your chippings. If you do not select
              this option, the credit card processing fees we incur will be
              deducted from the amount of your chipping.
            </Typography>

            <Typography sx={{ mt: "20px", ...paragraphStyle }}>
              We will pass through to you the chippings you make in connection
              with any raises based on the information provided in the account
              associated with the any raises. We are not responsible for any
              misdirected payments due to inaccurate or incomplete information
              provided by you or the person making the raise.
            </Typography>

            <Typography sx={{ mt: "20px", ...paragraphStyle }}>
              If you are not the beneficiary of any raises you organize, you
              agree to deliver funds to the ultimate beneficiary directly and as
              soon as possible.
            </Typography>

            <Typography sx={headingStyle}>
              7. Export Policy and Restrictions
            </Typography>

            <Typography sx={paragraphStyle}>
              You represent and warrant that in creating a raise or
              participating in chipping, you will not engage in any action that
              is a violation of any applicable laws. You also agree that raises
              or chippings will not be used for any unlawful purpose. You agree
              that you will not create any raises or provide to any person any
              chippings or other payment, for any person or entity who is
              forbidden from receiving any money or products under the Export
              Administration Regulations or any economic sanctions maintained by
              the U.S. Department of Treasury., U.S. anti-boycott regulations,
              or U.S. economic sanctions, including the export and anti-boycott
              restrictions found in the Export Administration Regulations or the
              sanctions regulations administered by the U.S. Office of Foreign
              Assets Control. You shall indemnify and hold harmless Company from
              all claims, demands, damages, costs, fines, penalties, attorneys’
              fees and all other expenses arising from your failure to comply
              with this provision and/or applicable export control,
              anti-boycott, or economic sanctions laws and regulations
            </Typography>

            <Typography sx={headingStyle}>8. Ownership of the Sites</Typography>

            <Typography sx={paragraphStyle}>
              All pages within this Sites and any apps or material made
              available for download are the property of the Company, or its
              licensors or suppliers, as applicable. The Sites are protected by
              United States and international copyright and trademark laws. The
              contents of the Sites, including without limitation all data,
              files, documents, text, photographs, images, audio, and video, and
              any materials accessed through or made available for use or
              download through the Sites (“Content”) may not be copied,
              distributed, modified, reproduced, published or used, in whole or
              in part, except for purposes authorized by these Terms or
              otherwise approved in writing by Company. You may not frame or
              utilize framing techniques to enclose, or deep link to, any name,
              trademarks, service marks, logo, Content or other proprietary
              information (including images, text, page layout, or form) of
              Company without our express written consent.
            </Typography>

            <Typography sx={headingStyle}>
              9. Sites Access, Security and Restrictions; Passwords
            </Typography>

            <Typography sx={paragraphStyle}>
              You are prohibited from violating or attempting to violate the
              security of the Sites, including, without limitation, (a)
              accessing data not intended for such user or logging onto a server
              or an account which the user is not authorized to access; or (b)
              attempting to probe, scan or test the vulnerability of a system or
              network or to breach security or authentication measures without
              proper authorization; or (c) accessing or using the Sites or any
              portion thereof without authorization, in violation of these Terms
              or in violation of applicable law.
            </Typography>

            <Typography sx={{ mt: "20px", ...paragraphStyle }}>
              You may not use any scraper, crawler, spider, robot or other
              automated means of any kind to access or copy data on the Sites,
              deep-link to any feature or content on the Sites, bypass our robot
              exclusion headers or other measures we may use to prevent or
              restrict access to the Sites.
            </Typography>

            <Typography sx={{ mt: "20px", ...paragraphStyle }}>
              Violations of system or network security may result in civil or
              criminal liability. Company will investigate occurrences that may
              involve such violations and may involve, and cooperate with, law
              enforcement authorities in prosecuting users who are involved in
              such violations. You agree not to use any device, software or
              routine to interfere or attempt to interfere with the proper
              working of the Sites or any activity being conducted on the Sites.
            </Typography>

            <Typography sx={{ mt: "20px", ...paragraphStyle }}>
              In the event access to the Sites or a portion thereof is limited
              requiring a user ID and password (“Protected Areas”), you agree to
              access Protected Areas using only your user ID and password as
              provided to you by Company. You agree to protect the
              confidentiality of your user ID and password, and not to share or
              disclose your user ID or password to any third party. You agree
              that you are fully responsible for all activity occurring under
              your user ID. Your access to the Sites may be revoked by Company
              at any time with or without cause. You agree to defend, indemnify
              and hold Company harmless from and against all claims, damages and
              expenses (including reasonable attorneys’ fees) against or
              incurred by Company arising out of your breach of these Terms or
              violation of applicable law, your use or access of the Sites, or
              access by anyone accessing the Sites using your user ID and
              password.
            </Typography>

            <Typography sx={headingStyle}>
              10. Accuracy of Information
            </Typography>

            <Typography sx={paragraphStyle}>
              Although Company attempts to ensure the integrity and accuracy of
              the Sites, it makes no representations, warranties or guarantees
              whatsoever as to the correctness or accuracy of the Sites and
              Content thereon. It is possible that the Sites could include
              typographical errors, inaccuracies or other errors, and that
              unauthorized additions, deletions and alterations could be made to
              the Sites by third parties. In the event that an inaccuracy
              arises, please inform the Company so that it can be corrected.
              Company reserves the right to unilaterally correct any
              inaccuracies on the Sites without notice. Information contained on
              the Sites may be changed or updated without notice. Additionally,
              Company shall have no responsibility or liability for information
              or Content posted to the Sites from any users or non-Company
              affiliated third parties.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonWithLoading
            variant="contained"
            isLoading={isLoading}
            sx={{
              cursor: "pointer",
              width: { xs: "100%", sm: "unset" },
              minWidth: { md: "407px" },
              paddingBlock: { xs: "8px", md: "14px" },
              mt: "12px",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
            disabled={!scrolledToBottom}
            onClick={async () => {
              !updateRecord && setAcceptTnc();
              updateRecord && (await acceptTermsAndConditions());
              handleClose();
            }}
          >
            Accept Terms & Conditions
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
