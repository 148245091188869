import { createSvgIcon } from "@mui/material";
import React from "react";

export default function SecurityIcon(props) {
  const CreateSecurityIcon = createSvgIcon(
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98844 17.0014C7.98844 17.0014 14.3818 15.0656 14.3818 9.72892C14.3818 4.39142 14.6134 3.97475 14.1009 3.46142C13.5876 2.94809 8.82677 1.28809 7.98844 1.28809C7.15011 1.28809 2.38927 2.94809 1.87677 3.46142C1.36344 3.97475 1.59511 4.39142 1.59511 9.72892C1.59511 15.0656 7.98844 17.0014 7.98844 17.0014Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M5.82422 8.89154L7.40089 10.4707L10.6492 7.2207"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  );
  return (
    <CreateSecurityIcon
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
