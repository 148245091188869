import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/store/useAuth";
import ProfileAvatar from "../../user/ProfileAvatar";
import useThemeStore from "../../../hooks/store/useThemeStore";
import moment from "moment";
import ThumbsUpIcon from "../../../icons/ThumbsUpIcon";
import useApi from "../../../hooks/useApi";
import { APIS } from "../../../api/lists";
import { useSnack } from "../../../hooks/store/useSnack";
import VerifiedIcon from "../../../icons/VerifiedIcon";
import { useAllModal } from "../../../hooks/store/useAllModal";

export default function SubCommentCard({ comment, feedId }) {
  const { userId: loggedInUserId, isLoggedIn } = useAuth();
  const profileLink = (userId) =>
    loggedInUserId === userId ? "/profile" : "/profile/" + userId;
  const { setSnack } = useSnack();
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const [likeCount, setLikeCount] = useState(comment.subCommentLikeCount || 0);
  const [currentUserLike, setCurrentUserLike] = useState(comment.loginUserLike);
  const { apiCall, isLoading: likeLoading } = useApi();
  const { setOpenKycDialog } = useAllModal();
  const likeComment = async (itemId, itemType) => {
    try {
      const res = await apiCall({
        url: APIS.LIKE.CREATE,
        method: "post",
        data: {
          itemType,
          itemId,
          userId: loggedInUserId,
          begId: feedId,
        },
      });
      setLikeCount(res?.data?.data?.likeCount || 0);
      setCurrentUserLike((prev) => !prev);
    } catch (error) {
      console.log("error in like the comment");
    }
  };

  const renderComment = (comment) => {
    // Regular expression to match URLs in the comment text
    const urlRegex =
      /((?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/\S*)?)/g;
    const commentWithLinks = comment.replace(urlRegex, (url) => {
      // Add http:// if the URL doesn't have http:// or https://
      if (!url.match(/^https?:\/\//i)) {
        return `<a href="http://${url}" target="_blank">${url}</a>`;
      }
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return (
      <Typography
        sx={{
          mt: { xs: "4px", sm: "6px" },
          mb: "10px",
          // textAlign: "justify",
          color: darkMode ? "white.800" : "black.350",
          opacity: { xs: darkMode ? 1 : 0.6, md: 0.6 },
          fontFamily: "Poppins",
          fontSize: { xs: "12px", md: "13px" },
          fontWeight: 400,
          lineHeight: { md: "19px" },
          overflowWrap: "anywhere",
          "&>a": {
            textDecoration: "none",
            color: darkMode ? "white.1000" : "primary.main",
            fontWeight: 600,
            opacity: 1,
          },
        }}
        dangerouslySetInnerHTML={{ __html: commentWithLinks }}
      />
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: { xs: "10px", sm: "15px" },
        mt: "15px",
      }}
    >
      <Box component={Link} to={profileLink(comment.userId)}>
        <ProfileAvatar
          sx={{
            backgroundColor: "primary.main",
            color: "white.1000",
            width: { xs: "32px", sm: "40px" },
            height: { xs: "32px", sm: "40px" },
          }}
          src={comment?.user?.profileImage}
          alt={comment?.user?.username}
        />
      </Box>
      <Box flexGrow={1}>
        <Stack
          display={"flex"}
          direction={"row"}
          alignItems={"center"}
          spacing={1}
        >
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "grey.550",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: 600,
              lineHeight: "16px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
            component={Link}
            to={profileLink(comment.userId)}
          >
            {comment.user.orgName
              ? comment.user.orgName
              : comment?.user?.firstName + " " + comment?.user?.lastName}
          </Typography>
          {comment?.user?.isKyc === 1 && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "7px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenKycDialog(true, comment?.user?.verifiedAt);
              }}
            >
              <VerifiedIcon id={"verifiedIcon124"}></VerifiedIcon>
            </Stack>
          )}
          <Typography
            sx={{
              color: darkMode ? "white.1000" : "grey.850",
              fontSize: { xs: "10px", sm: "14px" },
              fontWeight: 500,
              lineHeight: "16px",
              opacity: 0.5,
            }}
          >
            ({moment(comment.createdAt).fromNow()})
          </Typography>
        </Stack>
        {renderComment(comment.textDescription)}
        <Box display={"flex"}>
          <Box>
            <IconButton
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                fontSize: { xs: "12px", sm: "16px" },
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => {
                if (isLoggedIn) {
                  return likeComment(comment._id, "subcomment");
                } else {
                  return setSnack("Please login to like is comment", "warning");
                }
              }}
            >
              <ThumbsUpIcon
                color={
                  currentUserLike
                    ? "#e84251"
                    : darkMode
                    ? palette.white[1000]
                    : palette.black[1000]
                }
                mr={{ xs: "8px", sm: "12px" }}
                width="16px"
                height="16px"
              />{" "}
              {likeLoading ? (
                <CircularProgress size={14} color="inherit" />
              ) : (
                <>{likeCount || 0}</>
              )}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
