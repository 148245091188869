import { createSvgIcon } from "@mui/material";
export default function EmailIcon(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/Message">
        <g id="Message">
          <path
            id="Stroke 1"
            d="M17.9028 9.6322L13.4596 13.2452C12.6201 13.9112 11.4389 13.9112 10.5994 13.2452L6.11865 9.6322"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9089 21.781C19.9502 21.7894 22 19.2906 22 16.2194V9.35102C22 6.27983 19.9502 3.78101 16.9089 3.78101H7.09114C4.04979 3.78101 2 6.27983 2 9.35102V16.2194C2 19.2906 4.04979 21.7894 7.09114 21.781H16.9089Z"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateChatIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
