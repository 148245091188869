import {
  Box,
  Button,
  Fade,
  Slide,
  Stack,
  Tab,
  Typography,
  alpha,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React, { useCallback } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import GeneralNotificationItem from "./GeneralNotificationItem";
import RequestNotificationItem from "./RequestNotificationItem";
import { useAllModal } from "../../hooks/store/useAllModal";
import GeneralNotificationItemSkeleton from "./GeneralNotificationItemSkeleton";
import RequestNotificationItemSkeleton from "./RequestNotificationItemSkeleton";
import { useEffect } from "react";
import useApi from "../../hooks/useApi";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "@emotion/react";

const NotificationLayout = () => {
  const [value, setValue] = React.useState("1");
  const { showNotificationbar, setShowNotificationbar } = useAllModal();
  const bodyWidth = window.document.documentElement.clientWidth;
  const { apiCall } = useApi();
  const { palette } = useTheme();
  const [generalNotification, setGeneralNotification] = useState([]);
  const [totalGeneralPages, setTotalGeneralPages] = useState(1);
  const [currentGeneralPage, setCurrentGeneralPage] = useState(1);
  const [hasMoreGeneralItem, setHasMoreGeneralitem] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(totalGeneralPages + " " + currentGeneralPage);

  const fetchNotofications = useCallback(async () => {
    try {
      const res = await apiCall({
        url: `v3/users/get-notification`,
        method: "POST",
        params: { page: currentGeneralPage },
      });
      // console.log(res);

      if (res.data.data.results.length !== 0) {
        setGeneralNotification((prev) => {
          if (currentGeneralPage === 1) {
            return res.data.data.results;
          } else {
            return [...prev, ...res.data.data.results];
          }
        });
        setTotalGeneralPages(res.data.data.pagination.pages);
      } else {
        setHasMoreGeneralitem(false);
      }
    } catch (error) {
      console.log(error);
      // navigate("/404");
    }
  }, [apiCall, currentGeneralPage]);

  useEffect(() => {
    fetchNotofications();
  }, [fetchNotofications]);

  return (
    // full screen blur container
    <Fade in={showNotificationbar}>
      <Box
        position={"fixed"}
        top={"0%"}
        right={"0%"}
        backgroundColor={alpha(palette.black["1000"], 0.5)}
        minHeight={"100vh"}
        overflow={"auto"}
        width={"100%"}
        zIndex={"100"}
        id="scroll_component"
        onClick={() => {
          setShowNotificationbar(false);
        }}
      >
        {/* notificationbar */}
        <Slide
          in={showNotificationbar}
          direction={bodyWidth > 767 ? "left" : "up"}
        >
          <Box
            position={"absolute"}
            top={"0px"}
            right={"0px"}
            minHeight={"100%"}
            bgcolor={"white.500"}
            width={{ xs: "100%", sm: "90%", md: "450px" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* topbar */}
            <Stack
              padding={"12px 10px"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              bgcolor={"white.500"}
            >
              <Button
                sx={{
                  padding: "6px",
                  border: "none",
                  backgroundColor: "#ffffff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowNotificationbar(false);
                }}
              >
                <ArrowBackIosNewIcon sx={{ color: "black.1000" }} />
              </Button>
              <Typography
                sx={{ fontSize: "20px", fontWeight: "500", color: "black.200" }}
              >
                Notification
              </Typography>
              <Button
                variant="contained"
                sx={{ fontSize: "12px", textTransform: "unset" }}
              >
                How to?
              </Button>
            </Stack>

            {/* Tab index for genral and request notifications */}
            <Box
              sx={{
                width: "100%",
                typography: "body1",
                backgroundColor: "white.500",
              }}
              id="scrollable_Component"
            >
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    position: "sticky",
                    top: "0px",
                    backdropFilter: "blur(8px)",
                    zIndex: "100",
                  }}
                >
                  <TabList
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    onChange={handleChange}
                  >
                    <Tab
                      label="General"
                      sx={{ textTransform: "capitalize" }}
                      value="1"
                    />
                    <Tab
                      label="Request"
                      sx={{ textTransform: "capitalize" }}
                      value="2"
                    />
                  </TabList>
                </Box>
                <TabPanel
                  value="1"
                  sx={{ padding: { xs: "12px", md: "24px" } }}
                >
                  {/* message date with clear all notofication button */}
                  <Stack
                    direction={"row"}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      fontSize={"14px"}
                      color={"black.350"}
                      fontWeight={600}
                    >
                      Today
                    </Typography>
                    <Button
                      fontSize={"12px"}
                      sx={{
                        color: "black.350",
                        fontWeight: "400",
                        textTransform: "capitalize",
                      }}
                    >
                      Clear All
                    </Button>
                  </Stack>

                  {/* all request */}
                  <Stack
                    direction={"column"}
                    mt={"12px"}
                    gap={"15px"}
                    sx={{ marginBottom: "50px" }}
                  >
                    <InfiniteScroll
                      style={{ width: "100%" }}
                      scrollableTarget="scroll_component"
                      dataLength={generalNotification.length} //This is important field to render the next data
                      next={() => {
                        console.log("call");
                        if (currentGeneralPage < totalGeneralPages) {
                          setCurrentGeneralPage((prev) => prev + 1);
                        } else {
                          setHasMoreGeneralitem(false);
                        }
                      }}
                      hasMore={hasMoreGeneralItem}
                      loader={
                        <GeneralNotificationItemSkeleton></GeneralNotificationItemSkeleton>
                      }
                      endMessage={
                        <Typography
                          textAlign={"center"}
                          color={"primary.main"}
                          fontSize={{
                            xs: "14px",
                            sm: "14px",
                            md: "18px",
                          }}
                          mt={2}
                        >
                          {generalNotification.length === 0
                            ? "No Post Found"
                            : "you seen it all."}
                        </Typography>
                      }
                    >
                      {generalNotification.length > 0 &&
                        generalNotification.map((item) => (
                          <GeneralNotificationItem
                            item={item}
                            key={item._id}
                          ></GeneralNotificationItem>
                        ))}
                    </InfiniteScroll>
                  </Stack>
                </TabPanel>
                <TabPanel
                  value="2"
                  sx={{ padding: { xs: "12px", md: "24px" } }}
                >
                  <Stack
                    direction={"column"}
                    mt={"12px"}
                    gap={"18px"}
                    sx={{ marginBottom: "50px" }}
                  >
                    <RequestNotificationItemSkeleton></RequestNotificationItemSkeleton>
                    <RequestNotificationItem></RequestNotificationItem>
                    <RequestNotificationItem></RequestNotificationItem>
                    <RequestNotificationItem></RequestNotificationItem>
                    <RequestNotificationItem></RequestNotificationItem>
                    <RequestNotificationItem></RequestNotificationItem>
                    <RequestNotificationItem></RequestNotificationItem>
                    <RequestNotificationItem></RequestNotificationItem>
                  </Stack>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        </Slide>
      </Box>
    </Fade>
  );
};

export default NotificationLayout;
