import { createSvgIcon } from "@mui/material";

export default function CalendarIcon(props) {
  let CreateAddIcon = createSvgIcon(
    <g id="Iconly/Light/Calendar">
      <g id="Calendar">
        <path
          id="Line_200"
          d="M3.09265 9.90421H20.9166"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Line_201"
          d="M16.4421 13.8097H16.4514"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Line_202"
          d="M12.0046 13.8097H12.0139"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Line_203"
          d="M7.5579 13.8097H7.56717"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Line_204"
          d="M16.4421 17.6962H16.4514"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Line_205"
          d="M12.0046 17.6962H12.0139"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Line_206"
          d="M7.5579 17.6962H7.56717"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Line_207"
          d="M16.0437 2.5V5.79078"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Line_208"
          d="M7.96552 2.5V5.79078"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          id="Path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2383 4.0791H7.77096C4.83427 4.0791 3 5.71504 3 8.72213V17.7718C3 20.8261 4.83427 22.4999 7.77096 22.4999H16.229C19.175 22.4999 21 20.8545 21 17.8474V8.72213C21.0092 5.71504 19.1842 4.0791 16.2383 4.0791Z"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </g>
  );
  return (
    <CreateAddIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
      }}
      // {...props}
    />
  );
}
