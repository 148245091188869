import React, { useEffect, useState } from "react";
import { Box, Stack, alpha } from "@mui/material";
import DragAndDropInput from "./DragAndDropInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../../component/ui/form/Input";
import useApi from "../../hooks/useApi";
import axios from "axios";
import ButtonWithLoading from "../../component/ui/button/ButtonWithLoading";
import { useAuth } from "../../hooks/store/useAuth";
import { useSnack } from "../../hooks/store/useSnack";
import DynamicMeta from "../../utils/DynamicMeta";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { APIS } from "../../api/lists";
import { useNavigate } from "react-router-dom";
import FileUploadingDialog from "../ui/FileUploadingDialog";
import ROUTE_LIST from "../../Routes/list";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSettings } from "../../hooks/store/useSettings";
import { isPast } from "../../utils/time";

export const EditRaiseForm = ({ begDetails }) => {
  const { darkMode } = useThemeStore();
  const { userId, isLoggedIn } = useAuth();
  const { palette } = useTheme();
  const { apiCall } = useApi();
  const { apiCall: updateApiCall, isLoading } = useApi();
  const { setSnack } = useSnack();
  const [uploadinProgress, setUploadinProgress] = useState(false);
  const { settings } = useSettings();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [UploadLimit, setUploadLimit] = useState(10 - begDetails.videos.length);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState([]); //store index of current uploading file when error in comes
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [awsUploading, setAwsUploading] = useState(true); // 1.uploading 2.creating 3.error
  const [finalVideo, setFinalVideo] = useState(undefined);
  const [postDescription, setPostDescription] = useState(
    begDetails.textDescription
      ? begDetails.textDescription
      : begDetails.htmlDescription
      ? begDetails.htmlDescription
      : undefined
  );
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState(undefined);
  const [goalDate, setGoalDate] = useState(undefined);
  const [file, setFile] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(
    begDetails.daysRemaining >= 0 &&
      begDetails.status !== "ended" &&
      begDetails.endedDate === undefined
      ? false
      : true
  );
  // yup data validator schhema
  const schema = Yup.object({
    title: Yup.string()
      .required("Title is required.")
      .trim()
      .min(3)
      .max(
        settings?.raiseTitleLimit,
        `Title must be at most ${settings?.raiseTitleLimit} characters.`
      ),
    postDescription: Yup.string()
      .trim()
      .max(
        settings?.raiseDescriptionLimit,
        `Description must be at most ${settings?.raiseDescriptionLimit} characters.`
      ),
    file: Yup.array()
      .optional()
      .min(0)
      .max(
        UploadLimit,
        `Sorry, you can only upload up to ${UploadLimit} files at a time`
      )
      .test(
        "videos-size-validation",
        "Video is too big, please upload a video less than 100mb.",
        function (value) {
          if (value) {
            let videoSizeError = false;
            Array.from(value).forEach((element) => {
              if (String(element.file.type).slice(0, 5) === "video") {
                if (element.file.size / 1048576 > 100) {
                  videoSizeError = true;
                }
              }
            });
            if (videoSizeError) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      ),
  });

  const RaiseSchema = Yup.object({
    title: Yup.string()
      .required("Title is required.")
      .trim()
      .min(3)
      .max(
        settings?.raiseTitleLimit,
        `Title must be at most ${settings?.raiseTitleLimit} characters.`
      ),
    postDescription: Yup.string()
      .trim()
      .max(
        settings?.raiseDescriptionLimit,
        `Description must be at most ${settings?.raiseDescriptionLimit} characters.`
      ),
    file: Yup.array()
      .optional()
      .min(0)
      .max(
        UploadLimit,
        `Sorry, you can only upload up to ${UploadLimit} files at a time`
      )
      .test(
        "videos-size-validation",
        "Video is too big, please upload a video less than 100mb.",
        function (value) {
          if (value) {
            let videoSizeError = false;
            Array.from(value).forEach((element) => {
              if (String(element.file.type).slice(0, 5) === "video") {
                if (element.file.size / 1048576 > 100) {
                  videoSizeError = true;
                }
              }
            });
            if (videoSizeError) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      ),
    amount: Yup.number()
      .typeError("Amount is required")
      .min(
        begDetails.goalAmount,
        `Updated goal amount should be more than previous goal amount. Previous goal amount is $${begDetails.goalAmount}`
      ),
    goalDate: Yup.object()
      .typeError("required")
      .test(
        "is moment object",
        "provided date is not valid",
        (value) => value?._isAMomentObject || false
      )
      .test("min-date", "Please select a date after today.", (value) =>
        moment(value).isAfter(moment().add(0, "days"))
      )
      .test(
        "max-date",
        "Please choose a date that is within 6 month from the raise creation date.",
        (value) =>
          moment(value).isBefore(moment(begDetails.createdAt).add(6, "months"))
      ),
  });

  // effect function for prevent unauthorised access
  useEffect(() => {
    if (userId !== begDetails.userId || !isLoggedIn) {
      navigate("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //prevent tab close
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (uploadinProgress) {
        event.preventDefault();
        event.returnValue =
          "Are you sure you want to leave? Your upload is in progress.";
      }
    };

    const handlePopState = () => {
      if (
        uploadinProgress &&
        window.confirm(
          "Your upload is in progress. Are you sure you want to leave?"
        )
      ) {
        window.history.back();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [uploadinProgress]);

  // code for upload files in aws
  const uploadFileInAWS = async (files) => {
    setUploadinProgress(true);
    setTotalFiles(files);
    setOpenDialog(true);
    setUploadError([]);
    const videoArray = [...uploadedVideos];
    try {
      for (let i = videoArray.length; i < files.length; i++) {
        try {
          setCurrentFileIndex(i);
          setUploadProgress(0);
          let res;
          const unique = Date.now() + Math.ceil(Math.random() * 1e9).toString(),
            isVideo = files[i].file.type.includes("video");
          res = await apiCall({
            url: isVideo
              ? "v3/get-signed-url"
              : "v3/get-signed-url/begs/" + unique + ".jpeg",
          });
          if (res.status === 200) {
            res = res.data.data;
            await axios({
              method: "put",
              url: res.url,
              headers: {
                "Content-Type": files[i].file.type,
              },
              onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
              data: files[i].file,
            });
            videoArray.push({
              videoLink: isVideo ? res.uuid : unique,
              thumbLink: isVideo ? res.uuid : unique,
              type: isVideo ? "video" : "image",
            });
            setUploadedVideos(videoArray);
          } else {
            throw Error("link can not get");
          }
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (index === i) {
                video.uploaded = true;
              }
              return video;
            })
          );
        } catch (error) {
          setUploadError((prevError) => [...prevError, i]);
          setTotalFiles((prevVideo) =>
            prevVideo.map((video, index) => {
              if (!video.uploadError) {
                video.uploadError = index === i;
              }
              return video;
            })
          );
          console.log("error");
          continue;
        }
      }
      setAwsUploading(false);
    } catch (error) {
      setAwsUploading(false);
      throw error;
    }
    setFinalVideo(videoArray);
    return videoArray;
    // files.map((file) => {
    //   console.log(file.file);
    //   return file;
    // });
  };

  // code for update post
  const updatePost = async () => {
    try {
      console.log("call");
      //general validation
      if (disabled && (!file || file.length === 0)) {
        return setSnack("Everything is same as before", "warning");
      }

      // check new and old value of story first
      if (
        begDetails.begType !== 1 &&
        (!file || file.length === 0) &&
        postDescription === begDetails.textDescription &&
        title === begDetails.title
      ) {
        return setSnack("Everything is same as before", "warning");
      }

      // check new and old value of raise first
      if (
        begDetails.begType === 1 &&
        (!file || file.length === 0) &&
        postDescription === begDetails.textDescription &&
        title === begDetails.title &&
        moment(goalDate).format("l") === moment(begDetails.goalDate).format("l")
      ) {
        if (
          amount === undefined ||
          Number(amount) === Number(begDetails.goalAmount)
        ) {
          return setSnack("Everything is same as before", "warning");
        }
      }

      if (file && finalVideo === undefined) {
        await uploadFileInAWS(file);
        return;
      }
      setAwsUploading(false);
      const data = {
        title: title,
        textDescription: postDescription,
        htmlDescription: postDescription,
        goalAmount:
          begDetails.begType === 1 &&
          !disabled &&
          Number(amount) !== Number(begDetails.goalAmount)
            ? amount
            : undefined,
        goalDate:
          begDetails.begType === 1 &&
          !disabled &&
          moment(goalDate).format("l") !==
            moment(begDetails.goalDate).format("l")
            ? moment(goalDate).format("l")
            : undefined,
        links: finalVideo,
      };
      const res = await updateApiCall({
        url: APIS.FEED.UPDATE_BEG(begDetails._id),
        data,
        method: "patch",
      });
      setUploadinProgress(false);
      setOpenDialog(false);
      if (res.status === 201) {
        setSnack(res.data.message, "success");
        navigate(ROUTE_LIST.RAISE_DASHBOARD.redirect(begDetails._id));
      }
    } catch (error) {
      console.log(error);
      console.log(error);
      setUploadinProgress(false);
      setSnack(error?.response?.data?.message, "error");
    }
  };

  const { register, handleSubmit, formState, setValue, watch } = useForm({
    resolver: yupResolver(
      !disabled && begDetails.begType === 1 ? RaiseSchema : schema
    ),
    mode: "onChange",
    defaultValues: {
      title: begDetails?.title,
      postDescription: begDetails?.textDescription,
      goalDate:
        begDetails.goalDate && !disabled
          ? moment(begDetails.goalDate)
          : undefined,
      goalAmount: begDetails.goalAmount ? begDetails.goalAmount : undefined,
    },
  });

  const postDescriptionFormValue = watch("postDescription");
  const postTitleFormValue = watch("title");
  const postAmount = watch("amount");
  const postGoalDate = watch("goalDate");
  const postFile = watch("file");
  useEffect(() => {
    if (postDescriptionFormValue) {
      setPostDescription(postDescriptionFormValue);
    }
  }, [postDescriptionFormValue]);

  useEffect(() => {
    if (postTitleFormValue) {
      setTitle(postTitleFormValue);
    }
  }, [postTitleFormValue]);

  useEffect(() => {
    if (postAmount) {
      setAmount(postAmount);
    }
  }, [postAmount]);

  useEffect(() => {
    if (postGoalDate) {
      setGoalDate(postGoalDate);
    }
  }, [postGoalDate]);

  useEffect(() => {
    if (postFile) {
      setFile(postFile);
    }
  }, [postFile]);
  return (
    <>
      <DynamicMeta
        title="Create Post | Ploom Social"
        description="Creative
Are you a creative powerhouse on social media platforms? Ploom Social offers you the opportunity to lead your followers on an enriching journey that begins and ends with you as the content creator. No more tedious partnerships or complicated strategies to make money."
      />
      <Box
        sx={{
          position: "relative",
          backgroundColor: {
            xs: darkMode ? "bgDark.third" : "white.1000",
            md: "unset",
          },
          width: "100%",
          marginInline: "auto",
          zIndex: 50,
          borderRadius: { xs: "10px", lg: "unset" },
        }}
      >
        {/* form */}
        <Stack
          onSubmit={handleSubmit((values) => updatePost(values))}
          component={"form"}
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" },
            gridTemplateRows: { xs: "1fr", lg: "1fr" },
            gridTemplateAreas: {
              xs: `"dragAndDrop"
              "oldVideo" 
              "form"`,
              lg: `"dragAndDrop form"
              "oldVideo oldVideo"`,
            },
            columnGap: { md: "20px" },
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            borderRadius: "15px",
          }}
        >
          {/* drag and drop input container with old video  */}
          <Stack sx={{ gridArea: "dragAndDrop" }}>
            <DragAndDropInput
              UploadLimit={UploadLimit}
              setUploadLimit={setUploadLimit}
              register={register}
              errors={formState.errors}
              beg={begDetails}
              onChange={(value) => {
                setValue("file", value);
                setFile(value);
              }}
            />
          </Stack>

          {/* all input boxes */}
          <Stack sx={{ gridArea: "form" }}>
            <Input
              name={"title"}
              inputLabel={"Title"}
              register={register}
              errors={formState.errors}
              placeholder="Example title"
              defaultValue={begDetails?.title}
              countDown={true}
              maxLength={settings.raiseTitleLimit}
              targetValue={title}
              onChange={(event) => {
                setTitle(
                  String(event.target.value).slice(
                    0,
                    settings.raiseTitleLimit
                  ) || ""
                );
              }}
              onBlur={(event) => {
                setTitle(event.target.value?.trim() || "");
              }}
              value={title}
              disabled={disabled}
            />
            <Input
              name={"postDescription"}
              inputLabel={"Description"}
              placeholder="Add details about your campaign, your goal, and why you started this campaign."
              multiline
              fullWidth
              rows={5}
              defaultValue={begDetails?.htmlDescription}
              disabled={disabled}
              sx={{
                mt: "10px",
                "& fieldset": {
                  border: "1px solid",
                  borderColor: darkMode ? "neutral.650" : "white.400",
                  borderRadius: "10px",
                },
                "& textarea::placeholder": {
                  color: darkMode ? "white.1000" : "black.1000",
                },
                "& textarea,textarea:is(:hover,:focus)": {
                  border: "none",
                  zIndex: 2,
                  color: darkMode ? "white.1000" : "black.1000",
                  opacity: 1,
                  mb: "20px",
                },
                "& .MuiOutlinedInput-input.Mui-disabled": {
                  textFillColor: darkMode
                    ? `${palette.white[1000]}`
                    : `${palette.black[1000]}`,
                },
              }}
              register={register}
              errors={formState.errors}
              onChange={(event) => {
                setPostDescription(
                  String(event.target.value).slice(
                    0,
                    settings.raiseDescriptionLimit
                  ) || ""
                );
              }}
              onBlur={(event) => {
                setPostDescription(event.target.value?.trim() || "");
              }}
              value={postDescription}
              countDown={true}
              maxLength={settings.raiseDescriptionLimit}
              targetValue={postDescription}
              countDownSx={{
                position: "absolute",
                bottom: "20px",
                right: "9px",
              }}
            />

            {begDetails.begType === 1 && (
              <Input
                inputLabel={"Goal"}
                type="number"
                placeholder="Min goal amount is $50"
                fullWidth
                defaultValue={begDetails.goalAmount}
                name={"amount"}
                register={register}
                errors={formState.errors}
                disabled={
                  disabled ||
                  begDetails.goalAmountUpdateTry >=
                    settings.goalAmountUpdateLimit
                }
                onChange={(event) => {
                  setAmount(event.target.value);
                }}
              />
            )}

            {begDetails.begType === 1 && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Fundraiser end date"
                    defaultValue={moment(begDetails.goalDate)}
                    minDate={moment().add(1, "days")}
                    maxDate={
                      isPast(
                        moment().add(1, "month"),
                        moment(begDetails.createdAt).add(6, "month")
                      )
                        ? moment().add(1, "month")
                        : moment(begDetails.createdAt).add(6, "month")
                    }
                    disabled={disabled}
                    sx={{
                      mt: "20px",
                      width: "100%",
                      "& .MuiSvgIcon-root": {
                        color: darkMode ? "white.1000" : "black.350",
                      },
                      "& label.Mui-focused": {
                        color: darkMode ? "white.1000" : "primary.main",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: darkMode ? "white.1000" : "black.1000",
                        },

                        "& .Mui-disabled": {
                          cursor: "no-drop",
                          color: darkMode ? "white.1000" : "primary.main",
                          opacity: 0.6,
                        },
                      },
                      "input:placeholder-shown": { border: "none" },
                      "input:focus": { border: "none" },
                      "input:hover": { border: "none" },
                      "& label": {
                        color: darkMode ? "white.1000" : "primary.main",
                        fontSize: "16px",
                        fontWeight: 500,
                        fontStyle: "normal",
                        lineHeight: "normal",
                        textTransform: "capitalize",
                      },
                      "& .MuiFormLabel-root.Mui-disabled": {
                        color: darkMode ? "white.1000" : "primary.main",
                        opacity: 0.6,
                      },
                      "& input,input:is(:hover,:focus)": {
                        border: "none",
                        zIndex: 2,
                        color: darkMode ? "white.1000" : "black.1000",
                        opacity: 1,
                      },
                      "& fieldset": {
                        borderRadius: "10px",
                        border: "1px solid",
                        borderColor: darkMode ? "neutral.650" : "neutral.150",
                        paddingTop: "15px",
                        paddingBottom: "13px",
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: darkMode
                          ? `${palette.white[1000]}`
                          : `${palette.black[1000]}`,
                        cursor: "no-drop",
                      },

                      "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: darkMode
                          ? `${palette.neutral[650]} !important`
                          : `${palette.neutral[150]} !important`,
                      },
                    }}
                    onChange={(date) => {
                      setValue("goalDate", date);
                      setGoalDate(date);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(formState.errors.goalDate),
                        helperText: formState.errors.goalDate?.message || "",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
            <ButtonWithLoading
              color="primary"
              disabled={formState.isSubmitting}
              isLoading={formState.isSubmitting}
              size="13px"
              type="submit"
              variant="contained"
              sx={{
                background: "prmiary.main",
                mr: "0",
                alignSelf: "end",
                width: { xs: "100%", lg: "184px" },
                display: "flex",
                paddingBlock: "15px",
                borderRadius: "10px",
                textTransform: "capitalize",
                mt: { xs: "20px", md: "30px" },
                "&.Mui-disabled": {
                  color: alpha(palette.white["1000"], 0.5),
                },
              }}
            >
              Update Raise
            </ButtonWithLoading>
          </Stack>

          {/* already uploaded media files container */}
          {/* <Box sx={{ gridArea: "oldVideo" }}>
            <CurrentMedia
              allFilesUrl={begDetails.videos}
              begId={begDetails._id}
              setUploadLimit={setUploadLimit}
            />
          </Box> */}
        </Stack>
      </Box>
      <FileUploadingDialog
        openDialog={openDialog}
        totalFiles={totalFiles}
        currentFileIndex={currentFileIndex}
        message={`Hold on a moment, we are updating your ${
          begDetails.begType === 1 ? "raise" : "story."
        }`}
        uploadProgress={uploadProgress}
        isLoading={isLoading}
        setCurrentFileIndex={setCurrentFileIndex}
        setFinalVideo={setFinalVideo}
        setTotalFiles={setTotalFiles}
        setUploadProgress={setUploadProgress}
        createPost={updatePost}
        uploadError={uploadError}
        title={`Please don’t close the tabs while uploading files, Otherwise System won’t be able to update a ${
          begDetails.begType === 1 ? "raise" : "story."
        }`}
        awsUploading={awsUploading}
      ></FileUploadingDialog>
    </>
  );
};
