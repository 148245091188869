import { createSvgIcon } from "@mui/material";

export default function EditIcon(props) {
  let CreateEditIcon = createSvgIcon(
    <>
      <g id="Iconly/Bold/Delete">
        <g id="Delete">
          <path
            id="Delete_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2153 3.93223C15.5071 3.93223 15.75 4.17447 15.75 4.48272V4.76772C15.75 5.06846 15.5071 5.31821 15.2153 5.31821H2.78539C2.49289 5.31821 2.25 5.06846 2.25 4.76772V4.48272C2.25 4.17447 2.49289 3.93223 2.78539 3.93223H4.97217C5.41639 3.93223 5.80298 3.61648 5.90291 3.17098L6.01743 2.65949C6.1954 1.96275 6.78112 1.5 7.45145 1.5H10.5485C11.2116 1.5 11.8039 1.96275 11.9753 2.62274L12.0978 3.17023C12.197 3.61648 12.5836 3.93223 13.0286 3.93223H15.2153ZM14.1047 14.3511C14.333 12.2234 14.7327 7.16843 14.7327 7.11743C14.7473 6.96293 14.697 6.81668 14.597 6.69894C14.4898 6.58869 14.3542 6.52344 14.2046 6.52344H3.80172C3.65146 6.52344 3.50849 6.58869 3.40929 6.69894C3.30863 6.81668 3.25903 6.96293 3.26633 7.11743C3.26767 7.1268 3.28201 7.30486 3.30599 7.60255C3.41251 8.92499 3.7092 12.6082 3.90092 14.3511C4.03659 15.6351 4.87906 16.4421 6.09937 16.4713C7.04105 16.4931 8.01117 16.5006 9.00317 16.5006C9.93755 16.5006 10.8865 16.4931 11.8574 16.4713C13.12 16.4496 13.9617 15.6568 14.1047 14.3511Z"
            fill="white"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateEditIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
