import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const CrerateCrossTransfer = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.75226 0.499512H11.2548C13.7973 0.499512 15.4998 2.28451 15.4998 4.93951V11.0678C15.4998 13.7153 13.7973 15.4995 11.2548 15.4995H4.75226C2.20976 15.4995 0.499756 13.7153 0.499756 11.0678V4.93951C0.499756 2.28451 2.20976 0.499512 4.75226 0.499512ZM10.2565 10.2495C10.5115 9.99526 10.5115 9.58276 10.2565 9.32776L8.92147 7.99276L10.2565 6.65701C10.5115 6.40276 10.5115 5.98276 10.2565 5.72776C10.0015 5.47201 9.58897 5.47201 9.32647 5.72776L7.99897 7.06201L6.66397 5.72776C6.40147 5.47201 5.98897 5.47201 5.73397 5.72776C5.47897 5.98276 5.47897 6.40276 5.73397 6.65701L7.06897 7.99276L5.73397 9.32026C5.47897 9.58276 5.47897 9.99526 5.73397 10.2495C5.86147 10.377 6.03397 10.4453 6.19897 10.4453C6.37147 10.4453 6.53647 10.377 6.66397 10.2495L7.99897 8.92276L9.33397 10.2495C9.46147 10.3853 9.62647 10.4453 9.79147 10.4453C9.96397 10.4453 10.129 10.377 10.2565 10.2495Z"
    fill="white"
  />
);
export default function CrossTransparentIcon(props) {
  return (
    <CrerateCrossTransfer
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
