import { createSvgIcon } from "@mui/material";

export default function SendIcon(props) {
  let color = props.color;
  const CreateSendIcon = createSvgIcon(
    <>
      <path
        d="M14.7848 1.71888C14.4515 1.37821 13.9582 1.25221 13.4982 1.38554L2.7715 4.48488C2.28617 4.61954 1.94217 5.00421 1.8495 5.49221C1.75483 5.98954 2.0855 6.62154 2.5175 6.88554L5.8715 8.93354C6.2155 9.14421 6.6595 9.09154 6.94417 8.80621L10.7848 4.96554C10.9782 4.76488 11.2982 4.76488 11.4915 4.96554C11.6848 5.15821 11.6848 5.47221 11.4915 5.67221L7.64417 9.51288C7.35883 9.79821 7.3055 10.2409 7.5155 10.5855L9.56483 13.9522C9.80483 14.3515 10.2182 14.5789 10.6715 14.5789C10.7248 14.5789 10.7848 14.5789 10.8382 14.5715C11.3582 14.5055 11.7715 14.1515 11.9248 13.6515L15.1048 3.00554C15.2448 2.55221 15.1182 2.05888 14.7848 1.71888Z"
        fill={color}
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50752 11.2053C2.37952 11.2053 2.25152 11.1566 2.15419 11.0586C1.95886 10.8633 1.95886 10.5473 2.15419 10.3519L3.06419 9.44126C3.25952 9.24659 3.57619 9.24659 3.77152 9.44126C3.96619 9.63659 3.96619 9.95326 3.77152 10.1486L2.86086 11.0586C2.76352 11.1566 2.63552 11.2053 2.50752 11.2053ZM5.01534 12.0002C4.88734 12.0002 4.75934 11.9515 4.662 11.8535C4.46667 11.6582 4.46667 11.3422 4.662 11.1468L5.572 10.2362C5.76734 10.0415 6.084 10.0415 6.27934 10.2362C6.474 10.4315 6.474 10.7482 6.27934 10.9435L5.36867 11.8535C5.27134 11.9515 5.14334 12.0002 5.01534 12.0002ZM5.18284 14.3789C5.28017 14.4769 5.40817 14.5256 5.53617 14.5256C5.66417 14.5256 5.79217 14.4769 5.8895 14.3789L6.80017 13.4689C6.99484 13.2736 6.99484 12.9569 6.80017 12.7616C6.60484 12.5669 6.28817 12.5669 6.09284 12.7616L5.18284 13.6722C4.9875 13.8676 4.9875 14.1836 5.18284 14.3789Z"
        fill={color}
      />
    </>
  );
  return (
    <CreateSendIcon
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
