import { Box, Button, Stack, Typography, alpha } from "@mui/material";
import React, { useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import HandIcon from "../../icons/HandIcon";
import SendIcon from "../../icons/SendIcon";
import { useAllModal } from "../../hooks/store/useAllModal";

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "few seconds ago",
    ss: "%ss",
    m: "a minute ago",
    mm: "%d minute ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "a Day",
    dd: "%dd",
    M: "a month ago",
    MM: "%d month ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});
const NotificationItemEndRaise = ({ data, markAllAsRead = false, close }) => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const [isRead, setIsRead] = useState(data.isRead ? data.isRead : false);
  const { setShowShareDrawer } = useAllModal();
  const navigateUrl = `/campaign-dashboard/${data?.begId}`;

  function markAsRead() {
    setIsRead(true);
    close(true);
    apiCall({ url: APIS.NOTIFICATION.READ(data?._id), method: "PATCH" });
  }

  // share raise
  function getShareLink() {
    if (!isRead) {
      markAsRead();
    }
    setShowShareDrawer(
      true,
      data.data._id,
      data.data?.title,
      data.data?.thumbLink,
      false,
      data.data?.description || "",
      data.data.user,
      {
        goalAmount: data.data?.goalAmount,
        amountRaised: data.data?.amountRaised,
      },
      data.data.shareLink
    );
  }
  return (
    <Box
      sx={{
        mt: "10px",
        padding: "10px",
        border: isRead || markAllAsRead ? "1px solid" : "none",
        borderColor: darkMode ? "black.250" : "bgWhite.secondary",
        borderRadius: "10px",
        backgroundColor:
          isRead || markAllAsRead
            ? "transparent"
            : darkMode
            ? alpha(palette.grey[650], 0.1)
            : alpha(palette.primary.main, 0.1),
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Stack
            sx={{
              display: "grid",
              placeItems: "center",
              width: 44,
              height: 44,
              borderRadius: "50%",
              background: palette.gradients.main,
            }}
          >
            <HandIcon sx={{ color: "white.1000" }} />
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
            >
              End Raise
            </Typography>
            <Typography
              sx={{
                color: darkMode
                  ? "primary.disabledLight"
                  : "primary.disabledLight",
                fontFamily: "Poppins",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              {data?.sender?.username}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Typography
            sx={{
              color: darkMode
                ? "primary.disabledLight"
                : "primary.disabledLight",
              fontFamily: "Poppins",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              alignSelf: "baseline",
            }}
          >
            {moment(data.createdAt).fromNow()}
          </Typography>
          {!(isRead || markAllAsRead) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "11px",
                height: "11px",
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "50%",
              }}
            >
              <Box
                sx={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "primary.main",
                  borderRadius: "50%",
                }}
              ></Box>
            </Box>
          )}
        </Stack>
      </Box>
      {data?.message?.split("\\n")?.map((message, index) => (
        <Typography
          key={index}
          sx={{
            mt: "6px",
            maxWidth: "500px",
            color: darkMode ? alpha(palette.white[1000], 0.6) : "black.350",
            fontFamily: "Poppins",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "15px",
          }}
        >
          {message}
        </Typography>
      ))}

      <Stack
        sx={{
          mt: "15px",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          justifyContent: "end",
        }}
      >
        <Button
          sx={{
            width: { xs: "100%", sm: "unset" },
            background: palette.gradients.main,
            color: "white.1000",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            padding: { xs: "10px 15px", sm: "8px 15px" },
            borderRadius: "10px",
          }}
          onClick={() => {
            if (!isRead) {
              markAsRead();
            }
            navigate(navigateUrl);
          }}
        >
          Go To Raise
        </Button>
        <Button
          variant="contained"
          startIcon={<SendIcon />}
          sx={{
            width: { xs: "100%", sm: "unset" },
            background: palette.gradients.main,
            color: "white.1000",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            padding: { xs: "10px 15px", sm: "8px 15px" },
            borderRadius: "10px",
          }}
          onClick={() => getShareLink()}
        >
          Share
        </Button>
      </Stack>
    </Box>
  );
};

export default NotificationItemEndRaise;
