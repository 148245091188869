import { createSvgIcon } from "@mui/material";
export default function VerifyEmailIcon(props) {
  const CreateChatIcon = createSvgIcon(
    <>
      <path
        d="M210.645 69.2479L210.172 68.6707C210.305 68.5671 223.64 57.4671 242.924 51.8283C260.729 46.6187 287.398 45.1535 313.298 67.6791L312.81 68.2415C287.176 45.9527 260.788 47.3883 243.146 52.5387C224.025 58.1183 210.779 69.1443 210.645 69.2479Z"
        fill="#F6F6F6"
      />
      <path
        d="M416.38 213.15C413.598 183.506 402.098 161.01 372.335 144.508C363.559 139.638 352.562 137.256 347.294 128.716C342.069 120.221 344.008 109.387 342.972 99.4712C340.323 74.0448 316.006 54.3756 290.58 51.682C265.154 48.9884 239.86 60.5028 220.532 77.2712C206.042 89.8364 193.344 105.894 175.081 111.77C144.978 121.494 112.21 99.8264 81.2933 106.62C64.6729 110.275 50.7165 121.923 40.5341 135.554C23.4845 158.39 19.2369 187.25 24.4465 215.266C27.3029 230.584 34.2737 246.11 44.2785 258.038L402.601 257.298C413.08 246.82 417.564 225.863 416.38 213.15Z"
        fill="#F7F7F7"
      />
      <path
        d="M187.335 71.2181C187.335 86.5065 174.932 98.8941 159.644 98.8941C144.356 98.8941 131.953 86.5065 131.953 71.2181C131.953 55.9149 144.356 43.5273 159.644 43.5273C174.932 43.5273 187.335 55.9149 187.335 71.2181Z"
        fill="#F7F7F7"
      />
      <path
        d="M217.268 46.5169C217.268 52.8513 212.133 57.9869 205.798 57.9869C199.464 57.9869 194.328 52.8513 194.328 46.5169C194.328 40.1825 199.464 35.0469 205.798 35.0469C212.133 35.0469 217.268 40.1825 217.268 46.5169Z"
        fill="#F7F7F7"
      />
      <path
        d="M392.885 126.583C392.885 132.918 387.749 138.053 381.4 138.053C375.065 138.053 369.93 132.918 369.93 126.583C369.93 120.249 375.065 115.113 381.4 115.113C387.749 115.113 392.885 120.249 392.885 126.583Z"
        fill="#F7F7F7"
      />
      <path
        d="M414.664 257.712L413.998 257.372C427.451 231.99 428.886 204.728 417.905 182.543C406.361 159.218 382.814 143.53 351.586 138.365L351.719 137.625C383.184 142.835 406.923 158.671 418.571 182.217C429.656 204.61 428.235 232.138 414.664 257.712Z"
        fill="#F6F6F6"
      />
      <path
        d="M46.3824 258.792C30.1616 241.328 19.4612 220.312 15.4356 197.979C11.6912 177.199 14.1184 156.568 22.2584 139.903C34.8532 114.166 59.214 100.905 89.0952 103.51L89.0212 104.25C59.4656 101.675 35.3712 114.788 22.9392 140.244C14.8584 156.761 12.4608 177.214 16.1756 197.845C20.1716 220.031 30.8128 240.928 46.93 258.289L46.3824 258.792Z"
        fill="#F6F6F6"
      />
      <path
        d="M152.415 257.994H56.1406V203.797H152.415V257.994Z"
        fill="#D3DFFF"
      />
      <path
        opacity="0.5"
        d="M90.6394 257.994H56.1406V203.797H90.6394V257.994Z"
        fill="#86A7FF"
      />
      <path
        opacity="0.3"
        d="M117.866 203.797V216.998H108.883V203.797H117.866Z"
        fill="white"
      />
      <path
        d="M292.446 257.994H133.391V144.744H292.446V257.994Z"
        fill="#2A6CFF"
      />
      <path
        d="M307.93 104.71H278.508V77.8476C278.508 72.4308 282.903 68.0352 288.32 68.0352C291.029 68.0352 293.485 69.1304 295.261 70.9064C297.008 72.6528 298.103 75.08 298.133 77.7588V77.8476L298.118 94.8972C298.118 94.9712 298.118 95.06 298.133 95.1488V95.6668L298.162 95.652C298.547 100.714 302.765 104.71 307.93 104.71Z"
        fill="#86A7FF"
      />
      <path
        d="M317.744 88.9629V94.8977C317.744 97.6061 316.649 100.063 314.858 101.824C313.097 103.615 310.64 104.71 307.932 104.71C302.767 104.71 298.549 100.714 298.164 95.6525L317.744 88.9629Z"
        fill="#D3DFFF"
      />
      <path
        d="M288.316 68.0352C288.109 68.0352 287.907 68.045 287.709 68.0648C287.561 68.0648 287.443 68.0796 287.31 68.0944C287.177 68.1092 287.043 68.124 286.895 68.1388C286.816 68.1486 286.733 68.1634 286.644 68.1832C286.54 68.198 286.437 68.2128 286.348 68.2424C286.185 68.272 286.052 68.3016 285.904 68.3312C285.687 68.3805 285.475 68.4446 285.267 68.5236C285.164 68.5532 285.06 68.5828 284.957 68.6272C284.868 68.6568 284.764 68.7012 284.661 68.7456C284.522 68.7949 284.384 68.8541 284.246 68.9232C284.128 68.9676 284.009 69.0268 283.891 69.086C283.861 69.1008 283.832 69.1156 283.787 69.1452C283.679 69.1945 283.575 69.2488 283.477 69.308C283.062 69.5448 282.663 69.8112 282.293 70.1072C282.189 70.1812 282.085 70.27 281.982 70.3588C281.863 70.4476 281.76 70.5512 281.641 70.6548C281.533 70.7633 281.429 70.8669 281.331 70.9656C281.035 71.2616 280.753 71.5872 280.502 71.9128C280.398 72.0756 280.28 72.2088 280.176 72.3568C279.851 72.86 279.569 73.3928 279.318 73.9404C279.259 74.0736 279.214 74.2216 279.155 74.3548C278.992 74.784 278.859 75.228 278.755 75.672C278.726 75.8298 278.696 75.9828 278.667 76.1308C278.607 76.4416 278.563 76.7524 278.548 77.0632V214.688H147.391V83.3384C147.391 74.8876 154.228 68.0352 162.679 68.0352H288.316Z"
        fill="#D3DFFF"
      />
      <path
        d="M262.421 87.2908H162.891V82.0664H262.421V87.2908Z"
        fill="#ADC3FF"
      />
      <path
        d="M254.71 102.638H162.891V97.3984H254.71V102.638Z"
        fill="#ADC3FF"
      />
      <path
        d="M249.87 117.985H162.891V112.746H249.87V117.985Z"
        fill="#ADC3FF"
      />
      <path
        d="M262.421 133.317H162.891V128.078H262.421V133.317Z"
        fill="#ADC3FF"
      />
      <path
        d="M255.312 148.845H201.352V143.605H255.312V148.845Z"
        fill="#ADC3FF"
      />
      <path
        d="M212.219 173.026C211.612 173.026 211.064 172.819 210.694 172.301C210.236 171.664 210.265 170.791 210.28 170.214L210.798 156.672L211.538 156.701L211.035 170.229C211.005 170.865 211.02 171.472 211.301 171.857C211.938 172.745 213.462 171.975 213.773 171.812C215.268 171.013 216.363 169.489 217.399 168.023C218.568 166.381 219.767 164.693 221.587 163.894C222.668 163.421 223.467 164.116 224.103 164.664C224.458 164.989 224.799 165.285 225.169 165.389C226.116 165.67 227.063 165.419 228.07 165.167C228.958 164.93 229.875 164.693 230.808 164.827C231.681 164.96 232.376 165.315 233.042 165.641C234.034 166.129 234.892 166.558 236.136 166.262C237.852 165.848 239.451 164.871 240.99 163.924C241.434 163.643 241.878 163.376 242.322 163.11C244.054 162.103 246.658 160.845 249.248 161.437L249.086 162.163C246.747 161.63 244.32 162.814 242.707 163.761C242.263 164.027 241.819 164.294 241.375 164.56C239.791 165.537 238.148 166.543 236.313 166.987C234.804 167.343 233.738 166.825 232.717 166.307C232.095 166.011 231.444 165.685 230.704 165.567C229.905 165.448 229.106 165.67 228.262 165.877C227.196 166.159 226.101 166.44 224.947 166.099C224.429 165.951 224.014 165.581 223.615 165.241C222.934 164.664 222.505 164.323 221.883 164.59C220.255 165.3 219.16 166.825 218.006 168.453C216.91 169.992 215.786 171.59 214.113 172.478C213.536 172.789 212.84 173.026 212.219 173.026Z"
        fill="#102048"
      />
      <path
        opacity="0.3"
        d="M278.548 149.48V214.689H147.391V143.797L188.357 177.896C190.621 179.776 193.581 180.501 196.497 180.086C197.341 179.968 198.214 179.924 199.072 179.938L227 180.723C227.977 180.752 228.924 180.871 229.871 181.078C232.757 181.7 235.791 181.137 238.174 179.361L278.548 149.48Z"
        fill="#86A7FF"
      />
      <path
        d="M193.198 191.69L192.103 192.903L142.745 247.634L133.406 257.994V144.744L193.198 191.69Z"
        fill="#407BFF"
      />
      <path
        d="M292.447 144.744L232.641 191.69L292.447 257.994V144.744Z"
        fill="#407BFF"
      />
      <path
        d="M192.103 192.902L142.745 247.632L133.406 249.69V229.562C158.078 211.89 185.754 191.378 192.073 190.934L192.103 192.902Z"
        fill="#2A6CFF"
      />
      <path
        d="M233.734 192.902L283.092 247.632L292.446 249.69V229.562C267.774 211.89 240.098 191.378 233.764 190.934L233.734 192.902Z"
        fill="#2A6CFF"
      />
      <path
        d="M292.446 257.992H133.391L133.42 243.089L188.225 193.376C191.111 190.756 194.855 189.32 198.733 189.32H226.675C230.493 189.32 234.193 190.712 237.05 193.257L292.594 242.571L292.446 257.992Z"
        fill="#407BFF"
      />
      <path
        opacity="0.4"
        d="M281.883 203.367C266.787 203.367 254.562 215.592 254.562 230.673C254.562 245.754 266.787 257.994 281.883 257.994C296.964 257.994 309.189 245.754 309.189 230.673C309.189 215.592 296.964 203.367 281.883 203.367Z"
        fill="#5B4DAD"
      />
      <path
        d="M317.416 230.673C317.416 245.754 305.191 257.994 290.11 257.994C275.029 257.994 262.789 245.754 262.789 230.673C262.789 215.592 275.029 203.367 290.11 203.367C305.191 203.367 317.416 215.592 317.416 230.673Z"
        fill="#3A37EC"
      />
      <path
        d="M289.518 245.473C288.23 245.473 286.987 244.925 286.099 243.949L276.435 233.278C275.606 232.36 275.665 230.939 276.583 230.111C277.515 229.282 278.936 229.341 279.765 230.273L289.429 240.929C289.444 240.959 289.474 240.989 289.548 240.989C289.636 240.974 289.651 240.929 289.666 240.9L300.07 217.22C300.559 216.08 301.891 215.562 303.016 216.065C304.155 216.569 304.673 217.886 304.17 219.025L293.766 242.705C293.144 244.141 291.827 245.162 290.273 245.414C290.026 245.453 289.774 245.473 289.518 245.473Z"
        fill="white"
      />
      <path
        d="M100.539 188.699L101.412 203.78H124.604L114.125 195.182L113.504 188.699L109.389 184.703L100.539 188.699Z"
        fill="#263238"
      />
      <path
        d="M72.714 181.965L68.9844 195.433L88.0616 203.78L82.4524 191.067L83.814 185.325L79.078 179.094L72.714 181.965Z"
        fill="#263238"
      />
      <path
        d="M80.0844 115.113C80.0844 115.113 77.8348 124.467 78.5896 145.039C78.5896 145.039 71.974 168.112 72.714 181.965L83.814 185.324C89.6304 172.404 94.4256 159.972 96.9564 148.606C98.2292 144.58 98.1996 140.273 96.8972 136.262L91.5544 119.968L80.0844 115.113Z"
        fill="#5C8EFF"
      />
      <path
        d="M115.261 145.469L113.5 188.7H100.535C97.1458 174.625 95.8878 162.608 95.4586 154.557C95.0886 147.763 95.2958 143.797 95.2958 143.797C86.9634 133.955 82.0054 126.851 79.1194 121.582C74.8422 113.783 75.1086 110.023 75.5822 107.374C76.2334 103.852 78.5126 99.2787 80.0666 96.4815C80.9398 94.9275 81.5762 93.9062 81.5762 93.9062L103.273 100.892L100.683 110.26L100.639 110.408C100.254 111.814 100.446 113.339 101.171 114.597L112.553 134.414C114.477 137.773 115.424 141.607 115.261 145.469Z"
        fill="#79A2FF"
      />
      <path
        d="M108.517 188.981C108.507 188.981 108.502 188.981 108.502 188.981C108.339 188.966 108.221 188.833 108.235 188.685C108.265 188.241 110.944 145.262 107.762 138.424C107.199 137.225 106.252 135.686 105.157 133.91C100.584 126.481 92.9026 114.049 94.5158 96.1259C94.5306 95.9779 94.6638 95.8595 94.8266 95.8743C94.9746 95.8891 95.093 96.0223 95.0782 96.1851C93.4798 113.916 101.087 126.244 105.645 133.614C106.741 135.405 107.703 136.959 108.265 138.188C111.506 145.158 108.916 186.939 108.798 188.715C108.783 188.863 108.665 188.981 108.517 188.981Z"
        fill="#6594FF"
      />
      <path
        opacity="0.6"
        d="M103.277 100.892L100.687 110.26C90.6967 106.146 83.5187 99.9003 80.0703 96.4815C80.9435 94.9275 81.5799 93.9062 81.5799 93.9062L103.277 100.892Z"
        fill="#2669FF"
      />
      <path
        d="M106.941 195.788L106.719 192.029L113.867 191.91C114.06 191.91 114.148 192.029 114.148 192.177C114.163 192.339 114.03 192.458 113.882 192.473L107.311 192.591L107.459 195.196L114.104 194.9C114.252 194.9 114.4 195.018 114.4 195.166C114.415 195.329 114.296 195.447 114.134 195.462L106.941 195.788Z"
        fill="white"
      />
      <path
        d="M82.4487 191.35C82.4191 191.35 82.3895 191.345 82.3599 191.335L76.2031 189.367L77.7423 185.785L83.1591 187.754C83.3071 187.798 83.3811 187.961 83.3367 188.109C83.2775 188.257 83.1147 188.331 82.9667 188.272L78.0531 186.496L76.9579 189.026L82.5227 190.802C82.6707 190.847 82.7595 191.01 82.7151 191.158C82.6707 191.276 82.5671 191.35 82.4487 191.35Z"
        fill="white"
      />
      <path
        d="M110.941 59.4815L110.541 60.9911L108.928 67.1035L107.833 71.2031L98.1094 67.7103L102.801 58.1199L105.08 54.8047L110.941 59.4815Z"
        fill="#F9B384"
      />
      <path
        d="M110.541 60.9904L108.927 67.1028C106.056 63.9948 106.633 60.1172 106.633 60.1172L110.541 60.9904Z"
        fill="#102048"
      />
      <path
        d="M118.241 50.8822C118.241 50.8822 117.619 64.7202 110.634 63.5362C103.648 62.3522 103.781 50.4382 103.781 50.4382L112.883 47.5078L118.241 50.8822Z"
        fill="#F9B384"
      />
      <path
        d="M108.544 52.9697C108.544 52.9697 112.555 48.8405 118.238 50.8829C118.238 50.8829 120.754 51.9337 121.731 49.8765C121.731 49.8765 113.694 39.2205 107.375 44.3561C107.375 44.3561 96.0529 46.8425 102.787 58.1201L108.544 52.9697Z"
        fill="#263238"
      />
      <path
        d="M110.797 52.9401C111.004 54.2869 110.235 55.5301 109.065 55.7077C107.881 55.9001 106.757 54.9677 106.549 53.6209C106.342 52.2741 107.112 51.0309 108.281 50.8533C109.45 50.6609 110.575 51.5933 110.797 52.9401Z"
        fill="#F9B384"
      />
      <path
        d="M118.486 57.8674C118.486 58.5482 117.924 59.1106 117.243 59.1106C116.548 59.1106 116 58.5482 116 57.8674C116 57.1718 116.548 56.6094 117.243 56.6094C117.924 56.6094 118.486 57.1718 118.486 57.8674Z"
        fill="#F9B384"
      />
      <path
        opacity="0.4"
        d="M114.565 56.5796C114.565 57.7784 113.603 58.7404 112.404 58.7404C111.22 58.7404 110.258 57.7784 110.258 56.5796C110.258 55.3956 111.22 54.4336 112.404 54.4336C113.603 54.4336 114.565 55.3956 114.565 56.5796Z"
        fill="#EB5D60"
      />
      <path
        d="M152.794 78.972C152.794 78.972 154.082 80.748 151.447 82.5092C151.447 82.5092 153.534 83.93 146.341 86.298C145.542 86.5496 144.624 86.8308 143.574 87.112L140.688 76.6188C140.688 76.6188 153.534 72.2824 153.859 73.6736C154.185 75.0648 151.566 76.9444 151.566 76.9444C151.566 76.9444 156.168 75.8048 152.794 78.972Z"
        fill="#F9B384"
      />
      <path
        d="M140.687 76.6189L125.472 78.5725C125.472 78.5725 103.583 62.9733 99.0986 64.4681C94.5994 65.9629 79.4442 90.2941 79.4442 90.2941C79.4442 90.2941 75.3298 91.5965 75.5222 93.2837C75.7146 94.9709 102.281 108.75 105.833 105.257C108.067 103.067 110.628 85.5581 110.628 85.5581L120.307 89.5837C123.178 90.7677 126.36 91.0341 129.379 90.3533L143.573 87.1121L140.687 76.6189Z"
        fill="#407BFF"
      />
      <g opacity="0.8">
        <path
          d="M139.73 85.9883C139.212 83.9459 138.694 81.9479 138.235 80.0387C138.102 79.5059 137.984 78.9583 137.865 78.4107C137.767 77.947 137.663 77.4833 137.555 77.0195L136.992 77.0935C137.111 77.5671 137.219 78.0457 137.318 78.5291C137.436 79.0767 137.555 79.6243 137.688 80.1719C138.147 82.0811 138.665 84.0791 139.183 86.1363C139.34 86.7579 139.503 87.3795 139.671 88.0011L140.219 87.8827C140.051 87.2513 139.888 86.6198 139.73 85.9883Z"
          fill="#1D63FF"
        />
        <path
          d="M113.685 70.7881C113.458 70.8375 113.226 70.8868 112.989 70.9361C108.771 71.8093 104.272 72.5197 99.8615 71.7797C97.6267 71.3949 95.5695 70.6253 93.8083 69.5449C93.6899 69.6929 93.5715 69.8409 93.4531 69.9889C95.2883 71.1137 97.4343 71.9277 99.7579 72.3273C104.272 73.0969 108.845 72.3717 113.108 71.4837C113.532 71.3949 113.956 71.3012 114.38 71.2025C114.153 71.0644 113.922 70.9263 113.685 70.7881Z"
          fill="#1D63FF"
        />
        <path
          d="M107.851 97.7385C106.593 97.8421 105.276 97.9457 104.003 97.8273C100.273 97.4869 96.9579 95.1633 94.7823 93.4465C94.0127 92.8397 93.2579 92.2033 92.5179 91.5669C90.5939 89.9537 88.6107 88.2961 86.3907 87.0529C85.1031 86.3425 83.8007 85.8245 82.5131 85.5137C82.4095 85.6913 82.3059 85.8393 82.1875 86.0021C83.4899 86.2981 84.8071 86.8161 86.1095 87.5413C88.2999 88.7549 90.2535 90.3977 92.1479 91.9961C92.9027 92.6325 93.6575 93.2689 94.4419 93.8757C96.6619 95.6517 100.066 98.0345 103.958 98.3897C105.276 98.5081 106.607 98.4045 107.91 98.3009C108.028 98.2861 108.161 98.2861 108.28 98.2713C108.329 98.0838 108.374 97.8914 108.413 97.6941C108.236 97.7089 108.043 97.7237 107.851 97.7385Z"
          fill="#1D63FF"
        />
      </g>
      <path
        d="M110.632 85.5597L106.695 83.8281C106.695 83.8281 107.702 90.0885 109.596 90.9765L110.632 85.5597Z"
        fill="#2669FF"
      />
      <path
        d="M146.341 86.2983C145.542 86.5499 144.624 86.8311 143.574 87.1123L140.688 76.6191L146.341 86.2983Z"
        fill="#E28B6D"
      />
      <path
        d="M319.469 149.599L328.645 153.713L335.468 131.217L326.528 128.879C324.16 134.163 321.748 140.926 319.469 149.599Z"
        fill="#E28B6D"
      />
      <path
        d="M326.531 128.879L335.47 131.217L343.107 110.246C343.107 110.246 333.887 109.003 326.531 128.879Z"
        fill="#2669FF"
      />
      <path
        d="M315.671 141.799L309.07 160.003L333.757 169.49L338.744 150.531L315.671 141.799Z"
        fill="#407BFF"
      />
      <path
        d="M323.795 166.071C323.617 166.574 322.137 167.196 321.19 166.352C319.34 166.027 318.097 165.79 316.942 164.517C315.906 164.117 315.447 162.993 315.921 162.045C316.824 160.225 318.23 157.665 318.896 157.768C319.739 157.887 319.133 160.477 319.133 160.477C319.133 160.477 320.524 157.191 321.856 157.635C322.448 157.827 321.027 160.95 320.879 161.276C321.101 160.98 322.877 158.597 323.558 159.1C324.239 159.603 322.581 162.43 322.403 162.726C322.596 162.489 324.15 160.61 324.919 161.231C325.245 161.498 324.727 163.407 323.795 166.071Z"
        fill="#E28B6D"
      />
      <path
        d="M370.18 228.734C370.18 228.734 382.153 227.417 387.2 216.391L373.924 217.131L370.18 228.734Z"
        fill="#102048"
      />
      <path
        d="M383.392 216.508L371.922 223.612L377.087 231.204L388.143 223.656L383.392 216.508Z"
        fill="#E28B6D"
      />
      <path
        d="M395.246 234.342L388.142 223.656L377.086 231.204L378.966 233.972L377.841 248.742L395.246 234.342Z"
        fill="#263238"
      />
      <path
        d="M378.983 234.345L378.953 233.605L384.562 233.309L384.607 234.063L378.983 234.345Z"
        fill="white"
      />
      <path
        d="M350.72 236.711L338.125 235.971V246.168L351.682 245.309L350.72 236.711Z"
        fill="#E28B6D"
      />
      <path
        d="M338.12 246.167V248.565L328.648 258.037H353.098L351.677 245.309L338.12 246.167Z"
        fill="#263238"
      />
      <path
        d="M343.021 252.192L337.648 249.069L338.033 248.418L343.391 251.541L343.021 252.192Z"
        fill="white"
      />
      <path
        d="M350.083 171.592C349.89 176.239 349.861 181.138 349.935 186.066C350.009 190.344 350.171 194.65 350.393 198.868C351.474 219.796 353.96 238.34 353.96 238.34L334.513 240.826C325.278 176.476 331.272 149.288 331.272 149.288L339.752 147.557L347.271 146.018L355.721 144.301C355.613 144.459 355.509 144.621 355.411 144.789C354.123 146.891 353.102 149.688 352.317 153.018C351.089 158.183 350.393 164.606 350.083 171.592Z"
        fill="#79A2FF"
      />
      <path
        d="M350.389 198.867C342.235 181.521 345.298 167.742 345.298 167.742L350.079 171.59C349.886 176.237 349.857 181.136 349.931 186.065C350.005 190.342 350.167 194.649 350.389 198.867Z"
        fill="#5C8EFF"
      />
      <path
        d="M387.195 216.392C377.842 219.944 370.176 228.735 370.176 228.735C362.095 214.468 355.213 200.127 349.929 186.067C345.548 174.36 342.277 162.831 340.383 151.686C340.146 150.295 339.924 148.919 339.747 147.557C339.717 147.424 339.702 147.291 339.688 147.158L347.265 146.018L355.405 144.79L359.52 144.168C359.949 146.788 360.541 149.526 361.251 152.352C368.34 180.517 387.195 216.392 387.195 216.392Z"
        fill="#79A2FF"
      />
      <g opacity="0.8">
        <path
          d="M370.74 180.501C370.621 180.205 370.503 179.909 370.385 179.613C368.86 178.636 367.38 177.556 366.122 176.268C362.6 172.642 360.809 167.284 361.475 162.267C361.667 160.728 362.111 159.218 362.733 157.783C362.654 157.506 362.575 157.235 362.496 156.969C361.697 158.626 361.149 160.388 360.913 162.208C360.232 167.373 362.082 172.923 365.723 176.653C367.203 178.177 368.949 179.391 370.74 180.501Z"
          fill="#6594FF"
        />
        <path
          d="M380.542 202.848C380.463 202.69 380.385 202.527 380.306 202.359C378.234 203.188 375.762 203.336 373.202 203.484C370.612 203.632 367.933 203.78 365.58 204.698C363.197 205.63 361.332 207.288 360 209.33C360.089 209.528 360.183 209.725 360.281 209.922C361.584 207.85 363.404 206.148 365.787 205.231C368.051 204.343 370.686 204.195 373.231 204.047C375.762 203.899 378.352 203.751 380.542 202.848Z"
          fill="#6594FF"
        />
        <path
          d="M335.707 200.719C341.923 203.886 346.969 209.184 351.424 214.142C351.405 213.836 351.38 213.531 351.35 213.225C346.984 208.385 342.041 203.309 335.958 200.216C334.019 199.239 332.021 198.513 330.023 198.055C330.033 198.242 330.043 198.435 330.053 198.632C331.962 199.091 333.857 199.786 335.707 200.719Z"
          fill="#6594FF"
        />
        <path
          d="M352.924 229.593C351.37 229.963 349.668 229.874 348.262 229.533C346.797 229.193 345.362 228.631 343.97 228.083C342.49 227.491 340.966 226.899 339.382 226.544C337.162 226.07 334.854 226.129 332.648 226.662C332.668 226.84 332.688 227.022 332.708 227.21C334.854 226.692 337.103 226.633 339.264 227.091C340.803 227.432 342.313 228.024 343.763 228.601C345.169 229.163 346.62 229.726 348.129 230.081C349.594 230.421 351.356 230.525 352.984 230.155C352.969 229.963 352.939 229.77 352.924 229.593Z"
          fill="#6594FF"
        />
        <path
          d="M334.022 176.905C334.229 180.827 332.453 184.675 329.523 187.235C329.537 187.472 329.537 187.709 329.552 187.946C332.808 185.297 334.806 181.123 334.584 176.875C334.377 173.101 332.438 169.564 329.537 167.211C329.528 167.448 329.518 167.685 329.508 167.921C332.113 170.171 333.844 173.427 334.022 176.905Z"
          fill="#6594FF"
        />
      </g>
      <path
        opacity="0.4"
        d="M361.25 152.352C358.276 152.885 355.242 153.063 352.311 153.018C347.945 152.944 343.816 152.367 340.382 151.686C334.951 150.621 331.266 149.289 331.266 149.289L339.746 147.557C339.716 147.424 339.702 147.291 339.687 147.158L347.264 146.018L355.715 144.301C355.607 144.459 355.503 144.622 355.404 144.79L359.519 144.168C359.948 146.788 360.54 149.526 361.25 152.352Z"
        fill="#2669FF"
      />
      <path
        d="M349.928 186.436C349.78 186.436 349.632 186.347 349.587 186.185L344.171 170.985L337.673 167.063C337.496 166.959 337.451 166.723 337.555 166.545C337.659 166.382 337.895 166.323 338.058 166.427L344.792 170.482L350.283 185.933C350.357 186.125 350.253 186.347 350.061 186.421C350.022 186.431 349.977 186.436 349.928 186.436Z"
        fill="#6997FF"
      />
      <path
        d="M362.688 147.068C350.153 151.848 328.648 149.155 328.648 149.155C329.522 140.053 329.581 113.117 343.611 109.742L349.886 110.408C353.172 111.696 355.732 116.609 357.671 122.426C358.826 125.904 359.758 129.707 360.483 133.274C362.022 140.719 362.688 147.068 362.688 147.068Z"
        fill="#407BFF"
      />
      <path
        d="M360.484 133.274C354.579 129.825 353.336 122.1 353.336 122.1L357.672 122.425C358.827 125.903 359.759 129.707 360.484 133.274Z"
        fill="#2669FF"
      />
      <path
        d="M349.891 110.407L349.047 122.469L361.923 129.351L364.528 116.845C353.206 110.156 349.891 110.407 349.891 110.407Z"
        fill="#407BFF"
      />
      <path
        d="M374.413 126.983C374.102 127.456 373.762 127.885 373.377 128.285C372.667 129.01 371.838 129.617 370.92 130.061C369.544 130.727 368.049 131.008 366.569 130.919C365.326 130.845 364.097 130.505 362.973 129.913L361.922 129.351L364.527 116.845C365.222 117.259 365.947 117.688 366.717 118.162C366.717 118.162 376.914 103.761 377.669 104.043C378.335 104.294 377.195 106.514 376.959 106.958C377.403 106.307 380.703 101.467 381.591 101.793C382.301 102.059 381.399 104.22 381.295 104.472C381.502 104.191 383.263 101.571 384.344 102.207C384.832 102.489 382.923 105.33 382.731 105.611C382.967 105.389 385.084 103.332 385.617 103.954C386.149 104.561 384.092 107.017 383.856 107.299C384.063 107.106 385.883 105.523 386.46 106.292C387.215 107.269 377.95 121.595 374.413 126.983Z"
        fill="#E28B6D"
      />
      <path
        opacity="0.4"
        d="M373.373 128.286C372.662 129.011 371.833 129.618 370.916 130.062C369.539 130.728 368.045 131.009 366.565 130.92C366.495 130.654 366.461 130.378 366.461 130.091C366.461 128.064 368.119 126.406 370.161 126.406C371.537 126.406 372.736 127.161 373.373 128.286Z"
        fill="#EB5D60"
      />
      <path
        d="M342.131 146.624C336.596 146.624 331.638 146.269 329.211 146.061C329.043 146.052 328.944 146.047 328.915 146.047C328.767 146.032 328.648 145.913 328.648 145.751C328.648 145.603 328.767 145.484 328.93 145.484C328.944 145.484 329.063 145.484 329.255 145.499C334.805 145.987 353.527 147.216 362.008 143.264C362.156 143.19 362.318 143.249 362.392 143.397C362.452 143.531 362.392 143.708 362.244 143.767C357.405 146.032 349.28 146.624 342.131 146.624Z"
        fill="#1D63FF"
      />
      <path
        d="M345.065 118.251C342.09 118.251 340.773 116.282 340.758 116.253C340.684 116.134 340.714 115.957 340.847 115.868C340.98 115.794 341.143 115.823 341.232 115.957C341.336 116.105 343.674 119.553 349.594 116.356C349.727 116.282 349.905 116.341 349.979 116.475C350.053 116.608 349.994 116.785 349.86 116.859C347.936 117.895 346.353 118.251 345.065 118.251Z"
        fill="#1D63FF"
      />
      <path
        d="M354.922 86.9494C356.49 86.0614 358.622 85.9874 360.013 87.127C360.738 87.719 361.197 88.5774 361.878 89.2286C363.328 90.6346 365.578 90.9898 366.954 92.455C368.02 93.5798 368.404 95.2374 368.301 96.7914C368.197 98.3454 367.65 99.8254 367.072 101.276C366.244 103.333 365.282 105.435 363.639 106.915C363.002 107.492 362.262 107.965 361.434 108.143C360.605 108.335 359.687 108.217 359.006 107.699C358.296 107.151 357.956 106.234 357.882 105.346C357.822 104.443 358.015 103.555 358.207 102.682C358.651 102.963 359.273 102.948 359.717 102.667C360.161 102.386 360.427 101.838 360.353 101.305C360.25 100.417 359.376 99.855 358.577 99.4258C357.275 98.7055 355.967 97.9853 354.655 97.265C353.9 96.8506 353.116 96.4214 352.539 95.7702C351.577 94.675 350.807 92.2626 351.444 90.8714C352.154 89.347 353.442 87.7782 354.922 86.9494Z"
        fill="#263238"
      />
      <path
        d="M339.406 94.2165C339.406 94.2165 339.317 85.3513 350.329 87.6897C352.193 88.0893 353.821 89.0957 355.02 90.5757C356.811 92.7957 357.965 102.105 352.845 104.695C346.421 107.936 339.406 94.2165 339.406 94.2165Z"
        fill="#263238"
      />
      <path
        d="M350.801 101.707L350.55 110.617C350.491 112.866 348.7 114.701 346.435 114.82C346.366 114.83 346.292 114.835 346.213 114.835C343.683 114.835 341.67 112.659 341.892 110.113L341.907 109.965L342.484 103.202L343.224 103.069L350.801 101.707Z"
        fill="#E28B6D"
      />
      <path
        d="M347.042 104.887C347.042 104.887 345.665 108.306 341.906 109.963L342.483 103.2L343.223 103.066L347.042 104.887Z"
        fill="#102048"
      />
      <path
        d="M339.405 94.2159C339.405 94.2159 336.593 105.908 342.484 106.47C348.374 107.033 350.816 101.705 350.816 101.705L351.556 95.0595L347.915 89.8203L342.114 91.5963L339.405 94.2159Z"
        fill="#E28B6D"
      />
      <path
        d="M339.406 94.2176C339.406 94.2176 344.823 91.1836 346.791 95.2536C348.168 98.0804 350.255 99.6492 352.312 98.7168C354.369 97.7696 352.682 91.0356 352.682 91.0356L345.015 89.082L340.427 91.7016L339.406 94.2176Z"
        fill="#263238"
      />
      <path
        d="M352.583 100.033C351.872 101.247 350.481 101.779 349.46 101.187C348.454 100.61 348.202 99.1302 348.912 97.9166C349.623 96.6882 351.014 96.1703 352.035 96.7475C353.042 97.3395 353.293 98.8047 352.583 100.033Z"
        fill="#E28B6D"
      />
      <path
        d="M350.156 99.9875C350.052 99.9727 349.963 99.8839 349.978 99.7803C350.023 99.1291 350.437 97.8711 351.695 97.8711C351.799 97.8711 351.873 97.9451 351.873 98.0487C351.873 98.1523 351.799 98.2411 351.695 98.2411C350.481 98.2411 350.348 99.7507 350.348 99.8099C350.348 99.9135 350.259 99.9875 350.156 99.9875Z"
        fill="#102048"
      />
      <path
        d="M340.298 98.4189C340.298 98.6853 340.091 98.8925 339.824 98.8925C339.558 98.8925 339.336 98.6853 339.336 98.4189C339.336 98.1525 339.558 97.9453 339.824 97.9453C340.091 97.9453 340.298 98.1525 340.298 98.4189Z"
        fill="#102048"
      />
      <path
        d="M343.996 98.3754C343.996 98.6714 343.759 98.9082 343.478 98.9082C343.182 98.9082 342.945 98.6714 342.945 98.3754C342.945 98.0942 343.182 97.8574 343.478 97.8574C343.759 97.8574 343.996 98.0942 343.996 98.3754Z"
        fill="#102048"
      />
      <path
        d="M341.288 101.335C340.873 101.335 340.597 101.207 340.459 100.95C340.089 100.269 340.947 98.9964 341.125 98.7596C341.184 98.6708 341.302 98.656 341.376 98.7152C341.465 98.7744 341.48 98.8928 341.421 98.9668C340.977 99.618 340.607 100.432 340.784 100.758C340.858 100.906 341.066 100.965 341.391 100.95C341.391 100.95 341.396 100.95 341.406 100.95C341.495 100.95 341.584 101.024 341.584 101.128C341.584 101.231 341.51 101.32 341.406 101.335C341.367 101.335 341.327 101.335 341.288 101.335Z"
        fill="#102048"
      />
      <path
        opacity="0.4"
        d="M347.798 100.522C347.798 101.44 347.043 102.194 346.126 102.194C345.208 102.194 344.453 101.44 344.453 100.522C344.453 99.6044 345.208 98.8496 346.126 98.8496C347.043 98.8496 347.798 99.6044 347.798 100.522Z"
        fill="#EB5D60"
      />
      <path
        d="M341.529 102.949C341.026 102.949 340.67 102.772 340.656 102.757C340.552 102.713 340.522 102.594 340.567 102.505C340.611 102.417 340.73 102.387 340.818 102.431C340.863 102.446 341.869 102.949 342.92 101.987C342.994 101.913 343.112 101.913 343.186 102.002C343.26 102.076 343.246 102.195 343.172 102.254C342.58 102.801 341.988 102.949 341.529 102.949Z"
        fill="#102048"
      />
      <path
        d="M340.876 97.3689C340.521 97.2949 340.195 97.1913 339.899 97.1913C339.751 97.1765 339.618 97.2061 339.47 97.2357C339.322 97.2653 339.189 97.3393 339.026 97.4133H338.997C338.937 97.4429 338.878 97.4133 338.849 97.3541C338.839 97.3245 338.844 97.2949 338.863 97.2653C338.937 97.0729 339.085 96.8953 339.278 96.7917C339.47 96.6881 339.707 96.6289 339.914 96.6289C340.358 96.6733 340.743 96.8509 340.995 97.1913C341.039 97.2357 341.024 97.3097 340.98 97.3393C340.95 97.3689 340.921 97.3788 340.891 97.3689H340.876Z"
        fill="#102048"
      />
      <path
        d="M342.253 97.206C342.52 96.7472 342.993 96.4364 343.556 96.3772C343.837 96.3624 344.118 96.4216 344.355 96.5548C344.592 96.688 344.784 96.8804 344.902 97.1024C344.947 97.1616 344.917 97.2208 344.873 97.2652C344.843 97.28 344.799 97.28 344.769 97.2652H344.754C344.532 97.1616 344.355 97.058 344.162 97.0136C343.97 96.9396 343.778 96.9248 343.585 96.9396C343.215 96.9396 342.816 97.132 342.416 97.354H342.401C342.357 97.3836 342.283 97.3688 342.253 97.3096C342.238 97.28 342.238 97.2356 342.253 97.206Z"
        fill="#102048"
      />
      <path
        d="M431.536 258.408H16.2188V257.668H431.536V258.408Z"
        fill="#F6F6F6"
      />
    </>
  );
  return (
    <CreateChatIcon
      width="444"
      height="296"
      viewBox="0 0 444 296"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
      }}
      {...props}
    />
  );
}
