import React from "react";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import BackButtonLayout from "../../component/ui/BackButtonLayout";
import Heading from "../../component/ui/Heading";
import useApi from "../../hooks/useApi";
import DynamicMeta from "../../utils/DynamicMeta";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import useThemeStore from "../../hooks/store/useThemeStore";
import MobileInput from "../ui/form/MobileInput";

const AddNewMobileNo = ({ setOpenForm, setMobileNumber, setUpdateMobile }) => {
  const { apiCall: API } = useApi();
  const { darkMode } = useThemeStore();

  const inputStyle = {
    "& input,input:is(:hover,:focus)": {
      border: "none",
      zIndex: 2,
      color: darkMode ? "white.1000" : "black.1000",
      opacity: 1,
    },
    "& fieldset": {
      backgroundColor: darkMode ? "bgDark.third" : "white.1000",
      border: "1px solid",
      borderColor: darkMode ? "neutral.650" : "white.400",
      borderRadius: "15px",
    },
    "& fieldset:focus": {
      borderRadius: "15px",
      backgroundColor: darkMode ? "bgDark.third" : "white.1000",
      border: "1px solid",
      borderColor: "bgWhite.secondary",
    },
    "& :-webkit-autofill": {
      BackgroundClip: "text",
      textFillColor: darkMode ? "#ffffff" : "black.1000",
      transition: "background-color 5000s ease-in-out 0s",
      boxShadow: ` inset 0 0 20px 20px ${
        darkMode ? "neutral.650" : "white.1000"
      }`,
    },
  };

  // form handling
  const phoneRegExp =
    /^(\+\d{1,4}(\s|-)?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const schema = Yup.object({
    mobileNumber: Yup.string()
      .required("Mobile number is required.")
      .matches(phoneRegExp, "Invalid phone number")
      .test(
        "is-unique-mobile",
        "This mobile number is already taken",
        async function (value) {
          try {
            let result = await API({
              url: `v3/users/check/mobile/${value}`,
              method: "get",
            });
            if (result.data.success) return true;
            else return false;
          } catch (error) {
            return false; // Return false in case of an error
          }
        }
      ),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  function addNewMobileNumber(value) {
    setMobileNumber(value.mobileNumber);
    setUpdateMobile(true);
    setOpenForm("OTP");
    return;
  }

  return (
    <BackButtonLayout
      pageTitle={"Phone Number"}
      titlePrefix={"Edit"}
      isTitleBold
    >
      <DynamicMeta title="Reset Email | Ploom Social"></DynamicMeta>

      <Box component={"form"} onSubmit={handleSubmit(addNewMobileNumber)}>
        <Heading>Enter Your Phone Number</Heading>

        {/* form inputs */}
        <MobileInput
          name="mobileNumber"
          register={register}
          errors={formState.errors}
          placeholder="Enter your new Phone number"
          sx={inputStyle}
          inputLabel={"Phone Number"}
          type="number"
        />

        <ButtonWithLoading
          color="primary"
          disabled={formState.isSubmitting}
          isLoading={formState.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            marginTop: "38px",
            textTransform: "capitalize",
          }}
        >
          Confirm
        </ButtonWithLoading>
      </Box>
    </BackButtonLayout>
  );
};

export default AddNewMobileNo;
