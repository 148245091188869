import {
  IconButton,
  Stack,
  Typography,
  useTheme,
  Button,
  // Modal,
} from "@mui/material";
import React, { useState } from "react";

import HeartWhiteIcon from "../../icons/HeartWhiteIcon";
import SendIcon from "../../icons/SendIcon";
import ChatIcon from "../../icons/ChatIcon";
import MonetizationOnOutlinedIcon from "../../icons/MonetizationOnOutlinedIcon";
import { Box } from "@mui/system";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useAuth } from "../../hooks/store/useAuth";
import { useSnack } from "../../hooks/store/useSnack";
import LikeFillIcon from "../../icons/LikeFillIcon";
import useApi from "../../hooks/useApi";
import { useFeed } from "../../hooks/store/useFeed";
import { useAllModal } from "../../hooks/store/useAllModal";
import MonetizationOnOutlinedIconGrey from "../../icons/MonetizationOnOutlinedIconGrey";
import { formatNumberCount } from "../../utils/NumberFormater";
import CardText from "./CardText";
import { Link, useNavigate } from "react-router-dom";
import { APIS } from "../../api/lists";

export default function CardActionContainer({
  likeCount,
  commentCount,
  shareCount,
  htmlDescription,
  title,
  daysRemaining,
  id,
  isLiked,
  authorId,
  status,
  begType,
  videos,
  endedDate,
  author,
  goalAmount,
  amountRaised,
  shareLink,
}) {
  const [feedStat, setFeedStat] = useState({
    comments: commentCount,
    like: likeCount,
    shares: shareCount,
  });
  const { typography, palette } = useTheme();
  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useState(false);
  const [liked, setLiked] = useState(Boolean(isLiked));
  const { likeFeed } = useFeed();
  const { apiCall, isLoading } = useApi();
  const { isLoggedIn, userId } = useAuth();
  const { setSnack } = useSnack();
  const { setShowShareDrawer } = useAllModal();
  const { darkMode } = useThemeStore();
  const navigate = useNavigate();
  function getShareLink() {
    setShowShareDrawer(
      true,
      id,
      title,
      videos[0].thumbLink,
      false,
      htmlDescription,
      author,
      { amountRaised, goalAmount },
      shareLink
    );
  }

  const checkLogin = (message) => {
    if (!isLoggedIn) {
      setSnack(message, "warning");
      return false;
    }
    return true;
  };

  const feedAction = [
    {
      Icon: liked ? LikeFillIcon : HeartWhiteIcon,
      value: formatNumberCount(feedStat.like),
      name: "like",
      disabled: isLoading,
      onClick: async () => {
        if (!checkLogin("please login to like post")) return;
        const res = await apiCall({
          url: APIS.LIKE.CREATE,
          method: "post",
          data: {
            begId: id,
            itemType: "beg",
            itemId: id,
          },
        });
        if (res.status === 200) {
          setLiked(!liked);
          setSnack(
            !liked
              ? "post liked successfully"
              : "post like removed successfully"
          );
          likeFeed(id);
          setFeedStat((prevState) => ({
            ...prevState,
            like: prevState.like + (!liked ? 1 : -1),
          }));
        }
      },
    },
    {
      Icon: ChatIcon,
      value: formatNumberCount(commentCount),
      name: "comment",
      to: `/feed/${id}/comments`,
    },
    // {
    //   Icon: SendIcon,
    //   value: formatNumberCount(feedStat.shares),
    //   name: "share",
    //   disabled: true,
    // },
    // {
    //   Icon: MonetizationOnOutlinedIcon,
    //   value: donorCount,
    //   name: "donor",
    // },
    // {
    //   Icon: FileDownloadOutlinedIcon,
    //   //   value: 0,
    //   name: "downloads",
    // },
  ];

  return (
    <>
      {/* comment modal */}
      {/* <Modal
        open={showModal}
        title={"Comments"}
        onClose={() => {
          setShowModal(false);
        }}
        sx={{
          padding: "0px",
        }}
      >
        <Box
          sx={{
            maxWidth: "480px",
            mx: "auto",
          }}
        >
          <CommentModal
            id={id}
            setCommentCount={setFeedStat}
            setOpenDrawer={setShowModal}
          />
        </Box>
      </Modal> */}

      <Box display={"flex"} flexDirection={"column"}>
        <Stack
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "15px",
            mt: "15px",
          }}
        >
          {/* sharer and donation button */}
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 1fr"}
            gap={"10px"}
            width={{ xs: "100%", sm: "100%", md: "unset" }}
          >
            {daysRemaining >= 0 &&
            status !== "ended" &&
            endedDate === undefined ? (
              <Button
                variant="contained"
                sx={{
                  background: palette.gradients["main"],
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: 1,
                  fontFamily: typography.inter,
                  width: { sm: "100%", xs: "100%", md: "auto" },
                  display: "inline-flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  paddingBlock: { xs: "13px", sm: "15px" },
                  cursor: "pointer",
                  marginLeft: 0,
                  marginRight: 0,
                  "& *": {
                    marginRight: { sm: "0", xs: "0", md: "4px" },
                    marginLeft: 0,
                  },
                }}
                onClick={() => {
                  // if (!isLoggedIn) {
                  //   return setSnack("login required", "warning");
                  // } else {
                  if (begType !== 2) {
                    if (daysRemaining >= 0 && status !== "ended") {
                      if (userId === authorId) {
                        setSnack(
                          "Self contribution are not allowed at this time.",
                          "warning"
                        );
                      } else {
                        // setShowPaymentDrawer(true, id, authorId);
                        navigate(`/donate?feed=${id}&redirect=home`);
                      }
                    } else {
                      setSnack("Contribution Period ended", "warning");
                    }
                  } else {
                    if (status !== "ended") {
                      if (userId !== authorId) navigate(`/donate?feed=${id}`);
                      // setShowPaymentDrawer(true, id, authorId);
                      else
                        setSnack(
                          "Self contribution are not allowed at this time.",
                          "warning"
                        );
                    } else {
                      setSnack("Contribution Period ended", "warning");
                    }
                  }
                  // }
                }}
                startIcon={<MonetizationOnOutlinedIcon />}
              >
                Donate
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  background: palette.gradients["main"],
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: 1,
                  fontFamily: typography.inter,
                  width: { sm: "100%", xs: "100%", md: "auto" },
                  display: "inline-flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  paddingBlock: { xs: "13px", sm: "15px" },
                  cursor: "pointer",
                  marginLeft: 0,
                  marginRight: 0,
                  "& *": {
                    marginRight: { sm: "0", xs: "0", md: "4px" },
                    marginLeft: 0,
                  },
                }}
                style={{
                  background: "#a1a1a1",
                  color: "white",
                  pointerEvents: "all",
                  cursor: "no-drop",
                }}
                startIcon={<MonetizationOnOutlinedIconGrey />}
                disabled
              >
                Donate
              </Button>
            )}

            <Button
              variant="contained"
              startIcon={<SendIcon />}
              sx={{
                background: palette.gradients["main"],
                borderRadius: "10px",
                textTransform: "capitalize",
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: 1,
                fontFamily: typography.inter,
                width: { sm: "100%", xs: "100%", md: "auto" },
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                paddingBlock: { xs: "13px", sm: "15px" },
                cursor: "pointer",
                marginLeft: 0,
                marginRight: 0,
                "& *": {
                  marginRight: { sm: "0", xs: "0", md: "4px" },
                  marginLeft: 0,
                },
              }}
              onClick={() => getShareLink()}
            >
              Share
            </Button>
          </Box>
          {/* like and comment button and others */}
          <Stack display={"flex"} flexDirection={"row"} gap={"30px"}>
            {feedAction.map((action) => (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"10px"}
                key={action.name}
                textAlign={"center"}
                sx={{ opacity: action.disabled === true ? 0.5 : 1 }}
              >
                <IconButton
                  sx={{ p: 0 }}
                  onClick={action.onClick}
                  children={
                    <action.Icon
                      color={darkMode ? "white" : "black"}
                      sx={{
                        fill: "none",
                      }}
                    />
                  }
                  disabled={action.disabled}
                  component={action.to ? Link : ""}
                  to={action.to}
                />

                <Typography
                  color={darkMode ? "neutral.1000" : "black.1000"}
                  fontSize={{ xs: "12px", md: "14px" }}
                  lineHeight={"12px"}
                  // style={{ textShadow: "0px 0px 5px rgba(0, 0, 0, 0.60)" }}
                >
                  {action.value || 0}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
        {/* title and description */}
        <CardText title={title} htmlDescription={htmlDescription} />
      </Box>
    </>
  );
}
