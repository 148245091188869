import {
  Box,
  Button,
  Stack,
  SwipeableDrawer,
  Typography,
  alpha,
} from "@mui/material";
import React from "react";
import { useAllModal } from "../../hooks/store/useAllModal";
import { Link } from "react-router-dom";
import donatePNG from "../../assets/donate.png";
import { useTheme } from "@emotion/react";

export const CreatePost = () => {
  const { palette } = useTheme();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  function HandleClick(e) {
    e.stopPropagation();
  }

  function closeComponent() {
    setShowCreatePost(false);
  }
  const { showCreatePost, setShowCreatePost } = useAllModal();
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={showCreatePost}
      onClose={() => setShowCreatePost(false)}
      onOpen={() => setShowCreatePost(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      sx={{
        zIndex: 1200,
        backgroundColor: alpha(palette.black["1000"], 0.5),
        "& .css-4w5voh-MuiPaper-root-MuiDrawer-paper": {
          backgroundColor: "transparent",
          maxWidth: "550px",
          marginInline: "auto",
        },
      }}
    >
      <Box
        sx={{
          // marginTop: { xs: "70px" },
          padding: { xs: "22px 20px 40px 20px", md: "22px 20px" },
          background: "primary.main",
          maxWidth: "550px",
          width: "100%",
          marginInline: "auto",
          backgroundColor: "white.1000",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        onClick={HandleClick}
      >
        <Stack
          display={"flex"}
          flexDirection={"column"}
          justifyItems={"left"}
          alignItems={"flex-start"}
          width={"100%"}
        >
          {/* minimize icon */}
          <Button
            sx={{
              padding: "0px",
              margin: "0px auto 13px",
              backgroundColor: "black.500",
              opacity: "0.2",
              minWidth: "36px",
              height: "5px",
              borderRadius: "7px",
            }}
            onClick={closeComponent}
          ></Button>
          {/* top image */}
          <Box
            sx={{
              paddingTop: "10px",
              backgroundColor: "primary.main",
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "right",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <img src={donatePNG} alt="" />
          </Box>

          {/* post story button */}
          <Button
            sx={{
              textTransform: "unset",
              marginTop: "30px",
              display: "block",
              width: "100%",
              boxShadow: `0px 0px 5px 0px  ${alpha(
                palette.black["1000"],
                0.15
              )}`,
              padding: "20px 15px 20px 10px",
              borderRadius: "10px",
            }}
            to="/create-post"
            component={Link}
            onClick={closeComponent}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"stretch"}
              justifyContent={"stretch"}
            >
              <Box
                component={"span"}
                sx={{
                  width: "3px",
                  height: "20px",
                  backgroundColor: "primary.main",
                  borderRadius: "14px",
                }}
              ></Box>
              <Typography
                ml={"15px"}
                fontSize={"18px"}
                fontWeight={"600"}
                color={"black.1000"}
              >
                Post story
              </Typography>
            </Stack>
            <Typography
              mt={"5px"}
              paddingInline={"20px"}
              textAlign={"left"}
              fontSize={"14px"}
              sx={{ opacity: "0.69", color: "black.350" }}
            >
              A "post" is a piece of content that you share and It can be text,
              an image, a video, a link, or a combination of these.
            </Typography>
          </Button>

          {/* raiae fund button */}
          <Button
            sx={{
              textTransform: "unset",
              marginTop: "20px",
              display: "block",
              width: "100%",
              boxShadow: `0px 0px 5px 0px  ${alpha(
                palette.black["1000"],
                0.15
              )}`,
              padding: "20px 15px 20px 10px",
              borderRadius: "10px",
            }}
            to="/create-raise"
            component={Link}
            onClick={closeComponent}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"stretch"}
              justifyContent={"stretch"}
            >
              <Box
                component={"span"}
                sx={{
                  width: "3px",
                  height: "20px",
                  backgroundColor: "primary.main",
                  borderRadius: "14px",
                }}
              ></Box>
              <Typography
                ml={"15px"}
                fontSize={"18px"}
                fontWeight={"600"}
                color={"black.1000"}
              >
                Raise fund
              </Typography>
            </Stack>
            <Typography
              mt={"5px"}
              paddingInline={"20px"}
              textAlign={"left"}
              fontSize={"14px"}
              sx={{ opacity: "0.69", color: "black.350" }}
            >
              A post created to raise funds for a specific cause and It usually
              includes information about the fundraising goal and how people can
              contribute.
            </Typography>
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              textTransform: "capitalize",
              marginTop: "30px",
              paddingBlock: "15px",
              borderRadius: "10px",
            }}
            onClick={closeComponent}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};
