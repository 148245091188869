import { Box, Button, CardMedia, Stack, Typography } from "@mui/material";
import React from "react";
import DynamicMeta from "../utils/DynamicMeta";
import notFoundPng from "../assets/404.png";
import Layout from "../component/Layout";
import useThemeStore from "../hooks/store/useThemeStore";
import { Link } from "react-router-dom";

export default function NotFound() {
  const { darkMode } = useThemeStore();
  return (
    <Layout>
      <DynamicMeta title="Page Not Found | Ploom Social" />

      <Box
        minWidth={"100%"}
        display={"flex"}
        justifyContent={"center"}
        paddingInline={"15px"}
      >
        <Box
          sx={{
            mt: { xs: "15px", md: "30px" },
            padding: "30px",
            maxWidth: "673px",
            maxHeight: { md: "642px" },
            width: "100%",
            height: "100%",
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "15px",
            zIndex: 50,
          }}
        >
          <CardMedia
            component="img"
            image={notFoundPng}
            alt="404"
            sx={{ width: { xs: "100%", md: "487px" } }}
          />

          <Stack sx={{ mt: { xs: "50px", md: "30px" } }}>
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontSize: "26px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
            >
              Page not Found
            </Typography>
            <Typography
              sx={{
                mt: "15px",
                maxWidth: "196px",
                color: darkMode ? "white.1000" : "neutral.350",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "22px",
                letterSpacing: "-0.408px",
                opacity: darkMode ? 0.7 : 1,
              }}
            >
              It Seems like something is missing in the box!
            </Typography>
          </Stack>

          <Button
            component={Link}
            to="/"
            variant="contained"
            fullWidth
            sx={{
              mt: { xs: "25px", md: "15px" },
              borderRadius: "10px",
              paddingBlock: "15px",
              backgroundColor: "primary.disabledDark",
              textAlign: "center",
              color: "white.650",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "22px",
              letterSpacing: "-0.408px",
              textTransform: "unset",
            }}
          >
            Go Home
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}
