import { createSvgIcon } from "@mui/material";
// const color=props.color;

export default function UploadIcon(props) {
  const color = props.color;
  const CreateUploadIcon = createSvgIcon(
    <g id="Frame">
      <g id="Group">
        <path
          id="Vector"
          d="M29.6874 13.8051C29.1472 11.6455 27.901 9.72838 26.1466 8.35805C24.3923 6.98771 22.2304 6.24275 20.0043 6.24146C17.7782 6.24016 15.6155 6.98261 13.8596 8.35091C12.1037 9.71921 10.8552 11.6349 10.3124 13.7938C7.75224 14.0341 5.38298 15.2514 3.69681 17.1929C2.01065 19.1343 1.13718 21.6507 1.25785 24.2193C1.37852 26.788 2.48405 29.2114 4.3448 30.9862C6.20555 32.7611 8.67849 33.7509 11.2499 33.7501H14.9999C15.3315 33.7501 15.6494 33.6184 15.8838 33.384C16.1183 33.1495 16.2499 32.8316 16.2499 32.5001C16.2499 32.1686 16.1183 31.8506 15.8838 31.6162C15.6494 31.3818 15.3315 31.2501 14.9999 31.2501H11.2499C10.265 31.253 9.2892 31.0618 8.37816 30.6875C7.46712 30.3133 6.63871 29.7633 5.94024 29.0688C4.52962 27.6664 3.73388 25.7611 3.72808 23.772C3.72227 21.7828 4.50689 19.8729 5.90931 18.4622C7.31173 17.0516 9.21708 16.2559 11.2062 16.2501C11.5269 16.2742 11.8452 16.1784 12.0993 15.9811C12.3533 15.7839 12.5251 15.4993 12.5812 15.1826C12.8357 13.3977 13.7257 11.7645 15.0876 10.583C16.4495 9.40151 18.192 8.75104 19.9949 8.75104C21.7979 8.75104 23.5404 9.40151 24.9023 10.583C26.2642 11.7645 27.1542 13.3977 27.4087 15.1826C27.4741 15.4883 27.6435 15.7618 27.8881 15.9565C28.1328 16.1512 28.4374 16.255 28.7499 16.2501C30.7391 16.2501 32.6467 17.0403 34.0532 18.4468C35.4598 19.8533 36.2499 21.761 36.2499 23.7501C36.2499 25.7392 35.4598 27.6469 34.0532 29.0534C32.6467 30.4599 30.7391 31.2501 28.7499 31.2501H24.9999C24.6684 31.2501 24.3505 31.3818 24.1161 31.6162C23.8816 31.8506 23.7499 32.1686 23.7499 32.5001C23.7499 32.8316 23.8816 33.1495 24.1161 33.384C24.3505 33.6184 24.6684 33.7501 24.9999 33.7501H28.7499C31.3026 33.7234 33.7484 32.7214 35.5861 30.9496C37.4239 29.1777 38.5144 26.7701 38.6343 24.2202C38.7541 21.6702 37.8942 19.1709 36.2308 17.2345C34.5674 15.2981 32.2263 14.0711 29.6874 13.8051Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M25.3661 24.6337C25.6019 24.8614 25.9177 24.9874 26.2454 24.9846C26.5731 24.9817 26.8867 24.8503 27.1184 24.6185C27.3502 24.3867 27.4816 24.0732 27.4845 23.7455C27.4873 23.4177 27.3613 23.102 27.1336 22.8662L20.8836 16.6162C20.6492 16.3819 20.3314 16.2502 19.9999 16.2502C19.6684 16.2502 19.3506 16.3819 19.1161 16.6162L12.8661 22.8662C12.6385 23.102 12.5125 23.4177 12.5153 23.7455C12.5182 24.0732 12.6496 24.3867 12.8814 24.6185C13.1131 24.8503 13.4267 24.9817 13.7544 24.9846C14.0821 24.9874 14.3979 24.8614 14.6336 24.6337L18.7499 20.5175V36.25C18.7499 36.5815 18.8816 36.8994 19.116 37.1339C19.3504 37.3683 19.6684 37.5 19.9999 37.5C20.3314 37.5 20.6494 37.3683 20.8838 37.1339C21.1182 36.8994 21.2499 36.5815 21.2499 36.25V20.5175L25.3661 24.6337Z"
          fill={color}
        />
      </g>
    </g>
  );
  return (
    <CreateUploadIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ scale: "2" }}
      {...props}
    />
  );
}
