import {
  Box,
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Stack,
  SwipeableDrawer,
  Typography,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import { useAllModal } from "../../hooks/store/useAllModal";
import MonetizationOnOutlinedIcon from "../../icons/MonetizationOnOutlinedIcon";
import { useAuth } from "../../hooks/store/useAuth";
import useApi from "../../hooks/useApi";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import GuestUserForm from "./GuestUserForm";
// import useRefresh from "../../hooks/useRefresh";
import PaymentGateway from "../nuvei/PaymentGateway";
import { useSnack } from "../../hooks/store/useSnack";
import { useNavigate } from "react-router-dom";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
export const PaymentDrawer = () => {
  // Nuvei payment code

  const { palette } = useTheme();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const { showPaymentDrawer, setShowPaymentDrawer, authorId, begId } =
    useAllModal();
  const { userId, isLoggedIn, isGuest, logout, user } = useAuth();
  const { apiCall } = useApi();
  const { setSnack } = useSnack();
  // const refresh = useRefresh();
  const [activeButton, setActiveButton] = useState("1");
  const [amount, setAmount] = useState(5);
  const [showInput, setShowInput] = useState(false);
  const [highLightError, setHighLightError] = useState(false);
  // const [openStripeForm, setOpenStripeForm] = useState(false);
  const [anonymous, setAnonymous] = useState(false);
  // const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [sessionToken, setSessionToken] = useState("");
  const navigate = useNavigate();

  const allButtons = [
    {
      actionValue: "1",
      amount: 5,
    },
    {
      actionValue: "2",
      amount: 10,
    },
    {
      actionValue: "3",
      amount: 25,
    },
    {
      actionValue: "4",
      amount: 50,
    },
    {
      actionValue: "custom",
      amount: 5,
    },
  ];

  async function getNuveiSessionToken() {
    try {
      // await refresh();
      let customAmount =
        amount === 5 || amount === 10 || amount === 25 || amount === 50
          ? false
          : true;
      const result = await apiCall({
        method: "post",
        url: "v3/webhooks/nuvei/",
        data: {
          begId: begId,
          amount: amount,
          payeeId: authorId,
          userId: isGuest ? undefined : userId,
          email: isGuest ? user.email : undefined,
          transaction: "chipin",
          anonymous: anonymous,
          customAmount: customAmount,
        },
      });
      setSessionToken(result.data.data.sessionToken);
    } catch (error) {
      if (error.response.status === 500) {
        setSnack(error.response.data.message, "error");
        setShowPaymentDrawer(false);
        return navigate("/404");
      }
    }
  }

  function HandleClick(e) {
    e.stopPropagation();
  }

  function closeComponent() {
    // set default value before close drawer
    setAmount(5);
    setActiveButton("1");
    setShowInput(false);
    setHighLightError(false);
    setAnonymous(false);
    setLoading(false);
    setShowPaymentDrawer(false);
    setSessionToken("");
    isGuest && logout();

    // setOpenStripeForm(false);
  }

  function setActiveButtonHadler(btnId, amount, showInput) {
    setActiveButton(btnId);
    setAmount(amount);
    setShowInput(showInput);
    setHighLightError(false);
  }

  function amountInputHandler(e) {
    let value = parseInt(e.target.value);

    setAmount(isNaN(value) ? "" : value);
    if (e.target.value <= 4 || e.target.value.length < 1) {
      setHighLightError(true);
    } else {
      setHighLightError(false);
    }
  }

  function incrementAmount() {
    setAmount((prev) => prev + 5);
    setHighLightError(false);
  }

  function decrementAmount() {
    if (amount - 5 >= 5) {
      setAmount((prev) => prev - 5);
      setHighLightError(false);
    } else {
      setHighLightError(true);
    }
  }

  function anonymousHandler(e) {
    setAnonymous(e.target.checked);
  }

  async function submitHandler(e) {
    if (amount >= 5) {
      setLoading(true);
      e.preventDefault();
      await getNuveiSessionToken();
      setLoading(false);
    } else {
      e.preventDefault();
      setSnack("amount must be greater than or equal to 5 needed", "warning");
    }
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={showPaymentDrawer}
      onClose={() => {
        closeComponent();
      }}
      onOpen={() => setShowPaymentDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      sx={{
        zIndex: 9000,
        backgroundColor: alpha(palette.black["1000"], 0.5),
        "& *.css-18tmwsn": {
          backgroundColor: "transparent",
          maxWidth: "445px",
          margin: "0 auto",
        },
        "& .css-4w5voh-MuiPaper-root-MuiDrawer-paper": {
          background: "transparent",
          maxWidth: "450px",
          margin: "0 auto",
        },
      }}
    >
      {/* if user is not logged in then get email form user and then redirect user to numvei payment form  */}
      {isLoggedIn === false && isGuest === false && (
        <>
          <GuestUserForm />
        </>
      )}

      {/* get amount form  */}
      {sessionToken === "" && (isLoggedIn === true || isGuest === true) && (
        <Box
          sx={{
            // marginTop: { xs: "70px" },
            padding: {
              xs: "8px 18px 20px 18px",
              sm: "22px 20px 120px 20px",
              md: "22px 20px 20px 20px",
            },
            maxWidth: "450px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white.1000",
            borderTopRightRadius: "15px",
            borderTopLeftRadius: "15px",
            marginInline: "auto",
          }}
          onClick={HandleClick}
        >
          {/* chipin amount form */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            component={"form"}
            onSubmit={submitHandler}
          >
            {/* amount button list */}
            <Stack
              mt={3}
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={{ xs: "6px", md: "12px" }}
            >
              {allButtons.map((button) => (
                <Button
                  key={button.actionValue}
                  variant={
                    activeButton === button.actionValue
                      ? "contained"
                      : "outlined"
                  }
                  sx={{
                    minWidth: "unset",
                    maxWidth: "unset",
                    borderRadius: "24px",
                    textTransform: "capitalize",
                    lineHeight: { xs: "12px", sm: "1.75" },
                    padding: { xs: "10px", ssm: "10px 15px", sm: "5px 15px" },
                  }}
                  onClick={() => {
                    setActiveButtonHadler(
                      button.actionValue,
                      button.amount,
                      button.actionValue !== "custom" ? false : true
                    );
                  }}
                >
                  {button.actionValue !== "custom"
                    ? `$${button.amount}`
                    : "custom"}
                </Button>
              ))}
            </Stack>

            {/* amount input type with increment and decrement button */}
            {showInput && (
              <Stack
                mt={4}
                width={"100%"}
                display={"grid"}
                gridTemplateColumns={".5fr 2fr .5fr"}
                borderRadius={"8px"}
                overflow={"hidden"}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: "100%",
                    minWidth: "100%",
                    maxWidth: "100%",
                    borderRadius: "0px !important",
                  }}
                  onClick={decrementAmount}
                >
                  -
                </Button>
                <FormControl
                  variant="filled"
                  sx={{
                    backgroundColor: "white.1000",
                    border: "none",
                    "& .css-ag3zef-MuiInputBase-root-MuiFilledInput-root": {
                      backgroundColor: "white.1000",
                    },
                    "& .css-1ynp08w-MuiFormControl-root .css-ag3zef-MuiInputBase-root-MuiFilledInput-root":
                      {
                        backgroundColor: "white.1000",
                      },
                    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
                      {
                        appearance: "none",
                      },
                    "& .css-ag3zef-MuiInputBase-root-MuiFilledInput-root:after,.css-ag3zef-MuiInputBase-root-MuiFilledInput-root:before ":
                      {
                        display: "none",
                      },
                  }}
                >
                  <InputLabel htmlFor="filled-adornment-amount">
                    Amount
                  </InputLabel>
                  <FilledInput
                    onInput={amountInputHandler}
                    value={amount}
                    id="filled-adornment-amount"
                    type="number"
                    sx={{ "input:is(:focus,:hover)": { border: "none" } }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </FormControl>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: "100%",
                    minWidth: "100%",
                    maxWidth: "100%",
                    borderRadius: "0px !important",
                  }}
                  onClick={incrementAmount}
                >
                  +
                </Button>
              </Stack>
            )}

            {showInput && (
              <Typography
                mt={1}
                fontSize={{ xs: "12px", md: "14px" }}
                textAlign={"left"}
                width={"100%"}
                color={highLightError ? "red" : "black.1000"}
              >
                Minimum amount is $5.
              </Typography>
            )}

            <FormControlLabel
              control={
                <Checkbox onChange={anonymousHandler} checked={anonymous} />
              }
              label="Make my support anonymous"
              sx={{ mt: { xs: 1, sm: 2 }, display: "block", width: "100%" }}
            />

            <LoadingButton
              type="submit"
              startIcon={<MonetizationOnOutlinedIcon />}
              loading={loading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              sx={{
                mt: { xs: 1, sm: 2 },
                padding: "12px 16px",
                borderRadius: "32px",
                textTransform: "capitalize",
              }}
            >
              <span>Donate</span>
            </LoadingButton>

            <Typography
              sx={{
                mt: 3,
                textTransform: "unset",
                color: "black.500",
                fontSize: "14px",
              }}
            >
              By donating and continuing, you agree with PloomSocial Terms &
              Privacy Policy.
            </Typography>
          </Box>
        </Box>
      )}

      {/* jump user to nuvei payment form is user is loggedin */}
      {sessionToken !== "" && (isLoggedIn === true || isGuest === true) && (
        <>
          <PaymentGateway
            amount={amount}
            sessionToken={sessionToken}
            setSessionToken={setSessionToken}
            setAmount={setAmount}
            setActiveButton={setActiveButton}
            getNuveiSessionToken={getNuveiSessionToken}
            begId={begId}
            email={user.email}
            name={user.name}
            clearData={closeComponent}
          />
        </>
      )}
    </SwipeableDrawer>
  );
};
