import React, { useCallback, useEffect, useState } from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import { EditRaiseForm } from "../component/editRaise/EditRaiseForm";
import useApi from "../hooks/useApi";
import { useNavigate, useParams } from "react-router-dom";
import { APIS } from "../api/lists";
import { useAuth } from "../hooks/store/useAuth";
import EditFormSkeleton from "../component/editRaise/Skeleton/EditFormSkeleton";

const UpdateRaise = () => {
  const { isLoggedIn } = useAuth();
  const params = useParams();
  const { apiCall } = useApi();
  const { userId } = useAuth();
  const [begDetails, setBegDetails] = useState();
  const navigate = useNavigate();

  // effect function for prevent unauthorised access
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBegData = useCallback(async () => {
    let result = await apiCall({
      url: APIS.FEED.BEG_BY_ID(params.id, userId),
      method: "GET",
    });
    setBegDetails(result.data.data);
  }, [userId, params.id, apiCall]);
  useEffect(() => {
    getBegData();
  }, [getBegData]);

  return (
    <>
      <BackButtonLayout
        isTitleBold
        pageTitle={begDetails?.begType === 1 ? "Edit a Raise" : "Edit a Story"}
        sx={{ minWidth: { lg: "987px" } }}
      >
        {!begDetails && <EditFormSkeleton />}
        {begDetails && <EditRaiseForm begDetails={begDetails}></EditRaiseForm>}
      </BackButtonLayout>
    </>
  );
};

export default UpdateRaise;
