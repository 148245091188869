import BackButtonLayout from "../component/ui/BackButtonLayout";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import EditIcon2 from "../icons/EditIcon2";
import { useTheme } from "@emotion/react";
import useThemeStore from "../hooks/store/useThemeStore";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/css";
import "swiper/css/pagination";
import Slider from "../component/editRaise/Slider";
import BegDetails from "../component/editRaise/BegDetails";
import { useCallback, useEffect, useState } from "react";
import useApi from "../hooks/useApi";
import { APIS } from "../api/lists";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/store/useAuth";
import moment from "moment";
import DashboardSkeleton from "../component/editRaise/Skeleton/DashboardSkeleton";
import { socket } from "../socket";

const EditRaiseDashboard = () => {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const { apiCall, isLoading } = useApi();
  const params = useParams();
  const { isLoggedIn, userId } = useAuth();
  const [begDetails, setBegDetails] = useState();
  const [showAddBankText, setShowAddBankText] = useState(false);
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();

  // effect function for prevent unauthorised access
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // raise socket
  // useEffect(() => {
  //   socket.connect();
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // join user in room
  useEffect(() => {
    // socket.emit("evntJoinUserToRoom", { userId });
  });
  //listen videos event
  useEffect(() => {
    socket.on("evntUpdateVideo", (videoDetail) => {
      setVideos((prevVideo) =>
        prevVideo.map((video) => {
          if (video._id === videoDetail.videoId) {
            video = videoDetail.video;
          }
          return video;
        })
      );
    });
  }, []);

  // get beg details
  const getBegData = useCallback(async () => {
    let result = await apiCall({
      url: APIS.FEED.BEG_BY_ID(params.id, userId),
      method: "GET",
    });
    let response = await apiCall({ url: APIS.BANK_ACCOUNT.LIST(userId) });
    if (response.data.success === true) {
      if (response.data.data.results.length <= 0) {
        setShowAddBankText(true);
      }
    }
    const begData = result.data.data;
    setBegDetails(begData);
    setVideos(begData.videos || []);
    // ownership checker
    if (userId !== result.data.data.userId) {
      navigate("/404");
    }
  }, [userId, params.id, apiCall, navigate]);
  useEffect(() => {
    getBegData();
  }, [getBegData]);

  let begInfo = [
    {
      title: `${
        (begDetails && begDetails.hasOwnProperty("endedDate")) ||
        (begDetails &&
          begDetails.hasOwnProperty("goalDate") &&
          begDetails.begType !== 2)
          ? begDetails.endedDate
            ? "Raise Complete"
            : "Start and End Dates"
          : "Start date"
      }`,
      count: `${moment(begDetails?.createdAt).format("MMM DD")} ${
        begDetails?.endedDate
          ? `- ${moment(begDetails?.endedDate).format("MMM DD")}`
          : begDetails &&
            begDetails.hasOwnProperty("goalDate") &&
            begDetails.begType !== 2
          ? `- ${moment(begDetails?.goalDate).format("MMM DD")}`
          : ""
      }`,
    },
    { title: "Donors", count: begDetails?.donors },
    // { title: "Shares", count: begDetails?.shares },
  ];

  return (
    <>
      <BackButtonLayout
        isTitleBold
        pageTitle={
          isLoading ? "" : "My PloomSocial Dashboard"
          //`My ${begDetails?.begType === 1 ? "Raise" : "Story"} Dashboard`
        }
        sx={{ minWidth: { lg: "673px" } }}
      >
        {/* title and edit button */}
        {begDetails && (
          <Box>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
              >
                {begDetails?.title}
              </Typography>
              <IconButton
                component={Link}
                to={`/edit-raise/${begDetails?._id}`}
                aria-label="delete"
                size="large"
                sx={{ background: darkMode ? "grey.550" : "unset" }}
              >
                <EditIcon2
                  color={darkMode ? palette.white[1000] : palette.black[1000]}
                />
              </IconButton>
            </Stack>
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "primary.disabledLight",
                opacity: darkMode ? 0.7 : 1,
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              by @{begDetails?.author?.username}
            </Typography>
          </Box>
        )}

        {/* slider & text content  container*/}
        {begDetails && (
          <Box
            sx={{
              mt: "15px",
              display: "grid",
              gridTemplateColumns: { xs: "1fr" },
            }}
          >
            <Slider videos={videos} begDetails={begDetails} />
            <BegDetails
              showAddBankText={showAddBankText}
              begInfo={begInfo}
              begDetails={begDetails}
            />
          </Box>
        )}

        {/* {skeleton loading animation} */}
        {!begDetails && <DashboardSkeleton />}
      </BackButtonLayout>
    </>
  );
};

export default EditRaiseDashboard;
