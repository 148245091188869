import { Typography } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function Heading({ children, align = "left" }) {
  const { darkMode } = useThemeStore();

  return (
    <Typography
      variant="h2"
      color={darkMode ? "white.1000" : "black.950"}
      fontSize={{ xs: "16px", md: "18px" }}
      fontWeight={600}
      mb={{ xs: "10px", md: "15px" }}
      align={align}
    >
      {children}
    </Typography>
  );
}
