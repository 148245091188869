import React from "react";
import DialogBackground from "./DialogBackground";

export default function CanNotDeleteDialog({
  isLoading = false,
  confirmCall,
  cancelCall,
}) {
  return (
    <DialogBackground
      heading={"Delete Account"}
      description="You don’t have any other account so you can not delete , First please add another account."
      cancelCall={cancelCall}
      confirmCall={confirmCall}
      isLoading={isLoading}
      confirmLabel="Add Account"
    />
  );
}
