import { createSvgIcon } from "@mui/material";
import React from "react";

export default function EmailIcon(props) {
  const CreateNextDoorIcon = createSvgIcon(
    <>
      <path
        d="M22.2754 42.5508C33.197 42.5508 42.0508 33.697 42.0508 22.7754C42.0508 11.8537 33.197 3 22.2754 3C11.3537 3 2.5 11.8537 2.5 22.7754C2.5 33.697 11.3537 42.5508 22.2754 42.5508Z"
        fill="#41A5EE"
      />
      <path
        d="M24.1928 23.4223L31.4361 16.1058C31.2761 16.038 31.1014 16 30.9181 16H13.6528C13.4694 16 13.2948 16.038 13.1348 16.1058L20.3781 23.4223C21.4394 24.4946 23.1308 24.4946 24.1928 23.4223Z"
        fill="white"
      />
      <path
        d="M20.1278 24.1907L12.5072 16.6445C12.3672 16.8673 12.2852 17.1329 12.2852 17.4187V28.5816C12.2852 29.3654 12.8972 30.0005 13.6525 30.0005H30.9178C31.6732 30.0005 32.2852 29.3654 32.2852 28.5816V17.4194C32.2852 17.1336 32.2032 16.8673 32.0632 16.6452L24.4425 24.1914C23.2352 25.3868 21.3352 25.3868 20.1278 24.1914V24.1907Z"
        fill="white"
      />
    </>
  );
  return (
    <CreateNextDoorIcon
      width="36"
      height="36"
      viewBox="0 0 45 45"
      fill="none"
      sx={{
        "&.MuiSvgIcon-root": { width: "unset", height: "unset" },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
