import { Divider, ListItem, Typography, alpha, useTheme } from "@mui/material";

import React from "react";
import { formatNumberCount } from "../../utils/NumberFormater";
import useThemeStore from "../../hooks/store/useThemeStore";
import UserListAvatar from "./UserListAvatar";

const DonationListItem = ({ item }) => {
  const { darkMode } = useThemeStore();
  const { amount = 0, user, createdAt } = item;
  const { palette } = useTheme();
  return (
    <>
      <ListItem
        sx={{
          display: "flex",
          gap: "30px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <UserListAvatar
          timestamp={createdAt}
          profileImage={user?.profileImage}
          username={user?.username}
          sx={{ flexShrink: 0 }}
          userId={user?._id}
        />
        {/* <Typography
        fontSize={{ xs: "10px", md: "12px" }}
        fontWeight={"500"}
        color={"black.350"}
        sx={{
          flexGrow: "1",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: "1",
          whiteSpace: "normal",
          "&::first-letter": {
            textTransform: "capitalize",
          },
          color: darkMode ? alpha(palette.white["1000"], 0.8) : "black.1000",
        }}
      >
        {beg?.title?.charAt(0).toUpperCase() + beg?.title?.slice(1)}
      </Typography> */}
        <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000 ",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "16px /* 88.889% */",
          }}
        >
          ${formatNumberCount(amount)}
        </Typography>
      </ListItem>
      <Divider
        sx={{
          marginBlock: "7px",
          borderColor: darkMode
            ? "black.250"
            : alpha(palette.black["1000"], 0.1),
        }}
      />
    </>
  );
};

export default DonationListItem;
