import { Avatar, Box, ListItemAvatar, Typography, alpha } from "@mui/material";
import moment from "moment";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useAuth } from "../../hooks/store/useAuth";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function UserListAvatar({
  profileImage = "",
  username = "",
  timestamp,
  userId = "",
  sx = {},
}) {
  const { userId: loggedInUserId } = useAuth();
  const profileLink =
    loggedInUserId === userId ? "/profile" : "/profile/" + userId;
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <ListItemAvatar
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        width: "150px",
        flexShrink: "0",
        p: "0",
        mt: 0,
        ...sx,
      }}
    >
      <Avatar
        alt="Remy Sharp"
        src={profileImage}
        sx={{
          backgroundColor: darkMode
            ? alpha(palette.black[1000], 0.2)
            : "primary.disabledLight",
          flexShrink: "0",
          width: "40px",
          height: "40px",
        }}
        component={userId ? Link : "div"}
        to={profileLink}
      />
      <Box>
        <Typography
          fontSize={{ xs: "12px", md: "14px" }}
          fontWeight={"700"}
          sx={{
            textTransform: "capitalize",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "16px /* 133.333% */",
            textDecoration: "none",
          }}
          component={userId ? Link : "div"}
          to={profileLink}
        >
          {username || "anonymous"}
        </Typography>
        <Typography
          fontSize={{ xs: "10px", md: "11px" }}
          fontWeight={"500"}
          color={"black.350"}
          sx={{
            fontFamily: "Poppins",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "16px",
            color: darkMode
              ? alpha(palette.white["1000"], 0.8)
              : alpha(palette.black["1000"], 0.5),
          }}
        >
          {moment(timestamp).format("LL")}
        </Typography>
      </Box>
    </ListItemAvatar>
  );
}
