import {
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import UploadIcon from "../../icons/UploadIcon";
import CrossTransparentIcon from "../../icons/CrossTransparentIcon";
import DragDropBgIcon from "../../icons/DragDropBgIcon";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import heic2any from "heic2any";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ErrorIcon from "@mui/icons-material/Error";
import CurrentMedia from "./CurrentMedia";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function DragAndDropInput({
  errors,
  onChange = () => {},
  UploadLimit,
  allowImage = false,
  beg,
  setUploadLimit = () => {},
  draft = false,
}) {
  const [files, setFiles] = useState([]);
  const fileInput = useRef();
  const filesRef = useRef([]);
  const [errorMessage, setErrorMessage] = useState("");
  const remainingSpot = 10 - files.length;
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const [processing, setProcesssing] = useState(false);
  useEffect(() => {
    // Compare the current array with the previous one
    if (files.length !== filesRef.current.length) {
      // Array has changed, execute your desired function here
      onChange(files);

      // Update the previous array reference
      filesRef.current = files;
    }
  }, [files, onChange]);

  useEffect(() => {
    let timeout;
    if (errorMessage && errorMessage === "")
      timeout = setTimeout(setErrorMessage.bind(null, ""), 2000);
    return clearTimeout.bind(this, timeout);
  }, [errorMessage]);

  // append files in preview list
  async function fileHandler(uploadedFiles, remainingFiles) {
    const newFiles = [];
    setProcesssing(Array.from(uploadedFiles));
    const loopEndPoint =
      remainingFiles > uploadedFiles.length
        ? uploadedFiles.length
        : remainingFiles;
    for (let i = -1; i++ < loopEndPoint - 1; ) {
      let file = uploadedFiles[i];

      if (String(file.type).slice(0, 5) === "video") {
        file.isBig = file.size / 1048576 > 100;
      }

      // Check if the file already exists in the files array
      if (!files.find((f) => f.file.name === file.name)) {
        // if format is heic then convert it into jpeg
        if (String(file.name).endsWith(".heic")) {
          try {
            file = await heic2any({ blob: file });
            newFiles.push({ file, url: URL.createObjectURL(file) });
          } catch (error) {
            console.error("HEIC to JPEG conversion failed:", error);
          }
        } else {
          newFiles.push({ file, url: URL.createObjectURL(file) });
        }
      }
    }
    if (newFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
    if (remainingFiles === 0) {
      setErrorMessage("Maximum limit of files is 10");
    } else if (uploadedFiles.length > remainingFiles) {
      setErrorMessage(
        "Maximum limit of files is 10, so extra files were automatically removed"
      );
    } else {
      setErrorMessage("");
    }
    // Reset the input value to clear the selected files
    fileInput.current.value = null;
    setProcesssing(false);
  }

  //remove file from preview list
  function removeFile(url) {
    setFiles((prevFiles) => prevFiles.filter((file) => file.url !== url));
    fileInput.current.value = null;
  }
  // handle input on change event
  function fileInputHandler(event) {
    fileHandler(event.target.files, remainingSpot);
    // fileInput.current.click();
  }

  useEffect(() => {
    const dropElement = fileInput.current;
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { files: uploadedFiles } = e.dataTransfer;
      fileHandler(uploadedFiles, remainingSpot);
    };
    dropElement.addEventListener("dragover", handleDragOver);
    dropElement.addEventListener("drop", handleDrop);

    return () => {
      dropElement.removeEventListener("dragover", handleDragOver);
      dropElement.removeEventListener("drop", handleDrop);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingSpot]);
  return (
    <>
      <Box
        ref={fileInput}
        sx={{
          marginTop: "24px",
          width: "100%",
          display: "grid",
          placeItems: "center",
          gap: "12px",
          position: "relative",
          cursor: processing ? "no-drop" : "pointer",
        }}
        htmlFor="uploadFile"
        component={"label"}
      >
        <DragDropBgIcon
          width="100%"
          height="100%"
          color={darkMode ? palette.black["200"] : palette.primary["main"]}
          bg={darkMode ? palette.grey["550"] : palette.white["1000"]}
          border={darkMode ? palette.neutral["650"] : palette.white["400"]}
        ></DragDropBgIcon>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {processing ? (
            <CircularProgress
              sx={{ color: darkMode ? "white.1000" : "primary.main" }}
            />
          ) : (
            <UploadIcon
              color={darkMode ? palette.white["1000"] : palette.primary["main"]}
            />
          )}
          <Typography
            sx={{
              mt: "20px",
              color: darkMode ? "white.1000" : "primary.main",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: 600,
            }}
          >
            {processing ? "Processing..." : "Upload Your Video Or Photo"}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "10px", sm: "12px" },
              fontWeight: 500,
              textTransform: "uppercase",
              fontStyle: " normal",
              lineHeight: "normal",
              letterSpacing: "-0.408px",
              color: darkMode ? "white.1000" : "primary.main",
              opacity: 0.85,
            }}
          >
            {!processing && "jpg,png,mp4 up to 100mb"}
          </Typography>
        </Box>
      </Box>

      {(files.length > 0 || processing) && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "15px",
            mt: { xs: "15px", md: "12.73px" },
          }}
        >
          {files.map((file) => (
            <Box
              key={file.url}
              sx={{
                height: { xs: "55px", sm: "55px", md: "73px" },
                width: { xs: "55px", sm: "55px", md: "73px" },
                borderRadius: "5px",
                overflow: "hidden",
                position: "relative",
                border: file.file.isBig ? "2px solid" : "none",
                borderColor: "red",
                backgroundColor: darkMode ? "grey.1000" : "grey.100",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  zIndex: 2,
                  top: { xs: "3.44px", md: "4.56px" },
                  right: { xs: "3.44px", md: "4.56px" },
                  padding: "1px",
                }}
                onClick={removeFile.bind(this, file.url)}
              >
                <CrossTransparentIcon
                  sx={{
                    height: { xs: "12.375px", md: "16.425px" },
                    width: { xs: "12.375px", md: "16.425px" },
                  }}
                />
              </IconButton>
              {file.file.isBig && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    top: { xs: "0px", md: "2px" },
                    left: { xs: "3.44px", md: "4.56px" },
                    p: 0,
                  }}
                >
                  <Tooltip title="This video file size is too big. at most size limit is 100mb">
                    <IconButton sx={{ p: 0 }}>
                      <ErrorIcon
                        sx={{
                          color: "white.1000",
                          fontSize: { xs: "14px", md: "18px" },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {file.file.type.includes("image") && (
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  src={file.url}
                  alt="local"
                />
              )}
              {file.file.type.includes("video") && (
                <video
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  src={file.url}
                  height={80}
                  width={80}
                ></video>
              )}
            </Box>
          ))}

          {/* skeleton for loading animation */}
          {processing &&
            processing.map((file, index) => (
              <Skeleton
                key={index}
                sx={{
                  backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
                  height: { xs: "55px", sm: "55px", md: "73px" },
                  width: { xs: "55px", sm: "55px", md: "73px" },
                  borderRadius: "5px",
                  objectFit: "cover",
                  transform: "scale(1) !important",
                }}
              />
            ))}
        </Box>
      )}
      {Boolean(errorMessage.length) && (
        <Typography
          children={errorMessage}
          color={"error.main"}
          mt={2}
          sx={{ fontSize: "0.75rem", fontWeight: 400, lineHeight: 1.66 }}
        />
      )}

      <VisuallyHiddenInput
        type="file"
        name="media"
        accept={allowImage ? "video/*, image/*" : "video/*"}
        multiple // This should allow multiple file selection
        id="uploadFile"
        sx={{ "& .MuiInputBase-root": { display: "none" } }}
        onChange={fileInputHandler}
        onClick={(event) => (event.target.value = null)}
        disabled={processing || UploadLimit === 0 ? true : false}
      />
      {Boolean(errors["file"]) && (
        <Stack
          component={"span"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ErrorOutlineRoundedIcon
            sx={{ color: "error.main", fontSize: "16px", mr: "10px" }}
          />
          <Box
            component={"span"}
            sx={{
              fontSize: "0.75rem",
              fontWeight: 400,
              lineHeight: 1.66,
              textAlign: "left",
              marginTop: "3px",
              marginRight: "14px",
              marginBottom: "0",
              color: "error.main",
            }}
          >
            {errors["file"]?.message}
          </Box>
        </Stack>
      )}

      {beg && (
        <Box sx={{ gridArea: "oldVideo" }}>
          <CurrentMedia
            hideRearrang={draft}
            allFilesUrl={beg.videos}
            begId={beg._id}
            setUploadLimit={setUploadLimit}
            draft={draft}
          />
        </Box>
      )}
    </>
  );
}
