import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import DynamicMeta from "../utils/DynamicMeta";

import OtpVerifyForm from "../component/editProfile/OtpVerifyForm";
import { useAuth } from "../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";
import { useRedirect } from "../hooks/store/useRedirect";
import AddNewMobileNo from "../component/editProfile/AddNewMobileNo";

const UpdateMobile = () => {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useAuth();
  const { setRedirectRoute } = useRedirect();
  const [openForm, setOpenForm] = useState("OTP"); //1.OTP 2.addMobile 3.otp
  const [mobile, setMobileNumber] = useState(user.mobile);
  const [updateMobile, setUpdateMobile] = useState(false);
  useEffect(() => {
    if (!isLoggedIn) {
      setRedirectRoute("/change-number");
      return navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DynamicMeta title="Change Password | Ploom" />
      <Box>
        {openForm === "OTP" && (
          <OtpVerifyForm
            setOpenForm={setOpenForm}
            useCase={"addMobile"}
            mobile={mobile}
            updateMobile={updateMobile}
          ></OtpVerifyForm>
        )}
        {openForm === "addMobile" && (
          <AddNewMobileNo
            setMobileNumber={setMobileNumber}
            setOpenForm={setOpenForm}
            setUpdateMobile={setUpdateMobile}
          ></AddNewMobileNo>
        )}
      </Box>
    </>
  );
};

export default UpdateMobile;
