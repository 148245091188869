import { createSvgIcon } from "@mui/material";
export default function ThumbsUpIcon(props) {
  const CreateUpArrowIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_90_25474)">
        <path
          d="M0.599209 9.28703C0.272451 9.28703 0.00756836 9.55196 0.00756836 9.87867V18.5684C0.00756836 18.8952 0.27249 19.1601 0.599209 19.1601H4.16378V9.28703H0.599209ZM19.9924 12.5542C19.9924 11.8031 19.5888 11.1445 18.9872 10.7838C19.2258 10.4399 19.3534 10.0311 19.3529 9.61254C19.3529 8.47532 18.4276 7.55008 17.2904 7.55008H12.9366C13.07 6.94539 13.2525 6.03801 13.3796 5.08813C13.7103 2.61621 13.4843 1.24532 12.6684 0.77387C12.1597 0.480003 11.6113 0.420393 11.1241 0.605862C10.7477 0.749221 10.2394 1.10168 9.9498 1.96672L8.8055 4.96285C8.22538 6.39555 6.44886 7.89965 5.3471 8.73133V19.4219C7.38905 20.1373 9.51983 20.5 11.6937 20.5H16.4376C17.5749 20.5 18.5001 19.5748 18.5001 18.4375C18.5006 18.0536 18.3933 17.6773 18.1903 17.3514C18.8779 17.0166 19.3528 16.3106 19.3528 15.4959C19.3533 15.0773 19.2257 14.6685 18.9871 14.3246C19.5888 13.9639 19.9924 13.3052 19.9924 12.5542Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_90_25474">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateUpArrowIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
