import { Box, Skeleton } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function FeedDetailSkeleton() {
  const { darkMode } = useThemeStore();
  return (
    <Box
      margin={"0 auto"}
      mb={{ xs: "16px", md: "30px" }}
      sx={{
        maxWidth: { xs: "400px", sm: "440px", md: "100%", lg: "100%" },
        width: "100%",
        padding: "15px",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
      gap={"10px"}
    >
      <Box flexShrink={0} width={{ xs: "100%", md: "50%" }}>
        <Skeleton
          variant="rounded"
          width={"100%"}
          animation="wave"
          sx={{
            maxHeight: "700px",
            height: { xs: "60vh", md: "100vh" },
            backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
          }}
        ></Skeleton>
      </Box>

      <Box
        flexGrow={1}
        display={"flex"}
        sx={{
          height: { xs: "100%", lg: "100vh" },
          maxHeight: "700px",
        }}
        flexDirection={"column"}
      >
        <Box display={"flex"} gap={"10px"}>
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
          />
          <Box>
            <Skeleton
              fontSize={{ xs: "12px", md: "14px" }}
              fontWeight={"700"}
              width="100px"
              sx={{
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
            ></Skeleton>
            <Skeleton
              fontSize={{ xs: "10px", md: "14px" }}
              fontWeight={"500"}
              color={"black.300"}
              variant="text"
              width="70%"
              sx={{
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
            ></Skeleton>
          </Box>
        </Box>

        <Box>
          <Skeleton
            sx={{
              mt: 2,
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
            variant="rounded"
            width={"80%"}
            height={"16px"}
          />
          <Skeleton
            sx={{
              mt: 4,
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
            variant="rounded"
            width={"100%"}
            height={"16px"}
          />
          <Skeleton
            sx={{
              mt: 0.4,
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
            variant="rounded"
            width={"100%"}
            height={"16px"}
          />
          <Skeleton
            sx={{
              mt: 0.4,
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
            variant="rounded"
            width={"100%"}
            height={"16px"}
          />
          <Skeleton
            sx={{
              mt: 0.4,
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
            }}
            variant="rounded"
            width={"80%"}
            height={"16px"}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Box display={"flex"} gap={"24px"}>
            <Skeleton
              variant="rounded"
              sx={{
                mt: 3,
                height: { xs: "40px", md: "50px" },
                width: { xs: "40px", md: "50px" },
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
            ></Skeleton>
            <Skeleton
              variant="rounded"
              sx={{
                mt: 3,
                height: { xs: "40px", md: "50px" },
                width: { xs: "40px", md: "50px" },
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
            ></Skeleton>
            <Skeleton
              variant="rounded"
              sx={{
                mt: 3,
                height: { xs: "40px", md: "50px" },
                width: { xs: "40px", md: "50px" },
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
            ></Skeleton>
          </Box>
          <Box display={"flex"} mt={2} gap={"24px"}>
            <Skeleton
              variant="rounded"
              width={"35%"}
              height={"50px"}
              sx={{
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
            />
            <Skeleton
              variant="rounded"
              width={"35%"}
              height={"50px"}
              sx={{
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
