/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import Routes from "./Routes";
import SplashScreen from "./page/SplashScreen";
import { HelmetProvider } from "react-helmet-async";
import { useAuth } from "./hooks/store/useAuth";
import Scrollbar from "./component/scrollbar/Scrollbar";
import useApi from "./hooks/useApi";
import { useUserVerification } from "./hooks/store/useUserVerification";
import { useSnack } from "./hooks/store/useSnack";
import GetInfoDialog from "./component/verifyOldUser/GetInfoDialog";
import EmailVerifyDialog from "./component/verifyOldUser/EmailVerifyDialog";
import { useSettings } from "./hooks/store/useSettings";
import DialogBox from "./component/Dialogbox/DialogBox";
import { socket } from "./socket";
import { useSocket } from "./hooks/store/useSocket";
import GlobalLoadingDrawer from "./component/commonDrawer/GlobalLoadingDrawer";
import NetConnectivityDialog from "./component/feed/dialog/NetConnectivityDialog";
import TermsDialog from "./component/terms&condtions/TermsDialog";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga4";
import AddAccountTypeDialog from "./component/verifyOldUser/chooseAccount/AddAccountTypeDialog";
import { requestMsgPermissions } from "./services/firebasePushNotification";
import { APIS } from "./api/lists";

export default function App() {
  // hotjar initialization
  if (process.env.REACT_APP_ENV === "production") {
    // Hotjar initialization
    Hotjar.init(
      window.location.host === "app.ploomsocial.com"
        ? process.env.REACT_APP_HOTJAR_SITEID_PLOOMSOCIAL
        : process.env.REACT_APP_HOTJAR_SITEID_PLOOMMEDIA,
      process.env.REACT_APP_HOTJAR_VERSION
    );

    //  Google analytics initialization
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
  }
  const [isLoading, setIsLoading] = React.useState(true);
  const {
    isLoggedIn,
    isGuest,
    logout,
    userId,
    setUserDatail,
    setUserProfile,
    setMobile,
    user,
    notificationPermission,
    setNotification,
  } = useAuth();
  const { apiCall } = useApi(true);
  const { apiCall: API } = useApi();
  const { setUserVerification } = useUserVerification();
  const { setSettings } = useSettings();
  const { setSnack } = useSnack();
  const { setSocketConnection, socketConnection } = useSocket();
  // const navigate = useNavigate();
  const [openDialog, SetOpenDialog] = React.useState(false);
  const [openTncDialog, setOpenTncDialog] = React.useState(false);
  let [openEmailVerify, setOpenEmailVerify] = React.useState(false);
  let [openSelectAcTypeDialog, setOpenSelectAcTypeDialog] =
    React.useState(false);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  // 1. retrieve all admin settings
  const getSettings = React.useCallback(
    async function () {
      let response = await API({
        url: "v3/settings/normal",
        method: "get",
      });
      setSettings(response.data.data.settings);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 2. update user data
  const getLatestUserData = React.useCallback(async () => {
    const res = await API({
      url: `v3/users/${userId}`,
    });
    let email = res.data.data.email;
    let mobile = res.data.data.mobile;
    let firstName = res.data.data.firstName;
    let lastName = res.data.data.lastName;
    let orgName = res.data.data.orgName;
    let profileImage = res.data.data.profileImage;
    let accountType = res.data.data.accountType;
    let ein = res.data.data.ein;
    setUserDatail(firstName, lastName, email, orgName, accountType, ein);
    profileImage && setUserProfile(profileImage);
    mobile && setMobile(mobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  React.useEffect(() => {
    if (isLoggedIn) {
      getLatestUserData();
    }
  }, [isLoggedIn, userId]);

  //3. check user mobile,email and kyc verification
  const verifyUserIdentity = React.useCallback(
    async function () {
      try {
        let response = await apiCall({
          url: "v2/accounts/check-verified-2",
          method: "post",
          data: { userId: userId },
        });
        let mobileVerification = response.data.data.mobile_verified.mobile;
        let mobileRequired = response.data.data.mobile_required.mobile;
        let emailVerification = response.data.data.email_verified.email;
        let kycVerification = response.data.data.isKyc.isKyc;
        let requiredReLogin =
          response.data.data.required_relogin.required_relogin;
        let termsAndCondition =
          response.data.data.terms_condition.terms_condition;
        let accountType = response.data.data.accountType.status;
        setUserVerification(
          emailVerification,
          mobileVerification,
          kycVerification
        );

        if (requiredReLogin) {
          logout();
          setSnack(
            "You need to log in again because there has been no activity for the past 15 days."
          );
        }

        if (mobileRequired) {
          SetOpenDialog(true);
          return setSnack(
            response.data.data.mobile_required.message,
            "warning"
          );
        }

        if (!emailVerification) {
          getLatestUserData();
          return setOpenEmailVerify(true);
        }
        if (!termsAndCondition) {
          setSnack(response.data.data.terms_condition.message, "warning");
          return setOpenTncDialog(true);
        }
        if (accountType) {
          setOpenSelectAcTypeDialog(true);
          return setSnack(response.data.data.accountType.message, "warning");
        }
      } catch (error) {
        if (error.response.status === 404) {
          logout();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiCall, setSnack, logout, setUserVerification]
  );

  React.useEffect(() => {
    if (isLoggedIn && process.env.REACT_APP_ENV === "production") {
      Hotjar.identify(userId, {
        first_name: user.firstName,
      });
    }
  }, []);

  // effect code for plash screen
  React.useEffect(() => {
    // Wait for 1 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    if (isLoggedIn) verifyUserIdentity();

    const handleBeforeUnload = () => {
      isGuest && logout();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  let helmetContext = {};

  // effect for track internet connectivity
  React.useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // useEffect for socket connection
  React.useEffect(() => {
    socket.on("connect", () => {
      setSocketConnection(true);
    });
    socket.on("connect_error", (err) => {
      setSocketConnection(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect for join socket room
  React.useEffect(() => {
    if (isLoggedIn && socketConnection) {
      socket.emit("evntJoinUserToRoom", { userId });
    }
  }, [isLoggedIn, userId, socketConnection]);

  // useEffect for collect fcm token
  React.useEffect(() => {
    if (isLoggedIn && notificationPermission === undefined) {
      async function getToken() {
        let token = await requestMsgPermissions(setNotification);
        if (token) {
          await API({
            url: APIS.NOTIFICATION.FCM_TOKEN,
            method: "POST",
            data: { fcmToken: token },
          });
        }
      }
      getToken();
    }
  }, [isLoggedIn]);

  return isLoading ? (
    <SplashScreen />
  ) : (
    <ThemeProvider theme={theme}>
      <Scrollbar />
      <HelmetProvider context={helmetContext}>
        <CssBaseline />
        <Routes open={openDialog} setOpen={SetOpenDialog} />
        {openDialog && (
          <GetInfoDialog
            open={openDialog}
            setOpen={SetOpenDialog}
            verifyUserIdentity={verifyUserIdentity}
          ></GetInfoDialog>
        )}

        {openEmailVerify && (
          <EmailVerifyDialog
            open={openEmailVerify}
            setOpen={setOpenEmailVerify}
            verifyUserIdentity={verifyUserIdentity}
          />
        )}
        {openSelectAcTypeDialog && (
          <AddAccountTypeDialog
            open={openSelectAcTypeDialog}
            setOpen={setOpenSelectAcTypeDialog}
            verifyUserIdentity={verifyUserIdentity}
            getLatestUserData={getLatestUserData}
          />
        )}
      </HelmetProvider>

      <DialogBox
        openDialog={!isOnline}
        close={false}
        Content={() => <NetConnectivityDialog />}
      ></DialogBox>
      <GlobalLoadingDrawer></GlobalLoadingDrawer>
      <TermsDialog
        openTncDialog={openTncDialog}
        setOpenTncDialog={setOpenTncDialog}
        updateRecord
        verifyUserIdentity={verifyUserIdentity}
      />
    </ThemeProvider>
  );
}
