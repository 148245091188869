import { ListItem, Typography, alpha } from "@mui/material";

import React from "react";
// import { formatNumberCount } from "../../utils/NumberFormater";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import UserListAvatar from "../user/UserListAvatar";
import { UsMoneyFormat } from "../../utils/UsMoneyFormat";

const DonationListItem = ({ item }) => {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const { amount = 0, beg, user, createdAt, taken } = item;
  return (
    <ListItem
      sx={{
        padding: "10px 6px",
        display: "flex",
        gap: { xs: "8px", sm: "30px" },
        alignItems: "center",
      }}
    >
      <UserListAvatar
        timestamp={createdAt}
        profileImage={user?.profileImage}
        username={user?.username}
        sx={{ flexShrink: 0 }}
        userId={user?._id}
      />
      <Typography
        fontSize={{ xs: "10px", md: "12px" }}
        fontWeight={"500"}
        color={"black.350"}
        sx={{
          flexGrow: "1",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: "1",
          whiteSpace: "normal",
          "&::first-letter": {
            textTransform: "capitalize",
          },
          color: darkMode ? alpha(palette.white["1000"], 0.8) : "black.1000",
        }}
      >
        {beg?.title?.charAt(0).toUpperCase() + beg?.title?.slice(1)}
      </Typography>
      <Typography
        sx={{
          color: taken === 1 ? "#FC4135" : "#34A56F",
          fontSize: { xs: "12px", sm: "16px", md: "18px" },
          fontWeight: "500",
          flexShrink: "0",
        }}
      >
        ${UsMoneyFormat(amount)}
      </Typography>
    </ListItem>
  );
};

export default DonationListItem;
