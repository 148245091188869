import React from "react";
// import ProfileAvatar from "../../user/ProfileAvatar";
import {
  Box,
  Typography,
  // Stack,
  // useTheme,
  // IconButton,
  // Collapse,
} from "@mui/material";

import moment from "moment/moment";
import Skelton from "./Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import useThemeStore from "../../../hooks/store/useThemeStore";
// import { useAuth } from "../../../hooks/store/useAuth";
// import { Link } from "react-router-dom";

// import ThumbsUpIcon from "../../../icons/ThumbsUpIcon";
// import UpArrowIcon from "../../../icons/UpArrowIcon";
// import SubComment from "./SubComment";
import CommentCard from "./CommentCard";

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "few seconds ago",
    ss: "%ss",
    m: "a minute ago",
    mm: "%d minute ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "a Day",
    dd: "%dd",
    M: "a month ago",
    MM: "%d month ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});

export default function FeedCommentCard({
  comments,
  setPage,
  totalPages,
  page,
  feedId,
  begOwnerId,
}) {
  const hashMore = page < totalPages;
  const { darkMode } = useThemeStore();

  return (
    <Box sx={{ marginTop: { xs: "7px", md: "19px" } }}>
      <InfiniteScroll
        dataLength={comments.length}
        hasMore={hashMore}
        style={{ overflow: "hidden" }}
        scrollableTarget="scrollableDiv"
        next={async () => {
          if (page < totalPages) {
            setPage((prevPage) => prevPage + 1);
          }
        }}
        loader={
          <Box
            sx={{
              "&>:not([hidden])~:not([hidden])": {
                mt: 4,
              },
            }}
          >
            <Skelton />
            <Skelton />
          </Box>
        }
      >
        {comments.map((comment, index) => (
          <CommentCard
            comment={comment}
            key={comment._id}
            feedId={feedId}
            begOwnerId={begOwnerId}
            pinned={comment.pinAt ? true : false}
          />
        ))}
      </InfiniteScroll>
      {comments.length === 0 && (
        <Box style={{ textAlign: "center" }} my={"30px"}>
          <Typography sx={{ color: darkMode ? "white.1000" : "black.1000" }}>
            No comment found.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
