import { createSvgIcon } from "@mui/material/utils";

export default function MonetizationOnOutlinedIconGrey(props) {
  //  let { palette } = useTheme();
  //  let color = palette.darkIcon["main"];

  const CreateMonetizationOnOutlinedIcon = createSvgIcon(
    <>
      <path
        d="M2.1063 8.29901H1.31921C0.867992 8.29901 0.5 8.6854 0.5 9.15916V15.14C0.5 15.6137 0.867996 16.0001 1.31921 16.0001H2.10647C2.5609 16.0001 2.9289 15.6137 2.9289 15.14L2.92874 9.15916C2.92874 8.68539 2.56073 8.29901 2.1063 8.29901Z"
        fill="white"
      />
      <path
        d="M15.4022 11.6184L12.0806 11.8737C11.8439 12.2433 11.447 12.4851 10.9926 12.4851H7.24525C7.06932 12.4851 6.92531 12.3339 6.92531 12.1492C6.92531 11.9645 7.06932 11.8133 7.24525 11.8133H10.9926H10.999C11.3287 11.8099 11.5686 11.447 11.4727 11.1177C11.3254 10.6272 10.8902 10.2709 10.3782 10.2709H8.40695C7.82124 10.2709 7.25814 10.0761 6.76209 9.75349C6.1893 9.37709 5.53006 9.17899 4.85488 9.17899H3.56852L3.56836 15.1195L9.41479 15.5798C9.42124 15.5798 9.43075 15.5832 9.4372 15.5832C9.46607 15.5832 9.49477 15.5765 9.52364 15.5699L15.7573 13.7151C16.1925 13.5841 16.4997 13.1642 16.4997 12.687C16.4997 12.388 16.3781 12.1024 16.1733 11.9009C15.9653 11.6989 15.6838 11.5948 15.4022 11.6183L15.4022 11.6184Z"
        fill="white"
      />
      <path
        d="M11.2032 9.49177C13.696 9.49177 15.7249 7.36156 15.7249 4.74415C15.7249 2.12683 13.6961 0 11.2032 0C8.7104 0 6.68488 2.12678 6.68488 4.74415C6.68488 7.36148 8.71061 9.49177 11.2032 9.49177ZM11.5772 5.08095H10.8306C10.2278 5.08095 9.73708 4.5657 9.73708 3.93281C9.73708 3.29988 10.2278 2.78503 10.8306 2.78503H10.8836L10.8838 2.40711C10.8838 2.22136 11.027 2.07118 11.2037 2.07118C11.3806 2.07118 11.5237 2.22154 11.5237 2.40711V2.78503H12.3506C12.5275 2.78503 12.6705 2.93538 12.6705 3.12095C12.6705 3.3067 12.5273 3.45688 12.3506 3.45688H10.8306C10.5806 3.45688 10.3771 3.67022 10.3771 3.93268C10.3771 4.19513 10.5805 4.40879 10.8306 4.40879H11.5772C12.18 4.40879 12.6707 4.92404 12.6707 5.55692C12.6707 6.18985 12.18 6.70471 11.5772 6.70471H11.5238V7.08263C11.5238 7.26837 11.3806 7.41855 11.2039 7.41855C11.027 7.41855 10.884 7.2682 10.884 7.08263V6.70471L10.0572 6.70488C9.88029 6.70488 9.73725 6.55452 9.73725 6.36895C9.73725 6.18321 9.88045 6.03302 10.0572 6.03302H11.5772C11.8272 6.03302 12.0307 5.81968 12.0307 5.55722C12.0307 5.29461 11.8271 5.08095 11.5772 5.08095Z"
        fill="white"
      />
    </>
  );
  return (
    <CreateMonetizationOnOutlinedIcon
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: { xs: "18px", md: "24px" },
        height: { xs: "18px", md: "24px" },
      }}
      {...props}
    />
  );
}
