import { useEffect, useState } from "react";
import useApi from "./useApi";
import { useAuth } from "./store/useAuth";

export default function useInfinite({
  url,
  method = "get",
  addUserId = false,
  dataKey = "results",
  limit = 10,
  data = {},
  reRender,
}) {
  const { userId } = useAuth();
  const { apiCall, isLoading } = useApi();
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const stringData = JSON.stringify(data);
  useEffect(() => {
    (async () => {
      try {
        let params = { page, limit };
        if (addUserId) {
          params.cuserId = userId;
        }
        const response = await apiCall({
          url,
          method,
          params,
          data: JSON.parse(stringData),
        });
        if (response.data.success === true) {
          const responseResults = response.data.data[dataKey];
          setResults((prevRecords) => {
            if (page === 1) {
              return responseResults || [];
            }
            return [...prevRecords, ...responseResults];
          });

          setTotalPages(response.data.data.pagination.pages);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [
    apiCall,
    url,
    method,
    page,
    addUserId,
    stringData,
    userId,
    limit,
    dataKey,
    reRender,
  ]);
  useEffect(() => {
    setPage(1);
  }, [reRender]);
  return {
    results,
    hasMore,
    page,
    setHasMore,
    totalPages,
    setPage,
    isLoading,
    setResults,
  };
}
