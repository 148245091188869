import { createSvgIcon } from "@mui/material";
import React from "react";

const CreateLikeFillIcon = createSvgIcon(
  <g id="Iconly/Bold/Heart">
    <g id="Heart">
      <path
        id="Heart_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8498 2.50065C16.4808 2.50065 17.1108 2.58965 17.7098 2.79065C21.4008 3.99065 22.7308 8.04065 21.6198 11.5806C20.9898 13.3896 19.9598 15.0406 18.6108 16.3896C16.6798 18.2596 14.5608 19.9196 12.2798 21.3496L12.0298 21.5006L11.7698 21.3396C9.4808 19.9196 7.3498 18.2596 5.4008 16.3796C4.0608 15.0306 3.0298 13.3896 2.3898 11.5806C1.2598 8.04065 2.5898 3.99065 6.3208 2.76965C6.6108 2.66965 6.9098 2.59965 7.2098 2.56065H7.3298C7.6108 2.51965 7.8898 2.50065 8.1698 2.50065H8.2798C8.9098 2.51965 9.5198 2.62965 10.1108 2.83065H10.1698C10.2098 2.84965 10.2398 2.87065 10.2598 2.88965C10.4808 2.96065 10.6898 3.04065 10.8898 3.15065L11.2698 3.32065C11.3616 3.36962 11.4647 3.44445 11.5538 3.50912C11.6102 3.55009 11.661 3.58699 11.6998 3.61065C11.7161 3.62028 11.7327 3.62996 11.7494 3.63972C11.8352 3.68977 11.9245 3.74191 11.9998 3.79965C13.1108 2.95065 14.4598 2.49065 15.8498 2.50065ZM18.5098 9.70065C18.9198 9.68965 19.2698 9.36065 19.2998 8.93965V8.82065C19.3298 7.41965 18.4808 6.15065 17.1898 5.66065C16.7798 5.51965 16.3298 5.74065 16.1798 6.16065C16.0398 6.58065 16.2598 7.04065 16.6798 7.18965C17.3208 7.42965 17.7498 8.06065 17.7498 8.75965V8.79065C17.7308 9.01965 17.7998 9.24065 17.9398 9.41065C18.0798 9.58065 18.2898 9.67965 18.5098 9.70065Z"
        fill="#FF4439"
      />
    </g>
  </g>
);

export default function LikeFillIcon(props) {
  return (
    <CreateLikeFillIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        ...props,
      }}
    />
  );
}
