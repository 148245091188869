import { useTheme } from "@mui/material";
import { useEffect } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";

const getScrollbarBackground = (theme) =>
  useThemeStore.getState().darkMode
    ? theme.palette.bgDark.main
    : theme.palette.bgWhite.main;

const getScrollbarThumbBackground = (theme) =>
  useThemeStore.getState().darkMode
    ? theme.palette.gradients.main
    : theme.palette.gradients.main;

const Scrollbar = () => {
  const theme = useTheme();
  const darkMode = useThemeStore((state) => state.darkMode);

  useEffect(() => {
    const styleOverrides = {
      "*::-webkit-scrollbar": {
        width: "8px",
      },
      "*::-webkit-scrollbar-track": {
        background: getScrollbarBackground(theme),
      },
      "*::-webkit-scrollbar-thumb": {
        background: getScrollbarThumbBackground(theme),
      },
      "*::-webkit-scrollbar-thumb:hover": {
        backgroundColor: darkMode
          ? theme.palette.primary.main
          : theme.palette.gradients.main,
      },
    };

    const styleSheet = document.createElement("style");
    let styleText = "";
    for (const selector in styleOverrides) {
      const styles = styleOverrides[selector];
      styleText += `${selector} { ${Object.entries(styles)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")} }\n`;
    }
    styleSheet.innerHTML = styleText;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [theme, darkMode]);

  return null;
};

export default Scrollbar;
