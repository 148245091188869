import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Input from "../ui/form/Input";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../hooks/store/useAuth";
import { useSnack } from "../../hooks/store/useSnack";
import {
  maxCharacterMessage,
  minCaharacterMessage,
} from "../../utils/formErrorMessage";

const GuestUserForm = () => {
  const { guestLogin } = useAuth();
  const { setSnack } = useSnack();
  // guest user email validation
  const schema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email address"),
    name: Yup.string()
      .min(3, minCaharacterMessage("name", 2))
      .max(50, maxCharacterMessage("name", 50)),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  //   function for guest login
  async function loginGuestHandler(values) {
    try {
      guestLogin({ email: values.email, name: values.name });
    } catch (error) {
      setSnack(error.response.data.message, "error");
      // console.log(error);
    }
  }
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white.1000",
          padding: "18px",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          maxWidth: "450px",
          margin: "0 auto",
        }}
        component={"form"}
        onSubmit={handleSubmit(loginGuestHandler)}
      >
        <Stack position={"relative"}>
          <Input
            name="email"
            register={register}
            errors={formState.errors}
            placeholder="Enter your Email"
            sx={{
              "& fieldset": {
                backgroundColor: "white.500",
                borderRadius: "15px",
                // borderColor: "white.400",
              },
              "& input:placeholder-shown": {
                border: "none",
              },
              "& input": {
                color: "#000000",
                zIndex: 1,
              },

              "& input,input:is(:hover,:focus)": {
                border: "none",
                zIndex: 2,
                color: "black.1000",
                opacity: 1,
              },
            }}
          />
          <Input
            name="name"
            register={register}
            errors={formState.errors}
            placeholder="Enter your Name"
            sx={{
              "& fieldset": {
                backgroundColor: "white.500",
                borderRadius: "15px",
                // borderColor: "white.400",
              },
              "& input:placeholder-shown": {
                border: "none",
              },
              "& input": {
                color: "#000000",
                zIndex: 1,
              },

              "& input,input:is(:hover,:focus)": {
                border: "none",
                zIndex: 2,
                color: "black.1000",
                opacity: 1,
              },
            }}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: "grey.400",
              marginTop: "18px",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
              // textAlign: "justify",
            }}
          >
            Thank you for considering a donation! To ensure a secure process,
            please provide your email before proceeding. We'll use it only for
            donation-related communication. In rare cases of fraud or issues,
            your email helps us address them promptly. Your support is valuable
            to us. Thank you!
          </Typography>
        </Stack>

        <Stack display={"flex"} justifyContent={"flex-end"} height={"100%"}>
          <ButtonWithLoading
            color="primary"
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              marginTop: "38px",
              textTransform: "capitalize",
            }}
          >
            Continue to Donate
          </ButtonWithLoading>
        </Stack>
      </Box>
    </>
  );
};

export default GuestUserForm;
