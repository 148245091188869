import React, { useState } from "react";
import KycStepper from "./KycStepper";
import BasicInfoForm from "./BasicInfoForm";
import { AddressForm } from "./AddressForm";
import { SsnForm } from "./SsnForm";
import { Box, Dialog, alpha, IconButton } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useAllModal } from "../../hooks/store/useAllModal";
import Heading from "../ui/Heading";
import HappyPath from "./HappyPath";
import SadPath from "./SadPath";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../icons/CloseIcon";
import { useTheme } from "@emotion/react";

const IdentityVerificationDialog = () => {
  let [openForm, setOpenForm] = useState("step1"); //step1,step2,step3
  let [step, setStep] = useState(0);
  let { darkMode } = useThemeStore();
  // eslint-disable-next-line no-unused-vars
  let { showIdentityDrawer, setShowIdentityDrawer, KycRedirect } =
    useAllModal();
  let { palette } = useTheme();
  let [message, setMessage] = useState("");
  let [kycStatus, setKycStatus] = useState();
  let navigate = useNavigate();

  return (
    <Dialog
      open={showIdentityDrawer}
      onClose={() => {
        if (KycRedirect) {
          if (window.history.length <= 1) {
            return navigate("/");
          } else {
            return navigate(-1);
          }
        } else {
          return setShowIdentityDrawer(false);
        }
      }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        ".MuiDialog-paper": {
          position: "relative",
          background: "unset",
          boxShadow: "none",
          m: 0,
          overflowX: "hidden",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: alpha(palette.black["1000"], 0.85),
          backdropFilter: "blur(2px)",
        },
      }}
    >
      {/* close button */}
      <IconButton
        aria-label="delete"
        size="small"
        sx={{
          position: "absolute",
          top: "15px",
          right: "15px",
        }}
        onClick={() => {
          if (KycRedirect) {
            if (window.history.length <= 1) {
              return navigate("/");
            } else {
              return navigate(-1);
            }
          } else {
            return setShowIdentityDrawer(false);
          }
        }}
      >
        <CloseIcon
          color={
            darkMode ? alpha(palette.white["1000"], 0.7) : palette.black["1000"]
          }
        />
      </IconButton>
      {kycStatus === undefined && (
        <Box
          sx={{
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            minWidth: { xs: "100%", xsm: "440px", md: "620px" },
            p: 4,
            borderRadius: "12px",
          }}
        >
          <Box mb={2}>
            <Heading>Contact Information</Heading>
          </Box>
          <KycStepper step={step} setOpenForm={setOpenForm}></KycStepper>
          {openForm === "step1" && (
            <BasicInfoForm
              setOpenForm={setOpenForm}
              setStep={setStep}
            ></BasicInfoForm>
          )}
          {openForm === "step2" && (
            <AddressForm
              setOpenForm={setOpenForm}
              setStep={setStep}
            ></AddressForm>
          )}
          {openForm === "step3" && (
            <SsnForm
              setOpenForm={setOpenForm}
              setStep={setStep}
              setKycStatus={setKycStatus}
              setMessage={setMessage}
            />
          )}
        </Box>
      )}
      {kycStatus === true && (
        <HappyPath
          // message={"Your identity has been verified. continue to create raise"}
          message={message}
        ></HappyPath>
      )}
      {kycStatus === false && (
        <SadPath
          message={
            message
              ? message
              : "You have failed your ID verification more than 5 times, please contact PloomSocial Support (support@ploom.media)"
          }
        ></SadPath>
      )}
    </Dialog>
  );
};

export default IdentityVerificationDialog;
