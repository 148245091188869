import { create } from "zustand";

export const useFeed = create((set) => ({
  feeds: [],
  current: 1,
  totalPages: 2,
  userId: "",
  type: "all",
  setFeeds: (newFeeds, page = 1) =>
    set((state) => {
      if (page === 1 && state.feeds.length > 1) state.feeds = [];
      newFeeds = newFeeds.filter((feed) => {
        if (state.feeds.length === 0) return true;
        for (const oldFeed of state.feeds)
          if (feed._id === oldFeed._id) return false;
        return true;
      });
      return {
        ...state,
        feeds: [...state.feeds, ...newFeeds],
      };
    }),
  setFeedOnTop: (newFeed) => {
    set((state) => {
      const oldFeeds = state.feeds.filter((feed) => feed._id !== newFeed._id);

      return { ...state, feeds: [newFeed, ...oldFeeds] };
    });
  },
  setTotalPages: (totalPages) => set((state) => ({ ...state, totalPages })),
  setType: (type) => {
    return set((state) => ({ ...state, type }));
  },
  setUserId: (userId) => set((state) => ({ ...state, userId })),
  likeFeed: (feedId) =>
    set((state) => ({
      ...state,
      feeds: state.feeds?.map((feed) => {
        if (feed._id === feedId) {
          feed.currentUserLike = !feed.currentUserLike;
        }
        return feed;
      }),
    })),
  resetFeedStore: () =>
    set({
      feeds: [],
      // currentPage: 1,
      // totalPages: 2,
      type: "all",
    }),
  setFollowUser: (userId) => {
    set((state) => ({
      ...state,
      feeds: state.feeds?.map((feed) => {
        if (feed.author._id === userId) {
          feed.author.following = true;
        }
        return feed;
      }),
    }));
  },
  updateRaised: (begId, amount, donarCount) => {
    set((state) => ({
      ...state,
      feeds: state.feeds?.map((feed) => {
        if (feed._id === begId) {
          feed.amountRaised = amount;
          feed.donors = donarCount;
        }
        return feed;
      }),
    }));
  },
  setCurrent: (current) => set((state) => ({ ...state, current })),
  addNewFeed: (newFeed) =>
    set((state) => ({
      ...state,
      feeds: [newFeed, ...state.feeds],
    })),
}));
