import React, { useState } from "react";
import {
  Card as MaterialCard,
  useTheme,
  CardMedia,
  Box,
  Typography,
} from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import VideoPlayer from "./VideoPlayer";
import FeedProfile from "./feedDetailComponent/FeedProfile";
import FeedCardAction from "./feedDetailComponent/FeedCardAction";
import DonationDetails from "./feedDetailComponent/DonationDetails";
import DonationAndShareBtns from "./feedDetailComponent/DonationAndShareBtns";
import FeedMediaType from "./feedDetailComponent/FeedMediaType";
// import CommentContainer from "./feedDetailComponent/CommentContainer";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function DetailCardWithComment({
  id,
  author = {},
  title = "",
  videos = [],
  likeCount = 0,
  commentCount = 0,
  shareCount = 0,
  donorCount = 0,
  htmlDescription = "",
  daysRemaining,
  goalAmount,
  amountRaised,
  activeFeed,
  isLiked,
  begType,
  status,
  endedDate,
  saved,
  setFeed,
  setOpenTab,
  shareLink,
}) {
  SwiperCore.use([Keyboard]);
  // console.log(videos);
  const { palette, typography } = useTheme();
  const { darkMode } = useThemeStore();
  const [activeVideo, setActiveVideo] = useState(videos[0]);
  const videoId = sessionStorage.getItem("activeVideoId");
  useState(() => {
    if (videoId) {
      const video = videos.find((video) => video._id === videoId);
      setActiveVideo(video || videos[0]);

      // setActiveVideo(videos.find((video) => video._id === videoId));
    }
  }, [videoId]);
  // console.log(activeVideo);

  // get window inner width for styling non mui components
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });

  document.documentElement.style.setProperty(
    "--swiper-theme-color",
    palette.neutral[1000]
  );

  const [showMore, setShowMore] = useState(false);
  const truncatedText = showMore
    ? htmlDescription
    : htmlDescription.slice(0, 140);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        // maxWidth: { xs: "400px", sm: "440px", md: "1020px" },
        maxWidth: { xs: "100%", sm: "100%", md: "1020px" },
        backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
        margin: "0 auto",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          mx: "auto",
          display: { sm: "block", md: "flex" },
          width: "100%",
          padding: { xs: "15px", xl: "30px" },
          height: { xs: "auto", sm: "auto", md: "763px" },
          // gridTemplateColumns: { xs: "1fr", sm: "1fr", md: "1fr 1fr" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            mx: "auto",
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            overflow: "auto",
          }}
        >
          <MaterialCard
            sx={{
              width: "100%",
              height: {
                xs: begType === 1 ? "calc(500px - 83px)" : "500px",
                sm: begType === 1 ? "calc(550px - 83px)" : "550px",
                md: "auto",
              },
              // borderRadius: "0px",
              zIndex: 1,
              backgroundColor: darkMode ? "bgDark.third" : "white.1000",
              boxShadow: "none",
            }}
          >
            <Box
              component={Swiper}
              spaceBetween={10}
              slidesPerView={1}
              navigation
              keyboard={{ enabled: true }}
              pagination={{ clickable: true }}
              onSlideChange={(swiper) => {
                setActiveVideo(videos[swiper.activeIndex]);
                sessionStorage.setItem(
                  "activeVideoId",
                  videos[swiper.activeIndex]._id
                );
              }}
              initialSlide={
                videos.findIndex((video) => video._id === activeVideo._id) || 0
              }
              modules={[Pagination]}
              sx={{
                position: "absolute",
                width: "100%",
                height: {
                  xs: begType === 1 ? "calc(100% - 61px)" : "100%",
                  md: "100%",
                },
                top: "0px",
                "& .swiper-pagination": {
                  marginBottom: {
                    xs: begType === 1 ? "30px" : "55px",
                    md: "5px",
                  },
                },
              }}
            >
              <FeedMediaType
                video={activeVideo}
                data={{ id, title, description: htmlDescription, saved }}
                videoType={activeVideo?.type}
              />
              {videos.map((video) => (
                <SwiperSlide
                  style={{
                    display: "grid",
                    placeItems: "center",
                    borderRadius: windowWidth > 768 && "10px",
                    overflow: "hidden",
                    marginRight: "0",
                  }}
                  key={video._id}
                >
                  {/* <PlayIcon videoType={video.type} /> */}
                  {video?.type === 1 ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "grey.550",
                        // background: `url(${video?.thumbLink})`,
                        // backgroundSize: "cover",
                        // backgroundRepeat: "no-repeat",
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          placeItems: "center",
                          width: "100%",
                          height: "100%",
                          // backdropFilter: "blur(12px)",
                          borderRadius: "10px",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={video?.thumbLink}
                          alt="Paella dish"
                          // loading="lazy"
                        />
                      </Box>
                    </Box>
                  ) : (
                    <VideoPlayer
                      activeVideoId={activeVideo?._id}
                      videoId={video?._id}
                      videoUrl={video?.videoLink}
                      activeFeed={activeFeed === id}
                      id={id}
                    />
                  )}
                </SwiperSlide>
              ))}
            </Box>
          </MaterialCard>

          <Box
            sx={{
              display: { xs: "block", md: "none" },
              width: "100%",
              position: "relative",
              zIndex: 50,
            }}
          >
            <DonationDetails
              endedDate={endedDate}
              amountRaised={amountRaised}
              goalAmount={goalAmount}
              daysRemaining={daysRemaining}
              donorCount={donorCount}
              begType={begType}
            />
          </Box>
        </Box>

        {/* feed side details section */}
        <Box
          display={{ xs: "flex", sm: "flex", md: "grid" }}
          flexDirection={"column"}
          marginLeft={{ md: "30px" }}
          gridTemplateRows={"1.1fr .9fr"}
          height="100%"
          width="100%"
          maxWidth={{ md: "452px" }}
        >
          {/* profile,title and description container */}
          <Box
            display={{ xs: "flex", md: "grid" }}
            flexDirection={"column"}
            gridTemplateRows={"auto auto 1fr"}
          >
            {/* feed details */}
            <Box
              sx={{
                position: { xs: "absolute", md: "unset" },
                top: "35px",
                left: "30px",
                zIndex: 3,
                width: {
                  xs: "calc(100% - 140px)",
                  sm: "calc(100% - 160px)",
                  md: "100%",
                },
              }}
            >
              <FeedProfile
                author={author}
                username={author.username}
                profileImage={author.profileImage}
                id={id}
                title={title}
                description={htmlDescription}
                videoType={activeVideo?.type}
                activeVideo={activeVideo}
                setFeed={setFeed}
              />
            </Box>

            {begType === 1 && (
              <Typography
                mt={"14px"}
                fontSize={{ xs: "12px", md: "14px" }}
                color={darkMode ? "white.1000" : "black.1000"}
                sx={{ opacity: ".8" }}
              >
                {daysRemaining !== null &&
                  daysRemaining > 0 &&
                  endedDate === undefined &&
                  `${daysRemaining} days left to contribution`}

                {daysRemaining !== null &&
                  daysRemaining === 0 &&
                  endedDate === undefined &&
                  "Contribution ends today"}

                {daysRemaining !== null &&
                  daysRemaining < 0 &&
                  endedDate === undefined &&
                  `Contribution period ended`}

                {endedDate && `Contribution period ended`}
              </Typography>
            )}
            {/* title */}
            <Typography
              sx={{
                mt: { xs: begType !== 1 ? "15px" : "5px", md: "20px" },
                color: darkMode ? "white.1000" : "black.1000",
                fontFamily: typography.poppins,
                fontSize: { xs: "18px", md: "24px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                overflowWrap: "anywhere",
                "&::first-letter": {
                  textTransform: "uppercase",
                },
              }}
            >
              {title}
            </Typography>

            {/* description */}
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
                maxHeight: { md: "258px" },
                overflowY: "auto",
                mt: { xs: "5px", md: "20px" },
                color: darkMode ? "grey.350" : "grey.700",
                fontFamily: typography.poppins,
                fontSize: { xs: "12px", md: "17px" },
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                overflowWrap: "anywhere",
              }}
            >
              {htmlDescription}
            </Typography>

            <Typography
              sx={{
                display: { xs: "block", md: "none" },
                mt: "5px",
                color: darkMode ? "grey.350" : "black.1000",
                fontFamily: typography.poppins,
                fontSize: { xs: "12px", md: "17px" },
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {truncatedText}{" "}
              {htmlDescription.length > 140 && (
                <button
                  onClick={() => {
                    toggleShowMore();
                  }}
                  style={{
                    padding: 0,
                    margin: 0,
                    textTransform: "unset",
                    fontSize: "12px",
                    fontWeight: 700,
                    fontFamily: typography.inter,
                    color: darkMode
                      ? palette.white["1000"]
                      : palette.black["1000"],
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {showMore ? "Load less" : "Load more"}
                </button>
              )}
            </Typography>
          </Box>

          {/* donation details,like,share and donation button container */}
          <Box
            mt={{ xs: "10px", md: "61px" }}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <FeedCardAction
              title={title}
              imageUrl={videos[0]?.thumbLink}
              commentCount={commentCount}
              daysRemaining={daysRemaining}
              htmlDescription={htmlDescription}
              likeCount={likeCount}
              shareCount={shareCount}
              id={id}
              isLiked={isLiked}
              activeVideo={activeVideo}
              authorId={author?._id}
              setOpenTab={setOpenTab}
              author={author}
              goalAmount={goalAmount}
              amountRaised={amountRaised}
              shareLink={shareLink}
            />
            {/* {begType === 1 && ( */}
            <Box display={{ xs: "none", md: "block" }}>
              <DonationDetails
                endedDate={endedDate}
                amountRaised={amountRaised}
                goalAmount={goalAmount}
                daysRemaining={daysRemaining}
                donorCount={donorCount}
                begType={begType}
              />
            </Box>
            {/* )} */}

            <DonationAndShareBtns
              id={id}
              title={title}
              description={htmlDescription}
              imageUrl={videos[0]?.thumbLink}
              daysRemaining={daysRemaining}
              author={author}
              begType={begType}
              status={status}
              endedDate={endedDate}
              goalAmount={goalAmount}
              amountRaised={amountRaised}
              shareLink={shareLink}
            ></DonationAndShareBtns>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
