import { createSvgIcon } from "@mui/material";
import React from "react";

export default function InstaIcon(props) {
  const CreateNextDoorIcon = createSvgIcon(
    <>
      <path
        d="M22.5 42.2754C33.4216 42.2754 42.2754 33.4216 42.2754 22.5C42.2754 11.5784 33.4216 2.72461 22.5 2.72461C11.5784 2.72461 2.72461 11.5784 2.72461 22.5C2.72461 33.4216 11.5784 42.2754 22.5 42.2754Z"
        fill="url(#paint0_linear_615_2145)"
      />
      <path
        d="M26.7021 11.5137H18.3086C14.5645 11.5137 11.5234 14.5547 11.5234 18.2988V26.6924C11.5234 30.4365 14.5645 33.4775 18.3086 33.4775H26.7021C30.4463 33.4775 33.4873 30.4365 33.4873 26.6924V18.2988C33.4873 14.5547 30.4463 11.5137 26.7021 11.5137ZM31.0352 26.7012C31.0352 29.0918 29.0928 31.043 26.6934 31.043H18.2998C15.9092 31.043 13.958 29.1006 13.958 26.7012V18.3076C13.958 15.917 15.9004 13.9658 18.2998 13.9658H26.6934C29.084 13.9658 31.0352 15.9082 31.0352 18.3076V26.7012Z"
        fill="white"
      />
      <path
        d="M22.501 16.8838C19.4072 16.8838 16.8848 19.4062 16.8848 22.5C16.8848 25.5938 19.4072 28.1162 22.501 28.1162C25.5947 28.1162 28.1172 25.5938 28.1172 22.5C28.1172 19.4062 25.5947 16.8838 22.501 16.8838ZM22.501 25.9102C20.6201 25.9102 19.0908 24.3809 19.0908 22.5C19.0908 20.6191 20.6201 19.0898 22.501 19.0898C24.3818 19.0898 25.9111 20.6191 25.9111 22.5C25.9111 24.3809 24.3818 25.9102 22.501 25.9102Z"
        fill="white"
      />
      <path
        d="M28.5448 17.4995C29.0622 17.4152 29.4133 16.9275 29.3291 16.41C29.2448 15.8926 28.757 15.5415 28.2396 15.6257C27.7222 15.71 27.371 16.1978 27.4553 16.7152C27.5396 17.2326 28.0273 17.5838 28.5448 17.4995Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_615_2145"
          x1="22.5"
          y1="2.72461"
          x2="22.5"
          y2="42.2754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
      </defs>
    </>
  );
  return (
    <CreateNextDoorIcon
      width="36"
      height="36"
      viewBox="0 0 45 45"
      fill="none"
      sx={{
        "&.MuiSvgIcon-root": { width: "unset", height: "unset" },
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
