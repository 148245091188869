import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Typography } from "@mui/material";
import CardSkeleton from "../skeleton/CardSkeleton";
import ProfileFeedCard from "../profilefeedcard/ProfileFeedCard";
import { useAuth } from "../../hooks/store/useAuth";
import { APIS } from "../../api/lists";
import useInfinite from "../../hooks/useInfinite";
import useThemeStore from "../../hooks/store/useThemeStore";
// import useApi from "../../hooks/useApi";

export default function UserFeeds({ userId }) {
  const { userId: loogedInUserId } = useAuth();
  const { darkMode } = useThemeStore();
  const {
    results: feeds,
    page,
    hasMore,
    setHasMore,
    setPage,
    totalPages,
    isLoading,
  } = useInfinite({
    url:
      userId === loogedInUserId
        ? APIS.FEED.USER.SELF_LIST(userId)
        : APIS.FEED.USER.LIST(userId),
    addUserId: true,
    limit: 15,
  });

  return (
    <Box>
      <InfiniteScroll
        dataLength={feeds.length}
        next={() => {
          if (page < totalPages) {
            setPage((prev) => prev + 1);
          } else {
            setHasMore(false);
          }
        }}
        style={{ overflow: "visible" }}
        hasMore={hasMore}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr 1fr",
              xsm: "1fr 1fr 1fr",
            },
            gap: { xs: "10px", md: "20px" },
            marginTop: "12px",
            padding: "0px",
          }}
        >
          {feeds.map((feed) => (
            <ProfileFeedCard
              ownProfile={Boolean(userId === loogedInUserId)}
              feed={feed}
              key={feed._id}
            />
          ))}
          {isLoading && (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          )}
        </Box>
        {feeds.length === 0 && !isLoading && (
          <Typography
            textAlign={"center"}
            color={darkMode ? "white.1000" : "primary.main"}
            fontSize={{
              xs: "14px",
              md: "18px",
            }}
            mt={2}
          >
            No Post Found
          </Typography>
        )}
      </InfiniteScroll>
    </Box>
  );
}
