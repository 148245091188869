import React from "react";
import CustomTabs from "../ui/CustomTabs";
import { Box } from "@mui/material";
import CommentContainer from "./feedDetailComponent/CommentContainer";
import FeedDonationList from "./feedDetailComponent/FeedDonationList";

function FeedTabs({ begId, title, setFeed, openTab }) {
  const tabs = [
    {
      name: "Donor History",
      value: "donor-history",
      Component: () => <FeedDonationList begId={begId} title={title} />,
    },

    {
      name: "Comments",
      value: "comments",
      Component: () => <CommentContainer setFeed={setFeed} id={begId} />,
    },
  ];
  return (
    <Box sx={{ p: { xs: "15px", md: "30px" } }}>
      <CustomTabs
        tabs={tabs}
        defaultTab={openTab ? openTab : "donor-history"}
      />
    </Box>
  );
}

export default React.memo(FeedTabs);
