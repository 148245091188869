import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const UnderProcessing = ({ small = false, status, sx }) => {
  return (
    <>
      <Box
        sx={{
          ...sx,
        }}
      >
        {status !== "failed" && (
          <CircularProgress
            size={small ? "12px" : "30px"}
            sx={{ color: "white.1000" }}
          />
        )}
        <Typography
          sx={{
            mt: "16px",
            color: "white.1000",
            fontSize: small ? "10px" : "16px",
            textTransform: "capitalize",
          }}
        >
          {status !== "failed" ? status : "processing failure"}
        </Typography>
      </Box>
    </>
  );
};

export default UnderProcessing;
