import { Box, Button, ListItem, Typography, alpha } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import BinIcon from "../../icons/payouts/Bin";

import ButtonWithLoading from "../ui/button/ButtonWithLoading";

export default function BankAccount({
  accountNumber,
  routeNumber,
  id,
  defaultAccount,
  setPrimaryAccount,
  deleteBankAccount,
}) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();

  const paragraphStyle = {
    fontSize: "14px",
    lineHeight: 1.5,
  };

  return (
    <ListItem
      sx={{
        gap: "10px",
        flexDirection: "column",
        alignItems: "stretch",
        color: darkMode ? "white.1000" : "black.1000",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ ...paragraphStyle, fontWeight: 600 }}>
            Routing Number
          </Typography>
          <Typography
            sx={{
              ...paragraphStyle,
              color: darkMode ? alpha(palette.white[1000], 0.5) : "black.1000",
            }}
          >
            {routeNumber}
          </Typography>
        </Box>
        <Button
          sx={{
            flexShrink: 0,
            color: darkMode ? "white.1000" : "black.1000",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={deleteBankAccount.bind(null, defaultAccount, id)}
        >
          <BinIcon />
        </Button>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ ...paragraphStyle, fontWeight: 600 }}>
            Account Number
          </Typography>
          <Typography
            sx={{
              ...paragraphStyle,
              color: darkMode ? alpha(palette.white[1000], 0.5) : "black.1000",
            }}
          >
            {accountNumber}
          </Typography>
        </Box>
        {defaultAccount ? (
          <ButtonWithLoading
            sx={{
              flexShrink: 0,
              fontSize: "14px",
              "&.Mui-disabled": {
                color: darkMode ? alpha(palette.white[1000], 0.35) : "",
              },
            }}
            disabled
          >
            Primary account
          </ButtonWithLoading>
        ) : (
          <ButtonWithLoading
            sx={{
              flexShrink: 0,
              color: darkMode ? "white.1000" : "primary.main",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={setPrimaryAccount.bind(null, id)}
          >
            set as primary
          </ButtonWithLoading>
        )}
      </Box>
    </ListItem>
  );
}
