import React from "react";
import DialoagBackground from "./DialoagBackground";
import CustomDialogContent from "./CustomDialogContent";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import { useSnack } from "../../hooks/store/useSnack";
import handleApiError from "../../utils/handleApiError";
import { useNavigate } from "react-router-dom";
import ROUTE_LIST from "../../Routes/list";
import { useExistDraft } from "../../hooks/store/useExistDraft";

export default function DraftWarningDialog({ open, setOpen }) {
  const { apiCall, isLoading } = useApi();
  const navigate = useNavigate();
  const { setSnack } = useSnack();

  const { draft, resetExistDraftStore } = useExistDraft();
  const deleteDraft = async (id) => {
    try {
      const res = await apiCall({
        url: APIS.DRAFT.DELETE(id),
        method: "delete",
      });
      if (res.data.success === true) {
        setSnack(res.data.message);
        resetExistDraftStore();
      }
    } catch (error) {
      handleApiError(error, setSnack);
    }
  };
  return (
    <DialoagBackground setOpen={setOpen} open={open} notAllowToClose>
      <CustomDialogContent
        firstButton={{
          text: "Continue with draft",
          onClick: () => {
            navigate(ROUTE_LIST.FEED_CREATE.DRAFT_TO_CAMPAIGNS);
            setOpen(false);
          },
          isLoading: false,
        }}
        secondButton={{
          text: "Create from scratch",
          onClick: async () => {
            try {
              await deleteDraft(draft._id);
              setOpen(false);
              return navigate("/create/raise");
            } catch (error) {}
            setOpen(false);
            console.log("Create from scratch");
          },
          isLoading: isLoading,
        }}
      ></CustomDialogContent>
    </DialoagBackground>
  );
}
