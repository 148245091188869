import { Box, List, Typography, alpha } from "@mui/material";
import React, { useEffect, useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import ListItemSkeleton from "../skeleton/ListItemSkeleton";
import Heading from "../ui/Heading";
import WithdrawalListItem from "./WithdrawalListItem";

export default function WithdrawalList() {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { apiCall, isLoading } = useApi();
  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        let response = await apiCall({
          url: APIS.WITHDRAWAL.LIST,
        });
        if (response.data.success === true) {
          let records = response.data.data.results;
          if (
            records.length === 0 &&
            response.data.data.pagination.records > 0
          ) {
            let response = await apiCall({
              url: APIS.WITHDRAWAL.LIST,
            });

            if (response.data.success === true) {
              records = response.data.data.results;
            }
          }
          setWithdrawals(records);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [apiCall]);
  return (
    <Box>
      <Heading>Withdrawal History</Heading>
      <List
        sx={{
          "& li:not(:last-child)": {
            borderBottom:
              "1px solid " +
              (darkMode
                ? alpha(palette.white[1000], 0.08)
                : alpha(palette.black[1000], 0.25)),
          },
        }}
      >
        {isLoading && (
          <>
            <ListItemSkeleton />
            <ListItemSkeleton />
            <ListItemSkeleton />
          </>
        )}
        {withdrawals.map((withdrawal) => (
          <WithdrawalListItem
            key={withdrawal._id}
            date={withdrawal.createdAt}
            status={withdrawal.status}
            amount={withdrawal.amountRaised}
          />
        ))}
      </List>
      {Boolean(withdrawals.length === 0 && !isLoading) && (
        <Typography
          textAlign={"center"}
          mt={"20px"}
          color={darkMode ? "white.1000" : "black.1000"}
        >
          No withdrawal history available !
        </Typography>
      )}
    </Box>
  );
}
