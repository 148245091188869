import React, { useEffect } from "react";
import { useExistDraft } from "../hooks/store/useExistDraft";
import { useNavigate } from "react-router-dom";
import DraftToRaise from "../component/draft/DraftToRaise";
import ROUTE_LIST from "../Routes/list";
import { Box } from "@mui/material";

export default function DraftToCampaign() {
  const {
    //  draft,
    draftExist,
  } = useExistDraft();
  const navigate = useNavigate();
  useEffect(() => {
    if (!draftExist) {
      navigate(ROUTE_LIST.NOT_FOUND);
    }
  }, [draftExist, navigate]);

  return (
    <>
      <Box sx={{ mt: { xs: "3px", md: "30px" } }}>
        <DraftToRaise />
        {/* {draft.begType === 2 ? <DraftToStory /> : <DraftToRaise />} */}
      </Box>
    </>
  );
}
