import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  Slide,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { theme } from "../../theme";
import NotificationIcon from "../../icons/navMenu/NotificationIcon";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import NotificationItem from "./NotificationItem";
import GeneralNotificationItemSkeleton from "../notificationPanel/GeneralNotificationItemSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";
import { socket } from "../../socket";
import StyledSwitch from "./StyledSwitch";
import { useNotification } from "../../hooks/store/useNotification";
import { Link } from "react-router-dom";
import NotificationItemEndRaise from "./NotificationItemEndRaise";
import CloseGradientIcon from "../../icons/CloseGradientIcon";
import notificationAudio from "./notificationSound.mp3";

export default function NotificationPanel() {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuStyles = isSmallScreen
    ? {
        inset: "0! important",
        maxHeight: "none",
        maxWidth: "570px",
        borderRadius: "0px !important",
      }
    : {
        maxHeight: "calc(100% - 150px)",
        top: "calc(85px + 15px) !important",
      };
  const menuBackdropStyles = isSmallScreen
    ? {
        background: darkMode
          ? alpha(palette.black[1000], 0.7)
          : alpha(palette.white[1000], 0.4),
        backdropFilter: "blur(" + (darkMode ? 1.2 : 4) + "px)",
      }
    : {};
  const { apiCall, isLoading } = useApi();
  const { apiCall: API } = useApi();
  const [generalNotification, setGeneralNotification] = useState([]);
  const [totalGeneralPages, setTotalGeneralPages] = useState(1);
  const [currentGeneralPage, setCurrentGeneralPage] = useState(1);
  const [hasMoreGeneralItem, setHasMoreGeneralItem] = useState(true);
  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const [unReadOnly, setUnReadOnly] = useState(false);
  const { unReadNotificationCount, setUnReadNotificationCount } =
    useNotification();
  const [todaysNotification, setTodaysNotification] = useState([]);
  const [thisWeekNotification, setThisWeekNotification] = useState([]);
  const [otherNotification, setOtherNotification] = useState([]);

  useEffect(() => {
    socket.on("notificationCount", (data) => {
      document.getElementById("notification_audio").play();
      setUnReadNotificationCount(data.allReadCount);
      setHasMoreGeneralItem(true);
      setCurrentGeneralPage(1);
      setCurrentGeneralPage(1);
      setGeneralNotification([]);
      setMarkAllAsRead(false);
    });

    return () => {
      socket.off("notificationCount");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNotification = useCallback(async () => {
    try {
      const res = await apiCall({
        url: `v3/notification/get-all-notification`,
        method: "get",
        params: { page: currentGeneralPage, isRead: !unReadOnly },
      });

      if (res.data.data.data.length !== 0) {
        setGeneralNotification((prev) => {
          if (currentGeneralPage === 1) {
            return res.data.data.data;
          } else {
            return [...prev, ...res.data.data.data];
          }
        });
      }

      // Separate notifications based on timestamp
      const today = new Date();
      const thisWeek = new Date(today);
      thisWeek.setDate(thisWeek.getDate() - today.getDay());

      const todayNotifications = [];
      const thisWeekNotifications = [];
      const otherNotifications = [];

      res.data.data.data.forEach((item) => {
        const itemDate = new Date(item.createdAt);

        if (itemDate.toDateString() === today.toDateString()) {
          todayNotifications.push(item);
        } else if (itemDate >= thisWeek) {
          thisWeekNotifications.push(item);
        } else {
          otherNotifications.push(item);
        }
      });

      setTodaysNotification((prev) => {
        if (currentGeneralPage === 1) {
          return todayNotifications;
        } else {
          return [...prev, ...todayNotifications];
        }
      });

      setThisWeekNotification((prev) => {
        if (currentGeneralPage === 1) {
          return thisWeekNotifications;
        } else {
          return [...prev, ...thisWeekNotifications];
        }
      });

      setOtherNotification((prev) => {
        if (currentGeneralPage === 1) {
          return otherNotifications;
        } else {
          return [...prev, ...otherNotifications];
        }
      });

      setTotalGeneralPages(res.data.data.pagination.pages);
    } catch (error) {
      console.log(error);
    }
  }, [apiCall, currentGeneralPage, unReadOnly]);

  useEffect(() => {
    if (anchorEl !== null) {
      fetchNotification();
      setUnReadNotificationCount(0);
    } else {
      setHasMoreGeneralItem(true);
      setCurrentGeneralPage(1);
      setCurrentGeneralPage(1);
      setGeneralNotification([]);
      setMarkAllAsRead(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNotification, anchorEl]);

  // get all comment count
  const getUnreadMsgCount = useCallback(async () => {
    let response = await API({
      url: APIS.NOTIFICATION.GET_COUNT,
      method: "GET",
    });
    setUnReadNotificationCount(response.data.data.allReadCount);
    setMarkAllAsRead(response.data.data.isReadCount > 0 ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APIS.NOTIFICATION.GET_COUNT]);

  useEffect(() => {
    getUnreadMsgCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // mark all as read
  function markAllRead() {
    apiCall({ url: APIS.NOTIFICATION.READAll, method: "PATCH" });
    setMarkAllAsRead(true);
  }

  const extraProps = isSmallScreen
    ? {
        TransitionComponent: Slide,
        TransitionProps: { direction: "right" },
      }
    : {};
  return (
    <>
      <IconButton
        aria-label="navicon"
        sx={{
          position: "relative",
          mr: { xs: "0px", md: "30px" },
          ml: { xs: "15px", md: 0 },
          p: 0,
          "&::after": {
            content: `"${unReadNotificationCount}"`,
            position: "absolute",
            top: "-15%",
            left: "50%",
            width: 15,
            height: 15,
            display: unReadNotificationCount > 0 ? "grid" : "none",
            placeContent: "center",
            color: "white.1000",
            fontFamily: "Inter",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            background: palette.gradients.main,
            borderRadius: "50%",
          },
        }}
        onClick={handleClick}
        size="small"
        aria-controls={open ? "notification-panel" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <NotificationIcon></NotificationIcon>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="notification-panel"
        open={open}
        sx={menuBackdropStyles}
        onClose={handleClose}
        {...extraProps}
        PaperProps={{
          elevation: 0,
          sx: {
            ...menuStyles,
            overflow: "visible",
            minWidth: { md: "560px", lg: "580px" },
            maxWidth: { md: "560px", lg: "580px" },
            height: "100%",
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            boxShadow:
              "0 0 60px " + alpha(palette.black["1000"], darkMode ? 0.2 : 0.06),
            "&:before": {
              content: '""',
              display: { xs: "none", md: "block" },
              position: "absolute",
              top: 0,
              left: {
                md: 158,
                lg: 38,
              },
              borderBottom: "15px solid",
              borderBottomColor: darkMode ? "bgDark.third" : "white.1000",
              borderRight: "15px solid transparent",
              borderLeft: "15px solid transparent",
              transform: "translateY(-100%)",
            },

            "& ul": {
              p: "0px",
              display: "flex",
              flexDirection: "column",
              height: { xs: window.innerHeight, md: "100%" },
              // overflow: "auto",
              // minWidth: { sm: "300px" },
            },
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {/* notification top options */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: { xs: "10px", md: "20px 20px 10px 20px" },
              width: "100%",
              borderBottom: "1px solid",
              borderColor: darkMode ? "neutral.650" : "bgWhite.secondary",
            }}
          >
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: { xs: "14px", sm: "16px", md: "20px" },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "142% /* 28.4px */",
                letterSpacing: "-0.408px",
              }}
            >
              Notifications
            </Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                sx={{ mr: 0 }}
                control={
                  <>
                    <Typography
                      sx={{
                        display: { xs: "inline", sm: "none" },
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "10px", md: "13px" },
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      Only Show Unread
                    </Typography>
                    <Typography
                      sx={{
                        display: { xs: "none", sm: "inline" },
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "10px", md: "13px" },
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      Only Show Unread
                    </Typography>
                    <StyledSwitch
                      darkMode={darkMode}
                      sx={{
                        ml: { xs: "0px", sm: "12px" },
                        transform: { xs: "scale(.6)", sm: "scale(1)" },
                      }}
                      checked={unReadOnly}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setUnReadOnly(true);
                          setCurrentGeneralPage(1);
                          setTotalGeneralPages(1);
                          setGeneralNotification([]);
                          setOtherNotification([]);
                          setThisWeekNotification([]);
                          setTodaysNotification([]);
                          setHasMoreGeneralItem(true);
                        } else {
                          setUnReadOnly(false);
                          setCurrentGeneralPage(1);
                          setTotalGeneralPages(1);
                          setGeneralNotification([]);
                          setOtherNotification([]);
                          setThisWeekNotification([]);
                          setTodaysNotification([]);
                          setHasMoreGeneralItem(true);
                        }
                      }}
                    />
                  </>
                }
              />
              <IconButton
                component={Link}
                to={"/notification"}
                sx={{
                  padding: 0,
                  mx: "5px",
                  color: darkMode ? "white.1000" : "black.1000",
                  fontSize: "14px",
                }}
              >
                <LaunchRoundedIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      width: { xs: "18px", sm: "24px" },
                      height: { xs: "18px", sm: "24px" },
                    },
                  }}
                />
              </IconButton>
              <IconButton
                sx={{ padding: 0, mx: "5px", display: { md: "none" } }}
                onClick={() => handleClose()}
              >
                <CloseGradientIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      width: { xs: "18px", sm: "24px" },
                      height: { xs: "18px", sm: "24px" },
                    },
                  }}
                />
              </IconButton>
            </Stack>
          </Stack>

          {/* notification listing */}
          <Stack
            id="notification_container"
            sx={{
              width: "100%",
              height: "100%",
              padding: { xs: "0px 10px 10px 10px", sm: "0px 20px 20px 20px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Stack
              sx={{
                paddingTop: "15px",
                // position: "sticky",
                // top: 0,
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: 100,
                backgroundColor: darkMode ? "bgDark.third" : "white.1000",
              }}
            >
              <Typography
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "22px /* 137.5% */",
                  letterSpacing: "-0.408px",
                }}
              >
                {todaysNotification.length > 0
                  ? "Today"
                  : thisWeekNotification.length > 0
                  ? "This Week"
                  : otherNotification.length > 0
                  ? "Earlier"
                  : ""}
              </Typography>
              {(!markAllAsRead ||
                (isLoading && generalNotification.length > 0)) && (
                <Button
                  variant="text"
                  sx={{
                    // color: darkMode ? "grey.900" : "primary.main",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "142%",
                    letterSpacing: "-0.408px",
                    "&.MuiButton-root": {
                      textTransform: "none",
                    },
                  }}
                  onClick={() => {
                    markAllRead();
                  }}
                >
                  Mark all as read
                </Button>
              )}
            </Stack>
            <Stack
              sx={{
                width: "100%",
                flexDirection: "column",
              }}
            >
              <InfiniteScroll
                style={{ width: "100%" }}
                scrollableTarget="notification_container"
                dataLength={generalNotification.length} //This is important field to render the next data
                next={() => {
                  if (currentGeneralPage < totalGeneralPages) {
                    setCurrentGeneralPage((prev) => prev + 1);
                  } else {
                    setHasMoreGeneralItem(false);
                  }
                }}
                hasMore={hasMoreGeneralItem}
                loader={<GeneralNotificationItemSkeleton />}
                endMessage={
                  <Typography
                    textAlign={"center"}
                    color={darkMode ? "white.1000" : "primary.main"}
                    fontSize={{
                      xs: "14px",
                      sm: "14px",
                    }}
                    mt={2}
                  >
                    {generalNotification.length === 0
                      ? "No notifications yet."
                      : "you seen it all."}
                  </Typography>
                }
              >
                {todaysNotification.length > 0 &&
                  todaysNotification.map((item) =>
                    item.notificationType === "RAISE END" ? (
                      <NotificationItemEndRaise
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    ) : (
                      <NotificationItem
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    )
                  )}

                {todaysNotification.length > 0 &&
                  thisWeekNotification.length > 0 && (
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22px /* 137.5% */",
                        letterSpacing: "-0.408px",
                        mt: "18px",
                        pt: "18px",
                      }}
                    >
                      This week
                    </Typography>
                  )}
                {thisWeekNotification.length > 0 &&
                  thisWeekNotification.map((item) =>
                    item.notificationType === "RAISE END" ? (
                      <NotificationItemEndRaise
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    ) : (
                      <NotificationItem
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    )
                  )}

                {todaysNotification.length > 0 &&
                  thisWeekNotification.length > 0 &&
                  otherNotification.length > 0 && (
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22px /* 137.5% */",
                        letterSpacing: "-0.408px",
                        mt: "18px",
                        pt: "18px",
                      }}
                    >
                      Earlier
                    </Typography>
                  )}
                {otherNotification.length > 0 &&
                  otherNotification.map((item) =>
                    item.notificationType === "RAISE END" ? (
                      <NotificationItemEndRaise
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    ) : (
                      <NotificationItem
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    )
                  )}

                {/* loader  */}
                {isLoading && <GeneralNotificationItemSkeleton />}

                {/* message for no notification available */}
                {!isLoading && generalNotification.length === 0 && (
                  <Typography
                    textAlign={"center"}
                    color={darkMode ? "white.1000" : "primary.main"}
                    fontSize={{
                      xs: "12px",
                      sm: "14px",
                    }}
                    mt={2}
                  >
                    No notifications yet.
                  </Typography>
                )}
              </InfiniteScroll>
            </Stack>
          </Stack>
        </Box>
      </Menu>
      <audio
        src={notificationAudio}
        id="notification_audio"
        style={{ display: "none" }}
      ></audio>
    </>
  );
}
