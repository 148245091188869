import { create } from "zustand";

export const useRedirect = create((set) => ({
  redirectUrl: undefined,
  setRedirectUrl(url) {
    set(() => ({
      redirectUrl: url,
    }));
  },
}));
