import React from "react";
// import { useAuth } from "../../hooks/store/useAuth";
import { Avatar } from "@mui/material";
import { useTheme } from "@emotion/react";
function ProfileAvatar({ src, alt, sx = {} }) {
  // const { profileImage, username } = useAuth((state) => state.user);
  const { palette } = useTheme();
  // if (!alt) {
  //   src = profileImage;
  //   alt = username;
  // }
  return (
    <Avatar
      sx={{
        borderRadius: "50%",
        background: palette.gradients["main"],
        ...sx,
      }}
      alt={alt}
      src={src}
    />
  );
}

export default ProfileAvatar;
