import React from "react";
import { Box } from "@mui/system";
import { alpha } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import BackButtonLayout from "../../component/ui/BackButtonLayout";
import Heading from "../../component/ui/Heading";
import { useAuth } from "../../hooks/store/useAuth";
import { useSnack } from "../../hooks/store/useSnack";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";
import DynamicMeta from "../../utils/DynamicMeta";
import Input from "../ui/form/Input";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

const ResetEmailForm = () => {
  const { user, userId, setNewEmail } = useAuth();
  const { setSnack } = useSnack();
  const { apiCall } = useApi(true);
  const { apiCall: API } = useApi();
  const navigate = useNavigate();
  const { darkMode } = useThemeStore();
  const { setEmailVerificationFlag } = useAuth();
  const { palette } = useTheme();

  // Function: validate current password
  async function resetEmail(values) {
    try {
      // check new email address and current email most be not same
      if (user.email !== values.newEmail) {
        const res = await API({
          url: `v3/users/check/email/${values.newEmail}`,
        });

        if (res.data.success === false) {
          return setSnack("Email is taken", "warning");
        }
      } else {
        return setSnack("New email is same as old email", "warning");
      }

      let data = { email: values.newEmail };
      let response = await apiCall({
        url: `v2/accounts/${userId}`,
        method: "patch",
        data,
      });
      //   console.log(response);

      //   update email inside store and local storage
      console.log(response.data.data.email);
      setNewEmail(response.data.data.email);
      setEmailVerificationFlag(0);
      setSnack(response.data.message);
      return navigate("/edit-profile");
    } catch (error) {
      console.log(error);
      if (error.response.data.message) {
        setSnack(error.response.data.message, "error");
      }
    }
  }

  // form handling
  const schema = Yup.object({
    newEmail: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email")
      .test("is-unique-email", "This email is taken", async function (value) {
        try {
          let result = await API({
            url: `v3/users/check/email/${value}`,
            method: "get",
          });
          if (result.data.success) return true;
          else return false;
        } catch (error) {
          return false;
        }
      }),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <BackButtonLayout pageTitle={"Email"} titlePrefix={"Change"} isTitleBold>
      <DynamicMeta title="Reset Email | Ploom Social"></DynamicMeta>

      <Box component={"form"} onSubmit={handleSubmit(resetEmail)}>
        <Heading>Reset your email</Heading>

        {/* form inputs */}
        <Input
          name="newEmail"
          register={register}
          errors={formState.errors}
          placeholder="Enter your new email"
          inputLabel={"Email"}
        />

        <ButtonWithLoading
          color="primary"
          disabled={formState.isSubmitting}
          isLoading={formState.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            marginTop: "25px",
            textTransform: "capitalize",
            paddingBlock: "15px",
            "&.Mui-disabled": {
              color: darkMode
                ? alpha(palette.white["1000"], 0.7)
                : "white.1000",
              backgroundColor: darkMode
                ? "primary.disabledDark"
                : "primary.disabledLight",
            },
          }}
        >
          Send
        </ButtonWithLoading>
      </Box>
    </BackButtonLayout>
  );
};

export default ResetEmailForm;
