import React, { useState } from "react";
import BackButtonLayout from "../component/ui/BackButtonLayout";
import { ListItem, alpha } from "@mui/material";
import UserListAvatar from "../component/user/UserListAvatar";
import { useTheme } from "@emotion/react";
import useThemeStore from "../hooks/store/useThemeStore";

import { APIS } from "../api/lists";
import ListItemSkeleton from "../component/skeleton/ListItemSkeleton";
import CustomInfinite from "../component/ui/CustomInfinite";
import useApi from "../hooks/useApi";
import { useSnack } from "../hooks/store/useSnack";
import ButtonWithLoading from "../component/ui/button/ButtonWithLoading";

export default function BlockList() {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const [unBlockId, setUserUnBlockId] = useState();

  const { apiCall, isLoading } = useApi();
  // unblock user
  async function unBlock(id, setResult) {
    try {
      setUserUnBlockId(id);
      const res = await apiCall({
        url: `v3/users/${id}/unblock`,
        method: "post",
      });
      if (res.status === 200) {
        setSnack("Unblock user successfully", "success");
        setResult((prev) => prev.filter((user) => user.blockId !== id));
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <BackButtonLayout pageTitle={"Blocked"}>
      <CustomInfinite
        url={APIS.USER.BLOCK_LIST}
        listSx={{
          "& li:not(:last-child)": {
            borderBottom:
              "1px solid " +
              (darkMode
                ? alpha(palette.white[1000], 0.08)
                : alpha(palette.black[1000], 0.25)),
          },
        }}
        ListItem={({ item: user, setResults }) => (
          <ListItem
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <UserListAvatar
              userId={user.blockId}
              username={user.userDetails.username}
              profileImage={user.userDetails.profileImage}
              timestamp={user.createdAt}
              sx={{ flexGrow: 1 }}
            />
            <ButtonWithLoading
              variant="outlined"
              sx={{
                background: darkMode ? palette.grey[550] : "",
                border: darkMode ? "1px solid " + palette.grey[1000] : "",
                color: darkMode ? "white.1000" : "",
              }}
              isLoading={unBlockId === user.blockId ? isLoading : false}
              onClick={() => unBlock(user.blockId, setResults)}
            >
              Unblock
            </ButtonWithLoading>
          </ListItem>
        )}
        Skeleton={ListItemSkeleton}
      >
        No blocked user found !
      </CustomInfinite>
    </BackButtonLayout>
  );
}
