import * as React from "react";
import { Box, Typography } from "@mui/material";
import DangerInfoIcon from "../../../icons/DangerInfoIcon";
import useThemeStore from "../../../hooks/store/useThemeStore";
export default function NetConnectivityDialog() {
  const { darkMode } = useThemeStore();

  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{
            paddingBlock: "20px",
            minWidth: { xs: "200px", sm: "575px" },
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DangerInfoIcon
            sx={{
              width: { xs: "74px", sm: "80px" },
              height: { xs: "74px", sm: "80px" },
            }}
          />

          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "16px" },
              lineHeight: 1.5,
              color: darkMode ? "grey.900" : "black.1000",
              my: "30px",
              textAlign: "center",
              maxWidth: { sm: "400px" },
              mx: "auto",
            }}
          >
            The device is offline. Please check your internet connection.
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
