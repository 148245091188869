import { Box, Skeleton, Stack } from "@mui/material";
import useThemeStore from "../../../hooks/store/useThemeStore";

const CommentSkeleton = () => {
  const { darkMode } = useThemeStore();
  return (
    <>
      <Box position={"relative"} padding={"10px"} borderRadius={"8px"}>
        {/* avatar with text */}
        <Stack
          direction={"row"}
          gap={"9px"}
          display={"grid"}
          gridTemplateColumns={"auto 1fr"}
        >
          <Skeleton
            sx={{
              backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              borderRadius: "50%",
            }}
            variant="circular"
            width={40}
            height={40}
          />
          <Stack direction={"column"} width={"100%"}>
            <Skeleton
              sx={{
                width: "70%",
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
              }}
              variant="text"
            ></Skeleton>
            <Skeleton
              variant="text"
              sx={{
                backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary",
                width: "50%",
              }}
            ></Skeleton>
          </Stack>
        </Stack>
        <Skeleton
          variant="text"
          height={"16px"}
          sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        ></Skeleton>
        <Skeleton
          variant="text"
          width={"70%"}
          height={"16px"}
          sx={{ backgroundColor: darkMode ? "grey.550" : "bgWhite.secondary" }}
        ></Skeleton>
      </Box>
    </>
  );
};

export default CommentSkeleton;
