import * as React from "react";
import {
  Avatar,
  Button,
  Drawer,
  IconButton,
  Stack,
  alpha,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useTheme } from "@emotion/react";
import AppleIcon from "@mui/icons-material/Apple";
import PloomLogo from "../../icons/PloomLogo";
import useThemeStore from "../../hooks/store/useThemeStore";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/store/useAuth";
import { useFeed } from "../../hooks/store/useFeed";
import NavbarMenu from "./NavbarMenu";
import SearchIcon from "../../icons/SearchIcon";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import HowToIcon from "../../icons/HowToIcon";
import NotificationPanel from "../notificationPanel/NotificationPanel";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";

function Navbar() {
  // const [isVisible, setIsVisible] = React.useState(true);
  const [openNavbar, setOpenNavbar] = React.useState(false);
  const { palette } = useTheme();
  const { darkMode, toggleDarkMode } = useThemeStore();
  const { isLoggedIn, logout, user, fcmToken } = useAuth();
  const { apiCall } = useApi(true);
  const { resetFeedStore } = useFeed();
  const navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname;

  // function for logout user
  function logoutUser() {
    let currentDeviceFcmToken = fcmToken;
    logout();
    resetFeedStore();
    apiCall({
      url: APIS.USER.LOGOUT,
      method: "POST",
      data: { fcmToken: currentDeviceFcmToken },
    });
    navigate("/signin");
  }

  // function for toggle menu
  function toggleMenu() {
    setOpenNavbar(!openNavbar);
  }

  const defaultBackButtonHandler = () => {
    if (
      window.history.length <= 1 ||
      document.referrer === "https://ploom.page.link/"
    ) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <AppBar
      sx={{
        position: "relative",
        maxWidth: "100%",
        // top: isVisible ? "0px" : "-10%",
        backgroundColor: darkMode ? "bgDark.main" : "bgWhite.main",
        zIndex: "1300",
        display: { sm: "block", md: "inline" },
        transition: "all .5s ease-in-out",
        // opacity: isVisible ? 1 : 0,
        boxShadow: `0px 2px 4px 0px ${alpha(
          darkMode ? palette.black["1000"] : palette.white["1000"],
          0.1
        )} `,
      }}
    >
      <Box
        sx={{
          padding: {
            xs: "13px 15px",
            md: "15px 50px",
            lg: "15px 100px 15px 50px",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: darkMode ? "bgDark.main" : "bgWhite.main",
        }}
      >
        {/* logo with back button */}
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: "10px", md: "20px" },
          }}
        >
          {/* back button */}
          {path !== "/" && (
            <Button
              disableRipple={true}
              sx={{
                minWidth: "unset",
                backgroundColor: darkMode ? "grey.550" : "white.1000",
                flexShrink: "0",
                padding: "10px",
                borderRadius: "10px",
                border: darkMode ? "none" : "1px solid",
                borderColor: "grey.1100",
              }}
              onClick={
                // backButtonHandler ||
                defaultBackButtonHandler
              }
            >
              <ArrowBackIosNewRoundedIcon
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  marginLeft: "0",
                }}
                fontSize="18px"
              />
            </Button>
          )}

          {/* logo */}
          <Link to="/">
            <PloomLogo
              sx={{
                width: { xs: "80px", md: "104px" },
                height: { xs: "34px", md: "45px" },
                viewBox: "0 0 104 45",
              }}
            />
          </Link>
        </Stack>

        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {/* {isLoggedIn && ( */}
          <IconButton
            component={Link}
            to={"/search"}
            sx={{ mr: { xs: "0px", md: "30px" }, p: 0 }}
          >
            <SearchIcon
              sx={{
                fill: darkMode ? palette.white[1000] : palette.black[300],
                width: { xs: "20px", md: "24px" },
                height: { xs: "20px", md: "24px" },
              }}
            ></SearchIcon>
          </IconButton>
          <IconButton
            component={Link}
            to={"/coming-soon"}
            sx={{
              display: { xs: "none", md: "flex" },
              mr: { xs: "0px", md: "30px" },
              p: 0,
            }}
          >
            <HowToIcon
              sx={{
                fill: darkMode ? palette.white[1000] : palette.black[300],
                width: { xs: "18px", sm: "24px" },
                height: { xs: "18px", sm: "24px" },
              }}
            ></HowToIcon>
          </IconButton>
          {isLoggedIn && <NotificationPanel />}
          <Stack
            onClick={() => toggleDarkMode()}
            sx={{
              width: "46px",
              height: "27px",
              flexShrink: 0,
              transform: { xs: "scale(0.7)", sm: "scale(1)" },
              // minWidth: "46px",
              borderRadius: "20px",
              backgroundColor: darkMode ? "grey.550" : "white.50",
              border: "1px solid",
              borderColor: darkMode ? "black.850" : "white.50",
              cursor: "pointer",
              padding: "3px",
              display: { xs: "none", md: "inline-flex" },
              boxSizing: "border-box",
            }}
          >
            <IconButton
              aria-label="toggle btn"
              size="small"
              style={{
                transition: "all .2s ease-in",
                color: darkMode ? palette.black["850"] : palette.white["1000"],
              }}
              sx={{
                width: "19px",
                height: "19px",
                m: 0,
                transform: darkMode ? "translateX(100%)" : "translateX(0%)",
                backgroundColor: darkMode ? "white.1000" : "black.800",
                "&:hover": {
                  backgroundColor: darkMode ? "white.1000" : "black.800",
                },
              }}
            >
              {darkMode ? (
                <DarkModeRoundedIcon fontSize="8px" />
              ) : (
                <LightModeRoundedIcon fontSize="8px" />
              )}
            </IconButton>
          </Stack>
          {isLoggedIn && (
            <Avatar
              component={Link}
              to={"/profile"}
              alt="Travis Howard"
              sx={{
                display: { xs: "none", lg: "flex" },
                ml: { xs: "15px", md: "30px" },
                background: darkMode ? "grey.550" : palette.gradients.main,
                backgroundColor: darkMode ? "grey.550" : palette.gradients.main,
                color: "white.1000",
              }}
              src={user?.profileImage}
            />
          )}
          <Stack
            component={"hr"}
            sx={{
              width: "2px",
              height: "28px",
              backgroundColor: darkMode
                ? palette.neutral["150"]
                : palette.black[1000],
              opacity: 0.1,
              border: "none",
              marginInline: { xs: "20px", lg: "30px" },
            }}
          />
          <Button
            href="https://apps.apple.com/app/id1632348045"
            target="_blank"
            variant="contained"
            sx={{
              whiteSpace: "nowrap",
              minWidth: "160px",
              padding: "8px 16px",
              color: "white.1000",
              display: { xs: "none", md: "flex" },
              background: palette.gradients["main"],
              fontSize: "14px",
              fontWeight: 400,
              textTransform: "capitalize",
              lineHeight: "24px",
            }}
            startIcon={<AppleIcon />}
          >
            Download Now
          </Button>
          {isLoggedIn ? (
            <NavbarMenu logoutUser={logoutUser} />
          ) : (
            <>
              <Button
                variant="contained"
                component={Link}
                to="/signin"
                sx={{
                  display: { xs: "none", md: "flex" },
                  minWidth: "100px",
                  marginLeft: "10px",
                  padding: "  8px 16px",
                  color: "white.1000",
                  background: palette.gradients["main"],
                  fontSize: "14px",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  lineHeight: "24px",
                  width: "100%",
                }}
              >
                Login
              </Button>

              <NavbarMenu logoutUser={logoutUser} />
            </>
          )}
        </Box>
      </Box>

      {/* navbar small screen. reponsive part */}
      <Drawer
        anchor={"right"}
        open={openNavbar}
        sx={{
          "& .MuiDrawer-paper": {
            height: { xs: "calc(100vh - 60px)", md: "calc(100vh - 75px)" },
            top: "auto",
            bottom: 0,
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            backgroundColor: darkMode
              ? "bgDark.secondary"
              : "bgWhite.secondary",
          },
          ".MuiDrawer-root": {
            height: { xs: "calc(100vh - 60px)", md: "calc(100vh - 75px)" },
            top: "auto",
            bottom: 0,
          },
          "& .MuiModal-backdrop": {
            backdropFilter: "blur(7.5px)",
            background: alpha(palette.bgDark["secondary"], 0.2),
          },
          //
        }}
        onClose={() => toggleMenu()}
      >
        <Box
          height="100%"
          display={"grid"}
          gridTemplateRows={"1fr auto"}
          minWidth={{ xs: "280px", md: "360px" }}
          role="presentation"
          borderRadius={"10px"}
          backgroundColor={darkMode ? "bgDark.secondary" : "bgWhite.secondary"}
        >
          {/* <Divider /> */}
          <Stack sx={{ width: "100%", padding: "20px", gap: "16px" }}>
            {isLoggedIn ? (
              <Button
                variant="contained"
                onClick={logoutUser}
                sx={{
                  padding: "  8px 16px",
                  color: "white.1000",
                  background: palette.gradients["main"],
                  fontSize: "14px",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  lineHeight: "24px",
                  width: "100%",
                }}
              >
                Logout
              </Button>
            ) : (
              <Button
                variant="contained"
                component={Link}
                to="/signin"
                sx={{
                  padding: "  8px 16px",
                  color: "white.1000",
                  background: palette.gradients["main"],
                  fontSize: "14px",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  lineHeight: "24px",
                  width: "100%",
                }}
              >
                Login
              </Button>
            )}
            <Button
              variant="contained"
              href="https://apps.apple.com/app/id1632348045"
              target="_blank"
              sx={{
                padding: "  8px 16px",
                color: "white.1000",
                background: palette.gradients["main"],
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "capitalize",
                lineHeight: "24px",
                width: "100%",
              }}
              startIcon={<AppleIcon />}
            >
              Download Now
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </AppBar>
  );
}
export default Navbar;
