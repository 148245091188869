import { Box, Button, Stack } from "@mui/material";
import React from "react";
import PloomLogo from "../../icons/PloomLogo";
import { Link } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import useThemeStore from "../../hooks/store/useThemeStore";

const TopBar = ({ openForm, setOpenForm }) => {
  const { darkMode } = useThemeStore();
  //   0.chooseAccountType 1.Registration,  2.Verify, 3.EmailVerify 4.Profile
  return (
    <Box
      sx={{
        display: { xs: "block", lg: "none" },
        backgroundColor: darkMode ? "bgDark.main" : "bgWhite.main",
      }}
    >
      {/* logo with back button */}
      <Stack
        sx={{
          padding: "10px 9px 10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: { xs: "10px", md: "20px" },
        }}
      >
        {/* back button */}
        {openForm !== "Login" &&
          openForm !== "EmailVerify" &&
          openForm !== "Onboarding" && (
            <Button
              disableRipple={true}
              sx={{
                minWidth: "unset",
                backgroundColor: darkMode ? "grey.550" : "white.1000",
                flexShrink: "0",
                padding: "10px",
                borderRadius: "10px",
                border: darkMode ? "none" : "1px solid",
                borderColor: "grey.1100",
              }}
              onClick={() => {
                setOpenForm(
                  openForm === "AddMobileNumber"
                    ? "Login"
                    : openForm === "Varify"
                    ? "AddMobileNumber"
                    : "Login"
                );
              }}
            >
              <ArrowBackIosNewRoundedIcon
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  marginLeft: "0",
                }}
                fontSize="18px"
              />
            </Button>
          )}
        {/* logo */}
        <Link to="/">
          <PloomLogo
            sx={{
              width: { xs: "80px", md: "104px" },
              height: { xs: "34px", md: "45px" },
              viewBox: "0 0 104 45",
            }}
          />
        </Link>
      </Stack>
    </Box>
  );
};

export default TopBar;
