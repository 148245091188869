import { createSvgIcon } from "@mui/material";
export default function RearangeIcon(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3333 12.5714H1.66667C1.22464 12.5714 0.80074 12.4059 0.48818 12.1112C0.175619 11.8165 0 11.4168 0 11C0 10.5832 0.175619 10.1835 0.48818 9.88883C0.80074 9.59413 1.22464 9.42857 1.66667 9.42857H38.3333C38.7754 9.42857 39.1993 9.59413 39.5118 9.88883C39.8244 10.1835 40 10.5832 40 11C40 11.4168 39.8244 11.8165 39.5118 12.1112C39.1993 12.4059 38.7754 12.5714 38.3333 12.5714ZM38.3333 3.14286H1.66667C1.22464 3.14286 0.80074 2.9773 0.48818 2.6826C0.175619 2.3879 0 1.9882 0 1.57143C0 1.15466 0.175619 0.75496 0.48818 0.46026C0.80074 0.16556 1.22464 0 1.66667 0H38.3333C38.7754 0 39.1993 0.16556 39.5118 0.46026C39.8244 0.75496 40 1.15466 40 1.57143C40 1.9882 39.8244 2.3879 39.5118 2.6826C39.1993 2.9773 38.7754 3.14286 38.3333 3.14286ZM1.66667 18.8571H38.3333C38.7754 18.8571 39.1993 19.0227 39.5118 19.3174C39.8244 19.6121 40 20.0118 40 20.4286C40 20.8453 39.8244 21.245 39.5118 21.5397C39.1993 21.8344 38.7754 22 38.3333 22H1.66667C1.22464 22 0.80074 21.8344 0.48818 21.5397C0.175619 21.245 0 20.8453 0 20.4286C0 20.0118 0.175619 19.6121 0.48818 19.3174C0.80074 19.0227 1.22464 18.8571 1.66667 18.8571Z"
        fill={color}
      />
    </>
  );
  return (
    <CreateChatIcon
      width="40"
      height="22"
      viewBox="0 0 40 22"
      fill="none"
      className="Rearrange"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
          "&.Rearrange": {
            width: { xs: "16px", md: "24px" },
            height: { xs: "16px", md: "24px" },
          },
        },
        ...props,
      }}
    />
  );
}