import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Initialize Firebase with your configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

export const app = initializeApp(firebaseConfig);

export async function requestMsgPermissions(setNotification) {
  if (!("Notification" in window) || !("serviceWorker" in window?.navigator)) {
    setNotification(undefined, false);
    return;
  }
  const messaging = getMessaging(app);
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    let token = await getToken(messaging, {
      vapidKey:
        "BPMKOYKM9_yk8OEjegUnLpXSfpS-c4ugxl2XDvsOXWL2IAmhdwkBUkMYpGonYA2ubPZwYbeil3vuUAzYxoohqEA",
    });
    setNotification(token, true);
    return token;
  } else {
    setNotification(undefined, false);
    return;
  }
}
