import { createSvgIcon } from "@mui/material";

export default function Arrow(props) {
  let color = props.color;
  let CreateAddIcon = createSvgIcon(
    <>
      <g id="Frame">
        <g id="Group">
          <path
            id="Layer 47"
            d="M11.4716 2.79821L6.22158 9.54821C6.1943 9.58024 6.16038 9.60597 6.12218 9.62361C6.08398 9.64125 6.04241 9.65039 6.00033 9.65039C5.95825 9.65039 5.91668 9.64125 5.87848 9.62361C5.84028 9.60597 5.80637 9.58024 5.77908 9.54821L0.529081 2.79821C0.496354 2.75687 0.475994 2.70711 0.470353 2.65469C0.464713 2.60227 0.474023 2.54932 0.497206 2.50196C0.520575 2.45476 0.556644 2.41502 0.60136 2.3872C0.646076 2.35937 0.697666 2.34457 0.750331 2.34446H11.2503C11.303 2.34457 11.3546 2.35937 11.3993 2.3872C11.444 2.41502 11.4801 2.45476 11.5035 2.50196C11.5266 2.54932 11.5359 2.60227 11.5303 2.65469C11.5247 2.70711 11.5043 2.75687 11.4716 2.79821Z"
            fill={color}
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateAddIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
          ...props.sx,
        },
        ...props,
      }}
    />
  );
}
