import { List, Typography, alpha } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ListItemSkeleton from "../skeleton/ListItemSkeleton";
// import useApi from "../../hooks/useApi";
import DonationListItem from "../profilefeedcard/DonationListItem";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import useInfinite from "../../hooks/useInfinite";
import { APIS } from "../../api/lists";

const UserDonations = ({ userId }) => {
  const {
    results: donationHistory,
    page,
    hasMore,
    setHasMore,
    setPage,
    totalPages,
    isLoading,
  } = useInfinite({ url: APIS.USER.ALL_CHIPINS_LIST(userId) });

  const { darkMode } = useThemeStore();
  const { palette } = useTheme();

  // --------------------------------------------------------------
  return (
    <>
      <InfiniteScroll
        style={{
          width: "100%",
          backgroundColor: darkMode
            ? palette.bgDark.third
            : palette.white[1000],
          paddingBottom: "12px",
          borderRadius: "8px",
        }}
        dataLength={donationHistory.length}
        next={() => {
          if (page < totalPages) {
            setPage((prev) => prev + 1);
          } else {
            setHasMore(false);
          }
        }}
        hasMore={hasMore}
      >
        {donationHistory.length > 0 && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              paddingTop: 0,
              "& li:not(:last-child)": {
                borderBottom:
                  "1px solid " +
                  (darkMode
                    ? alpha(palette.white[1000], 0.08)
                    : alpha(palette.black[1000], 0.25)),
              },
            }}
          >
            {donationHistory.map((item) => (
              <DonationListItem
                sx={{
                  "& li:not(:last-child)": {
                    borderBottom:
                      "1px solid " +
                      (darkMode
                        ? alpha(palette.white[1000], 0.08)
                        : alpha(palette.black[1000], 0.25)),
                  },
                }}
                item={item}
                key={item._id}
              ></DonationListItem>
            ))}
          </List>
        )}
        {donationHistory.length === 0 && !isLoading && (
          <Typography
            textAlign={"center"}
            color={darkMode ? "white.1000" : "primary.main"}
            fontSize={{
              xs: "14px",
              sm: "14px",
              md: "18px",
            }}
          >
            No donation history found
          </Typography>
        )}
        {isLoading && <ListItemSkeleton></ListItemSkeleton>}
      </InfiniteScroll>
    </>
  );
};

export default UserDonations;
