import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, alpha } from "@mui/material";
// import { useSocket } from "../../hooks/store/useSocket";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import SelectTypeForm from "./SelectTypeForm";
import OrgInfoForm from "./OrgInfoForm";
import Submitted from "./Submitted";

export default function AddAccountTypeDialog({
  open = false,
  setOpen,
  verifyUserIdentity,
  getLatestUserData,
}) {
  const { darkMode } = useThemeStore();
  const [openForm, setOpenForm] = React.useState("selectAccountType"); // 1.selectAccountType 2.fillDetails 3.completed
  const [accountType, setAccountType] = React.useState(false);
  const { palette } = useTheme();

  return (
    <React.Fragment>
      {window.location.pathname !== "/email-verification" && (
        <Dialog
          maxWidth={"md"}
          open={open}
          sx={{
            "& .MuiPaper-root": {
              mx: "15px",
              borderRadius: "15px!important",
              boxShadow: "none",
              border: "none",
              backgroundColor: "unset",
            },
            "& .MuiDialog-container": {
              background: alpha(palette.black[1000], 0.6),
              backdropFilter: "blur(2px)",
            },
          }}
        >
          <DialogContent
            sx={{
              padding: "0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            }}
          >
            {openForm === "selectAccountType" && (
              <SelectTypeForm
                setOpenForm={setOpenForm}
                accountType={accountType}
                setAccountType={setAccountType}
                verifyUserIdentity={verifyUserIdentity}
                getLatestUserData={getLatestUserData}
              />
            )}

            {openForm === "fillDetails" && (
              <OrgInfoForm
                setOpenForm={setOpenForm}
                accountType={accountType}
                verifyUserIdentity={verifyUserIdentity}
                getLatestUserData={getLatestUserData}
              />
            )}

            {openForm === "completed" && <Submitted setOpen={setOpen} />}
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}
