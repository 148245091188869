import React, { useEffect, useState } from "react";
import BackButtonLayout from "../ui/BackButtonLayout";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Icon,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import CameraIcon from "../../icons/CameraIcon";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import EditIcon from "../../icons/EditIcon";
import { Link, useNavigate } from "react-router-dom";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import { useAuth } from "../../hooks/store/useAuth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../ui/form/Input";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";
import axios from "axios";
import { useRedirect } from "../../hooks/store/useRedirect";
import MobileInput from "../../component/ui/form/MobileInput";
import { socket } from "../../socket";
import DustbinIcon2 from "../../icons/DustbinIcon2";
import FileUploadIcon from "../../icons/FileUploadIcon";

const EditProfileForm = () => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { apiCall: API } = useApi();
  const { setSnack } = useSnack();
  const navigate = useNavigate();
  const {
    isLoggedIn,
    userId,
    user,
    setUserProfile,
    setUserDatail,
    setEmailVerificationFlag,
  } = useAuth();
  const { setRedirectRoute } = useRedirect();
  const [newImage, setNewImage] = useState(user?.profileImage);
  let oldImage = user?.profileImage;
  const [timer, setTimer] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let einRegExp = /^(\d{2}\d{7})$/;
  let nonProfileSchema = {
    orgName: Yup.string()
      .required("Organization name is required")
      .min(4, " Organization name must be at least 4 characters"),
    EIN: Yup.string()
      .required("EIN is required")
      .matches(einRegExp, "Invalid EIN number"),
  };
  let organizationSchema = {
    orgName: Yup.string().required("Organization name is required"),
  };
  const schema = Yup.object({
    firstName: Yup.string().required("First Name is required").min(3).max(24),
    lastName: Yup.string().required("Last Name is required").min(3).max(24),
    profilePicture: Yup.array().min(1).max(1),
    ...(user.accountType === "Non-profit" ? nonProfileSchema : {}),
    ...(user.accountType === "Organization" ? organizationSchema : {}),
  });

  // check user is loaggedin or not
  useEffect(
    () => {
      if (!isLoggedIn) {
        setRedirectRoute("/edit-profile");
        return navigate("/");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    values: {
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      orgName: user?.orgName,
      EIN: user?.EIN,
    },
    mode: "onChange",
  });

  // upload profile pic in aws
  const uploadFileInAWS = async (file) => {
    let res;

    try {
      res = await API({
        url: `v3/get-signed-url/profiles/${userId}`,
      });
      if (res.status === 200) {
        res = res.data.data;
        await axios({
          method: "put",
          url: res.url,
          headers: {
            "Content-Type": file.type,
          },
          data: file,
        });
      } else throw Error("link can not get");
    } catch (error) {
      console.log(error);
    }
    return res;
  };

  // update profile handler
  const resetUserData = async (values) => {
    try {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        orgName: values?.orgName,
        ein: values?.EIN,
      };
      if (
        user.firstName === data.firstName &&
        user.lastName === data.lastName &&
        user.orgName === data.orgName &&
        newImage === user.profileImage &&
        (user.ein ? user.ein : undefined) === data.ein
      ) {
        return setSnack("everything is same as before", "warning");
      }

      //2. validate profile image type if usder went to change profile image
      if (values.file !== undefined) {
        try {
          if (
            values.file.type === "image/png" ||
            values.file.type === "image/jpg" ||
            values.file.type === "image/jpeg" ||
            values.file.type === "image/webp"
          ) {
            let res = await uploadFileInAWS(values.file);
            data.profileImage = `${process.env.REACT_APP_MEDIA_PREFIX_URL}${res.uuid}`;
          } else {
            return setSnack(
              "profile picture file must be png,jpg,jpeg or webp required",
              "error"
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (oldImage !== undefined && newImage === undefined) {
        data.profileImage = "";
      }

      // // 4. if user didn't set any profile picture than remove profileImage field from data
      // if (data.profileImage === "") {
      //   delete data.profileImage;
      // }

      let response = await API({
        url: `v3/accounts`,
        method: "patch",
        data,
      });

      //4. if profile image is change than update existing userAuth store image profile
      if (response.data.data.profileImage) {
        setUserProfile(response.data.data.profileImage);
      }

      if (newImage === undefined || newImage === "") {
        setUserProfile(undefined);
      }
      // update existing userAuth store data
      setUserDatail(
        values?.firstName,
        values?.lastName,
        user.email,
        values?.orgName,
        user.accountType,
        values?.EIN
      );
      setSnack(response.data.message, "success");
      return navigate("/profile");
    } catch (error) {
      console.log(error);
      if (error.response) {
        setSnack(error.response.data.message, "error");
      }
    }
  };

  // verify email
  const verifyMail = async () => {
    try {
      setDisableBtn(true);
      setTimer(30);
      const response = await API({
        url: `v3/users/${userId}/verify-email`,
        method: "post",
      });
      setSnack(response.data.message);
    } catch (error) {
      setDisableBtn(false);
      console.log(error);
    }
  };

  // socket event for email is verified
  const emailVerified = React.useCallback(
    async (value) => {
      try {
        if (value.email_verified.email) {
          setEmailVerificationFlag(value.email_verified.email);
          if (window.location.pathname === "/edit-profile")
            return setSnack("Your email has been verified");
          else return;
        }
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    // const data = { userId: userId };
    socket.on("connect", () => {});
    if (window.location.pathname === "/edit-profile")
      socket.on("evntUpdateAccountCheck", emailVerified);
    // socket.emit("evntJoinUserToRoom", data);
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    return () => {
      socket.off("connect", () => {});
      socket.off("disconnect", () => {});
    };
  }, [emailVerified, userId]);

  // Start the countdown timer when the component mounts
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setDisableBtn(false); // Enable the "Resend OTP" button when the timer reaches 0
        clearInterval(interval); // Stop the timer
      }
    }, 1000);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [timer]);

  return (
    <BackButtonLayout
      pageTitle={"Edit Profile"}
      isTitleBold
      // sx={{ maxWidth: "673px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Box
          component={"form"}
          onSubmit={handleSubmit(resetUserData)}
          sx={{ marginTop: { md: "24px" }, width: "100%" }}
        >
          <Stack
            sx={{
              position: "relative",
              width: { xs: "90px", sm: "100px", md: "196px" },
              height: { xs: "90px", sm: "100px", md: "190px" },
            }}
          >
            <Avatar
              sx={{
                width: { xs: "90px", sm: "100px", md: "196px" },
                height: { xs: "90px", sm: "100px", md: "190px" },
                borderRadius: { xs: "10px", md: "10px" },
                backgroundColor: "primary.main",
                cursor: "pointer",
              }}
              // onClick={handleOpen}
              src={newImage}
            ></Avatar>
            {/* <Icon
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: "21px", md: "46px" },
                height: { xs: "21px", md: "46px" },
                borderRadius: "10px",
                position: "absolute",
                bottom: "9.31px",
                right: "9.33px",
                background: "#F6F6F6",
                cursor: "pointer",
              }}
              component={"label"}
              htmlFor="userImage"
            >
              <CameraIcon
                width={{ xs: "18px", md: "25px" }}
                height={{ xs: "18px", md: "25px" }}
              ></CameraIcon>
            </Icon>

            <input
              type="file"
              name="profilePicture"
              accept="image/png, image/jpg, image/jpeg, image/webp" // Fix the accepted file types
              id="userImage"
              style={{ display: "none" }}
              onChange={(e) => {
                const imageFile = e.target.files[0];
                if (imageFile) {
                  const reader = new FileReader();
                  // Convert the image file to a string
                  reader.readAsDataURL(imageFile);
                  reader.addEventListener("load", () => {
                    setNewImage(reader.result);
                  });
                }
                setValue("file", e.target.files[0]);
              }}
            /> */}

            <>
              <Icon
                aria-label="more"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: { xs: "21px", md: "46px" },
                  height: { xs: "21px", md: "46px" },
                  borderRadius: "10px",
                  position: "absolute",
                  bottom: "9.31px",
                  right: "9.33px",
                  backgroundColor: darkMode ? "grey.550" : "white.1000",
                  cursor: "pointer",
                }}
              >
                <CameraIcon
                  width={{ xs: "18px", md: "25px" }}
                  height={{ xs: "18px", md: "25px" }}
                  color={darkMode ? palette.white[1000] : palette.black[1000]}
                ></CameraIcon>
              </Icon>
              <Menu
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                sx={{
                  width: "120px",
                  position: "absolute",
                  marginTop: { xs: "8px", sm: "12px" },
                  marginLeft: { xs: "-14px", sm: "0px" },
                  top: 0,
                  left: 0,
                  borderRadius: "10px",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    position: "absolute",
                    top: "0",
                    left: 0,
                    overflow: "visible",
                    backgroundColor: darkMode ? "grey.550" : "white.1000",
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: 15,
                      borderBottom: "8px solid",
                      borderBottomColor: darkMode ? "grey.550" : "white.1000",
                      borderRight: "8px solid transparent",
                      borderLeft: "8px solid transparent",
                      transform: "translateY(-100%)",
                    },

                    "& ul": {
                      padding: "10px 10px",
                      display: "flex",
                      flexDirection: "column",
                    },
                    "&.MuiPopover-paper": {
                      maxWidth: "unset",
                      borderRadius: "8px",
                      filter: `drop-shadow(0px 0px 20px  ${alpha(
                        palette.black[1000],
                        0.07
                      )})`,
                    },
                  },
                }}
              >
                <MenuItem
                  component={"label"}
                  htmlFor="userImage"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "10px",
                    paddingInline: "4px",
                    minHeight: { xs: "unset", sm: "45px" },
                  }}
                >
                  <Icon>
                    <FileUploadIcon
                      color={
                        darkMode ? palette.white[1000] : palette.black[1000]
                      }
                    />
                  </Icon>
                  <Typography
                    sx={{
                      color: darkMode ? "white.1000" : "black.350",
                      fontFamily: "Poppins",
                      fontSize: { xs: "12px", sm: "14px" },
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Upload From Local Device
                  </Typography>
                  <input
                    type="file"
                    name="profilePicture"
                    accept="image/png, image/jpg, image/jpeg, image/webp" // Fix the accepted file types
                    id="userImage"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const imageFile = e.target.files[0];
                      if (imageFile) {
                        const reader = new FileReader();
                        // Convert the image file to a string
                        reader.readAsDataURL(imageFile);
                        reader.addEventListener("load", () => {
                          setNewImage(reader.result);
                        });
                      }
                      setValue("file", e.target.files[0]);
                      handleClose();
                    }}
                  />
                </MenuItem>

                {newImage && (
                  <>
                    <Divider
                      sx={{
                        "&.MuiDivider-root": {
                          m: "0px !important",
                          borderColor: darkMode ? "grey.555" : "white.700",
                          opacity: darkMode ? 0.2 : 1,
                        },
                      }}
                    />
                    <MenuItem
                      onClick={() => {
                        setNewImage(undefined);
                        setValue("file", undefined);
                        handleClose();
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        gap: "10px",
                        paddingInline: "4px",
                        minHeight: { xs: "unset", sm: "45px" },
                      }}
                    >
                      <Icon>
                        <DustbinIcon2
                          color={
                            darkMode ? palette.white[1000] : palette.black[1000]
                          }
                        />
                      </Icon>
                      <Typography
                        sx={{
                          color: darkMode ? "white.1000" : "black.350",
                          fontFamily: "Poppins",
                          fontSize: { xs: "12px", sm: "14px" },
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        Remove Profile Photo
                      </Typography>
                    </MenuItem>
                  </>
                )}
              </Menu>
            </>
          </Stack>

          <Stack sx={{ mt: { xs: "0x", md: "25px" }, width: "100%" }}>
            <Input
              name="username"
              inputLabel={"User Name"}
              defaultValue={user.username}
              disabled
              sx={{
                "& .Mui-disabled": {
                  cursor: "no-drop",
                  opacity: 1,
                },
              }}
            ></Input>

            <Input
              name="firstName"
              register={register}
              inputLabel={"First Name"}
              errors={formState.errors}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ zIndex: 2 }}>
                    <Icon
                      sx={{
                        position: "absolute",
                        top: "30%",
                        transform: "translate(-50%)",
                        right: "0%",
                        zIndex: 1,
                      }}
                    >
                      <EditIcon
                        color={
                          darkMode
                            ? palette.white["1000"]
                            : palette.black["350"]
                        }
                      ></EditIcon>
                    </Icon>
                  </InputAdornment>
                ),
              }}
            ></Input>

            <Input
              name="lastName"
              register={register}
              inputLabel={"Last Name"}
              errors={formState.errors}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ zIndex: 2 }}>
                    <Icon
                      sx={{
                        position: "absolute",
                        top: "30%",
                        transform: "translate(-50%)",
                        right: "0%",
                        zIndex: 1,
                      }}
                    >
                      <EditIcon
                        color={
                          darkMode
                            ? palette.white["1000"]
                            : palette.black["350"]
                        }
                      ></EditIcon>
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            {(user.accountType === "Organization" ||
              user.accountType === "Non-profit") && (
              <Input
                name="orgName"
                register={register}
                inputLabel={"Organization"}
                errors={formState.errors}
                placeholder={"Add Organization Name"}
                defaultValue={user.orgName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" sx={{ zIndex: 2 }}>
                      <Icon
                        sx={{
                          position: "absolute",
                          top: "30%",
                          transform: "translate(-50%)",
                          right: "0%",
                          zIndex: 1,
                        }}
                      >
                        <EditIcon
                          color={
                            darkMode
                              ? palette.white["1000"]
                              : palette.black["350"]
                          }
                        ></EditIcon>
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {user.accountType === "Non-profit" && (
              <Input
                name="EIN"
                register={register}
                inputLabel={"Non Profit EIN"}
                errors={formState.errors}
                placeholder={"Add EIN Number"}
                defaultValue={user.ein}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" sx={{ zIndex: 2 }}>
                      <Icon
                        sx={{
                          position: "absolute",
                          top: "30%",
                          transform: "translate(-50%)",
                          right: "0%",
                          zIndex: 1,
                        }}
                      >
                        <EditIcon
                          color={
                            darkMode
                              ? palette.white["1000"]
                              : palette.black["350"]
                          }
                        ></EditIcon>
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <Input
              name={"email"}
              inputLabel={"Email"}
              defaultValue={user.email}
              disabled
              fullWidth
              type="text"
              sx={{
                "& .Mui-disabled": {
                  cursor: "no-drop",
                  opacity: 1,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ zIndex: 2 }}>
                    <Icon
                      sx={{
                        position: "absolute",
                        top: "30%",
                        transform: "translate(-50%)",
                        right: "0%",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    >
                      <Link to="/reset-email">
                        <EditIcon
                          color={
                            darkMode
                              ? palette.white["1000"]
                              : palette.black["350"]
                          }
                        ></EditIcon>
                      </Link>
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            {!user?.email_verified && (
              <Stack
                display={"flex"}
                sx={{
                  justifyContent: "flex-end",
                  alignItems: "end",
                  paddingInline: "5px",
                  marginBottom: "-24px",
                }}
              >
                <Button
                  onClick={verifyMail}
                  disabled={disableBtn ? true : false}
                  sx={{
                    display: "block",
                    maxWidth: "64px",
                    color: darkMode ? "white.1000" : "primary.main",
                  }}
                >
                  Verify
                </Button>
              </Stack>
            )}

            <Input
              inputLabel="Password"
              placeholder="************"
              defaultValue={"**********"}
              disabled
              fullWidth
              type="password"
              sx={{
                "& .Mui-disabled": {
                  cursor: "no-drop",
                  opacity: 1,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ zIndex: 2 }}>
                    <Icon
                      sx={{
                        position: "absolute",
                        top: "30%",
                        transform: "translate(-50%)",
                        right: "0%",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    >
                      <Link to="/change-password">
                        <EditIcon
                          color={
                            darkMode
                              ? palette.white["1000"]
                              : palette.black["350"]
                          }
                        ></EditIcon>
                      </Link>
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            <MobileInput
              inputLabel={"mobile number"}
              placeholder={`+${user.code} ${user.mobile}`}
              defaultValue={`+${user.code} ${user.mobile}`}
              disabled
              sx={{
                "& .Mui-disabled": {
                  cursor: "no-drop",
                  opacity: 1,
                },
              }}
              editIcon={
                <Icon
                  sx={{
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                >
                  <Link to="/change-number">
                    <EditIcon
                      color={
                        darkMode ? palette.white["1000"] : palette.black["350"]
                      }
                    ></EditIcon>
                  </Link>
                </Icon>
              }
            />
          </Stack>

          <ButtonWithLoading
            color="primary"
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              marginTop: "25px",
              textTransform: "capitalize",
              paddingBlock: "15px",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Update
          </ButtonWithLoading>
        </Box>
      </Box>
    </BackButtonLayout>
  );
};

export default EditProfileForm;
