import React, { memo } from "react";
import UserDonations from "./UserDonations";

import { Box } from "@mui/material";
import UserFeeds from "./UserFeeds";
import CustomTabs from "../ui/CustomTabs";
import { useAuth } from "../../hooks/store/useAuth";

const ProfileTabs = memo(({ userId, defaultTab }) => {
  const tabs = [
    {
      name: "Campaigns",
      value: "campaigns",
      Component: () => <UserFeeds userId={userId} />,
    },
    {
      name: "Contribution",
      value: "contribution",
      Component: () => <UserDonations userId={userId} />,
    },
  ];
  const { userId: loggedInUserId } = useAuth();
  return (
    <>
      <Box
        width={"100%"}
        sx={{
          mx: "auto",
          maxWidth: "800px",
          minHeight: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "600px",
          },
          marginTop: { xs: "20px", sm: "20px" },
        }}
      >
        <Box display={"grid"} gridTemplateRows={"auto 1fr"}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              typography: "body1",
            }}
          >
            <CustomTabs
              tabs={userId !== loggedInUserId ? tabs.slice(0, 1) : tabs}
              defaultTab={defaultTab}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default ProfileTabs;
