import React, { useCallback, useEffect } from "react";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";
import { useAuth } from "../../hooks/store/useAuth";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useFeed } from "../../hooks/store/useFeed";
import { useLocation } from "react-router-dom";
import { useImmersivePage } from "../../hooks/store/useImmersivePage";
import { Box } from "@mui/material";
import "./style.css";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import card from "../../assets/Nuvei/Card.svg";
import cardDark from "../../assets/Nuvei/CardDark.svg";
import Paypal from "../../assets/Nuvei/Paypal.svg";

const PaymentGateway = ({
  amount,
  sessionToken,
  setSessionToken,
  // setAmount,
  setActiveButton,
  begId,
  offAutoClose = false,
  email,
  name,
  clearData,
  redirectToImmersive = false,
  merchantDetails,
  openShareDrawer,
}) => {
  const { apiCall } = useApi();
  const { setSnack } = useSnack();
  const { isGuest, logout, isLoggedIn, user } = useAuth();
  const { setShowPaymentDrawer } = useAllModal();
  const { updateRaised } = useFeed();
  const { setBegDetails } = useImmersivePage();
  const location = useLocation();
  const path = location.pathname;
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();

  const initCheckout = useCallback(() => {
    // payment confirm method
    async function confirmPayment(paymentData) {
      try {
        // first get the latest access token
        apiCall({
          url: "v3/webhooks/nuvei/confirm",
          method: "POST",
          data: {
            transactionId: paymentData.transactionId,
            sessionToken: sessionToken,
            transactionStatus: paymentData.transactionStatus,
            email: email || user.email,
            name: name || user.firstName + " " + user.lastName,
          },
        });
        paymentData.result === "APPROVED" && !isLoggedIn && logout();

        // code for update beg details after payment
        if (!redirectToImmersive) {
          const updatedBeg = await apiCall({
            url: `v3/begs/${begId}`,
            method: "GET",
          });
          // console.log(result);
          updatedBeg.data.data.amountRaised &&
            path === "/" &&
            updateRaised(
              begId,
              updatedBeg.data.data.amountRaised,
              updatedBeg.data.data.donors
            );

          String(path).slice(0, 5) === "/feed" &&
            setBegDetails(updatedBeg.data.data);
        }

        if (redirectToImmersive && paymentData.result === "APPROVED") {
          return openShareDrawer();
        } else {
        }
      } catch (error) {
        setActiveButton("1");
        setSessionToken("");
        setShowPaymentDrawer(false);
        clearData();
        isGuest && logout();
        // setSnack("Something gone wrong try again later.", "error");
        console.log(error);
      }
    }
    // checkout method
    window.checkout({
      sessionToken: sessionToken,
      env: process.env.REACT_APP_NUVEI_ENV,
      merchantSiteId: process.env.REACT_APP_NUVEI_MERCHANT_SITEID,
      merchantId: process.env.REACT_APP_NUVEI_MERCHANT_ID,
      country: "US",
      currency: "USD",
      amount: amount,
      fullName: "",
      email: email || user.email,
      billingAddress: {
        email: email || user.email,
        country: "US",
      },
      apmConfig: {
        applePay: {
          buttonStyle: "black", // black (default), white, white-outline
          buttonType: "checkout", // buy (default), plain,
          // book, checkout, order, pay, subscribe, inStore,
          // addMoney, continue, contribute, donate, reload,
          // rent, setUp, support, tip, topUp
        },
      },
      renderTo: "#checkout",
      onSelectPaymentMethod: function (paymentDetails) {
        console.log("onSelectPaymentMethod", paymentDetails);
      },
      onResult: function (result) {
        // if (renderInNewTab) {
        //   result.amount = amount;
        //   result.sessionToken = sessionToken;
        //   const url =
        //     result.result === "APPROVED"
        //       ? "/donation-success"
        //       : "/donation-failure";
        //   window.open(url + "?payload=" + JSON.stringify(result), "_blank");
        //   return;
        // }
        if (result.result === "APPROVED") {
          setSnack("Your transaction completed.");
          setActiveButton("1");
          if (!Boolean(offAutoClose)) {
            setShowPaymentDrawer(false);
            clearData();
            isGuest && logout();
          }
          confirmPayment(result);
        } else {
          setSnack(result.errorDescription + ", please try again.", "error");
          confirmPayment(result);
          if (redirectToImmersive) {
            return setSessionToken("");
          }
        }
        if (!Boolean(offAutoClose)) {
          setSessionToken("");
        }
      },
      merchantDetails: merchantDetails,
      fieldStyle: {
        base: {
          iconColor: "#c4f0ff",
          color: darkMode ? palette.white[1000] : palette.black[1000],
          fontFamily: "Nunito Sans",
          fontSmoothing: "antialiased",
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  //   load checkout script
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.safecharge.com/safecharge_resources/v1/checkout/checkout.js";
    script.async = true;
    script.onload = () => {
      initCheckout(); // Call the initialization once the script is loaded
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup: remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, [initCheckout]);

  return (
    <>
      <Box
        className="container"
        sx={{
          backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
          width: "100%",
          margin: "0 auto",
          padding: "12px 14px",
        }}
      >
        <Box
          id="checkout"
          sx={{
            "div.sfc-main-container .sfc-cfa-button": {
              background: palette.gradients.main,
              border: "none",
              borderRadius: "12px",
            },

            ".sfc-main-container .input-row label": {
              color: darkMode ? palette.white[1000] : palette.primary.main,
            },

            "div.sfc-main-container input": {
              backgroundColor: "transparent",
              border: "1px solid",
              borderColor: darkMode ? "neutral.650" : "neutral.150",
              borderRadius: "6px",
              color: darkMode ? "white.1000" : "black.1000",
            },
            ".inputField::placeholder": {
              color: darkMode ? "white.1000" : "black.1000",
            },
            "div.sfc-main-container #ccCardNumber, .sfc-main-container #ccCardNumber, .sfc-main-container #ccExpYear, .sfc-main-container #ccCVV ":
              {
                backgroundColor: "transparent",
                border: "1px solid",
                borderColor: darkMode ? "neutral.650" : "neutral.150",
                borderRadius: "6px",
                color: darkMode ? "white.1000" : "black.1000",
              },
            ".sfc-main-container .quick-pm-message, .sfc-main-container .sc-underline-message":
              {
                backgroundColor: darkMode ? "bgDark.third" : "bgWhite.main",
                border: "1px solid",
                borderColor: darkMode ? "transparent" : "transparent",
                borderRadius: "6px",
                color: darkMode ? "white.1000" : "black.1000",
              },
            ".sfc-main-container .quick-pm-underline": {
              backgroundColor: darkMode ? "neutral.650" : "neutral.150",
            },
            ".sfc-main-container .quick-pm-message": {
              color: darkMode ? "white.1000" : "black.1000",
            },
            ".sfc-main-container .pm-logo.cc_card": {
              background: `url(${darkMode ? card : cardDark}) no-repeat center`,
            },
            ".sfc-main-container .pm-logo.apmgw_expresscheckout": {
              background: `url(${Paypal}) no-repeat center`,
            },
            ".sfc-main-container .label-offset": {
              marginLeft: 0,
              color: darkMode ? "white.1000" : "primary.main",
            },
            ".sfc-main-container div.pm-logo": {
              width: "60px",
              height: "36px",
            },
            ".sfc-main-container .line-container input:checked~.checkmark": {
              backgroundColor: "transparent",
              borderColor: darkMode ? "white.1000" : "primary.main",
            },
            ".sfc-main-container .line-container .checkmark:after": {
              backgroundColor: darkMode ? "white.1000" : "primary.main",
            },
            "div.sfc-main-container .line-container": {
              borderRadius: "10px",
              border: "1px solid",
              background: darkMode ? "#3C3C3C" : "#FCFCFC",
              borderColor: darkMode ? "#505050" : "#F1F1F1",
            },
            ".sfc-main-container .form-radio-style": {
              borderRadius: "10px",
              border: "1px solid",
              borderColor: darkMode ? "#505050" : "#F1F1F1",
            },
            ".sfc-main-container .checkmark ": {
              background: "none",
              borderColor: "grey.200",
            },
            "div.sfc-main-container .main-title": {
              color: darkMode ? "white.1000" : "black.1000",
            },
            ".checkbox-label": {
              color: darkMode ? "white.1000" : "primary.disabledLight",
            },
            ".sfc-main-container input[type=checkbox]::before": {
              backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            },
            ".sfc-main-container input[type=checkbox]:checked::before": {
              background: palette.gradients.main,
              border: "none",
            },
          }}
        ></Box>
      </Box>
    </>
  );
};

export default PaymentGateway;
