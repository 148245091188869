import { Box, Skeleton, Stack } from "@mui/material";

const RequestNotificationItemSkeleton = () => {
  return (
    <>
      <Box position={"relative"} padding={"10px"} borderRadius={"8px"}>
        {/* avatar with text */}
        <Stack
          direction={"row"}
          gap={"9px"}
          display={"grid"}
          gridTemplateColumns={"auto 1fr"}
        >
          <Skeleton variant="circular" width={40} height={40} />
          <Stack direction={"column"} width={"100%"}>
            <Skeleton
              variant="text"
              sx={{
                width: "70%",
              }}
            ></Skeleton>
            <Skeleton
              variant="text"
              sx={{
                width: "50%",
              }}
            ></Skeleton>
          </Stack>
        </Stack>
        <Skeleton variant="text"></Skeleton>
        <Skeleton variant="text" width={"70%"}></Skeleton>
        <Stack
          display={"grid"}
          gap="12px"
          gridTemplateColumns={"1fr 1fr"}
          mt={2}
        >
          <Skeleton variant="rounded" width={"100%"} height={"30px"}></Skeleton>
          <Skeleton variant="rounded" width={"100%"} height={"30px"}></Skeleton>
        </Stack>
      </Box>
    </>
  );
};

export default RequestNotificationItemSkeleton;
