import { createSvgIcon } from "@mui/material";
export default function Shield(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/Shield Done">
        <g id="Shield Done">
          <path
            id="Stroke 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9846 22.3871C11.9846 22.3871 19.6566 20.0641 19.6566 13.6601C19.6566 7.25513 19.9346 6.75513 19.3196 6.13913C18.7036 5.52313 12.9906 3.53113 11.9846 3.53113C10.9786 3.53113 5.26557 5.52313 4.65057 6.13913C4.03457 6.75513 4.31257 7.25513 4.31257 13.6601C4.31257 20.0641 11.9846 22.3871 11.9846 22.3871Z"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 3"
            d="M9.38574 12.6558L11.2777 14.5508L15.1757 10.6508"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateChatIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
