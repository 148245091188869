import { useTheme } from "@emotion/react";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import useThemeStore from "../../hooks/store/useThemeStore";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: Number(values.value).toFixed(2),
          },
        });
      }}
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function AmountInput({
  name,
  label,
  type = "text",
  first = false,
  sx = {},
  register = () => {},
  errors = {},
  serverError,
  inputLabel,
  boxSX = {},
  setAmount = () => {},
  amount,
  ...rest
}) {
  let { typography, palette } = useTheme();
  let { darkMode } = useThemeStore();
  const handleChange = (event) => {
    const inputValue = event.target.value;

    // Remove any non-numeric characters except for dot
    const numericValue = inputValue.replace(/[^0-9.]/g, "");

    // Allow only two decimal places
    const formattedValue = /^-?\d*\.?\d{0,2}$/.test(numericValue)
      ? numericValue
      : amount;

    // Round to the nearest whole number
    const roundedValue = Math.round(Number(formattedValue));
    console.log(roundedValue);
    setAmount(roundedValue);
  };
  return (
    <Box
      sx={{
        marginTop: { xs: first ? "0px" : "30px", lg: first ? "0px" : "25px" },
        ...boxSX,
      }}
    >
      {label && (
        <label
          style={{
            color: darkMode ? palette.white["1000"] : palette.neutral["350"],
            fontFamily: typography.manrope,
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "146%",
            cursor: "pointer",
          }}
          htmlFor={name}
        >
          {label}
        </label>
      )}

      <TextField
        fullWidth
        label={inputLabel && inputLabel}
        name={name}
        placeholder="0"
        value={amount > 0 ? Math.round(amount) : ""}
        onChange={handleChange}
        pattern="/[^0-9]/g"
        id={`formatted-numberformat-input ${name}`}
        onKeyDown={(e) => {
          const numberRegex = /^\d+$/;
          const value = e.target.value;

          switch (e.key) {
            case "Backspace":
            case "Delete":
            case "ArrowLeft":
            case "ArrowRight":
              break;
            case ".":
              e.preventDefault();
              break;
            default:
              if (!numberRegex.test(e.key)) {
                return e.preventDefault();
              }
              if (value.includes(".") && value.split(".")[1]?.length >= 2) {
                return e.preventDefault();
              }
              break;
          }
        }}
        onPaste={(e) => {
          e.preventDefault();
        }}
        InputProps={{
          inputComponent: NumericFormatCustom,
          onWheel: (e) => e.target.blur(),
          startAdornment: (
            <InputAdornment position="start" sx={{ zIndex: 2 }}>
              <Typography
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  fontFamily: "Poppins",
                  fontSize: { xs: "20px", sm: "24px" },
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}
              >
                ${" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: { xs: "14px", md: "15px" },
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  USD
                </Typography>
              </Typography>
            </InputAdornment>
          ),
          autoComplete: "off",
        }}
        sx={{
          mt: { md: "8px" },
          "input:placeholder-shown": { border: "none" },
          "input:focus": { border: "none" },
          "input:hover": { border: "none" },
          "& label, & label.Mui-disabled": {
            color: darkMode ? "white.1000" : "primary.main",
            fontSize: "16px",
            fontWeight: 500,
            fontStyle: "normal",
            lineHeight: "normal",
          },
          "& .MuiOutlinedInput-input": {
            padding: 0,
          },
          "& input,input:is(:hover,:focus)": {
            textAlign: "right",
            border: "none",
            zIndex: 2,
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Poppins",
            fontSize: { xs: "20px", sm: "25px" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            opacity: 1,
          },
          "& fieldset": {
            borderRadius: "10px",
            border: "1px solid",
            borderColor: darkMode ? "neutral.650" : "neutral.150",
          },

          "& :-webkit-autofill": {
            BackgroundClip: "text",
            textFillColor: darkMode ? "#ffffff" : "black.1000",
            transition: "background-color 5000s ease-in-out 0s",
            boxShadow: ` inset 0 0 20px 20px ${
              darkMode ? "neutral.650" : "white.1000"
            }`,
          },
          "& label.Mui-focused": {
            color: darkMode ? "white.1000" : "primary.main",
          },
          "& .MuiOutlinedInput-root": {
            padding: { xs: "10px 11px 10px 16px", md: "14px 20px" },
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: darkMode ? "white.1000" : "black.1000",
            },
          },
          "& .Mui-disabled": {
            cursor: "no-drop",
            color: darkMode ? "white.1000" : "primary.main",
            opacity: 0.6,
          },
          "& input.Mui-disabled": {
            textFillColor: darkMode
              ? palette.white["1000"]
              : palette.black["1000"],
          },
          " & .Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: darkMode
              ? `${palette.neutral["650"]}!important`
              : `${palette.neutral["150"]}!important`,
          },
          ".MuiFormHelperText-root": {
            marginInline: 0,
            mt: "5px",
          },
          ".MuiInputAdornment-root": {
            ml: 0,
          },

          "& .MuiInputBase-input::placeholder": {
            color: darkMode ? palette.white[1000] : palette.black[1000],
            opacity: 1,
          },
          ...sx,
        }}
        error={Boolean(errors[name]) || Boolean(serverError)}
        helperText={
          (errors[name]?.message || serverError) && (
            <>
              <Stack
                component={"span"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ErrorOutlineRoundedIcon
                  sx={{ fontSize: "16px", mr: "10px" }}
                />
                <Box component={"span"}>
                  {errors[name]?.message || serverError}
                </Box>
              </Stack>
            </>
          )
        }
        {...register(name)}
        {...rest}
      />
    </Box>
  );
}
