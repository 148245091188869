import { createSvgIcon } from "@mui/material";
export default function UserIcon(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/Profile">
        <g id="Profile">
          <path
            id="Stroke 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9849 16.1267C8.11731 16.1267 4.81445 16.7115 4.81445 19.0534C4.81445 21.3953 8.09636 22.001 11.9849 22.001C15.8525 22.001 19.1545 21.4153 19.1545 19.0743C19.1545 16.7334 15.8735 16.1267 11.9849 16.1267Z"
            stroke={color}
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9849 12.7869C14.523 12.7869 16.5801 10.7288 16.5801 8.1907C16.5801 5.6526 14.523 3.59546 11.9849 3.59546C9.44679 3.59546 7.3887 5.6526 7.3887 8.1907C7.38013 10.7202 9.42394 12.7783 11.9525 12.7869H11.9849Z"
            stroke={color}
            fill="none"
            strokeWidth="1.42857"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateChatIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
