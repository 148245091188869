import React from "react";
import BackButtonLayout from "../../component/ui/BackButtonLayout";

import { Box, Button, Typography } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import CongratsSvgLight from "../../component/CongratsSvgLight";

import { Link } from "react-router-dom";

export default function WithdrawalConfirm() {
  const { darkMode } = useThemeStore();
  return (
    <BackButtonLayout pageTitle={"Confirmed"}>
      <Box display="grid" sx={{ placeItems: "center" }}>
        <Box>
          <CongratsSvgLight />
        </Box>

        <Box sx={{ mt: { xs: "50px", md: "30px" } }}>
          <Typography
            sx={{
              color: "#148C00", // "success.main",
              textAlign: "center",
              fontSize: "26px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            Congratulations!
          </Typography>
          <Typography
            sx={{
              mt: "15px",
              color: darkMode ? "white.1000" : "neutral.350",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "22px",
              letterSpacing: "-0.408px",
              opacity: darkMode ? 0.7 : 1,
            }}
          >
            Your funds are on route! Continue posting videos to inspire your
            donor community further.
          </Typography>
        </Box>

        <Button
          component={Link}
          to="/"
          variant="contained"
          fullWidth
          sx={{
            mt: { xs: "25px", md: "15px" },
            borderRadius: "10px",
            paddingBlock: "15px",
            backgroundColor: "primary.main",
            textAlign: "center",
            color: "white.650",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "22px",
            letterSpacing: "-0.408px",
            textTransform: "unset",
          }}
        >
          Go Back to the Home Feed
        </Button>
      </Box>
    </BackButtonLayout>
  );
}
