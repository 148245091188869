import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Link as MuiLink,
  alpha,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import Shield from "../../icons/Shield";
import PasswordInput from "../ui/form/PasswordInput";
import EmailIcon from "../../icons/EmailIcon";
import Input from "../ui/form/Input";
import PhoneIcon from "../../icons/PhoneIcon";
import UserIcon from "../../icons/UserIcon";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useSnack } from "../../hooks/store/useSnack";
import { useTheme } from "@emotion/react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useApi from "../../hooks/useApi";
import { useSignup } from "../../hooks/store/useSignup";
import { useNavigate } from "react-router-dom";
import MobileInput from "../ui/form/MobileInput";
import TermsDialog from "../terms&condtions/TermsDialog";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import OrgIcon from "../../icons/OrgIcon";

const SignupForm = ({ openNextForm }) => {
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const { apiCall } = useApi();
  const { apiCall: authApiCall } = useApi(true);
  const {
    accountType,
    setStep1,
    username,
    email,
    mobileNumber,
    password,
    orgName,
    EIN,
  } = useSignup();
  const { typography, palette } = useTheme();
  const navigate = useNavigate();
  let [openTncDialog, setOpenTncDialog] = useState(false);
  let [acceptTnc, setAcceptTnc] = useState(false);
  let [tncError, setTncError] = useState(false);
  // validation
  const phoneRegExp =
    /^(\+\d{1,4}(\s|-)?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
  const usernameRegExp = /^[a-z0-9A-Z_]+$/;
  const einRegExp = /^(\d{2}\d{7})$/;
  let nonProfileSchema = {
    orgName: Yup.string().required("Organization name is required"),
    EIN: Yup.string()
      .required("EIN is required")
      .matches(einRegExp, "Invalid EIN number"),
  };
  let organizationSchema = {
    orgName: Yup.string()
      .required("Organization name is required")
      .min(4, " Organization name must be at least 4 characters"),
  };
  const schema = Yup.object({
    emailAddress: Yup.string()
      .required("Email is required")
      .email("Enter a valid email address")
      .test(
        "is-unique-email",
        "This email is already taken",
        async function (value) {
          try {
            let result = await apiCall({
              url: `v3/users/check/email/${value}`,
              method: "get",
            });
            if (result.data.success) return true;
            else return false;
          } catch (error) {
            return false;
          }
        }
      ),
    username: Yup.string()
      .required("User name is required")
      .matches(
        usernameRegExp,
        "User name should not contain any special characters"
      )
      .min(6)
      .max(24)
      .test(
        "is-unique-username",
        "This user name is already taken",
        async function (value) {
          try {
            let result = await apiCall({
              url: `v3/users/check/${value}`,
              method: "get",
            });
            if (result.data.success) return true;
            else return false;
          } catch (error) {
            return false;
          }
        }
      ),
    mobileNumber: Yup.string()
      .required("Mobile number is required.")
      .matches(phoneRegExp, "Invalid phone number")
      .test(
        "is-unique-mobile",
        "This mobile number is already taken",
        async function (value) {
          try {
            let result = await apiCall({
              url: `v3/users/check/mobile/${value}`,
              method: "get",
            });
            if (result.data.success) return true;
            else return false;
          } catch (error) {
            return false; // Return false in case of an error
          }
        }
      ),
    password: Yup.string()
      .required("Password is required")
      .matches(
        passwordRegExp,
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@$!%*?&)"
      ),
    ...(accountType.value === "non-profit" ? nonProfileSchema : {}),
    ...(accountType.value === "organization" ? organizationSchema : {}),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      username: username,
      emailAddress: email,
      password: password,
      mobileNumber: mobileNumber,
      orgName: orgName,
      EIN: EIN,
    },
  });

  const signupUser = async (values) => {
    try {
      //check user accepts terms and conditions or not
      if (!acceptTnc) {
        return setTncError(true);
      }

      let username = String(values.username).toLowerCase();
      let mobileNumber = values.mobileNumber;
      let email = values.emailAddress;
      let password = values.password;
      let orgName = values?.orgName;
      let EIN = values?.EIN;
      setStep1({ username, mobileNumber, email, password, orgName, EIN });

      // request for otp varification
      await authApiCall({
        url: "v2/accounts/get-otp",
        method: "post",
        data: {
          code: "1",
          mobile: mobileNumber,
          OTP_description: "registration",
        },
      });
      setSnack(
        "Please verify your phone number to complete the registration process. We've sent a verification code to your mobile number.",
        "success"
      );
      openNextForm("Verify");
    } catch (error) {
      setSnack(error.response.data.message, "error");
      navigate("/404");
    }
  };
  return (
    <>
      <Box
        component={"form"}
        sx={{
          minWidth: { md: "571px" },
          paddingInline: { xs: "20px", md: "30px", lg: "60px" },
          paddingTop: { xs: "20px", md: "40px", lg: "50px" },
          paddingBottom: { xs: "175px", md: "40px", lg: "50px" },
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: { xs: "10px", lg: "18px" },
          marginTop: { xs: 0, lg: "50px" },
          boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
        }}
        onSubmit={handleSubmit(signupUser)}
      >
        <Box sx={{ maxWidth: "431px", margin: "0 auto" }}>
          {/* form headings */}
          <Typography
            sx={{
              fontFamily: typography.manrope,
              color: darkMode ? "white.1000" : "black.1000",
              fontSize: { xs: "26px", lg: "36px" },
              fontWeight: { xs: 800, lg: 700 },
              lineHeight: { xs: "40px", lg: "122%" },
              letterSpacing: { xs: "-0.52px", md: "-0.72px" },
            }}
          >
            Welcome to{" "}
            <Typography
              sx={{
                color: "transparent",
                background: palette.gradients["main"],
                backgroundClip: "text",
                fontSize: { xs: "26px", lg: "36px" },
                fontWeight: { xs: 800, lg: 700 },
                lineHeight: { xs: "40px", lg: "122%" },
                letterSpacing: { xs: "-0.52px", md: "-0.72px" },
                fontFamily: typography.manrope,
              }}
              component={"span"}
            >
              PloomSocial
            </Typography>
          </Typography>
          <Typography
            sx={{
              mt: "10px",
              fontFamily: typography.manrope,
              color: darkMode ? "grey.900" : "black.350",
              fontSize: { xs: "18px", lg: "16px" },
              fontWeight: 400,
              lineHeight: "152%",
            }}
          >
            To complete your registration, we require an OTP (One-Time Password)
            sent to authenticate your phone number.
          </Typography>

          <Box sx={{ paddingTop: { lg: "10px" } }}>
            <Input
              name={"username"}
              inputLabel={"User ID"}
              type="text"
              register={register}
              errors={formState.errors}
              placeholder="Enter User Name"
              icon={
                <UserIcon
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
            />

            <MobileInput
              name={"mobileNumber"}
              inputLabel={"Mobile Number"}
              type="number"
              register={register}
              errors={formState.errors}
              placeholder="Enter Mobile Number"
              icon={
                <PhoneIcon
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
            />
            {(accountType.value === "organization" ||
              accountType.value === "non-profit") && (
              <Input
                name={"orgName"}
                inputLabel={"Organization"}
                type="text"
                register={register}
                errors={formState.errors}
                placeholder="Enter a Organization"
                icon={
                  <OrgIcon
                    color={
                      darkMode ? palette.white["1000"] : palette.black["450"]
                    }
                  />
                }
              />
            )}

            {accountType.value === "non-profit" && (
              <Input
                name={"EIN"}
                inputLabel={"EIN"}
                type="text"
                register={register}
                errors={formState.errors}
                placeholder="Enter EIN Number"
                icon={
                  <OrgIcon
                    color={
                      darkMode ? palette.white["1000"] : palette.black["450"]
                    }
                  />
                }
              />
            )}

            <Input
              name={"emailAddress"}
              inputLabel={"Email ID"}
              type="text"
              register={register}
              errors={formState.errors}
              placeholder="Enter Email ID"
              icon={
                <EmailIcon
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
            />

            <PasswordInput
              name="password"
              inputLabel={"Password"}
              register={register}
              errors={formState.errors}
              placeholder="Enter Your Password"
              icon={
                <Shield
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
            />

            <Stack
              sx={{ flexDirection: "row", alignItems: "center", gap: "0px" }}
            >
              <FormControlLabel
                mt="15px"
                name="termAndCondition"
                sx={{ "&.MuiFormControlLabel-root": { mr: 0 } }}
                control={
                  <Checkbox
                    onClick={() => {
                      setAcceptTnc(!acceptTnc);
                      setTncError(acceptTnc);
                    }}
                    // color={"red"}
                    checked={acceptTnc}
                    sx={{ color: darkMode ? "neutral.650" : "black.100" }}
                  />
                }
              />
              <Typography
                sx={{
                  fontFamily: typography.manrope,
                  color: darkMode ? "grey.900" : "neutral.350",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "146%",
                }}
              >
                I accept{" "}
                <Typography
                  component={"span"}
                  // to="/terms-and-condition"
                  // target="_black"
                  onClick={() => setOpenTncDialog(true)}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: darkMode ? "white.1000" : "primary.main",
                    fontFamily: typography.manrope,
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "146%",
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Typography>
            </Stack>
            {tncError && (
              <Stack
                component={"span"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "12px",
                  color: "error.main",
                }}
              >
                <ErrorOutlineRoundedIcon
                  sx={{ fontSize: "16px", mr: "10px" }}
                />
                <Box component={"span"}>Please accept terms and conditions</Box>
              </Stack>
            )}
          </Box>

          <ButtonWithLoading
            color="primary"
            disabled={
              !acceptTnc || !formState.isValid || formState.isSubmitting
            }
            isLoading={formState.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              fontFamily: typography.manrope,
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "152%",
              padding: "14px 12px",
              marginTop: { xs: "25px", lg: "30px" },
              borderRadius: "10px",
              textTransform: "capitalize",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Sign Up
          </ButtonWithLoading>
        </Box>

        {/* oauth and other form links */}
        <Box
          sx={{
            marginTop: { xs: "30px", lg: "40px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MuiLink
            component={Link}
            to="/signin"
            underline="hover"
            sx={{
              fontFamily: typography.manrope,
              color: darkMode ? "grey.900" : "white.200",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: "500",
              lineHeight: "146%",
            }}
          >
            {"Already have an account?  "}
            <Typography
              component={"span"}
              sx={{
                color: darkMode ? "white.1000" : "primary.main",
                fontFamily: typography.manrope,
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: "700",
              }}
            >
              {" Sign In"}
            </Typography>
          </MuiLink>
        </Box>

        {/* <Box display={"flex"} justifyContent={"center"}>
                <ButtonWithLoading
                  // color="primary"
                  // disabled={formState.isSubmitting}
                  disabled
                  // isLoading={formState.isSubmitting}
                  startIcon={<GoogleIcon></GoogleIcon>}
                  fullWidth
                  fontSize="13px"
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: "30px",
                    color: "black.100",
                    paddingBlock: "16px",
                    marginTop: "38px",
                    borderRadius: "10px",
                    backgroundColor: "white.100",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    maxWidth: "90%",
                  }}
                >
                  Sign up with google
                </ButtonWithLoading>
              </Box> */}

        <TermsDialog
          openTncDialog={openTncDialog}
          setOpenTncDialog={setOpenTncDialog}
          setAcceptTnc={() => {
            setAcceptTnc(true);
            setTncError(false);
          }}
        />
      </Box>
    </>
  );
};

export default SignupForm;
