import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useApi from "../../hooks/useApi";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import MobileInput from "../ui/form/MobileInput";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import TermsDialog from "../terms&condtions/TermsDialog";

const AddMobileNumber = ({
  mobile,
  setOpenForm,
  setMobileNumber,
  verifyUserIdentity,
}) => {
  const { apiCall: API } = useApi();
  const { darkMode } = useThemeStore();
  const { typography } = useTheme();
  let [openTncDialog, setOpenTncDialog] = useState(false);
  let [acceptTnc, setAcceptTnc] = useState(false);
  let [tncError, setTncError] = useState(false);

  // form handling
  const phoneRegExp =
    /^(\+\d{1,4}(\s|-)?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const schema = Yup.object({
    mobileNumber: Yup.string()
      .required("Mobile number is required.")
      .matches(phoneRegExp, "Invalid phone number")
      .test(
        "is-unique-mobile",
        "This mobile number is already taken",
        async function (value) {
          try {
            let result = await API({
              url: `v3/users/check/mobile/${value}`,
              method: "get",
            });
            if (result.data.success) return true;
            else return false;
          } catch (error) {
            return false; // Return false in case of an error
          }
        }
      ),
  });
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: { mobileNumber: mobile },
  });

  function addNewMobileNumber(value) {
    //check user accepts terms and conditions or not
    if (!acceptTnc) {
      return setTncError(true);
    }

    setMobileNumber(value.mobileNumber);
    setOpenForm("otpVerify");
    return;
  }
  return (
    <>
      <Box
        component={"form"}
        // width={{ xs: "unset", sm: "540px" }}
        onSubmit={handleSubmit(addNewMobileNumber)}
        sx={{
          padding: { xs: "20px", md: "50px" },
        }}
      >
        <Typography
          sx={{
            color: darkMode ? "white.1000" : "black.1000",
            fontFamily: "Manrope",
            fontSize: { xs: "26px", md: "36px" },
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: { xs: "40px", md: "43.92px" },
            letterSpacing: "-0.72px",
          }}
        >
          Verify Your Mobile Number
        </Typography>
        {/* form inputs */}
        <MobileInput
          name="mobileNumber"
          register={register}
          errors={formState.errors}
          placeholder="Enter your new Phone number"
          inputLabel={"Phone Number"}
        />

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "0px" }}>
          <FormControlLabel
            mt="15px"
            name="termAndCondition"
            sx={{ "&.MuiFormControlLabel-root": { mr: 0 } }}
            control={
              <Checkbox
                onClick={() => {
                  setAcceptTnc(!acceptTnc);
                  setTncError(acceptTnc);
                }}
                // color={"red"}
                checked={acceptTnc}
                sx={{ color: darkMode ? "neutral.650" : "black.100" }}
              />
            }
          />
          <Typography
            sx={{
              fontFamily: typography.manrope,
              color: darkMode ? "grey.900" : "neutral.350",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "146%",
            }}
          >
            I accept{" "}
            <Typography
              component={"span"}
              // to="/terms-and-condition"
              // target="_black"
              onClick={() => setOpenTncDialog(true)}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                color: darkMode ? "white.1000" : "primary.main",
                fontFamily: typography.manrope,
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "146%",
              }}
            >
              Terms & Conditions
            </Typography>
          </Typography>
        </Stack>
        {tncError && (
          <Stack
            component={"span"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "12px",
              color: "error.main",
            }}
          >
            <ErrorOutlineRoundedIcon sx={{ fontSize: "16px", mr: "10px" }} />
            <Box component={"span"}>Please accept terms and conditions</Box>
          </Stack>
        )}

        <ButtonWithLoading
          color="primary"
          disabled={!acceptTnc || !formState.isValid || formState.isSubmitting}
          isLoading={formState.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            paddingBlock: "14px",
            marginTop: "38px",
            textTransform: "capitalize",
          }}
        >
          Verify
        </ButtonWithLoading>

        <TermsDialog
          openTncDialog={openTncDialog}
          setOpenTncDialog={setOpenTncDialog}
          setAcceptTnc={() => {
            setAcceptTnc(true);
            setTncError(false);
          }}
        />
      </Box>
    </>
  );
};

export default AddMobileNumber;
