import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useInfinite from "../../hooks/useInfinite";
import { Box, List, Typography } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function CustomInfinite({
  url,
  children,
  Skeleton,
  listSx = {},
  ListItem,
  method = "get",
  dataKey = "results",
  renderLoaderInList = false,
  data = {},
  reRender,
}) {
  const {
    results,
    isLoading,
    page,
    totalPages,
    setPage,
    setHasMore,
    hasMore,
    setResults,
  } = useInfinite({
    url,
    method,
    dataKey,
    data,
    reRender,
  });
  const { darkMode } = useThemeStore();
  return (
    <InfiniteScroll
      next={() => {
        if (page < totalPages) {
          setPage((prev) => prev + 1);
        } else {
          setHasMore(false);
        }
      }}
      hasMore={hasMore}
      dataLength={results.length}
    >
      {!isLoading && results.length === 0 && (
        <Typography
          textAlign={"center"}
          color={darkMode ? "white.1000" : "primary.main"}
          fontSize={{
            xs: "14px",
            sm: "14px",
            md: "18px",
          }}
          mt={2}
        >
          {children}
        </Typography>
      )}
      <List sx={listSx}>
        {results.map((result, index) => (
          <ListItem item={result} setResults={setResults} key={index} />
        ))}
        {isLoading && renderLoaderInList && <Skeleton></Skeleton>}
      </List>
      {isLoading && !renderLoaderInList && (
        <Box sx={listSx}>
          <>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
          </>
        </Box>
      )}
    </InfiniteScroll>
  );
}
