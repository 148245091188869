import { createSvgIcon } from "@mui/material";

export default function HeartWhiteIcon(props) {
  let color = props.color;
  const CreateHeartWhiteIcon = createSvgIcon(
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87162 11.5984C1.79862 8.24838 3.05262 4.41938 6.56962 3.28638C8.41962 2.68938 10.4616 3.04138 11.9996 4.19838C13.4546 3.07338 15.5716 2.69338 17.4196 3.28638C20.9366 4.41938 22.1986 8.24838 21.1266 11.5984C19.4566 16.9084 11.9996 20.9984 11.9996 20.9984C11.9996 20.9984 4.59762 16.9704 2.87162 11.5984Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15.9998 6.70007C17.0698 7.04607 17.8258 8.00107 17.9168 9.12207"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
  return (
    <CreateHeartWhiteIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
