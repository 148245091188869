import React from "react";
import FeedToggleMenu from "../ui/FeedToggleMenu";
import { useAuth } from "../../hooks/store/useAuth";
import { useSnack } from "../../hooks/store/useSnack";
import { useAllModal } from "../../hooks/store/useAllModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { APIS } from "../../api/lists";

export default function FeedMoreMenu({ activeVideo, data }) {
  const { setShowReportDrawer } = useAllModal();

  const { isLoggedIn } = useAuth();
  const { setSnack } = useSnack();
  const navigate = useNavigate();
  const downloadHandler = () => {
    const filename = activeVideo.downloadLink.split("/").pop();
    if (!activeVideo.videoLink) {
      return setSnack("download link is not available", "warning");
    }
    const a = document.createElement("a");
    a.href = activeVideo.downloadLink;
    a.style = "display: none";
    a.download = filename;
    a.click();
  };
  const { apiCall } = useApi();
  const saveHandler = async (id) => {
    try {
      const res = await apiCall({
        url: APIS.BOOKMARK.ADD,
        data: { begId: id },
        method: "post",
      });
      if (res.data.success === true) {
        setSnack(res.data.message);
      }
    } catch (error) {
      let message = "Something went wrong! Please try again later.";
      if (error?.response.data.message) {
        message = error.response.data.message;
      }
      setSnack(message, "error");
    }
  };
  const options = [
    {
      name: "Report",
      onClick: () => {
        setShowReportDrawer(true, {
          itemType: "beg",
          id: data.id,
          title: data.title,
          description: data.description,
        });
      },
      disabled: !isLoggedIn,
    },
    {
      name: "Download",
      onClick: downloadHandler,
    },
    {
      name: "Save",
      onClick: saveHandler.bind(null, data.id),
      disabled: !isLoggedIn,
    },
    {
      name: "Donation history",
      onClick: () => {
        navigate("/feed/" + data.id + "/donors");
      },
    },
  ];
  return (
    <FeedToggleMenu
      icon
      options={options}
      sx={{
        backgroundColor: "black.350",
        margin: "0px",
        width: { xs: "30px", sm: "40px" },
        height: { xs: "30px", sm: "40px" },
        "&": {
          borderRadius: "50%",
        },
        "&:hover": {
          backgroundColor: "black.350",
        },
      }}
    >
      <MoreHorizIcon />
    </FeedToggleMenu>
  );
}
