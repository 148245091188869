import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useAllModal } from "../../hooks/store/useAllModal";

export default function GlobalLoadingDrawer() {
  const theme = useTheme();
  const { darkMode } = useThemeStore();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { globalLoader } = useAllModal();
  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={globalLoader}
        aria-labelledby="responsive-dialog-title"
        sx={{
          ".MuiDialog-paper": {
            background: "unset",
            boxShadow: "none",
            m: "15px",
          },
        }}
      >
        <DialogContent>
          <CircularProgress
            sx={{ color: darkMode ? "white.1000" : "primary.main" }}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
