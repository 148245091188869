import { Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import AmountProgress from "./AmountProgress";
import MediaType from "./MediaType";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MediaInfoLayer from "../mediaInfoDialog/MediaInfoLayer";

const Slider = ({ videos, begDetails, mediaPoint }) => {
  const [activeVideo, setActiveVideo] = useState(videos[0]);

  //  function for swiper onchange event
  const handleSlideChange = useCallback(
    (swiper) => {
      const activeIndex = swiper.activeIndex;
      setActiveVideo(videos[activeIndex]);
      sessionStorage.setItem("activeVideoId", videos[activeIndex]._id);
    },
    [videos]
  );
  return (
    <Box
      component={Swiper}
      spaceBetween={10}
      slidesPerView={1}
      onSlideChange={handleSlideChange}
      navigation
      pagination={{ clickable: true }}
      modules={[Pagination]}
      sx={{
        width: "100%",
        height: { xs: "260px", ss: "300px", md: "412px" },
        position: "relative",
        borderRadius: "15px",
        "& .swiper-pagination": {
          marginBottom: {
            xs: begDetails.begType === 1 ? "65px" : "45px",
            md: begDetails.begType === 1 ? "65px" : "50px",
          },
        },
      }}
    >
      {/* media type indicator image/video icon */}
      <MediaType videoType={activeVideo.type}></MediaType>

      {/* raised amount and timeline details  */}
      <AmountProgress
        daysRemaining={begDetails.daysRemaining}
        amountRaised={begDetails.amountRaised}
        donorCount={begDetails.donors}
        goalAmount={begDetails.goalAmount}
        begType={begDetails.begType}
        endedDate={begDetails.endedDate}
      ></AmountProgress>
      {videos.map((video, index) => (
        <SwiperSlide key={index}>
          <Box
            sx={{
              position: "relative",
              display: "block",
              width: "100%",
              height: "100%",
              "& span": {
                overflow: "hidden",
                width: "100%",
                height: "100%",
              },
            }}
          >
            <Box
              component={LazyLoadImage}
              src={video.thumbLink}
              // placeholderSrc={wrapper}
              effect="blur"
              alt="Paella dish"
              threshold={200}
              width={"100%"}
              sx={{
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
              }}
              loading="lazy"
            />

            <MediaInfoLayer
              twoHate={video.twoHatflagged}
              processingStatus={video.processingStatus}
              adminFlag={video.isDelete}
              modifyHeight={false}
            ></MediaInfoLayer>
          </Box>
        </SwiperSlide>
      ))}
    </Box>
  );
};

export default Slider;
