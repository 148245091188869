import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Stack } from "@mui/material";
import { useAllModal } from "../../hooks/store/useAllModal";

export default function SettingDrawer() {
  const { showSettingDrawer, setShowSettingDrawer, setShowBlockListDrawer } =
    useAllModal();

  return (
    <Drawer
      anchor={"right"}
      open={showSettingDrawer}
      onClose={() => setShowSettingDrawer(false)}
    >
      <Box
        height="100%"
        maxHeight={"100%"}
        display={"grid"}
        gridTemplateRows={"1fr auto"}
        minWidth={{ xs: "280px", md: "360px" }}
        role="presentation"
        onClick={() => {
          setShowSettingDrawer(false);
        }}
      >
        <List sx={{ overflowY: "auto" }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Payment & Withdrawls" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="My Chip-ins & Karma Points" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Leader Board" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              setShowBlockListDrawer(true);
            }}
          >
            <ListItemButton>
              <ListItemText primary="Block list" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Help" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Divider /> */}
        <Stack sx={{ width: "100%", padding: "20px", gap: "16px" }}>
          <Button
            variant="outlined"
            sx={{ borderRadius: "16px", textTransform: "unset" }}
          >
            Contact use
          </Button>
          <Button
            variant="outlined"
            sx={{ borderRadius: "16px", textTransform: "unset" }}
          >
            Leave Feedback
          </Button>
          <Button
            variant="contained"
            sx={{ borderRadius: "16px", textTransform: "unset" }}
          >
            Log out
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}
