import {
  Box,
  IconButton,
  SwipeableDrawer,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAllModal } from "../../hooks/store/useAllModal";
import DrawerBg from "../../icons/DrawerBg";
import useThemeStore from "../../hooks/store/useThemeStore";
import CloseIcon from "../../icons/CloseIcon";
import UserVerificationPending from "./UserVerificationPending";

export default function CommonDrawer() {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const { showCommonDrawer, setShowCommonDrawer } = useAllModal();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  function HandleClick(e) {
    e.stopPropagation();
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={showCommonDrawer}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      onOpen={() => {}}
      onClose={() => {}}
      onClick={() => {
        setShowCommonDrawer(false);
      }}
      sx={{
        zIndex: 4000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingInline: "15px",
        backgroundColor: alpha(palette.black["1000"], 0.5),
        "& *.css-18tmwsn": {
          backgroundColor: "transparent",
          maxWidth: "550px",
          margin: "0 auto",
        },
        "& .css-4w5voh-MuiPaper-root-MuiDrawer-paper": {
          background: "transparent",
          maxWidth: "550px",
          margin: "0 auto",
        },
        "& .MuiPaper-root": {
          position: "relative",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "574px",
          width: "100%",
          minHeight: "533px",
          marginInline: "auto",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
          overflowX: "hidden",
        }}
        onClick={HandleClick}
      >
        {/* background */}
        <Box sx={{ position: "absolute", top: "35px", left: "-70px" }}>
          <DrawerBg
            color={darkMode ? palette.grey["550"] : palette.primary["light50"]}
            width={"220px"}
            height={"253px"}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "29px",
            right: "-70px",
            transform: "rotate(180deg)",
          }}
        >
          <DrawerBg
            color={darkMode ? palette.grey["550"] : palette.primary["light50"]}
            width={"220px"}
            height={"253px"}
          />
        </Box>

        {/* close button */}
        <IconButton
          aria-label="delete"
          size="small"
          sx={{
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
          onClick={() => setShowCommonDrawer(false)}
        >
          <CloseIcon
            color={
              darkMode
                ? alpha(palette.white["1000"], 0.7)
                : palette.black["1000"]
            }
          />
        </IconButton>

        {/* data component */}
        <UserVerificationPending />
      </Box>
    </SwipeableDrawer>
  );
}
