import React, { useState } from "react";
import ProfileAvatar from "../../user/ProfileAvatar";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Collapse,
  useTheme,
} from "@mui/material";

import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/store/useAuth";
import useThemeStore from "../../../hooks/store/useThemeStore";
import ThumbsUpIcon from "../../../icons/ThumbsUpIcon";
import UpArrowIcon from "../../../icons/UpArrowIcon";
import SubComment from "./SubComment";
import useApi from "../../../hooks/useApi";
import { APIS } from "../../../api/lists";
import CommentToggleMenu from "./CommentToggleMenu";
import VerifiedIcon from "../../../icons/VerifiedIcon";
import { useAllModal } from "../../../hooks/store/useAllModal";
import { useSnack } from "../../../hooks/store/useSnack";
import PushPinIcon from "@mui/icons-material/PushPin";

export default function CommentCard({
  comment,
  feedId,
  disableMainCommentCount = false,
  begOwnerId,
  pinned,
}) {
  moment.locale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "few seconds ago",
      ss: "%ss",
      m: "a minute ago",
      mm: "%d minute ago",
      h: "an hour ago",
      hh: "%d hours ago",
      d: "a Day",
      dd: "%dd",
      M: "a month ago",
      MM: "%d month ago",
      y: "a year ago",
      yy: "%d years ago",
    },
  });

  const renderComment = (comment) => {
    // Regular expression to match URLs in the comment text
    const urlRegex =
      /((?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/\S*)?)/g;
    const commentWithLinks = comment.replace(urlRegex, (url) => {
      // Add http:// if the URL doesn't have http:// or https://
      if (!url.match(/^https?:\/\//i)) {
        return `<a href="http://${url}" target="_blank">${url}</a>`;
      }
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return (
      <Typography
        sx={{
          mt: { xs: "4px", sm: "6px" },
          mb: "10px",
          // textAlign: "justify",
          color: darkMode ? "white.800" : "black.350",
          opacity: { xs: darkMode ? 1 : 0.6, md: 0.6 },
          fontFamily: "Poppins",
          fontSize: { xs: "12px", md: "13px" },
          fontWeight: 400,
          lineHeight: { md: "19px" },
          overflowWrap: "anywhere",
          "&>a": {
            textDecoration: "none",
            color: darkMode ? "white.1000" : "primary.main",
            fontWeight: 600,
            opacity: 1,
          },
        }}
        dangerouslySetInnerHTML={{ __html: commentWithLinks }}
      />
    );
  };

  const { userId: loggedInUserId, isLoggedIn } = useAuth();
  const { darkMode } = useThemeStore();
  const { setSnack } = useSnack();
  const [showSubComments, setShowSubComments] = useState(false);
  const [showSubCommentForm, setShowSubCommentForm] = useState(true);
  const [currentUserLike, setCurrentUserLike] = useState(comment.loginUserLike);
  const [isPinned, setIsPinned] = useState(pinned);
  const { apiCall, isLoading: likeLoading } = useApi();
  const { palette } = useTheme();

  const [repliesCount, setRepliesCount] = useState(comment.replies || 0);
  const [likeCount, setLikeCount] = useState(comment.commentLikeCount || 0);
  const { setOpenKycDialog } = useAllModal();
  const profileLink = (userId) =>
    loggedInUserId === userId ? "/profile" : "/profile/" + userId;
  const handleShowSubComments = () => {
    if (!showSubComments) {
      setShowSubComments(true);
      setShowSubCommentForm(true);
      return;
    }
    setShowSubCommentForm(false);
    setShowSubComments(false);
  };

  const likeComment = async (itemId, itemType) => {
    try {
      const res = await apiCall({
        url: APIS.LIKE.CREATE,
        method: "post",
        data: {
          itemType,
          itemId,
          userId: loggedInUserId,
          begId: feedId,
        },
      });
      setLikeCount(res?.data?.data?.likeCount || 0);
      setCurrentUserLike(res?.data.data?.like);
    } catch (error) {
      console.log("error in like the comment");
    }
  };
  return (
    <Stack mt={{ xs: "20px", sm: "30px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: { xs: "10px", sm: "15px" },
        }}
      >
        <Box
          component={comment.userId ? Link : undefined}
          to={profileLink(comment.userId)}
        >
          <ProfileAvatar
            sx={{
              backgroundColor: "primary.main",
              color: "white.1000",
              width: { xs: "32px", sm: "40px" },
              height: { xs: "32px", sm: "40px" },
            }}
            src={comment.userId ? comment?.user?.profileImage : ""}
            alt={comment?.user?.username}
          />
        </Box>
        <Box flexGrow={1} overflow={"hidden"}>
          <Stack
            display={"flex"}
            direction={"row"}
            alignItems={"center"}
            overflow={"hidden"}
            spacing={1}
          >
            <Stack
              flexGrow={1}
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              overflow={"hidden"}
            >
              <Stack sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
                <Typography
                  sx={{
                    color: darkMode ? "white.1000" : "grey.550",
                    fontSize: { xs: "12px", sm: "14px" },
                    fontWeight: 600,
                    lineHeight: "16px",
                    textTransform: "capitalize",
                    textDecoration: "none",
                    textWrap: "wrap",
                  }}
                  component={comment.userId ? Link : undefined}
                  to={profileLink(comment.userId)}
                >
                  {comment.user.orgName
                    ? comment.user.orgName
                    : comment?.user?.firstName + " " + comment?.user?.lastName}
                </Typography>
                {comment?.user?.isKyc === 1 && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "7px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenKycDialog(true, comment?.user?.verifiedAt);
                    }}
                  >
                    <VerifiedIcon id={"verifiedIcon122"}></VerifiedIcon>
                  </Stack>
                )}
              </Stack>
              <Typography
                sx={{
                  color: darkMode ? "white.1000" : "grey.850",
                  fontSize: { xs: "12px", sm: "14px" },
                  fontWeight: 500,
                  lineHeight: "16px",
                  opacity: 0.5,
                  whiteSpace: "nowrap",
                }}
              >
                {moment(comment.createdAt).fromNow()}{" "}
              </Typography>
              {isPinned && (
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "6px",
                    alignItems: "center",
                  }}
                >
                  <PushPinIcon
                    sx={{
                      transform: "rotate(40deg)",
                      width: "14px",
                      color: darkMode ? "white.1000" : "grey.850",
                      opacity: darkMode ? 0.8 : 1,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: darkMode ? "white.1000" : "grey.850",
                      opacity: darkMode ? 0.8 : 1,
                    }}
                  >
                    Pinned
                  </Typography>
                </Stack>
              )}
            </Stack>
            {isLoggedIn && (
              <CommentToggleMenu
                feedId={feedId}
                commentId={comment._id}
                begOwnerId={begOwnerId}
                isPinned={isPinned}
                setIsPinned={setIsPinned}
              ></CommentToggleMenu>
            )}
          </Stack>
          {renderComment(comment.textDescription)}
          <Box display={"flex"}>
            <Box>
              <IconButton
                disabled={likeLoading}
                sx={{
                  "&.MuiIconButton-root.Mui-disabled": {
                    color: darkMode ? "white.1000" : "black.1000",
                    opacity: 0.4,
                  },
                  color: darkMode ? "white.1000" : "black.1000",
                  fontSize: { xs: "12px", sm: "16px" },
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  if (isLoggedIn) {
                    return likeComment(comment._id, "comment");
                  } else {
                    return setSnack(
                      "Please login to like this comment",
                      "warning"
                    );
                  }
                }}
              >
                <ThumbsUpIcon
                  color={
                    currentUserLike
                      ? "#e84251"
                      : darkMode
                      ? palette.white[1000]
                      : palette.black[1000]
                  }
                  mr={{ xs: "8px", sm: "12px" }}
                  width={{ xs: "14px", sm: "20px" }}
                  height={{ xs: "14px", sm: "20px" }}
                />{" "}
                {likeCount || 0}
              </IconButton>
            </Box>
            <Box ml={{ sm: "12px" }}>
              <IconButton
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  fontSize: { xs: "12px", sm: "16px" },
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleShowSubComments}
              >
                <UpArrowIcon
                  color={darkMode ? palette.white[1000] : palette.black[1000]}
                  mr={{ xs: "8px", sm: "12px" }}
                  width={{ xs: "14px", sm: "20px" }}
                  height={{ xs: "14px", sm: "20px" }}
                  rotate={showSubComments ? "180deg" : "0deg"}
                />{" "}
                {repliesCount || 0} Replies
              </IconButton>
            </Box>

            {isLoggedIn && (
              <Box ml={{ sm: "12px" }}>
                <IconButton
                  sx={{
                    color: "black.1000",
                    fontSize: { xs: "12px", sm: "16px" },
                    backgroundImage: palette.gradients.main,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    MozBackgroundClip: "text",
                    MozTextFillColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={() => {
                    if (showSubComments && !showSubCommentForm) {
                      setShowSubCommentForm(true);
                    } else if (showSubCommentForm && showSubComments) {
                      setShowSubCommentForm(false);
                      setShowSubComments(false);
                    } else if (!showSubComments || !showSubCommentForm) {
                      setShowSubComments(true);
                      setShowSubCommentForm(true);
                    }
                  }}
                >
                  Reply
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Collapse
        in={showSubComments}
        sx={{ paddingLeft: { xs: "20px", sm: "50px" } }}
      >
        <SubComment
          showSubComments={showSubComments}
          parentId={comment._id}
          feedId={feedId}
          showSubCommentForm={showSubCommentForm}
          setShowSubCommentForm={setShowSubCommentForm}
          setRepliesCount={setRepliesCount}
          repliesCount={repliesCount}
          disableMainCommentCount={disableMainCommentCount}
        />
      </Collapse>
    </Stack>
  );
}
