import { Box, Button, Divider, Stack, Typography, alpha } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";

const DeleteDialog = ({ handleClose, deleteFile, isLoading }) => {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <>
      <Box
        sx={{
          minWidth: { md: "534px" },
          width: "100%",
          display: "grid",
          placeItems: "center",
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "20px", md: "40px 40px 0 40px" },
          }}
        >
          <Stack
            sx={{
              pb: "15px",
              width: "100%",
              borderBottom: "1px solid",
              borderColor: darkMode
                ? "black.200"
                : alpha(palette.grey[100], 0.4),
            }}
          >
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                letterSpacing: "-0.408px",
              }}
            >
              Delete
            </Typography>
          </Stack>
          <Typography
            sx={{
              mt: "20px",
              pb: { xs: "40px", md: "60px" },
              color: darkMode ? "grey.900" : "black.1000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: { xs: "12px", md: "14px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              maxWidth: { xs: "271px", md: "310px" },
              mx: "auto",
            }}
          >
            Are you sure you want to delete this video/image?
          </Typography>
        </Box>

        <Divider
          light
          sx={{
            width: { xs: "100%", md: "calc(100% - 80px)" },
            background: darkMode ? "black.200" : alpha(palette.grey[100], 0.4),
          }}
        />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: { md: "10px 0 0px 0" },
          }}
        >
          <Button
            variant="text"
            fullWidth
            sx={{
              paddingBlock: "15px",
              maxWidth: "unset",
              color: darkMode ? "white.1000" : "black.1000",
              "&:hover": { background: "none" },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: darkMode
                ? "black.200"
                : alpha(palette.grey[100], 0.4),
              height: "40px",
              transform: "translateY(20%)",
            }}
          />
          <LoadingButton
            loading={isLoading}
            variant="text"
            fullWidth
            sx={{
              paddingBlock: "15px",
              color: "error.main",
              "&:hover": { background: "none" },
              "&.MuiLoadingButton-root.Mui-disabled": {
                color: darkMode ? "black.100" : "black.100",
              },
            }}
            endIcon={<></>}
            loadingPosition="end"
            onClick={() => {
              deleteFile();
            }}
          >
            Delete
          </LoadingButton>
        </Stack>
      </Box>
    </>
  );
};

export default DeleteDialog;
