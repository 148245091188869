import { createSvgIcon } from "@mui/material";
export default function PhoneIcon(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <g id="Iconly/Light/Call">
        <g id="Call">
          <path
            fill="none"
            id="Stroke 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5317 12.6133C15.5208 16.6012 16.4258 11.9876 18.9656 14.5257C21.4143 16.9736 22.8216 17.4641 19.7192 20.5656C19.3306 20.8779 16.8616 24.6351 8.1846 15.9605C-0.493478 7.28487 3.26158 4.81331 3.57397 4.42482C6.68387 1.31471 7.16586 2.73025 9.61449 5.1782C12.1544 7.71736 7.54266 8.62528 11.5317 12.6133Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </>
  );
  return (
    <CreateChatIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
