import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NavIcon from "../../icons/NavIcon";
import useThemeStore from "../../hooks/store/useThemeStore";
import { useAuth } from "../../hooks/store/useAuth";
import NoteIcon from "../../icons/navMenu/NoteIcon";
import SecurityIcon from "../../icons/navMenu/SecurtityIcon";
import InfoIcon from "../../icons/navMenu/InfoIcon";
import GearIcon from "../../icons/navMenu/GearIcon";
import MessageIcon from "../../icons/navMenu/MessageIcon";
import EditIcon from "../../icons/navMenu/EditIcon";
import { useTheme } from "@emotion/react";
import { theme } from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import AppleIcon from "../../icons/AppleIcon";
import { useAllModal } from "../../hooks/store/useAllModal";
import { useSettings } from "../../hooks/store/useSettings";
import ThemeSwitch from "../../icons/navMenu/ThemeSwitch";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import HowToIconNav from "../../icons/HowToIconNav";

export default function NavbarMenu({ logoutUser }) {
  const { darkMode, toggleDarkMode } = useThemeStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const { palette } = useTheme();
  const open = Boolean(anchorEl);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const { setShowIdentityDrawer } = useAllModal();
  const { isLoggedIn } = useAuth();
  const { settings } = useSettings();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const resizeHandler = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
      setShowIdentityDrawer(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user } = useAuth();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuNames = [
    {
      name: "Terms & Conditions",
      Icon: NoteIcon,
      url:
        settings.termsAndCondition ||
        "https://www.ploommedia.com/terms-conditions/",
      newTab: true,
      // disable: true,
    },
    {
      name: "Privacy Policy",
      Icon: SecurityIcon,
      url: settings.privacyLink || "https://www.ploommedia.com/privacy-policy/",
      newTab: true,
      // disable: true,
    },
    {
      name: "About",
      Icon: InfoIcon,
      url: settings.aboutLink || "https://www.ploommedia.com/about/",
      newTab: true,
    },
    {
      name: "Settings",
      Icon: GearIcon,
      url: "/settings",
      hide: !isLoggedIn,
    },
    {
      name: "Contact Us",
      Icon: MessageIcon,
      url: "mailto:" + (settings.supportEmail || "support@ploom.media"),
      // disable: true,
    },
    {
      name: "Leave Feedback",
      Icon: EditIcon,
      url: "mailto:" + (settings.supportEmail || "support@ploom.media"),
      hide: !isLoggedIn,
      // newTab: true,
      // disable: true,
    },
    {
      name: "How To?",
      Icon: HowToIconNav,
      url: "/coming-soon",
      hide: isSmallScreen ? false : true,
      // newTab: true,
      // disable: true,
    },
  ];
  const menuStyles = isSmallScreen
    ? {
        inset: "0! important",
        maxHeight: "none",
        maxWidth: "320px",
        borderRadius: "0px !important",
      }
    : {
        maxHeight: "calc(100% - 150px)",
        top: "calc(85px + 30px) !important",
      };
  const menuBackdropStyles = isSmallScreen
    ? {
        background: darkMode
          ? alpha(palette.black[1000], 0.7)
          : alpha(palette.white[1000], 0.4),
        backdropFilter: "blur(" + (darkMode ? 1.2 : 4) + "px)",
      }
    : {};
  const extraProps = isSmallScreen
    ? {
        TransitionComponent: Slide,
        TransitionProps: { direction: "right" },
      }
    : {};
  return (
    <>
      <IconButton
        aria-label="navicon"
        sx={{ padding: "5px 0", ml: { md: "30px" } }}
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <NavIcon color={darkMode ? "white.1000" : "bgDark.third"}></NavIcon>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        sx={menuBackdropStyles}
        onClose={handleClose}
        {...extraProps}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            ...menuStyles,
            height: screenHeight >= 849 ? "auto" : "100%",
            p: { xs: "15px 0px", md: "30px 0px" },
            backgroundColor: darkMode ? "black.1000" : "white.1000",
            boxShadow:
              "0 0 60px " + alpha(palette.black["1000"], darkMode ? 0.2 : 0.06),
            "&:before": {
              content: '""',
              display: { xs: "none", md: "block" },
              position: "absolute",
              top: 0,
              right: { md: 50, lg: 92 },
              borderBottom: "15px solid",
              borderBottomColor: darkMode ? "black.1000" : "white.1000",
              borderRight: "15px solid transparent",
              borderLeft: "15px solid transparent",
              transform: "translateY(-100%)",
            },

            "& ul": {
              p: "0px",
              display: "flex",
              flexDirection: "column",
              height: { xs: window.innerHeight, md: "100%" },
              // overflow: "auto",
              // minWidth: { sm: "300px" },
            },
          },
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          mb={"16px"}
          sx={{ position: "sticky", top: "0px", paddingInline: "30px" }}
        >
          <Typography
            fontSize={"20px"}
            color={darkMode ? "white.1000" : "black.1000"}
            flexGrow={1}
            fontWeight={500}
          >
            Menu
          </Typography>
          {/* close Icon */}
          {isSmallScreen && (
            <Button
              sx={{
                color: darkMode ? "white.350" : "black.300",
                minWidth: "auto",
                borderRadius: "10px",
                padding: "4px",
                border: "2px solid",
                borderColor: darkMode ? "white.350" : "black.300",
                flexShrink: "0",
              }}
              onClick={handleClose}
            >
              <CloseIcon sx={{ fontSize: "16px" }}></CloseIcon>
            </Button>
          )}
        </Box>

        <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
          {isLoggedIn && (
            <Box
              sx={{
                p: "0 30px",
                mb: "15px",
                backgroundColor: darkMode ? "black.1000" : "white.1000",
                zIndex: 2,
              }}
            >
              <Divider
                sx={{
                  borderColor: darkMode ? "black.250" : "bgWhite.secondary",
                }}
              />
              <Box
                component={Link}
                to="/profile"
                display={"flex"}
                my={"16px"}
                sx={{ gap: "15px", textDecoration: "none" }}
              >
                <Avatar
                  sx={{
                    borderRadius: "10px",
                    width: "61px !important",
                    height: "59px !important",
                    color: "white.1000",
                    backgroundColor: "primary.main",
                    ml: "0 !important",
                    mr: "0 !important",
                  }}
                  src={user && user?.profileImage}
                />
                <Box
                  sx={{
                    overflow: "hidden",
                    width: {
                      xs: "140px",
                      ssm: "140px",
                      md: "160px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "14px",
                      marginTop: "5px",
                      fontWeight: 700,
                      color: darkMode ? "white.1000" : "black.1000",
                      fontStyle: "normal",
                      lineHeight: 1.5,
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {user.orgName
                      ? user.orgName
                      : user.firstName + " " + user.lastName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      textAlign: "left",
                      color: darkMode ? "grey.450" : "black.1000",
                      lineHeight: 1.5,
                      fontWeight: 500,
                      opacity: 0.3,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    @{user && user.username}
                  </Typography>
                </Box>
              </Box>
              <Divider
                mb={"16px"}
                sx={{
                  borderColor: darkMode ? "black.250" : "bgWhite.secondary",
                }}
              />
            </Box>
          )}

          <Box>
            {menuNames.map(
              ({
                name,
                Icon,
                url,
                disable = false,
                newTab = false,
                hide = false,
              }) => (
                <MenuItem
                  onClick={handleClose}
                  key={name}
                  sx={{ p: "6px 30px", display: hide ? "none" : "block" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      width: "100%",
                      textDecoration: "none",
                    }}
                    component={disable ? "div" : Link}
                    to={url}
                    target={newTab ? "_blank" : ""}
                  >
                    <Box
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "6px",
                        borderColor: darkMode ? "black.350" : "white.555",
                        color: darkMode ? "white.1000" : "black.1000",
                        flexShrink: "0",
                      }}
                    >
                      <Icon
                        sx={{
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        flexGrow: "1",
                        fontSize: "14px",
                        lineHeight: 1.5,
                        fontWeight: 500,
                        textTransform: "capitalize",
                        color: darkMode ? "white.1000" : "black.1000",
                        textDecoration: "none",
                      }}
                    >
                      {name}
                    </Typography>
                  </Box>
                </MenuItem>
              )
            )}

            {/* theme toggler */}
            <MenuItem
              key={"them toggler"}
              sx={{ p: "6px 30px", display: { xs: "flex", md: "none" } }}
              onClick={() => toggleDarkMode()}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  width: "100%",
                  textDecoration: "none",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "6px",
                    borderColor: darkMode ? "black.350" : "white.555",
                    color: darkMode ? "white.1000" : "black.1000",
                    flexShrink: "0",
                  }}
                >
                  <ThemeSwitch
                    sx={{
                      height: "16px",
                      width: "16px",
                    }}
                  />
                </Box>
                <Stack
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: "1",
                      fontSize: "14px",
                      lineHeight: 1.5,
                      fontWeight: 500,
                      textTransform: "capitalize",
                      color: darkMode ? "white.1000" : "black.1000",
                      textDecoration: "none",
                    }}
                  >
                    Theme
                  </Typography>

                  <Stack
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDarkMode();
                    }}
                    sx={{
                      width: "46px",
                      height: "27px",
                      flexShrink: 0,
                      transform: "scale(0.8)",
                      // minWidth: "46px",
                      borderRadius: "20px",
                      backgroundColor: darkMode ? "grey.550" : "white.50",
                      border: "1px solid",
                      borderColor: darkMode ? "black.850" : "white.50",
                      cursor: "pointer",
                      padding: "3px",
                      display: "inline-flex",
                      boxSizing: "border-box",
                    }}
                  >
                    <IconButton
                      aria-label="toggle btn"
                      size="small"
                      style={{
                        transition: "all .2s ease-in",
                        color: darkMode
                          ? palette.black["850"]
                          : palette.white["1000"],
                      }}
                      sx={{
                        width: "19px",
                        height: "19px",
                        m: 0,
                        transform: darkMode
                          ? "translateX(100%)"
                          : "translateX(0%)",
                        backgroundColor: darkMode ? "white.1000" : "black.800",
                        "&:hover": {
                          backgroundColor: darkMode
                            ? "white.1000"
                            : "black.800",
                        },
                      }}
                    >
                      {darkMode ? (
                        <DarkModeRoundedIcon fontSize="8px" />
                      ) : (
                        <LightModeRoundedIcon fontSize="8px" />
                      )}
                    </IconButton>
                  </Stack>
                </Stack>
              </Box>
            </MenuItem>
          </Box>
          {isLoggedIn && (
            <Box sx={{ p: "0 30px", mt: "25px !important" }}>
              <Button
                variant="contained"
                onClick={logoutUser}
                sx={{
                  minWidth: "100px",
                  padding: "8px",
                  color: "white.1000",
                  fontSize: { xs: "14px", md: "16px" },
                  fontWeight: { xs: 400, md: 600 },
                  textTransform: "capitalize",
                  lineHeight: "24px",
                  width: "100%",
                }}
              >
                Log Out
              </Button>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: { xs: "block", md: "none" },
            maxWidth: "320px",
            width: "100%",
            position: "sticky",
            bottom: "0px",
            p: { xs: "15px 30px", md: "30px 30px" },
            mt: { xs: "25px !important", sm: "unset" },
            backgroundColor: darkMode ? "black.1000" : "white.1000",
          }}
        >
          <Divider
            sx={{ borderColor: darkMode ? "black.250" : "bgWhite.secondary" }}
          />
          {!isLoggedIn && (
            <Button
              variant="contained"
              component={Link}
              to="/signin"
              sx={{
                mt: "30px",
                minWidth: "100px",
                padding: "8px",
                color: "white.1000",
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: { xs: 400, md: 600 },
                textTransform: "capitalize",
                lineHeight: "24px",
                width: "100%",
                background: palette.gradients["main"],
              }}
            >
              Login
            </Button>
          )}
          <Button
            variant="contained"
            href="https://apps.apple.com/app/id1632348045"
            target="_blank"
            sx={{
              mt: isLoggedIn ? "30px" : "15px",
              minWidth: "100px",
              padding: "8px",
              color: "white.1000",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: { xs: 400, md: 600 },
              textTransform: "capitalize",
              lineHeight: "24px",
              width: "100%",
            }}
            startIcon={<AppleIcon />}
          >
            Download Now
          </Button>
        </Box>
      </Menu>
    </>
  );
}
