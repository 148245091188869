import { Box } from "@mui/material";
import React from "react";
import "../app.css";
import ploomSocialLightLogo from "../assets/PloomSocialLight.svg";

const SplashScreen = () => {
  return (
    <Box
      width={"100%"}
      height={"100vh"}
      display={"grid"}
      sx={{
        placeItems: "center",
        background: "linear-gradient(180deg, #3837F6 0%, #CE2B7E 100%)",
      }}
    >
      <Box sx={{ width: { xs: "150px", md: "auto" } }}>
        <img
          src={ploomSocialLightLogo}
          alt="Ploom Social"
          style={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
};

export default SplashScreen;
