import { useTheme, alpha, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import VideoIcon from "../../icons/VideoIcon";
import ImageIcon from "../../icons/ImageIcon";

import FeedMoreMenu from "./FeedMoreMenu";
import MuteIcon from "../../icons/MuteIcon";
import { useAuth } from "../../hooks/store/useAuth";
import VolumeUpIcon from "../../icons/VolumeUpIcon";
import { useSettings } from "../../hooks/store/useSettings";

export default function MediaType({
  videoType,
  // author,
  // username,
  // profileImage,
  id,
  title,
  description,
  activeVideo,
  saved,
  isMute = true,
  muteOnClickHandler = () => {},
  toggleVideoPlay = () => {},
}) {
  const { isLoggedIn } = useAuth();
  const { palette } = useTheme();
  const { settings } = useSettings();
  return (
    <>
      <Box
        position={"absolute"}
        display={"flex"}
        top={"15px"}
        right={{ xs: isLoggedIn ? "15px" : 0, sm: "15px" }}
        zIndex={2}
        sx={{
          flexDirection: "column",
          gap: "15px",
          alignItems: !isLoggedIn ? "center" : "flex-end",
        }}
        onMouseEnter={toggleVideoPlay}
        onMouseLeave={toggleVideoPlay}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          color={"neutral.1000"}
          border="1px solid"
          borderRadius={"54px"}
          position={"relative"}
          sx={{
            padding: "5px",
            backgroundColor: alpha(palette.white["550"], 0.2),
            backdropFilter: "blur(20px)",
          }}
        >
          <Box
            sx={{
              padding: { xs: "5px", sm: "11px" },
              display: "grid",
              placeItems: "center",
            }}
          >
            {videoType === 1 ? (
              <ImageIcon
                sx={{
                  width: {
                    xs: "16px",
                    sm: "20px",
                  },
                  height: { xs: "16px", sm: "20px" },
                }}
              />
            ) : (
              <VideoIcon
                sx={{
                  width: {
                    xs: "16px",
                    sm: "20px",
                  },
                  height: { xs: "16px", sm: "20px" },
                }}
              />
            )}
          </Box>
          {isLoggedIn && (
            <FeedMoreMenu
              activeVideo={activeVideo}
              data={{ id, title, description, saved }}
            />
          )}
        </Box>
        {videoType === 0 && settings.webAutoPlayFeed && (
          <Button
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            p={"0px"}
            onClick={muteOnClickHandler}
            mt="15px"
          >
            {isMute ? <MuteIcon /> : <VolumeUpIcon />}
          </Button>
        )}
      </Box>
    </>
  );
}
