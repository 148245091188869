import { create } from "zustand";

export const useAllModal = create((set) => ({
  showCreatePost: false,
  showNotificationbar: false,
  showSearchbar: false,
  showSettingDrawer: false,
  showSignupDrawer: false,
  showReportDrawer: false,
  reportData: {},
  showFollowerDrawer: false,
  followerId: undefined,
  showFollowingDrawer: false,
  followingId: undefined,
  showPaymentDrawer: false,
  begId: undefined,
  beg: undefined,
  begDescription: "",
  imageUrl: undefined,
  begTitle: "",
  authorId: undefined,
  showBlockListDrawer: false,
  showShareDrawer: false,
  openAfterDonation: false,
  donorDetails: false,
  shareLink: undefined,
  showCommonDrawer: false,
  author: { firstName: "", lastName: "" },

  showIdentityDrawer: false,
  profile: false,
  kycReason: undefined, // 1.raise 2.banking 3.deposit
  KycRedirect: false,
  redirectUrl: undefined,

  globalLoader: false,

  // next moment is onclick function prop
  nextMovement: false,

  // kyc dialog
  kycDialog: false,
  kycCompleteDate: undefined,

  setShowCreatePost: (value) => {
    set({ showCreatePost: value });
  },

  setShowNotificationbar: (value) => {
    set({ showNotificationbar: value });
    document.body.style.maxHeight = value ? "100vh" : "auto";
    document.body.style.overflow = value ? "hidden" : "auto";
  },

  setShowSearchbar: (value) => {
    set({ showSearchbar: value });
    document.body.style.maxHeight = value ? "100vh" : "auto";
    document.body.style.overflow = value ? "hidden" : "auto";
  },

  setShowSettingDrawer: (value) => {
    set({ showSettingDrawer: value });
  },

  setShowSignupDrawer: (value) => {
    set({ showSignupDrawer: value });
  },

  setShowReportDrawer: (value, reportData = {}) => {
    set({ showReportDrawer: value, reportData: reportData });
  },

  setShowFollowerDrawer: (value, id) => {
    set({ showFollowerDrawer: value, followerId: id });
  },

  setShowFollowingDrawer: (value, id) => {
    set({ showFollowingDrawer: value, followingId: id });
  },

  setShowPaymentDrawer: (value, begId = undefined, authorId = undefined) => {
    set({ showPaymentDrawer: value, begId: begId, authorId: authorId });
  },

  setShowBlockListDrawer: (value) => {
    set({ showBlockListDrawer: value });
  },

  setShowShareDrawer: (
    value,
    begId = undefined,
    begTitle = undefined,
    imageUrl = undefined,
    profile = false,
    description,
    author = {},
    beg = {},
    shareLink = undefined,
    openAfterDonation = false,
    donorDetails = false
  ) => {
    set({
      showShareDrawer: value,
      begId: begId,
      begTitle: begTitle,
      imageUrl: imageUrl,
      profile: profile,
      begDescription: description,
      author: author,
      beg: beg,
      shareLink: shareLink,
      openAfterDonation: openAfterDonation,
      donorDetails: donorDetails,
    });
  },

  setShowCommonDrawer: (value) => {
    set({ showCommonDrawer: value });
  },

  setShowIdentityDrawer: (
    value,
    kycReason = undefined,
    redirectUrl = undefined,
    KycRedirect = false
  ) => {
    set({ showIdentityDrawer: value, kycReason, redirectUrl, KycRedirect });
  },

  setGlobalLoader: (value) => {
    set({ globalLoader: value });
  },

  setNextMovement: (value = false) => {
    set({ nextMovement: value });
  },

  setOpenKycDialog: (value = false, kycCompleteDate = undefined) => {
    set({ kycDialog: value, kycCompleteDate });
  },
}));
