import { createSvgIcon } from "@mui/material";

export default function CameraIcon(props) {
  let color = props.color ? props.color : "#2e2e2e";
  const CreateCameraIcon = createSvgIcon(
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0511 5.4623C20.3079 5.96152 20.6925 7.70008 21.2064 8.2589C21.7204 8.81773 22.4559 9.00773 22.8628 9.00773C25.0256 9.00773 26.7791 10.7575 26.7791 12.9145V20.1109C26.7791 23.0044 24.4271 25.3515 21.5275 25.3515H9.00839C6.10759 25.3515 3.75684 23.0044 3.75684 20.1109V12.9145C3.75684 10.7575 5.51026 9.00773 7.6731 9.00773C8.07879 9.00773 8.81426 8.81773 9.32946 8.2589C9.84341 7.70008 10.2267 5.96152 11.4836 5.4623C12.7417 4.96309 17.7942 4.96309 19.0511 5.4623Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M22.1064 12.2283H22.1176"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2232 16.7339C19.2232 14.5533 17.4524 12.7861 15.2671 12.7861C13.0819 12.7861 11.311 14.5533 11.311 16.7339C11.311 18.9146 13.0819 20.6817 15.2671 20.6817C17.4524 20.6817 19.2232 18.9146 19.2232 16.7339Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  );
  return (
    <CreateCameraIcon
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "& .MuiSvgIcon-root": { width: "unset", height: "unset" },
        ...props,
      }}
    />
  );
}
