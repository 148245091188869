import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useThemeStore from "../hooks/store/useThemeStore";
import InfiniteScroll from "react-infinite-scroll-component";
import useApi from "../hooks/useApi";
import { APIS } from "../api/lists";
import { socket } from "../socket";
import { useNotification } from "../hooks/store/useNotification";
import Layout from "../component/Layout";
import StyledSwitch from "../component/notificationPanel/StyledSwitch";
import GeneralNotificationItemSkeleton from "../component/notificationPanel/GeneralNotificationItemSkeleton";
import NotificationItem from "../component/notificationPanel/NotificationItem";
import { useAuth } from "../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";
import NotificationItemEndRaise from "../component/notificationPanel/NotificationItemEndRaise";

export default function Notification() {
  const { darkMode } = useThemeStore();

  const handleClose = () => {};
  const { apiCall, isLoading } = useApi();
  const { apiCall: API } = useApi();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [generalNotification, setGeneralNotification] = useState([]);
  const [totalGeneralPages, setTotalGeneralPages] = useState(1);
  const [currentGeneralPage, setCurrentGeneralPage] = useState(1);
  const [hasMoreGeneralItem, setHasMoreGeneralItem] = useState(true);
  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const [unReadOnly, setUnReadOnly] = useState(false);
  const { setUnReadNotificationCount } = useNotification();
  const [todaysNotification, setTodaysNotification] = useState([]);
  const [thisWeekNotification, setThisWeekNotification] = useState([]);
  const [otherNotification, setOtherNotification] = useState([]);

  useEffect(() => {
    socket.on("notificationCount", (data) => {
      setUnReadNotificationCount(data.allReadCount);
      setHasMoreGeneralItem(true);
      setCurrentGeneralPage(1);
      setCurrentGeneralPage(1);
      setGeneralNotification([]);
      setMarkAllAsRead(false);
    });

    return () => {
      socket.off("notificationCount");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNotification = useCallback(async () => {
    try {
      const res = await apiCall({
        url: `v3/notification/get-all-notification`,
        method: "get",
        params: { page: currentGeneralPage, isRead: !unReadOnly },
      });

      if (res.data.data.data.length !== 0) {
        setGeneralNotification((prev) => {
          if (currentGeneralPage === 1) {
            return res.data.data.data;
          } else {
            return [...prev, ...res.data.data.data];
          }
        });
      }

      // Separate notifications based on timestamp
      const today = new Date();
      const thisWeek = new Date(today);
      thisWeek.setDate(thisWeek.getDate() - today.getDay());

      const todayNotifications = [];
      const thisWeekNotifications = [];
      const otherNotifications = [];

      res.data.data.data.forEach((item) => {
        const itemDate = new Date(item.createdAt);

        if (itemDate.toDateString() === today.toDateString()) {
          todayNotifications.push(item);
        } else if (itemDate >= thisWeek) {
          thisWeekNotifications.push(item);
        } else {
          otherNotifications.push(item);
        }
      });

      setTodaysNotification((prev) => {
        if (currentGeneralPage === 1) {
          return todayNotifications;
        } else {
          return [...prev, ...todayNotifications];
        }
      });

      setThisWeekNotification((prev) => {
        if (currentGeneralPage === 1) {
          return thisWeekNotifications;
        } else {
          return [...prev, ...thisWeekNotifications];
        }
      });

      setOtherNotification((prev) => {
        if (currentGeneralPage === 1) {
          return otherNotifications;
        } else {
          return [...prev, ...otherNotifications];
        }
      });

      setTotalGeneralPages(res.data.data.pagination.pages);
    } catch (error) {
      console.log(error);
    }
  }, [apiCall, currentGeneralPage, unReadOnly]);

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate("/");
    }
    fetchNotification();
    setUnReadNotificationCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNotification]);

  // get all comment count
  const getUnreadMsgCount = useCallback(async () => {
    let response = await API({
      url: APIS.NOTIFICATION.GET_COUNT,
      method: "GET",
    });
    setUnReadNotificationCount(response.data.data.allReadCount);
    setMarkAllAsRead(response.data.data.isReadCount > 0 ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [API]);

  useEffect(() => {
    getUnreadMsgCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // mark all as read
  function markAllRead() {
    apiCall({ url: APIS.NOTIFICATION.READAll, method: "PATCH" });
    setMarkAllAsRead(true);
  }
  return (
    <Layout>
      <Box sx={{ display: "flex", justifyContent: "center", mx: "15px" }}>
        <Box
          sx={{
            position: "relative",
            mt: { xs: "15px", md: "30px" },
            height: "85vh",
            display: "grid",
            gridTemplateRows: "auto 1fr",
            overflow: "hidden",
            width: { xs: "100%", sm: "500px", md: "673px" },
            backgroundColor: darkMode ? "bgDark.third" : "white.1000",
            zIndex: 100,
            borderRadius: "10px",
          }}
        >
          {/* notification top options */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: { xs: "10px", md: "20px 20px 10px 20px" },
              width: "100%",
              borderBottom: "1px solid",
              borderColor: darkMode ? "neutral.650" : "bgWhite.secondary",
            }}
          >
            <Typography
              sx={{
                color: darkMode ? "white.1000" : "black.1000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "20px" },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "142% /* 28.4px */",
                letterSpacing: "-0.408px",
              }}
            >
              Notifications
            </Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                sx={{ mr: 0 }}
                control={
                  <>
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: { xs: "10px", md: "13px" },
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      Only Show Unread
                    </Typography>
                    <StyledSwitch
                      darkMode={darkMode}
                      sx={{
                        ml: { xs: "4px", sm: "12px" },
                        transform: { xs: "scale(.7)", sm: "scale(1)" },
                      }}
                      checked={unReadOnly}
                      onClick={(e) => {
                        console.log(e.target.checked);
                        if (e.target.checked) {
                          setUnReadOnly(true);
                          setCurrentGeneralPage(1);
                          setTotalGeneralPages(1);
                          setGeneralNotification([]);
                          setOtherNotification([]);
                          setThisWeekNotification([]);
                          setTodaysNotification([]);
                          setHasMoreGeneralItem(true);
                        } else {
                          setUnReadOnly(false);
                          setCurrentGeneralPage(1);
                          setTotalGeneralPages(1);
                          setGeneralNotification([]);
                          setOtherNotification([]);
                          setThisWeekNotification([]);
                          setTodaysNotification([]);
                          setHasMoreGeneralItem(true);
                        }
                      }}
                    />
                  </>
                }
              />
            </Stack>
          </Stack>

          {/* notification listing */}
          <Stack
            id="page_notification_container"
            sx={{
              width: "100%",
              height: "100%",
              padding: "0px 10px 20px 20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Stack
              sx={{
                paddingTop: "15px",
                // position: "sticky",
                // top: 0,
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: 100,
                backgroundColor: darkMode ? "bgDark.third" : "white.1000",
              }}
            >
              <Typography
                sx={{
                  color: darkMode ? "white.1000" : "black.1000",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "22px /* 137.5% */",
                  letterSpacing: "-0.408px",
                }}
              >
                {todaysNotification.length > 0
                  ? "Today"
                  : thisWeekNotification.length > 0
                  ? "This Week"
                  : otherNotification.length > 0
                  ? "Earlier"
                  : ""}
              </Typography>
              {(!markAllAsRead ||
                (isLoading && generalNotification.length > 0)) && (
                <Button
                  variant="text"
                  sx={{
                    // color: darkMode ? "grey.900" : "primary.main",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "142% /* 17.04px */",
                    letterSpacing: "-0.408px",
                  }}
                  onClick={() => {
                    markAllRead();
                  }}
                >
                  Mark all as read
                </Button>
              )}
            </Stack>
            <Stack
              sx={{
                width: "100%",
                flexDirection: "column",
              }}
            >
              <InfiniteScroll
                style={{ width: "100%" }}
                scrollableTarget="page_notification_container"
                dataLength={generalNotification.length} //This is important field to render the next data
                next={() => {
                  if (currentGeneralPage < totalGeneralPages) {
                    setCurrentGeneralPage((prev) => prev + 1);
                  } else {
                    setHasMoreGeneralItem(false);
                  }
                }}
                hasMore={hasMoreGeneralItem}
                loader={
                  <GeneralNotificationItemSkeleton></GeneralNotificationItemSkeleton>
                }
                endMessage={
                  <Typography
                    textAlign={"center"}
                    color={darkMode ? "white.1000" : "primary.main"}
                    fontSize={{
                      xs: "14px",
                      sm: "14px",
                      md: "18px",
                    }}
                    mt={2}
                  >
                    {generalNotification.length === 0
                      ? "No notifications yet."
                      : "you seen it all."}
                  </Typography>
                }
              >
                {todaysNotification.length > 0 &&
                  todaysNotification.map((item) =>
                    item.notificationType === "RAISE END" ? (
                      <NotificationItemEndRaise
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    ) : (
                      <NotificationItem
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    )
                  )}

                {todaysNotification.length > 0 &&
                  thisWeekNotification.length > 0 && (
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22px /* 137.5% */",
                        letterSpacing: "-0.408px",
                        mt: "18px",
                        pt: "18px",
                      }}
                    >
                      This week
                    </Typography>
                  )}
                {thisWeekNotification.length > 0 &&
                  thisWeekNotification.map((item) =>
                    item.notificationType === "RAISE END" ? (
                      <NotificationItemEndRaise
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    ) : (
                      <NotificationItem
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    )
                  )}

                {todaysNotification.length > 0 &&
                  thisWeekNotification.length > 0 &&
                  otherNotification.length > 0 && (
                    <Typography
                      sx={{
                        color: darkMode ? "white.1000" : "black.1000",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22px /* 137.5% */",
                        letterSpacing: "-0.408px",
                        mt: "18px",
                        pt: "18px",
                      }}
                    >
                      Earlier
                    </Typography>
                  )}
                {otherNotification.length > 0 &&
                  otherNotification.map((item) =>
                    item.notificationType === "RAISE END" ? (
                      <NotificationItemEndRaise
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    ) : (
                      <NotificationItem
                        key={item?._id}
                        data={item}
                        markAllAsRead={markAllAsRead}
                        close={handleClose}
                      />
                    )
                  )}

                {/* loader  */}
                {isLoading && <GeneralNotificationItemSkeleton />}

                {/* message for no notification available */}
                {!isLoading && generalNotification.length === 0 && (
                  <Typography
                    textAlign={"center"}
                    color={darkMode ? "white.1000" : "primary.main"}
                    fontSize={{
                      xs: "12px",
                      sm: "14px",
                    }}
                    mt={2}
                  >
                    No notifications yet.
                  </Typography>
                )}
              </InfiniteScroll>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Layout>
  );
}
