import React from "react";

export default function SadPathSvg() {
  return (
    <svg
      width="430"
      height="377"
      viewBox="0 0 430 377"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3981 57H317.073C324.67 57 331.221 63.8725 331.607 72.2655L342.232 301.735C342.618 310.128 336.699 317 329.102 317H25.4273C17.8024 317 11.2786 310.128 10.8932 301.735L0.267881 72.2655C-0.117493 63.8725 5.77322 57 13.3981 57Z"
        fill="#3362CC"
      />
      <path
        opacity="0.05"
        d="M215 0C333.745 0 430 108.881 430 243.171C430 292.194 417.168 337.81 395.108 376H34.8918C12.8322 337.81 0 292.194 0 243.171C0 108.881 96.255 0 215 0Z"
        fill="white"
      />
      <path
        d="M128.119 345.413C181.797 345.413 226.708 358.879 238.159 376.947H37.4922C35.1065 373.209 32.8103 369.401 30.6035 365.521C50.1961 353.487 86.5184 345.413 128.119 345.413Z"
        fill="#3362CC"
      />
      <path
        d="M181.5 309.229L162.31 377H99.5L116.678 316.393C136 301.558 160.193 298.863 181.5 309.229Z"
        fill="#3362CC"
      />
      <path
        d="M19.5833 48H322.422C329.998 48 336.53 54.9287 336.915 63.353L347.482 293.647C347.866 302.102 341.993 309 334.417 309H31.6057C24.0025 309 17.4698 302.102 17.0855 293.647L6.51783 63.353C6.13355 54.9287 12.0075 48 19.5833 48Z"
        fill="#79A2FF"
      />
      <path
        d="M24.1469 56H315.749C321.328 56 326.145 61.1196 326.445 67.3668L336.488 287.603C336.76 293.88 332.433 299 326.853 299H35.2514C29.672 299 24.8546 293.88 24.5552 287.603L14.5122 67.3668C14.24 61.1196 18.5675 56 24.1469 56Z"
        fill="white"
      />
      <path
        d="M86.667 118.336H253.845L259.809 237.983H92.6312L86.667 118.336Z"
        fill="#3362CC"
      />
      <path
        d="M86.667 118.336H253.845L254.978 141.126H87.8002L86.667 118.336Z"
        fill="#79A2FF"
      />
      <path
        d="M141.148 224.661C152.958 211.956 173.087 211.956 186.149 224.661C199.21 237.336 200.254 257.932 188.445 270.607C176.666 283.312 156.506 283.312 143.445 270.607C130.413 257.932 129.369 237.336 141.148 224.661Z"
        fill="white"
      />
      <path
        d="M154.123 180.491L163.815 170.041L173.298 179.242L181.857 170.041L192.592 180.491L184.034 189.693L193.517 198.894L183.825 209.344L174.342 200.143L165.783 209.344L155.018 198.894L163.576 189.693L154.123 180.491Z"
        fill="white"
      />
      <path
        d="M224.979 123.486H248.09L248.776 136.739H225.635L224.979 123.486Z"
        fill="white"
      />
      <path
        d="M336.494 259.769H351.077V250.11H336.494V259.769Z"
        fill="#FFAA00"
      />
      <path
        d="M370.021 353.731C367.009 356.717 361.462 365.156 359.852 365.705C358.211 366.253 356.989 368.234 356.81 370.671C356.631 373.078 357.525 375.424 359.046 376.46H392.058L391.611 371.219L392.058 363.115L370.021 353.731Z"
        fill="#1A1A1A"
      />
      <path
        d="M338.381 93.4746L335.757 115.229C335.309 119.068 336.711 122.876 339.544 125.435C342.377 127.964 346.284 128.939 349.922 127.995L354.335 145.757L366.055 132.961L362.685 123.333L367.069 95.4245L338.381 93.4746Z"
        fill="#F7A491"
      />
      <path
        d="M304.832 239.873H302.804C301.701 239.873 300.657 240.33 299.881 241.152C299.136 241.944 298.718 243.041 298.778 244.169V250.11H303.013V244.29H305.011C305.816 244.29 306.532 243.864 306.919 243.133C307.307 242.432 307.277 241.579 306.83 240.878C306.412 240.208 305.637 239.873 304.832 239.873Z"
        fill="#1A1A1A"
      />
      <path
        d="M353.232 150.907L354.425 158.036C358.003 179.272 353.143 201.087 340.976 218.667C340.141 219.886 340.051 221.501 340.737 222.78C344.316 229.392 351.204 233.414 358.57 233.231C365.936 233.018 372.616 228.661 375.837 221.866C378.282 216.809 378.282 210.868 375.896 205.779L378.431 196.761C382.576 181.862 382.666 166.08 378.67 151.151C377.119 145.301 371.96 141.249 366.025 141.188C360.121 141.157 354.902 145.118 353.232 150.907Z"
        fill="#3362CC"
      />
      <path
        d="M372.705 206.663L378.013 215.072C383.768 224.212 384.424 235.729 379.772 245.509L385.379 297L388.033 305.439C394.087 324.695 395.429 345.2 391.88 365.095H366.8L359.733 309.37L356.184 300.93L340.289 220.8L372.705 206.663Z"
        fill="#79A2FF"
      />
      <path
        d="M385.677 297.944L388.033 305.439C394.086 324.695 395.428 345.2 391.88 365.095H381.83C377.655 358.453 373.808 349.526 371.064 339.411C364.712 316.195 366.472 295.446 374.941 293.039C378.192 292.095 381.919 293.984 385.677 297.944Z"
        fill="#79A2FF"
      />
      <path
        d="M358.033 206.663L362.059 215.072C366.413 224.212 365.339 235.729 359.196 245.509L356.959 297L358.331 305.439C361.463 324.695 359.643 345.2 353.083 365.095H328.033L329.434 309.37L327.168 300.93L323.44 220.8L358.033 206.663Z"
        fill="#79A2FF"
      />
      <path
        d="M359.196 209.07L362.059 215.072C366.413 224.212 365.339 235.729 359.196 245.509L359.107 247.581C356.423 243.285 352.516 239.812 347.745 237.74C340.021 234.358 331.254 235.181 324.335 239.751L323.44 220.8L353.023 208.704L359.196 209.07Z"
        fill="#79A2FF"
      />
      <path
        d="M366.204 127.264L373.033 136.77C379.594 145.88 381.592 157.61 378.46 168.457C375.389 179.059 376.939 190.546 382.695 199.93C387.049 207.029 386.572 216.169 381.502 222.781L377.268 221.562C363.729 217.693 349.206 220.709 338.232 229.666C334.593 232.622 329.911 233.81 325.349 232.957C320.786 232.104 316.82 229.27 314.464 225.188C311.571 220.191 311.452 214.036 314.106 208.918C319.325 198.955 321.74 187.682 321.054 176.409C319.981 158.676 329.106 141.919 344.434 133.51L366.204 127.264Z"
        fill="#3362CC"
      />
      <path
        d="M385.469 213.213C385.022 216.625 383.68 219.946 381.503 222.78L377.268 221.561C363.729 217.692 349.206 220.708 338.232 229.666C334.594 232.621 329.912 233.809 325.349 232.956C323.471 232.591 321.681 231.92 320.071 230.945L312.317 214.858C312.526 212.817 313.123 210.806 314.107 208.917C319.325 198.954 321.741 187.681 321.055 176.408C321.015 175.819 320.995 175.24 320.995 174.671C321.731 174.509 322.457 174.275 323.172 173.971C326.125 172.63 328.391 170.071 329.345 166.932L332.715 155.781L350.817 187.529C348.043 197.309 351.801 207.82 360.151 213.426C367.904 218.636 377.924 218.484 385.469 213.213Z"
        fill="#3362CC"
      />
      <path
        d="M329.316 155.386L328.838 162.576C327.407 184.087 317.685 204.134 301.791 218.302C300.687 219.277 300.24 220.8 300.628 222.232C302.566 229.514 308.321 235.059 315.538 236.644C322.755 238.258 330.24 235.608 334.952 229.788C338.471 225.431 339.872 219.673 338.709 214.158L343.242 205.993C350.727 192.526 354.425 177.201 353.978 161.723C353.799 155.66 349.713 150.481 343.988 149.018C338.262 147.556 332.268 150.146 329.316 155.386Z"
        fill="#3362CC"
      />
      <path
        d="M303.162 223.847L301.253 240.879C300.985 243.346 302.356 245.693 304.653 246.607C308.261 248.008 312.287 248.008 315.925 246.607C317.655 245.906 318.848 244.261 318.937 242.372C319.027 240.848 318.937 239.355 318.639 237.862C318.341 236.247 317.058 235.029 315.448 234.876L315.925 232.134C316.492 228.813 315.03 225.462 312.257 223.634C309.454 221.836 305.875 221.928 303.162 223.847Z"
        fill="#F7A491"
      />
      <path d="M278.5 259.769H344.077V250.11H278.5V259.769Z" fill="#407BFF" />
      <path
        d="M282.019 285.087H340.558V259.768H282.019V285.087Z"
        fill="#333333"
      />
      <path d="M325.2 285.087H340.558V259.768H325.2V285.087Z" fill="#1A1A1A" />
      <path
        d="M282.019 259.768V262.419L329.494 269.792L325.647 259.768H282.019Z"
        fill="#1A1A1A"
      />
      <path
        d="M316.612 239.873H318.639C319.743 239.873 320.787 240.33 321.562 241.152C322.307 241.944 322.725 243.041 322.695 244.169V250.11H318.431V244.29H316.433C315.657 244.29 314.912 243.864 314.524 243.133C314.166 242.432 314.196 241.579 314.614 240.878C315.061 240.208 315.806 239.873 316.612 239.873Z"
        fill="#1A1A1A"
      />
      <path
        d="M337.694 99.172C337.008 94.4191 334.474 89.2395 337.993 85.035C340.199 82.4147 343.748 81.4398 346.939 82.5671L362.058 85.2787C364.712 85.5834 367.098 87.0458 368.589 89.3309C370.08 91.5855 370.498 94.4191 369.722 97.0393L362.685 123.333H356.75C354.663 123.333 352.903 121.749 352.635 119.616C352.237 116.63 351.82 113.644 351.382 110.658L345.418 110.445L347.028 102.31C347.177 101.488 346.939 100.634 346.402 100.056C345.836 99.4462 345.03 99.172 344.225 99.2939L337.694 99.172Z"
        fill="#1A1A1A"
      />
      <path
        d="M358.466 105.723C360.077 106.089 360.852 108.801 360.226 111.756C359.57 114.711 357.721 116.783 356.11 116.418C354.47 116.052 353.695 113.371 354.351 110.415C355.007 107.46 356.826 105.358 358.466 105.723Z"
        fill="#F7A491"
      />
      <path
        d="M331.701 353.731L327.436 357.996C323.768 361.653 319.653 364.242 315.329 365.705C313.689 366.253 312.466 368.234 312.287 370.671C312.108 373.078 313.003 375.424 314.524 376.46H353.739L353.291 371.219L353.739 363.115L331.701 353.731Z"
        fill="black"
      />
    </svg>
  );
}
