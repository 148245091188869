import { Box } from "@mui/material";
import React from "react";
import useThemeStore from "../../hooks/store/useThemeStore";

const SelectedFileContainer = ({ AllFiles }) => {
  const { darkMode } = useThemeStore();
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(auto-fill, minmax(70px, 1fr))",
            md: "repeat(auto-fill, minmax(70px, 1fr))",
          },
          gap: "5px",
          mt: "15px",
        }}
      >
        {AllFiles.map((file) => (
          <Box
            key={file.url}
            sx={{
              height: { xs: "70px", md: "70px" },
              width: { xs: "70px", md: "70px" },
              borderRadius: "5px",
              overflow: "hidden",
              position: "relative",
              backgroundColor: darkMode ? "grey.1000" : "grey.100",
            }}
          >
            {String(file.file.type).slice(0, 5) === "image" ? (
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
                alt="video.file"
                src={file.url}
                height={80}
                width={80}
              ></img>
            ) : (
              <video
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
                alt="video.file"
                src={file.url}
                height={80}
                width={80}
              ></video>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default SelectedFileContainer;
