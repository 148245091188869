import { createSvgIcon } from "@mui/material";

export default function VerifiedIcon(props) {
  let CreateAddIcon = createSvgIcon(
    <>
      <path
        d="M25 12.5C25 13.2904 23.6489 13.943 23.5018 14.6875C23.3456 15.4596 24.3474 16.5717 24.0533 17.2886C23.75 18.0055 22.2518 18.0882 21.829 18.7316C21.3971 19.375 21.8842 20.7904 21.3419 21.3419C20.7904 21.8842 19.375 21.3879 18.7316 21.829C18.0882 22.2518 18.0055 23.75 17.2886 24.0533C16.5809 24.3474 15.4596 23.3456 14.6875 23.5018C13.943 23.6489 13.2904 25 12.5 25C11.7096 25 11.057 23.6489 10.3125 23.5018C9.54044 23.3456 8.42831 24.3474 7.72059 24.0533C6.99449 23.75 6.91177 22.2518 6.26838 21.829C5.625 21.3879 4.20956 21.8842 3.65809 21.3419C3.11581 20.7904 3.61213 19.375 3.18015 18.7316C2.74816 18.0882 1.25 18.0055 0.94669 17.2886C0.652572 16.5717 1.65441 15.4596 1.49816 14.6875C1.3511 13.943 0 13.2904 0 12.5C0 11.7096 1.3511 11.057 1.49816 10.3125C1.65441 9.54044 0.652572 8.42831 0.94669 7.71139C1.25 6.99448 2.74816 6.91176 3.18015 6.26838C3.61213 5.625 3.11581 4.20956 3.65809 3.65809C4.20956 3.11581 5.625 3.60294 6.26838 3.17096C6.91177 2.74816 6.99449 1.25 7.72059 0.94669C8.42831 0.652572 9.54044 1.65441 10.3125 1.49816C11.057 1.3511 11.7096 0 12.5 0C13.2904 0 13.943 1.3511 14.6875 1.49816C15.4596 1.65441 16.5809 0.652572 17.2886 0.94669C18.0055 1.25 18.0882 2.74816 18.7316 3.17096C19.375 3.60294 20.7904 3.11581 21.3419 3.65809C21.8934 4.20956 21.3971 5.625 21.829 6.26838C22.2518 6.91176 23.75 6.99448 24.0533 7.71139C24.3474 8.42831 23.3456 9.54044 23.5018 10.3125C23.6489 11.057 25 11.7096 25 12.5Z"
        fill={`url(#${props.id})`}
      />
      <path
        d="M10.9554 17.6476L6.61719 13.0982L8.26241 11.3728L10.9554 14.1873L17.472 7.35352L19.1172 9.07884L10.9554 17.6476Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={props.id}
          x1="12.5"
          y1="0"
          x2="12.5"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
      </defs>
    </>
  );
  return (
    <CreateAddIcon
      width="15"
      height="15"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
        },
        ...props,
      }}
    />
  );
}
