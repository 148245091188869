import React, { useState } from "react";
import ButtonWithLoading from "../ui/button/ButtonWithLoading";
import UserIcon from "../../icons/UserIcon";
import Input from "../ui/form/Input";
import CameraIcon from "../../icons/CameraIcon";
import { Avatar, Box, Icon, Stack, Typography, alpha } from "@mui/material";
import ManIcon from "../../icons/ManIcon";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";
import useApi from "../../hooks/useApi";
import { useSnack } from "../../hooks/store/useSnack";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/store/useAuth";
import { useSignin } from "../../hooks/store/useSignin";
import { useFeed } from "../../hooks/store/useFeed";

const SetProfileForm = () => {
  const { darkMode } = useThemeStore();
  const { typography, palette } = useTheme();
  const { apiCall } = useApi(true);
  const { apiCall: API } = useApi();
  const { setSnack } = useSnack();
  const { userId, idToken, username, password, normalLogin } = useSignin();
  const { login } = useAuth();
  const [newImage, setNewImage] = useState("");
  const navigate = useNavigate();
  const { resetFeedStore } = useFeed();

  // upload file in aws
  const uploadFileInAWS = async (file) => {
    let res;

    try {
      res = await API({
        url: `v3/get-signed-url/new/profiles/${userId}`,
      });
      if (res.status === 200) {
        res = res.data.data;
        await axios({
          method: "put",
          url: res.url,
          headers: {
            "Content-Type": file.type,
          },
          data: file,
        });
      } else throw Error("link can not get");
    } catch (error) {
      console.log(error);
    }
    return res;
  };

  // form handling
  const schema = Yup.object({
    firstName: Yup.string()
      .required("First name is required")
      .min(3, "First name must contain at least 3 characters")
      .max(24, "First name must be at most 24 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .min(3, "Last name must contain at least 3 characters")
      .max(24, "Last name must be at most 24 characters"),
    profilePicture: Yup.array().min(1).max(1),
  });

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const complateOnboarding = async (values) => {
    try {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
      };

      // validate profile image type if usder went to change profile image
      if (values.file !== undefined) {
        try {
          if (
            values.file.type === "image/png" ||
            values.file.type === "image/jpg" ||
            values.file.type === "image/jpeg" ||
            values.file.type === "image/webp"
          ) {
            let res = await uploadFileInAWS(values.file);
            data.profileImage = `${process.env.REACT_APP_MEDIA_PREFIX_URL}${res.uuid}`;
          } else {
            return setSnack(
              "profile picture file must be png,jpg,jpeg or webp required",
              "error"
            );
          }
        } catch (error) {
          console.log(error);
        }
      }

      // 4. if user didn't set any profile picture than remove profileImage field from data
      if (data.profileImage === "") {
        delete data.profileImage;
      }

      let response = await apiCall({
        url: `v2/accounts/onbord/${userId}`,
        method: "patch",
        data,
      });
      console.log(response);

      try {
        let manual_login_data = {
          username,
          password,
          role: "customer",
        };

        let social_login_data = {
          idToken: idToken,
        };
        let user = await apiCall({
          url: normalLogin ? "v2/login/signin" : "v2/login/social-login",
          method: "POST",
          data: normalLogin ? manual_login_data : social_login_data,
        });

        if (user.status === 200) {
          // console.log(res.data.data);
          let {
            userId,
            firstName,
            lastName,
            username,
            orgName,
            email,
            accessToken,
            refreshToken,
            profileImage,
            mobile,
            code,
            email_verified,
            mobile_verified,
            isKyc,
            onboarding_complete,
          } = user.data.data;
          login({
            user: {
              firstName,
              lastName,
              username,
              orgName,
              email,
              profileImage,
              mobile,
              code,
              email_verified,
              mobile_verified,
              isKyc,
              onboarding_complete,
            },
            refreshToken,
            accessToken,
            userId,
          });
          setSnack("login successfully");
          resetFeedStore();
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      if (error.response) {
        setSnack("user is already exist with this email", "error");
      }
      navigate("/404");
    }
  };

  return (
    <>
      <Box
        component={"form"}
        onSubmit={handleSubmit(complateOnboarding)}
        sx={{
          minWidth: { xs: "unset", md: "491px", lg: "551px" },
          paddingInline: { xs: "20px", md: "30px", lg: "60px" },
          paddingBlock: { xs: "20px", lg: "50px" },
          backgroundColor: darkMode ? "bgDark.third" : "white.1000",
          borderRadius: { xs: "10px", lg: "18px" },
          marginTop: { xs: 0, lg: "50px" },
          boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.06)",
          height: { xs: "85vh", md: "unset" },
        }}
      >
        <Box
          sx={{
            maxWidth: "431px",
            margin: "0 auto",
            height: { xs: "100%", md: "unset" },
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {/* form headings */}
          <Typography
            sx={{
              width: "471px",
              fontFamily: typography.manrope,
              color: darkMode ? "white.1000" : "black.1000",
              fontSize: { xs: "26px", lg: "36px" },
              fontWeight: { xs: 800, lg: 700 },
              lineHeight: { xs: "40px", lg: "122%" },
              letterSpacing: { xs: "-0.52px", md: "-0.72px" },
            }}
          >
            Set Profile,
          </Typography>
          <Typography
            sx={{
              mt: "10px",
              fontFamily: typography.manrope,
              color: darkMode ? "neutral.450" : "neutral.550",
              fontSize: { xs: "18px", lg: "16px" },
              fontWeight: 400,
              lineHeight: "152%",
            }}
          >
            Connecting Mentors with Purpose
          </Typography>

          <Box sx={{ marginTop: { xs: "20px", md: "15px" }, flex: "1" }}>
            {/* user profile input */}
            <Stack
              sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: { xs: "flex-start", md: "flex-start" },
              }}
            >
              <Avatar
                sx={{
                  position: "relative",
                  width: { xs: "90px", sm: "100px", md: "105px" },
                  height: { xs: "90px", sm: "100px", md: "102px" },
                  borderRadius: "10px",
                  backgroundColor: darkMode ? "grey.550" : "white.800",
                  cursor: "pointer",
                  color: "white.200",
                }}
                //   onClick={handleOpen}
                src={newImage}
              >
                <ManIcon
                  color={darkMode ? "white.1000" : "white.200"}
                  width="36px"
                  height="36px"
                />
              </Avatar>
              <Icon
                sx={{
                  position: "absolute",
                  bottom: "5px",
                  right: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: { xs: "25px", md: "30px" },
                  height: { xs: "25px", md: "30px" },
                  borderRadius: "12px",
                  backgroundColor: "white.1000",
                  padding: "4px",
                  cursor: "pointer",
                }}
                component={"label"}
                htmlFor="profilePicture"
              >
                <CameraIcon
                  width={{ xs: "18px", md: "20px" }}
                  height={{ xs: "18px", md: "20px" }}
                ></CameraIcon>
              </Icon>
            </Stack>

            {/* input box for set profile picture */}
            <input
              type="file"
              name="profilePicture"
              accept="image/png, image/jpg, image/jpeg, image/webp" // Fix the accepted file types
              id="profilePicture"
              style={{ display: "none" }}
              onChange={(e) => {
                const imageFile = e.target.files[0];
                if (imageFile) {
                  const reader = new FileReader();
                  // Convert the image file to a string
                  reader.readAsDataURL(imageFile);
                  reader.addEventListener("load", () => {
                    setNewImage(reader.result);
                  });
                }
                setValue("file", e.target.files[0]);
              }}
            />

            <Input
              name={"firstName"}
              inputLabel={"First Name"}
              type="text"
              register={register}
              errors={formState.errors}
              placeholder="Enter First Name"
              icon={
                <UserIcon
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
            />

            <Input
              name={"lastName"}
              inputLabel={"Last Name"}
              type="text"
              register={register}
              errors={formState.errors}
              placeholder="Enter Last Name"
              icon={
                <UserIcon
                  color={
                    darkMode ? palette.white["1000"] : palette.black["450"]
                  }
                />
              }
            />
          </Box>

          <ButtonWithLoading
            color="primary"
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              fontFamily: typography.manrope,
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "152%",
              padding: "14px 12px",
              marginTop: { xs: "25px", lg: "30px" },
              borderRadius: "10px",
              textTransform: "capitalize",
              "&.Mui-disabled": {
                color: darkMode
                  ? alpha(palette.white["1000"], 0.7)
                  : "white.1000",
                backgroundColor: darkMode
                  ? "primary.disabledDark"
                  : "primary.disabledLight",
              },
            }}
          >
            Confirm
          </ButtonWithLoading>
        </Box>
      </Box>
    </>
  );
};

export default SetProfileForm;
