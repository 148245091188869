import React from "react";
import DialogBackground from "./DialogBackground";
import { Box, Button, alpha } from "@mui/material";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

export default function AddBankAccount({ confirmCall, isLoading }) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  return (
    <DialogBackground
      heading={"Add Banking Details"}
      description="Please add bank account details in order to complete the funds withdrawal process."
      confirmCall={confirmCall}
      isLoading={isLoading}
      hideAction
      confirmLabel="Add Account"
      showCloseBtn={true}
    >
      <Box sx={{ mx: "auto", maxWidth: { sm: "400px" }, my: "20px" }}>
        <Button
          color="primary"
          variant="contained"
          sx={{
            p: "15px",
            fontSize: { xs: "14px", md: "16px" },
            lineHeight: 1.3,
            width: "100%",
            "&.Mui-disabled": {
              color: darkMode
                ? alpha(palette.white["1000"], 0.7)
                : "white.1000",
              backgroundColor: darkMode
                ? "primary.disabledDark"
                : "primary.disabledLight",
            },
          }}
          onClick={confirmCall}
        >
          Add Bank Account
        </Button>
      </Box>
    </DialogBackground>
  );
}
