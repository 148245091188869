import { create } from "zustand";

export const useSnack = create((set) => ({
  message: "",
  type: "",
  open: false,
  setSnack(message = "", type = "success", open = true) {
    set(() => ({
      message,
      type,
      open,
    }));
  },
  closeSnack() {
    set(() => ({ open: false }));
  },
}));
