import { createSvgIcon } from "@mui/material";
import React from "react";

export default function MessageIcon(props) {
  const CreateMessageIcon = createSvgIcon(
    <>
      <path
        d="M14.9217 6.37207L11.219 9.38294C10.5194 9.93794 9.53513 9.93794 8.83555 9.38294L5.10156 6.37207"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.092 16.4961C16.6265 16.5031 18.3346 14.4207 18.3346 11.8614V6.13777C18.3346 3.57845 16.6265 1.49609 14.092 1.49609H5.91058C3.37612 1.49609 1.66797 3.57845 1.66797 6.13777V11.8614C1.66797 14.4207 3.37612 16.5031 5.91058 16.4961H14.092Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
    </>
  );
  return (
    <CreateMessageIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
