import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ResetEmailForm from "../component/editProfile/ResetEmailForm";
import OtpVerifyForm from "../component/editProfile/OtpVerifyForm";
import { useAuth } from "../hooks/store/useAuth";
import { useRedirect } from "../hooks/store/useRedirect";
import { useNavigate } from "react-router-dom";

const ResetEmail = () => {
  const [openForm, setOpenForm] = useState("OTP"); //1.OTP 2.resetEmail
  const { isLoggedIn } = useAuth();
  const { setRedirectRoute } = useRedirect();
  const navigate = useNavigate();
  // check user is loaggedin or not
  useEffect(() => {
    if (!isLoggedIn) {
      setRedirectRoute("/reset-email");
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {openForm === "OTP" && (
        <OtpVerifyForm setOpenForm={setOpenForm} useCase={"resetEmail"} />
      )}
      {openForm === "resetEmail" && <ResetEmailForm />}
    </Box>
  );
};

export default ResetEmail;
