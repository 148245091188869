import { createSvgIcon } from "@mui/material";

export default function HowToIconNav(props) {
  let CreateSearchIcon = createSvgIcon(
    <>
      <g id="Frame" clipPath="url(#clip0_2629_117724)">
        <g id="Group">
          <path
            id="Vector"
            d="M10 0C4.47301 0 0 4.4725 0 10C0 15.5269 4.4725 20 10 20C15.527 20 20 15.5275 20 10C20 4.47301 15.5275 0 10 0ZM10 18.6046C5.25539 18.6046 1.39535 14.7446 1.39535 10C1.39535 5.25535 5.25539 1.39535 10 1.39535C14.7446 1.39535 18.6046 5.25535 18.6046 10C18.6046 14.7446 14.7446 18.6046 10 18.6046Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M9.70401 12.6535C9.15116 12.6535 8.70362 13.1142 8.70362 13.667C8.70362 14.2068 9.138 14.6806 9.70401 14.6806C10.27 14.6806 10.7175 14.2068 10.7175 13.667C10.7175 13.1142 10.2568 12.6535 9.70401 12.6535ZM9.87511 4.97949C8.09808 4.97949 7.28198 6.03258 7.28198 6.74336C7.28198 7.25672 7.71636 7.49367 8.07175 7.49367C8.78257 7.49367 8.493 6.48008 9.83562 6.48008C10.4937 6.48008 11.0203 6.76969 11.0203 7.3752C11.0203 8.08598 10.2832 8.49402 9.84878 8.86258C9.46702 9.1916 8.96687 9.73133 8.96687 10.8634C8.96687 11.5478 9.15116 11.7453 9.69081 11.7453C10.3358 11.7453 10.4674 11.4557 10.4674 11.2055C10.4674 10.5211 10.4806 10.1262 11.2046 9.5602C11.56 9.28379 12.6788 8.38867 12.6788 7.15137C12.6788 5.91406 11.56 4.97949 9.87511 4.97949Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2629_117724">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateSearchIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
