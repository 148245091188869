import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import ScrollToTop from "../component/ScrollToTop";
import DynamicMeta from "../utils/DynamicMeta";
import { useTheme } from "@emotion/react";
import bgWhite from "../assets/bgWhite.svg";
import bgDark from "../assets/darkBg.png";
import smallBg from "../assets/smallBg.svg";
import ploomLogo from "../assets/ploom.svg";
import useThemeStore from "../hooks/store/useThemeStore";
import SignupForm from "../component/SignupFormContainers/SignupForm";
import VarifyOtpForm from "../component/SignupFormContainers/VarifyOtpForm";
import SetProfileForm from "../component/SignupFormContainers/SetProfileForm";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/store/useAuth";
import EmailVerifyForm from "../component/SignupFormContainers/EmailVerifyForm";
import AccountTypeForm from "../component/SignupFormContainers/AccountTypeForm";
import TopBar from "../component/SignupFormContainers/TopBar";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

export default function Signup() {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const [openForm, setOpenForm] = useState("chooseAccountType"); //0.chooseAccountType 1.Registration,  2.Verify, 3.EmailVerify 4.Profile
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openNextForm(formName) {
    setOpenForm(formName);
  }

  return (
    <>
      <DynamicMeta title="Register | Ploom Social" />
      <ScrollToTop></ScrollToTop>

      <TopBar openForm={openForm} setOpenForm={setOpenForm} />

      <Box
        minHeight={"100vh"}
        sx={{
          display: { xs: "flex", lg: "grid" },
          alignItems: { xs: "flex-start", md: "center", lg: "center" },
          gridTemplateColumns: { xs: "1fr", lg: "1.2fr 1fr" },
          background: {
            xs: `url(${smallBg}) no-repeat fixed`,
            md: `${
              darkMode ? palette.bgDark["secondary"] : palette.white["1000"]
            } url(${darkMode ? bgDark : bgWhite}) no-repeat fixed`,
          },
          backgroundPosition: { xs: "center", md: "right bottom" },
          backgroundSize: { xs: "cover", md: "auto 100% " },
          padding: { xs: "15px", md: "unset" },
          paddingLeft: { lg: "49px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%" },
            maxWidth: "780px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "center", lg: "flex-start" },
            borderRadius: "10px",
            gap: "30px",
            backgroundColor: "transparent",
          }}
        >
          {/* form container */}
          <Box
            sx={{
              marginTop: { xs: 0, md: "30px", lg: "45px" },
              display: "flex",
              flexDirection: "column",
              maxWidth: "571px",
              width: "100%",
              borderRadius: { xs: "10px", md: "16px" },
              marginBottom: { xs: 0, lg: "40px" },
            }}
          >
            {/* logo with back button */}
            <Box
              sx={{
                display: { xs: "none", lg: "flex" },
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "87px",
                margin: "0 auto",
                background: "transparent",
              }}
            >
              <Box minWidth={"86px"}>
                {/* back button */}
                {openForm !== "chooseAccountType" &&
                  openForm !== "EmailVerify" &&
                  openForm !== "Profile" && (
                    <Button
                      disableRipple={true}
                      sx={{
                        minWidth: "unset",
                        backgroundColor: darkMode ? "grey.550" : "white.1000",
                        flexShrink: "0",
                        padding: "10px",
                        borderRadius: "10px",
                        border: darkMode ? "none" : "1px solid",
                        borderColor: "grey.1100",
                      }}
                      onClick={() => {
                        setOpenForm(
                          openForm === "Registration"
                            ? "chooseAccountType"
                            : openForm === "Verify"
                            ? "Registration"
                            : "Registration"
                        );
                      }}
                    >
                      <ArrowBackIosNewRoundedIcon
                        sx={{
                          color: darkMode ? "white.1000" : "black.1000",
                          marginLeft: "0",
                        }}
                        fontSize="18px"
                      />
                    </Button>
                  )}
              </Box>
              <Link to="/">
                <img src={ploomLogo} alt="" width={"201px"} height={"100%"} />
              </Link>
              <Box minWidth={"86px"}></Box>
            </Box>
            {/* form */}
            {openForm === "chooseAccountType" && (
              <AccountTypeForm openNextForm={openNextForm} />
            )}
            {openForm === "Registration" && (
              <SignupForm openNextForm={openNextForm} />
            )}
            {openForm === "Verify" && (
              <VarifyOtpForm openNextForm={openNextForm} />
            )}
            {openForm === "EmailVerify" && (
              <EmailVerifyForm openNextForm={openNextForm} />
            )}
            {openForm === "Profile" && (
              <SetProfileForm openNextForm={openNextForm} />
            )}
          </Box>

          {/* verticle text */}
          <Box
            sx={{
              maxHeight: "120vh",
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              gap: "105px",
              transform: "rotate(180deg)",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: " 64px",
                fontWeight: 800,
                lineHeight: "normal",
                textTransform: " capitalize",
                background: darkMode
                  ? palette.gradients["verticleTextBg"]
                  : palette.gradients["main"],
                color: "transparent",
                backgroundClip: "text",
                opacity: 0.1,
                writingMode: "vertical-rl",
                textOrientation: "mixed",
              }}
            >
              {openForm === "chooseAccountType" ? "Registration" : openForm}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: " 64px",
                fontWeight: 800,
                lineHeight: "normal",
                textTransform: " capitalize",
                background: darkMode
                  ? palette.gradients["verticleTextBg"]
                  : palette.gradients["main"],
                color: "transparent",
                backgroundClip: "text",
                opacity: 0.1,
                writingMode: "vertical-rl",
                textOrientation: "mixed",
              }}
            >
              {openForm === "chooseAccountType" ? "Registration" : openForm}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: " 64px",
                fontWeight: 800,
                lineHeight: "normal",
                textTransform: " capitalize",
                background: darkMode
                  ? palette.gradients["verticleTextBg"]
                  : palette.gradients["main"],
                color: "transparent",
                backgroundClip: "text",
                opacity: 0.1,
                writingMode: "vertical-rl",
                textOrientation: "mixed",
              }}
            >
              {openForm === "chooseAccountType" ? "Registration" : openForm}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
