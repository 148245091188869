import { createSvgIcon } from "@mui/material";
export default function PersonIcon(props) {
  let color = props.color;
  const CreateChatIcon = createSvgIcon(
    <>
      <path
        className="personIcon"
        d="M6.94759 6.70833C6.94759 4.19846 8.9898 2.15625 11.4997 2.15625C14.0096 2.15625 16.0518 4.19846 16.0518 6.70833C16.0518 9.21821 14.0096 11.2604 11.4997 11.2604C8.9898 11.2604 6.94759 9.21821 6.94759 6.70833ZM14.3747 12.6979H8.62467C5.58676 12.6979 3.11426 15.1704 3.11426 18.2083C3.11477 18.9071 3.39259 19.5772 3.88671 20.0713C4.38084 20.5654 5.05087 20.8432 5.74967 20.8438H17.2497C17.9485 20.8432 18.6185 20.5654 19.1126 20.0713C19.6068 19.5772 19.8846 18.9071 19.8851 18.2083C19.8851 15.1704 17.4126 12.6979 14.3747 12.6979Z"
        fill={color}
      />
    </>
  );
  return (
    <CreateChatIcon
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...props }}
    />
  );
}
