import React, { useState } from "react";
import Input from "./Input";
import { IconButton, InputAdornment, alpha, Stack, Box } from "@mui/material";
import HidePasswordIcon from "../../../icons/HidePasswordIcon";
import ShowPasswordIcon from "../../../icons/ShowPasswordIcon";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

export default function PasswordInput({
  name,
  register,
  errors,
  label,
  icon,
  serverError,
  sx,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // if (!label) {
  //   label = name.replace(/([a-z])([A-Z])/g, "$1 $2");
  // }
  return (
    <Input
      name={name}
      type={showPassword ? "text" : "password"}
      label={label}
      register={register}
      error={Boolean(errors[name]) || Boolean(serverError)}
      helperText={
        (errors[name]?.message || serverError) && (
          <>
            <Stack
              component={"span"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ErrorOutlineRoundedIcon sx={{ fontSize: "16px", mr: "10px" }} />
              <Box component={"span"}>
                {errors[name]?.message || serverError}
              </Box>
            </Stack>
          </>
        )
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ zIndex: 2 }}>
            {icon}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ zIndex: 2 }}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <HidePasswordIcon
                  color={
                    darkMode
                      ? palette.white["1000"]
                      : alpha(palette.black["450"], 0.3)
                  }
                />
              ) : (
                <ShowPasswordIcon
                  color={
                    darkMode
                      ? palette.white["1000"]
                      : alpha(palette.black["450"], 0.3)
                  }
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        "& input,input:is(:hover,:focus)": {
          border: "none",
          zIndex: 2,
          color: darkMode ? "white.1000" : "black.1000",
          opacity: 1,
        },
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid",
          borderColor: darkMode ? "neutral.650" : "bgWhite.secondary",
        },
        "& label": {
          color: darkMode ? "white.1000" : "primary.main",
        },
        "& label.Mui-focused": {
          color: darkMode ? "white.1000" : "primary.main",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "white",
          },
          "&:hover fieldset": {
            borderColor: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: darkMode ? "white.1000" : "",
          },

          "& :-webkit-autofill": {
            BackgroundClip: "text",
            textFillColor: darkMode ? "#ffffff" : "black.1000",
            transition: "background-color 5000s ease-in-out 0s",
            boxShadow: ` inset 0 0 20px 20px ${
              darkMode ? "neutral.650" : "white.1000"
            }`,
          },
        },
        ...sx,
      }}
      {...rest}
    />
  );
}
