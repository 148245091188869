import React, { useEffect, useState } from "react";
// import CreatePostBtnContainer from "../component/createPost/CreatePostBtnContainer";
import Layout from "../component/Layout";
import DraftWarningDialog from "../component/commonDrawer/DraftWarningDialog";
import useApi from "../hooks/useApi";
import { APIS } from "../api/lists";
import { useExistDraft } from "../hooks/store/useExistDraft";
import { useAuth } from "../hooks/store/useAuth";
import { useSnack } from "../hooks/store/useSnack";
import { useNavigate } from "react-router-dom";

export default function CreateFeed() {
  const [openDraftPopup, setOpenDraftPopup] = useState(false);

  const { setDraft, setChecked } = useExistDraft();
  const { apiCall, isLoading } = useApi();
  const { isLoggedIn } = useAuth();
  const { setSnack } = useSnack();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [load, setLoad] = useState(true);
  // protect route from unauthorized access
  useEffect(() => {
    if (!isLoggedIn) {
      setSnack("Please login to create a Campaign", "warning");
      return navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check if draft exists or not
  useEffect(() => {
    (async function () {
      try {
        setLoad(true);
        const isDraftExist = await apiCall({
          url: APIS.DRAFT.LIST,
          params: { limit: 1 },
        });
        setLoad(false);
        if (isDraftExist.data.data.results.length > 0) {
          setOpenDraftPopup(true);
          setDraft(isDraftExist.data.data.results[0]);
        }
      } catch (error) {
        setLoad(false);
        setChecked();
        return navigate("/create/raise");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCall, setDraft, setChecked]);
  return (
    <Layout isLoading={isLoading}>
      {/* {!load && <CreatePostBtnContainer />} */}
      <DraftWarningDialog
        open={openDraftPopup}
        setOpen={setOpenDraftPopup}
      ></DraftWarningDialog>
    </Layout>
  );
}
