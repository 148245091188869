import { createSvgIcon } from "@mui/material";

export default function PloomLogo(props) {
  let CreateMenuIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_421_10848)">
        <path
          d="M28.2021 11.2216H31.7138V13.0197C32.5713 11.853 33.7419 10.9883 35.5794 10.9883C38.4649 10.9883 41.2144 13.2667 41.2144 17.453V17.4942C41.2144 21.6667 38.5058 23.9589 35.5794 23.9589C33.701 23.9589 32.5441 23.0942 31.7138 22.0922V29.7098H28.2021V11.2216ZM37.7163 17.4942V17.453C37.7163 15.3804 36.328 14.0079 34.681 14.0079C33.0341 14.0079 31.673 15.3804 31.673 17.453V17.4942C31.673 19.5667 33.0341 20.9393 34.681 20.9393C36.328 20.9393 37.7163 19.5804 37.7163 17.4942Z"
          fill="url(#paint0_linear_421_10848)"
        />
        <path
          d="M43.0518 6.69141H46.5634V23.711H43.0518V6.69141Z"
          fill="url(#paint1_linear_421_10848)"
        />
        <path
          d="M48.3467 17.5353V17.4942C48.3467 13.8981 51.2186 10.9883 55.0706 10.9883C58.9089 10.9883 61.7536 13.8569 61.7536 17.453V17.4942C61.7536 21.0902 58.8817 24 55.0297 24C51.1914 24 48.3467 21.1314 48.3467 17.5353ZM58.2828 17.5353V17.4942C58.2828 15.6549 56.9625 14.0491 55.0161 14.0491C53.0017 14.0491 51.8039 15.6138 51.8039 17.453V17.4942C51.8039 19.3334 53.1242 20.9393 55.0706 20.9393C57.085 20.9393 58.2828 19.3746 58.2828 17.5353Z"
          fill="url(#paint2_linear_421_10848)"
        />
        <path
          d="M62.7744 17.5353V17.4942C62.7744 13.8981 65.6464 10.9883 69.4983 10.9883C73.3366 10.9883 76.1814 13.8569 76.1814 17.453V17.4942C76.1814 21.0902 73.3094 24 69.4575 24C65.6191 24 62.7744 21.1314 62.7744 17.5353ZM72.7105 17.5353V17.4942C72.7105 15.6549 71.3902 14.0491 69.4439 14.0491C67.4294 14.0491 66.2316 15.6138 66.2316 17.453V17.4942C66.2316 19.3334 67.5519 20.9393 69.4983 20.9393C71.5127 20.9393 72.7105 19.3746 72.7105 17.5353Z"
          fill="url(#paint3_linear_421_10848)"
        />
        <path
          d="M77.8008 11.2216H81.3125V12.9922C82.1155 11.9491 83.1908 10.9883 84.9602 10.9883C86.58 10.9883 87.805 11.7157 88.4447 12.9648C89.5336 11.6883 90.8266 10.9883 92.5144 10.9883C95.1277 10.9883 96.693 12.5667 96.693 15.5863V23.7255H93.1813V16.753C93.1813 15.0785 92.4463 14.2138 91.1261 14.2138C89.8058 14.2138 89.0027 15.0785 89.0027 16.753V23.7255H85.4911V16.753C85.4911 15.0785 84.7561 14.2138 83.4358 14.2138C82.1155 14.2138 81.3125 15.0785 81.3125 16.753V23.7255H77.8008V11.2216Z"
          fill="url(#paint4_linear_421_10848)"
        />
        <path
          d="M62.8833 34.7588L63.5366 33.7568C64.2444 34.2784 64.993 34.5529 65.6464 34.5529C66.2861 34.5529 66.64 34.2784 66.64 33.8529V33.8255C66.64 33.3176 65.9594 33.1529 65.1972 32.9196C64.2444 32.6451 63.1827 32.2608 63.1827 31.0117V30.9843C63.1827 29.749 64.19 29.0078 65.4694 29.0078C66.2725 29.0078 67.1572 29.296 67.8377 29.749L67.2525 30.8059C66.6264 30.4215 65.973 30.1882 65.4422 30.1882C64.8705 30.1882 64.5439 30.4627 64.5439 30.8333V30.8608C64.5439 31.3411 65.238 31.5333 66.0002 31.7804C66.9394 32.0686 68.0011 32.5078 68.0011 33.6745V33.7019C68.0011 35.0608 66.953 35.747 65.6191 35.747C64.7072 35.7196 63.6864 35.3902 62.8833 34.7588Z"
          fill="url(#paint5_linear_421_10848)"
        />
        <path
          d="M69.1582 32.3981V32.3707C69.1582 30.5177 70.6146 28.9805 72.5882 28.9805C74.5618 28.9805 76.0182 30.4903 76.0182 32.3432V32.3707C76.0182 34.2099 74.5618 35.7471 72.5746 35.7471C70.6146 35.7471 69.1582 34.2373 69.1582 32.3981ZM74.5482 32.3981V32.3707C74.5482 31.2315 73.7315 30.2844 72.5746 30.2844C71.3904 30.2844 70.6418 31.2177 70.6418 32.3432V32.3707C70.6418 33.4962 71.4585 34.4432 72.6018 34.4432C73.7996 34.4432 74.5482 33.5099 74.5482 32.3981Z"
          fill="url(#paint6_linear_421_10848)"
        />
        <path
          d="M77.1611 32.3981V32.3707C77.1611 30.5315 78.5767 28.9805 80.5095 28.9805C81.7209 28.9805 82.4695 29.4334 83.082 30.0922L82.1564 31.0942C81.7072 30.6138 81.2309 30.2844 80.4959 30.2844C79.4206 30.2844 78.6311 31.2177 78.6311 32.3432V32.3707C78.6311 33.5236 79.4206 34.4432 80.5503 34.4432C81.2445 34.4432 81.7617 34.1275 82.2245 33.6471L83.1092 34.5256C82.4695 35.2393 81.7345 35.7471 80.4822 35.7471C78.5767 35.7471 77.1611 34.2373 77.1611 32.3981Z"
          fill="url(#paint7_linear_421_10848)"
        />
        <path
          d="M84.498 26.7031H86.0769V28.1169H84.498V26.7031ZM84.5661 29.1188H86.0361V35.611H84.5661V29.1188Z"
          fill="url(#paint8_linear_421_10848)"
        />
        <path
          d="M87.5332 33.7293V33.7018C87.5332 32.3018 88.6221 31.6155 90.1874 31.6155C90.9088 31.6155 91.4124 31.7254 91.916 31.8901V31.7254C91.916 30.8057 91.3579 30.3116 90.3235 30.3116C89.6021 30.3116 89.0713 30.4763 88.486 30.7234L88.0913 29.5567C88.799 29.241 89.4932 29.0352 90.514 29.0352C91.4804 29.0352 92.2018 29.2959 92.651 29.7626C93.1274 30.2293 93.3451 30.9155 93.3451 31.7665V35.6097H91.9024V34.7999C91.4668 35.3214 90.7999 35.7469 89.8063 35.7469C88.6085 35.7332 87.5332 35.0332 87.5332 33.7293ZM91.9432 33.2626V32.8234C91.5621 32.6724 91.0721 32.5626 90.4868 32.5626C89.534 32.5626 88.976 32.9744 88.976 33.6469V33.6744C88.976 34.3057 89.5204 34.6626 90.2282 34.6626C91.2082 34.6626 91.9432 34.0861 91.9432 33.2626Z"
          fill="url(#paint9_linear_421_10848)"
        />
        <path
          d="M95.1963 26.6328H96.6663V35.6093H95.1963V26.6328Z"
          fill="url(#paint10_linear_421_10848)"
        />
        <path
          d="M12.1683 19.8526C11.0114 19.8526 10.0586 18.9055 10.0586 17.7251C10.0586 16.5584 10.9978 15.5977 12.1683 15.5977C13.3389 15.5977 14.278 16.5447 14.278 17.7251C14.278 18.9055 13.3253 19.8526 12.1683 19.8526ZM12.1683 16.6545C11.583 16.6545 11.093 17.1349 11.093 17.7388C11.093 18.329 11.5694 18.8231 12.1683 18.8231C12.7672 18.8231 13.2436 18.3428 13.2436 17.7388C13.2436 17.1349 12.7536 16.6545 12.1683 16.6545Z"
          fill="url(#paint11_linear_421_10848)"
        />
        <path
          d="M11.0251 28.7206V25.701C7.14593 25.1383 4.1651 21.7892 4.1651 17.7265C4.1651 13.6637 7.14593 10.301 11.0251 9.75198V6.74609C5.43093 7.32256 1.2251 12.1402 1.2251 17.699C1.2251 19.6206 1.2251 35.9951 1.2251 35.9951C7.66315 35.9951 7.51343 30.4363 7.51343 28.2128C8.65676 28.5422 9.60954 28.6794 11.0251 28.7206Z"
          fill="url(#paint12_linear_421_10848)"
        />
        <path
          d="M12.2637 6.70312C12.2637 6.70312 18.6609 6.85411 19.3142 14.1149C18.7698 12.8384 16.4287 9.69528 12.2637 9.66783C12.2637 9.44822 12.2637 6.70312 12.2637 6.70312Z"
          fill="url(#paint13_linear_421_10848)"
        />
        <path
          d="M12.2636 28.7868C12.2636 28.7868 14.6456 28.8143 16.9867 27.6339C20.3486 25.9319 24.2822 21.4025 22.7442 14.8555C22.3903 21.0182 16.905 25.7947 12.25 25.7947C12.2636 26.0006 12.2636 28.7868 12.2636 28.7868Z"
          fill="url(#paint14_linear_421_10848)"
        />
        <path
          d="M17.9805 8.36328C17.9805 8.36328 20.4985 10.8064 19.8588 15.4868C20.2671 16.9554 20.4169 19.0966 19.3007 21.375C20.8932 19.3711 24.3369 12.577 17.9805 8.36328Z"
          fill="url(#paint15_linear_421_10848)"
        />
        <path
          d="M25.7116 -0.554688H25.1943V36.2434H25.7116V-0.554688Z"
          fill="url(#paint16_linear_421_10848)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_421_10848"
          x1="34.7083"
          y1="10.9883"
          x2="34.7083"
          y2="29.7098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_421_10848"
          x1="44.8076"
          y1="6.69141"
          x2="44.8076"
          y2="23.711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_421_10848"
          x1="55.0502"
          y1="10.9883"
          x2="55.0502"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_421_10848"
          x1="69.4779"
          y1="10.9883"
          x2="69.4779"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_421_10848"
          x1="87.2469"
          y1="10.9883"
          x2="87.2469"
          y2="23.7255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_421_10848"
          x1="65.4422"
          y1="29.0078"
          x2="65.4422"
          y2="35.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_421_10848"
          x1="72.5882"
          y1="28.9805"
          x2="72.5882"
          y2="35.7471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_421_10848"
          x1="80.1352"
          y1="28.9805"
          x2="80.1352"
          y2="35.7471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_421_10848"
          x1="85.2875"
          y1="26.7031"
          x2="85.2875"
          y2="35.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_421_10848"
          x1="90.4392"
          y1="29.0352"
          x2="90.4392"
          y2="35.7469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_421_10848"
          x1="95.9313"
          y1="26.6328"
          x2="95.9313"
          y2="35.6093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_421_10848"
          x1="12.1683"
          y1="15.5977"
          x2="12.1683"
          y2="19.8526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_421_10848"
          x1="6.1251"
          y1="6.74609"
          x2="6.1251"
          y2="35.9951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_421_10848"
          x1="15.7889"
          y1="6.70313"
          x2="15.7889"
          y2="14.1149"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_421_10848"
          x1="17.6688"
          y1="14.8555"
          x2="17.6688"
          y2="28.7869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_421_10848"
          x1="19.826"
          y1="8.36328"
          x2="19.826"
          y2="21.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_421_10848"
          x1="25.4529"
          y1="-0.554687"
          x2="25.4529"
          y2="36.2434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5235E1" />
          <stop offset="0.526042" stopColor="#B02D96" />
          <stop offset="1" stopColor="#EF6231" />
        </linearGradient>
        <clipPath id="clip0_421_10848">
          <rect width="98" height="36" fill="white" />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateMenuIcon
      width="98"
      height="36"
      viewBox="0 0 98 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
          background: "transparent",
        },
      }}
      {...props}
    />
  );
}
