import React from "react";

export default function CongratsSvgLight() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 430 377"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M177.225 169.946C177.225 169.946 172.765 182.977 173.719 184.681C174.647 186.384 180.138 192.009 181.143 192.989C182.123 193.996 184.624 201.118 184.624 201.118L194.446 200.266L191.12 187.906C191.12 187.906 186.248 171.056 183.283 171.056C180.318 171.056 177.225 169.946 177.225 169.946Z"
        fill="#F7A491"
      />
      <path
        d="M39.2002 208.653L55.2096 212.498C51.8324 201.196 48.9193 189.274 48.7904 185.945C48.6099 181.249 38.8393 174.204 35.8488 174.127C32.6263 174.024 31.6466 187.339 32.9356 190.074C33.5801 191.468 40.2829 192.293 40.2829 192.293C40.2829 192.293 39.3806 199.802 39.2002 208.653Z"
        fill="#F7A491"
      />
      <path
        d="M41.3651 176.423C40.0761 175.494 38.5809 174.927 37.0857 174.385C37.3177 175.03 37.4724 175.701 37.6528 176.346C38.2458 178.694 39.0192 181.223 38.8645 185.223C38.8129 186.435 38.5293 188.164 37.4466 189.558C37.1888 189.867 36.4154 190.512 35.3584 191.338C36.9825 191.803 37.8591 191.932 38.7356 192.061C40.1277 190.719 42.4221 188.448 42.551 185.352C42.6542 182.513 42.087 179.184 41.3651 176.423Z"
        fill="#F47458"
      />
      <path
        d="M27.9599 197.351L25.8975 195.235L26.0779 195.131C27.9599 193.919 35.565 189.351 36.3899 188.448C37.3954 187.364 37.6789 186.023 37.7305 185.094C37.8852 181.971 37.1891 180.01 36.6477 178.204C36.261 176.862 35.8228 175.34 34.6111 174.952C33.6573 174.746 28.9395 175.081 28.0114 175.107L27.9599 172.372C29.6871 172.32 34.2244 171.933 35.797 172.398C39.0453 173.353 39.5351 176.32 40.1538 178.281C40.9014 180.526 41.2108 182.849 41.0819 185.171C40.9788 187.055 40.2827 188.758 39.0968 190.048C38.3234 190.9 35.565 192.68 30.3832 195.854C29.4551 196.422 28.6301 196.912 28.1145 197.247L27.9599 197.351Z"
        fill="#7473E8"
      />
      <path
        d="M30.6922 189.661C28.9134 181.275 29.8415 167.728 29.8415 167.728L10.6869 168.476C10.6869 168.476 12.6462 181.894 11.5376 190.409C10.4291 198.899 10.1455 199.802 10.1455 199.802C10.1713 200.783 15.2757 201.402 21.5403 201.144C27.8048 200.912 32.8577 199.905 32.8062 198.925C32.8062 198.925 32.4453 198.048 30.6922 189.661Z"
        fill="#7473E8"
      />
      <path
        d="M29.0673 168.501L11.4854 169.172C11.6658 170.54 12.0267 173.43 12.2845 176.862L28.8869 176.217C28.8611 172.759 28.99 169.869 29.0673 168.501Z"
        fill="#FCCAA7"
      />
      <path
        d="M12.2845 176.862L14.579 176.785C14.579 176.191 14.7594 175.752 15.275 175.572C16.6414 175.082 17.1054 176.475 18.7553 176.604L19.6318 176.578C19.9068 176.544 20.2248 176.466 20.5857 176.346C22.9317 175.546 22.9832 172.527 22.9832 172.527C25.9737 171.727 27.9588 170.927 29.0158 169.895C29.0416 169.327 29.0673 168.863 29.0673 168.501L11.4854 169.172C11.6658 170.54 12.0267 173.43 12.2845 176.862Z"
        fill="#407BFF"
      />
      <path
        d="M28.8886 176.217L12.2863 176.862C12.6214 181.197 12.7761 186.384 12.2347 190.512C11.4355 196.576 11.0746 198.821 10.9199 199.621C11.2035 199.776 12.0542 200.06 14.1682 200.266C16.2048 200.473 18.8086 200.524 21.5155 200.421C24.2224 200.292 26.8004 200.06 28.8371 199.699C30.9252 199.337 31.7244 198.976 32.0338 198.795C31.8018 198.021 31.2604 195.802 29.9972 189.816C29.1464 185.739 28.8886 180.552 28.8886 176.217Z"
        fill="#407BFF"
      />
      <path
        d="M15.0704 181.998C15.6375 180.578 14.5806 178.23 14.5806 176.785L12.2861 176.863C12.4408 178.979 12.5697 181.301 12.5955 183.597C13.6783 183.443 14.7352 182.849 15.0704 181.998Z"
        fill="#E27023"
      />
      <path
        d="M18.7559 176.603C19.0137 176.638 19.3058 176.629 19.6324 176.578L18.7559 176.603Z"
        fill="#B75742"
      />
      <path
        d="M22.9321 171.495C22.9321 171.495 22.9063 174.23 20.5346 174.901C18.137 175.598 15.8942 173.843 14.0895 174.411C12.3107 174.978 14.6052 178.746 13.806 180.526C13.0326 182.281 9.83584 182.255 9.34602 179.752C8.88198 177.249 10.8928 176.165 9.86162 173.456C8.8562 170.721 7.36096 168.708 9.21712 165.844C11.2537 162.747 15.9973 166.489 19.2713 164.321C23.8344 161.328 30.6146 163.599 29.8412 167.727C29.5318 169.405 27.2632 170.437 22.9321 171.495Z"
        fill="#E9FAFF"
      />
      <path
        d="M16.1515 186.306C16.2031 188.061 15.3781 189.506 14.2696 189.557C13.1868 189.583 12.233 188.215 12.1556 186.461C12.1041 184.732 12.929 183.287 14.0376 183.235C15.1203 183.209 16.0742 184.577 16.1515 186.306Z"
        fill="#E9FAFF"
      />
      <path
        d="M16.5657 193.248C16.5142 194.125 15.9986 194.822 15.4056 194.796C14.8127 194.745 14.3744 193.996 14.426 193.119C14.4776 192.215 14.9932 191.518 15.5861 191.57C16.179 191.596 16.6173 192.344 16.5657 193.248Z"
        fill="#E9FAFF"
      />
      <path
        d="M35.8488 174.126C35.8488 174.126 43.1961 180.345 42.1649 182.152C41.1079 183.958 34.8176 179.132 34.8176 179.132C34.8176 179.132 41.0048 183.313 41.7524 184.835C42.5258 186.358 41.4946 188.164 38.9166 186.796C36.3386 185.429 34.6371 183.648 34.6371 183.648C34.6371 183.648 40.7985 187.519 40.8759 189.325C40.9274 191.157 32.8067 188.861 32.8067 188.861C32.8067 188.861 31.4146 176.758 35.8488 174.126Z"
        fill="#F7A491"
      />
      <path
        d="M39.4316 168.579C39.4316 168.579 37.163 168.682 37.2661 170.798C37.3434 172.94 40.8495 177.12 40.8495 177.12L42.8088 181.094L48.7124 185.249C48.7124 185.249 47.3719 178.204 44.9486 175.882C42.5252 173.533 39.4316 168.579 39.4316 168.579Z"
        fill="#F7A491"
      />
      <path
        d="M206.358 249.269C206.358 249.269 250.7 251.513 252.53 251.565C256.861 251.694 255.392 251.591 257.686 251.694C257.807 251.711 257.867 251.72 257.867 251.72L257.841 255.797C252.969 260.21 238.558 347.609 237.604 347.557C236.547 347.505 206.565 345.312 208.086 344.151C216.902 337.39 229.174 281.137 229.947 275.563C217.934 275.899 202.027 271.357 202.027 271.357L206.358 249.269Z"
        fill="#407BFF"
      />
      <path
        d="M225.744 338.525L193.468 374.419L226.75 374.47L239.537 365.387L256.294 343.144L225.744 338.525Z"
        fill="#79A2FF"
      />
      <path
        d="M239.072 340.486L238.376 358.601L241.057 374.47H270.292L274.184 334.242L239.072 340.486Z"
        fill="#79A2FF"
      />
      <path
        d="M257.711 251.746L272.509 252.572C272.509 252.572 272.844 252.675 272.947 252.701C274.159 252.933 273.05 296.052 271.993 312.154C270.911 328.256 268.745 351.944 268.745 351.944C268.745 351.944 243.764 349.957 237.603 347.557C235.051 346.577 220.64 342.99 220.64 342.99C220.64 342.99 238.711 310.451 241.882 296.645C244.332 285.988 248.766 268.545 250.493 264.597C254.463 255.539 257.711 251.746 257.711 251.746Z"
        fill="#3362CC"
      />
      <path
        d="M272.096 239.515L272.509 252.572C272.509 252.572 262.944 269.086 258.02 266.79C253.896 264.854 257.556 252.288 257.556 252.288C257.556 252.288 258.278 249.423 259.258 246.12C259.954 243.798 260.521 241.295 260.495 239.463C260.392 234.999 272.096 239.515 272.096 239.515Z"
        fill="#F7A491"
      />
      <path
        d="M260.652 209.71C260.652 209.71 255.779 217.065 255.418 223.258C255.057 229.451 256.372 233.683 256.372 233.683L272.021 214.949L260.652 209.71Z"
        fill="#271651"
      />
      <path
        d="M253.793 221.994C253.561 224.162 254.412 226.019 255.675 226.148C256.938 226.277 258.124 224.626 258.356 222.458C258.562 220.291 257.737 218.433 256.474 218.304C255.211 218.175 254.025 219.826 253.793 221.994Z"
        fill="#F48C7F"
      />
      <path
        d="M277.071 217.968C277.432 217.89 277.406 235.902 272.173 240.159C266.966 244.391 257.865 244.778 255.21 240.495C252.271 235.773 255.081 216.781 262.841 212.729C270.601 208.704 277.071 217.968 277.071 217.968Z"
        fill="#F7A491"
      />
      <path
        d="M278.387 206.77C282.383 210.279 284.317 212.834 286.998 219.052C290.323 226.691 283.749 234.612 278.232 237.761C272.716 240.909 272.251 244.779 272.251 244.779L272.019 242.38C271.787 240.496 272.355 239.438 272.87 238.302C273.36 237.296 274.855 233.864 275.448 232.961C276.995 230.561 277.304 230.045 276.531 227.774C275.964 226.02 276.634 222.149 275.732 219.259L268.307 217.659C268.307 217.659 263.641 216.549 261.295 213.84C254.566 206.047 267.895 197.377 278.387 206.77Z"
        fill="#263238"
      />
      <path
        d="M274.779 229.735C273.851 231.722 274.057 233.76 275.191 234.276C276.351 234.818 278.027 233.631 278.929 231.67C279.857 229.683 279.651 227.645 278.517 227.129C277.357 226.587 275.681 227.748 274.779 229.735Z"
        fill="#F7A491"
      />
      <path
        d="M325.359 180.01C324.534 183.468 323.013 188.964 323.013 188.964C323.116 190.461 323.58 196.757 324.096 204.447L312.804 199.905C312.804 199.905 312.933 187.39 312.443 186.616C312.005 185.971 311.283 186.771 307.828 181.816C306.823 180.397 306.643 177.817 306.643 177.817C306.643 177.817 306.436 174.669 307.158 174.385C307.854 174.101 308.653 174.565 308.911 175.107C309.169 175.649 309.117 176.914 309.375 177.455C309.607 177.997 311.257 178.668 311.927 178.617C312.623 178.591 311.85 169.327 311.85 169.327C311.85 169.327 311.463 166.695 311.283 165.818C311.102 165.095 310.432 162.953 310.432 162.953C310.432 162.953 309.659 159.831 310.638 159.573C311.206 159.444 312.649 161.973 313.655 164.657C314.712 167.572 315.33 170.643 315.64 170.591C316.362 170.514 315.975 165.431 315.975 165.431C315.975 165.431 315.253 163.082 315.279 161.999C314.609 160.631 314.145 158.154 315.356 157.947C315.562 157.896 317.135 161.07 317.135 161.07C317.135 161.07 317.754 163.315 318.115 164.347C318.759 166.205 318.862 169.095 319.172 170.385C319.455 171.443 319.713 171.004 319.816 171.056C319.919 171.082 319.765 169.611 319.584 167.598C319.481 166.592 319.61 165.405 319.326 164.244C319.068 163.16 318.914 161.637 318.836 160.708C318.656 158.076 320.306 158.334 320.873 160.734C321.079 161.663 321.311 162.67 321.543 163.676C322.446 167.624 322.42 171.417 322.832 171.443C323.4 171.495 323.039 164.579 323.039 164.579C323.039 164.579 323.864 160.734 324.714 164.295C325.694 168.424 325.591 171.778 325.565 171.933C325.488 172.94 326.21 176.526 325.359 180.01Z"
        fill="#F8A492"
      />
      <path
        d="M272.536 252.598L313.527 228.522L311.696 189.79H325.102C325.102 189.79 331.985 237.218 329.588 242.147C327.139 247.23 299.58 268.158 294.063 277.241C290.892 282.453 284.266 356.614 283.261 357.234C276.12 360.95 254.104 361.646 254.619 359.401C268.953 297.11 272.536 252.598 272.536 252.598Z"
        fill="#407BFF"
      />
      <path
        d="M181.117 196.731C181.117 196.731 190.398 264.312 193.466 267.383C196.534 270.479 214.812 274.195 214.812 274.195L212.079 249.268L194.961 195.518L181.117 196.731Z"
        fill="#407BFF"
      />
      <path
        d="M143.298 374.471C143.298 374.471 144.587 368.536 145.257 366.652C147.448 360.511 156.446 343.145 156.446 343.145L197.771 344.564C197.771 344.564 204.732 360.924 206.253 366.652C206.871 368.897 207.516 374.471 207.516 374.471H143.298Z"
        fill="#3362CC"
      />
      <path
        d="M171.168 205.841C171.168 205.841 195.607 203.235 203.264 220.627C210.946 237.993 205.842 263.359 184.316 263.359C162.789 263.359 156.319 263.668 151.833 262.481C147.321 261.294 139.716 256.572 126.62 252.263C113.498 247.979 105.274 214.511 130.281 218.872C141.469 220.833 138.144 209.454 148.739 204.654C159.361 199.88 171.168 205.841 171.168 205.841Z"
        fill="#263238"
      />
      <path
        d="M399.631 148.116L397.053 150.645L392.541 146.929L388.288 150.155L384.472 147.91L383.338 149.845L388.468 152.838L392.49 149.793L397.182 153.664L401.075 149.871L399.631 148.116Z"
        fill="#7473E8"
      />
      <path
        d="M271.479 176.965L245.879 171.804C242.863 171.185 240.904 168.243 241.522 165.224L244.435 150.774C245.028 147.754 247.967 145.793 251.009 146.413L276.609 151.599C279.625 152.193 281.585 155.134 280.966 158.179L278.053 172.604C277.46 175.623 274.521 177.584 271.479 176.965Z"
        fill="#407BFF"
      />
      <path
        d="M244.127 158.102L231.03 167.108L245.003 167.211L244.127 158.102Z"
        fill="#407BFF"
      />
      <path
        d="M254.257 158.438L257.557 159.031C257.557 159.031 262.3 156.735 262.635 155.677C262.97 154.593 262.738 152.141 263.434 151.987C264.156 151.832 265.548 151.857 266.116 153.457C266.554 154.644 265.291 158.386 265.626 158.721C265.832 158.928 267.714 159.315 269.106 159.573C270.008 159.728 270.756 160.399 271.014 161.276C271.194 161.87 270.936 162.515 270.395 162.824L269.544 163.289L269.957 164.166C270.292 164.837 270.008 165.611 269.364 165.947L268.694 166.282L269.003 166.798C269.415 167.469 269.132 168.346 268.41 168.682L267.817 168.94L267.869 169.095C268.075 169.74 267.74 170.462 267.121 170.72L266.528 170.953L257.763 169.404C256.938 169.275 256.139 168.966 255.443 168.501L252.452 167.443C252.117 167.34 251.937 167.005 252.014 166.669L253.458 159.005C253.535 158.618 253.896 158.386 254.257 158.438Z"
        fill="white"
      />
      <path
        d="M97.5658 174.333L95.426 171.366L91.7653 171.469C91.3528 171.469 91.1208 171.005 91.3528 170.669L93.4925 167.728L92.2809 164.296C92.152 163.883 92.5129 163.521 92.9254 163.65L96.3799 164.76L99.2672 162.541C99.6024 162.309 100.066 162.541 100.066 162.954V166.592L103.083 168.656C103.418 168.914 103.341 169.431 102.954 169.56L99.4735 170.669L98.4423 174.179C98.3134 174.566 97.7978 174.669 97.5658 174.333Z"
        fill="#7473E8"
      />
      <path
        d="M330.001 135.678L327.113 135.033L327.681 130.26L323.556 128.763L323.401 125.125L321.545 125.202L321.777 130.079L325.67 131.498L325.103 136.453L329.459 137.433L330.001 135.678Z"
        fill="#7473E8"
      />
      <path
        d="M88.31 146.026L86.1187 142.878H82.2775C81.8392 142.878 81.5814 142.388 81.865 142.027L84.1852 138.956L82.9993 135.292C82.8704 134.879 83.2571 134.492 83.6696 134.647L87.3046 135.911L90.424 133.64C90.7591 133.382 91.2747 133.64 91.249 134.079L91.1716 137.924L94.291 140.195C94.6519 140.453 94.5488 140.995 94.1363 141.124L90.4498 142.233L89.2639 145.897C89.135 146.31 88.5678 146.388 88.31 146.026Z"
        fill="#407BFF"
      />
      <path
        d="M219.608 142.491L220.794 143.136L219.221 146L223.346 147.523L222.882 148.787L217.313 146.723L219.608 142.491Z"
        fill="#7473E8"
      />
      <path
        d="M214.53 124.996L210.38 126.363L207.441 119.938L201.15 121.202L198.16 116.738L195.917 118.261L199.887 124.196L205.868 123.009L208.936 129.692L215.149 127.628L214.53 124.996Z"
        fill="#7473E8"
      />
      <path
        d="M429.535 206.898L425.745 207.414L424.044 210.821C423.838 211.208 423.27 211.208 423.09 210.821L421.44 207.389L417.676 206.795C417.238 206.718 417.083 206.202 417.393 205.892L420.151 203.26L419.532 199.493C419.455 199.054 419.919 198.744 420.306 198.951L423.657 200.757L427.06 199.002C427.447 198.796 427.885 199.131 427.808 199.57L427.137 203.312L429.844 206.021C430.154 206.331 429.973 206.847 429.535 206.898Z"
        fill="#E9FAFF"
      />
      <path
        d="M133.606 139.213L129.043 139.394L127.264 143.703L122.134 143.549L121.644 148.709L115.844 148.787L114.787 153.483L109.218 154.464L108.857 152.348L113.008 151.625L114.117 146.671L119.685 146.593L120.201 141.329L125.846 141.51L127.6 137.33L131.827 137.149L132.395 134.156L134.509 134.569L133.606 139.213Z"
        fill="#E9FAFF"
      />
      <path
        d="M76.5569 176.991C77.6397 182.152 74.3399 187.209 69.1838 188.293C64.0278 189.377 58.975 186.074 57.8922 180.913C56.8094 175.752 60.1093 170.694 65.2653 169.611C70.4213 168.527 75.4742 171.83 76.5569 176.991Z"
        fill="#407BFF"
      />
      <path
        d="M62.5566 181.481C63.1238 184.242 65.8307 185.997 68.5892 185.429C71.3476 184.835 73.1007 182.126 72.5335 179.391L62.5566 181.481Z"
        fill="#EFEFEF"
      />
      <path
        d="M162.842 125.538C162.842 125.538 163.358 123.963 165.188 123.37C166.838 122.828 168.385 123.783 168.875 125.305C169.442 127.06 168.565 129.64 165.162 132.737C160.573 132.195 158.356 130.621 157.815 128.866C157.325 127.344 158.021 125.667 159.671 125.15C161.502 124.557 162.842 125.538 162.842 125.538Z"
        fill="#407BFF"
      />
      <path
        d="M149.512 130.595C151.188 130.337 152.528 130.053 153.817 129.692C154.256 129.589 155.029 129.511 155.209 129.072C155.313 128.84 155.287 128.582 155.055 128.453C154.642 128.169 154.049 128.479 153.611 128.608C152.296 128.943 150.801 129.279 149.254 129.537C148.971 129.589 148.79 129.924 148.868 130.182C148.945 130.492 149.228 130.621 149.512 130.595Z"
        fill="#407BFF"
      />
      <path
        d="M172.353 128.118C172.971 127.963 173.599 127.8 174.235 127.628C174.828 127.473 175.575 127.395 176.091 127.034C176.478 126.776 176.374 126.208 175.885 126.157C175.24 126.105 174.57 126.389 173.977 126.57C173.341 126.724 172.714 126.896 172.095 127.086C171.425 127.266 171.657 128.299 172.353 128.118Z"
        fill="#407BFF"
      />
      <path
        d="M157.478 137.304C157.942 135.756 158.793 135.085 159.386 134.388C159.695 134.027 159.334 133.485 158.87 133.691C157.607 134.259 156.808 135.73 156.447 136.995C156.266 137.666 157.298 137.975 157.478 137.304Z"
        fill="#407BFF"
      />
      <path
        d="M165.627 138.698C165.421 138.053 165.343 137.408 165.446 136.737C165.472 136.324 165.962 135.085 165.163 135.085C164.415 135.085 164.364 136.324 164.312 136.866C164.261 137.563 164.338 138.285 164.57 138.956C164.776 139.627 165.833 139.369 165.627 138.698Z"
        fill="#407BFF"
      />
      <path
        d="M169.906 133.33C170.335 133.502 170.756 133.665 171.169 133.82C171.581 134.001 172.02 134.285 172.458 134.053C172.69 133.949 172.716 133.64 172.638 133.433C172.458 133.02 171.839 132.917 171.452 132.762C171.04 132.607 170.627 132.444 170.215 132.272C169.931 132.168 169.596 132.401 169.545 132.659C169.442 132.969 169.648 133.227 169.906 133.33Z"
        fill="#407BFF"
      />
      <path
        d="M157.505 122.803C155.314 119.939 155.34 119.913 155.005 119.732C154.67 119.526 154.205 119.835 154.309 120.248C154.412 120.635 154.386 120.558 156.706 123.577C156.887 123.809 157.299 123.783 157.48 123.603C157.737 123.371 157.686 123.035 157.505 122.803Z"
        fill="#407BFF"
      />
      <path
        d="M163.229 121.254C163.297 120.669 163.306 120.084 163.254 119.499C163.203 118.931 163.177 118.105 162.61 117.847C162.481 117.796 162.275 117.847 162.197 117.951C161.836 118.389 162.12 119.06 162.146 119.577C162.197 120.144 162.197 120.686 162.146 121.254C162.12 121.538 162.404 121.796 162.687 121.796C162.997 121.796 163.203 121.564 163.229 121.254Z"
        fill="#407BFF"
      />
      <path
        d="M171.478 119.654C171.504 119.396 171.246 119.216 171.014 119.19C170.524 119.138 170.086 119.706 169.777 120.016C169.416 120.377 169.08 120.764 168.797 121.177C168.616 121.409 168.745 121.797 168.977 121.926C169.261 122.08 169.545 121.977 169.725 121.745C170.911 120.222 171.401 120.325 171.478 119.654Z"
        fill="#407BFF"
      />
      <path
        d="M269.569 125.873L268.461 128.66L263.794 127.37L261.68 131.215L258.02 130.827L257.813 132.685L262.712 133.201L264.723 129.563L269.569 130.905L271.245 126.725L269.569 125.873Z"
        fill="#466EB6"
      />
      <path
        d="M199.758 261.088L221.49 254.276C221.568 252.934 221.619 251.798 221.697 250.973C222.418 242.173 219.583 210.305 219.428 209.066C219.273 207.828 204.811 199.7 206.358 198.332C207.904 196.99 214.195 200.938 214.839 200.164C216.334 198.384 213.138 179.624 214.659 179.882C217.469 180.346 217.134 191.778 218.5 191.778C219.866 191.752 217.727 179.804 219.995 178.979C222.264 178.179 220.949 190.487 221.954 190.978C222.96 191.468 224.79 179.546 226.904 180.759C228.064 181.43 225.022 191.313 226.569 191.262C228.142 191.21 231.674 183.985 232.911 184.888C234.174 185.765 228.709 191.855 228.657 196.035C228.606 200.241 226.75 201.945 228.657 208.628C230.591 215.285 241.186 257.14 236.443 262.714C232.576 267.229 208.729 279.486 206.693 278.48C198.959 274.609 199.758 261.088 199.758 261.088Z"
        fill="#F7A491"
      />
      <path
        d="M218.499 199.466C218.499 199.466 224.867 200.344 224.093 205.892C224.093 205.892 223.165 201.04 218.499 199.466Z"
        fill="#F47458"
      />
      <path
        d="M129.044 213.117C127.781 209.014 128.348 204.653 125.615 200.963C124.635 199.673 123.63 198.976 122.728 197.557C121.284 195.26 117.907 191.854 116.876 190.435C116.721 190.228 116.54 189.996 116.592 189.738C116.695 189.196 117.417 188.835 118.01 188.835C118.551 188.835 118.964 189.145 119.376 189.454C120.923 190.538 123.81 193.118 124.455 192.448C125.228 191.648 125.099 189.454 123.785 185.403C122.444 181.352 120.098 175.365 121.542 175.159C123.011 174.952 127.084 186.1 127.729 186.074C128.348 186.048 128.038 172.707 129.508 172.114C130.977 171.52 130.848 184.603 131.312 185.171C131.776 185.764 135.025 173.146 136.314 173.507C138.041 173.972 134.329 184.939 134.947 185.79C135.566 186.667 138.866 178.307 140.413 177.817C141.934 177.326 140.155 183.003 138.763 185.971C137.706 188.241 137.783 195.286 137.886 196.886C137.989 198.512 136.623 201.556 137.061 204.111C137.525 206.64 138.453 207.595 138.453 207.595C135.325 209.435 132.189 211.276 129.044 213.117Z"
        fill="#F8A492"
      />
      <path
        d="M223.656 272.415L217.417 252.236L190.065 261.422C190.065 261.422 162.609 259.796 159.335 261.964C149.667 268.363 157.298 308.696 158.072 327.636C156.448 332.358 148.997 350.266 148.997 350.266C148.997 350.266 162.351 357.904 182.64 356.175C194.757 355.143 203.651 352.356 203.651 352.356C203.651 352.356 197.077 341.596 196.071 333.7C200.428 314.269 205.533 284.44 205.533 284.44L223.656 272.415Z"
        fill="#79A2FF"
      />
      <path
        d="M177.717 243.772C177.717 243.772 176.583 257.01 175.113 260.674C173.618 264.338 173.85 277.55 181.816 279.924C189.808 282.298 189.653 265.37 189.988 261.448C190.323 257.526 190.684 244.701 190.684 244.701L177.717 243.772Z"
        fill="#F7A491"
      />
      <path
        d="M177.328 247.797C178.127 248.675 179.081 249.475 180.19 250.197C184.057 252.649 187.589 252.623 190.476 251.126C190.579 247.643 190.682 244.701 190.682 244.701L177.715 243.772C177.715 243.772 177.586 245.449 177.328 247.797Z"
        fill="#F47458"
      />
      <path
        d="M181.815 216.265C181.815 216.265 176.814 217.4 173.746 222.096C170.678 226.793 168.435 241.579 178.567 248.03C188.699 254.481 196.69 244.417 196.819 235.489C196.974 226.586 195.066 214.716 181.815 216.265Z"
        fill="#F7A491"
      />
      <path
        d="M199.472 231.618C199.266 233.837 198.028 235.54 196.739 235.411C195.45 235.282 194.574 233.399 194.78 231.179C195.012 228.96 196.224 227.257 197.513 227.36C198.802 227.489 199.678 229.399 199.472 231.618Z"
        fill="#F7A491"
      />
      <path
        d="M184.056 215.697C184.056 215.697 177.946 229.425 172.558 230.664C167.196 231.877 167.273 238.792 167.273 238.792C167.273 238.792 167.377 222.845 172.43 219.181C177.457 215.517 184.056 215.697 184.056 215.697Z"
        fill="#263238"
      />
      <path
        d="M174.855 233.889C175.551 236.134 174.907 238.327 173.411 238.792C171.89 239.282 170.112 237.863 169.39 235.618C168.694 233.399 169.338 231.205 170.833 230.715C172.354 230.251 174.133 231.67 174.855 233.889Z"
        fill="#F7A491"
      />
      <path
        d="M112.622 260.983L135.773 251.952L126.053 207.775L139.562 203.259C139.562 203.259 155.649 256.519 155.881 259.925C156.139 263.332 125.074 287.51 125.074 287.51L112.622 260.983Z"
        fill="#407BFF"
      />
      <path
        d="M140.308 332.72C139.663 331.429 139.07 330.217 138.477 329.107C133.089 330.965 126.979 333.029 120.715 335.171L122.726 358.808C122.7 358.317 124.633 365.981 126.722 374.471H150.31C148.145 359.762 144.665 341.519 140.308 332.72Z"
        fill="#79A2FF"
      />
      <path
        d="M120.716 335.171C110.276 338.732 100.685 342.138 91.7139 345.389C93.5443 353.982 95.916 364.897 97.9526 374.471H128.399C128.708 364.304 128.502 353.466 127.445 344.512L120.716 335.171Z"
        fill="#79A2FF"
      />
      <path
        d="M116.771 261.087L109.32 261.139C109.32 261.139 96.1468 261.861 93.7234 262.171C93.5172 262.196 89.5213 309.367 89.2635 326.965C89.0315 344.589 91.8673 358.369 91.8673 358.369C91.8673 358.369 108.753 357.904 120.457 355.453C132.626 352.898 148.429 346.938 148.429 346.938C148.429 346.938 129.867 304.748 126.541 294.607C123.242 284.44 116.771 261.087 116.771 261.087Z"
        fill="#3362CC"
      />
      <path
        d="M93.7767 262.145L66.6818 230.689C66.6818 230.689 51.1107 194.537 50.6466 193.376C50.1826 192.241 39.1487 196.344 36.2872 198.073C35.5138 198.537 49.3319 246.43 49.3319 246.43L69.7497 269.576C69.7497 269.576 82.5881 287.846 82.949 300.232C83.542 320.823 79.0047 368.406 79.9843 369.181C85.7848 371.942 106.95 373.671 110.121 366.368C121.104 341.002 93.7767 262.145 93.7767 262.145Z"
        fill="#407BFF"
      />
      <path
        d="M91.25 248.546L93.7764 262.145C93.7764 262.145 103.805 278.737 108.78 276.286C112.957 274.221 109.502 261.5 109.502 261.5C109.502 261.5 108.187 258.558 107.105 255.204C106.357 252.856 105.713 250.301 105.738 248.417C105.738 243.876 91.25 248.546 91.25 248.546Z"
        fill="#F7A491"
      />
      <path
        d="M105.738 248.417C105.738 243.876 93.8789 248.701 93.8789 248.701V250.043C94.3945 250.869 94.9359 251.539 95.5804 252.03C98.7771 254.533 103.366 255.617 107.104 255.178C106.356 252.83 105.712 250.301 105.738 248.417Z"
        fill="#F47458"
      />
      <path
        d="M107.492 219.826C107.492 219.826 111.539 228.031 111.101 234.431C110.663 240.83 108.755 244.985 108.755 244.985L95.1689 223.722L107.492 219.826Z"
        fill="#271651"
      />
      <path
        d="M89.5746 226.122C89.2136 226.019 86.8934 244.52 91.6885 249.552C96.4836 254.584 105.764 256.158 109.038 252.133C112.673 247.668 112.313 227.799 104.862 222.638C97.4375 217.478 89.5746 226.122 89.5746 226.122Z"
        fill="#F7A491"
      />
      <path
        d="M89.6766 214.484C85.1393 217.555 82.8191 219.929 79.2357 225.941C74.8273 233.347 80.5505 242.353 85.7838 246.301C91.0429 250.249 90.9914 254.739 90.9914 254.739L91.8163 252.494C92.4093 250.662 89.7539 242.74 89.2641 241.734C88.0009 239.076 87.7431 238.508 88.8259 236.263C89.6766 234.534 89.4704 230.483 90.7851 227.645L98.6223 226.974C98.6223 226.974 103.52 226.432 106.305 223.954C114.219 216.858 101.69 206.227 89.6766 214.484Z"
        fill="#263238"
      />
      <path
        d="M90.3981 238.508C91.0684 240.676 90.6043 242.74 89.3669 243.127C88.1037 243.514 86.5569 242.095 85.8608 239.953C85.1905 237.811 85.6546 235.747 86.892 235.334C88.1552 234.947 89.702 236.366 90.3981 238.508Z"
        fill="#F7A491"
      />
      <path
        d="M136.236 277.756L117.675 259.022L109.271 261.061C109.271 261.061 118.01 268.08 122.728 282.014C127.42 295.922 147.966 360.33 147.966 360.33C147.966 360.33 155.932 357.543 157.299 353.182C158.665 348.821 136.236 277.756 136.236 277.756Z"
        fill="#407BFF"
      />
      <path
        d="M361.244 141.82C361.244 141.82 354.954 140.762 349.102 143.729C343.25 146.671 338.3 152.89 338.3 152.89L346.472 158.489C346.472 158.489 345.621 148.864 361.244 141.82Z"
        fill="#407BFF"
      />
      <path
        d="M361.476 146.697L362.482 141.381L358.718 137.536C358.28 137.098 358.537 136.349 359.156 136.272L364.493 135.627L366.993 130.827C367.277 130.285 368.05 130.311 368.308 130.853L370.602 135.756L375.913 136.633C376.532 136.737 376.764 137.485 376.3 137.924L372.355 141.588L373.18 146.929C373.258 147.548 372.613 147.987 372.072 147.703L367.354 145.097L362.533 147.497C361.966 147.781 361.347 147.29 361.476 146.697Z"
        fill="#407BFF"
      />
      <path
        d="M15.3027 240.159C15.3027 240.159 21.5931 239.101 27.4451 242.069C33.2972 245.011 38.247 251.204 38.247 251.204L30.0747 256.803C30.0747 256.803 30.9254 247.204 15.3027 240.159Z"
        fill="#407BFF"
      />
      <path
        d="M15.0698 245.036L14.0643 239.72L17.8282 235.876C18.2665 235.437 18.0087 234.689 17.39 234.611L12.0535 233.94L9.55282 229.166C9.26924 228.625 8.49584 228.625 8.23804 229.192L5.94362 234.069L0.607143 234.972C0.0142023 235.076 -0.217817 235.824 0.246224 236.237L4.16479 239.927L3.36561 245.268C3.28827 245.888 3.93277 246.326 4.47415 246.042L9.1919 243.41L14.0128 245.836C14.5799 246.094 15.1987 245.63 15.0698 245.036Z"
        fill="#407BFF"
      />
      <path
        d="M290.555 125.125C290.555 126.595 289.369 127.782 287.899 127.782C286.43 127.782 285.244 126.595 285.244 125.125C285.244 123.654 286.43 122.467 287.899 122.467C289.369 122.467 290.555 123.654 290.555 125.125Z"
        fill="#E9FAFF"
      />
      <path
        d="M387.746 127.344C387.746 128.556 386.767 129.537 385.555 129.537C384.318 129.537 383.338 128.556 383.338 127.344C383.338 126.105 384.318 125.125 385.555 125.125C386.767 125.125 387.746 126.105 387.746 127.344Z"
        fill="#407BFF"
      />
      <path
        d="M310.354 140.142C310.354 141.381 309.349 142.413 308.111 142.413C306.848 142.413 305.843 141.381 305.843 140.142C305.843 138.904 306.848 137.897 308.111 137.897C309.349 137.897 310.354 138.904 310.354 140.142Z"
        fill="#407BFF"
      />
      <path
        d="M214.273 283.511L205.198 304.232C204.296 306.296 206.255 309.134 209.555 310.579C212.855 312.024 216.258 311.534 217.16 309.47L226.235 288.749L214.273 283.511Z"
        fill="#7473E8"
      />
      <path
        d="M203.47 326.501L201.794 325.752L209.915 307.225L211.59 307.948L203.47 326.501Z"
        fill="#7473E8"
      />
      <path
        d="M208.963 329.907L195.042 323.791C195.351 323.12 196.151 322.811 196.821 323.094L208.267 328.126C208.963 328.436 209.273 329.236 208.963 329.907Z"
        fill="#7473E8"
      />
      <path
        d="M209.709 310.243C206.693 308.927 204.914 306.321 205.739 304.463L214.659 284.078L225.564 288.851L216.644 309.237C215.819 311.12 212.7 311.559 209.709 310.243Z"
        fill="#407BFF"
      />
      <path
        d="M211.075 292.283L221.98 297.057L225.564 288.851L214.659 284.078L211.075 292.283Z"
        fill="#E9FAFF"
      />
      <path
        d="M158.611 262.583C158.611 262.583 152.295 262.531 148.995 266.918C143.968 273.55 137.806 329.88 140.204 331.145C148.17 335.299 196.508 321.159 198.441 323.068C200.349 324.978 205.531 326.913 205.531 326.913C205.531 326.913 215.25 318.475 214.94 314.837C214.451 308.902 192.976 311.405 192.976 311.405L154.177 315.353L158.611 262.583Z"
        fill="#F7A491"
      />
      <path
        d="M156.008 312.024L161.293 261.371C161.293 261.371 152.295 261.809 148.995 266.17C145.696 270.531 137.162 294.607 134.945 311.405C140.565 311.199 155.982 312.024 156.008 312.024Z"
        fill="#80A7FF"
      />
      <path
        d="M295.323 374.471C303.289 370.032 311.487 365.388 314.401 363.194C320.639 358.498 337.293 345.854 337.293 345.854L311.101 318.321C311.101 318.321 265.573 362.73 259.18 374.471H295.323Z"
        fill="#3362CC"
      />
      <path
        d="M309.658 374.471H344.435C344.487 368.717 344.306 362.988 343.61 358.756C340.672 340.951 332.138 328.436 331.081 318.269C331.081 318.192 330.978 317.108 331.081 317.057C331.081 317.082 303.832 324.333 303.832 324.333C303.832 324.333 301.46 334.862 303.084 339.816C303.058 339.79 305.74 356.098 309.658 374.471Z"
        fill="#3362CC"
      />
      <path
        d="M297.749 274.35L282.41 290.478L251.448 292.103C251.448 292.103 243.018 287.33 241.11 287.33C239.202 287.33 236.882 299.716 239.795 301.006C242.709 302.322 284.73 304.954 288.391 303.896C292.052 302.864 309.324 282.788 309.324 282.788L297.749 274.35Z"
        fill="#F48C7F"
      />
      <path
        d="M287.229 283.175L300.248 297.213L312.855 281.472L326.828 253.655C326.828 253.655 321.156 254.455 315.201 255.513C305.611 257.216 287.229 283.175 287.229 283.175Z"
        fill="#80A7FF"
      />
      <path
        d="M378.851 219.49C373.18 211.955 363.306 209.452 355.082 213.813C351.138 210.33 345.776 208.523 340.62 209.04C330.462 209.994 319.609 220.78 322.909 230.818C321.182 238.792 321.491 245.862 328.658 249.243C329.354 259.048 341.599 267.409 352.633 263.538C358.382 268.725 367.302 268.157 374.366 264.932C376.041 264.183 377.872 262.635 378.413 262.558C378.413 262.558 397.155 267.254 403.214 249.784C409.556 231.489 396.846 211.078 378.851 219.49Z"
        fill="#263238"
      />
      <path
        d="M305.895 287.975C306.23 282.35 305.56 270.454 306.05 268.648C306.032 268.493 306.007 268.338 305.972 268.183L321.054 254.533C321.105 254.533 326.467 253.655 327.164 253.733C331.675 254.352 346.318 256.055 349.128 257.397L349.18 279.589C347.813 281.679 332.139 326.32 340.131 339.274C341.703 341.829 296.485 338.448 295.325 333.468C294.81 331.3 305.225 299.355 305.895 287.975Z"
        fill="#79A2FF"
      />
      <path
        d="M338.119 248.727C338.119 248.727 336.392 257.526 336.237 261.036C336.082 264.571 327.059 270.635 319.996 269.68C312.958 268.725 324.868 259.849 326.002 256.52C327.137 253.217 329.328 242.792 329.328 242.792L338.119 248.727Z"
        fill="#F7A491"
      />
      <path
        d="M328.195 247.901C329.923 250.74 332.965 252.546 337.528 252.004C337.863 250.095 338.121 248.727 338.121 248.727L329.33 242.792C329.33 242.792 328.84 245.114 328.195 247.901Z"
        fill="#F47458"
      />
      <path
        d="M345.003 221.425C345.003 221.425 348.793 224.419 349.566 229.812C350.365 235.205 346.756 248.365 335.645 250.094C324.534 251.849 321.414 240.598 324.766 232.599C328.117 224.625 334.356 214.794 345.003 221.425Z"
        fill="#F7A491"
      />
      <path
        d="M323.993 230.096C323.89 232.212 324.793 233.967 325.953 234.019C327.139 234.07 328.17 232.393 328.273 230.251C328.376 228.135 327.499 226.38 326.314 226.355C325.128 226.303 324.097 227.98 323.993 230.096Z"
        fill="#F7A491"
      />
      <path
        d="M343.07 218.201C343.07 218.201 342.915 234.793 347.53 238.431C352.145 242.07 349.025 249.011 349.025 249.011C349.025 249.011 355.934 232.857 352.712 226.922C349.489 220.987 343.07 218.201 343.07 218.201Z"
        fill="#263238"
      />
      <path
        d="M344.332 241.476C342.914 243.153 342.605 245.295 343.687 246.301C344.744 247.282 346.781 246.74 348.199 245.088C349.643 243.411 349.926 241.269 348.869 240.263C347.787 239.282 345.776 239.824 344.332 241.476Z"
        fill="#F7A491"
      />
      <path
        d="M346.266 256.571C346.266 256.571 365.523 227.747 369.751 221.09C373.979 214.432 384.936 188.009 385.322 186.951C385.709 185.919 377.614 173.481 379.419 173.042C381.198 172.604 383.208 179.597 385.116 178.745C387.024 177.919 392.541 161.018 393.624 161.843C395.66 163.392 390.659 172.32 391.742 172.887C392.85 173.429 396.073 163.056 398.212 163.366C400.352 163.65 394.217 172.862 394.809 173.687C395.402 174.487 401.796 165.791 402.956 167.649C403.6 168.63 397.104 175.21 398.341 175.829C399.605 176.448 405.405 172.191 406.024 173.404C406.642 174.616 400.662 176.319 398.883 179.597C397.104 182.9 394.062 184.345 392.824 190.434C391.587 196.524 389.808 218.561 383.363 229.605C376.918 240.624 359.568 265.783 359.568 265.783L346.266 256.571Z"
        fill="#F48C7F"
      />
      <path
        d="M342.451 257.036C347.453 250.894 360.214 234.379 360.214 234.379C360.214 234.379 373.233 243.179 374.651 247.823C374.651 247.823 348.02 281.808 346.705 283.743C346.705 283.743 340.75 259.1 342.451 257.036Z"
        fill="#80A7FF"
      />
      <path
        d="M246.111 301.961C246.111 294.297 244.023 289.059 244.023 289.059L242.502 287.872L245.003 301.858C245.364 301.892 245.733 301.927 246.111 301.961Z"
        fill="#B75742"
      />
      <path
        d="M231.801 265.086L235.9 285.394C236.312 287.407 239.251 288.517 242.5 287.872C245.748 287.201 248.042 285.033 247.63 283.02L243.531 262.712L231.801 265.086Z"
        fill="#7473E8"
      />
      <path
        d="M246.318 302.993L244.693 303.328L241.007 285.162L242.657 284.827L246.318 302.993Z"
        fill="#7473E8"
      />
      <path
        d="M252.246 302.683L238.583 305.445C238.454 304.774 238.892 304.103 239.563 303.974L250.777 301.703C251.473 301.574 252.118 302.012 252.246 302.683Z"
        fill="#7473E8"
      />
      <path
        d="M242.451 287.536C239.486 288.129 236.779 287.123 236.419 285.291L232.371 265.293L243.07 263.125L247.117 283.123C247.478 284.955 245.39 286.942 242.451 287.536Z"
        fill="#407BFF"
      />
      <path
        d="M233.995 273.344L244.694 271.176L243.07 263.125L232.371 265.293L233.995 273.344Z"
        fill="#E9FAFF"
      />
      <path
        d="M182.383 180.449L180.424 180.397L181.068 159.16L183.002 159.212L182.383 180.449Z"
        fill="#E9FAFF"
      />
      <path
        d="M182.15 160.502L199.345 148.064H164.98L182.15 160.502Z"
        fill="#E9FAFF"
      />
      <path
        d="M189.86 154.257C187.927 154.36 185.993 154.154 184.06 153.663C180.089 152.554 176.119 151.831 172.227 152.631L179.986 158.256C181.275 159.185 183.028 159.185 184.343 158.256L189.86 154.257Z"
        fill="#A4E5FF"
      />
      <path
        d="M47.9648 150.283C47.9648 151.676 46.8563 152.786 45.4641 152.786C44.0978 152.786 42.9893 151.676 42.9893 150.283C42.9893 148.915 44.0978 147.806 45.4641 147.806C46.8563 147.806 47.9648 148.915 47.9648 150.283Z"
        fill="#7473E8"
      />
      <path
        d="M26.7735 265.602C26.7735 266.222 26.2579 266.738 25.6392 266.738C25.0205 266.738 24.5049 266.222 24.5049 265.602C24.5049 264.983 25.0205 264.467 25.6392 264.467C26.2579 264.467 26.7735 264.983 26.7735 265.602Z"
        fill="#9BC1F9"
      />
      <path
        d="M419.068 247.592C419.068 248.521 418.294 249.269 417.366 249.269C416.438 249.269 415.69 248.521 415.69 247.592C415.69 246.662 416.438 245.888 417.366 245.888C418.294 245.888 419.068 246.662 419.068 247.592Z"
        fill="#407BFF"
      />
      <path
        d="M251.448 292.103C251.448 292.103 251.062 289.781 248.844 288.052C246.834 286.478 240.543 283.898 239.77 284.672C237.656 286.814 241.678 287.407 243.482 288.955C244.952 290.22 245.132 290.942 245.132 290.942L251.448 292.103Z"
        fill="#F48C7F"
      />
      <path
        d="M239.718 289.24C239.718 289.24 244.668 296.517 245.493 298.839C246.318 301.187 242.709 298.271 242.709 298.271C242.709 298.271 245.751 300.181 245.287 301.161C244.848 302.116 241.316 300.052 241.316 300.052L244.359 301.781C244.359 301.781 244.539 303.664 239.795 301.007C235.052 298.349 239.718 289.24 239.718 289.24Z"
        fill="#F48C7F"
      />
      <path
        d="M180.037 162.592C180.372 162.102 182.279 160.553 182.279 163.185C182.279 165.843 180.604 174.642 180.604 174.642L177.227 169.946C177.227 169.946 178.928 164.218 180.037 162.592Z"
        fill="#F7A491"
      />
      <path
        d="M184.935 169.895C183.929 168.579 179.727 173.301 180.243 173.817C180.758 174.359 184.857 173.817 184.857 173.817L188.621 180.784C188.621 180.784 186.868 172.372 184.935 169.895Z"
        fill="#F7A491"
      />
      <path
        d="M189.753 181.817L172.017 181.301V180.991C172.042 180.294 172.61 179.752 173.306 179.778L188.567 180.217C189.263 180.242 189.779 180.81 189.779 181.507L189.753 181.817Z"
        fill="#E9FAFF"
      />
      <path
        d="M23.6035 375.735C23.6035 376.432 24.1707 377 24.8667 377H395.223C395.919 377 396.486 376.432 396.486 375.735C396.486 375.039 395.919 374.471 395.223 374.471H24.8667C24.1707 374.471 23.6035 375.039 23.6035 375.735Z"
        fill="#5858C6"
      />
      <path
        d="M325.435 82.8322L326.337 76.0457L321.259 71.4267C320.692 70.9107 320.975 69.9559 321.723 69.8011L328.477 68.5624L331.287 62.3178C331.597 61.5953 332.602 61.5695 332.963 62.2404L336.211 68.2786L343.043 69.0269C343.816 69.1043 344.152 70.0591 343.61 70.6268L338.892 75.5812L340.285 82.2903C340.439 83.0644 339.666 83.6837 338.97 83.3483L332.783 80.3808L326.827 83.7869C326.157 84.174 325.332 83.6063 325.435 82.8322Z"
        fill="#E9FAFF"
      />
      <path
        d="M265.394 79.374L268.204 81.5674L271.452 79.8901L274.469 82.5738L277.356 79.7095L280.888 82.5996L283.904 80.303L287.745 82.5222L286.895 83.993L284.033 82.3157L280.862 84.7413L277.459 81.9802L274.52 84.8703L271.195 81.9029L268.024 83.5543L265.42 81.5158L263.564 83.0382L262.507 81.7222L265.394 79.374Z"
        fill="#5858C6"
      />
      <path
        d="M147.426 89.8508C150.21 93.9279 149.153 99.4758 145.08 102.237C141.032 105.024 135.489 103.966 132.705 99.9144C129.947 95.8374 130.978 90.2895 135.051 87.5284C139.124 84.7416 144.641 85.7995 147.426 89.8508Z"
        fill="#407BFF"
      />
      <path
        d="M136.933 98.7271C138.428 100.895 141.367 101.462 143.558 99.9915C145.724 98.4949 146.291 95.5274 144.796 93.3599L136.933 98.7271Z"
        fill="#EFEFEF"
      />
      <path
        d="M257.196 0L256.551 3.14811L251.369 2.68365L249.874 7.17359L245.93 7.45743L246.059 9.44435L251.343 9.08308L252.761 4.82538L258.149 5.31567L259.129 0.567681L257.196 0Z"
        fill="#E9FAFF"
      />
      <path
        d="M396.77 93.9281L399.966 93.9023C399.966 93.9023 404.014 90.8832 404.143 89.7994C404.297 88.7157 403.653 86.4191 404.272 86.1352C404.916 85.8772 406.231 85.645 407.056 87.09C407.649 88.148 407.107 91.9412 407.468 92.1992C407.7 92.3541 409.557 92.4057 410.897 92.4057C411.774 92.4057 412.573 92.9217 412.985 93.7217C413.243 94.2636 413.114 94.9087 412.65 95.2958L411.928 95.8892L412.496 96.6634C412.908 97.2311 412.779 98.031 412.238 98.4697L411.671 98.8825L412.031 99.347C412.521 99.9147 412.418 100.818 411.799 101.231L411.284 101.592L411.361 101.747C411.671 102.315 411.49 103.063 410.949 103.424L410.433 103.76L401.926 103.811C401.127 103.811 400.327 103.656 399.605 103.347L396.615 102.856C396.28 102.805 396.048 102.521 396.048 102.185L396.099 94.599C396.125 94.2378 396.409 93.9281 396.77 93.9281Z"
        fill="#407BFF"
      />
      <path
        d="M168.617 35.8676L171.659 36.8224C171.659 36.8224 176.454 35.1967 176.918 34.2161C177.382 33.2098 177.485 30.8358 178.155 30.7584C178.852 30.6809 180.166 30.8874 180.501 32.5131C180.759 33.7001 179.058 37.132 179.341 37.4933C179.522 37.7255 181.249 38.319 182.512 38.7577C183.363 39.0157 183.982 39.7383 184.111 40.6414C184.214 41.2349 183.879 41.8284 183.311 42.0606L182.461 42.3961L182.744 43.2993C182.951 43.9702 182.59 44.6927 181.945 44.9249L181.275 45.1571L181.481 45.699C181.765 46.4215 181.404 47.2215 180.682 47.4279L180.089 47.6085L180.115 47.7634C180.218 48.4343 179.805 49.0794 179.187 49.2342L178.594 49.4148L170.473 46.8344C169.725 46.6022 169.029 46.2151 168.411 45.6732L165.729 44.3056C165.42 44.1508 165.291 43.8153 165.394 43.4799L167.792 36.2805C167.895 35.945 168.282 35.7386 168.617 35.8676Z"
        fill="#E9FAFF"
      />
      <path
        d="M135.59 38.3448L135.512 33.3388L139.611 30.4488C140.075 30.1133 139.972 29.3908 139.405 29.2101L134.636 27.7135L133.14 22.9397C132.986 22.3978 132.264 22.2688 131.929 22.7333L129.041 26.8362L124.014 26.7587C123.447 26.7587 123.112 27.4039 123.473 27.8683L126.463 31.8938L124.839 36.6418C124.659 37.1837 125.174 37.6998 125.716 37.5191L130.459 35.8934L134.481 38.9126C134.945 39.248 135.59 38.9125 135.59 38.3448Z"
        fill="#7473E8"
      />
      <path
        d="M78.952 89.9797L81.1949 87.1412L80.1121 83.6835C79.9832 83.2706 80.3699 82.9093 80.7566 83.0642L84.1596 84.3027L87.0985 82.2126C87.4336 81.9804 87.8977 82.2384 87.8977 82.6513L87.743 86.2639L90.6561 88.4314C90.9913 88.6637 90.8882 89.2056 90.5015 89.3088L87.0212 90.2894L85.8611 93.7213C85.7064 94.1084 85.1908 94.1858 84.9588 93.8503L82.9479 90.8312L79.3387 90.7796C78.9262 90.7796 78.6942 90.3151 78.952 89.9797Z"
        fill="#5858C6"
      />
      <path
        d="M325.022 21.1853L327.265 18.3468L326.182 14.889C326.054 14.4761 326.44 14.1149 326.827 14.2697L330.23 15.5083L333.195 13.4182C333.53 13.1859 333.994 13.444 333.968 13.8568L333.839 17.4694L336.726 19.637C337.062 19.895 336.984 20.4111 336.572 20.5143L333.091 21.4949L331.931 24.9269C331.802 25.3397 331.261 25.3913 331.055 25.0559L329.018 22.0368L325.409 22.011C324.997 21.9852 324.765 21.5207 325.022 21.1853Z"
        fill="#407BFF"
      />
      <path
        d="M69.5164 23.4038L70.7538 19.9977L68.6657 17.0302C68.4336 16.6947 68.6914 16.2303 69.1039 16.2561L72.7131 16.3851L74.8786 13.495C75.1364 13.1596 75.652 13.2628 75.7552 13.6499L76.7348 17.1334L80.1636 18.2946C80.5503 18.4494 80.6276 18.9655 80.2925 19.1978L77.2762 21.2105L77.2246 24.8231C77.2246 25.2359 76.7348 25.4682 76.4255 25.2101L73.5896 22.9652L70.1093 24.0489C69.7226 24.1522 69.3617 23.7909 69.5164 23.4038Z"
        fill="#5858C6"
      />
      <path
        d="M28.1653 140.014L31.3105 141.717L34.2494 139.549L37.6266 141.717L40.0499 138.414L43.9943 140.71L46.5723 137.949L50.7228 139.523L50.1299 141.097L47.0363 139.91L44.3036 142.826L40.514 140.633L38.0649 143.962L34.3267 141.588L31.4394 143.704L28.5262 142.13L26.9537 143.91L25.6904 142.8L28.1653 140.014Z"
        fill="#407BFF"
      />
      <path
        d="M281.068 34.9135L281.325 37.5971L277.046 38.5261L276.969 42.4999L273.875 43.7128L274.468 45.2868L278.619 43.6611L278.696 39.8937L283.156 38.939L282.743 34.8877L281.068 34.9135Z"
        fill="#5858C6"
      />
      <path
        d="M65.5225 111.139L68.2294 111.113L68.7707 115.474L72.7151 115.912L73.6432 119.112L75.2673 118.648L74.0299 114.364L70.266 113.951L69.7246 109.41L65.6514 109.461L65.5225 111.139Z"
        fill="#E9FAFF"
      />
      <path
        d="M385.428 53.6729C391.28 57.5435 392.878 65.4138 389.037 71.2456C385.17 77.0773 377.307 78.703 371.481 74.8324C365.629 70.9875 364.03 63.1172 367.897 57.2855C371.738 51.4279 379.601 49.828 385.428 53.6729Z"
        fill="#A1BEFF"
      />
      <path
        d="M387.127 64.1494L382.126 66.7556L381.43 72.3551C381.378 72.7938 380.811 73.0002 380.502 72.6648L376.48 68.7168L370.937 69.7747C370.499 69.8779 370.138 69.4135 370.344 69.0006L372.871 63.943L370.138 59.0143C369.906 58.6015 370.241 58.137 370.705 58.1886L376.274 59.0402L380.141 54.9115C380.45 54.576 380.991 54.7567 381.069 55.1953L381.997 60.769L387.101 63.1688C387.514 63.3495 387.539 63.943 387.127 64.1494Z"
        fill="#7473E8"
      />
      <path
        d="M217.882 63.3754L213.886 66.059C213.474 66.3429 213.577 67.0654 214.067 67.2718L218.707 69.1555L220.151 74.2132C220.306 74.7551 220.976 74.9099 221.285 74.4712L224.095 70.4716L228.968 70.9102C229.509 70.9619 229.793 70.3684 229.483 69.8781L226.57 65.5171L228.143 60.7434C228.323 60.2273 227.833 59.6854 227.343 59.8144L222.729 61.1304L218.836 57.7242C218.424 57.363 217.805 57.6468 217.805 58.2145L217.882 63.3754Z"
        fill="#407BFF"
      />
    </svg>
  );
}
