export const formatNumberCount = (value) => {
  if (value >= 1000000000) {
    return (
      (value / 1000000000).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "B"
    );
  } else if (value >= 1000000) {
    return (
      (value / 1000000).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "M"
    );
  } else if (value >= 1000) {
    return (
      (value / 1000).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "K"
    );
  } else {
    return value?.toLocaleString();
  }
};
