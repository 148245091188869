import { createSvgIcon } from "@mui/material";

export default function ReplyIcon(props) {
  let CreateAddIcon = createSvgIcon(
    <>
      <path
        d="M13.732 10.1298C13.7547 9.99668 13.7428 9.85993 13.6975 9.73268C13.6522 9.60543 13.575 9.49195 13.4732 9.40309L9.72324 6.40309C9.56768 6.28205 9.37073 6.22711 9.17498 6.25015C8.97923 6.27319 8.80042 6.37236 8.67722 6.52621C8.55402 6.68006 8.49634 6.87622 8.51665 7.07227C8.53695 7.26833 8.63362 7.4485 8.78574 7.57384L10.8662 9.23809H2.50449C2.31414 9.21227 2.13741 9.12507 2.00111 8.98972C1.86481 8.85437 1.77638 8.67825 1.74924 8.48809V0.988087C1.73352 0.800685 1.64797 0.626029 1.50954 0.49873C1.37112 0.371431 1.18992 0.300781 1.00186 0.300781C0.813802 0.300781 0.632605 0.371431 0.49418 0.49873C0.355756 0.626029 0.270206 0.800685 0.254486 0.988087V8.48809C0.283543 9.07543 0.529924 9.631 0.945748 10.0468C1.36157 10.4626 1.91714 10.709 2.50449 10.7381H10.8662L8.78574 12.4001C8.70877 12.4616 8.64467 12.5378 8.59712 12.6241C8.54956 12.7104 8.51948 12.8053 8.50858 12.9032C8.49768 13.0012 8.50618 13.1003 8.53359 13.195C8.56101 13.2896 8.6068 13.378 8.66836 13.455C8.79268 13.6104 8.97366 13.7101 9.17149 13.7321C9.26944 13.743 9.36859 13.7345 9.46325 13.7071C9.55792 13.6797 9.64627 13.6339 9.72324 13.5723L13.4732 10.5723C13.6032 10.4551 13.6936 10.3005 13.732 10.1298Z"
        fill="url(#paint0_linear_325_6604)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_325_6604"
          x1="6.99884"
          y1="0.300781"
          x2="6.99884"
          y2="13.7367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5235E1" />
          <stop offset="0.526042" stop-color="#B02D96" />
          <stop offset="1" stop-color="#EF6231" />
        </linearGradient>
      </defs>
    </>
  );
  return (
    <CreateAddIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
          ...props.sx,
        },
      }}
    />
  );
}
