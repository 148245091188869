import { create } from "zustand";

export const useSignin = create((set) => ({
  idToken: undefined,
  username: undefined,
  mobileNumber: undefined,
  code: 1,
  password: undefined,
  firstName: undefined,
  lastName: undefined,
  profilePicture: undefined,
  userId: undefined,
  normalLogin: false,
  mobileRequired: undefined,
  mobileVarification: undefined,
  email_verified: undefined,
  onboarding: undefined,
  email: undefined,
  accountType: undefined,
  orgName: undefined,
  EIN: undefined,

  setStep1(username, password, mobileNumber = undefined, code = undefined) {
    set(() => ({
      username,
      password,
      mobileNumber,
      code,
    }));
  },

  setOnBoarding(firstName, lastName, profilePicture = undefined) {
    set(() => ({
      firstName,
      lastName,
      profilePicture,
    }));
  },

  setUserId(id) {
    set(() => ({
      userId: id,
    }));
  },

  setIdToken(token) {
    set(() => ({
      idToken: token,
    }));
  },

  setMobileNumber(mobile_number, code) {
    set(() => ({
      mobileNumber: mobile_number,
      code: code,
    }));
  },

  setFlag(mobileRequired, mobileVarification, onboarding, email_verified) {
    set(() => ({
      mobileRequired,
      mobileVarification,
      onboarding,
      email_verified,
    }));
  },

  setNormalLogin(value) {
    set(() => ({
      normalLogin: value,
    }));
  },

  setEmail(value) {
    set(() => ({
      email: value,
    }));
  },

  setAccountTypeDetails(
    accountType = undefined,
    orgName = undefined,
    EIN = undefined
  ) {
    set(() => ({
      accountType: accountType,
      orgName: orgName,
      EIN: EIN,
    }));
  },

  resetStore() {
    set(() => ({
      accountType: undefined,
      EIN: undefined,
      orgName: undefined,
      email: undefined,
      mobileNumber: undefined,
      code: undefined,
      idToken: undefined,
      password: undefined,
      username: undefined,
    }));
  },
}));
