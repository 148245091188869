import { createSvgIcon } from "@mui/material";
import React from "react";

export default function GearIcon(props) {
  const CreateGearIcon = createSvgIcon(
    <>
      <g clipPath="url(#clip0_421_36270)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3411 7.34915L16.8224 6.44907C16.3836 5.68747 15.4112 5.42473 14.6485 5.86173V5.86173C14.2855 6.07559 13.8523 6.13626 13.4445 6.03037C13.0367 5.92449 12.6877 5.66073 12.4746 5.29728C12.3375 5.06626 12.2638 4.80313 12.261 4.5345V4.5345C12.2734 4.10382 12.1109 3.68647 11.8106 3.37753C11.5103 3.06858 11.0977 2.89435 10.6668 2.89453H9.62182C9.19971 2.89453 8.795 3.06273 8.49724 3.36192C8.19948 3.66111 8.03322 4.06663 8.03525 4.48873V4.48873C8.02274 5.36024 7.31264 6.06014 6.44104 6.06005C6.17241 6.05726 5.90929 5.98359 5.67827 5.84648V5.84648C4.91562 5.40948 3.94317 5.67221 3.50435 6.43381L2.94753 7.34915C2.50925 8.1098 2.7684 9.08164 3.52724 9.52306V9.52306C4.02049 9.80784 4.32435 10.3341 4.32435 10.9037C4.32435 11.4732 4.02049 11.9995 3.52724 12.2843V12.2843C2.76937 12.7228 2.50993 13.6922 2.94753 14.4506V14.4506L3.47384 15.3583C3.67944 15.7293 4.02441 16.003 4.4324 16.119C4.84039 16.235 5.27777 16.1835 5.64776 15.9761V15.9761C6.01147 15.7639 6.4449 15.7058 6.85169 15.8146C7.25848 15.9235 7.60493 16.1903 7.81404 16.5559C7.95115 16.7869 8.02483 17.05 8.02762 17.3186V17.3186C8.02762 18.1991 8.74137 18.9128 9.62182 18.9128H10.6668C11.5443 18.9128 12.2568 18.2037 12.261 17.3263V17.3263C12.259 16.9028 12.4263 16.4962 12.7257 16.1967C13.0251 15.8973 13.4318 15.73 13.8552 15.7321C14.1232 15.7392 14.3853 15.8126 14.618 15.9456V15.9456C15.3787 16.3839 16.3505 16.1248 16.7919 15.3659V15.3659L17.3411 14.4506C17.5537 14.0857 17.612 13.6511 17.5032 13.2431C17.3944 12.8351 17.1274 12.4873 16.7614 12.2767V12.2767C16.3954 12.0661 16.1284 11.7183 16.0196 11.3103C15.9108 10.9022 15.9691 10.4677 16.1817 10.1028C16.3199 9.86141 16.5201 9.6613 16.7614 9.52306V9.52306C17.5157 9.08188 17.7742 8.11571 17.3411 7.35677V7.35677V7.34915Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          fill="none"
          strokeLinejoin="round"
        />
        <circle
          cx="10.1499"
          cy="10.9038"
          r="2.1968"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          fill="none"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_421_36270">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.996094)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateGearIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
