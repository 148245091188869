import React from "react";
import { Alert, Snackbar, alpha } from "@mui/material";
import { useSnack } from "../../hooks/store/useSnack";
import Slide from "@mui/material/Slide";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";

export default function CustomSnack() {
  const { type, open, message, closeSnack } = useSnack();
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  let toastColor = "success.main";
  switch (type) {
    case "success":
      toastColor = "success.main";
      break;
    case "warning":
      toastColor = "warning.main";
      break;
    case "error":
      toastColor = "error.main";
      break;
    default:
      toastColor = "success.main";
      break;
  }

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnack();
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      TransitionComponent={Slide}
      // sx={{
      //   "& .alert ": {
      //     top: "80px",
      //   },
      // }}
      sx={{ zIndex: 10000 }}
    >
      <Alert
        // className="alert"
        onClose={handleClose}
        severity={type || "success"}
        sx={{
          maxWidth: "500px",
          width: "100%",
          borderRadius: "15px",
          fontSize: "14px",
          fontWeight: "600",
          color: darkMode ? "white.1000" : "black.350",
          backgroundColor: darkMode
            ? "grey.550"
            : alpha(palette.white["1000"], 0.9),
          backdropFilter: "blur(20px)",
          position: "relative",
          "&::before": {
            content: "''",
            position: "absolute",
            top: "50%",
            left: "0%",
            transform: "translateY(-50%) translateX(70%)",
            width: "4px",
            height: "70%",
            backgroundColor: toastColor,
            borderRadius: "3px",
          },
          "&::after": {
            content: "''",
            position: "absolute",
            top: "50%",
            left: "0%",
            transform: "translateY(-50%) translateX(130%)",
            width: "4px",
            height: "50%",
            backgroundColor: toastColor,
            borderRadius: "3px",
            opacity: 0.5,
          },
          "& :first-letter": {
            textTransform: "uppercase",
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
