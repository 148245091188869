import { alpha } from "@mui/material";
import React from "react";
import useThemeStore from "../../../hooks/store/useThemeStore";
import { useTheme } from "@emotion/react";

import { APIS } from "../../../api/lists";
import ListItemSkeleton from "../../skeleton/ListItemSkeleton";
import DonationListItem from "../../profilefeedcard/DonationListItem";
import CustomInfinite from "../../ui/CustomInfinite";
import { useImmersivePage } from "../../../hooks/store/useImmersivePage";

export default function FeedDonationList({ begId, title }) {
  const { darkMode } = useThemeStore();
  const { palette } = useTheme();
  const { begDetails } = useImmersivePage();
  return (
    <CustomInfinite
      url={APIS.FEED.DONATION_LIST(begId)}
      reRender={begDetails?.amountRaised || 0}
      listSx={{
        "& li:not(:last-child)": {
          borderBottom:
            "1px solid " +
            (darkMode
              ? alpha(palette.white[1000], 0.08)
              : alpha(palette.black[1000], 0.25)),
        },
      }}
      Skeleton={ListItemSkeleton}
      ListItem={({ item }) => (
        <DonationListItem
          sx={{
            "& li:not(:last-child)": {
              borderBottom:
                "1px solid " +
                (darkMode
                  ? alpha(palette.white[1000], 0.08)
                  : alpha(palette.black[1000], 0.25)),
            },
          }}
          item={{
            amount: item.amount,
            beg: { title: title },
            user: item.anonymous
              ? {}
              : item.donor
              ? item.donor
              : { username: item.donorName },
            createdAt: item.createdAt,
          }}
          key={item._id}
        />
      )}
    >
      Donor History is not available !
    </CustomInfinite>
  );
}
