import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const FollowerListItem = ({ item, closeComponent }) => {
  return (
    <>
      <Stack
        component={Link}
        to={`/profile/${item.userId}`}
        spacing={2}
        direction="row"
        alignItems="center"
        p={"6px"}
        borderRadius={"6px"}
        onClick={closeComponent}
        width={"100%"}
        mb={1}
        sx={{
          cursor: "pointer",
          transition: "all .3s ease-in",
          textDecoration: "none",
          color: "black.1000",
          ":hover": {
            background: "#2938f8",
            color: "#ffffff",
          },
        }}
      >
        <Avatar
          src={item?.users?.profileImage}
          sx={{
            backgroundColor: "primary.main",
            color: "white.1000",
          }}
        ></Avatar>
        <Typography noWrap textTransform={"capitalize"}>
          {item.users?.firstName} {item.users?.lastName}
        </Typography>
      </Stack>
    </>
  );
};

export default FollowerListItem;
