import { createSvgIcon } from "@mui/material";
import React from "react";

export default function SuccessIcon(props) {
  const CreateNextDoorIcon = createSvgIcon(
    <>
      <circle cx="27.5" cy="27.5" r="27.5" fill="#3BB54A" fillOpacity="0.15" />
      <g clipPath="url(#clip0_616_2971)">
        <path
          d="M27.5 8.9375C37.7518 8.9375 46.0625 17.2482 46.0625 27.4999C46.0625 37.7517 37.7518 46.0624 27.5 46.0624C17.2483 46.0624 8.93758 37.7517 8.93758 27.4999C8.90841 17.2773 17.1718 8.96667 27.3943 8.9375H27.5Z"
          fill="#368000"
        />
        <path
          d="M37.7831 22.3844L24.5996 35.5679L17.2168 28.2379L20.2227 25.2848L24.5996 29.609L34.7773 19.4313L37.7831 22.3844Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_616_2971">
          <rect
            width="37.125"
            height="37.125"
            fill="white"
            transform="translate(8.9375 8.9375)"
          />
        </clipPath>
      </defs>
    </>
  );
  return (
    <CreateNextDoorIcon
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      sx={{
        "&.MuiSvgIcon-root": { width: "unset", height: "unset" },
        ...props.sx,
      }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    />
  );
}
