import { Box, Stack, Typography, alpha } from "@mui/material";
import React from "react";
import SuccessIcon from "../../icons/share/SuccessIcon";
import { useTheme } from "@emotion/react";
import useThemeStore from "../../hooks/store/useThemeStore";

const ChipinSuccessMsg = () => {
  const { palette } = useTheme();
  const { darkMode } = useThemeStore();
  return (
    <Box
      sx={{
        padding: { xs: "42px 20px 22px", sm: "49px 30px 19px" },
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        gap: { xs: "12px", sm: "17px" },
        backgroundColor: darkMode
          ? alpha(palette.success.secondary, 0.09)
          : alpha(palette.success.progressBar, 0.05),
      }}
    >
      <SuccessIcon
        sx={{
          width: { xs: "45px", sm: "55px" },
          height: { xs: "45px", sm: "55px" },
        }}
      />
      <Stack sx={{ flexDirection: "column" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: { xs: "16px", sm: "18px" },
            fontWeight: 600,
            lineHeight: "27px",
            letterSpacing: "-0.40799999237060547px",
            textAlign: "left",
            color: darkMode ? "white.1000" : "black.1000",
          }}
        >
          Success
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: { xs: "12px", sm: "14px" },
            fontWeight: 400,
            lineHeight: "21px",
            letterSpacing: "-0.40799999237060547px",
            textAlign: "left",
            color: darkMode ? "white.1000" : "black.1000",
          }}
        >
          Your transaction was successfully approved.
        </Typography>
      </Stack>
    </Box>
  );
};

export default ChipinSuccessMsg;
